/* eslint-disable */

// Converted from KML file downloaded at https://dgeq.org/carte2017simple.kml
const ridingsGeojson = {
  "type": "FeatureCollection",
  "features": [
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.641655,
                          45.541656,
                          0
                      ],
                      [
                          -73.648303,
                          45.530767,
                          0
                      ],
                      [
                          -73.64831,
                          45.530755,
                          0
                      ],
                      [
                          -73.65447,
                          45.520121,
                          0
                      ],
                      [
                          -73.662468,
                          45.507752,
                          0
                      ],
                      [
                          -73.66858,
                          45.509705,
                          0
                      ],
                      [
                          -73.672167,
                          45.511416,
                          0
                      ],
                      [
                          -73.679991,
                          45.516247,
                          0
                      ],
                      [
                          -73.680621,
                          45.515798,
                          0
                      ],
                      [
                          -73.698457,
                          45.527051,
                          0
                      ],
                      [
                          -73.694147,
                          45.528471,
                          0
                      ],
                      [
                          -73.689925,
                          45.528973,
                          0
                      ],
                      [
                          -73.686937,
                          45.528802,
                          0
                      ],
                      [
                          -73.68498,
                          45.530162,
                          0
                      ],
                      [
                          -73.684709,
                          45.530497,
                          0
                      ],
                      [
                          -73.68699,
                          45.530156,
                          0
                      ],
                      [
                          -73.690166,
                          45.530322,
                          0
                      ],
                      [
                          -73.692705,
                          45.530865,
                          0
                      ],
                      [
                          -73.693718,
                          45.531228,
                          0
                      ],
                      [
                          -73.69851,
                          45.534035,
                          0
                      ],
                      [
                          -73.70197,
                          45.536161,
                          0
                      ],
                      [
                          -73.704123,
                          45.537019,
                          0
                      ],
                      [
                          -73.707527,
                          45.537816,
                          0
                      ],
                      [
                          -73.709552,
                          45.538693,
                          0
                      ],
                      [
                          -73.712328,
                          45.540361,
                          0
                      ],
                      [
                          -73.708668,
                          45.543766,
                          0
                      ],
                      [
                          -73.703632,
                          45.546593,
                          0
                      ],
                      [
                          -73.692505,
                          45.549364,
                          0
                      ],
                      [
                          -73.679815,
                          45.552976,
                          0
                      ],
                      [
                          -73.641655,
                          45.541656,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Acadie",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.537186,
                          45.615837,
                          0
                      ],
                      [
                          -73.537868,
                          45.613906,
                          0
                      ],
                      [
                          -73.544097,
                          45.615054,
                          0
                      ],
                      [
                          -73.545396,
                          45.614108,
                          0
                      ],
                      [
                          -73.545749,
                          45.61336,
                          0
                      ],
                      [
                          -73.545961,
                          45.613033,
                          0
                      ],
                      [
                          -73.546318,
                          45.612703,
                          0
                      ],
                      [
                          -73.544587,
                          45.612198,
                          0
                      ],
                      [
                          -73.545082,
                          45.611134,
                          0
                      ],
                      [
                          -73.545336,
                          45.610741,
                          0
                      ],
                      [
                          -73.543159,
                          45.610013,
                          0
                      ],
                      [
                          -73.541082,
                          45.609506,
                          0
                      ],
                      [
                          -73.541369,
                          45.608871,
                          0
                      ],
                      [
                          -73.541571,
                          45.60816,
                          0
                      ],
                      [
                          -73.540826,
                          45.607838,
                          0
                      ],
                      [
                          -73.541584,
                          45.606931,
                          0
                      ],
                      [
                          -73.537546,
                          45.60518,
                          0
                      ],
                      [
                          -73.537771,
                          45.604784,
                          0
                      ],
                      [
                          -73.537158,
                          45.60459,
                          0
                      ],
                      [
                          -73.53652,
                          45.602632,
                          0
                      ],
                      [
                          -73.536747,
                          45.602698,
                          0
                      ],
                      [
                          -73.536586,
                          45.602138,
                          0
                      ],
                      [
                          -73.539302,
                          45.603263,
                          0
                      ],
                      [
                          -73.539493,
                          45.603036,
                          0
                      ],
                      [
                          -73.542183,
                          45.604183,
                          0
                      ],
                      [
                          -73.542949,
                          45.603197,
                          0
                      ],
                      [
                          -73.539798,
                          45.601809,
                          0
                      ],
                      [
                          -73.540701,
                          45.600678,
                          0
                      ],
                      [
                          -73.544728,
                          45.601906,
                          0
                      ],
                      [
                          -73.544728,
                          45.601906,
                          0
                      ],
                      [
                          -73.545247,
                          45.601101,
                          0
                      ],
                      [
                          -73.544878,
                          45.600943,
                          0
                      ],
                      [
                          -73.54481,
                          45.600742,
                          0
                      ],
                      [
                          -73.544484,
                          45.600652,
                          0
                      ],
                      [
                          -73.544828,
                          45.59955,
                          0
                      ],
                      [
                          -73.54454,
                          45.59941,
                          0
                      ],
                      [
                          -73.544299,
                          45.598483,
                          0
                      ],
                      [
                          -73.544228,
                          45.598206,
                          0
                      ],
                      [
                          -73.543854,
                          45.598087,
                          0
                      ],
                      [
                          -73.545391,
                          45.595604,
                          0
                      ],
                      [
                          -73.543454,
                          45.594799,
                          0
                      ],
                      [
                          -73.541762,
                          45.594192,
                          0
                      ],
                      [
                          -73.536984,
                          45.593225,
                          0
                      ],
                      [
                          -73.548988,
                          45.573467,
                          0
                      ],
                      [
                          -73.55576,
                          45.575716,
                          0
                      ],
                      [
                          -73.558393,
                          45.578081,
                          0
                      ],
                      [
                          -73.568483,
                          45.581443,
                          0
                      ],
                      [
                          -73.568246,
                          45.581783,
                          0
                      ],
                      [
                          -73.568099,
                          45.581735,
                          0
                      ],
                      [
                          -73.567967,
                          45.581909,
                          0
                      ],
                      [
                          -73.567812,
                          45.581875,
                          0
                      ],
                      [
                          -73.567617,
                          45.581928,
                          0
                      ],
                      [
                          -73.567566,
                          45.581868,
                          0
                      ],
                      [
                          -73.567444,
                          45.581896,
                          0
                      ],
                      [
                          -73.567383,
                          45.582034,
                          0
                      ],
                      [
                          -73.567302,
                          45.582071,
                          0
                      ],
                      [
                          -73.567083,
                          45.581973,
                          0
                      ],
                      [
                          -73.566992,
                          45.582033,
                          0
                      ],
                      [
                          -73.566625,
                          45.582479,
                          0
                      ],
                      [
                          -73.566529,
                          45.582483,
                          0
                      ],
                      [
                          -73.566392,
                          45.582373,
                          0
                      ],
                      [
                          -73.56629,
                          45.582491,
                          0
                      ],
                      [
                          -73.566139,
                          45.58252,
                          0
                      ],
                      [
                          -73.566071,
                          45.582488,
                          0
                      ],
                      [
                          -73.566028,
                          45.582511,
                          0
                      ],
                      [
                          -73.566,
                          45.582803,
                          0
                      ],
                      [
                          -73.565782,
                          45.582843,
                          0
                      ],
                      [
                          -73.565492,
                          45.582811,
                          0
                      ],
                      [
                          -73.565169,
                          45.582854,
                          0
                      ],
                      [
                          -73.565115,
                          45.582827,
                          0
                      ],
                      [
                          -73.564895,
                          45.58296,
                          0
                      ],
                      [
                          -73.56478,
                          45.583,
                          0
                      ],
                      [
                          -73.566161,
                          45.584264,
                          0
                      ],
                      [
                          -73.563955,
                          45.584988,
                          0
                      ],
                      [
                          -73.564253,
                          45.585406,
                          0
                      ],
                      [
                          -73.564215,
                          45.587244,
                          0
                      ],
                      [
                          -73.56607,
                          45.588172,
                          0
                      ],
                      [
                          -73.566497,
                          45.588552,
                          0
                      ],
                      [
                          -73.567722,
                          45.590504,
                          0
                      ],
                      [
                          -73.568454,
                          45.591811,
                          0
                      ],
                      [
                          -73.569021,
                          45.593536,
                          0
                      ],
                      [
                          -73.568677,
                          45.594885,
                          0
                      ],
                      [
                          -73.569702,
                          45.596605,
                          0
                      ],
                      [
                          -73.576088,
                          45.599238,
                          0
                      ],
                      [
                          -73.577333,
                          45.599392,
                          0
                      ],
                      [
                          -73.577103,
                          45.599784,
                          0
                      ],
                      [
                          -73.60321,
                          45.611155,
                          0
                      ],
                      [
                          -73.601656,
                          45.611971,
                          0
                      ],
                      [
                          -73.60263,
                          45.61288,
                          0
                      ],
                      [
                          -73.600638,
                          45.613202,
                          0
                      ],
                      [
                          -73.599126,
                          45.613778,
                          0
                      ],
                      [
                          -73.596471,
                          45.615188,
                          0
                      ],
                      [
                          -73.577345,
                          45.628286,
                          0
                      ],
                      [
                          -73.568407,
                          45.634234,
                          0
                      ],
                      [
                          -73.568341,
                          45.634206,
                          0
                      ],
                      [
                          -73.568168,
                          45.634131,
                          0
                      ],
                      [
                          -73.535497,
                          45.619899,
                          0
                      ],
                      [
                          -73.537186,
                          45.615837,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Anjou-Louis-Riel",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -71.759699,
                          46.363932,
                          0
                      ],
                      [
                          -71.720678,
                          46.389632,
                          0
                      ],
                      [
                          -71.617574,
                          46.451706,
                          0
                      ],
                      [
                          -71.617574,
                          46.451706,
                          0
                      ],
                      [
                          -71.565531,
                          46.412848,
                          0
                      ],
                      [
                          -71.531409,
                          46.434387,
                          0
                      ],
                      [
                          -71.478987,
                          46.395869,
                          0
                      ],
                      [
                          -71.478982,
                          46.395866,
                          0
                      ],
                      [
                          -71.508142,
                          46.376578,
                          0
                      ],
                      [
                          -71.494104,
                          46.366472,
                          0
                      ],
                      [
                          -71.481431,
                          46.356014,
                          0
                      ],
                      [
                          -71.481429,
                          46.356011,
                          0
                      ],
                      [
                          -71.508494,
                          46.338389,
                          0
                      ],
                      [
                          -71.371931,
                          46.267811,
                          0
                      ],
                      [
                          -71.371979,
                          46.267767,
                          0
                      ],
                      [
                          -71.419268,
                          46.223292,
                          0
                      ],
                      [
                          -71.434587,
                          46.230834,
                          0
                      ],
                      [
                          -71.445487,
                          46.220213,
                          0
                      ],
                      [
                          -71.466801,
                          46.231183,
                          0
                      ],
                      [
                          -71.466822,
                          46.231194,
                          0
                      ],
                      [
                          -71.485769,
                          46.21347,
                          0
                      ],
                      [
                          -71.486819,
                          46.212149,
                          0
                      ],
                      [
                          -71.488004,
                          46.209082,
                          0
                      ],
                      [
                          -71.483258,
                          46.206644,
                          0
                      ],
                      [
                          -71.517854,
                          46.174048,
                          0
                      ],
                      [
                          -71.517808,
                          46.174023,
                          0
                      ],
                      [
                          -71.529479,
                          46.163136,
                          0
                      ],
                      [
                          -71.525671,
                          46.16128,
                          0
                      ],
                      [
                          -71.523122,
                          46.159975,
                          0
                      ],
                      [
                          -71.533884,
                          46.149153,
                          0
                      ],
                      [
                          -71.514664,
                          46.13978,
                          0
                      ],
                      [
                          -71.50973,
                          46.137359,
                          0
                      ],
                      [
                          -71.509702,
                          46.137345,
                          0
                      ],
                      [
                          -71.55163,
                          46.09709,
                          0
                      ],
                      [
                          -71.625733,
                          46.025058,
                          0
                      ],
                      [
                          -71.645388,
                          46.035182,
                          0
                      ],
                      [
                          -71.645657,
                          46.035152,
                          0
                      ],
                      [
                          -71.653337,
                          46.039195,
                          0
                      ],
                      [
                          -71.653337,
                          46.039195,
                          0
                      ],
                      [
                          -71.653497,
                          46.039274,
                          0
                      ],
                      [
                          -71.653518,
                          46.039279,
                          0
                      ],
                      [
                          -71.653616,
                          46.039299,
                          0
                      ],
                      [
                          -71.711267,
                          46.068909,
                          0
                      ],
                      [
                          -71.711274,
                          46.068912,
                          0
                      ],
                      [
                          -71.723231,
                          46.057981,
                          0
                      ],
                      [
                          -71.725267,
                          46.059371,
                          0
                      ],
                      [
                          -71.760532,
                          46.077517,
                          0
                      ],
                      [
                          -71.772222,
                          46.066548,
                          0
                      ],
                      [
                          -71.771208,
                          46.065998,
                          0
                      ],
                      [
                          -71.782789,
                          46.054626,
                          0
                      ],
                      [
                          -71.783434,
                          46.054976,
                          0
                      ],
                      [
                          -71.806185,
                          46.033098,
                          0
                      ],
                      [
                          -71.806235,
                          46.033124,
                          0
                      ],
                      [
                          -71.808868,
                          46.034504,
                          0
                      ],
                      [
                          -71.879147,
                          45.966592,
                          0
                      ],
                      [
                          -71.949189,
                          46.004955,
                          0
                      ],
                      [
                          -71.972662,
                          46.019411,
                          0
                      ],
                      [
                          -71.972668,
                          46.019414,
                          0
                      ],
                      [
                          -71.986217,
                          46.026404,
                          0
                      ],
                      [
                          -71.986318,
                          46.026456,
                          0
                      ],
                      [
                          -71.986448,
                          46.026342,
                          0
                      ],
                      [
                          -72.014166,
                          46.001104,
                          0
                      ],
                      [
                          -72.028929,
                          46.009555,
                          0
                      ],
                      [
                          -72.028929,
                          46.009555,
                          0
                      ],
                      [
                          -72.043553,
                          46.017242,
                          0
                      ],
                      [
                          -72.043547,
                          46.017247,
                          0
                      ],
                      [
                          -72.029884,
                          46.029665,
                          0
                      ],
                      [
                          -72.045251,
                          46.037757,
                          0
                      ],
                      [
                          -72.045278,
                          46.037732,
                          0
                      ],
                      [
                          -72.054212,
                          46.029446,
                          0
                      ],
                      [
                          -72.069015,
                          46.037378,
                          0
                      ],
                      [
                          -72.082477,
                          46.024634,
                          0
                      ],
                      [
                          -72.082615,
                          46.02471,
                          0
                      ],
                      [
                          -72.097211,
                          46.032699,
                          0
                      ],
                      [
                          -72.097247,
                          46.032666,
                          0
                      ],
                      [
                          -72.11358,
                          46.017176,
                          0
                      ],
                      [
                          -72.145163,
                          46.03457,
                          0
                      ],
                      [
                          -72.140762,
                          46.038615,
                          0
                      ],
                      [
                          -72.161862,
                          46.03465,
                          0
                      ],
                      [
                          -72.16181,
                          46.057115,
                          0
                      ],
                      [
                          -72.162383,
                          46.07884,
                          0
                      ],
                      [
                          -72.178611,
                          46.075668,
                          0
                      ],
                      [
                          -72.178657,
                          46.07566,
                          0
                      ],
                      [
                          -72.178659,
                          46.07566,
                          0
                      ],
                      [
                          -72.178341,
                          46.097132,
                          0
                      ],
                      [
                          -72.178341,
                          46.097132,
                          0
                      ],
                      [
                          -72.172585,
                          46.09811,
                          0
                      ],
                      [
                          -72.17255,
                          46.105878,
                          0
                      ],
                      [
                          -72.1495,
                          46.110603,
                          0
                      ],
                      [
                          -72.149505,
                          46.125206,
                          0
                      ],
                      [
                          -72.149504,
                          46.12522,
                          0
                      ],
                      [
                          -72.149503,
                          46.12522,
                          0
                      ],
                      [
                          -72.102048,
                          46.13477,
                          0
                      ],
                      [
                          -72.102544,
                          46.1639,
                          0
                      ],
                      [
                          -72.102545,
                          46.163905,
                          0
                      ],
                      [
                          -72.082073,
                          46.167765,
                          0
                      ],
                      [
                          -72.081818,
                          46.218545,
                          0
                      ],
                      [
                          -72.084247,
                          46.216959,
                          0
                      ],
                      [
                          -72.117428,
                          46.241533,
                          0
                      ],
                      [
                          -72.092254,
                          46.257906,
                          0
                      ],
                      [
                          -72.092251,
                          46.257904,
                          0
                      ],
                      [
                          -72.092208,
                          46.257872,
                          0
                      ],
                      [
                          -72.082121,
                          46.250302,
                          0
                      ],
                      [
                          -72.055928,
                          46.267558,
                          0
                      ],
                      [
                          -72.055931,
                          46.26756,
                          0
                      ],
                      [
                          -72.064885,
                          46.274376,
                          0
                      ],
                      [
                          -72.064887,
                          46.274377,
                          0
                      ],
                      [
                          -72.015813,
                          46.305088,
                          0
                      ],
                      [
                          -72.020759,
                          46.308815,
                          0
                      ],
                      [
                          -72.005385,
                          46.318985,
                          0
                      ],
                      [
                          -71.974959,
                          46.294669,
                          0
                      ],
                      [
                          -71.960149,
                          46.30404,
                          0
                      ],
                      [
                          -71.959983,
                          46.304142,
                          0
                      ],
                      [
                          -71.945198,
                          46.292215,
                          0
                      ],
                      [
                          -71.945197,
                          46.292216,
                          0
                      ],
                      [
                          -71.945127,
                          46.292262,
                          0
                      ],
                      [
                          -71.944958,
                          46.292372,
                          0
                      ],
                      [
                          -71.891696,
                          46.326626,
                          0
                      ],
                      [
                          -71.89169,
                          46.32663,
                          0
                      ],
                      [
                          -71.974047,
                          46.386333,
                          0
                      ],
                      [
                          -71.881162,
                          46.450756,
                          0
                      ],
                      [
                          -71.759699,
                          46.363932,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Arthabaska",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -71.119025,
                          46.630387,
                          0
                      ],
                      [
                          -71.092954,
                          46.648473,
                          0
                      ],
                      [
                          -71.084399,
                          46.642916,
                          0
                      ],
                      [
                          -71.087667,
                          46.640766,
                          0
                      ],
                      [
                          -71.088137,
                          46.639056,
                          0
                      ],
                      [
                          -71.084054,
                          46.636172,
                          0
                      ],
                      [
                          -71.083526,
                          46.634965,
                          0
                      ],
                      [
                          -71.082715,
                          46.631955,
                          0
                      ],
                      [
                          -71.080239,
                          46.63027,
                          0
                      ],
                      [
                          -71.078178,
                          46.629711,
                          0
                      ],
                      [
                          -71.076329,
                          46.628441,
                          0
                      ],
                      [
                          -71.074077,
                          46.628825,
                          0
                      ],
                      [
                          -71.070673,
                          46.626107,
                          0
                      ],
                      [
                          -71.066753,
                          46.624984,
                          0
                      ],
                      [
                          -71.058475,
                          46.623288,
                          0
                      ],
                      [
                          -71.053082,
                          46.623868,
                          0
                      ],
                      [
                          -71.050533,
                          46.621376,
                          0
                      ],
                      [
                          -71.047182,
                          46.62082,
                          0
                      ],
                      [
                          -71.04716,
                          46.620816,
                          0
                      ],
                      [
                          -71.052201,
                          46.616184,
                          0
                      ],
                      [
                          -71.053298,
                          46.616364,
                          0
                      ],
                      [
                          -71.054436,
                          46.613659,
                          0
                      ],
                      [
                          -71.059935,
                          46.614754,
                          0
                      ],
                      [
                          -71.060783,
                          46.61274,
                          0
                      ],
                      [
                          -71.039323,
                          46.608493,
                          0
                      ],
                      [
                          -71.03901,
                          46.60617,
                          0
                      ],
                      [
                          -71.039237,
                          46.604738,
                          0
                      ],
                      [
                          -71.040597,
                          46.602955,
                          0
                      ],
                      [
                          -71.04216,
                          46.590324,
                          0
                      ],
                      [
                          -71.01889,
                          46.589211,
                          0
                      ],
                      [
                          -71.019185,
                          46.586824,
                          0
                      ],
                      [
                          -70.995939,
                          46.585934,
                          0
                      ],
                      [
                          -70.996051,
                          46.585105,
                          0
                      ],
                      [
                          -70.993343,
                          46.585709,
                          0
                      ],
                      [
                          -70.991018,
                          46.585346,
                          0
                      ],
                      [
                          -70.989177,
                          46.585719,
                          0
                      ],
                      [
                          -70.984191,
                          46.587466,
                          0
                      ],
                      [
                          -70.981903,
                          46.585355,
                          0
                      ],
                      [
                          -70.960899,
                          46.593768,
                          0
                      ],
                      [
                          -70.956885,
                          46.590499,
                          0
                      ],
                      [
                          -70.951293,
                          46.586786,
                          0
                      ],
                      [
                          -70.951285,
                          46.586782,
                          0
                      ],
                      [
                          -70.950278,
                          46.585395,
                          0
                      ],
                      [
                          -70.950657,
                          46.583616,
                          0
                      ],
                      [
                          -70.949154,
                          46.582299,
                          0
                      ],
                      [
                          -70.945344,
                          46.580976,
                          0
                      ],
                      [
                          -70.943999,
                          46.580332,
                          0
                      ],
                      [
                          -70.942025,
                          46.579994,
                          0
                      ],
                      [
                          -70.939135,
                          46.578385,
                          0
                      ],
                      [
                          -70.937422,
                          46.577382,
                          0
                      ],
                      [
                          -70.937419,
                          46.577382,
                          0
                      ],
                      [
                          -70.934269,
                          46.577629,
                          0
                      ],
                      [
                          -70.927304,
                          46.577583,
                          0
                      ],
                      [
                          -70.92324,
                          46.57779,
                          0
                      ],
                      [
                          -70.911376,
                          46.564274,
                          0
                      ],
                      [
                          -70.910521,
                          46.562667,
                          0
                      ],
                      [
                          -70.909188,
                          46.561364,
                          0
                      ],
                      [
                          -70.905212,
                          46.562924,
                          0
                      ],
                      [
                          -70.874974,
                          46.534877,
                          0
                      ],
                      [
                          -70.86335,
                          46.539724,
                          0
                      ],
                      [
                          -70.836219,
                          46.513686,
                          0
                      ],
                      [
                          -70.814188,
                          46.528433,
                          0
                      ],
                      [
                          -70.814108,
                          46.52837,
                          0
                      ],
                      [
                          -70.768264,
                          46.491891,
                          0
                      ],
                      [
                          -70.765084,
                          46.494076,
                          0
                      ],
                      [
                          -70.765057,
                          46.494095,
                          0
                      ],
                      [
                          -70.75446,
                          46.48594,
                          0
                      ],
                      [
                          -70.743414,
                          46.493655,
                          0
                      ],
                      [
                          -70.695919,
                          46.456421,
                          0
                      ],
                      [
                          -70.709819,
                          46.446957,
                          0
                      ],
                      [
                          -70.707823,
                          46.445444,
                          0
                      ],
                      [
                          -70.723506,
                          46.434728,
                          0
                      ],
                      [
                          -70.723532,
                          46.43471,
                          0
                      ],
                      [
                          -70.688794,
                          46.407331,
                          0
                      ],
                      [
                          -70.662537,
                          46.424569,
                          0
                      ],
                      [
                          -70.641386,
                          46.409819,
                          0
                      ],
                      [
                          -70.641386,
                          46.409818,
                          0
                      ],
                      [
                          -70.642529,
                          46.409099,
                          0
                      ],
                      [
                          -70.642529,
                          46.409098,
                          0
                      ],
                      [
                          -70.557914,
                          46.349967,
                          0
                      ],
                      [
                          -70.558014,
                          46.349901,
                          0
                      ],
                      [
                          -70.665622,
                          46.278681,
                          0
                      ],
                      [
                          -70.657808,
                          46.272731,
                          0
                      ],
                      [
                          -70.657861,
                          46.272695,
                          0
                      ],
                      [
                          -70.685803,
                          46.254284,
                          0
                      ],
                      [
                          -70.686684,
                          46.253693,
                          0
                      ],
                      [
                          -70.686713,
                          46.253674,
                          0
                      ],
                      [
                          -70.686763,
                          46.25364,
                          0
                      ],
                      [
                          -70.701007,
                          46.244619,
                          0
                      ],
                      [
                          -70.70355,
                          46.242641,
                          0
                      ],
                      [
                          -70.711202,
                          46.237205,
                          0
                      ],
                      [
                          -70.715397,
                          46.234419,
                          0
                      ],
                      [
                          -70.706034,
                          46.227218,
                          0
                      ],
                      [
                          -70.702945,
                          46.227031,
                          0
                      ],
                      [
                          -70.700463,
                          46.226556,
                          0
                      ],
                      [
                          -70.70048,
                          46.226544,
                          0
                      ],
                      [
                          -70.714419,
                          46.21689,
                          0
                      ],
                      [
                          -70.713586,
                          46.21668,
                          0
                      ],
                      [
                          -70.709936,
                          46.216019,
                          0
                      ],
                      [
                          -70.707896,
                          46.215672,
                          0
                      ],
                      [
                          -70.707516,
                          46.215423,
                          0
                      ],
                      [
                          -70.705982,
                          46.214119,
                          0
                      ],
                      [
                          -70.70455,
                          46.213055,
                          0
                      ],
                      [
                          -70.703451,
                          46.212346,
                          0
                      ],
                      [
                          -70.722267,
                          46.19946,
                          0
                      ],
                      [
                          -70.723156,
                          46.198873,
                          0
                      ],
                      [
                          -70.723777,
                          46.198443,
                          0
                      ],
                      [
                          -70.724121,
                          46.19821,
                          0
                      ],
                      [
                          -70.724804,
                          46.197747,
                          0
                      ],
                      [
                          -70.725425,
                          46.197328,
                          0
                      ],
                      [
                          -70.725184,
                          46.197197,
                          0
                      ],
                      [
                          -70.725182,
                          46.197196,
                          0
                      ],
                      [
                          -70.724919,
                          46.196982,
                          0
                      ],
                      [
                          -70.724684,
                          46.196659,
                          0
                      ],
                      [
                          -70.724572,
                          46.196345,
                          0
                      ],
                      [
                          -70.724379,
                          46.196053,
                          0
                      ],
                      [
                          -70.724132,
                          46.195828,
                          0
                      ],
                      [
                          -70.72457,
                          46.195604,
                          0
                      ],
                      [
                          -70.724997,
                          46.195559,
                          0
                      ],
                      [
                          -70.725382,
                          46.195558,
                          0
                      ],
                      [
                          -70.725909,
                          46.1957,
                          0
                      ],
                      [
                          -70.725952,
                          46.195727,
                          0
                      ],
                      [
                          -70.727155,
                          46.194908,
                          0
                      ],
                      [
                          -70.727373,
                          46.194759,
                          0
                      ],
                      [
                          -70.742693,
                          46.184324,
                          0
                      ],
                      [
                          -70.74819,
                          46.18058,
                          0
                      ],
                      [
                          -70.745941,
                          46.175474,
                          0
                      ],
                      [
                          -70.744819,
                          46.172815,
                          0
                      ],
                      [
                          -70.74322,
                          46.171137,
                          0
                      ],
                      [
                          -70.740688,
                          46.168574,
                          0
                      ],
                      [
                          -70.740666,
                          46.168547,
                          0
                      ],
                      [
                          -70.740338,
                          46.16813,
                          0
                      ],
                      [
                          -70.739776,
                          46.167159,
                          0
                      ],
                      [
                          -70.74017,
                          46.163916,
                          0
                      ],
                      [
                          -70.740423,
                          46.16267,
                          0
                      ],
                      [
                          -70.754723,
                          46.152796,
                          0
                      ],
                      [
                          -70.756159,
                          46.146273,
                          0
                      ],
                      [
                          -70.755356,
                          46.145207,
                          0
                      ],
                      [
                          -70.753843,
                          46.142892,
                          0
                      ],
                      [
                          -70.749433,
                          46.135846,
                          0
                      ],
                      [
                          -70.774906,
                          46.118796,
                          0
                      ],
                      [
                          -70.775046,
                          46.118705,
                          0
                      ],
                      [
                          -70.784859,
                          46.126994,
                          0
                      ],
                      [
                          -70.783834,
                          46.127667,
                          0
                      ],
                      [
                          -70.78384,
                          46.127672,
                          0
                      ],
                      [
                          -70.784436,
                          46.128167,
                          0
                      ],
                      [
                          -70.786153,
                          46.129612,
                          0
                      ],
                      [
                          -70.79374,
                          46.136036,
                          0
                      ],
                      [
                          -70.823424,
                          46.116115,
                          0
                      ],
                      [
                          -70.831676,
                          46.12354,
                          0
                      ],
                      [
                          -70.846012,
                          46.114442,
                          0
                      ],
                      [
                          -70.845972,
                          46.114405,
                          0
                      ],
                      [
                          -70.832392,
                          46.102102,
                          0
                      ],
                      [
                          -70.832432,
                          46.102077,
                          0
                      ],
                      [
                          -70.84196,
                          46.096082,
                          0
                      ],
                      [
                          -70.842019,
                          46.095998,
                          0
                      ],
                      [
                          -70.844465,
                          46.092554,
                          0
                      ],
                      [
                          -70.859329,
                          46.083973,
                          0
                      ],
                      [
                          -70.859322,
                          46.083963,
                          0
                      ],
                      [
                          -70.846164,
                          46.071824,
                          0
                      ],
                      [
                          -70.860074,
                          46.062715,
                          0
                      ],
                      [
                          -70.860109,
                          46.062746,
                          0
                      ],
                      [
                          -70.875744,
                          46.076814,
                          0
                      ],
                      [
                          -70.893833,
                          46.092904,
                          0
                      ],
                      [
                          -70.90921,
                          46.082999,
                          0
                      ],
                      [
                          -70.909245,
                          46.083033,
                          0
                      ],
                      [
                          -70.923082,
                          46.095598,
                          0
                      ],
                      [
                          -70.937141,
                          46.108155,
                          0
                      ],
                      [
                          -70.940911,
                          46.111542,
                          0
                      ],
                      [
                          -70.940912,
                          46.111544,
                          0
                      ],
                      [
                          -70.946569,
                          46.108024,
                          0
                      ],
                      [
                          -70.955219,
                          46.102567,
                          0
                      ],
                      [
                          -70.969862,
                          46.11565,
                          0
                      ],
                      [
                          -70.980357,
                          46.124997,
                          0
                      ],
                      [
                          -70.984054,
                          46.128221,
                          0
                      ],
                      [
                          -70.984456,
                          46.128343,
                          0
                      ],
                      [
                          -70.984936,
                          46.128349,
                          0
                      ],
                      [
                          -70.985387,
                          46.128255,
                          0
                      ],
                      [
                          -70.986254,
                          46.128255,
                          0
                      ],
                      [
                          -70.987292,
                          46.128418,
                          0
                      ],
                      [
                          -70.98865,
                          46.129358,
                          0
                      ],
                      [
                          -71.004632,
                          46.142137,
                          0
                      ],
                      [
                          -71.006541,
                          46.143591,
                          0
                      ],
                      [
                          -71.006541,
                          46.143591,
                          0
                      ],
                      [
                          -70.993266,
                          46.152469,
                          0
                      ],
                      [
                          -70.993264,
                          46.15247,
                          0
                      ],
                      [
                          -71.001942,
                          46.158986,
                          0
                      ],
                      [
                          -71.001944,
                          46.158987,
                          0
                      ],
                      [
                          -71.013388,
                          46.167951,
                          0
                      ],
                      [
                          -71.013391,
                          46.167953,
                          0
                      ],
                      [
                          -70.978977,
                          46.190123,
                          0
                      ],
                      [
                          -71.003567,
                          46.212872,
                          0
                      ],
                      [
                          -71.049721,
                          46.256671,
                          0
                      ],
                      [
                          -71.065966,
                          46.272296,
                          0
                      ],
                      [
                          -71.082715,
                          46.287711,
                          0
                      ],
                      [
                          -71.083411,
                          46.287289,
                          0
                      ],
                      [
                          -71.098341,
                          46.277238,
                          0
                      ],
                      [
                          -71.099782,
                          46.27815,
                          0
                      ],
                      [
                          -71.098602,
                          46.278888,
                          0
                      ],
                      [
                          -71.104043,
                          46.283722,
                          0
                      ],
                      [
                          -71.104046,
                          46.284378,
                          0
                      ],
                      [
                          -71.106017,
                          46.286525,
                          0
                      ],
                      [
                          -71.109057,
                          46.289891,
                          0
                      ],
                      [
                          -71.11019,
                          46.290892,
                          0
                      ],
                      [
                          -71.117135,
                          46.297092,
                          0
                      ],
                      [
                          -71.119519,
                          46.299062,
                          0
                      ],
                      [
                          -71.106479,
                          46.307852,
                          0
                      ],
                      [
                          -71.105155,
                          46.308709,
                          0
                      ],
                      [
                          -71.104962,
                          46.308721,
                          0
                      ],
                      [
                          -71.100104,
                          46.308906,
                          0
                      ],
                      [
                          -71.122865,
                          46.325825,
                          0
                      ],
                      [
                          -71.132534,
                          46.332071,
                          0
                      ],
                      [
                          -71.13748,
                          46.334838,
                          0
                      ],
                      [
                          -71.145662,
                          46.341213,
                          0
                      ],
                      [
                          -71.145466,
                          46.341312,
                          0
                      ],
                      [
                          -71.131562,
                          46.348393,
                          0
                      ],
                      [
                          -71.124799,
                          46.351728,
                          0
                      ],
                      [
                          -71.121712,
                          46.349569,
                          0
                      ],
                      [
                          -71.103204,
                          46.358953,
                          0
                      ],
                      [
                          -71.109607,
                          46.363091,
                          0
                      ],
                      [
                          -71.110591,
                          46.363915,
                          0
                      ],
                      [
                          -71.113393,
                          46.371694,
                          0
                      ],
                      [
                          -71.115169,
                          46.373209,
                          0
                      ],
                      [
                          -71.117535,
                          46.37446,
                          0
                      ],
                      [
                          -71.122279,
                          46.376895,
                          0
                      ],
                      [
                          -71.13062,
                          46.38112,
                          0
                      ],
                      [
                          -71.132314,
                          46.3828,
                          0
                      ],
                      [
                          -71.136666,
                          46.386992,
                          0
                      ],
                      [
                          -71.145153,
                          46.394978,
                          0
                      ],
                      [
                          -71.148821,
                          46.39918,
                          0
                      ],
                      [
                          -71.154795,
                          46.410599,
                          0
                      ],
                      [
                          -71.159018,
                          46.417921,
                          0
                      ],
                      [
                          -71.166639,
                          46.424961,
                          0
                      ],
                      [
                          -71.166642,
                          46.424963,
                          0
                      ],
                      [
                          -71.181324,
                          46.438538,
                          0
                      ],
                      [
                          -71.215493,
                          46.470055,
                          0
                      ],
                      [
                          -71.226959,
                          46.480514,
                          0
                      ],
                      [
                          -71.227621,
                          46.481136,
                          0
                      ],
                      [
                          -71.227895,
                          46.481381,
                          0
                      ],
                      [
                          -71.225951,
                          46.482672,
                          0
                      ],
                      [
                          -71.208265,
                          46.494541,
                          0
                      ],
                      [
                          -71.208456,
                          46.494728,
                          0
                      ],
                      [
                          -71.191605,
                          46.501676,
                          0
                      ],
                      [
                          -71.211023,
                          46.515186,
                          0
                      ],
                      [
                          -71.194856,
                          46.526468,
                          0
                      ],
                      [
                          -71.215917,
                          46.541044,
                          0
                      ],
                      [
                          -71.232078,
                          46.529831,
                          0
                      ],
                      [
                          -71.232151,
                          46.53117,
                          0
                      ],
                      [
                          -71.233899,
                          46.532248,
                          0
                      ],
                      [
                          -71.235421,
                          46.534264,
                          0
                      ],
                      [
                          -71.239667,
                          46.542891,
                          0
                      ],
                      [
                          -71.241266,
                          46.544116,
                          0
                      ],
                      [
                          -71.242839,
                          46.546812,
                          0
                      ],
                      [
                          -71.249772,
                          46.542025,
                          0
                      ],
                      [
                          -71.24994,
                          46.541909,
                          0
                      ],
                      [
                          -71.249779,
                          46.541798,
                          0
                      ],
                      [
                          -71.268628,
                          46.52876,
                          0
                      ],
                      [
                          -71.300773,
                          46.551076,
                          0
                      ],
                      [
                          -71.332132,
                          46.573015,
                          0
                      ],
                      [
                          -71.300602,
                          46.594424,
                          0
                      ],
                      [
                          -71.268229,
                          46.616684,
                          0
                      ],
                      [
                          -71.265572,
                          46.618752,
                          0
                      ],
                      [
                          -71.265366,
                          46.619043,
                          0
                      ],
                      [
                          -71.265432,
                          46.6193,
                          0
                      ],
                      [
                          -71.265086,
                          46.619059,
                          0
                      ],
                      [
                          -71.265007,
                          46.619187,
                          0
                      ],
                      [
                          -71.263954,
                          46.619917,
                          0
                      ],
                      [
                          -71.254268,
                          46.629907,
                          0
                      ],
                      [
                          -71.237686,
                          46.618567,
                          0
                      ],
                      [
                          -71.236419,
                          46.6177,
                          0
                      ],
                      [
                          -71.236071,
                          46.618227,
                          0
                      ],
                      [
                          -71.23539,
                          46.619392,
                          0
                      ],
                      [
                          -71.23539,
                          46.619392,
                          0
                      ],
                      [
                          -71.23549,
                          46.620081,
                          0
                      ],
                      [
                          -71.235351,
                          46.62049,
                          0
                      ],
                      [
                          -71.235065,
                          46.620773,
                          0
                      ],
                      [
                          -71.234241,
                          46.621339,
                          0
                      ],
                      [
                          -71.23376,
                          46.621821,
                          0
                      ],
                      [
                          -71.233119,
                          46.622632,
                          0
                      ],
                      [
                          -71.232569,
                          46.623388,
                          0
                      ],
                      [
                          -71.232359,
                          46.623998,
                          0
                      ],
                      [
                          -71.232318,
                          46.62498,
                          0
                      ],
                      [
                          -71.232775,
                          46.62541,
                          0
                      ],
                      [
                          -71.234008,
                          46.62553,
                          0
                      ],
                      [
                          -71.237361,
                          46.625306,
                          0
                      ],
                      [
                          -71.238364,
                          46.625374,
                          0
                      ],
                      [
                          -71.239873,
                          46.626129,
                          0
                      ],
                      [
                          -71.24008,
                          46.626438,
                          0
                      ],
                      [
                          -71.240077,
                          46.626442,
                          0
                      ],
                      [
                          -71.239465,
                          46.627397,
                          0
                      ],
                      [
                          -71.238136,
                          46.6282,
                          0
                      ],
                      [
                          -71.236953,
                          46.630064,
                          0
                      ],
                      [
                          -71.237255,
                          46.632419,
                          0
                      ],
                      [
                          -71.237736,
                          46.633687,
                          0
                      ],
                      [
                          -71.237736,
                          46.633688,
                          0
                      ],
                      [
                          -71.236787,
                          46.635767,
                          0
                      ],
                      [
                          -71.23657,
                          46.637054,
                          0
                      ],
                      [
                          -71.237146,
                          46.638207,
                          0
                      ],
                      [
                          -71.238241,
                          46.64021,
                          0
                      ],
                      [
                          -71.238124,
                          46.642148,
                          0
                      ],
                      [
                          -71.223528,
                          46.65167,
                          0
                      ],
                      [
                          -71.221209,
                          46.64594,
                          0
                      ],
                      [
                          -71.21692,
                          46.635025,
                          0
                      ],
                      [
                          -71.216376,
                          46.633668,
                          0
                      ],
                      [
                          -71.216378,
                          46.633631,
                          0
                      ],
                      [
                          -71.21648,
                          46.632259,
                          0
                      ],
                      [
                          -71.217089,
                          46.624765,
                          0
                      ],
                      [
                          -71.217602,
                          46.619185,
                          0
                      ],
                      [
                          -71.217086,
                          46.61709,
                          0
                      ],
                      [
                          -71.216585,
                          46.615052,
                          0
                      ],
                      [
                          -71.216581,
                          46.614999,
                          0
                      ],
                      [
                          -71.216415,
                          46.613251,
                          0
                      ],
                      [
                          -71.216047,
                          46.610901,
                          0
                      ],
                      [
                          -71.215093,
                          46.608939,
                          0
                      ],
                      [
                          -71.215083,
                          46.608925,
                          0
                      ],
                      [
                          -71.214769,
                          46.607644,
                          0
                      ],
                      [
                          -71.15157,
                          46.65289,
                          0
                      ],
                      [
                          -71.119025,
                          46.630387,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Beauce-Nord",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.837849,
                          45.339093,
                          0
                      ],
                      [
                          -73.832912,
                          45.329786,
                          0
                      ],
                      [
                          -73.811586,
                          45.313846,
                          0
                      ],
                      [
                          -73.814342,
                          45.311842,
                          0
                      ],
                      [
                          -73.812515,
                          45.307089,
                          0
                      ],
                      [
                          -73.817236,
                          45.302358,
                          0
                      ],
                      [
                          -73.818676,
                          45.299048,
                          0
                      ],
                      [
                          -73.820332,
                          45.29523,
                          0
                      ],
                      [
                          -73.820333,
                          45.295225,
                          0
                      ],
                      [
                          -73.818967,
                          45.29522,
                          0
                      ],
                      [
                          -73.819386,
                          45.294088,
                          0
                      ],
                      [
                          -73.823796,
                          45.289855,
                          0
                      ],
                      [
                          -73.825275,
                          45.2864,
                          0
                      ],
                      [
                          -73.823345,
                          45.283639,
                          0
                      ],
                      [
                          -73.826929,
                          45.278913,
                          0
                      ],
                      [
                          -73.826687,
                          45.278021,
                          0
                      ],
                      [
                          -73.819366,
                          45.277287,
                          0
                      ],
                      [
                          -73.819868,
                          45.273541,
                          0
                      ],
                      [
                          -73.820665,
                          45.27363,
                          0
                      ],
                      [
                          -73.821794,
                          45.274446,
                          0
                      ],
                      [
                          -73.851357,
                          45.229287,
                          0
                      ],
                      [
                          -73.851399,
                          45.22932,
                          0
                      ],
                      [
                          -73.859162,
                          45.235085,
                          0
                      ],
                      [
                          -73.862368,
                          45.232164,
                          0
                      ],
                      [
                          -73.866687,
                          45.224978,
                          0
                      ],
                      [
                          -73.856583,
                          45.217431,
                          0
                      ],
                      [
                          -73.856592,
                          45.217429,
                          0
                      ],
                      [
                          -73.859994,
                          45.216131,
                          0
                      ],
                      [
                          -73.864808,
                          45.2118,
                          0
                      ],
                      [
                          -73.867537,
                          45.21382,
                          0
                      ],
                      [
                          -73.873323,
                          45.208832,
                          0
                      ],
                      [
                          -73.885151,
                          45.206962,
                          0
                      ],
                      [
                          -73.887266,
                          45.204474,
                          0
                      ],
                      [
                          -73.902447,
                          45.203039,
                          0
                      ],
                      [
                          -73.90392,
                          45.201014,
                          0
                      ],
                      [
                          -73.910101,
                          45.19539,
                          0
                      ],
                      [
                          -73.911425,
                          45.193604,
                          0
                      ],
                      [
                          -73.910923,
                          45.193181,
                          0
                      ],
                      [
                          -73.913123,
                          45.191724,
                          0
                      ],
                      [
                          -73.91072,
                          45.189821,
                          0
                      ],
                      [
                          -73.91319,
                          45.18803,
                          0
                      ],
                      [
                          -73.916583,
                          45.19061,
                          0
                      ],
                      [
                          -73.918714,
                          45.189254,
                          0
                      ],
                      [
                          -73.920026,
                          45.187307,
                          0
                      ],
                      [
                          -73.920218,
                          45.187327,
                          0
                      ],
                      [
                          -73.921433,
                          45.188283,
                          0
                      ],
                      [
                          -73.921616,
                          45.188177,
                          0
                      ],
                      [
                          -73.919609,
                          45.186573,
                          0
                      ],
                      [
                          -73.921794,
                          45.185155,
                          0
                      ],
                      [
                          -73.922199,
                          45.185473,
                          0
                      ],
                      [
                          -73.924451,
                          45.184032,
                          0
                      ],
                      [
                          -73.925449,
                          45.184816,
                          0
                      ],
                      [
                          -73.927612,
                          45.183396,
                          0
                      ],
                      [
                          -73.927302,
                          45.183155,
                          0
                      ],
                      [
                          -73.929531,
                          45.181751,
                          0
                      ],
                      [
                          -73.928453,
                          45.180926,
                          0
                      ],
                      [
                          -73.928474,
                          45.180911,
                          0
                      ],
                      [
                          -73.937181,
                          45.175028,
                          0
                      ],
                      [
                          -73.937667,
                          45.172056,
                          0
                      ],
                      [
                          -73.939129,
                          45.170437,
                          0
                      ],
                      [
                          -73.938282,
                          45.169811,
                          0
                      ],
                      [
                          -73.938292,
                          45.169804,
                          0
                      ],
                      [
                          -73.950014,
                          45.162199,
                          0
                      ],
                      [
                          -73.972819,
                          45.179451,
                          0
                      ],
                      [
                          -74.023924,
                          45.153387,
                          0
                      ],
                      [
                          -74.024185,
                          45.153315,
                          0
                      ],
                      [
                          -74.026324,
                          45.154978,
                          0
                      ],
                      [
                          -74.026385,
                          45.155026,
                          0
                      ],
                      [
                          -74.124093,
                          45.145487,
                          0
                      ],
                      [
                          -74.124109,
                          45.145485,
                          0
                      ],
                      [
                          -74.124177,
                          45.145546,
                          0
                      ],
                      [
                          -74.212701,
                          45.220875,
                          0
                      ],
                      [
                          -74.212704,
                          45.220878,
                          0
                      ],
                      [
                          -74.197935,
                          45.232182,
                          0
                      ],
                      [
                          -74.180247,
                          45.248409,
                          0
                      ],
                      [
                          -74.180182,
                          45.248479,
                          0
                      ],
                      [
                          -74.172859,
                          45.255977,
                          0
                      ],
                      [
                          -74.168951,
                          45.260371,
                          0
                      ],
                      [
                          -74.169292,
                          45.263688,
                          0
                      ],
                      [
                          -74.174647,
                          45.267158,
                          0
                      ],
                      [
                          -74.175701,
                          45.267766,
                          0
                      ],
                      [
                          -74.177756,
                          45.26812,
                          0
                      ],
                      [
                          -74.178423,
                          45.269175,
                          0
                      ],
                      [
                          -74.179221,
                          45.269748,
                          0
                      ],
                      [
                          -74.18086,
                          45.272144,
                          0
                      ],
                      [
                          -74.179731,
                          45.274061,
                          0
                      ],
                      [
                          -74.177579,
                          45.274432,
                          0
                      ],
                      [
                          -74.174418,
                          45.273156,
                          0
                      ],
                      [
                          -74.174418,
                          45.273156,
                          0
                      ],
                      [
                          -74.17327,
                          45.273146,
                          0
                      ],
                      [
                          -74.170766,
                          45.273926,
                          0
                      ],
                      [
                          -74.169657,
                          45.274275,
                          0
                      ],
                      [
                          -74.169116,
                          45.274026,
                          0
                      ],
                      [
                          -74.16863,
                          45.273017,
                          0
                      ],
                      [
                          -74.168365,
                          45.272055,
                          0
                      ],
                      [
                          -74.168528,
                          45.271526,
                          0
                      ],
                      [
                          -74.168249,
                          45.27088,
                          0
                      ],
                      [
                          -74.167541,
                          45.270456,
                          0
                      ],
                      [
                          -74.166613,
                          45.270492,
                          0
                      ],
                      [
                          -74.165729,
                          45.272282,
                          0
                      ],
                      [
                          -74.165495,
                          45.274065,
                          0
                      ],
                      [
                          -74.165621,
                          45.275213,
                          0
                      ],
                      [
                          -74.165802,
                          45.275566,
                          0
                      ],
                      [
                          -74.165766,
                          45.276423,
                          0
                      ],
                      [
                          -74.164762,
                          45.276488,
                          0
                      ],
                      [
                          -74.16352,
                          45.276743,
                          0
                      ],
                      [
                          -74.162279,
                          45.27688,
                          0
                      ],
                      [
                          -74.161524,
                          45.278675,
                          0
                      ],
                      [
                          -74.1613,
                          45.279404,
                          0
                      ],
                      [
                          -74.160716,
                          45.28011,
                          0
                      ],
                      [
                          -74.159261,
                          45.281435,
                          0
                      ],
                      [
                          -74.158137,
                          45.282353,
                          0
                      ],
                      [
                          -74.156975,
                          45.283739,
                          0
                      ],
                      [
                          -74.156637,
                          45.285973,
                          0
                      ],
                      [
                          -74.157516,
                          45.290985,
                          0
                      ],
                      [
                          -74.157516,
                          45.290986,
                          0
                      ],
                      [
                          -74.153673,
                          45.29197,
                          0
                      ],
                      [
                          -74.143764,
                          45.295211,
                          0
                      ],
                      [
                          -74.137307,
                          45.29805,
                          0
                      ],
                      [
                          -74.1267,
                          45.300846,
                          0
                      ],
                      [
                          -74.126697,
                          45.300847,
                          0
                      ],
                      [
                          -74.119754,
                          45.302352,
                          0
                      ],
                      [
                          -74.105604,
                          45.304385,
                          0
                      ],
                      [
                          -74.105325,
                          45.304395,
                          0
                      ],
                      [
                          -74.099495,
                          45.304296,
                          0
                      ],
                      [
                          -74.09427,
                          45.303213,
                          0
                      ],
                      [
                          -74.090271,
                          45.300598,
                          0
                      ],
                      [
                          -74.088683,
                          45.299347,
                          0
                      ],
                      [
                          -74.084513,
                          45.296679,
                          0
                      ],
                      [
                          -74.078643,
                          45.294792,
                          0
                      ],
                      [
                          -74.075307,
                          45.294727,
                          0
                      ],
                      [
                          -74.069152,
                          45.295857,
                          0
                      ],
                      [
                          -74.063056,
                          45.297093,
                          0
                      ],
                      [
                          -74.061159,
                          45.295939,
                          0
                      ],
                      [
                          -74.061154,
                          45.295913,
                          0
                      ],
                      [
                          -74.060924,
                          45.294858,
                          0
                      ],
                      [
                          -74.058261,
                          45.293868,
                          0
                      ],
                      [
                          -74.058101,
                          45.293871,
                          0
                      ],
                      [
                          -74.053969,
                          45.294162,
                          0
                      ],
                      [
                          -74.053901,
                          45.294192,
                          0
                      ],
                      [
                          -74.051529,
                          45.295162,
                          0
                      ],
                      [
                          -74.047595,
                          45.295093,
                          0
                      ],
                      [
                          -74.042383,
                          45.295358,
                          0
                      ],
                      [
                          -74.040274,
                          45.296678,
                          0
                      ],
                      [
                          -74.034326,
                          45.300771,
                          0
                      ],
                      [
                          -74.030586,
                          45.303692,
                          0
                      ],
                      [
                          -74.027727,
                          45.304365,
                          0
                      ],
                      [
                          -74.019085,
                          45.305386,
                          0
                      ],
                      [
                          -74.013284,
                          45.307707,
                          0
                      ],
                      [
                          -74.006943,
                          45.308949,
                          0
                      ],
                      [
                          -74.000086,
                          45.309227,
                          0
                      ],
                      [
                          -73.986955,
                          45.312049,
                          0
                      ],
                      [
                          -73.980325,
                          45.314929,
                          0
                      ],
                      [
                          -73.968651,
                          45.321983,
                          0
                      ],
                      [
                          -73.963536,
                          45.323078,
                          0
                      ],
                      [
                          -73.9577,
                          45.319912,
                          0
                      ],
                      [
                          -73.95402,
                          45.319847,
                          0
                      ],
                      [
                          -73.946413,
                          45.323023,
                          0
                      ],
                      [
                          -73.939006,
                          45.325443,
                          0
                      ],
                      [
                          -73.931383,
                          45.326303,
                          0
                      ],
                      [
                          -73.916744,
                          45.332376,
                          0
                      ],
                      [
                          -73.896991,
                          45.335541,
                          0
                      ],
                      [
                          -73.878377,
                          45.338257,
                          0
                      ],
                      [
                          -73.846461,
                          45.357972,
                          0
                      ],
                      [
                          -73.846798,
                          45.338953,
                          0
                      ],
                      [
                          -73.837849,
                          45.339093,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Beauharnois",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.637837,
                          46.079094,
                          0
                      ],
                      [
                          -73.637829,
                          46.079087,
                          0
                      ],
                      [
                          -73.610296,
                          46.059355,
                          0
                      ],
                      [
                          -73.610291,
                          46.059344,
                          0
                      ],
                      [
                          -73.634595,
                          46.043313,
                          0
                      ],
                      [
                          -73.634575,
                          46.0433,
                          0
                      ],
                      [
                          -73.622317,
                          46.034543,
                          0
                      ],
                      [
                          -73.622352,
                          46.034518,
                          0
                      ],
                      [
                          -73.658051,
                          46.009733,
                          0
                      ],
                      [
                          -73.662565,
                          46.012609,
                          0
                      ],
                      [
                          -73.663863,
                          46.014017,
                          0
                      ],
                      [
                          -73.666542,
                          46.014261,
                          0
                      ],
                      [
                          -73.669229,
                          46.012911,
                          0
                      ],
                      [
                          -73.672668,
                          46.014409,
                          0
                      ],
                      [
                          -73.675905,
                          46.016012,
                          0
                      ],
                      [
                          -73.67677,
                          46.017326,
                          0
                      ],
                      [
                          -73.677914,
                          46.018162,
                          0
                      ],
                      [
                          -73.676724,
                          46.021159,
                          0
                      ],
                      [
                          -73.677672,
                          46.022934,
                          0
                      ],
                      [
                          -73.677566,
                          46.026175,
                          0
                      ],
                      [
                          -73.677566,
                          46.026175,
                          0
                      ],
                      [
                          -73.679026,
                          46.029285,
                          0
                      ],
                      [
                          -73.681784,
                          46.032346,
                          0
                      ],
                      [
                          -73.684047,
                          46.03199,
                          0
                      ],
                      [
                          -73.68602,
                          46.032825,
                          0
                      ],
                      [
                          -73.686883,
                          46.034292,
                          0
                      ],
                      [
                          -73.687904,
                          46.033726,
                          0
                      ],
                      [
                          -73.687651,
                          46.032434,
                          0
                      ],
                      [
                          -73.687459,
                          46.031571,
                          0
                      ],
                      [
                          -73.688144,
                          46.031385,
                          0
                      ],
                      [
                          -73.69147,
                          46.032158,
                          0
                      ],
                      [
                          -73.694207,
                          46.033408,
                          0
                      ],
                      [
                          -73.695532,
                          46.035013,
                          0
                      ],
                      [
                          -73.697313,
                          46.035432,
                          0
                      ],
                      [
                          -73.698799,
                          46.03456,
                          0
                      ],
                      [
                          -73.698353,
                          46.033351,
                          0
                      ],
                      [
                          -73.699005,
                          46.031167,
                          0
                      ],
                      [
                          -73.703451,
                          46.030388,
                          0
                      ],
                      [
                          -73.704328,
                          46.029043,
                          0
                      ],
                      [
                          -73.704877,
                          46.028944,
                          0
                      ],
                      [
                          -73.705037,
                          46.029011,
                          0
                      ],
                      [
                          -73.705398,
                          46.031422,
                          0
                      ],
                      [
                          -73.707748,
                          46.031171,
                          0
                      ],
                      [
                          -73.78781,
                          45.977158,
                          0
                      ],
                      [
                          -73.788017,
                          45.97702,
                          0
                      ],
                      [
                          -73.788021,
                          45.977017,
                          0
                      ],
                      [
                          -73.873396,
                          46.028259,
                          0
                      ],
                      [
                          -73.873435,
                          46.028234,
                          0
                      ],
                      [
                          -73.89867,
                          46.010525,
                          0
                      ],
                      [
                          -73.9079,
                          46.01619,
                          0
                      ],
                      [
                          -73.910478,
                          46.014477,
                          0
                      ],
                      [
                          -73.918102,
                          46.019163,
                          0
                      ],
                      [
                          -73.918589,
                          46.017721,
                          0
                      ],
                      [
                          -73.921093,
                          46.019244,
                          0
                      ],
                      [
                          -73.923609,
                          46.017453,
                          0
                      ],
                      [
                          -73.923611,
                          46.017453,
                          0
                      ],
                      [
                          -73.923624,
                          46.017443,
                          0
                      ],
                      [
                          -73.973999,
                          45.978338,
                          0
                      ],
                      [
                          -73.974012,
                          45.97833,
                          0
                      ],
                      [
                          -73.974013,
                          45.978329,
                          0
                      ],
                      [
                          -74.002335,
                          45.996972,
                          0
                      ],
                      [
                          -74.068212,
                          45.950756,
                          0
                      ],
                      [
                          -74.071211,
                          45.952816,
                          0
                      ],
                      [
                          -74.126785,
                          45.914361,
                          0
                      ],
                      [
                          -74.139757,
                          45.923127,
                          0
                      ],
                      [
                          -74.140647,
                          45.921765,
                          0
                      ],
                      [
                          -74.140807,
                          45.921248,
                          0
                      ],
                      [
                          -74.140748,
                          45.920405,
                          0
                      ],
                      [
                          -74.139844,
                          45.919929,
                          0
                      ],
                      [
                          -74.139324,
                          45.919457,
                          0
                      ],
                      [
                          -74.139191,
                          45.918953,
                          0
                      ],
                      [
                          -74.139212,
                          45.918743,
                          0
                      ],
                      [
                          -74.1401,
                          45.917407,
                          0
                      ],
                      [
                          -74.14083,
                          45.918067,
                          0
                      ],
                      [
                          -74.141083,
                          45.918731,
                          0
                      ],
                      [
                          -74.141432,
                          45.91881,
                          0
                      ],
                      [
                          -74.141869,
                          45.918744,
                          0
                      ],
                      [
                          -74.142483,
                          45.9184,
                          0
                      ],
                      [
                          -74.143046,
                          45.917919,
                          0
                      ],
                      [
                          -74.143467,
                          45.917602,
                          0
                      ],
                      [
                          -74.144237,
                          45.917328,
                          0
                      ],
                      [
                          -74.144545,
                          45.917119,
                          0
                      ],
                      [
                          -74.144502,
                          45.916814,
                          0
                      ],
                      [
                          -74.144612,
                          45.916335,
                          0
                      ],
                      [
                          -74.145004,
                          45.915622,
                          0
                      ],
                      [
                          -74.146597,
                          45.91511,
                          0
                      ],
                      [
                          -74.14724,
                          45.915026,
                          0
                      ],
                      [
                          -74.147736,
                          45.914374,
                          0
                      ],
                      [
                          -74.147731,
                          45.914032,
                          0
                      ],
                      [
                          -74.14792,
                          45.913801,
                          0
                      ],
                      [
                          -74.148312,
                          45.913646,
                          0
                      ],
                      [
                          -74.148406,
                          45.91341,
                          0
                      ],
                      [
                          -74.147727,
                          45.913183,
                          0
                      ],
                      [
                          -74.147737,
                          45.912956,
                          0
                      ],
                      [
                          -74.148066,
                          45.912823,
                          0
                      ],
                      [
                          -74.148888,
                          45.913041,
                          0
                      ],
                      [
                          -74.149753,
                          45.9131,
                          0
                      ],
                      [
                          -74.151269,
                          45.913508,
                          0
                      ],
                      [
                          -74.151667,
                          45.913687,
                          0
                      ],
                      [
                          -74.152107,
                          45.913654,
                          0
                      ],
                      [
                          -74.154248,
                          45.913144,
                          0
                      ],
                      [
                          -74.158447,
                          45.915919,
                          0
                      ],
                      [
                          -74.158471,
                          45.915901,
                          0
                      ],
                      [
                          -74.160598,
                          45.914377,
                          0
                      ],
                      [
                          -74.162217,
                          45.915479,
                          0
                      ],
                      [
                          -74.164868,
                          45.913554,
                          0
                      ],
                      [
                          -74.176328,
                          45.921562,
                          0
                      ],
                      [
                          -74.176355,
                          45.92158,
                          0
                      ],
                      [
                          -74.192133,
                          45.910871,
                          0
                      ],
                      [
                          -74.271679,
                          45.965544,
                          0
                      ],
                      [
                          -74.262006,
                          45.999547,
                          0
                      ],
                      [
                          -74.261935,
                          45.999758,
                          0
                      ],
                      [
                          -74.40763,
                          46.018673,
                          0
                      ],
                      [
                          -74.399077,
                          46.053961,
                          0
                      ],
                      [
                          -74.416107,
                          46.055644,
                          0
                      ],
                      [
                          -74.412899,
                          46.07123,
                          0
                      ],
                      [
                          -74.41289,
                          46.07128,
                          0
                      ],
                      [
                          -74.396278,
                          46.068349,
                          0
                      ],
                      [
                          -74.396271,
                          46.068347,
                          0
                      ],
                      [
                          -74.368136,
                          46.158713,
                          0
                      ],
                      [
                          -74.458695,
                          46.22095,
                          0
                      ],
                      [
                          -74.458985,
                          46.221155,
                          0
                      ],
                      [
                          -74.443276,
                          46.232429,
                          0
                      ],
                      [
                          -74.475029,
                          46.254319,
                          0
                      ],
                      [
                          -74.47506,
                          46.254342,
                          0
                      ],
                      [
                          -74.474843,
                          46.254501,
                          0
                      ],
                      [
                          -74.334155,
                          46.354,
                          0
                      ],
                      [
                          -74.334157,
                          46.354002,
                          0
                      ],
                      [
                          -74.477487,
                          46.454092,
                          0
                      ],
                      [
                          -74.343519,
                          46.549237,
                          0
                      ],
                      [
                          -73.637837,
                          46.079094,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Bertrand",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.783452,
                          45.680275,
                          0
                      ],
                      [
                          -73.781662,
                          45.681597,
                          0
                      ],
                      [
                          -73.780705,
                          45.680733,
                          0
                      ],
                      [
                          -73.778629,
                          45.681525,
                          0
                      ],
                      [
                          -73.777287,
                          45.68308,
                          0
                      ],
                      [
                          -73.777596,
                          45.685573,
                          0
                      ],
                      [
                          -73.772341,
                          45.681819,
                          0
                      ],
                      [
                          -73.767067,
                          45.685805,
                          0
                      ],
                      [
                          -73.759162,
                          45.680142,
                          0
                      ],
                      [
                          -73.757078,
                          45.681588,
                          0
                      ],
                      [
                          -73.745502,
                          45.668675,
                          0
                      ],
                      [
                          -73.745493,
                          45.668666,
                          0
                      ],
                      [
                          -73.745496,
                          45.668665,
                          0
                      ],
                      [
                          -73.745498,
                          45.668664,
                          0
                      ],
                      [
                          -73.749338,
                          45.667057,
                          0
                      ],
                      [
                          -73.75029,
                          45.663088,
                          0
                      ],
                      [
                          -73.753611,
                          45.661333,
                          0
                      ],
                      [
                          -73.757061,
                          45.65731,
                          0
                      ],
                      [
                          -73.768045,
                          45.648804,
                          0
                      ],
                      [
                          -73.768051,
                          45.648802,
                          0
                      ],
                      [
                          -73.768055,
                          45.648804,
                          0
                      ],
                      [
                          -73.77392,
                          45.652499,
                          0
                      ],
                      [
                          -73.773356,
                          45.652921,
                          0
                      ],
                      [
                          -73.775129,
                          45.654072,
                          0
                      ],
                      [
                          -73.776272,
                          45.653301,
                          0
                      ],
                      [
                          -73.793918,
                          45.664779,
                          0
                      ],
                      [
                          -73.793924,
                          45.664775,
                          0
                      ],
                      [
                          -73.802224,
                          45.658564,
                          0
                      ],
                      [
                          -73.79082,
                          45.651035,
                          0
                      ],
                      [
                          -73.793194,
                          45.651556,
                          0
                      ],
                      [
                          -73.795091,
                          45.65132,
                          0
                      ],
                      [
                          -73.796768,
                          45.650875,
                          0
                      ],
                      [
                          -73.798145,
                          45.649827,
                          0
                      ],
                      [
                          -73.79828,
                          45.649192,
                          0
                      ],
                      [
                          -73.798861,
                          45.649063,
                          0
                      ],
                      [
                          -73.799382,
                          45.649712,
                          0
                      ],
                      [
                          -73.79964,
                          45.65027,
                          0
                      ],
                      [
                          -73.799682,
                          45.650684,
                          0
                      ],
                      [
                          -73.79982,
                          45.650823,
                          0
                      ],
                      [
                          -73.801327,
                          45.651144,
                          0
                      ],
                      [
                          -73.802404,
                          45.65122,
                          0
                      ],
                      [
                          -73.804162,
                          45.650664,
                          0
                      ],
                      [
                          -73.805592,
                          45.650191,
                          0
                      ],
                      [
                          -73.805594,
                          45.65019,
                          0
                      ],
                      [
                          -73.806467,
                          45.649594,
                          0
                      ],
                      [
                          -73.806891,
                          45.649766,
                          0
                      ],
                      [
                          -73.807409,
                          45.649893,
                          0
                      ],
                      [
                          -73.807541,
                          45.649781,
                          0
                      ],
                      [
                          -73.80736,
                          45.649571,
                          0
                      ],
                      [
                          -73.806794,
                          45.6492,
                          0
                      ],
                      [
                          -73.807541,
                          45.648664,
                          0
                      ],
                      [
                          -73.806965,
                          45.647982,
                          0
                      ],
                      [
                          -73.806978,
                          45.647792,
                          0
                      ],
                      [
                          -73.807294,
                          45.647727,
                          0
                      ],
                      [
                          -73.8086,
                          45.647673,
                          0
                      ],
                      [
                          -73.809239,
                          45.647258,
                          0
                      ],
                      [
                          -73.810322,
                          45.646955,
                          0
                      ],
                      [
                          -73.811223,
                          45.645538,
                          0
                      ],
                      [
                          -73.811226,
                          45.645536,
                          0
                      ],
                      [
                          -73.812672,
                          45.644775,
                          0
                      ],
                      [
                          -73.813055,
                          45.642924,
                          0
                      ],
                      [
                          -73.813055,
                          45.642924,
                          0
                      ],
                      [
                          -73.839871,
                          45.660904,
                          0
                      ],
                      [
                          -73.839897,
                          45.660885,
                          0
                      ],
                      [
                          -73.842319,
                          45.659035,
                          0
                      ],
                      [
                          -73.8345,
                          45.65413,
                          0
                      ],
                      [
                          -73.834734,
                          45.653832,
                          0
                      ],
                      [
                          -73.833972,
                          45.653325,
                          0
                      ],
                      [
                          -73.838179,
                          45.650279,
                          0
                      ],
                      [
                          -73.840088,
                          45.651527,
                          0
                      ],
                      [
                          -73.840117,
                          45.651546,
                          0
                      ],
                      [
                          -73.840121,
                          45.649961,
                          0
                      ],
                      [
                          -73.841597,
                          45.650864,
                          0
                      ],
                      [
                          -73.842411,
                          45.649121,
                          0
                      ],
                      [
                          -73.84534,
                          45.647299,
                          0
                      ],
                      [
                          -73.85513,
                          45.653881,
                          0
                      ],
                      [
                          -73.855492,
                          45.653604,
                          0
                      ],
                      [
                          -73.855539,
                          45.653642,
                          0
                      ],
                      [
                          -73.870091,
                          45.663287,
                          0
                      ],
                      [
                          -73.880912,
                          45.655103,
                          0
                      ],
                      [
                          -73.925832,
                          45.68541,
                          0
                      ],
                      [
                          -73.913347,
                          45.694873,
                          0
                      ],
                      [
                          -73.916018,
                          45.696594,
                          0
                      ],
                      [
                          -73.916083,
                          45.696637,
                          0
                      ],
                      [
                          -73.916035,
                          45.696672,
                          0
                      ],
                      [
                          -73.908572,
                          45.702221,
                          0
                      ],
                      [
                          -73.900934,
                          45.704967,
                          0
                      ],
                      [
                          -73.898544,
                          45.70903,
                          0
                      ],
                      [
                          -73.8963,
                          45.710985,
                          0
                      ],
                      [
                          -73.894196,
                          45.711385,
                          0
                      ],
                      [
                          -73.893843,
                          45.713459,
                          0
                      ],
                      [
                          -73.892829,
                          45.715081,
                          0
                      ],
                      [
                          -73.88782,
                          45.713971,
                          0
                      ],
                      [
                          -73.886168,
                          45.714703,
                          0
                      ],
                      [
                          -73.883016,
                          45.71486,
                          0
                      ],
                      [
                          -73.881923,
                          45.715394,
                          0
                      ],
                      [
                          -73.875725,
                          45.71682,
                          0
                      ],
                      [
                          -73.875837,
                          45.719104,
                          0
                      ],
                      [
                          -73.868993,
                          45.721191,
                          0
                      ],
                      [
                          -73.865622,
                          45.721165,
                          0
                      ],
                      [
                          -73.863675,
                          45.722179,
                          0
                      ],
                      [
                          -73.857881,
                          45.722968,
                          0
                      ],
                      [
                          -73.855915,
                          45.723876,
                          0
                      ],
                      [
                          -73.850393,
                          45.724717,
                          0
                      ],
                      [
                          -73.846171,
                          45.721947,
                          0
                      ],
                      [
                          -73.783452,
                          45.680275,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Blainville",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.098151,
                          45.798577,
                          0
                      ],
                      [
                          -73.102538,
                          45.789811,
                          0
                      ],
                      [
                          -73.102926,
                          45.786818,
                          0
                      ],
                      [
                          -73.102929,
                          45.786792,
                          0
                      ],
                      [
                          -73.099145,
                          45.784036,
                          0
                      ],
                      [
                          -73.093006,
                          45.779564,
                          0
                      ],
                      [
                          -73.094716,
                          45.776412,
                          0
                      ],
                      [
                          -73.094727,
                          45.776391,
                          0
                      ],
                      [
                          -73.093786,
                          45.775679,
                          0
                      ],
                      [
                          -73.094738,
                          45.773353,
                          0
                      ],
                      [
                          -73.094745,
                          45.773338,
                          0
                      ],
                      [
                          -73.074722,
                          45.758628,
                          0
                      ],
                      [
                          -73.07467,
                          45.75883,
                          0
                      ],
                      [
                          -73.05944,
                          45.747461,
                          0
                      ],
                      [
                          -73.073928,
                          45.717807,
                          0
                      ],
                      [
                          -73.080578,
                          45.722869,
                          0
                      ],
                      [
                          -73.089343,
                          45.729375,
                          0
                      ],
                      [
                          -73.09442,
                          45.722634,
                          0
                      ],
                      [
                          -73.100884,
                          45.717455,
                          0
                      ],
                      [
                          -73.103956,
                          45.715001,
                          0
                      ],
                      [
                          -73.105058,
                          45.713577,
                          0
                      ],
                      [
                          -73.10613,
                          45.711325,
                          0
                      ],
                      [
                          -73.111735,
                          45.699467,
                          0
                      ],
                      [
                          -73.111837,
                          45.699239,
                          0
                      ],
                      [
                          -73.113993,
                          45.69473,
                          0
                      ],
                      [
                          -73.114888,
                          45.692878,
                          0
                      ],
                      [
                          -73.115541,
                          45.68907,
                          0
                      ],
                      [
                          -73.116424,
                          45.683885,
                          0
                      ],
                      [
                          -73.116942,
                          45.681071,
                          0
                      ],
                      [
                          -73.116427,
                          45.675477,
                          0
                      ],
                      [
                          -73.116888,
                          45.673672,
                          0
                      ],
                      [
                          -73.100963,
                          45.661789,
                          0
                      ],
                      [
                          -73.098805,
                          45.613642,
                          0
                      ],
                      [
                          -73.099236,
                          45.612075,
                          0
                      ],
                      [
                          -73.099234,
                          45.612075,
                          0
                      ],
                      [
                          -73.051129,
                          45.600538,
                          0
                      ],
                      [
                          -72.997926,
                          45.601477,
                          0
                      ],
                      [
                          -72.993648,
                          45.596392,
                          0
                      ],
                      [
                          -72.997735,
                          45.59214,
                          0
                      ],
                      [
                          -72.998488,
                          45.581447,
                          0
                      ],
                      [
                          -73.001262,
                          45.577826,
                          0
                      ],
                      [
                          -73.002388,
                          45.570008,
                          0
                      ],
                      [
                          -73.052425,
                          45.568518,
                          0
                      ],
                      [
                          -73.054829,
                          45.569099,
                          0
                      ],
                      [
                          -73.058648,
                          45.537577,
                          0
                      ],
                      [
                          -73.062083,
                          45.539433,
                          0
                      ],
                      [
                          -73.062104,
                          45.539389,
                          0
                      ],
                      [
                          -73.079017,
                          45.505799,
                          0
                      ],
                      [
                          -73.062567,
                          45.493854,
                          0
                      ],
                      [
                          -73.072756,
                          45.476043,
                          0
                      ],
                      [
                          -73.07277,
                          45.476019,
                          0
                      ],
                      [
                          -73.079039,
                          45.465694,
                          0
                      ],
                      [
                          -73.093591,
                          45.476618,
                          0
                      ],
                      [
                          -73.093835,
                          45.476808,
                          0
                      ],
                      [
                          -73.099883,
                          45.465772,
                          0
                      ],
                      [
                          -73.10163,
                          45.467028,
                          0
                      ],
                      [
                          -73.105189,
                          45.464163,
                          0
                      ],
                      [
                          -73.106278,
                          45.462638,
                          0
                      ],
                      [
                          -73.1122,
                          45.462398,
                          0
                      ],
                      [
                          -73.116124,
                          45.460783,
                          0
                      ],
                      [
                          -73.11961,
                          45.460467,
                          0
                      ],
                      [
                          -73.112494,
                          45.455486,
                          0
                      ],
                      [
                          -73.116943,
                          45.454792,
                          0
                      ],
                      [
                          -73.131972,
                          45.465435,
                          0
                      ],
                      [
                          -73.168104,
                          45.490492,
                          0
                      ],
                      [
                          -73.23175,
                          45.535049,
                          0
                      ],
                      [
                          -73.231755,
                          45.535052,
                          0
                      ],
                      [
                          -73.288973,
                          45.574983,
                          0
                      ],
                      [
                          -73.288977,
                          45.574986,
                          0
                      ],
                      [
                          -73.288997,
                          45.574866,
                          0
                      ],
                      [
                          -73.289005,
                          45.574815,
                          0
                      ],
                      [
                          -73.289005,
                          45.574814,
                          0
                      ],
                      [
                          -73.297049,
                          45.580604,
                          0
                      ],
                      [
                          -73.296999,
                          45.580831,
                          0
                      ],
                      [
                          -73.296998,
                          45.580833,
                          0
                      ],
                      [
                          -73.29643,
                          45.581361,
                          0
                      ],
                      [
                          -73.290534,
                          45.585599,
                          0
                      ],
                      [
                          -73.290535,
                          45.5856,
                          0
                      ],
                      [
                          -73.291232,
                          45.58609,
                          0
                      ],
                      [
                          -73.284996,
                          45.589827,
                          0
                      ],
                      [
                          -73.276628,
                          45.593199,
                          0
                      ],
                      [
                          -73.27611,
                          45.593699,
                          0
                      ],
                      [
                          -73.292447,
                          45.605164,
                          0
                      ],
                      [
                          -73.291094,
                          45.606452,
                          0
                      ],
                      [
                          -73.288579,
                          45.609162,
                          0
                      ],
                      [
                          -73.287196,
                          45.610523,
                          0
                      ],
                      [
                          -73.287066,
                          45.611104,
                          0
                      ],
                      [
                          -73.285389,
                          45.614218,
                          0
                      ],
                      [
                          -73.285012,
                          45.61579,
                          0
                      ],
                      [
                          -73.284708,
                          45.616356,
                          0
                      ],
                      [
                          -73.284091,
                          45.617512,
                          0
                      ],
                      [
                          -73.283474,
                          45.618563,
                          0
                      ],
                      [
                          -73.283029,
                          45.619366,
                          0
                      ],
                      [
                          -73.281944,
                          45.621505,
                          0
                      ],
                      [
                          -73.281578,
                          45.622151,
                          0
                      ],
                      [
                          -73.281563,
                          45.622161,
                          0
                      ],
                      [
                          -73.280527,
                          45.622922,
                          0
                      ],
                      [
                          -73.279951,
                          45.623284,
                          0
                      ],
                      [
                          -73.279713,
                          45.62349,
                          0
                      ],
                      [
                          -73.279235,
                          45.624274,
                          0
                      ],
                      [
                          -73.278938,
                          45.624813,
                          0
                      ],
                      [
                          -73.27736,
                          45.626318,
                          0
                      ],
                      [
                          -73.275599,
                          45.625013,
                          0
                      ],
                      [
                          -73.275071,
                          45.625806,
                          0
                      ],
                      [
                          -73.274293,
                          45.627127,
                          0
                      ],
                      [
                          -73.273836,
                          45.628298,
                          0
                      ],
                      [
                          -73.273595,
                          45.628981,
                          0
                      ],
                      [
                          -73.273469,
                          45.629877,
                          0
                      ],
                      [
                          -73.273007,
                          45.630712,
                          0
                      ],
                      [
                          -73.272407,
                          45.631788,
                          0
                      ],
                      [
                          -73.27196,
                          45.632598,
                          0
                      ],
                      [
                          -73.27148,
                          45.633463,
                          0
                      ],
                      [
                          -73.27071,
                          45.634018,
                          0
                      ],
                      [
                          -73.268569,
                          45.635139,
                          0
                      ],
                      [
                          -73.267402,
                          45.635807,
                          0
                      ],
                      [
                          -73.26618,
                          45.636433,
                          0
                      ],
                      [
                          -73.261064,
                          45.638696,
                          0
                      ],
                      [
                          -73.27631,
                          45.649924,
                          0
                      ],
                      [
                          -73.270165,
                          45.65348,
                          0
                      ],
                      [
                          -73.265779,
                          45.656198,
                          0
                      ],
                      [
                          -73.263038,
                          45.658801,
                          0
                      ],
                      [
                          -73.262844,
                          45.659153,
                          0
                      ],
                      [
                          -73.258189,
                          45.667578,
                          0
                      ],
                      [
                          -73.258357,
                          45.674668,
                          0
                      ],
                      [
                          -73.279523,
                          45.689936,
                          0
                      ],
                      [
                          -73.287144,
                          45.695924,
                          0
                      ],
                      [
                          -73.283538,
                          45.69813,
                          0
                      ],
                      [
                          -73.280581,
                          45.695936,
                          0
                      ],
                      [
                          -73.276829,
                          45.69847,
                          0
                      ],
                      [
                          -73.244432,
                          45.725757,
                          0
                      ],
                      [
                          -73.244062,
                          45.72621,
                          0
                      ],
                      [
                          -73.238619,
                          45.730882,
                          0
                      ],
                      [
                          -73.238617,
                          45.730882,
                          0
                      ],
                      [
                          -73.23861,
                          45.730889,
                          0
                      ],
                      [
                          -73.23861,
                          45.730889,
                          0
                      ],
                      [
                          -73.240168,
                          45.732014,
                          0
                      ],
                      [
                          -73.231057,
                          45.73745,
                          0
                      ],
                      [
                          -73.219726,
                          45.748794,
                          0
                      ],
                      [
                          -73.26358,
                          45.781264,
                          0
                      ],
                      [
                          -73.262144,
                          45.783533,
                          0
                      ],
                      [
                          -73.262115,
                          45.783511,
                          0
                      ],
                      [
                          -73.260027,
                          45.781948,
                          0
                      ],
                      [
                          -73.259929,
                          45.782011,
                          0
                      ],
                      [
                          -73.257786,
                          45.783383,
                          0
                      ],
                      [
                          -73.25961,
                          45.784625,
                          0
                      ],
                      [
                          -73.268593,
                          45.791201,
                          0
                      ],
                      [
                          -73.264619,
                          45.79295,
                          0
                      ],
                      [
                          -73.263708,
                          45.792277,
                          0
                      ],
                      [
                          -73.261943,
                          45.793839,
                          0
                      ],
                      [
                          -73.258641,
                          45.794567,
                          0
                      ],
                      [
                          -73.256063,
                          45.795571,
                          0
                      ],
                      [
                          -73.254083,
                          45.79689,
                          0
                      ],
                      [
                          -73.251634,
                          45.799808,
                          0
                      ],
                      [
                          -73.249468,
                          45.801906,
                          0
                      ],
                      [
                          -73.246942,
                          45.800032,
                          0
                      ],
                      [
                          -73.246935,
                          45.800027,
                          0
                      ],
                      [
                          -73.240889,
                          45.804374,
                          0
                      ],
                      [
                          -73.242683,
                          45.805735,
                          0
                      ],
                      [
                          -73.242679,
                          45.80651,
                          0
                      ],
                      [
                          -73.241666,
                          45.807665,
                          0
                      ],
                      [
                          -73.242301,
                          45.808177,
                          0
                      ],
                      [
                          -73.228807,
                          45.816515,
                          0
                      ],
                      [
                          -73.226479,
                          45.814827,
                          0
                      ],
                      [
                          -73.218083,
                          45.825382,
                          0
                      ],
                      [
                          -73.217723,
                          45.825402,
                          0
                      ],
                      [
                          -73.217243,
                          45.82619,
                          0
                      ],
                      [
                          -73.212598,
                          45.834672,
                          0
                      ],
                      [
                          -73.209192,
                          45.838049,
                          0
                      ],
                      [
                          -73.209182,
                          45.838063,
                          0
                      ],
                      [
                          -73.206457,
                          45.842109,
                          0
                      ],
                      [
                          -73.193632,
                          45.850873,
                          0
                      ],
                      [
                          -73.188261,
                          45.859195,
                          0
                      ],
                      [
                          -73.18821,
                          45.859225,
                          0
                      ],
                      [
                          -73.184769,
                          45.861364,
                          0
                      ],
                      [
                          -73.184718,
                          45.861395,
                          0
                      ],
                      [
                          -73.098151,
                          45.798577,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Borduas",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.604197,
                          45.610608,
                          0
                      ],
                      [
                          -73.603542,
                          45.610015,
                          0
                      ],
                      [
                          -73.603542,
                          45.610015,
                          0
                      ],
                      [
                          -73.604741,
                          45.60937,
                          0
                      ],
                      [
                          -73.604742,
                          45.609369,
                          0
                      ],
                      [
                          -73.606173,
                          45.610719,
                          0
                      ],
                      [
                          -73.608816,
                          45.608522,
                          0
                      ],
                      [
                          -73.613621,
                          45.602935,
                          0
                      ],
                      [
                          -73.629643,
                          45.583748,
                          0
                      ],
                      [
                          -73.628951,
                          45.58323,
                          0
                      ],
                      [
                          -73.631946,
                          45.579715,
                          0
                      ],
                      [
                          -73.632984,
                          45.580119,
                          0
                      ],
                      [
                          -73.636516,
                          45.575937,
                          0
                      ],
                      [
                          -73.640014,
                          45.577406,
                          0
                      ],
                      [
                          -73.650321,
                          45.583897,
                          0
                      ],
                      [
                          -73.64872,
                          45.587753,
                          0
                      ],
                      [
                          -73.647883,
                          45.589401,
                          0
                      ],
                      [
                          -73.642592,
                          45.595832,
                          0
                      ],
                      [
                          -73.644488,
                          45.597583,
                          0
                      ],
                      [
                          -73.646745,
                          45.599181,
                          0
                      ],
                      [
                          -73.641283,
                          45.609069,
                          0
                      ],
                      [
                          -73.636329,
                          45.620637,
                          0
                      ],
                      [
                          -73.624576,
                          45.633092,
                          0
                      ],
                      [
                          -73.60263,
                          45.61288,
                          0
                      ],
                      [
                          -73.601656,
                          45.611971,
                          0
                      ],
                      [
                          -73.60321,
                          45.611155,
                          0
                      ],
                      [
                          -73.604197,
                          45.610608,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Bourassa-Sauvé",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.499456,
                          45.609371,
                          0
                      ],
                      [
                          -73.500082,
                          45.595417,
                          0
                      ],
                      [
                          -73.49884,
                          45.588198,
                          0
                      ],
                      [
                          -73.497372,
                          45.58289,
                          0
                      ],
                      [
                          -73.512173,
                          45.555364,
                          0
                      ],
                      [
                          -73.552548,
                          45.568224,
                          0
                      ],
                      [
                          -73.548988,
                          45.573467,
                          0
                      ],
                      [
                          -73.536984,
                          45.593225,
                          0
                      ],
                      [
                          -73.541762,
                          45.594192,
                          0
                      ],
                      [
                          -73.543454,
                          45.594799,
                          0
                      ],
                      [
                          -73.545391,
                          45.595604,
                          0
                      ],
                      [
                          -73.543854,
                          45.598087,
                          0
                      ],
                      [
                          -73.544228,
                          45.598206,
                          0
                      ],
                      [
                          -73.544299,
                          45.598483,
                          0
                      ],
                      [
                          -73.54454,
                          45.59941,
                          0
                      ],
                      [
                          -73.544828,
                          45.59955,
                          0
                      ],
                      [
                          -73.544484,
                          45.600652,
                          0
                      ],
                      [
                          -73.54481,
                          45.600742,
                          0
                      ],
                      [
                          -73.544878,
                          45.600943,
                          0
                      ],
                      [
                          -73.545247,
                          45.601101,
                          0
                      ],
                      [
                          -73.544728,
                          45.601906,
                          0
                      ],
                      [
                          -73.544728,
                          45.601906,
                          0
                      ],
                      [
                          -73.540701,
                          45.600678,
                          0
                      ],
                      [
                          -73.539798,
                          45.601809,
                          0
                      ],
                      [
                          -73.542949,
                          45.603197,
                          0
                      ],
                      [
                          -73.542183,
                          45.604183,
                          0
                      ],
                      [
                          -73.539493,
                          45.603036,
                          0
                      ],
                      [
                          -73.539302,
                          45.603263,
                          0
                      ],
                      [
                          -73.536586,
                          45.602138,
                          0
                      ],
                      [
                          -73.536747,
                          45.602698,
                          0
                      ],
                      [
                          -73.53652,
                          45.602632,
                          0
                      ],
                      [
                          -73.537158,
                          45.60459,
                          0
                      ],
                      [
                          -73.537771,
                          45.604784,
                          0
                      ],
                      [
                          -73.537546,
                          45.60518,
                          0
                      ],
                      [
                          -73.541584,
                          45.606931,
                          0
                      ],
                      [
                          -73.540826,
                          45.607838,
                          0
                      ],
                      [
                          -73.541571,
                          45.60816,
                          0
                      ],
                      [
                          -73.541369,
                          45.608871,
                          0
                      ],
                      [
                          -73.541082,
                          45.609506,
                          0
                      ],
                      [
                          -73.543159,
                          45.610013,
                          0
                      ],
                      [
                          -73.545336,
                          45.610741,
                          0
                      ],
                      [
                          -73.545082,
                          45.611134,
                          0
                      ],
                      [
                          -73.544587,
                          45.612198,
                          0
                      ],
                      [
                          -73.546318,
                          45.612703,
                          0
                      ],
                      [
                          -73.545961,
                          45.613033,
                          0
                      ],
                      [
                          -73.545749,
                          45.61336,
                          0
                      ],
                      [
                          -73.545396,
                          45.614108,
                          0
                      ],
                      [
                          -73.544097,
                          45.615054,
                          0
                      ],
                      [
                          -73.537868,
                          45.613906,
                          0
                      ],
                      [
                          -73.537186,
                          45.615837,
                          0
                      ],
                      [
                          -73.499456,
                          45.609371,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Bourget",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -72.634549,
                          45.418523,
                          0
                      ],
                      [
                          -72.63455,
                          45.418435,
                          0
                      ],
                      [
                          -72.634546,
                          45.405584,
                          0
                      ],
                      [
                          -72.597015,
                          45.40324,
                          0
                      ],
                      [
                          -72.597036,
                          45.403203,
                          0
                      ],
                      [
                          -72.603057,
                          45.39268,
                          0
                      ],
                      [
                          -72.462313,
                          45.383445,
                          0
                      ],
                      [
                          -72.461348,
                          45.383421,
                          0
                      ],
                      [
                          -72.461348,
                          45.38342,
                          0
                      ],
                      [
                          -72.464947,
                          45.331446,
                          0
                      ],
                      [
                          -72.46209,
                          45.307423,
                          0
                      ],
                      [
                          -72.462028,
                          45.307424,
                          0
                      ],
                      [
                          -72.462,
                          45.307425,
                          0
                      ],
                      [
                          -72.400213,
                          45.308032,
                          0
                      ],
                      [
                          -72.403398,
                          45.155722,
                          0
                      ],
                      [
                          -72.403398,
                          45.155721,
                          0
                      ],
                      [
                          -72.479425,
                          45.15767,
                          0
                      ],
                      [
                          -72.479638,
                          45.157659,
                          0
                      ],
                      [
                          -72.479638,
                          45.157659,
                          0
                      ],
                      [
                          -72.479949,
                          45.157642,
                          0
                      ],
                      [
                          -72.485071,
                          45.008774,
                          0
                      ],
                      [
                          -72.485073,
                          45.008727,
                          0
                      ],
                      [
                          -72.485073,
                          45.008725,
                          0
                      ],
                      [
                          -72.555387,
                          45.00802,
                          0
                      ],
                      [
                          -72.618063,
                          45.013829,
                          0
                      ],
                      [
                          -72.867115,
                          45.01629,
                          0
                      ],
                      [
                          -72.868392,
                          45.016228,
                          0
                      ],
                      [
                          -72.966864,
                          45.014142,
                          0
                      ],
                      [
                          -73.090802,
                          45.01533,
                          0
                      ],
                      [
                          -73.139867,
                          45.014408,
                          0
                      ],
                      [
                          -73.139887,
                          45.014407,
                          0
                      ],
                      [
                          -73.110696,
                          45.074427,
                          0
                      ],
                      [
                          -73.104495,
                          45.082061,
                          0
                      ],
                      [
                          -73.104478,
                          45.082082,
                          0
                      ],
                      [
                          -73.11521,
                          45.081848,
                          0
                      ],
                      [
                          -73.115828,
                          45.09712,
                          0
                      ],
                      [
                          -73.115746,
                          45.09712,
                          0
                      ],
                      [
                          -73.10757,
                          45.097149,
                          0
                      ],
                      [
                          -73.106973,
                          45.104506,
                          0
                      ],
                      [
                          -73.106973,
                          45.10451,
                          0
                      ],
                      [
                          -73.106917,
                          45.104483,
                          0
                      ],
                      [
                          -73.098344,
                          45.100247,
                          0
                      ],
                      [
                          -73.097158,
                          45.101575,
                          0
                      ],
                      [
                          -73.097158,
                          45.101577,
                          0
                      ],
                      [
                          -73.09716,
                          45.101594,
                          0
                      ],
                      [
                          -73.099521,
                          45.125021,
                          0
                      ],
                      [
                          -73.099388,
                          45.125023,
                          0
                      ],
                      [
                          -73.099308,
                          45.125024,
                          0
                      ],
                      [
                          -73.101242,
                          45.140068,
                          0
                      ],
                      [
                          -73.101243,
                          45.140069,
                          0
                      ],
                      [
                          -73.101243,
                          45.14007,
                          0
                      ],
                      [
                          -73.085972,
                          45.140101,
                          0
                      ],
                      [
                          -73.085884,
                          45.140101,
                          0
                      ],
                      [
                          -73.085884,
                          45.140105,
                          0
                      ],
                      [
                          -73.085899,
                          45.15117,
                          0
                      ],
                      [
                          -73.085864,
                          45.191662,
                          0
                      ],
                      [
                          -73.090912,
                          45.19628,
                          0
                      ],
                      [
                          -73.058271,
                          45.214589,
                          0
                      ],
                      [
                          -73.058271,
                          45.21459,
                          0
                      ],
                      [
                          -73.06373,
                          45.219512,
                          0
                      ],
                      [
                          -73.064633,
                          45.220377,
                          0
                      ],
                      [
                          -73.064882,
                          45.220748,
                          0
                      ],
                      [
                          -73.073982,
                          45.228839,
                          0
                      ],
                      [
                          -73.073987,
                          45.228843,
                          0
                      ],
                      [
                          -73.070171,
                          45.231035,
                          0
                      ],
                      [
                          -73.068106,
                          45.229607,
                          0
                      ],
                      [
                          -73.066788,
                          45.245534,
                          0
                      ],
                      [
                          -73.066783,
                          45.245531,
                          0
                      ],
                      [
                          -73.065452,
                          45.244619,
                          0
                      ],
                      [
                          -73.06545,
                          45.244618,
                          0
                      ],
                      [
                          -73.064078,
                          45.262117,
                          0
                      ],
                      [
                          -73.063536,
                          45.267814,
                          0
                      ],
                      [
                          -73.063533,
                          45.267827,
                          0
                      ],
                      [
                          -73.063534,
                          45.26783,
                          0
                      ],
                      [
                          -73.058931,
                          45.265097,
                          0
                      ],
                      [
                          -73.058931,
                          45.265101,
                          0
                      ],
                      [
                          -73.05893,
                          45.265106,
                          0
                      ],
                      [
                          -73.058258,
                          45.273183,
                          0
                      ],
                      [
                          -73.05051,
                          45.268127,
                          0
                      ],
                      [
                          -73.049912,
                          45.277662,
                          0
                      ],
                      [
                          -73.048886,
                          45.276889,
                          0
                      ],
                      [
                          -73.048882,
                          45.276892,
                          0
                      ],
                      [
                          -73.04702,
                          45.277707,
                          0
                      ],
                      [
                          -73.045577,
                          45.281312,
                          0
                      ],
                      [
                          -73.046189,
                          45.293373,
                          0
                      ],
                      [
                          -73.015657,
                          45.272022,
                          0
                      ],
                      [
                          -73.014491,
                          45.286071,
                          0
                      ],
                      [
                          -73.013981,
                          45.289047,
                          0
                      ],
                      [
                          -73.016112,
                          45.28932,
                          0
                      ],
                      [
                          -73.047805,
                          45.311292,
                          0
                      ],
                      [
                          -73.047812,
                          45.311297,
                          0
                      ],
                      [
                          -73.047819,
                          45.311301,
                          0
                      ],
                      [
                          -73.04886,
                          45.325315,
                          0
                      ],
                      [
                          -73.04886,
                          45.325316,
                          0
                      ],
                      [
                          -73.046297,
                          45.326057,
                          0
                      ],
                      [
                          -73.033846,
                          45.325623,
                          0
                      ],
                      [
                          -73.010351,
                          45.330159,
                          0
                      ],
                      [
                          -73.01034,
                          45.330161,
                          0
                      ],
                      [
                          -73.009651,
                          45.337525,
                          0
                      ],
                      [
                          -73.009651,
                          45.337525,
                          0
                      ],
                      [
                          -72.994411,
                          45.337953,
                          0
                      ],
                      [
                          -72.994409,
                          45.337952,
                          0
                      ],
                      [
                          -72.989285,
                          45.334547,
                          0
                      ],
                      [
                          -72.989283,
                          45.334552,
                          0
                      ],
                      [
                          -72.990147,
                          45.336306,
                          0
                      ],
                      [
                          -72.989424,
                          45.338674,
                          0
                      ],
                      [
                          -72.990098,
                          45.340059,
                          0
                      ],
                      [
                          -72.989917,
                          45.341838,
                          0
                      ],
                      [
                          -72.989917,
                          45.341838,
                          0
                      ],
                      [
                          -72.928142,
                          45.298664,
                          0
                      ],
                      [
                          -72.928141,
                          45.298668,
                          0
                      ],
                      [
                          -72.928136,
                          45.298684,
                          0
                      ],
                      [
                          -72.927425,
                          45.300743,
                          0
                      ],
                      [
                          -72.927425,
                          45.300744,
                          0
                      ],
                      [
                          -72.924418,
                          45.300807,
                          0
                      ],
                      [
                          -72.924396,
                          45.30079,
                          0
                      ],
                      [
                          -72.906434,
                          45.28779,
                          0
                      ],
                      [
                          -72.906431,
                          45.287788,
                          0
                      ],
                      [
                          -72.908072,
                          45.287655,
                          0
                      ],
                      [
                          -72.909949,
                          45.288494,
                          0
                      ],
                      [
                          -72.911639,
                          45.290069,
                          0
                      ],
                      [
                          -72.912982,
                          45.290235,
                          0
                      ],
                      [
                          -72.913641,
                          45.289359,
                          0
                      ],
                      [
                          -72.912429,
                          45.287794,
                          0
                      ],
                      [
                          -72.912825,
                          45.286735,
                          0
                      ],
                      [
                          -72.909251,
                          45.284084,
                          0
                      ],
                      [
                          -72.907967,
                          45.284075,
                          0
                      ],
                      [
                          -72.907966,
                          45.284075,
                          0
                      ],
                      [
                          -72.907961,
                          45.282806,
                          0
                      ],
                      [
                          -72.883017,
                          45.265549,
                          0
                      ],
                      [
                          -72.851421,
                          45.353784,
                          0
                      ],
                      [
                          -72.849862,
                          45.35717,
                          0
                      ],
                      [
                          -72.84582,
                          45.368438,
                          0
                      ],
                      [
                          -72.812966,
                          45.368398,
                          0
                      ],
                      [
                          -72.816743,
                          45.355073,
                          0
                      ],
                      [
                          -72.75472,
                          45.354545,
                          0
                      ],
                      [
                          -72.756356,
                          45.341024,
                          0
                      ],
                      [
                          -72.667706,
                          45.337874,
                          0
                      ],
                      [
                          -72.664287,
                          45.420423,
                          0
                      ],
                      [
                          -72.664283,
                          45.420559,
                          0
                      ],
                      [
                          -72.634549,
                          45.418523,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Brome-Missisquoi",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.231755,
                          45.535052,
                          0
                      ],
                      [
                          -73.23175,
                          45.535049,
                          0
                      ],
                      [
                          -73.168104,
                          45.490492,
                          0
                      ],
                      [
                          -73.168078,
                          45.489463,
                          0
                      ],
                      [
                          -73.168757,
                          45.488511,
                          0
                      ],
                      [
                          -73.170638,
                          45.486737,
                          0
                      ],
                      [
                          -73.171409,
                          45.486147,
                          0
                      ],
                      [
                          -73.169327,
                          45.484038,
                          0
                      ],
                      [
                          -73.169254,
                          45.477328,
                          0
                      ],
                      [
                          -73.171192,
                          45.474172,
                          0
                      ],
                      [
                          -73.173313,
                          45.47385,
                          0
                      ],
                      [
                          -73.175565,
                          45.471678,
                          0
                      ],
                      [
                          -73.175568,
                          45.471679,
                          0
                      ],
                      [
                          -73.18407,
                          45.474456,
                          0
                      ],
                      [
                          -73.184685,
                          45.474915,
                          0
                      ],
                      [
                          -73.185061,
                          45.476074,
                          0
                      ],
                      [
                          -73.186534,
                          45.475221,
                          0
                      ],
                      [
                          -73.188388,
                          45.474996,
                          0
                      ],
                      [
                          -73.188,
                          45.474692,
                          0
                      ],
                      [
                          -73.185125,
                          45.473687,
                          0
                      ],
                      [
                          -73.188329,
                          45.471585,
                          0
                      ],
                      [
                          -73.197438,
                          45.472238,
                          0
                      ],
                      [
                          -73.195327,
                          45.470518,
                          0
                      ],
                      [
                          -73.196519,
                          45.46402,
                          0
                      ],
                      [
                          -73.198778,
                          45.46263,
                          0
                      ],
                      [
                          -73.199709,
                          45.461505,
                          0
                      ],
                      [
                          -73.197784,
                          45.461399,
                          0
                      ],
                      [
                          -73.197913,
                          45.460947,
                          0
                      ],
                      [
                          -73.198463,
                          45.460989,
                          0
                      ],
                      [
                          -73.198756,
                          45.458795,
                          0
                      ],
                      [
                          -73.198256,
                          45.457676,
                          0
                      ],
                      [
                          -73.20261,
                          45.453444,
                          0
                      ],
                      [
                          -73.198939,
                          45.449753,
                          0
                      ],
                      [
                          -73.20314,
                          45.443355,
                          0
                      ],
                      [
                          -73.20598,
                          45.443371,
                          0
                      ],
                      [
                          -73.204464,
                          45.440778,
                          0
                      ],
                      [
                          -73.201559,
                          45.436947,
                          0
                      ],
                      [
                          -73.200222,
                          45.433799,
                          0
                      ],
                      [
                          -73.202366,
                          45.433493,
                          0
                      ],
                      [
                          -73.201686,
                          45.431938,
                          0
                      ],
                      [
                          -73.202257,
                          45.428609,
                          0
                      ],
                      [
                          -73.202376,
                          45.424058,
                          0
                      ],
                      [
                          -73.203062,
                          45.422113,
                          0
                      ],
                      [
                          -73.204504,
                          45.42208,
                          0
                      ],
                      [
                          -73.211138,
                          45.42121,
                          0
                      ],
                      [
                          -73.211133,
                          45.421207,
                          0
                      ],
                      [
                          -73.196826,
                          45.411961,
                          0
                      ],
                      [
                          -73.192537,
                          45.404237,
                          0
                      ],
                      [
                          -73.196857,
                          45.402941,
                          0
                      ],
                      [
                          -73.198757,
                          45.37972,
                          0
                      ],
                      [
                          -73.198759,
                          45.379715,
                          0
                      ],
                      [
                          -73.210115,
                          45.371476,
                          0
                      ],
                      [
                          -73.210125,
                          45.371469,
                          0
                      ],
                      [
                          -73.210127,
                          45.37147,
                          0
                      ],
                      [
                          -73.216791,
                          45.376431,
                          0
                      ],
                      [
                          -73.22141,
                          45.373558,
                          0
                      ],
                      [
                          -73.221401,
                          45.373531,
                          0
                      ],
                      [
                          -73.220147,
                          45.369542,
                          0
                      ],
                      [
                          -73.219215,
                          45.365819,
                          0
                      ],
                      [
                          -73.219383,
                          45.363161,
                          0
                      ],
                      [
                          -73.219388,
                          45.36315,
                          0
                      ],
                      [
                          -73.220014,
                          45.362059,
                          0
                      ],
                      [
                          -73.220015,
                          45.362061,
                          0
                      ],
                      [
                          -73.249914,
                          45.383726,
                          0
                      ],
                      [
                          -73.250184,
                          45.384785,
                          0
                      ],
                      [
                          -73.251601,
                          45.38424,
                          0
                      ],
                      [
                          -73.252442,
                          45.385791,
                          0
                      ],
                      [
                          -73.254775,
                          45.384939,
                          0
                      ],
                      [
                          -73.25485,
                          45.384693,
                          0
                      ],
                      [
                          -73.255141,
                          45.384208,
                          0
                      ],
                      [
                          -73.255593,
                          45.383654,
                          0
                      ],
                      [
                          -73.256473,
                          45.383628,
                          0
                      ],
                      [
                          -73.256522,
                          45.383185,
                          0
                      ],
                      [
                          -73.256432,
                          45.382895,
                          0
                      ],
                      [
                          -73.256421,
                          45.382708,
                          0
                      ],
                      [
                          -73.25655,
                          45.382572,
                          0
                      ],
                      [
                          -73.257016,
                          45.382243,
                          0
                      ],
                      [
                          -73.274782,
                          45.382631,
                          0
                      ],
                      [
                          -73.308344,
                          45.406131,
                          0
                      ],
                      [
                          -73.308344,
                          45.406131,
                          0
                      ],
                      [
                          -73.312549,
                          45.40047,
                          0
                      ],
                      [
                          -73.323219,
                          45.391963,
                          0
                      ],
                      [
                          -73.323221,
                          45.39194,
                          0
                      ],
                      [
                          -73.323747,
                          45.38911,
                          0
                      ],
                      [
                          -73.326655,
                          45.382973,
                          0
                      ],
                      [
                          -73.338664,
                          45.385497,
                          0
                      ],
                      [
                          -73.347378,
                          45.390315,
                          0
                      ],
                      [
                          -73.347378,
                          45.390315,
                          0
                      ],
                      [
                          -73.347311,
                          45.389113,
                          0
                      ],
                      [
                          -73.34869,
                          45.386089,
                          0
                      ],
                      [
                          -73.348693,
                          45.386092,
                          0
                      ],
                      [
                          -73.379361,
                          45.408194,
                          0
                      ],
                      [
                          -73.379364,
                          45.408197,
                          0
                      ],
                      [
                          -73.381241,
                          45.409018,
                          0
                      ],
                      [
                          -73.386018,
                          45.412414,
                          0
                      ],
                      [
                          -73.386899,
                          45.413047,
                          0
                      ],
                      [
                          -73.383237,
                          45.415626,
                          0
                      ],
                      [
                          -73.38201,
                          45.417051,
                          0
                      ],
                      [
                          -73.383293,
                          45.420207,
                          0
                      ],
                      [
                          -73.382053,
                          45.42434,
                          0
                      ],
                      [
                          -73.385621,
                          45.426902,
                          0
                      ],
                      [
                          -73.38243,
                          45.430874,
                          0
                      ],
                      [
                          -73.379884,
                          45.432639,
                          0
                      ],
                      [
                          -73.377527,
                          45.434365,
                          0
                      ],
                      [
                          -73.379079,
                          45.435467,
                          0
                      ],
                      [
                          -73.37747,
                          45.436595,
                          0
                      ],
                      [
                          -73.378985,
                          45.437703,
                          0
                      ],
                      [
                          -73.376619,
                          45.438378,
                          0
                      ],
                      [
                          -73.372804,
                          45.437995,
                          0
                      ],
                      [
                          -73.37534,
                          45.442091,
                          0
                      ],
                      [
                          -73.37563,
                          45.4423,
                          0
                      ],
                      [
                          -73.374086,
                          45.443477,
                          0
                      ],
                      [
                          -73.373563,
                          45.443101,
                          0
                      ],
                      [
                          -73.371998,
                          45.444296,
                          0
                      ],
                      [
                          -73.371495,
                          45.443928,
                          0
                      ],
                      [
                          -73.36832,
                          45.446217,
                          0
                      ],
                      [
                          -73.369409,
                          45.446984,
                          0
                      ],
                      [
                          -73.367718,
                          45.448283,
                          0
                      ],
                      [
                          -73.363399,
                          45.444994,
                          0
                      ],
                      [
                          -73.363396,
                          45.444995,
                          0
                      ],
                      [
                          -73.363015,
                          45.445102,
                          0
                      ],
                      [
                          -73.346627,
                          45.46707,
                          0
                      ],
                      [
                          -73.346362,
                          45.466904,
                          0
                      ],
                      [
                          -73.338627,
                          45.482124,
                          0
                      ],
                      [
                          -73.335327,
                          45.479648,
                          0
                      ],
                      [
                          -73.335314,
                          45.479686,
                          0
                      ],
                      [
                          -73.326758,
                          45.504739,
                          0
                      ],
                      [
                          -73.331888,
                          45.508634,
                          0
                      ],
                      [
                          -73.326888,
                          45.511857,
                          0
                      ],
                      [
                          -73.326538,
                          45.511579,
                          0
                      ],
                      [
                          -73.318064,
                          45.515323,
                          0
                      ],
                      [
                          -73.316777,
                          45.515494,
                          0
                      ],
                      [
                          -73.316196,
                          45.515835,
                          0
                      ],
                      [
                          -73.312484,
                          45.520269,
                          0
                      ],
                      [
                          -73.311838,
                          45.521846,
                          0
                      ],
                      [
                          -73.310015,
                          45.522406,
                          0
                      ],
                      [
                          -73.308714,
                          45.524127,
                          0
                      ],
                      [
                          -73.300839,
                          45.534701,
                          0
                      ],
                      [
                          -73.294821,
                          45.540178,
                          0
                      ],
                      [
                          -73.294771,
                          45.540439,
                          0
                      ],
                      [
                          -73.292775,
                          45.552707,
                          0
                      ],
                      [
                          -73.292625,
                          45.553594,
                          0
                      ],
                      [
                          -73.292587,
                          45.55378,
                          0
                      ],
                      [
                          -73.289005,
                          45.574814,
                          0
                      ],
                      [
                          -73.289005,
                          45.574815,
                          0
                      ],
                      [
                          -73.288997,
                          45.574866,
                          0
                      ],
                      [
                          -73.288977,
                          45.574986,
                          0
                      ],
                      [
                          -73.288973,
                          45.574983,
                          0
                      ],
                      [
                          -73.231755,
                          45.535052,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Chambly",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -72.167646,
                          46.874268,
                          0
                      ],
                      [
                          -72.228684,
                          46.835173,
                          0
                      ],
                      [
                          -72.228685,
                          46.835172,
                          0
                      ],
                      [
                          -72.228685,
                          46.835172,
                          0
                      ],
                      [
                          -72.228685,
                          46.835172,
                          0
                      ],
                      [
                          -72.23372,
                          46.838375,
                          0
                      ],
                      [
                          -72.248495,
                          46.828394,
                          0
                      ],
                      [
                          -72.248495,
                          46.828394,
                          0
                      ],
                      [
                          -72.248495,
                          46.828394,
                          0
                      ],
                      [
                          -72.248496,
                          46.828395,
                          0
                      ],
                      [
                          -72.272422,
                          46.844919,
                          0
                      ],
                      [
                          -72.272449,
                          46.844936,
                          0
                      ],
                      [
                          -72.272449,
                          46.844936,
                          0
                      ],
                      [
                          -72.272449,
                          46.844936,
                          0
                      ],
                      [
                          -72.298149,
                          46.82882,
                          0
                      ],
                      [
                          -72.298149,
                          46.82882,
                          0
                      ],
                      [
                          -72.305854,
                          46.833785,
                          0
                      ],
                      [
                          -72.305854,
                          46.833785,
                          0
                      ],
                      [
                          -72.305854,
                          46.833785,
                          0
                      ],
                      [
                          -72.334203,
                          46.814437,
                          0
                      ],
                      [
                          -72.334203,
                          46.814437,
                          0
                      ],
                      [
                          -72.345075,
                          46.822429,
                          0
                      ],
                      [
                          -72.393284,
                          46.79195,
                          0
                      ],
                      [
                          -72.083729,
                          46.570207,
                          0
                      ],
                      [
                          -72.083729,
                          46.570207,
                          0
                      ],
                      [
                          -72.139706,
                          46.563235,
                          0
                      ],
                      [
                          -72.139709,
                          46.563234,
                          0
                      ],
                      [
                          -72.179793,
                          46.545744,
                          0
                      ],
                      [
                          -72.218443,
                          46.515347,
                          0
                      ],
                      [
                          -72.249994,
                          46.44557,
                          0
                      ],
                      [
                          -72.294472,
                          46.424405,
                          0
                      ],
                      [
                          -72.332948,
                          46.421396,
                          0
                      ],
                      [
                          -72.378722,
                          46.410147,
                          0
                      ],
                      [
                          -72.457692,
                          46.382343,
                          0
                      ],
                      [
                          -72.475454,
                          46.37196,
                          0
                      ],
                      [
                          -72.494493,
                          46.355354,
                          0
                      ],
                      [
                          -72.494586,
                          46.355291,
                          0
                      ],
                      [
                          -72.494586,
                          46.355291,
                          0
                      ],
                      [
                          -72.522618,
                          46.360595,
                          0
                      ],
                      [
                          -72.530926,
                          46.361111,
                          0
                      ],
                      [
                          -72.534089,
                          46.362832,
                          0
                      ],
                      [
                          -72.536406,
                          46.36756,
                          0
                      ],
                      [
                          -72.539099,
                          46.368139,
                          0
                      ],
                      [
                          -72.553237,
                          46.366338,
                          0
                      ],
                      [
                          -72.574153,
                          46.368804,
                          0
                      ],
                      [
                          -72.577483,
                          46.369597,
                          0
                      ],
                      [
                          -72.582417,
                          46.372224,
                          0
                      ],
                      [
                          -72.591905,
                          46.37482,
                          0
                      ],
                      [
                          -72.60446,
                          46.37526,
                          0
                      ],
                      [
                          -72.607483,
                          46.377201,
                          0
                      ],
                      [
                          -72.611687,
                          46.385754,
                          0
                      ],
                      [
                          -72.622038,
                          46.393706,
                          0
                      ],
                      [
                          -72.628043,
                          46.397126,
                          0
                      ],
                      [
                          -72.641855,
                          46.392685,
                          0
                      ],
                      [
                          -72.660183,
                          46.403421,
                          0
                      ],
                      [
                          -72.671938,
                          46.407007,
                          0
                      ],
                      [
                          -72.679101,
                          46.410845,
                          0
                      ],
                      [
                          -72.588754,
                          46.474483,
                          0
                      ],
                      [
                          -72.618762,
                          46.49586,
                          0
                      ],
                      [
                          -72.604485,
                          46.505683,
                          0
                      ],
                      [
                          -72.593994,
                          46.505734,
                          0
                      ],
                      [
                          -72.53605,
                          46.547134,
                          0
                      ],
                      [
                          -72.536023,
                          46.547153,
                          0
                      ],
                      [
                          -72.614067,
                          46.602043,
                          0
                      ],
                      [
                          -72.583126,
                          46.623994,
                          0
                      ],
                      [
                          -72.655563,
                          46.670633,
                          0
                      ],
                      [
                          -72.642932,
                          46.67923,
                          0
                      ],
                      [
                          -72.682451,
                          46.706544,
                          0
                      ],
                      [
                          -72.671566,
                          46.713886,
                          0
                      ],
                      [
                          -72.673892,
                          46.715482,
                          0
                      ],
                      [
                          -72.6711,
                          46.717317,
                          0
                      ],
                      [
                          -72.674196,
                          46.719961,
                          0
                      ],
                      [
                          -72.674522,
                          46.721321,
                          0
                      ],
                      [
                          -72.673566,
                          46.721969,
                          0
                      ],
                      [
                          -72.664302,
                          46.715688,
                          0
                      ],
                      [
                          -72.631589,
                          46.737859,
                          0
                      ],
                      [
                          -72.640487,
                          46.743998,
                          0
                      ],
                      [
                          -72.587993,
                          46.78267,
                          0
                      ],
                      [
                          -72.715336,
                          46.866968,
                          0
                      ],
                      [
                          -72.715297,
                          46.866996,
                          0
                      ],
                      [
                          -72.612652,
                          46.940482,
                          0
                      ],
                      [
                          -72.565381,
                          46.908525,
                          0
                      ],
                      [
                          -72.515623,
                          46.944963,
                          0
                      ],
                      [
                          -72.560134,
                          46.97089,
                          0
                      ],
                      [
                          -72.43805,
                          47.057587,
                          0
                      ],
                      [
                          -72.438049,
                          47.057588,
                          0
                      ],
                      [
                          -72.167646,
                          46.874268,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Champlain",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -75.611795,
                          45.493778,
                          0
                      ],
                      [
                          -75.630936,
                          45.492935,
                          0
                      ],
                      [
                          -75.64096,
                          45.49081,
                          0
                      ],
                      [
                          -75.640961,
                          45.490807,
                          0
                      ],
                      [
                          -75.63975,
                          45.4846,
                          0
                      ],
                      [
                          -75.639582,
                          45.482011,
                          0
                      ],
                      [
                          -75.639845,
                          45.480974,
                          0
                      ],
                      [
                          -75.639391,
                          45.475464,
                          0
                      ],
                      [
                          -75.618557,
                          45.47345,
                          0
                      ],
                      [
                          -75.618216,
                          45.47056,
                          0
                      ],
                      [
                          -75.626582,
                          45.469193,
                          0
                      ],
                      [
                          -75.633462,
                          45.467671,
                          0
                      ],
                      [
                          -75.643644,
                          45.466023,
                          0
                      ],
                      [
                          -75.654947,
                          45.464411,
                          0
                      ],
                      [
                          -75.665605,
                          45.462626,
                          0
                      ],
                      [
                          -75.672561,
                          45.461389,
                          0
                      ],
                      [
                          -75.676505,
                          45.460554,
                          0
                      ],
                      [
                          -75.67651,
                          45.460552,
                          0
                      ],
                      [
                          -75.684062,
                          45.458302,
                          0
                      ],
                      [
                          -75.687167,
                          45.456725,
                          0
                      ],
                      [
                          -75.689507,
                          45.454609,
                          0
                      ],
                      [
                          -75.689947,
                          45.454836,
                          0
                      ],
                      [
                          -75.691166,
                          45.453835,
                          0
                      ],
                      [
                          -75.693972,
                          45.453761,
                          0
                      ],
                      [
                          -75.701481,
                          45.457006,
                          0
                      ],
                      [
                          -75.704566,
                          45.45824,
                          0
                      ],
                      [
                          -75.709888,
                          45.458173,
                          0
                      ],
                      [
                          -75.713511,
                          45.45714,
                          0
                      ],
                      [
                          -75.716189,
                          45.457192,
                          0
                      ],
                      [
                          -75.719691,
                          45.458229,
                          0
                      ],
                      [
                          -75.722029,
                          45.460123,
                          0
                      ],
                      [
                          -75.725438,
                          45.464012,
                          0
                      ],
                      [
                          -75.727867,
                          45.470661,
                          0
                      ],
                      [
                          -75.731357,
                          45.473925,
                          0
                      ],
                      [
                          -75.735026,
                          45.475266,
                          0
                      ],
                      [
                          -75.734066,
                          45.476325,
                          0
                      ],
                      [
                          -75.734346,
                          45.478343,
                          0
                      ],
                      [
                          -75.734161,
                          45.478701,
                          0
                      ],
                      [
                          -75.73449,
                          45.479054,
                          0
                      ],
                      [
                          -75.734327,
                          45.47971,
                          0
                      ],
                      [
                          -75.734159,
                          45.479835,
                          0
                      ],
                      [
                          -75.73359,
                          45.479364,
                          0
                      ],
                      [
                          -75.732647,
                          45.479959,
                          0
                      ],
                      [
                          -75.731712,
                          45.480124,
                          0
                      ],
                      [
                          -75.729835,
                          45.479895,
                          0
                      ],
                      [
                          -75.728449,
                          45.480184,
                          0
                      ],
                      [
                          -75.726898,
                          45.480407,
                          0
                      ],
                      [
                          -75.724701,
                          45.481112,
                          0
                      ],
                      [
                          -75.723115,
                          45.482118,
                          0
                      ],
                      [
                          -75.722154,
                          45.482238,
                          0
                      ],
                      [
                          -75.720812,
                          45.483029,
                          0
                      ],
                      [
                          -75.720843,
                          45.483524,
                          0
                      ],
                      [
                          -75.719471,
                          45.483811,
                          0
                      ],
                      [
                          -75.720259,
                          45.484177,
                          0
                      ],
                      [
                          -75.719845,
                          45.484453,
                          0
                      ],
                      [
                          -75.719565,
                          45.485351,
                          0
                      ],
                      [
                          -75.718865,
                          45.486022,
                          0
                      ],
                      [
                          -75.718543,
                          45.487081,
                          0
                      ],
                      [
                          -75.717819,
                          45.487889,
                          0
                      ],
                      [
                          -75.71838,
                          45.488098,
                          0
                      ],
                      [
                          -75.718324,
                          45.489042,
                          0
                      ],
                      [
                          -75.715456,
                          45.488392,
                          0
                      ],
                      [
                          -75.715456,
                          45.488396,
                          0
                      ],
                      [
                          -75.716304,
                          45.494672,
                          0
                      ],
                      [
                          -75.715593,
                          45.49731,
                          0
                      ],
                      [
                          -75.713044,
                          45.501012,
                          0
                      ],
                      [
                          -75.709857,
                          45.502863,
                          0
                      ],
                      [
                          -75.706216,
                          45.503884,
                          0
                      ],
                      [
                          -75.649624,
                          45.511076,
                          0
                      ],
                      [
                          -75.644086,
                          45.511288,
                          0
                      ],
                      [
                          -75.610111,
                          45.511233,
                          0
                      ],
                      [
                          -75.607734,
                          45.495863,
                          0
                      ],
                      [
                          -75.607199,
                          45.494781,
                          0
                      ],
                      [
                          -75.607199,
                          45.494777,
                          0
                      ],
                      [
                          -75.611795,
                          45.493778,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Chapleau",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -71.222311,
                          46.87346,
                          0
                      ],
                      [
                          -71.223163,
                          46.873075,
                          0
                      ],
                      [
                          -71.221928,
                          46.87174,
                          0
                      ],
                      [
                          -71.232364,
                          46.867368,
                          0
                      ],
                      [
                          -71.23715,
                          46.869964,
                          0
                      ],
                      [
                          -71.238448,
                          46.871012,
                          0
                      ],
                      [
                          -71.2412,
                          46.87395,
                          0
                      ],
                      [
                          -71.251453,
                          46.869491,
                          0
                      ],
                      [
                          -71.248268,
                          46.867851,
                          0
                      ],
                      [
                          -71.23293,
                          46.850936,
                          0
                      ],
                      [
                          -71.239687,
                          46.847972,
                          0
                      ],
                      [
                          -71.238875,
                          46.847316,
                          0
                      ],
                      [
                          -71.237763,
                          46.846105,
                          0
                      ],
                      [
                          -71.238632,
                          46.845809,
                          0
                      ],
                      [
                          -71.242566,
                          46.844065,
                          0
                      ],
                      [
                          -71.262606,
                          46.835421,
                          0
                      ],
                      [
                          -71.268378,
                          46.841681,
                          0
                      ],
                      [
                          -71.27074,
                          46.844125,
                          0
                      ],
                      [
                          -71.27301,
                          46.845984,
                          0
                      ],
                      [
                          -71.278564,
                          46.850077,
                          0
                      ],
                      [
                          -71.279861,
                          46.851276,
                          0
                      ],
                      [
                          -71.284311,
                          46.856047,
                          0
                      ],
                      [
                          -71.288033,
                          46.860562,
                          0
                      ],
                      [
                          -71.288938,
                          46.860557,
                          0
                      ],
                      [
                          -71.292203,
                          46.864091,
                          0
                      ],
                      [
                          -71.29186,
                          46.864378,
                          0
                      ],
                      [
                          -71.297093,
                          46.8698,
                          0
                      ],
                      [
                          -71.297093,
                          46.869801,
                          0
                      ],
                      [
                          -71.312962,
                          46.886358,
                          0
                      ],
                      [
                          -71.311106,
                          46.887211,
                          0
                      ],
                      [
                          -71.311096,
                          46.887231,
                          0
                      ],
                      [
                          -71.316015,
                          46.894849,
                          0
                      ],
                      [
                          -71.304639,
                          46.899488,
                          0
                      ],
                      [
                          -71.307044,
                          46.901728,
                          0
                      ],
                      [
                          -71.305413,
                          46.901557,
                          0
                      ],
                      [
                          -71.26321,
                          46.917845,
                          0
                      ],
                      [
                          -71.222311,
                          46.87346,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Charlesbourg",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.612161,
                          45.411578,
                          0
                      ],
                      [
                          -73.597627,
                          45.415933,
                          0
                      ],
                      [
                          -73.597487,
                          45.415973,
                          0
                      ],
                      [
                          -73.597412,
                          45.415994,
                          0
                      ],
                      [
                          -73.597412,
                          45.415993,
                          0
                      ],
                      [
                          -73.611652,
                          45.38466,
                          0
                      ],
                      [
                          -73.616336,
                          45.385756,
                          0
                      ],
                      [
                          -73.620091,
                          45.37824,
                          0
                      ],
                      [
                          -73.620102,
                          45.378222,
                          0
                      ],
                      [
                          -73.620141,
                          45.378229,
                          0
                      ],
                      [
                          -73.620241,
                          45.378208,
                          0
                      ],
                      [
                          -73.622901,
                          45.375563,
                          0
                      ],
                      [
                          -73.623318,
                          45.375117,
                          0
                      ],
                      [
                          -73.624108,
                          45.372984,
                          0
                      ],
                      [
                          -73.62609,
                          45.369542,
                          0
                      ],
                      [
                          -73.628857,
                          45.368523,
                          0
                      ],
                      [
                          -73.628881,
                          45.368512,
                          0
                      ],
                      [
                          -73.629418,
                          45.368701,
                          0
                      ],
                      [
                          -73.632042,
                          45.367066,
                          0
                      ],
                      [
                          -73.633839,
                          45.365656,
                          0
                      ],
                      [
                          -73.636185,
                          45.363317,
                          0
                      ],
                      [
                          -73.639151,
                          45.361841,
                          0
                      ],
                      [
                          -73.640875,
                          45.360695,
                          0
                      ],
                      [
                          -73.643599,
                          45.357705,
                          0
                      ],
                      [
                          -73.646095,
                          45.354155,
                          0
                      ],
                      [
                          -73.648602,
                          45.352484,
                          0
                      ],
                      [
                          -73.648636,
                          45.352458,
                          0
                      ],
                      [
                          -73.651489,
                          45.353101,
                          0
                      ],
                      [
                          -73.65173,
                          45.352706,
                          0
                      ],
                      [
                          -73.656004,
                          45.351436,
                          0
                      ],
                      [
                          -73.656037,
                          45.351427,
                          0
                      ],
                      [
                          -73.660827,
                          45.351438,
                          0
                      ],
                      [
                          -73.661711,
                          45.350124,
                          0
                      ],
                      [
                          -73.661721,
                          45.350111,
                          0
                      ],
                      [
                          -73.644087,
                          45.345947,
                          0
                      ],
                      [
                          -73.644095,
                          45.345943,
                          0
                      ],
                      [
                          -73.644811,
                          45.3455,
                          0
                      ],
                      [
                          -73.644807,
                          45.345499,
                          0
                      ],
                      [
                          -73.631231,
                          45.342221,
                          0
                      ],
                      [
                          -73.635034,
                          45.340018,
                          0
                      ],
                      [
                          -73.638558,
                          45.337353,
                          0
                      ],
                      [
                          -73.639461,
                          45.336153,
                          0
                      ],
                      [
                          -73.641108,
                          45.3345,
                          0
                      ],
                      [
                          -73.635923,
                          45.33347,
                          0
                      ],
                      [
                          -73.634304,
                          45.332732,
                          0
                      ],
                      [
                          -73.620082,
                          45.329416,
                          0
                      ],
                      [
                          -73.627911,
                          45.32494,
                          0
                      ],
                      [
                          -73.63104,
                          45.318871,
                          0
                      ],
                      [
                          -73.634467,
                          45.314755,
                          0
                      ],
                      [
                          -73.638104,
                          45.310652,
                          0
                      ],
                      [
                          -73.63811,
                          45.310654,
                          0
                      ],
                      [
                          -73.645623,
                          45.297602,
                          0
                      ],
                      [
                          -73.64563,
                          45.297591,
                          0
                      ],
                      [
                          -73.650794,
                          45.301287,
                          0
                      ],
                      [
                          -73.650844,
                          45.301325,
                          0
                      ],
                      [
                          -73.650845,
                          45.301323,
                          0
                      ],
                      [
                          -73.677158,
                          45.268253,
                          0
                      ],
                      [
                          -73.677161,
                          45.268253,
                          0
                      ],
                      [
                          -73.677491,
                          45.268269,
                          0
                      ],
                      [
                          -73.677491,
                          45.268268,
                          0
                      ],
                      [
                          -73.688311,
                          45.246019,
                          0
                      ],
                      [
                          -73.688312,
                          45.246017,
                          0
                      ],
                      [
                          -73.68089,
                          45.244457,
                          0
                      ],
                      [
                          -73.682758,
                          45.242864,
                          0
                      ],
                      [
                          -73.682755,
                          45.242819,
                          0
                      ],
                      [
                          -73.682688,
                          45.242776,
                          0
                      ],
                      [
                          -73.698191,
                          45.229073,
                          0
                      ],
                      [
                          -73.811586,
                          45.313846,
                          0
                      ],
                      [
                          -73.832912,
                          45.329786,
                          0
                      ],
                      [
                          -73.837849,
                          45.339093,
                          0
                      ],
                      [
                          -73.846798,
                          45.338953,
                          0
                      ],
                      [
                          -73.846461,
                          45.357972,
                          0
                      ],
                      [
                          -73.822179,
                          45.385403,
                          0
                      ],
                      [
                          -73.767955,
                          45.418681,
                          0
                      ],
                      [
                          -73.697203,
                          45.423069,
                          0
                      ],
                      [
                          -73.666389,
                          45.421853,
                          0
                      ],
                      [
                          -73.643594,
                          45.410927,
                          0
                      ],
                      [
                          -73.612161,
                          45.411578,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Châteauguay",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -71.048264,
                          48.461597,
                          0
                      ],
                      [
                          -71.05254,
                          48.451583,
                          0
                      ],
                      [
                          -71.052603,
                          48.451433,
                          0
                      ],
                      [
                          -71.020115,
                          48.443525,
                          0
                      ],
                      [
                          -71.010957,
                          48.449272,
                          0
                      ],
                      [
                          -71.000521,
                          48.453368,
                          0
                      ],
                      [
                          -70.986252,
                          48.45425,
                          0
                      ],
                      [
                          -70.986701,
                          48.45291,
                          0
                      ],
                      [
                          -70.959926,
                          48.446187,
                          0
                      ],
                      [
                          -70.902302,
                          48.430287,
                          0
                      ],
                      [
                          -70.902285,
                          48.430285,
                          0
                      ],
                      [
                          -70.902203,
                          48.430273,
                          0
                      ],
                      [
                          -70.966635,
                          48.370912,
                          0
                      ],
                      [
                          -70.9903,
                          48.382483,
                          0
                      ],
                      [
                          -71.025878,
                          48.34873,
                          0
                      ],
                      [
                          -71.064085,
                          48.365708,
                          0
                      ],
                      [
                          -71.098158,
                          48.334789,
                          0
                      ],
                      [
                          -71.111071,
                          48.340499,
                          0
                      ],
                      [
                          -71.124782,
                          48.326953,
                          0
                      ],
                      [
                          -71.126238,
                          48.325327,
                          0
                      ],
                      [
                          -71.130052,
                          48.32014,
                          0
                      ],
                      [
                          -71.131811,
                          48.317914,
                          0
                      ],
                      [
                          -71.133761,
                          48.316305,
                          0
                      ],
                      [
                          -71.139411,
                          48.310749,
                          0
                      ],
                      [
                          -71.138595,
                          48.310411,
                          0
                      ],
                      [
                          -71.133872,
                          48.314993,
                          0
                      ],
                      [
                          -71.114731,
                          48.306477,
                          0
                      ],
                      [
                          -71.116666,
                          48.305418,
                          0
                      ],
                      [
                          -71.11602,
                          48.305117,
                          0
                      ],
                      [
                          -71.11676,
                          48.304708,
                          0
                      ],
                      [
                          -71.115644,
                          48.304267,
                          0
                      ],
                      [
                          -71.114515,
                          48.304937,
                          0
                      ],
                      [
                          -71.11311,
                          48.30405,
                          0
                      ],
                      [
                          -71.113807,
                          48.303478,
                          0
                      ],
                      [
                          -71.115021,
                          48.303194,
                          0
                      ],
                      [
                          -71.116011,
                          48.302547,
                          0
                      ],
                      [
                          -71.116862,
                          48.301745,
                          0
                      ],
                      [
                          -71.116335,
                          48.301474,
                          0
                      ],
                      [
                          -71.11576,
                          48.301848,
                          0
                      ],
                      [
                          -71.115441,
                          48.301533,
                          0
                      ],
                      [
                          -71.114108,
                          48.302224,
                          0
                      ],
                      [
                          -71.114533,
                          48.302529,
                          0
                      ],
                      [
                          -71.11428,
                          48.302612,
                          0
                      ],
                      [
                          -71.102514,
                          48.297143,
                          0
                      ],
                      [
                          -71.102145,
                          48.296971,
                          0
                      ],
                      [
                          -71.124088,
                          48.275511,
                          0
                      ],
                      [
                          -71.148772,
                          48.286805,
                          0
                      ],
                      [
                          -71.145898,
                          48.289723,
                          0
                      ],
                      [
                          -71.147224,
                          48.290322,
                          0
                      ],
                      [
                          -71.147187,
                          48.290899,
                          0
                      ],
                      [
                          -71.155222,
                          48.294582,
                          0
                      ],
                      [
                          -71.154752,
                          48.29482,
                          0
                      ],
                      [
                          -71.155553,
                          48.295167,
                          0
                      ],
                      [
                          -71.153258,
                          48.297216,
                          0
                      ],
                      [
                          -71.152506,
                          48.29686,
                          0
                      ],
                      [
                          -71.151555,
                          48.297842,
                          0
                      ],
                      [
                          -71.151316,
                          48.29775,
                          0
                      ],
                      [
                          -71.151075,
                          48.297915,
                          0
                      ],
                      [
                          -71.15096,
                          48.297873,
                          0
                      ],
                      [
                          -71.15068,
                          48.298117,
                          0
                      ],
                      [
                          -71.151838,
                          48.298637,
                          0
                      ],
                      [
                          -71.151095,
                          48.299342,
                          0
                      ],
                      [
                          -71.150208,
                          48.298989,
                          0
                      ],
                      [
                          -71.149392,
                          48.299628,
                          0
                      ],
                      [
                          -71.150391,
                          48.300048,
                          0
                      ],
                      [
                          -71.14781,
                          48.302605,
                          0
                      ],
                      [
                          -71.147573,
                          48.302515,
                          0
                      ],
                      [
                          -71.147186,
                          48.302941,
                          0
                      ],
                      [
                          -71.147381,
                          48.303026,
                          0
                      ],
                      [
                          -71.145719,
                          48.304621,
                          0
                      ],
                      [
                          -71.145344,
                          48.304421,
                          0
                      ],
                      [
                          -71.144933,
                          48.30481,
                          0
                      ],
                      [
                          -71.145825,
                          48.305269,
                          0
                      ],
                      [
                          -71.134355,
                          48.316498,
                          0
                      ],
                      [
                          -71.131953,
                          48.319378,
                          0
                      ],
                      [
                          -71.127438,
                          48.325774,
                          0
                      ],
                      [
                          -71.112332,
                          48.341007,
                          0
                      ],
                      [
                          -71.184477,
                          48.373724,
                          0
                      ],
                      [
                          -71.170042,
                          48.387234,
                          0
                      ],
                      [
                          -71.156453,
                          48.383534,
                          0
                      ],
                      [
                          -71.12759,
                          48.410717,
                          0
                      ],
                      [
                          -71.135666,
                          48.413075,
                          0
                      ],
                      [
                          -71.13948,
                          48.41605,
                          0
                      ],
                      [
                          -71.125247,
                          48.429411,
                          0
                      ],
                      [
                          -71.129663,
                          48.431193,
                          0
                      ],
                      [
                          -71.121778,
                          48.441173,
                          0
                      ],
                      [
                          -71.11227,
                          48.466176,
                          0
                      ],
                      [
                          -71.077795,
                          48.457765,
                          0
                      ],
                      [
                          -71.07376,
                          48.466723,
                          0
                      ],
                      [
                          -71.048264,
                          48.461597,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Chicoutimi",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.749739,
                          45.519135,
                          0
                      ],
                      [
                          -73.75621,
                          45.517924,
                          0
                      ],
                      [
                          -73.763914,
                          45.511847,
                          0
                      ],
                      [
                          -73.76472,
                          45.511629,
                          0
                      ],
                      [
                          -73.774902,
                          45.521788,
                          0
                      ],
                      [
                          -73.784015,
                          45.530946,
                          0
                      ],
                      [
                          -73.782054,
                          45.531564,
                          0
                      ],
                      [
                          -73.778364,
                          45.532255,
                          0
                      ],
                      [
                          -73.778388,
                          45.53229,
                          0
                      ],
                      [
                          -73.778622,
                          45.53302,
                          0
                      ],
                      [
                          -73.778578,
                          45.533112,
                          0
                      ],
                      [
                          -73.778174,
                          45.533207,
                          0
                      ],
                      [
                          -73.777394,
                          45.533704,
                          0
                      ],
                      [
                          -73.776392,
                          45.534442,
                          0
                      ],
                      [
                          -73.775644,
                          45.535359,
                          0
                      ],
                      [
                          -73.773974,
                          45.535999,
                          0
                      ],
                      [
                          -73.772198,
                          45.536997,
                          0
                      ],
                      [
                          -73.770462,
                          45.538002,
                          0
                      ],
                      [
                          -73.771274,
                          45.538764,
                          0
                      ],
                      [
                          -73.771353,
                          45.539151,
                          0
                      ],
                      [
                          -73.771146,
                          45.5394,
                          0
                      ],
                      [
                          -73.771077,
                          45.539743,
                          0
                      ],
                      [
                          -73.771258,
                          45.540153,
                          0
                      ],
                      [
                          -73.771726,
                          45.541005,
                          0
                      ],
                      [
                          -73.773094,
                          45.54255,
                          0
                      ],
                      [
                          -73.773382,
                          45.543,
                          0
                      ],
                      [
                          -73.77362,
                          45.543102,
                          0
                      ],
                      [
                          -73.774219,
                          45.543608,
                          0
                      ],
                      [
                          -73.774298,
                          45.543927,
                          0
                      ],
                      [
                          -73.775078,
                          45.544563,
                          0
                      ],
                      [
                          -73.775249,
                          45.544831,
                          0
                      ],
                      [
                          -73.775176,
                          45.545004,
                          0
                      ],
                      [
                          -73.775226,
                          45.545055,
                          0
                      ],
                      [
                          -73.775525,
                          45.545158,
                          0
                      ],
                      [
                          -73.775558,
                          45.545388,
                          0
                      ],
                      [
                          -73.775771,
                          45.545497,
                          0
                      ],
                      [
                          -73.776253,
                          45.545795,
                          0
                      ],
                      [
                          -73.790923,
                          45.558622,
                          0
                      ],
                      [
                          -73.791662,
                          45.559667,
                          0
                      ],
                      [
                          -73.766858,
                          45.567001,
                          0
                      ],
                      [
                          -73.762936,
                          45.568342,
                          0
                      ],
                      [
                          -73.75109,
                          45.5759,
                          0
                      ],
                      [
                          -73.737225,
                          45.562911,
                          0
                      ],
                      [
                          -73.732068,
                          45.558065,
                          0
                      ],
                      [
                          -73.718794,
                          45.546361,
                          0
                      ],
                      [
                          -73.714372,
                          45.541861,
                          0
                      ],
                      [
                          -73.712535,
                          45.540485,
                          0
                      ],
                      [
                          -73.71233,
                          45.540362,
                          0
                      ],
                      [
                          -73.712328,
                          45.540361,
                          0
                      ],
                      [
                          -73.719284,
                          45.538357,
                          0
                      ],
                      [
                          -73.725375,
                          45.53551,
                          0
                      ],
                      [
                          -73.727999,
                          45.533241,
                          0
                      ],
                      [
                          -73.729777,
                          45.532203,
                          0
                      ],
                      [
                          -73.729785,
                          45.532198,
                          0
                      ],
                      [
                          -73.736469,
                          45.530449,
                          0
                      ],
                      [
                          -73.742634,
                          45.525853,
                          0
                      ],
                      [
                          -73.745724,
                          45.521272,
                          0
                      ],
                      [
                          -73.749739,
                          45.519135,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Chomedey",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -71.183827,
                          46.742748,
                          0
                      ],
                      [
                          -71.178145,
                          46.742921,
                          0
                      ],
                      [
                          -71.168724,
                          46.740042,
                          0
                      ],
                      [
                          -71.160449,
                          46.743385,
                          0
                      ],
                      [
                          -71.156296,
                          46.740528,
                          0
                      ],
                      [
                          -71.154258,
                          46.742357,
                          0
                      ],
                      [
                          -71.152134,
                          46.742375,
                          0
                      ],
                      [
                          -71.149847,
                          46.738742,
                          0
                      ],
                      [
                          -71.143486,
                          46.736372,
                          0
                      ],
                      [
                          -71.141745,
                          46.734474,
                          0
                      ],
                      [
                          -71.13747,
                          46.731852,
                          0
                      ],
                      [
                          -71.135308,
                          46.727011,
                          0
                      ],
                      [
                          -71.130739,
                          46.722885,
                          0
                      ],
                      [
                          -71.1272,
                          46.721704,
                          0
                      ],
                      [
                          -71.12429,
                          46.719454,
                          0
                      ],
                      [
                          -71.14081,
                          46.707932,
                          0
                      ],
                      [
                          -71.13945,
                          46.703243,
                          0
                      ],
                      [
                          -71.132787,
                          46.70788,
                          0
                      ],
                      [
                          -71.132987,
                          46.695906,
                          0
                      ],
                      [
                          -71.1377,
                          46.695745,
                          0
                      ],
                      [
                          -71.138227,
                          46.694437,
                          0
                      ],
                      [
                          -71.135621,
                          46.693407,
                          0
                      ],
                      [
                          -71.134211,
                          46.688026,
                          0
                      ],
                      [
                          -71.136725,
                          46.681797,
                          0
                      ],
                      [
                          -71.127758,
                          46.681996,
                          0
                      ],
                      [
                          -71.12791,
                          46.669215,
                          0
                      ],
                      [
                          -71.15157,
                          46.65289,
                          0
                      ],
                      [
                          -71.214769,
                          46.607644,
                          0
                      ],
                      [
                          -71.215083,
                          46.608925,
                          0
                      ],
                      [
                          -71.215093,
                          46.608939,
                          0
                      ],
                      [
                          -71.216047,
                          46.610901,
                          0
                      ],
                      [
                          -71.216415,
                          46.613251,
                          0
                      ],
                      [
                          -71.216581,
                          46.614999,
                          0
                      ],
                      [
                          -71.216585,
                          46.615052,
                          0
                      ],
                      [
                          -71.217086,
                          46.61709,
                          0
                      ],
                      [
                          -71.217602,
                          46.619185,
                          0
                      ],
                      [
                          -71.217089,
                          46.624765,
                          0
                      ],
                      [
                          -71.21648,
                          46.632259,
                          0
                      ],
                      [
                          -71.216378,
                          46.633631,
                          0
                      ],
                      [
                          -71.216376,
                          46.633668,
                          0
                      ],
                      [
                          -71.21692,
                          46.635025,
                          0
                      ],
                      [
                          -71.221209,
                          46.64594,
                          0
                      ],
                      [
                          -71.223528,
                          46.65167,
                          0
                      ],
                      [
                          -71.238124,
                          46.642148,
                          0
                      ],
                      [
                          -71.238241,
                          46.64021,
                          0
                      ],
                      [
                          -71.237146,
                          46.638207,
                          0
                      ],
                      [
                          -71.23657,
                          46.637054,
                          0
                      ],
                      [
                          -71.236787,
                          46.635767,
                          0
                      ],
                      [
                          -71.237736,
                          46.633688,
                          0
                      ],
                      [
                          -71.237736,
                          46.633687,
                          0
                      ],
                      [
                          -71.237255,
                          46.632419,
                          0
                      ],
                      [
                          -71.236953,
                          46.630064,
                          0
                      ],
                      [
                          -71.238136,
                          46.6282,
                          0
                      ],
                      [
                          -71.239465,
                          46.627397,
                          0
                      ],
                      [
                          -71.240077,
                          46.626442,
                          0
                      ],
                      [
                          -71.24008,
                          46.626438,
                          0
                      ],
                      [
                          -71.239873,
                          46.626129,
                          0
                      ],
                      [
                          -71.238364,
                          46.625374,
                          0
                      ],
                      [
                          -71.237361,
                          46.625306,
                          0
                      ],
                      [
                          -71.234008,
                          46.62553,
                          0
                      ],
                      [
                          -71.232775,
                          46.62541,
                          0
                      ],
                      [
                          -71.232318,
                          46.62498,
                          0
                      ],
                      [
                          -71.232359,
                          46.623998,
                          0
                      ],
                      [
                          -71.232569,
                          46.623388,
                          0
                      ],
                      [
                          -71.233119,
                          46.622632,
                          0
                      ],
                      [
                          -71.23376,
                          46.621821,
                          0
                      ],
                      [
                          -71.234241,
                          46.621339,
                          0
                      ],
                      [
                          -71.235065,
                          46.620773,
                          0
                      ],
                      [
                          -71.235351,
                          46.62049,
                          0
                      ],
                      [
                          -71.23549,
                          46.620081,
                          0
                      ],
                      [
                          -71.23539,
                          46.619392,
                          0
                      ],
                      [
                          -71.23539,
                          46.619392,
                          0
                      ],
                      [
                          -71.236071,
                          46.618227,
                          0
                      ],
                      [
                          -71.236419,
                          46.6177,
                          0
                      ],
                      [
                          -71.237686,
                          46.618567,
                          0
                      ],
                      [
                          -71.254268,
                          46.629907,
                          0
                      ],
                      [
                          -71.263954,
                          46.619917,
                          0
                      ],
                      [
                          -71.265007,
                          46.619187,
                          0
                      ],
                      [
                          -71.265086,
                          46.619059,
                          0
                      ],
                      [
                          -71.265432,
                          46.6193,
                          0
                      ],
                      [
                          -71.265366,
                          46.619043,
                          0
                      ],
                      [
                          -71.265572,
                          46.618752,
                          0
                      ],
                      [
                          -71.268229,
                          46.616684,
                          0
                      ],
                      [
                          -71.300602,
                          46.594424,
                          0
                      ],
                      [
                          -71.332132,
                          46.573015,
                          0
                      ],
                      [
                          -71.409553,
                          46.625734,
                          0
                      ],
                      [
                          -71.403528,
                          46.628192,
                          0
                      ],
                      [
                          -71.403396,
                          46.628245,
                          0
                      ],
                      [
                          -71.403232,
                          46.628295,
                          0
                      ],
                      [
                          -71.419873,
                          46.639278,
                          0
                      ],
                      [
                          -71.427587,
                          46.63662,
                          0
                      ],
                      [
                          -71.519721,
                          46.699467,
                          0
                      ],
                      [
                          -71.506252,
                          46.703228,
                          0
                      ],
                      [
                          -71.354637,
                          46.727464,
                          0
                      ],
                      [
                          -71.294994,
                          46.744962,
                          0
                      ],
                      [
                          -71.294994,
                          46.744962,
                          0
                      ],
                      [
                          -71.283704,
                          46.746729,
                          0
                      ],
                      [
                          -71.274225,
                          46.740519,
                          0
                      ],
                      [
                          -71.273269,
                          46.73952,
                          0
                      ],
                      [
                          -71.274266,
                          46.738344,
                          0
                      ],
                      [
                          -71.277786,
                          46.73731,
                          0
                      ],
                      [
                          -71.281147,
                          46.736714,
                          0
                      ],
                      [
                          -71.282844,
                          46.735964,
                          0
                      ],
                      [
                          -71.282859,
                          46.735954,
                          0
                      ],
                      [
                          -71.284485,
                          46.734377,
                          0
                      ],
                      [
                          -71.284812,
                          46.733923,
                          0
                      ],
                      [
                          -71.28432,
                          46.733017,
                          0
                      ],
                      [
                          -71.284323,
                          46.731919,
                          0
                      ],
                      [
                          -71.285666,
                          46.730772,
                          0
                      ],
                      [
                          -71.286755,
                          46.729758,
                          0
                      ],
                      [
                          -71.28705,
                          46.728786,
                          0
                      ],
                      [
                          -71.287048,
                          46.728761,
                          0
                      ],
                      [
                          -71.286996,
                          46.727675,
                          0
                      ],
                      [
                          -71.285903,
                          46.725953,
                          0
                      ],
                      [
                          -71.286079,
                          46.723147,
                          0
                      ],
                      [
                          -71.286246,
                          46.721401,
                          0
                      ],
                      [
                          -71.278542,
                          46.724947,
                          0
                      ],
                      [
                          -71.268981,
                          46.7266,
                          0
                      ],
                      [
                          -71.257764,
                          46.728486,
                          0
                      ],
                      [
                          -71.234404,
                          46.736422,
                          0
                      ],
                      [
                          -71.192178,
                          46.748434,
                          0
                      ],
                      [
                          -71.190057,
                          46.74918,
                          0
                      ],
                      [
                          -71.186996,
                          46.751955,
                          0
                      ],
                      [
                          -71.18438,
                          46.755575,
                          0
                      ],
                      [
                          -71.181076,
                          46.753301,
                          0
                      ],
                      [
                          -71.190111,
                          46.744275,
                          0
                      ],
                      [
                          -71.183827,
                          46.742748,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Chutes-de-la-Chaudière",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.624788,
                          45.488626,
                          0
                      ],
                      [
                          -73.622386,
                          45.491269,
                          0
                      ],
                      [
                          -73.619199,
                          45.489787,
                          0
                      ],
                      [
                          -73.618927,
                          45.489786,
                          0
                      ],
                      [
                          -73.618006,
                          45.489419,
                          0
                      ],
                      [
                          -73.618408,
                          45.488977,
                          0
                      ],
                      [
                          -73.618317,
                          45.488671,
                          0
                      ],
                      [
                          -73.618424,
                          45.488211,
                          0
                      ],
                      [
                          -73.618122,
                          45.488074,
                          0
                      ],
                      [
                          -73.615291,
                          45.486789,
                          0
                      ],
                      [
                          -73.617218,
                          45.484789,
                          0
                      ],
                      [
                          -73.615151,
                          45.483858,
                          0
                      ],
                      [
                          -73.615722,
                          45.483552,
                          0
                      ],
                      [
                          -73.61689,
                          45.483406,
                          0
                      ],
                      [
                          -73.618088,
                          45.483528,
                          0
                      ],
                      [
                          -73.619218,
                          45.483374,
                          0
                      ],
                      [
                          -73.619837,
                          45.483065,
                          0
                      ],
                      [
                          -73.622,
                          45.481526,
                          0
                      ],
                      [
                          -73.623517,
                          45.479846,
                          0
                      ],
                      [
                          -73.624212,
                          45.479405,
                          0
                      ],
                      [
                          -73.624781,
                          45.47921,
                          0
                      ],
                      [
                          -73.62614,
                          45.478948,
                          0
                      ],
                      [
                          -73.629669,
                          45.478309,
                          0
                      ],
                      [
                          -73.629924,
                          45.478365,
                          0
                      ],
                      [
                          -73.632559,
                          45.477883,
                          0
                      ],
                      [
                          -73.634965,
                          45.477105,
                          0
                      ],
                      [
                          -73.635669,
                          45.476972,
                          0
                      ],
                      [
                          -73.639323,
                          45.476863,
                          0
                      ],
                      [
                          -73.639734,
                          45.476747,
                          0
                      ],
                      [
                          -73.640377,
                          45.476334,
                          0
                      ],
                      [
                          -73.643119,
                          45.474603,
                          0
                      ],
                      [
                          -73.643889,
                          45.47422,
                          0
                      ],
                      [
                          -73.64446,
                          45.473967,
                          0
                      ],
                      [
                          -73.644391,
                          45.473938,
                          0
                      ],
                      [
                          -73.64462,
                          45.473838,
                          0
                      ],
                      [
                          -73.645922,
                          45.473277,
                          0
                      ],
                      [
                          -73.645888,
                          45.473091,
                          0
                      ],
                      [
                          -73.646947,
                          45.472241,
                          0
                      ],
                      [
                          -73.647874,
                          45.471503,
                          0
                      ],
                      [
                          -73.649022,
                          45.470583,
                          0
                      ],
                      [
                          -73.649334,
                          45.470335,
                          0
                      ],
                      [
                          -73.649453,
                          45.470382,
                          0
                      ],
                      [
                          -73.649834,
                          45.469681,
                          0
                      ],
                      [
                          -73.650348,
                          45.468732,
                          0
                      ],
                      [
                          -73.650636,
                          45.468539,
                          0
                      ],
                      [
                          -73.65125,
                          45.468167,
                          0
                      ],
                      [
                          -73.652214,
                          45.467626,
                          0
                      ],
                      [
                          -73.653092,
                          45.466475,
                          0
                      ],
                      [
                          -73.655759,
                          45.462955,
                          0
                      ],
                      [
                          -73.657471,
                          45.461999,
                          0
                      ],
                      [
                          -73.657402,
                          45.461973,
                          0
                      ],
                      [
                          -73.658272,
                          45.461455,
                          0
                      ],
                      [
                          -73.657713,
                          45.46125,
                          0
                      ],
                      [
                          -73.657587,
                          45.461005,
                          0
                      ],
                      [
                          -73.657752,
                          45.460805,
                          0
                      ],
                      [
                          -73.657729,
                          45.460671,
                          0
                      ],
                      [
                          -73.657449,
                          45.460502,
                          0
                      ],
                      [
                          -73.657292,
                          45.460469,
                          0
                      ],
                      [
                          -73.657293,
                          45.460216,
                          0
                      ],
                      [
                          -73.657223,
                          45.460105,
                          0
                      ],
                      [
                          -73.657531,
                          45.459954,
                          0
                      ],
                      [
                          -73.657567,
                          45.459906,
                          0
                      ],
                      [
                          -73.657603,
                          45.459694,
                          0
                      ],
                      [
                          -73.657837,
                          45.459685,
                          0
                      ],
                      [
                          -73.658305,
                          45.459826,
                          0
                      ],
                      [
                          -73.658442,
                          45.459711,
                          0
                      ],
                      [
                          -73.657963,
                          45.45953,
                          0
                      ],
                      [
                          -73.659577,
                          45.458473,
                          0
                      ],
                      [
                          -73.659696,
                          45.458314,
                          0
                      ],
                      [
                          -73.661324,
                          45.457222,
                          0
                      ],
                      [
                          -73.657991,
                          45.455941,
                          0
                      ],
                      [
                          -73.659556,
                          45.454576,
                          0
                      ],
                      [
                          -73.667316,
                          45.457566,
                          0
                      ],
                      [
                          -73.668167,
                          45.45655,
                          0
                      ],
                      [
                          -73.66942,
                          45.455633,
                          0
                      ],
                      [
                          -73.671789,
                          45.452692,
                          0
                      ],
                      [
                          -73.677498,
                          45.452732,
                          0
                      ],
                      [
                          -73.677506,
                          45.452732,
                          0
                      ],
                      [
                          -73.687234,
                          45.455402,
                          0
                      ],
                      [
                          -73.686497,
                          45.456756,
                          0
                      ],
                      [
                          -73.685658,
                          45.457428,
                          0
                      ],
                      [
                          -73.683023,
                          45.460029,
                          0
                      ],
                      [
                          -73.683193,
                          45.460095,
                          0
                      ],
                      [
                          -73.6828,
                          45.461012,
                          0
                      ],
                      [
                          -73.681831,
                          45.461364,
                          0
                      ],
                      [
                          -73.681287,
                          45.462153,
                          0
                      ],
                      [
                          -73.682806,
                          45.463179,
                          0
                      ],
                      [
                          -73.679226,
                          45.47624,
                          0
                      ],
                      [
                          -73.677211,
                          45.483689,
                          0
                      ],
                      [
                          -73.674636,
                          45.481961,
                          0
                      ],
                      [
                          -73.669757,
                          45.483714,
                          0
                      ],
                      [
                          -73.66185,
                          45.480258,
                          0
                      ],
                      [
                          -73.66097,
                          45.481152,
                          0
                      ],
                      [
                          -73.655963,
                          45.478983,
                          0
                      ],
                      [
                          -73.655994,
                          45.484425,
                          0
                      ],
                      [
                          -73.655527,
                          45.486566,
                          0
                      ],
                      [
                          -73.655457,
                          45.486726,
                          0
                      ],
                      [
                          -73.655792,
                          45.486876,
                          0
                      ],
                      [
                          -73.652483,
                          45.49052,
                          0
                      ],
                      [
                          -73.640191,
                          45.50443,
                          0
                      ],
                      [
                          -73.624305,
                          45.497234,
                          0
                      ],
                      [
                          -73.629955,
                          45.49095,
                          0
                      ],
                      [
                          -73.629956,
                          45.490948,
                          0
                      ],
                      [
                          -73.624788,
                          45.488626,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "D'Arcy-McGee",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.93997,
                          45.618665,
                          0
                      ],
                      [
                          -73.93998,
                          45.61879,
                          0
                      ],
                      [
                          -73.937863,
                          45.618803,
                          0
                      ],
                      [
                          -73.937838,
                          45.619345,
                          0
                      ],
                      [
                          -73.933295,
                          45.619345,
                          0
                      ],
                      [
                          -73.933289,
                          45.619264,
                          0
                      ],
                      [
                          -73.933117,
                          45.61656,
                          0
                      ],
                      [
                          -73.91633,
                          45.605758,
                          0
                      ],
                      [
                          -73.916304,
                          45.605763,
                          0
                      ],
                      [
                          -73.91438,
                          45.60615,
                          0
                      ],
                      [
                          -73.91438,
                          45.60615,
                          0
                      ],
                      [
                          -73.91432,
                          45.606162,
                          0
                      ],
                      [
                          -73.914317,
                          45.606164,
                          0
                      ],
                      [
                          -73.905319,
                          45.613004,
                          0
                      ],
                      [
                          -73.905263,
                          45.61153,
                          0
                      ],
                      [
                          -73.903198,
                          45.611548,
                          0
                      ],
                      [
                          -73.903136,
                          45.610326,
                          0
                      ],
                      [
                          -73.900914,
                          45.610315,
                          0
                      ],
                      [
                          -73.900806,
                          45.60704,
                          0
                      ],
                      [
                          -73.898456,
                          45.607015,
                          0
                      ],
                      [
                          -73.898454,
                          45.606628,
                          0
                      ],
                      [
                          -73.896198,
                          45.606645,
                          0
                      ],
                      [
                          -73.896198,
                          45.606648,
                          0
                      ],
                      [
                          -73.896495,
                          45.616992,
                          0
                      ],
                      [
                          -73.880497,
                          45.606716,
                          0
                      ],
                      [
                          -73.880375,
                          45.602343,
                          0
                      ],
                      [
                          -73.875302,
                          45.597271,
                          0
                      ],
                      [
                          -73.870183,
                          45.598703,
                          0
                      ],
                      [
                          -73.870272,
                          45.598854,
                          0
                      ],
                      [
                          -73.86922,
                          45.599584,
                          0
                      ],
                      [
                          -73.853883,
                          45.589717,
                          0
                      ],
                      [
                          -73.853087,
                          45.590111,
                          0
                      ],
                      [
                          -73.849615,
                          45.587684,
                          0
                      ],
                      [
                          -73.849615,
                          45.587684,
                          0
                      ],
                      [
                          -73.849615,
                          45.587684,
                          0
                      ],
                      [
                          -73.847831,
                          45.58914,
                          0
                      ],
                      [
                          -73.840736,
                          45.583039,
                          0
                      ],
                      [
                          -73.841659,
                          45.582557,
                          0
                      ],
                      [
                          -73.84256,
                          45.58277,
                          0
                      ],
                      [
                          -73.843161,
                          45.582749,
                          0
                      ],
                      [
                          -73.842357,
                          45.582197,
                          0
                      ],
                      [
                          -73.843184,
                          45.582019,
                          0
                      ],
                      [
                          -73.843677,
                          45.58156,
                          0
                      ],
                      [
                          -73.843717,
                          45.581226,
                          0
                      ],
                      [
                          -73.843253,
                          45.580449,
                          0
                      ],
                      [
                          -73.843037,
                          45.579431,
                          0
                      ],
                      [
                          -73.843037,
                          45.579431,
                          0
                      ],
                      [
                          -73.842757,
                          45.57872,
                          0
                      ],
                      [
                          -73.845665,
                          45.578131,
                          0
                      ],
                      [
                          -73.849147,
                          45.577135,
                          0
                      ],
                      [
                          -73.850429,
                          45.576055,
                          0
                      ],
                      [
                          -73.85335,
                          45.574394,
                          0
                      ],
                      [
                          -73.858192,
                          45.571738,
                          0
                      ],
                      [
                          -73.861023,
                          45.569775,
                          0
                      ],
                      [
                          -73.863134,
                          45.56678,
                          0
                      ],
                      [
                          -73.866784,
                          45.564627,
                          0
                      ],
                      [
                          -73.87473,
                          45.561366,
                          0
                      ],
                      [
                          -73.882032,
                          45.560558,
                          0
                      ],
                      [
                          -73.884783,
                          45.558173,
                          0
                      ],
                      [
                          -73.884341,
                          45.55493,
                          0
                      ],
                      [
                          -73.886318,
                          45.551508,
                          0
                      ],
                      [
                          -73.886288,
                          45.549093,
                          0
                      ],
                      [
                          -73.883494,
                          45.5418,
                          0
                      ],
                      [
                          -73.882497,
                          45.537889,
                          0
                      ],
                      [
                          -73.881052,
                          45.536177,
                          0
                      ],
                      [
                          -73.882307,
                          45.535079,
                          0
                      ],
                      [
                          -73.882307,
                          45.535079,
                          0
                      ],
                      [
                          -73.886227,
                          45.531357,
                          0
                      ],
                      [
                          -73.891375,
                          45.527873,
                          0
                      ],
                      [
                          -73.896139,
                          45.525546,
                          0
                      ],
                      [
                          -73.896139,
                          45.525546,
                          0
                      ],
                      [
                          -73.899878,
                          45.524457,
                          0
                      ],
                      [
                          -73.899882,
                          45.524459,
                          0
                      ],
                      [
                          -73.902116,
                          45.526114,
                          0
                      ],
                      [
                          -73.910478,
                          45.532304,
                          0
                      ],
                      [
                          -73.910513,
                          45.532297,
                          0
                      ],
                      [
                          -73.910542,
                          45.532291,
                          0
                      ],
                      [
                          -73.910769,
                          45.532253,
                          0
                      ],
                      [
                          -73.92705,
                          45.544184,
                          0
                      ],
                      [
                          -73.927809,
                          45.544742,
                          0
                      ],
                      [
                          -73.932292,
                          45.544168,
                          0
                      ],
                      [
                          -73.932281,
                          45.544126,
                          0
                      ],
                      [
                          -73.932063,
                          45.543311,
                          0
                      ],
                      [
                          -73.932066,
                          45.54331,
                          0
                      ],
                      [
                          -73.934281,
                          45.542961,
                          0
                      ],
                      [
                          -73.933992,
                          45.541819,
                          0
                      ],
                      [
                          -73.935314,
                          45.541672,
                          0
                      ],
                      [
                          -73.935444,
                          45.542055,
                          0
                      ],
                      [
                          -73.936931,
                          45.541866,
                          0
                      ],
                      [
                          -73.937648,
                          45.542399,
                          0
                      ],
                      [
                          -73.937648,
                          45.542399,
                          0
                      ],
                      [
                          -73.941568,
                          45.541838,
                          0
                      ],
                      [
                          -73.941516,
                          45.541647,
                          0
                      ],
                      [
                          -73.943408,
                          45.541408,
                          0
                      ],
                      [
                          -73.943497,
                          45.541563,
                          0
                      ],
                      [
                          -73.945969,
                          45.541206,
                          0
                      ],
                      [
                          -73.945948,
                          45.541092,
                          0
                      ],
                      [
                          -73.950509,
                          45.540514,
                          0
                      ],
                      [
                          -73.95052,
                          45.540512,
                          0
                      ],
                      [
                          -73.950586,
                          45.540757,
                          0
                      ],
                      [
                          -73.95059,
                          45.540771,
                          0
                      ],
                      [
                          -73.952914,
                          45.54048,
                          0
                      ],
                      [
                          -73.95282,
                          45.54006,
                          0
                      ],
                      [
                          -73.955005,
                          45.539793,
                          0
                      ],
                      [
                          -73.954857,
                          45.539167,
                          0
                      ],
                      [
                          -73.957105,
                          45.53888,
                          0
                      ],
                      [
                          -73.957117,
                          45.538878,
                          0
                      ],
                      [
                          -73.95719,
                          45.539065,
                          0
                      ],
                      [
                          -73.957196,
                          45.539082,
                          0
                      ],
                      [
                          -73.961627,
                          45.538545,
                          0
                      ],
                      [
                          -73.96163,
                          45.538545,
                          0
                      ],
                      [
                          -73.961731,
                          45.538892,
                          0
                      ],
                      [
                          -73.968651,
                          45.538002,
                          0
                      ],
                      [
                          -73.968704,
                          45.538207,
                          0
                      ],
                      [
                          -73.970736,
                          45.537945,
                          0
                      ],
                      [
                          -73.971223,
                          45.539864,
                          0
                      ],
                      [
                          -73.9735,
                          45.539628,
                          0
                      ],
                      [
                          -73.971765,
                          45.532883,
                          0
                      ],
                      [
                          -73.999997,
                          45.55321,
                          0
                      ],
                      [
                          -74.018821,
                          45.566735,
                          0
                      ],
                      [
                          -74.01938,
                          45.565624,
                          0
                      ],
                      [
                          -74.021405,
                          45.566118,
                          0
                      ],
                      [
                          -74.021283,
                          45.566383,
                          0
                      ],
                      [
                          -74.025508,
                          45.567497,
                          0
                      ],
                      [
                          -74.020212,
                          45.577519,
                          0
                      ],
                      [
                          -74.017853,
                          45.57666,
                          0
                      ],
                      [
                          -74.014666,
                          45.576064,
                          0
                      ],
                      [
                          -74.011842,
                          45.576231,
                          0
                      ],
                      [
                          -74.009697,
                          45.57685,
                          0
                      ],
                      [
                          -74.009769,
                          45.577478,
                          0
                      ],
                      [
                          -74.011933,
                          45.578299,
                          0
                      ],
                      [
                          -74.011399,
                          45.579562,
                          0
                      ],
                      [
                          -74.009637,
                          45.579339,
                          0
                      ],
                      [
                          -74.007532,
                          45.579136,
                          0
                      ],
                      [
                          -74.006015,
                          45.578579,
                          0
                      ],
                      [
                          -74.005189,
                          45.578681,
                          0
                      ],
                      [
                          -74.00409,
                          45.579073,
                          0
                      ],
                      [
                          -74.003425,
                          45.578241,
                          0
                      ],
                      [
                          -74.0036,
                          45.577488,
                          0
                      ],
                      [
                          -74.002996,
                          45.577312,
                          0
                      ],
                      [
                          -73.999996,
                          45.577835,
                          0
                      ],
                      [
                          -73.998936,
                          45.578072,
                          0
                      ],
                      [
                          -73.998284,
                          45.577744,
                          0
                      ],
                      [
                          -73.998884,
                          45.576506,
                          0
                      ],
                      [
                          -73.998907,
                          45.57611,
                          0
                      ],
                      [
                          -73.998291,
                          45.576096,
                          0
                      ],
                      [
                          -73.997663,
                          45.57628,
                          0
                      ],
                      [
                          -73.995353,
                          45.576618,
                          0
                      ],
                      [
                          -73.993705,
                          45.577227,
                          0
                      ],
                      [
                          -73.991433,
                          45.577012,
                          0
                      ],
                      [
                          -73.990542,
                          45.576423,
                          0
                      ],
                      [
                          -73.988369,
                          45.574952,
                          0
                      ],
                      [
                          -73.988139,
                          45.573763,
                          0
                      ],
                      [
                          -73.987743,
                          45.572031,
                          0
                      ],
                      [
                          -73.978746,
                          45.581706,
                          0
                      ],
                      [
                          -73.977964,
                          45.581619,
                          0
                      ],
                      [
                          -73.980016,
                          45.588857,
                          0
                      ],
                      [
                          -73.981573,
                          45.595196,
                          0
                      ],
                      [
                          -73.982652,
                          45.595715,
                          0
                      ],
                      [
                          -73.982887,
                          45.599426,
                          0
                      ],
                      [
                          -73.979944,
                          45.602505,
                          0
                      ],
                      [
                          -73.961072,
                          45.604828,
                          0
                      ],
                      [
                          -73.961193,
                          45.606119,
                          0
                      ],
                      [
                          -73.959608,
                          45.606222,
                          0
                      ],
                      [
                          -73.957518,
                          45.60555,
                          0
                      ],
                      [
                          -73.956598,
                          45.604709,
                          0
                      ],
                      [
                          -73.952874,
                          45.605375,
                          0
                      ],
                      [
                          -73.950604,
                          45.602413,
                          0
                      ],
                      [
                          -73.951288,
                          45.616682,
                          0
                      ],
                      [
                          -73.94467,
                          45.621676,
                          0
                      ],
                      [
                          -73.944665,
                          45.621588,
                          0
                      ],
                      [
                          -73.944507,
                          45.618727,
                          0
                      ],
                      [
                          -73.944441,
                          45.618728,
                          0
                      ],
                      [
                          -73.94221,
                          45.618747,
                          0
                      ],
                      [
                          -73.942202,
                          45.61863,
                          0
                      ],
                      [
                          -73.93997,
                          45.618665,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Deux-Montagnes",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -72.192069,
                          46.091568,
                          0
                      ],
                      [
                          -72.19207,
                          46.091479,
                          0
                      ],
                      [
                          -72.178284,
                          46.100805,
                          0
                      ],
                      [
                          -72.178283,
                          46.100801,
                          0
                      ],
                      [
                          -72.178341,
                          46.097132,
                          0
                      ],
                      [
                          -72.178659,
                          46.07566,
                          0
                      ],
                      [
                          -72.178657,
                          46.07566,
                          0
                      ],
                      [
                          -72.178611,
                          46.075668,
                          0
                      ],
                      [
                          -72.162383,
                          46.07884,
                          0
                      ],
                      [
                          -72.16181,
                          46.057115,
                          0
                      ],
                      [
                          -72.161862,
                          46.03465,
                          0
                      ],
                      [
                          -72.140762,
                          46.038615,
                          0
                      ],
                      [
                          -72.145163,
                          46.03457,
                          0
                      ],
                      [
                          -72.11358,
                          46.017176,
                          0
                      ],
                      [
                          -72.097247,
                          46.032666,
                          0
                      ],
                      [
                          -72.097211,
                          46.032699,
                          0
                      ],
                      [
                          -72.082615,
                          46.02471,
                          0
                      ],
                      [
                          -72.082477,
                          46.024634,
                          0
                      ],
                      [
                          -72.069015,
                          46.037378,
                          0
                      ],
                      [
                          -72.054212,
                          46.029446,
                          0
                      ],
                      [
                          -72.045278,
                          46.037732,
                          0
                      ],
                      [
                          -72.045251,
                          46.037757,
                          0
                      ],
                      [
                          -72.029884,
                          46.029665,
                          0
                      ],
                      [
                          -72.043547,
                          46.017247,
                          0
                      ],
                      [
                          -72.043553,
                          46.017242,
                          0
                      ],
                      [
                          -72.028929,
                          46.009555,
                          0
                      ],
                      [
                          -72.014166,
                          46.001104,
                          0
                      ],
                      [
                          -71.986448,
                          46.026342,
                          0
                      ],
                      [
                          -71.986318,
                          46.026456,
                          0
                      ],
                      [
                          -71.986217,
                          46.026404,
                          0
                      ],
                      [
                          -71.972668,
                          46.019414,
                          0
                      ],
                      [
                          -71.972662,
                          46.019411,
                          0
                      ],
                      [
                          -71.949189,
                          46.004955,
                          0
                      ],
                      [
                          -71.879147,
                          45.966592,
                          0
                      ],
                      [
                          -71.808868,
                          46.034504,
                          0
                      ],
                      [
                          -71.806235,
                          46.033124,
                          0
                      ],
                      [
                          -71.806185,
                          46.033098,
                          0
                      ],
                      [
                          -71.783434,
                          46.054976,
                          0
                      ],
                      [
                          -71.782789,
                          46.054626,
                          0
                      ],
                      [
                          -71.771208,
                          46.065998,
                          0
                      ],
                      [
                          -71.772222,
                          46.066548,
                          0
                      ],
                      [
                          -71.760532,
                          46.077517,
                          0
                      ],
                      [
                          -71.725267,
                          46.059371,
                          0
                      ],
                      [
                          -71.723231,
                          46.057981,
                          0
                      ],
                      [
                          -71.711274,
                          46.068912,
                          0
                      ],
                      [
                          -71.711267,
                          46.068909,
                          0
                      ],
                      [
                          -71.653616,
                          46.039299,
                          0
                      ],
                      [
                          -71.653518,
                          46.039279,
                          0
                      ],
                      [
                          -71.653497,
                          46.039274,
                          0
                      ],
                      [
                          -71.653337,
                          46.039195,
                          0
                      ],
                      [
                          -71.653337,
                          46.039195,
                          0
                      ],
                      [
                          -71.645657,
                          46.035152,
                          0
                      ],
                      [
                          -71.645388,
                          46.035182,
                          0
                      ],
                      [
                          -71.625733,
                          46.025058,
                          0
                      ],
                      [
                          -71.625733,
                          46.025058,
                          0
                      ],
                      [
                          -71.626521,
                          46.02442,
                          0
                      ],
                      [
                          -71.686121,
                          45.966709,
                          0
                      ],
                      [
                          -71.562201,
                          45.906067,
                          0
                      ],
                      [
                          -71.562141,
                          45.906038,
                          0
                      ],
                      [
                          -71.551401,
                          45.916104,
                          0
                      ],
                      [
                          -71.551399,
                          45.916105,
                          0
                      ],
                      [
                          -71.501667,
                          45.890859,
                          0
                      ],
                      [
                          -71.498463,
                          45.893819,
                          0
                      ],
                      [
                          -71.490043,
                          45.889591,
                          0
                      ],
                      [
                          -71.489811,
                          45.889477,
                          0
                      ],
                      [
                          -71.489788,
                          45.889465,
                          0
                      ],
                      [
                          -71.489786,
                          45.889464,
                          0
                      ],
                      [
                          -71.489736,
                          45.889435,
                          0
                      ],
                      [
                          -71.455441,
                          45.872098,
                          0
                      ],
                      [
                          -71.463364,
                          45.834509,
                          0
                      ],
                      [
                          -71.463396,
                          45.834517,
                          0
                      ],
                      [
                          -71.466356,
                          45.821665,
                          0
                      ],
                      [
                          -71.459207,
                          45.818124,
                          0
                      ],
                      [
                          -71.45921,
                          45.818122,
                          0
                      ],
                      [
                          -71.461406,
                          45.81662,
                          0
                      ],
                      [
                          -71.497972,
                          45.79189,
                          0
                      ],
                      [
                          -71.52238,
                          45.804357,
                          0
                      ],
                      [
                          -71.522467,
                          45.804402,
                          0
                      ],
                      [
                          -71.541531,
                          45.787832,
                          0
                      ],
                      [
                          -71.541559,
                          45.787806,
                          0
                      ],
                      [
                          -71.594199,
                          45.815361,
                          0
                      ],
                      [
                          -71.59427,
                          45.815399,
                          0
                      ],
                      [
                          -71.590271,
                          45.818525,
                          0
                      ],
                      [
                          -71.592452,
                          45.820077,
                          0
                      ],
                      [
                          -71.593073,
                          45.82059,
                          0
                      ],
                      [
                          -71.593198,
                          45.820876,
                          0
                      ],
                      [
                          -71.593183,
                          45.821263,
                          0
                      ],
                      [
                          -71.593025,
                          45.821553,
                          0
                      ],
                      [
                          -71.592839,
                          45.821808,
                          0
                      ],
                      [
                          -71.600242,
                          45.825426,
                          0
                      ],
                      [
                          -71.603203,
                          45.822827,
                          0
                      ],
                      [
                          -71.617308,
                          45.830495,
                          0
                      ],
                      [
                          -71.617309,
                          45.830495,
                          0
                      ],
                      [
                          -71.635518,
                          45.813913,
                          0
                      ],
                      [
                          -71.769951,
                          45.885289,
                          0
                      ],
                      [
                          -71.769965,
                          45.885296,
                          0
                      ],
                      [
                          -71.769975,
                          45.885287,
                          0
                      ],
                      [
                          -71.844361,
                          45.815017,
                          0
                      ],
                      [
                          -71.851386,
                          45.808437,
                          0
                      ],
                      [
                          -71.853071,
                          45.806895,
                          0
                      ],
                      [
                          -71.856913,
                          45.803423,
                          0
                      ],
                      [
                          -71.858841,
                          45.801383,
                          0
                      ],
                      [
                          -71.86131,
                          45.798709,
                          0
                      ],
                      [
                          -71.861441,
                          45.798567,
                          0
                      ],
                      [
                          -71.861443,
                          45.798568,
                          0
                      ],
                      [
                          -71.861486,
                          45.798593,
                          0
                      ],
                      [
                          -71.869437,
                          45.803193,
                          0
                      ],
                      [
                          -71.869438,
                          45.803192,
                          0
                      ],
                      [
                          -71.869463,
                          45.803166,
                          0
                      ],
                      [
                          -71.874465,
                          45.798347,
                          0
                      ],
                      [
                          -71.874471,
                          45.798352,
                          0
                      ],
                      [
                          -71.874473,
                          45.798352,
                          0
                      ],
                      [
                          -71.878737,
                          45.800955,
                          0
                      ],
                      [
                          -71.879782,
                          45.802512,
                          0
                      ],
                      [
                          -71.880053,
                          45.803919,
                          0
                      ],
                      [
                          -71.880051,
                          45.803939,
                          0
                      ],
                      [
                          -71.88007,
                          45.804127,
                          0
                      ],
                      [
                          -71.880071,
                          45.804129,
                          0
                      ],
                      [
                          -71.881098,
                          45.804464,
                          0
                      ],
                      [
                          -71.881868,
                          45.804403,
                          0
                      ],
                      [
                          -71.882303,
                          45.804314,
                          0
                      ],
                      [
                          -71.883696,
                          45.804408,
                          0
                      ],
                      [
                          -71.883699,
                          45.804408,
                          0
                      ],
                      [
                          -71.883728,
                          45.804405,
                          0
                      ],
                      [
                          -71.885528,
                          45.8038,
                          0
                      ],
                      [
                          -71.88739,
                          45.802247,
                          0
                      ],
                      [
                          -71.88947,
                          45.801135,
                          0
                      ],
                      [
                          -71.891158,
                          45.800726,
                          0
                      ],
                      [
                          -71.893544,
                          45.801049,
                          0
                      ],
                      [
                          -71.895011,
                          45.801649,
                          0
                      ],
                      [
                          -71.896438,
                          45.802353,
                          0
                      ],
                      [
                          -71.900312,
                          45.803853,
                          0
                      ],
                      [
                          -71.904668,
                          45.804821,
                          0
                      ],
                      [
                          -71.906715,
                          45.804512,
                          0
                      ],
                      [
                          -71.909031,
                          45.803719,
                          0
                      ],
                      [
                          -71.911955,
                          45.803111,
                          0
                      ],
                      [
                          -71.917039,
                          45.80169,
                          0
                      ],
                      [
                          -71.917728,
                          45.801131,
                          0
                      ],
                      [
                          -71.917914,
                          45.800632,
                          0
                      ],
                      [
                          -71.917615,
                          45.799062,
                          0
                      ],
                      [
                          -71.918201,
                          45.797846,
                          0
                      ],
                      [
                          -71.921234,
                          45.796308,
                          0
                      ],
                      [
                          -71.921238,
                          45.796311,
                          0
                      ],
                      [
                          -72.029648,
                          45.853417,
                          0
                      ],
                      [
                          -72.029655,
                          45.853411,
                          0
                      ],
                      [
                          -72.206339,
                          45.696388,
                          0
                      ],
                      [
                          -72.206342,
                          45.696384,
                          0
                      ],
                      [
                          -72.209654,
                          45.698875,
                          0
                      ],
                      [
                          -72.209525,
                          45.702332,
                          0
                      ],
                      [
                          -72.213321,
                          45.707087,
                          0
                      ],
                      [
                          -72.213971,
                          45.709261,
                          0
                      ],
                      [
                          -72.215108,
                          45.711337,
                          0
                      ],
                      [
                          -72.218068,
                          45.712707,
                          0
                      ],
                      [
                          -72.220892,
                          45.713099,
                          0
                      ],
                      [
                          -72.220937,
                          45.713117,
                          0
                      ],
                      [
                          -72.223937,
                          45.716404,
                          0
                      ],
                      [
                          -72.223765,
                          45.717838,
                          0
                      ],
                      [
                          -72.218793,
                          45.720638,
                          0
                      ],
                      [
                          -72.214122,
                          45.72327,
                          0
                      ],
                      [
                          -72.212535,
                          45.726091,
                          0
                      ],
                      [
                          -72.213435,
                          45.727734,
                          0
                      ],
                      [
                          -72.215058,
                          45.729903,
                          0
                      ],
                      [
                          -72.215659,
                          45.730839,
                          0
                      ],
                      [
                          -72.215649,
                          45.732644,
                          0
                      ],
                      [
                          -72.218047,
                          45.733145,
                          0
                      ],
                      [
                          -72.218821,
                          45.734443,
                          0
                      ],
                      [
                          -72.221592,
                          45.735275,
                          0
                      ],
                      [
                          -72.224865,
                          45.734546,
                          0
                      ],
                      [
                          -72.228809,
                          45.735456,
                          0
                      ],
                      [
                          -72.23375,
                          45.736229,
                          0
                      ],
                      [
                          -72.235826,
                          45.738119,
                          0
                      ],
                      [
                          -72.236297,
                          45.740022,
                          0
                      ],
                      [
                          -72.234445,
                          45.741191,
                          0
                      ],
                      [
                          -72.228858,
                          45.741322,
                          0
                      ],
                      [
                          -72.226055,
                          45.742837,
                          0
                      ],
                      [
                          -72.221973,
                          45.747933,
                          0
                      ],
                      [
                          -72.221652,
                          45.751709,
                          0
                      ],
                      [
                          -72.222293,
                          45.753622,
                          0
                      ],
                      [
                          -72.227477,
                          45.762978,
                          0
                      ],
                      [
                          -72.229265,
                          45.765181,
                          0
                      ],
                      [
                          -72.234086,
                          45.76858,
                          0
                      ],
                      [
                          -72.240474,
                          45.7765,
                          0
                      ],
                      [
                          -72.245932,
                          45.782194,
                          0
                      ],
                      [
                          -72.254154,
                          45.783721,
                          0
                      ],
                      [
                          -72.262261,
                          45.783578,
                          0
                      ],
                      [
                          -72.266204,
                          45.784677,
                          0
                      ],
                      [
                          -72.270653,
                          45.789795,
                          0
                      ],
                      [
                          -72.273923,
                          45.79565,
                          0
                      ],
                      [
                          -72.275251,
                          45.79816,
                          0
                      ],
                      [
                          -72.279309,
                          45.799796,
                          0
                      ],
                      [
                          -72.283728,
                          45.802441,
                          0
                      ],
                      [
                          -72.284255,
                          45.807039,
                          0
                      ],
                      [
                          -72.285428,
                          45.809992,
                          0
                      ],
                      [
                          -72.28985,
                          45.815903,
                          0
                      ],
                      [
                          -72.292092,
                          45.818481,
                          0
                      ],
                      [
                          -72.295364,
                          45.822079,
                          0
                      ],
                      [
                          -72.296272,
                          45.827056,
                          0
                      ],
                      [
                          -72.29638,
                          45.826968,
                          0
                      ],
                      [
                          -72.347428,
                          45.78512,
                          0
                      ],
                      [
                          -72.359209,
                          45.790859,
                          0
                      ],
                      [
                          -72.363191,
                          45.793924,
                          0
                      ],
                      [
                          -72.365877,
                          45.796791,
                          0
                      ],
                      [
                          -72.369143,
                          45.800285,
                          0
                      ],
                      [
                          -72.373785,
                          45.805162,
                          0
                      ],
                      [
                          -72.378702,
                          45.808403,
                          0
                      ],
                      [
                          -72.401065,
                          45.821015,
                          0
                      ],
                      [
                          -72.442981,
                          45.844341,
                          0
                      ],
                      [
                          -72.46655,
                          45.85729,
                          0
                      ],
                      [
                          -72.466591,
                          45.857317,
                          0
                      ],
                      [
                          -72.473863,
                          45.861402,
                          0
                      ],
                      [
                          -72.48411,
                          45.875484,
                          0
                      ],
                      [
                          -72.492215,
                          45.880495,
                          0
                      ],
                      [
                          -72.553423,
                          45.915398,
                          0
                      ],
                      [
                          -72.520245,
                          45.944453,
                          0
                      ],
                      [
                          -72.520238,
                          45.94446,
                          0
                      ],
                      [
                          -72.532522,
                          45.951865,
                          0
                      ],
                      [
                          -72.538158,
                          45.955677,
                          0
                      ],
                      [
                          -72.540097,
                          45.958956,
                          0
                      ],
                      [
                          -72.541076,
                          45.961355,
                          0
                      ],
                      [
                          -72.543705,
                          45.963185,
                          0
                      ],
                      [
                          -72.550291,
                          45.965974,
                          0
                      ],
                      [
                          -72.550293,
                          45.965974,
                          0
                      ],
                      [
                          -72.551616,
                          45.96699,
                          0
                      ],
                      [
                          -72.552906,
                          45.971307,
                          0
                      ],
                      [
                          -72.553522,
                          45.973643,
                          0
                      ],
                      [
                          -72.557905,
                          45.974622,
                          0
                      ],
                      [
                          -72.563924,
                          45.974401,
                          0
                      ],
                      [
                          -72.568317,
                          45.975289,
                          0
                      ],
                      [
                          -72.575824,
                          45.979175,
                          0
                      ],
                      [
                          -72.577954,
                          45.97971,
                          0
                      ],
                      [
                          -72.578011,
                          45.979711,
                          0
                      ],
                      [
                          -72.583873,
                          45.979284,
                          0
                      ],
                      [
                          -72.588297,
                          45.978902,
                          0
                      ],
                      [
                          -72.592098,
                          45.979568,
                          0
                      ],
                      [
                          -72.595347,
                          45.981355,
                          0
                      ],
                      [
                          -72.598944,
                          45.98281,
                          0
                      ],
                      [
                          -72.603616,
                          45.984095,
                          0
                      ],
                      [
                          -72.608574,
                          45.98597,
                          0
                      ],
                      [
                          -72.620653,
                          45.991875,
                          0
                      ],
                      [
                          -72.623702,
                          45.994008,
                          0
                      ],
                      [
                          -72.627078,
                          45.995954,
                          0
                      ],
                      [
                          -72.63149,
                          45.997797,
                          0
                      ],
                      [
                          -72.634769,
                          46.000038,
                          0
                      ],
                      [
                          -72.637568,
                          46.00232,
                          0
                      ],
                      [
                          -72.640153,
                          46.003682,
                          0
                      ],
                      [
                          -72.642103,
                          46.004454,
                          0
                      ],
                      [
                          -72.646264,
                          46.005003,
                          0
                      ],
                      [
                          -72.65037,
                          46.00548,
                          0
                      ],
                      [
                          -72.656731,
                          46.007155,
                          0
                      ],
                      [
                          -72.656729,
                          46.007157,
                          0
                      ],
                      [
                          -72.646051,
                          46.014084,
                          0
                      ],
                      [
                          -72.646027,
                          46.014092,
                          0
                      ],
                      [
                          -72.643219,
                          46.015851,
                          0
                      ],
                      [
                          -72.643166,
                          46.015798,
                          0
                      ],
                      [
                          -72.642254,
                          46.015109,
                          0
                      ],
                      [
                          -72.641891,
                          46.014829,
                          0
                      ],
                      [
                          -72.641815,
                          46.014878,
                          0
                      ],
                      [
                          -72.630157,
                          46.022409,
                          0
                      ],
                      [
                          -72.630116,
                          46.022378,
                          0
                      ],
                      [
                          -72.629351,
                          46.021816,
                          0
                      ],
                      [
                          -72.629263,
                          46.021874,
                          0
                      ],
                      [
                          -72.61803,
                          46.029206,
                          0
                      ],
                      [
                          -72.600296,
                          46.01614,
                          0
                      ],
                      [
                          -72.600272,
                          46.016122,
                          0
                      ],
                      [
                          -72.593946,
                          46.020528,
                          0
                      ],
                      [
                          -72.588523,
                          46.016578,
                          0
                      ],
                      [
                          -72.577342,
                          46.024265,
                          0
                      ],
                      [
                          -72.5661,
                          46.031872,
                          0
                      ],
                      [
                          -72.551466,
                          46.021463,
                          0
                      ],
                      [
                          -72.540564,
                          46.028645,
                          0
                      ],
                      [
                          -72.532868,
                          46.023015,
                          0
                      ],
                      [
                          -72.533011,
                          46.022915,
                          0
                      ],
                      [
                          -72.544155,
                          46.015492,
                          0
                      ],
                      [
                          -72.507673,
                          45.988714,
                          0
                      ],
                      [
                          -72.507617,
                          45.988673,
                          0
                      ],
                      [
                          -72.479834,
                          46.001389,
                          0
                      ],
                      [
                          -72.479422,
                          46.001155,
                          0
                      ],
                      [
                          -72.479357,
                          46.001157,
                          0
                      ],
                      [
                          -72.445146,
                          45.982503,
                          0
                      ],
                      [
                          -72.424214,
                          45.999031,
                          0
                      ],
                      [
                          -72.422521,
                          46.000429,
                          0
                      ],
                      [
                          -72.427739,
                          46.00345,
                          0
                      ],
                      [
                          -72.415336,
                          46.014224,
                          0
                      ],
                      [
                          -72.415368,
                          46.014241,
                          0
                      ],
                      [
                          -72.420571,
                          46.017134,
                          0
                      ],
                      [
                          -72.420528,
                          46.017175,
                          0
                      ],
                      [
                          -72.409369,
                          46.027787,
                          0
                      ],
                      [
                          -72.411806,
                          46.030204,
                          0
                      ],
                      [
                          -72.410265,
                          46.031551,
                          0
                      ],
                      [
                          -72.407803,
                          46.030254,
                          0
                      ],
                      [
                          -72.406514,
                          46.029482,
                          0
                      ],
                      [
                          -72.405474,
                          46.028469,
                          0
                      ],
                      [
                          -72.403868,
                          46.027523,
                          0
                      ],
                      [
                          -72.403237,
                          46.027266,
                          0
                      ],
                      [
                          -72.402889,
                          46.027031,
                          0
                      ],
                      [
                          -72.402672,
                          46.026775,
                          0
                      ],
                      [
                          -72.402615,
                          46.026362,
                          0
                      ],
                      [
                          -72.398897,
                          46.024138,
                          0
                      ],
                      [
                          -72.397001,
                          46.023036,
                          0
                      ],
                      [
                          -72.383231,
                          46.034783,
                          0
                      ],
                      [
                          -72.368517,
                          46.047797,
                          0
                      ],
                      [
                          -72.368504,
                          46.047809,
                          0
                      ],
                      [
                          -72.367881,
                          46.047442,
                          0
                      ],
                      [
                          -72.367854,
                          46.047427,
                          0
                      ],
                      [
                          -72.354448,
                          46.058799,
                          0
                      ],
                      [
                          -72.350015,
                          46.056296,
                          0
                      ],
                      [
                          -72.349469,
                          46.056217,
                          0
                      ],
                      [
                          -72.349497,
                          46.055973,
                          0
                      ],
                      [
                          -72.327958,
                          46.043467,
                          0
                      ],
                      [
                          -72.307265,
                          46.03147,
                          0
                      ],
                      [
                          -72.301438,
                          46.036623,
                          0
                      ],
                      [
                          -72.291539,
                          46.045374,
                          0
                      ],
                      [
                          -72.290504,
                          46.046282,
                          0
                      ],
                      [
                          -72.289181,
                          46.046796,
                          0
                      ],
                      [
                          -72.286223,
                          46.048388,
                          0
                      ],
                      [
                          -72.267197,
                          46.058518,
                          0
                      ],
                      [
                          -72.258634,
                          46.063031,
                          0
                      ],
                      [
                          -72.257993,
                          46.06344,
                          0
                      ],
                      [
                          -72.25542,
                          46.065302,
                          0
                      ],
                      [
                          -72.254953,
                          46.065563,
                          0
                      ],
                      [
                          -72.243725,
                          46.07382,
                          0
                      ],
                      [
                          -72.239455,
                          46.076859,
                          0
                      ],
                      [
                          -72.23914,
                          46.077168,
                          0
                      ],
                      [
                          -72.239119,
                          46.077189,
                          0
                      ],
                      [
                          -72.23459,
                          46.080512,
                          0
                      ],
                      [
                          -72.230134,
                          46.083754,
                          0
                      ],
                      [
                          -72.229734,
                          46.083814,
                          0
                      ],
                      [
                          -72.225169,
                          46.087282,
                          0
                      ],
                      [
                          -72.224929,
                          46.087124,
                          0
                      ],
                      [
                          -72.215864,
                          46.081265,
                          0
                      ],
                      [
                          -72.215852,
                          46.081258,
                          0
                      ],
                      [
                          -72.21586,
                          46.081518,
                          0
                      ],
                      [
                          -72.21589,
                          46.082645,
                          0
                      ],
                      [
                          -72.214949,
                          46.082879,
                          0
                      ],
                      [
                          -72.214872,
                          46.089766,
                          0
                      ],
                      [
                          -72.214868,
                          46.090009,
                          0
                      ],
                      [
                          -72.214786,
                          46.094662,
                          0
                      ],
                      [
                          -72.214786,
                          46.094663,
                          0
                      ],
                      [
                          -72.214733,
                          46.097649,
                          0
                      ],
                      [
                          -72.20407,
                          46.099828,
                          0
                      ],
                      [
                          -72.204032,
                          46.099837,
                          0
                      ],
                      [
                          -72.191944,
                          46.102308,
                          0
                      ],
                      [
                          -72.192069,
                          46.091568,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Drummond-Bois-Francs",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.809076,
                          45.554569,
                          0
                      ],
                      [
                          -73.791662,
                          45.559667,
                          0
                      ],
                      [
                          -73.790923,
                          45.558622,
                          0
                      ],
                      [
                          -73.776253,
                          45.545795,
                          0
                      ],
                      [
                          -73.775771,
                          45.545497,
                          0
                      ],
                      [
                          -73.775558,
                          45.545388,
                          0
                      ],
                      [
                          -73.775525,
                          45.545158,
                          0
                      ],
                      [
                          -73.775226,
                          45.545055,
                          0
                      ],
                      [
                          -73.775176,
                          45.545004,
                          0
                      ],
                      [
                          -73.775249,
                          45.544831,
                          0
                      ],
                      [
                          -73.775078,
                          45.544563,
                          0
                      ],
                      [
                          -73.774298,
                          45.543927,
                          0
                      ],
                      [
                          -73.774219,
                          45.543608,
                          0
                      ],
                      [
                          -73.77362,
                          45.543102,
                          0
                      ],
                      [
                          -73.773382,
                          45.543,
                          0
                      ],
                      [
                          -73.773094,
                          45.54255,
                          0
                      ],
                      [
                          -73.771726,
                          45.541005,
                          0
                      ],
                      [
                          -73.771258,
                          45.540153,
                          0
                      ],
                      [
                          -73.771077,
                          45.539743,
                          0
                      ],
                      [
                          -73.771146,
                          45.5394,
                          0
                      ],
                      [
                          -73.771353,
                          45.539151,
                          0
                      ],
                      [
                          -73.771274,
                          45.538764,
                          0
                      ],
                      [
                          -73.770462,
                          45.538002,
                          0
                      ],
                      [
                          -73.772198,
                          45.536997,
                          0
                      ],
                      [
                          -73.773974,
                          45.535999,
                          0
                      ],
                      [
                          -73.775644,
                          45.535359,
                          0
                      ],
                      [
                          -73.776392,
                          45.534442,
                          0
                      ],
                      [
                          -73.777394,
                          45.533704,
                          0
                      ],
                      [
                          -73.778174,
                          45.533207,
                          0
                      ],
                      [
                          -73.778578,
                          45.533112,
                          0
                      ],
                      [
                          -73.778622,
                          45.53302,
                          0
                      ],
                      [
                          -73.778388,
                          45.53229,
                          0
                      ],
                      [
                          -73.778364,
                          45.532255,
                          0
                      ],
                      [
                          -73.782054,
                          45.531564,
                          0
                      ],
                      [
                          -73.784015,
                          45.530946,
                          0
                      ],
                      [
                          -73.774902,
                          45.521788,
                          0
                      ],
                      [
                          -73.76472,
                          45.511629,
                          0
                      ],
                      [
                          -73.775667,
                          45.511543,
                          0
                      ],
                      [
                          -73.795909,
                          45.512767,
                          0
                      ],
                      [
                          -73.799638,
                          45.515327,
                          0
                      ],
                      [
                          -73.805086,
                          45.516159,
                          0
                      ],
                      [
                          -73.812881,
                          45.517609,
                          0
                      ],
                      [
                          -73.821466,
                          45.516018,
                          0
                      ],
                      [
                          -73.828501,
                          45.518505,
                          0
                      ],
                      [
                          -73.844056,
                          45.518591,
                          0
                      ],
                      [
                          -73.846376,
                          45.517561,
                          0
                      ],
                      [
                          -73.846382,
                          45.517555,
                          0
                      ],
                      [
                          -73.849826,
                          45.513668,
                          0
                      ],
                      [
                          -73.852536,
                          45.511441,
                          0
                      ],
                      [
                          -73.854515,
                          45.512183,
                          0
                      ],
                      [
                          -73.856581,
                          45.515069,
                          0
                      ],
                      [
                          -73.862267,
                          45.517993,
                          0
                      ],
                      [
                          -73.863215,
                          45.519758,
                          0
                      ],
                      [
                          -73.878328,
                          45.520606,
                          0
                      ],
                      [
                          -73.88216,
                          45.521991,
                          0
                      ],
                      [
                          -73.89151,
                          45.522446,
                          0
                      ],
                      [
                          -73.896139,
                          45.525546,
                          0
                      ],
                      [
                          -73.896139,
                          45.525546,
                          0
                      ],
                      [
                          -73.891375,
                          45.527873,
                          0
                      ],
                      [
                          -73.886227,
                          45.531357,
                          0
                      ],
                      [
                          -73.882307,
                          45.535079,
                          0
                      ],
                      [
                          -73.882307,
                          45.535079,
                          0
                      ],
                      [
                          -73.881052,
                          45.536177,
                          0
                      ],
                      [
                          -73.882497,
                          45.537889,
                          0
                      ],
                      [
                          -73.883494,
                          45.5418,
                          0
                      ],
                      [
                          -73.886288,
                          45.549093,
                          0
                      ],
                      [
                          -73.886318,
                          45.551508,
                          0
                      ],
                      [
                          -73.884341,
                          45.55493,
                          0
                      ],
                      [
                          -73.884783,
                          45.558173,
                          0
                      ],
                      [
                          -73.882032,
                          45.560558,
                          0
                      ],
                      [
                          -73.87473,
                          45.561366,
                          0
                      ],
                      [
                          -73.866784,
                          45.564627,
                          0
                      ],
                      [
                          -73.863134,
                          45.56678,
                          0
                      ],
                      [
                          -73.861023,
                          45.569775,
                          0
                      ],
                      [
                          -73.858192,
                          45.571738,
                          0
                      ],
                      [
                          -73.85335,
                          45.574394,
                          0
                      ],
                      [
                          -73.850429,
                          45.576055,
                          0
                      ],
                      [
                          -73.849147,
                          45.577135,
                          0
                      ],
                      [
                          -73.845665,
                          45.578131,
                          0
                      ],
                      [
                          -73.842757,
                          45.57872,
                          0
                      ],
                      [
                          -73.841909,
                          45.578875,
                          0
                      ],
                      [
                          -73.840311,
                          45.579169,
                          0
                      ],
                      [
                          -73.838764,
                          45.579934,
                          0
                      ],
                      [
                          -73.837682,
                          45.580845,
                          0
                      ],
                      [
                          -73.837682,
                          45.580845,
                          0
                      ],
                      [
                          -73.824122,
                          45.568392,
                          0
                      ],
                      [
                          -73.809076,
                          45.554569,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Fabre",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.577324,
                          45.544108,
                          0
                      ],
                      [
                          -73.577792,
                          45.543434,
                          0
                      ],
                      [
                          -73.579327,
                          45.541846,
                          0
                      ],
                      [
                          -73.58066,
                          45.54123,
                          0
                      ],
                      [
                          -73.582719,
                          45.540597,
                          0
                      ],
                      [
                          -73.584048,
                          45.539975,
                          0
                      ],
                      [
                          -73.585416,
                          45.5391,
                          0
                      ],
                      [
                          -73.586315,
                          45.538344,
                          0
                      ],
                      [
                          -73.591873,
                          45.533588,
                          0
                      ],
                      [
                          -73.596069,
                          45.530184,
                          0
                      ],
                      [
                          -73.598024,
                          45.529261,
                          0
                      ],
                      [
                          -73.601292,
                          45.528466,
                          0
                      ],
                      [
                          -73.605709,
                          45.528356,
                          0
                      ],
                      [
                          -73.607129,
                          45.528235,
                          0
                      ],
                      [
                          -73.609458,
                          45.52726,
                          0
                      ],
                      [
                          -73.61232,
                          45.525679,
                          0
                      ],
                      [
                          -73.616531,
                          45.527531,
                          0
                      ],
                      [
                          -73.617294,
                          45.527784,
                          0
                      ],
                      [
                          -73.617294,
                          45.527784,
                          0
                      ],
                      [
                          -73.617064,
                          45.528431,
                          0
                      ],
                      [
                          -73.617133,
                          45.528898,
                          0
                      ],
                      [
                          -73.617627,
                          45.529668,
                          0
                      ],
                      [
                          -73.61802,
                          45.530063,
                          0
                      ],
                      [
                          -73.619224,
                          45.530722,
                          0
                      ],
                      [
                          -73.621522,
                          45.531115,
                          0
                      ],
                      [
                          -73.6182,
                          45.53479,
                          0
                      ],
                      [
                          -73.617248,
                          45.535292,
                          0
                      ],
                      [
                          -73.616862,
                          45.5357,
                          0
                      ],
                      [
                          -73.614433,
                          45.538299,
                          0
                      ],
                      [
                          -73.613895,
                          45.539037,
                          0
                      ],
                      [
                          -73.612652,
                          45.541685,
                          0
                      ],
                      [
                          -73.607051,
                          45.547945,
                          0
                      ],
                      [
                          -73.607051,
                          45.547946,
                          0
                      ],
                      [
                          -73.60705,
                          45.547945,
                          0
                      ],
                      [
                          -73.603546,
                          45.546348,
                          0
                      ],
                      [
                          -73.603546,
                          45.546348,
                          0
                      ],
                      [
                          -73.596376,
                          45.554595,
                          0
                      ],
                      [
                          -73.595926,
                          45.554438,
                          0
                      ],
                      [
                          -73.574898,
                          45.548103,
                          0
                      ],
                      [
                          -73.577324,
                          45.544108,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Gouin",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -72.794771,
                          45.457954,
                          0
                      ],
                      [
                          -72.699251,
                          45.457365,
                          0
                      ],
                      [
                          -72.699237,
                          45.457364,
                          0
                      ],
                      [
                          -72.700418,
                          45.448452,
                          0
                      ],
                      [
                          -72.7004,
                          45.448452,
                          0
                      ],
                      [
                          -72.6634,
                          45.447352,
                          0
                      ],
                      [
                          -72.663401,
                          45.44734,
                          0
                      ],
                      [
                          -72.664424,
                          45.420572,
                          0
                      ],
                      [
                          -72.664283,
                          45.420559,
                          0
                      ],
                      [
                          -72.664287,
                          45.420423,
                          0
                      ],
                      [
                          -72.667706,
                          45.337874,
                          0
                      ],
                      [
                          -72.756356,
                          45.341024,
                          0
                      ],
                      [
                          -72.75472,
                          45.354545,
                          0
                      ],
                      [
                          -72.816743,
                          45.355073,
                          0
                      ],
                      [
                          -72.812966,
                          45.368398,
                          0
                      ],
                      [
                          -72.84582,
                          45.368438,
                          0
                      ],
                      [
                          -72.823765,
                          45.431218,
                          0
                      ],
                      [
                          -72.819871,
                          45.456636,
                          0
                      ],
                      [
                          -72.819868,
                          45.456659,
                          0
                      ],
                      [
                          -72.819862,
                          45.456699,
                          0
                      ],
                      [
                          -72.819861,
                          45.456699,
                          0
                      ],
                      [
                          -72.794771,
                          45.457954,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Granby",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.855539,
                          45.653642,
                          0
                      ],
                      [
                          -73.855492,
                          45.653604,
                          0
                      ],
                      [
                          -73.85513,
                          45.653881,
                          0
                      ],
                      [
                          -73.84534,
                          45.647299,
                          0
                      ],
                      [
                          -73.842411,
                          45.649121,
                          0
                      ],
                      [
                          -73.841597,
                          45.650864,
                          0
                      ],
                      [
                          -73.840121,
                          45.649961,
                          0
                      ],
                      [
                          -73.840117,
                          45.651546,
                          0
                      ],
                      [
                          -73.840088,
                          45.651527,
                          0
                      ],
                      [
                          -73.838179,
                          45.650279,
                          0
                      ],
                      [
                          -73.833972,
                          45.653325,
                          0
                      ],
                      [
                          -73.834734,
                          45.653832,
                          0
                      ],
                      [
                          -73.8345,
                          45.65413,
                          0
                      ],
                      [
                          -73.842319,
                          45.659035,
                          0
                      ],
                      [
                          -73.839897,
                          45.660885,
                          0
                      ],
                      [
                          -73.839871,
                          45.660904,
                          0
                      ],
                      [
                          -73.813055,
                          45.642924,
                          0
                      ],
                      [
                          -73.813055,
                          45.642924,
                          0
                      ],
                      [
                          -73.812672,
                          45.644775,
                          0
                      ],
                      [
                          -73.811226,
                          45.645536,
                          0
                      ],
                      [
                          -73.811223,
                          45.645538,
                          0
                      ],
                      [
                          -73.810322,
                          45.646955,
                          0
                      ],
                      [
                          -73.809239,
                          45.647258,
                          0
                      ],
                      [
                          -73.8086,
                          45.647673,
                          0
                      ],
                      [
                          -73.807294,
                          45.647727,
                          0
                      ],
                      [
                          -73.806978,
                          45.647792,
                          0
                      ],
                      [
                          -73.806965,
                          45.647982,
                          0
                      ],
                      [
                          -73.807541,
                          45.648664,
                          0
                      ],
                      [
                          -73.806794,
                          45.6492,
                          0
                      ],
                      [
                          -73.80736,
                          45.649571,
                          0
                      ],
                      [
                          -73.807541,
                          45.649781,
                          0
                      ],
                      [
                          -73.807409,
                          45.649893,
                          0
                      ],
                      [
                          -73.806891,
                          45.649766,
                          0
                      ],
                      [
                          -73.806467,
                          45.649594,
                          0
                      ],
                      [
                          -73.805594,
                          45.65019,
                          0
                      ],
                      [
                          -73.805592,
                          45.650191,
                          0
                      ],
                      [
                          -73.804162,
                          45.650664,
                          0
                      ],
                      [
                          -73.802404,
                          45.65122,
                          0
                      ],
                      [
                          -73.801327,
                          45.651144,
                          0
                      ],
                      [
                          -73.79982,
                          45.650823,
                          0
                      ],
                      [
                          -73.799682,
                          45.650684,
                          0
                      ],
                      [
                          -73.79964,
                          45.65027,
                          0
                      ],
                      [
                          -73.799382,
                          45.649712,
                          0
                      ],
                      [
                          -73.798861,
                          45.649063,
                          0
                      ],
                      [
                          -73.79828,
                          45.649192,
                          0
                      ],
                      [
                          -73.798145,
                          45.649827,
                          0
                      ],
                      [
                          -73.796768,
                          45.650875,
                          0
                      ],
                      [
                          -73.795091,
                          45.65132,
                          0
                      ],
                      [
                          -73.793194,
                          45.651556,
                          0
                      ],
                      [
                          -73.79082,
                          45.651035,
                          0
                      ],
                      [
                          -73.802224,
                          45.658564,
                          0
                      ],
                      [
                          -73.793924,
                          45.664775,
                          0
                      ],
                      [
                          -73.793918,
                          45.664779,
                          0
                      ],
                      [
                          -73.776272,
                          45.653301,
                          0
                      ],
                      [
                          -73.775129,
                          45.654072,
                          0
                      ],
                      [
                          -73.773356,
                          45.652921,
                          0
                      ],
                      [
                          -73.77392,
                          45.652499,
                          0
                      ],
                      [
                          -73.768055,
                          45.648804,
                          0
                      ],
                      [
                          -73.768051,
                          45.648802,
                          0
                      ],
                      [
                          -73.777296,
                          45.64136,
                          0
                      ],
                      [
                          -73.778327,
                          45.636113,
                          0
                      ],
                      [
                          -73.78797,
                          45.630167,
                          0
                      ],
                      [
                          -73.788142,
                          45.623384,
                          0
                      ],
                      [
                          -73.795553,
                          45.615144,
                          0
                      ],
                      [
                          -73.804701,
                          45.610369,
                          0
                      ],
                      [
                          -73.808554,
                          45.610981,
                          0
                      ],
                      [
                          -73.818822,
                          45.607517,
                          0
                      ],
                      [
                          -73.822614,
                          45.608531,
                          0
                      ],
                      [
                          -73.825357,
                          45.6074,
                          0
                      ],
                      [
                          -73.826054,
                          45.607157,
                          0
                      ],
                      [
                          -73.825671,
                          45.602778,
                          0
                      ],
                      [
                          -73.825831,
                          45.598891,
                          0
                      ],
                      [
                          -73.829591,
                          45.597047,
                          0
                      ],
                      [
                          -73.831816,
                          45.594835,
                          0
                      ],
                      [
                          -73.832551,
                          45.586534,
                          0
                      ],
                      [
                          -73.836037,
                          45.584322,
                          0
                      ],
                      [
                          -73.837663,
                          45.580861,
                          0
                      ],
                      [
                          -73.837682,
                          45.580845,
                          0
                      ],
                      [
                          -73.838764,
                          45.579934,
                          0
                      ],
                      [
                          -73.840311,
                          45.579169,
                          0
                      ],
                      [
                          -73.841909,
                          45.578875,
                          0
                      ],
                      [
                          -73.842757,
                          45.57872,
                          0
                      ],
                      [
                          -73.843037,
                          45.579431,
                          0
                      ],
                      [
                          -73.843037,
                          45.579431,
                          0
                      ],
                      [
                          -73.843253,
                          45.580449,
                          0
                      ],
                      [
                          -73.843717,
                          45.581226,
                          0
                      ],
                      [
                          -73.843677,
                          45.58156,
                          0
                      ],
                      [
                          -73.843184,
                          45.582019,
                          0
                      ],
                      [
                          -73.842357,
                          45.582197,
                          0
                      ],
                      [
                          -73.843161,
                          45.582749,
                          0
                      ],
                      [
                          -73.84256,
                          45.58277,
                          0
                      ],
                      [
                          -73.841659,
                          45.582557,
                          0
                      ],
                      [
                          -73.840736,
                          45.583039,
                          0
                      ],
                      [
                          -73.847831,
                          45.58914,
                          0
                      ],
                      [
                          -73.849615,
                          45.587684,
                          0
                      ],
                      [
                          -73.849615,
                          45.587684,
                          0
                      ],
                      [
                          -73.849615,
                          45.587684,
                          0
                      ],
                      [
                          -73.853087,
                          45.590111,
                          0
                      ],
                      [
                          -73.853883,
                          45.589717,
                          0
                      ],
                      [
                          -73.86922,
                          45.599584,
                          0
                      ],
                      [
                          -73.870272,
                          45.598854,
                          0
                      ],
                      [
                          -73.870183,
                          45.598703,
                          0
                      ],
                      [
                          -73.875302,
                          45.597271,
                          0
                      ],
                      [
                          -73.880375,
                          45.602343,
                          0
                      ],
                      [
                          -73.880497,
                          45.606716,
                          0
                      ],
                      [
                          -73.896495,
                          45.616992,
                          0
                      ],
                      [
                          -73.931384,
                          45.639443,
                          0
                      ],
                      [
                          -73.929848,
                          45.640437,
                          0
                      ],
                      [
                          -73.919635,
                          45.633282,
                          0
                      ],
                      [
                          -73.91835,
                          45.633796,
                          0
                      ],
                      [
                          -73.91729,
                          45.633699,
                          0
                      ],
                      [
                          -73.915742,
                          45.633771,
                          0
                      ],
                      [
                          -73.904263,
                          45.635891,
                          0
                      ],
                      [
                          -73.903385,
                          45.635687,
                          0
                      ],
                      [
                          -73.899834,
                          45.636257,
                          0
                      ],
                      [
                          -73.899777,
                          45.636529,
                          0
                      ],
                      [
                          -73.898519,
                          45.636709,
                          0
                      ],
                      [
                          -73.896174,
                          45.637296,
                          0
                      ],
                      [
                          -73.893076,
                          45.637604,
                          0
                      ],
                      [
                          -73.895232,
                          45.639004,
                          0
                      ],
                      [
                          -73.893319,
                          45.639721,
                          0
                      ],
                      [
                          -73.892602,
                          45.639232,
                          0
                      ],
                      [
                          -73.890524,
                          45.639766,
                          0
                      ],
                      [
                          -73.890158,
                          45.640058,
                          0
                      ],
                      [
                          -73.890619,
                          45.640393,
                          0
                      ],
                      [
                          -73.8899,
                          45.640742,
                          0
                      ],
                      [
                          -73.88932,
                          45.640908,
                          0
                      ],
                      [
                          -73.88819,
                          45.64107,
                          0
                      ],
                      [
                          -73.88493,
                          45.638775,
                          0
                      ],
                      [
                          -73.883922,
                          45.639216,
                          0
                      ],
                      [
                          -73.883021,
                          45.639014,
                          0
                      ],
                      [
                          -73.881721,
                          45.63896,
                          0
                      ],
                      [
                          -73.880635,
                          45.638675,
                          0
                      ],
                      [
                          -73.880194,
                          45.638619,
                          0
                      ],
                      [
                          -73.879913,
                          45.638809,
                          0
                      ],
                      [
                          -73.879781,
                          45.639299,
                          0
                      ],
                      [
                          -73.879526,
                          45.639687,
                          0
                      ],
                      [
                          -73.878742,
                          45.640021,
                          0
                      ],
                      [
                          -73.876528,
                          45.640837,
                          0
                      ],
                      [
                          -73.873947,
                          45.641378,
                          0
                      ],
                      [
                          -73.871529,
                          45.64186,
                          0
                      ],
                      [
                          -73.88244,
                          45.649157,
                          0
                      ],
                      [
                          -73.87809,
                          45.649726,
                          0
                      ],
                      [
                          -73.870096,
                          45.644442,
                          0
                      ],
                      [
                          -73.867106,
                          45.642503,
                          0
                      ],
                      [
                          -73.866484,
                          45.642597,
                          0
                      ],
                      [
                          -73.865035,
                          45.643512,
                          0
                      ],
                      [
                          -73.86392,
                          45.643731,
                          0
                      ],
                      [
                          -73.865796,
                          45.645008,
                          0
                      ],
                      [
                          -73.865388,
                          45.645049,
                          0
                      ],
                      [
                          -73.866028,
                          45.645548,
                          0
                      ],
                      [
                          -73.866504,
                          45.645496,
                          0
                      ],
                      [
                          -73.880912,
                          45.655103,
                          0
                      ],
                      [
                          -73.870091,
                          45.663287,
                          0
                      ],
                      [
                          -73.855539,
                          45.653642,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Groulx",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.540715,
                          45.528103,
                          0
                      ],
                      [
                          -73.543048,
                          45.528937,
                          0
                      ],
                      [
                          -73.563005,
                          45.537405,
                          0
                      ],
                      [
                          -73.561627,
                          45.539034,
                          0
                      ],
                      [
                          -73.561419,
                          45.539564,
                          0
                      ],
                      [
                          -73.561459,
                          45.540144,
                          0
                      ],
                      [
                          -73.56172,
                          45.543488,
                          0
                      ],
                      [
                          -73.561286,
                          45.54429,
                          0
                      ],
                      [
                          -73.554874,
                          45.554822,
                          0
                      ],
                      [
                          -73.554406,
                          45.55572,
                          0
                      ],
                      [
                          -73.554293,
                          45.556908,
                          0
                      ],
                      [
                          -73.554293,
                          45.55693,
                          0
                      ],
                      [
                          -73.554584,
                          45.564727,
                          0
                      ],
                      [
                          -73.554188,
                          45.565788,
                          0
                      ],
                      [
                          -73.552548,
                          45.568224,
                          0
                      ],
                      [
                          -73.512173,
                          45.555364,
                          0
                      ],
                      [
                          -73.519986,
                          45.545352,
                          0
                      ],
                      [
                          -73.531445,
                          45.536469,
                          0
                      ],
                      [
                          -73.530887,
                          45.534675,
                          0
                      ],
                      [
                          -73.536525,
                          45.533661,
                          0
                      ],
                      [
                          -73.536525,
                          45.533661,
                          0
                      ],
                      [
                          -73.536549,
                          45.533647,
                          0
                      ],
                      [
                          -73.538986,
                          45.531499,
                          0
                      ],
                      [
                          -73.540715,
                          45.528103,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Hochelaga-Maisonneuve",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -75.769744,
                          45.482523,
                          0
                      ],
                      [
                          -75.769785,
                          45.481944,
                          0
                      ],
                      [
                          -75.768784,
                          45.480733,
                          0
                      ],
                      [
                          -75.768172,
                          45.480892,
                          0
                      ],
                      [
                          -75.766917,
                          45.479812,
                          0
                      ],
                      [
                          -75.765132,
                          45.478834,
                          0
                      ],
                      [
                          -75.760185,
                          45.477525,
                          0
                      ],
                      [
                          -75.759295,
                          45.476391,
                          0
                      ],
                      [
                          -75.758165,
                          45.476855,
                          0
                      ],
                      [
                          -75.757753,
                          45.476589,
                          0
                      ],
                      [
                          -75.757248,
                          45.476669,
                          0
                      ],
                      [
                          -75.757242,
                          45.477085,
                          0
                      ],
                      [
                          -75.755281,
                          45.477819,
                          0
                      ],
                      [
                          -75.75449,
                          45.478943,
                          0
                      ],
                      [
                          -75.753518,
                          45.479571,
                          0
                      ],
                      [
                          -75.752445,
                          45.48081,
                          0
                      ],
                      [
                          -75.750699,
                          45.479945,
                          0
                      ],
                      [
                          -75.749177,
                          45.478537,
                          0
                      ],
                      [
                          -75.74808,
                          45.47689,
                          0
                      ],
                      [
                          -75.7435,
                          45.477221,
                          0
                      ],
                      [
                          -75.744953,
                          45.479435,
                          0
                      ],
                      [
                          -75.74747,
                          45.481179,
                          0
                      ],
                      [
                          -75.748938,
                          45.481921,
                          0
                      ],
                      [
                          -75.750716,
                          45.482163,
                          0
                      ],
                      [
                          -75.750835,
                          45.485394,
                          0
                      ],
                      [
                          -75.750427,
                          45.486192,
                          0
                      ],
                      [
                          -75.748737,
                          45.487143,
                          0
                      ],
                      [
                          -75.744333,
                          45.482309,
                          0
                      ],
                      [
                          -75.738767,
                          45.476775,
                          0
                      ],
                      [
                          -75.735026,
                          45.475266,
                          0
                      ],
                      [
                          -75.731357,
                          45.473925,
                          0
                      ],
                      [
                          -75.727867,
                          45.470661,
                          0
                      ],
                      [
                          -75.725438,
                          45.464012,
                          0
                      ],
                      [
                          -75.722029,
                          45.460123,
                          0
                      ],
                      [
                          -75.719691,
                          45.458229,
                          0
                      ],
                      [
                          -75.716189,
                          45.457192,
                          0
                      ],
                      [
                          -75.713511,
                          45.45714,
                          0
                      ],
                      [
                          -75.709888,
                          45.458173,
                          0
                      ],
                      [
                          -75.704566,
                          45.45824,
                          0
                      ],
                      [
                          -75.701481,
                          45.457006,
                          0
                      ],
                      [
                          -75.693972,
                          45.453761,
                          0
                      ],
                      [
                          -75.691166,
                          45.453835,
                          0
                      ],
                      [
                          -75.689947,
                          45.454836,
                          0
                      ],
                      [
                          -75.689507,
                          45.454609,
                          0
                      ],
                      [
                          -75.695944,
                          45.447691,
                          0
                      ],
                      [
                          -75.700975,
                          45.438887,
                          0
                      ],
                      [
                          -75.703555,
                          45.429882,
                          0
                      ],
                      [
                          -75.705134,
                          45.424003,
                          0
                      ],
                      [
                          -75.721261,
                          45.420471,
                          0
                      ],
                      [
                          -75.72298,
                          45.417138,
                          0
                      ],
                      [
                          -75.731529,
                          45.416852,
                          0
                      ],
                      [
                          -75.731535,
                          45.41685,
                          0
                      ],
                      [
                          -75.744827,
                          45.412874,
                          0
                      ],
                      [
                          -75.756633,
                          45.412221,
                          0
                      ],
                      [
                          -75.760781,
                          45.410431,
                          0
                      ],
                      [
                          -75.767692,
                          45.413939,
                          0
                      ],
                      [
                          -75.773611,
                          45.411868,
                          0
                      ],
                      [
                          -75.775778,
                          45.428981,
                          0
                      ],
                      [
                          -75.775972,
                          45.432034,
                          0
                      ],
                      [
                          -75.780252,
                          45.430944,
                          0
                      ],
                      [
                          -75.784227,
                          45.43054,
                          0
                      ],
                      [
                          -75.79384,
                          45.430948,
                          0
                      ],
                      [
                          -75.795313,
                          45.430776,
                          0
                      ],
                      [
                          -75.797606,
                          45.429846,
                          0
                      ],
                      [
                          -75.799437,
                          45.427816,
                          0
                      ],
                      [
                          -75.801176,
                          45.419755,
                          0
                      ],
                      [
                          -75.80159,
                          45.418818,
                          0
                      ],
                      [
                          -75.803571,
                          45.416857,
                          0
                      ],
                      [
                          -75.806026,
                          45.415815,
                          0
                      ],
                      [
                          -75.808787,
                          45.415435,
                          0
                      ],
                      [
                          -75.80977,
                          45.421389,
                          0
                      ],
                      [
                          -75.811594,
                          45.423869,
                          0
                      ],
                      [
                          -75.814374,
                          45.425787,
                          0
                      ],
                      [
                          -75.817303,
                          45.429916,
                          0
                      ],
                      [
                          -75.817848,
                          45.430816,
                          0
                      ],
                      [
                          -75.81935,
                          45.441217,
                          0
                      ],
                      [
                          -75.822302,
                          45.463916,
                          0
                      ],
                      [
                          -75.821204,
                          45.463596,
                          0
                      ],
                      [
                          -75.820329,
                          45.462975,
                          0
                      ],
                      [
                          -75.819237,
                          45.462474,
                          0
                      ],
                      [
                          -75.819146,
                          45.462578,
                          0
                      ],
                      [
                          -75.819164,
                          45.462631,
                          0
                      ],
                      [
                          -75.819344,
                          45.462757,
                          0
                      ],
                      [
                          -75.819932,
                          45.463105,
                          0
                      ],
                      [
                          -75.820215,
                          45.463459,
                          0
                      ],
                      [
                          -75.820072,
                          45.464522,
                          0
                      ],
                      [
                          -75.820141,
                          45.464807,
                          0
                      ],
                      [
                          -75.820571,
                          45.46547,
                          0
                      ],
                      [
                          -75.820956,
                          45.466297,
                          0
                      ],
                      [
                          -75.820938,
                          45.466965,
                          0
                      ],
                      [
                          -75.820723,
                          45.467482,
                          0
                      ],
                      [
                          -75.819825,
                          45.468666,
                          0
                      ],
                      [
                          -75.819887,
                          45.470262,
                          0
                      ],
                      [
                          -75.820735,
                          45.471562,
                          0
                      ],
                      [
                          -75.796407,
                          45.473347,
                          0
                      ],
                      [
                          -75.796402,
                          45.473318,
                          0
                      ],
                      [
                          -75.793999,
                          45.458763,
                          0
                      ],
                      [
                          -75.780631,
                          45.459715,
                          0
                      ],
                      [
                          -75.782852,
                          45.473316,
                          0
                      ],
                      [
                          -75.784789,
                          45.4746,
                          0
                      ],
                      [
                          -75.786945,
                          45.475625,
                          0
                      ],
                      [
                          -75.788544,
                          45.476691,
                          0
                      ],
                      [
                          -75.79009,
                          45.477102,
                          0
                      ],
                      [
                          -75.79032,
                          45.47815,
                          0
                      ],
                      [
                          -75.790853,
                          45.478125,
                          0
                      ],
                      [
                          -75.790853,
                          45.478013,
                          0
                      ],
                      [
                          -75.792445,
                          45.478441,
                          0
                      ],
                      [
                          -75.79252,
                          45.478285,
                          0
                      ],
                      [
                          -75.792729,
                          45.478369,
                          0
                      ],
                      [
                          -75.793406,
                          45.478732,
                          0
                      ],
                      [
                          -75.793353,
                          45.478865,
                          0
                      ],
                      [
                          -75.793857,
                          45.479143,
                          0
                      ],
                      [
                          -75.794016,
                          45.478918,
                          0
                      ],
                      [
                          -75.794373,
                          45.479616,
                          0
                      ],
                      [
                          -75.795147,
                          45.479883,
                          0
                      ],
                      [
                          -75.796704,
                          45.481666,
                          0
                      ],
                      [
                          -75.796878,
                          45.482441,
                          0
                      ],
                      [
                          -75.795816,
                          45.483777,
                          0
                      ],
                      [
                          -75.794698,
                          45.483594,
                          0
                      ],
                      [
                          -75.792804,
                          45.48205,
                          0
                      ],
                      [
                          -75.790844,
                          45.480836,
                          0
                      ],
                      [
                          -75.791575,
                          45.484843,
                          0
                      ],
                      [
                          -75.789339,
                          45.483977,
                          0
                      ],
                      [
                          -75.788345,
                          45.484047,
                          0
                      ],
                      [
                          -75.787794,
                          45.484049,
                          0
                      ],
                      [
                          -75.785534,
                          45.483773,
                          0
                      ],
                      [
                          -75.784538,
                          45.484051,
                          0
                      ],
                      [
                          -75.784219,
                          45.482038,
                          0
                      ],
                      [
                          -75.777378,
                          45.482571,
                          0
                      ],
                      [
                          -75.778601,
                          45.490173,
                          0
                      ],
                      [
                          -75.777008,
                          45.490267,
                          0
                      ],
                      [
                          -75.777021,
                          45.49008,
                          0
                      ],
                      [
                          -75.77812,
                          45.489386,
                          0
                      ],
                      [
                          -75.77802,
                          45.489184,
                          0
                      ],
                      [
                          -75.776279,
                          45.488492,
                          0
                      ],
                      [
                          -75.77524,
                          45.488005,
                          0
                      ],
                      [
                          -75.775326,
                          45.486765,
                          0
                      ],
                      [
                          -75.773816,
                          45.485835,
                          0
                      ],
                      [
                          -75.772769,
                          45.484681,
                          0
                      ],
                      [
                          -75.771572,
                          45.484519,
                          0
                      ],
                      [
                          -75.771103,
                          45.483002,
                          0
                      ],
                      [
                          -75.769744,
                          45.482523,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Hull",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.369744,
                          45.311796,
                          0
                      ],
                      [
                          -73.369498,
                          45.30966,
                          0
                      ],
                      [
                          -73.371472,
                          45.306844,
                          0
                      ],
                      [
                          -73.371479,
                          45.306835,
                          0
                      ],
                      [
                          -73.370997,
                          45.305221,
                          0
                      ],
                      [
                          -73.374125,
                          45.304656,
                          0
                      ],
                      [
                          -73.374123,
                          45.304653,
                          0
                      ],
                      [
                          -73.373506,
                          45.3031,
                          0
                      ],
                      [
                          -73.375874,
                          45.30263,
                          0
                      ],
                      [
                          -73.375447,
                          45.301078,
                          0
                      ],
                      [
                          -73.379846,
                          45.300248,
                          0
                      ],
                      [
                          -73.379606,
                          45.299247,
                          0
                      ],
                      [
                          -73.379738,
                          45.295624,
                          0
                      ],
                      [
                          -73.382436,
                          45.295136,
                          0
                      ],
                      [
                          -73.382019,
                          45.293555,
                          0
                      ],
                      [
                          -73.381918,
                          45.291713,
                          0
                      ],
                      [
                          -73.381721,
                          45.290189,
                          0
                      ],
                      [
                          -73.382715,
                          45.288349,
                          0
                      ],
                      [
                          -73.381001,
                          45.288645,
                          0
                      ],
                      [
                          -73.381,
                          45.288645,
                          0
                      ],
                      [
                          -73.379205,
                          45.285829,
                          0
                      ],
                      [
                          -73.37952,
                          45.284966,
                          0
                      ],
                      [
                          -73.379765,
                          45.282526,
                          0
                      ],
                      [
                          -73.379511,
                          45.280688,
                          0
                      ],
                      [
                          -73.389439,
                          45.268138,
                          0
                      ],
                      [
                          -73.389482,
                          45.268084,
                          0
                      ],
                      [
                          -73.389499,
                          45.268064,
                          0
                      ],
                      [
                          -73.400948,
                          45.26606,
                          0
                      ],
                      [
                          -73.411898,
                          45.254267,
                          0
                      ],
                      [
                          -73.411811,
                          45.254284,
                          0
                      ],
                      [
                          -73.383085,
                          45.259621,
                          0
                      ],
                      [
                          -73.384849,
                          45.258486,
                          0
                      ],
                      [
                          -73.385748,
                          45.257162,
                          0
                      ],
                      [
                          -73.352086,
                          45.234383,
                          0
                      ],
                      [
                          -73.366693,
                          45.223383,
                          0
                      ],
                      [
                          -73.365541,
                          45.222625,
                          0
                      ],
                      [
                          -73.380171,
                          45.211723,
                          0
                      ],
                      [
                          -73.380127,
                          45.211693,
                          0
                      ],
                      [
                          -73.373019,
                          45.206972,
                          0
                      ],
                      [
                          -73.373051,
                          45.206948,
                          0
                      ],
                      [
                          -73.373051,
                          45.206948,
                          0
                      ],
                      [
                          -73.373073,
                          45.206921,
                          0
                      ],
                      [
                          -73.373111,
                          45.206893,
                          0
                      ],
                      [
                          -73.30174,
                          45.159145,
                          0
                      ],
                      [
                          -73.301721,
                          45.159132,
                          0
                      ],
                      [
                          -73.287506,
                          45.169983,
                          0
                      ],
                      [
                          -73.291858,
                          45.172958,
                          0
                      ],
                      [
                          -73.286337,
                          45.177013,
                          0
                      ],
                      [
                          -73.286387,
                          45.183529,
                          0
                      ],
                      [
                          -73.255109,
                          45.184127,
                          0
                      ],
                      [
                          -73.257156,
                          45.169781,
                          0
                      ],
                      [
                          -73.256735,
                          45.163133,
                          0
                      ],
                      [
                          -73.255009,
                          45.151591,
                          0
                      ],
                      [
                          -73.256445,
                          45.14019,
                          0
                      ],
                      [
                          -73.253533,
                          45.1353,
                          0
                      ],
                      [
                          -73.254055,
                          45.130397,
                          0
                      ],
                      [
                          -73.258289,
                          45.123843,
                          0
                      ],
                      [
                          -73.268951,
                          45.116747,
                          0
                      ],
                      [
                          -73.273769,
                          45.115054,
                          0
                      ],
                      [
                          -73.275743,
                          45.112564,
                          0
                      ],
                      [
                          -73.278698,
                          45.106949,
                          0
                      ],
                      [
                          -73.288036,
                          45.104777,
                          0
                      ],
                      [
                          -73.29566,
                          45.099704,
                          0
                      ],
                      [
                          -73.307228,
                          45.093053,
                          0
                      ],
                      [
                          -73.314341,
                          45.087571,
                          0
                      ],
                      [
                          -73.321243,
                          45.080465,
                          0
                      ],
                      [
                          -73.321282,
                          45.075946,
                          0
                      ],
                      [
                          -73.3253,
                          45.067876,
                          0
                      ],
                      [
                          -73.329481,
                          45.065188,
                          0
                      ],
                      [
                          -73.331546,
                          45.062471,
                          0
                      ],
                      [
                          -73.331046,
                          45.059438,
                          0
                      ],
                      [
                          -73.329,
                          45.053156,
                          0
                      ],
                      [
                          -73.332912,
                          45.041929,
                          0
                      ],
                      [
                          -73.339351,
                          45.033774,
                          0
                      ],
                      [
                          -73.341237,
                          45.030486,
                          0
                      ],
                      [
                          -73.341245,
                          45.030464,
                          0
                      ],
                      [
                          -73.343041,
                          45.024292,
                          0
                      ],
                      [
                          -73.343162,
                          45.010708,
                          0
                      ],
                      [
                          -73.343166,
                          45.010708,
                          0
                      ],
                      [
                          -73.343168,
                          45.010708,
                          0
                      ],
                      [
                          -73.631499,
                          45.003589,
                          0
                      ],
                      [
                          -74.007504,
                          44.9964,
                          0
                      ],
                      [
                          -74.148115,
                          44.991614,
                          0
                      ],
                      [
                          -74.390664,
                          44.994444,
                          0
                      ],
                      [
                          -74.507933,
                          44.998006,
                          0
                      ],
                      [
                          -74.661207,
                          44.999454,
                          0
                      ],
                      [
                          -74.663051,
                          44.998916,
                          0
                      ],
                      [
                          -74.663053,
                          44.998917,
                          0
                      ],
                      [
                          -74.669803,
                          45.005562,
                          0
                      ],
                      [
                          -74.652401,
                          45.030012,
                          0
                      ],
                      [
                          -74.652395,
                          45.030019,
                          0
                      ],
                      [
                          -74.652394,
                          45.030019,
                          0
                      ],
                      [
                          -74.625748,
                          45.030076,
                          0
                      ],
                      [
                          -74.601439,
                          45.039631,
                          0
                      ],
                      [
                          -74.565699,
                          45.046301,
                          0
                      ],
                      [
                          -74.532855,
                          45.062682,
                          0
                      ],
                      [
                          -74.51058,
                          45.076743,
                          0
                      ],
                      [
                          -74.510579,
                          45.076741,
                          0
                      ],
                      [
                          -74.495216,
                          45.064744,
                          0
                      ],
                      [
                          -74.495214,
                          45.064743,
                          0
                      ],
                      [
                          -74.495213,
                          45.064742,
                          0
                      ],
                      [
                          -74.4636,
                          45.104649,
                          0
                      ],
                      [
                          -74.442325,
                          45.131211,
                          0
                      ],
                      [
                          -74.435529,
                          45.134297,
                          0
                      ],
                      [
                          -74.419373,
                          45.14163,
                          0
                      ],
                      [
                          -74.37704,
                          45.155213,
                          0
                      ],
                      [
                          -74.33133,
                          45.180953,
                          0
                      ],
                      [
                          -74.318816,
                          45.187992,
                          0
                      ],
                      [
                          -74.300778,
                          45.194543,
                          0
                      ],
                      [
                          -74.275299,
                          45.198563,
                          0
                      ],
                      [
                          -74.254672,
                          45.202477,
                          0
                      ],
                      [
                          -74.223116,
                          45.214792,
                          0
                      ],
                      [
                          -74.212704,
                          45.220878,
                          0
                      ],
                      [
                          -74.212701,
                          45.220875,
                          0
                      ],
                      [
                          -74.124177,
                          45.145546,
                          0
                      ],
                      [
                          -74.124109,
                          45.145485,
                          0
                      ],
                      [
                          -74.124093,
                          45.145487,
                          0
                      ],
                      [
                          -74.026385,
                          45.155026,
                          0
                      ],
                      [
                          -74.026324,
                          45.154978,
                          0
                      ],
                      [
                          -74.024185,
                          45.153315,
                          0
                      ],
                      [
                          -74.023924,
                          45.153387,
                          0
                      ],
                      [
                          -73.972819,
                          45.179451,
                          0
                      ],
                      [
                          -73.950014,
                          45.162199,
                          0
                      ],
                      [
                          -73.938292,
                          45.169804,
                          0
                      ],
                      [
                          -73.938282,
                          45.169811,
                          0
                      ],
                      [
                          -73.939129,
                          45.170437,
                          0
                      ],
                      [
                          -73.937667,
                          45.172056,
                          0
                      ],
                      [
                          -73.937181,
                          45.175028,
                          0
                      ],
                      [
                          -73.928474,
                          45.180911,
                          0
                      ],
                      [
                          -73.928453,
                          45.180926,
                          0
                      ],
                      [
                          -73.929531,
                          45.181751,
                          0
                      ],
                      [
                          -73.927302,
                          45.183155,
                          0
                      ],
                      [
                          -73.927612,
                          45.183396,
                          0
                      ],
                      [
                          -73.925449,
                          45.184816,
                          0
                      ],
                      [
                          -73.924451,
                          45.184032,
                          0
                      ],
                      [
                          -73.922199,
                          45.185473,
                          0
                      ],
                      [
                          -73.921794,
                          45.185155,
                          0
                      ],
                      [
                          -73.919609,
                          45.186573,
                          0
                      ],
                      [
                          -73.921616,
                          45.188177,
                          0
                      ],
                      [
                          -73.921433,
                          45.188283,
                          0
                      ],
                      [
                          -73.920218,
                          45.187327,
                          0
                      ],
                      [
                          -73.920026,
                          45.187307,
                          0
                      ],
                      [
                          -73.918714,
                          45.189254,
                          0
                      ],
                      [
                          -73.916583,
                          45.19061,
                          0
                      ],
                      [
                          -73.91319,
                          45.18803,
                          0
                      ],
                      [
                          -73.91072,
                          45.189821,
                          0
                      ],
                      [
                          -73.913123,
                          45.191724,
                          0
                      ],
                      [
                          -73.910923,
                          45.193181,
                          0
                      ],
                      [
                          -73.911425,
                          45.193604,
                          0
                      ],
                      [
                          -73.910101,
                          45.19539,
                          0
                      ],
                      [
                          -73.90392,
                          45.201014,
                          0
                      ],
                      [
                          -73.902447,
                          45.203039,
                          0
                      ],
                      [
                          -73.887266,
                          45.204474,
                          0
                      ],
                      [
                          -73.885151,
                          45.206962,
                          0
                      ],
                      [
                          -73.873323,
                          45.208832,
                          0
                      ],
                      [
                          -73.867537,
                          45.21382,
                          0
                      ],
                      [
                          -73.864808,
                          45.2118,
                          0
                      ],
                      [
                          -73.859994,
                          45.216131,
                          0
                      ],
                      [
                          -73.856592,
                          45.217429,
                          0
                      ],
                      [
                          -73.856583,
                          45.217431,
                          0
                      ],
                      [
                          -73.866687,
                          45.224978,
                          0
                      ],
                      [
                          -73.862368,
                          45.232164,
                          0
                      ],
                      [
                          -73.859162,
                          45.235085,
                          0
                      ],
                      [
                          -73.851399,
                          45.22932,
                          0
                      ],
                      [
                          -73.851357,
                          45.229287,
                          0
                      ],
                      [
                          -73.821794,
                          45.274446,
                          0
                      ],
                      [
                          -73.820665,
                          45.27363,
                          0
                      ],
                      [
                          -73.819868,
                          45.273541,
                          0
                      ],
                      [
                          -73.819366,
                          45.277287,
                          0
                      ],
                      [
                          -73.826687,
                          45.278021,
                          0
                      ],
                      [
                          -73.826929,
                          45.278913,
                          0
                      ],
                      [
                          -73.823345,
                          45.283639,
                          0
                      ],
                      [
                          -73.825275,
                          45.2864,
                          0
                      ],
                      [
                          -73.823796,
                          45.289855,
                          0
                      ],
                      [
                          -73.819386,
                          45.294088,
                          0
                      ],
                      [
                          -73.818967,
                          45.29522,
                          0
                      ],
                      [
                          -73.820333,
                          45.295225,
                          0
                      ],
                      [
                          -73.820332,
                          45.29523,
                          0
                      ],
                      [
                          -73.818676,
                          45.299048,
                          0
                      ],
                      [
                          -73.817236,
                          45.302358,
                          0
                      ],
                      [
                          -73.812515,
                          45.307089,
                          0
                      ],
                      [
                          -73.814342,
                          45.311842,
                          0
                      ],
                      [
                          -73.811586,
                          45.313846,
                          0
                      ],
                      [
                          -73.698191,
                          45.229073,
                          0
                      ],
                      [
                          -73.639241,
                          45.184617,
                          0
                      ],
                      [
                          -73.613988,
                          45.220749,
                          0
                      ],
                      [
                          -73.605276,
                          45.218075,
                          0
                      ],
                      [
                          -73.598208,
                          45.229482,
                          0
                      ],
                      [
                          -73.598233,
                          45.229489,
                          0
                      ],
                      [
                          -73.6031,
                          45.231031,
                          0
                      ],
                      [
                          -73.589724,
                          45.252098,
                          0
                      ],
                      [
                          -73.585023,
                          45.25075,
                          0
                      ],
                      [
                          -73.585021,
                          45.250749,
                          0
                      ],
                      [
                          -73.583266,
                          45.253819,
                          0
                      ],
                      [
                          -73.583237,
                          45.253868,
                          0
                      ],
                      [
                          -73.586327,
                          45.25509,
                          0
                      ],
                      [
                          -73.580232,
                          45.262043,
                          0
                      ],
                      [
                          -73.580228,
                          45.26216,
                          0
                      ],
                      [
                          -73.581147,
                          45.263543,
                          0
                      ],
                      [
                          -73.581228,
                          45.265737,
                          0
                      ],
                      [
                          -73.578776,
                          45.269158,
                          0
                      ],
                      [
                          -73.581013,
                          45.269853,
                          0
                      ],
                      [
                          -73.58189,
                          45.270757,
                          0
                      ],
                      [
                          -73.581888,
                          45.270759,
                          0
                      ],
                      [
                          -73.581311,
                          45.271785,
                          0
                      ],
                      [
                          -73.582292,
                          45.272092,
                          0
                      ],
                      [
                          -73.582296,
                          45.272094,
                          0
                      ],
                      [
                          -73.581664,
                          45.273331,
                          0
                      ],
                      [
                          -73.581898,
                          45.273403,
                          0
                      ],
                      [
                          -73.581897,
                          45.273407,
                          0
                      ],
                      [
                          -73.582242,
                          45.274769,
                          0
                      ],
                      [
                          -73.582243,
                          45.274769,
                          0
                      ],
                      [
                          -73.581762,
                          45.275576,
                          0
                      ],
                      [
                          -73.581761,
                          45.275578,
                          0
                      ],
                      [
                          -73.582672,
                          45.275863,
                          0
                      ],
                      [
                          -73.580273,
                          45.279537,
                          0
                      ],
                      [
                          -73.578778,
                          45.282563,
                          0
                      ],
                      [
                          -73.577881,
                          45.28534,
                          0
                      ],
                      [
                          -73.577008,
                          45.287068,
                          0
                      ],
                      [
                          -73.572846,
                          45.287384,
                          0
                      ],
                      [
                          -73.571774,
                          45.288356,
                          0
                      ],
                      [
                          -73.571774,
                          45.288356,
                          0
                      ],
                      [
                          -73.576911,
                          45.289889,
                          0
                      ],
                      [
                          -73.576914,
                          45.28989,
                          0
                      ],
                      [
                          -73.576121,
                          45.29138,
                          0
                      ],
                      [
                          -73.576119,
                          45.291383,
                          0
                      ],
                      [
                          -73.570952,
                          45.289805,
                          0
                      ],
                      [
                          -73.570949,
                          45.289811,
                          0
                      ],
                      [
                          -73.568237,
                          45.294482,
                          0
                      ],
                      [
                          -73.568236,
                          45.294486,
                          0
                      ],
                      [
                          -73.569381,
                          45.294836,
                          0
                      ],
                      [
                          -73.568902,
                          45.295698,
                          0
                      ],
                      [
                          -73.547596,
                          45.289723,
                          0
                      ],
                      [
                          -73.547593,
                          45.289722,
                          0
                      ],
                      [
                          -73.547591,
                          45.289724,
                          0
                      ],
                      [
                          -73.547136,
                          45.290727,
                          0
                      ],
                      [
                          -73.548916,
                          45.292415,
                          0
                      ],
                      [
                          -73.550038,
                          45.2932,
                          0
                      ],
                      [
                          -73.537064,
                          45.290414,
                          0
                      ],
                      [
                          -73.537064,
                          45.290414,
                          0
                      ],
                      [
                          -73.536559,
                          45.291087,
                          0
                      ],
                      [
                          -73.536617,
                          45.292769,
                          0
                      ],
                      [
                          -73.535505,
                          45.292337,
                          0
                      ],
                      [
                          -73.535505,
                          45.292337,
                          0
                      ],
                      [
                          -73.530419,
                          45.292771,
                          0
                      ],
                      [
                          -73.515298,
                          45.286751,
                          0
                      ],
                      [
                          -73.515298,
                          45.286751,
                          0
                      ],
                      [
                          -73.515312,
                          45.28673,
                          0
                      ],
                      [
                          -73.517338,
                          45.283481,
                          0
                      ],
                      [
                          -73.516845,
                          45.283272,
                          0
                      ],
                      [
                          -73.518006,
                          45.281884,
                          0
                      ],
                      [
                          -73.516578,
                          45.2813,
                          0
                      ],
                      [
                          -73.517406,
                          45.2803,
                          0
                      ],
                      [
                          -73.51031,
                          45.277586,
                          0
                      ],
                      [
                          -73.510307,
                          45.277585,
                          0
                      ],
                      [
                          -73.510011,
                          45.278737,
                          0
                      ],
                      [
                          -73.510006,
                          45.278735,
                          0
                      ],
                      [
                          -73.508053,
                          45.277991,
                          0
                      ],
                      [
                          -73.50805,
                          45.27799,
                          0
                      ],
                      [
                          -73.507028,
                          45.279356,
                          0
                      ],
                      [
                          -73.510256,
                          45.280654,
                          0
                      ],
                      [
                          -73.510259,
                          45.280655,
                          0
                      ],
                      [
                          -73.510262,
                          45.280656,
                          0
                      ],
                      [
                          -73.509264,
                          45.283156,
                          0
                      ],
                      [
                          -73.50888,
                          45.283743,
                          0
                      ],
                      [
                          -73.505796,
                          45.285958,
                          0
                      ],
                      [
                          -73.502671,
                          45.284951,
                          0
                      ],
                      [
                          -73.494367,
                          45.295756,
                          0
                      ],
                      [
                          -73.492682,
                          45.295882,
                          0
                      ],
                      [
                          -73.492872,
                          45.295114,
                          0
                      ],
                      [
                          -73.492198,
                          45.294258,
                          0
                      ],
                      [
                          -73.491982,
                          45.293475,
                          0
                      ],
                      [
                          -73.491341,
                          45.2928,
                          0
                      ],
                      [
                          -73.488431,
                          45.294237,
                          0
                      ],
                      [
                          -73.486312,
                          45.291573,
                          0
                      ],
                      [
                          -73.480224,
                          45.294627,
                          0
                      ],
                      [
                          -73.479715,
                          45.293526,
                          0
                      ],
                      [
                          -73.478626,
                          45.294065,
                          0
                      ],
                      [
                          -73.477104,
                          45.291793,
                          0
                      ],
                      [
                          -73.480049,
                          45.290314,
                          0
                      ],
                      [
                          -73.480056,
                          45.29031,
                          0
                      ],
                      [
                          -73.478717,
                          45.288104,
                          0
                      ],
                      [
                          -73.478058,
                          45.28836,
                          0
                      ],
                      [
                          -73.477063,
                          45.286913,
                          0
                      ],
                      [
                          -73.47705,
                          45.286893,
                          0
                      ],
                      [
                          -73.479821,
                          45.285527,
                          0
                      ],
                      [
                          -73.47839,
                          45.284204,
                          0
                      ],
                      [
                          -73.477833,
                          45.284475,
                          0
                      ],
                      [
                          -73.476421,
                          45.282107,
                          0
                      ],
                      [
                          -73.476193,
                          45.281429,
                          0
                      ],
                      [
                          -73.460765,
                          45.289011,
                          0
                      ],
                      [
                          -73.463894,
                          45.293985,
                          0
                      ],
                      [
                          -73.463907,
                          45.294003,
                          0
                      ],
                      [
                          -73.463956,
                          45.294072,
                          0
                      ],
                      [
                          -73.443472,
                          45.303795,
                          0
                      ],
                      [
                          -73.44393,
                          45.304576,
                          0
                      ],
                      [
                          -73.442398,
                          45.306168,
                          0
                      ],
                      [
                          -73.442755,
                          45.306771,
                          0
                      ],
                      [
                          -73.44223,
                          45.308066,
                          0
                      ],
                      [
                          -73.442671,
                          45.308915,
                          0
                      ],
                      [
                          -73.443849,
                          45.31058,
                          0
                      ],
                      [
                          -73.424799,
                          45.319278,
                          0
                      ],
                      [
                          -73.423245,
                          45.316492,
                          0
                      ],
                      [
                          -73.421568,
                          45.315777,
                          0
                      ],
                      [
                          -73.420368,
                          45.317097,
                          0
                      ],
                      [
                          -73.421466,
                          45.317587,
                          0
                      ],
                      [
                          -73.412593,
                          45.325051,
                          0
                      ],
                      [
                          -73.411239,
                          45.330215,
                          0
                      ],
                      [
                          -73.369744,
                          45.311796,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Huntingdon",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.131972,
                          45.465435,
                          0
                      ],
                      [
                          -73.116943,
                          45.454792,
                          0
                      ],
                      [
                          -73.112494,
                          45.455486,
                          0
                      ],
                      [
                          -73.11961,
                          45.460467,
                          0
                      ],
                      [
                          -73.116124,
                          45.460783,
                          0
                      ],
                      [
                          -73.1122,
                          45.462398,
                          0
                      ],
                      [
                          -73.106278,
                          45.462638,
                          0
                      ],
                      [
                          -73.105189,
                          45.464163,
                          0
                      ],
                      [
                          -73.10163,
                          45.467028,
                          0
                      ],
                      [
                          -73.099883,
                          45.465772,
                          0
                      ],
                      [
                          -73.093835,
                          45.476808,
                          0
                      ],
                      [
                          -73.093591,
                          45.476618,
                          0
                      ],
                      [
                          -73.079039,
                          45.465694,
                          0
                      ],
                      [
                          -73.07277,
                          45.476019,
                          0
                      ],
                      [
                          -73.072756,
                          45.476043,
                          0
                      ],
                      [
                          -73.039117,
                          45.477403,
                          0
                      ],
                      [
                          -73.033752,
                          45.490934,
                          0
                      ],
                      [
                          -73.033714,
                          45.491029,
                          0
                      ],
                      [
                          -73.007856,
                          45.474299,
                          0
                      ],
                      [
                          -73.007854,
                          45.474298,
                          0
                      ],
                      [
                          -73.003532,
                          45.479254,
                          0
                      ],
                      [
                          -72.958109,
                          45.475002,
                          0
                      ],
                      [
                          -72.95806,
                          45.474997,
                          0
                      ],
                      [
                          -72.958065,
                          45.47498,
                          0
                      ],
                      [
                          -72.958067,
                          45.474978,
                          0
                      ],
                      [
                          -72.962699,
                          45.46903,
                          0
                      ],
                      [
                          -72.961908,
                          45.462057,
                          0
                      ],
                      [
                          -72.962545,
                          45.459187,
                          0
                      ],
                      [
                          -72.962564,
                          45.459175,
                          0
                      ],
                      [
                          -72.964982,
                          45.457447,
                          0
                      ],
                      [
                          -72.964965,
                          45.457444,
                          0
                      ],
                      [
                          -72.943281,
                          45.45437,
                          0
                      ],
                      [
                          -72.943105,
                          45.454995,
                          0
                      ],
                      [
                          -72.925684,
                          45.452554,
                          0
                      ],
                      [
                          -72.92169,
                          45.466486,
                          0
                      ],
                      [
                          -72.905657,
                          45.464023,
                          0
                      ],
                      [
                          -72.903909,
                          45.470541,
                          0
                      ],
                      [
                          -72.90435,
                          45.47161,
                          0
                      ],
                      [
                          -72.896619,
                          45.479168,
                          0
                      ],
                      [
                          -72.896613,
                          45.479164,
                          0
                      ],
                      [
                          -72.883856,
                          45.469901,
                          0
                      ],
                      [
                          -72.883836,
                          45.469767,
                          0
                      ],
                      [
                          -72.880248,
                          45.468916,
                          0
                      ],
                      [
                          -72.880185,
                          45.468945,
                          0
                      ],
                      [
                          -72.880171,
                          45.468945,
                          0
                      ],
                      [
                          -72.875479,
                          45.474037,
                          0
                      ],
                      [
                          -72.871773,
                          45.475817,
                          0
                      ],
                      [
                          -72.866813,
                          45.476865,
                          0
                      ],
                      [
                          -72.863246,
                          45.477642,
                          0
                      ],
                      [
                          -72.85901,
                          45.477826,
                          0
                      ],
                      [
                          -72.85199,
                          45.476704,
                          0
                      ],
                      [
                          -72.838466,
                          45.474004,
                          0
                      ],
                      [
                          -72.837448,
                          45.47556,
                          0
                      ],
                      [
                          -72.816935,
                          45.4725,
                          0
                      ],
                      [
                          -72.819862,
                          45.456699,
                          0
                      ],
                      [
                          -72.819868,
                          45.456659,
                          0
                      ],
                      [
                          -72.819871,
                          45.456636,
                          0
                      ],
                      [
                          -72.823765,
                          45.431218,
                          0
                      ],
                      [
                          -72.84582,
                          45.368438,
                          0
                      ],
                      [
                          -72.849862,
                          45.35717,
                          0
                      ],
                      [
                          -72.851421,
                          45.353784,
                          0
                      ],
                      [
                          -72.883017,
                          45.265549,
                          0
                      ],
                      [
                          -72.907961,
                          45.282806,
                          0
                      ],
                      [
                          -72.907966,
                          45.284075,
                          0
                      ],
                      [
                          -72.907967,
                          45.284075,
                          0
                      ],
                      [
                          -72.909251,
                          45.284084,
                          0
                      ],
                      [
                          -72.912825,
                          45.286735,
                          0
                      ],
                      [
                          -72.912429,
                          45.287794,
                          0
                      ],
                      [
                          -72.913641,
                          45.289359,
                          0
                      ],
                      [
                          -72.912982,
                          45.290235,
                          0
                      ],
                      [
                          -72.911639,
                          45.290069,
                          0
                      ],
                      [
                          -72.909949,
                          45.288494,
                          0
                      ],
                      [
                          -72.908072,
                          45.287655,
                          0
                      ],
                      [
                          -72.906431,
                          45.287788,
                          0
                      ],
                      [
                          -72.906434,
                          45.28779,
                          0
                      ],
                      [
                          -72.924396,
                          45.30079,
                          0
                      ],
                      [
                          -72.924418,
                          45.300807,
                          0
                      ],
                      [
                          -72.927425,
                          45.300744,
                          0
                      ],
                      [
                          -72.927425,
                          45.300743,
                          0
                      ],
                      [
                          -72.928136,
                          45.298684,
                          0
                      ],
                      [
                          -72.928141,
                          45.298668,
                          0
                      ],
                      [
                          -72.928142,
                          45.298664,
                          0
                      ],
                      [
                          -72.989917,
                          45.341838,
                          0
                      ],
                      [
                          -72.989917,
                          45.341838,
                          0
                      ],
                      [
                          -72.990098,
                          45.340059,
                          0
                      ],
                      [
                          -72.989424,
                          45.338674,
                          0
                      ],
                      [
                          -72.990147,
                          45.336306,
                          0
                      ],
                      [
                          -72.989283,
                          45.334552,
                          0
                      ],
                      [
                          -72.989285,
                          45.334547,
                          0
                      ],
                      [
                          -72.994409,
                          45.337952,
                          0
                      ],
                      [
                          -72.994411,
                          45.337953,
                          0
                      ],
                      [
                          -73.009651,
                          45.337525,
                          0
                      ],
                      [
                          -73.009651,
                          45.337525,
                          0
                      ],
                      [
                          -73.01034,
                          45.330161,
                          0
                      ],
                      [
                          -73.010351,
                          45.330159,
                          0
                      ],
                      [
                          -73.033846,
                          45.325623,
                          0
                      ],
                      [
                          -73.046297,
                          45.326057,
                          0
                      ],
                      [
                          -73.04886,
                          45.325316,
                          0
                      ],
                      [
                          -73.04886,
                          45.325315,
                          0
                      ],
                      [
                          -73.047819,
                          45.311301,
                          0
                      ],
                      [
                          -73.047812,
                          45.311297,
                          0
                      ],
                      [
                          -73.047805,
                          45.311292,
                          0
                      ],
                      [
                          -73.016112,
                          45.28932,
                          0
                      ],
                      [
                          -73.013981,
                          45.289047,
                          0
                      ],
                      [
                          -73.014491,
                          45.286071,
                          0
                      ],
                      [
                          -73.015657,
                          45.272022,
                          0
                      ],
                      [
                          -73.046189,
                          45.293373,
                          0
                      ],
                      [
                          -73.045577,
                          45.281312,
                          0
                      ],
                      [
                          -73.04702,
                          45.277707,
                          0
                      ],
                      [
                          -73.048882,
                          45.276892,
                          0
                      ],
                      [
                          -73.048886,
                          45.276889,
                          0
                      ],
                      [
                          -73.049912,
                          45.277662,
                          0
                      ],
                      [
                          -73.05051,
                          45.268127,
                          0
                      ],
                      [
                          -73.058258,
                          45.273183,
                          0
                      ],
                      [
                          -73.05893,
                          45.265106,
                          0
                      ],
                      [
                          -73.058931,
                          45.265101,
                          0
                      ],
                      [
                          -73.058931,
                          45.265097,
                          0
                      ],
                      [
                          -73.063534,
                          45.26783,
                          0
                      ],
                      [
                          -73.063533,
                          45.267827,
                          0
                      ],
                      [
                          -73.063536,
                          45.267814,
                          0
                      ],
                      [
                          -73.064078,
                          45.262117,
                          0
                      ],
                      [
                          -73.06545,
                          45.244618,
                          0
                      ],
                      [
                          -73.065452,
                          45.244619,
                          0
                      ],
                      [
                          -73.066783,
                          45.245531,
                          0
                      ],
                      [
                          -73.066788,
                          45.245534,
                          0
                      ],
                      [
                          -73.068106,
                          45.229607,
                          0
                      ],
                      [
                          -73.070171,
                          45.231035,
                          0
                      ],
                      [
                          -73.073987,
                          45.228843,
                          0
                      ],
                      [
                          -73.073982,
                          45.228839,
                          0
                      ],
                      [
                          -73.064882,
                          45.220748,
                          0
                      ],
                      [
                          -73.064633,
                          45.220377,
                          0
                      ],
                      [
                          -73.06373,
                          45.219512,
                          0
                      ],
                      [
                          -73.058271,
                          45.21459,
                          0
                      ],
                      [
                          -73.058271,
                          45.214589,
                          0
                      ],
                      [
                          -73.090912,
                          45.19628,
                          0
                      ],
                      [
                          -73.085864,
                          45.191662,
                          0
                      ],
                      [
                          -73.085899,
                          45.15117,
                          0
                      ],
                      [
                          -73.085884,
                          45.140105,
                          0
                      ],
                      [
                          -73.085884,
                          45.140101,
                          0
                      ],
                      [
                          -73.085972,
                          45.140101,
                          0
                      ],
                      [
                          -73.101243,
                          45.14007,
                          0
                      ],
                      [
                          -73.101243,
                          45.140069,
                          0
                      ],
                      [
                          -73.101242,
                          45.140068,
                          0
                      ],
                      [
                          -73.099308,
                          45.125024,
                          0
                      ],
                      [
                          -73.099388,
                          45.125023,
                          0
                      ],
                      [
                          -73.099521,
                          45.125021,
                          0
                      ],
                      [
                          -73.09716,
                          45.101594,
                          0
                      ],
                      [
                          -73.097158,
                          45.101577,
                          0
                      ],
                      [
                          -73.097158,
                          45.101575,
                          0
                      ],
                      [
                          -73.098344,
                          45.100247,
                          0
                      ],
                      [
                          -73.106917,
                          45.104483,
                          0
                      ],
                      [
                          -73.106973,
                          45.10451,
                          0
                      ],
                      [
                          -73.106973,
                          45.104506,
                          0
                      ],
                      [
                          -73.10757,
                          45.097149,
                          0
                      ],
                      [
                          -73.115746,
                          45.09712,
                          0
                      ],
                      [
                          -73.115828,
                          45.09712,
                          0
                      ],
                      [
                          -73.11521,
                          45.081848,
                          0
                      ],
                      [
                          -73.104478,
                          45.082082,
                          0
                      ],
                      [
                          -73.104495,
                          45.082061,
                          0
                      ],
                      [
                          -73.110696,
                          45.074427,
                          0
                      ],
                      [
                          -73.139887,
                          45.014407,
                          0
                      ],
                      [
                          -73.343162,
                          45.010708,
                          0
                      ],
                      [
                          -73.343041,
                          45.024292,
                          0
                      ],
                      [
                          -73.341245,
                          45.030464,
                          0
                      ],
                      [
                          -73.341237,
                          45.030486,
                          0
                      ],
                      [
                          -73.339351,
                          45.033774,
                          0
                      ],
                      [
                          -73.332912,
                          45.041929,
                          0
                      ],
                      [
                          -73.329,
                          45.053156,
                          0
                      ],
                      [
                          -73.331046,
                          45.059438,
                          0
                      ],
                      [
                          -73.331546,
                          45.062471,
                          0
                      ],
                      [
                          -73.329481,
                          45.065188,
                          0
                      ],
                      [
                          -73.3253,
                          45.067876,
                          0
                      ],
                      [
                          -73.321282,
                          45.075946,
                          0
                      ],
                      [
                          -73.321243,
                          45.080465,
                          0
                      ],
                      [
                          -73.314341,
                          45.087571,
                          0
                      ],
                      [
                          -73.307228,
                          45.093053,
                          0
                      ],
                      [
                          -73.29566,
                          45.099704,
                          0
                      ],
                      [
                          -73.288036,
                          45.104777,
                          0
                      ],
                      [
                          -73.278698,
                          45.106949,
                          0
                      ],
                      [
                          -73.275743,
                          45.112564,
                          0
                      ],
                      [
                          -73.273769,
                          45.115054,
                          0
                      ],
                      [
                          -73.268951,
                          45.116747,
                          0
                      ],
                      [
                          -73.258289,
                          45.123843,
                          0
                      ],
                      [
                          -73.254055,
                          45.130397,
                          0
                      ],
                      [
                          -73.253533,
                          45.1353,
                          0
                      ],
                      [
                          -73.256445,
                          45.14019,
                          0
                      ],
                      [
                          -73.255009,
                          45.151591,
                          0
                      ],
                      [
                          -73.256735,
                          45.163133,
                          0
                      ],
                      [
                          -73.257156,
                          45.169781,
                          0
                      ],
                      [
                          -73.255109,
                          45.184127,
                          0
                      ],
                      [
                          -73.255423,
                          45.192514,
                          0
                      ],
                      [
                          -73.254051,
                          45.205337,
                          0
                      ],
                      [
                          -73.253151,
                          45.213725,
                          0
                      ],
                      [
                          -73.246631,
                          45.230607,
                          0
                      ],
                      [
                          -73.246005,
                          45.245307,
                          0
                      ],
                      [
                          -73.245552,
                          45.25348,
                          0
                      ],
                      [
                          -73.244913,
                          45.258797,
                          0
                      ],
                      [
                          -73.244894,
                          45.258932,
                          0
                      ],
                      [
                          -73.244572,
                          45.27633,
                          0
                      ],
                      [
                          -73.247559,
                          45.290463,
                          0
                      ],
                      [
                          -73.247031,
                          45.302168,
                          0
                      ],
                      [
                          -73.246994,
                          45.308461,
                          0
                      ],
                      [
                          -73.250867,
                          45.316457,
                          0
                      ],
                      [
                          -73.258381,
                          45.323422,
                          0
                      ],
                      [
                          -73.26089,
                          45.33268,
                          0
                      ],
                      [
                          -73.2625,
                          45.340788,
                          0
                      ],
                      [
                          -73.261832,
                          45.34471,
                          0
                      ],
                      [
                          -73.253244,
                          45.35611,
                          0
                      ],
                      [
                          -73.247399,
                          45.370529,
                          0
                      ],
                      [
                          -73.247964,
                          45.378708,
                          0
                      ],
                      [
                          -73.251601,
                          45.38424,
                          0
                      ],
                      [
                          -73.250184,
                          45.384785,
                          0
                      ],
                      [
                          -73.249914,
                          45.383726,
                          0
                      ],
                      [
                          -73.220015,
                          45.362061,
                          0
                      ],
                      [
                          -73.220014,
                          45.362059,
                          0
                      ],
                      [
                          -73.219388,
                          45.36315,
                          0
                      ],
                      [
                          -73.219383,
                          45.363161,
                          0
                      ],
                      [
                          -73.219215,
                          45.365819,
                          0
                      ],
                      [
                          -73.220147,
                          45.369542,
                          0
                      ],
                      [
                          -73.221401,
                          45.373531,
                          0
                      ],
                      [
                          -73.22141,
                          45.373558,
                          0
                      ],
                      [
                          -73.216791,
                          45.376431,
                          0
                      ],
                      [
                          -73.210127,
                          45.37147,
                          0
                      ],
                      [
                          -73.210125,
                          45.371469,
                          0
                      ],
                      [
                          -73.210115,
                          45.371476,
                          0
                      ],
                      [
                          -73.198759,
                          45.379715,
                          0
                      ],
                      [
                          -73.198757,
                          45.37972,
                          0
                      ],
                      [
                          -73.196857,
                          45.402941,
                          0
                      ],
                      [
                          -73.192537,
                          45.404237,
                          0
                      ],
                      [
                          -73.196826,
                          45.411961,
                          0
                      ],
                      [
                          -73.211133,
                          45.421207,
                          0
                      ],
                      [
                          -73.211138,
                          45.42121,
                          0
                      ],
                      [
                          -73.204504,
                          45.42208,
                          0
                      ],
                      [
                          -73.203062,
                          45.422113,
                          0
                      ],
                      [
                          -73.202376,
                          45.424058,
                          0
                      ],
                      [
                          -73.202257,
                          45.428609,
                          0
                      ],
                      [
                          -73.201686,
                          45.431938,
                          0
                      ],
                      [
                          -73.202366,
                          45.433493,
                          0
                      ],
                      [
                          -73.200222,
                          45.433799,
                          0
                      ],
                      [
                          -73.201559,
                          45.436947,
                          0
                      ],
                      [
                          -73.204464,
                          45.440778,
                          0
                      ],
                      [
                          -73.20598,
                          45.443371,
                          0
                      ],
                      [
                          -73.20314,
                          45.443355,
                          0
                      ],
                      [
                          -73.198939,
                          45.449753,
                          0
                      ],
                      [
                          -73.20261,
                          45.453444,
                          0
                      ],
                      [
                          -73.198256,
                          45.457676,
                          0
                      ],
                      [
                          -73.198756,
                          45.458795,
                          0
                      ],
                      [
                          -73.198463,
                          45.460989,
                          0
                      ],
                      [
                          -73.197913,
                          45.460947,
                          0
                      ],
                      [
                          -73.197784,
                          45.461399,
                          0
                      ],
                      [
                          -73.199709,
                          45.461505,
                          0
                      ],
                      [
                          -73.198778,
                          45.46263,
                          0
                      ],
                      [
                          -73.196519,
                          45.46402,
                          0
                      ],
                      [
                          -73.195327,
                          45.470518,
                          0
                      ],
                      [
                          -73.197438,
                          45.472238,
                          0
                      ],
                      [
                          -73.188329,
                          45.471585,
                          0
                      ],
                      [
                          -73.185125,
                          45.473687,
                          0
                      ],
                      [
                          -73.188,
                          45.474692,
                          0
                      ],
                      [
                          -73.188388,
                          45.474996,
                          0
                      ],
                      [
                          -73.186534,
                          45.475221,
                          0
                      ],
                      [
                          -73.185061,
                          45.476074,
                          0
                      ],
                      [
                          -73.184685,
                          45.474915,
                          0
                      ],
                      [
                          -73.18407,
                          45.474456,
                          0
                      ],
                      [
                          -73.175568,
                          45.471679,
                          0
                      ],
                      [
                          -73.175565,
                          45.471678,
                          0
                      ],
                      [
                          -73.173313,
                          45.47385,
                          0
                      ],
                      [
                          -73.171192,
                          45.474172,
                          0
                      ],
                      [
                          -73.169254,
                          45.477328,
                          0
                      ],
                      [
                          -73.169327,
                          45.484038,
                          0
                      ],
                      [
                          -73.171409,
                          45.486147,
                          0
                      ],
                      [
                          -73.170638,
                          45.486737,
                          0
                      ],
                      [
                          -73.168757,
                          45.488511,
                          0
                      ],
                      [
                          -73.168078,
                          45.489463,
                          0
                      ],
                      [
                          -73.168104,
                          45.490492,
                          0
                      ],
                      [
                          -73.131972,
                          45.465435,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Iberville",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -62.299949,
                          46.840008,
                          0
                      ],
                      [
                          -62.986357,
                          47.139731,
                          0
                      ],
                      [
                          -62.999143,
                          47.157145,
                          0
                      ],
                      [
                          -62.999143,
                          47.157154,
                          0
                      ],
                      [
                          -62.999128,
                          48.666681,
                          0
                      ],
                      [
                          -60.468604,
                          48.666668,
                          0
                      ],
                      [
                          -60.40377,
                          47.76111,
                          0
                      ],
                      [
                          -60.762672,
                          47.423331,
                          0
                      ],
                      [
                          -60.991847,
                          47.329443,
                          0
                      ],
                      [
                          -61.39936,
                          46.840005,
                          0
                      ],
                      [
                          -61.399363,
                          46.840005,
                          0
                      ],
                      [
                          -62.299949,
                          46.840008,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Îles-de-la-Madeleine",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.78027,
                          45.467175,
                          0
                      ],
                      [
                          -73.784563,
                          45.465083,
                          0
                      ],
                      [
                          -73.784596,
                          45.465067,
                          0
                      ],
                      [
                          -73.7832,
                          45.463413,
                          0
                      ],
                      [
                          -73.780021,
                          45.454868,
                          0
                      ],
                      [
                          -73.782282,
                          45.45445,
                          0
                      ],
                      [
                          -73.767955,
                          45.418681,
                          0
                      ],
                      [
                          -73.822179,
                          45.385403,
                          0
                      ],
                      [
                          -73.879125,
                          45.408459,
                          0
                      ],
                      [
                          -73.899626,
                          45.408793,
                          0
                      ],
                      [
                          -73.918877,
                          45.398348,
                          0
                      ],
                      [
                          -73.950265,
                          45.401286,
                          0
                      ],
                      [
                          -73.957015,
                          45.403394,
                          0
                      ],
                      [
                          -73.961708,
                          45.40814,
                          0
                      ],
                      [
                          -73.964563,
                          45.40965,
                          0
                      ],
                      [
                          -73.981815,
                          45.416483,
                          0
                      ],
                      [
                          -73.981818,
                          45.416487,
                          0
                      ],
                      [
                          -73.983296,
                          45.421182,
                          0
                      ],
                      [
                          -73.996579,
                          45.457441,
                          0
                      ],
                      [
                          -73.996579,
                          45.457442,
                          0
                      ],
                      [
                          -73.996605,
                          45.457485,
                          0
                      ],
                      [
                          -73.996605,
                          45.457485,
                          0
                      ],
                      [
                          -73.996602,
                          45.457486,
                          0
                      ],
                      [
                          -73.996602,
                          45.457486,
                          0
                      ],
                      [
                          -73.974353,
                          45.466428,
                          0
                      ],
                      [
                          -73.974348,
                          45.46643,
                          0
                      ],
                      [
                          -73.937024,
                          45.450965,
                          0
                      ],
                      [
                          -73.925204,
                          45.440835,
                          0
                      ],
                      [
                          -73.922807,
                          45.441634,
                          0
                      ],
                      [
                          -73.922793,
                          45.441637,
                          0
                      ],
                      [
                          -73.923027,
                          45.442041,
                          0
                      ],
                      [
                          -73.905565,
                          45.44843,
                          0
                      ],
                      [
                          -73.90429,
                          45.4468,
                          0
                      ],
                      [
                          -73.904148,
                          45.445024,
                          0
                      ],
                      [
                          -73.905569,
                          45.444834,
                          0
                      ],
                      [
                          -73.904413,
                          45.440496,
                          0
                      ],
                      [
                          -73.903231,
                          45.440406,
                          0
                      ],
                      [
                          -73.902883,
                          45.440096,
                          0
                      ],
                      [
                          -73.901322,
                          45.435304,
                          0
                      ],
                      [
                          -73.900226,
                          45.435623,
                          0
                      ],
                      [
                          -73.900109,
                          45.435181,
                          0
                      ],
                      [
                          -73.893922,
                          45.436595,
                          0
                      ],
                      [
                          -73.893519,
                          45.435394,
                          0
                      ],
                      [
                          -73.885866,
                          45.437017,
                          0
                      ],
                      [
                          -73.885975,
                          45.437331,
                          0
                      ],
                      [
                          -73.883703,
                          45.437419,
                          0
                      ],
                      [
                          -73.88324,
                          45.436141,
                          0
                      ],
                      [
                          -73.881136,
                          45.436692,
                          0
                      ],
                      [
                          -73.881899,
                          45.438841,
                          0
                      ],
                      [
                          -73.879825,
                          45.439764,
                          0
                      ],
                      [
                          -73.879337,
                          45.43847,
                          0
                      ],
                      [
                          -73.878689,
                          45.43816,
                          0
                      ],
                      [
                          -73.878215,
                          45.438228,
                          0
                      ],
                      [
                          -73.87713,
                          45.437986,
                          0
                      ],
                      [
                          -73.876627,
                          45.438401,
                          0
                      ],
                      [
                          -73.875957,
                          45.438676,
                          0
                      ],
                      [
                          -73.876862,
                          45.441453,
                          0
                      ],
                      [
                          -73.875245,
                          45.441758,
                          0
                      ],
                      [
                          -73.872799,
                          45.442564,
                          0
                      ],
                      [
                          -73.872776,
                          45.442541,
                          0
                      ],
                      [
                          -73.871585,
                          45.441344,
                          0
                      ],
                      [
                          -73.868514,
                          45.442867,
                          0
                      ],
                      [
                          -73.868443,
                          45.442816,
                          0
                      ],
                      [
                          -73.864852,
                          45.439047,
                          0
                      ],
                      [
                          -73.852035,
                          45.44553,
                          0
                      ],
                      [
                          -73.832132,
                          45.445378,
                          0
                      ],
                      [
                          -73.836156,
                          45.449748,
                          0
                      ],
                      [
                          -73.833858,
                          45.449728,
                          0
                      ],
                      [
                          -73.846552,
                          45.462571,
                          0
                      ],
                      [
                          -73.815747,
                          45.478342,
                          0
                      ],
                      [
                          -73.812612,
                          45.475219,
                          0
                      ],
                      [
                          -73.79604,
                          45.483099,
                          0
                      ],
                      [
                          -73.794534,
                          45.481681,
                          0
                      ],
                      [
                          -73.78027,
                          45.467175,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Jacques-Cartier",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -71.232364,
                          46.867368,
                          0
                      ],
                      [
                          -71.221928,
                          46.87174,
                          0
                      ],
                      [
                          -71.22172,
                          46.871827,
                          0
                      ],
                      [
                          -71.221626,
                          46.871459,
                          0
                      ],
                      [
                          -71.221213,
                          46.870832,
                          0
                      ],
                      [
                          -71.219257,
                          46.868735,
                          0
                      ],
                      [
                          -71.218125,
                          46.867814,
                          0
                      ],
                      [
                          -71.216897,
                          46.867305,
                          0
                      ],
                      [
                          -71.216772,
                          46.866055,
                          0
                      ],
                      [
                          -71.212796,
                          46.862019,
                          0
                      ],
                      [
                          -71.211591,
                          46.863481,
                          0
                      ],
                      [
                          -71.20902,
                          46.865455,
                          0
                      ],
                      [
                          -71.208503,
                          46.864427,
                          0
                      ],
                      [
                          -71.205762,
                          46.863861,
                          0
                      ],
                      [
                          -71.204206,
                          46.862685,
                          0
                      ],
                      [
                          -71.202833,
                          46.862114,
                          0
                      ],
                      [
                          -71.201198,
                          46.861325,
                          0
                      ],
                      [
                          -71.200057,
                          46.861171,
                          0
                      ],
                      [
                          -71.197735,
                          46.861244,
                          0
                      ],
                      [
                          -71.196637,
                          46.860207,
                          0
                      ],
                      [
                          -71.197708,
                          46.859674,
                          0
                      ],
                      [
                          -71.199081,
                          46.858472,
                          0
                      ],
                      [
                          -71.199663,
                          46.857373,
                          0
                      ],
                      [
                          -71.199948,
                          46.856371,
                          0
                      ],
                      [
                          -71.20076,
                          46.855592,
                          0
                      ],
                      [
                          -71.200648,
                          46.854583,
                          0
                      ],
                      [
                          -71.199741,
                          46.854068,
                          0
                      ],
                      [
                          -71.199261,
                          46.853316,
                          0
                      ],
                      [
                          -71.199679,
                          46.852136,
                          0
                      ],
                      [
                          -71.199699,
                          46.850525,
                          0
                      ],
                      [
                          -71.172551,
                          46.842564,
                          0
                      ],
                      [
                          -71.180596,
                          46.836687,
                          0
                      ],
                      [
                          -71.194568,
                          46.830182,
                          0
                      ],
                      [
                          -71.216309,
                          46.821417,
                          0
                      ],
                      [
                          -71.220888,
                          46.820007,
                          0
                      ],
                      [
                          -71.225582,
                          46.819157,
                          0
                      ],
                      [
                          -71.228472,
                          46.819947,
                          0
                      ],
                      [
                          -71.230984,
                          46.821514,
                          0
                      ],
                      [
                          -71.233348,
                          46.823024,
                          0
                      ],
                      [
                          -71.235477,
                          46.823757,
                          0
                      ],
                      [
                          -71.237959,
                          46.823489,
                          0
                      ],
                      [
                          -71.238873,
                          46.823071,
                          0
                      ],
                      [
                          -71.239757,
                          46.820819,
                          0
                      ],
                      [
                          -71.239388,
                          46.819871,
                          0
                      ],
                      [
                          -71.241823,
                          46.820358,
                          0
                      ],
                      [
                          -71.243563,
                          46.821019,
                          0
                      ],
                      [
                          -71.244401,
                          46.821524,
                          0
                      ],
                      [
                          -71.245302,
                          46.822254,
                          0
                      ],
                      [
                          -71.24667,
                          46.823143,
                          0
                      ],
                      [
                          -71.250331,
                          46.824637,
                          0
                      ],
                      [
                          -71.252781,
                          46.825648,
                          0
                      ],
                      [
                          -71.254755,
                          46.826953,
                          0
                      ],
                      [
                          -71.255372,
                          46.82756,
                          0
                      ],
                      [
                          -71.262606,
                          46.835421,
                          0
                      ],
                      [
                          -71.242566,
                          46.844065,
                          0
                      ],
                      [
                          -71.238632,
                          46.845809,
                          0
                      ],
                      [
                          -71.237763,
                          46.846105,
                          0
                      ],
                      [
                          -71.238875,
                          46.847316,
                          0
                      ],
                      [
                          -71.239687,
                          46.847972,
                          0
                      ],
                      [
                          -71.23293,
                          46.850936,
                          0
                      ],
                      [
                          -71.248268,
                          46.867851,
                          0
                      ],
                      [
                          -71.251453,
                          46.869491,
                          0
                      ],
                      [
                          -71.2412,
                          46.87395,
                          0
                      ],
                      [
                          -71.238448,
                          46.871012,
                          0
                      ],
                      [
                          -71.23715,
                          46.869964,
                          0
                      ],
                      [
                          -71.232364,
                          46.867368,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Jean-Lesage",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.577103,
                          45.599784,
                          0
                      ],
                      [
                          -73.577333,
                          45.599392,
                          0
                      ],
                      [
                          -73.576088,
                          45.599238,
                          0
                      ],
                      [
                          -73.569702,
                          45.596605,
                          0
                      ],
                      [
                          -73.568677,
                          45.594885,
                          0
                      ],
                      [
                          -73.569021,
                          45.593536,
                          0
                      ],
                      [
                          -73.568454,
                          45.591811,
                          0
                      ],
                      [
                          -73.567722,
                          45.590504,
                          0
                      ],
                      [
                          -73.566497,
                          45.588552,
                          0
                      ],
                      [
                          -73.56607,
                          45.588172,
                          0
                      ],
                      [
                          -73.564215,
                          45.587244,
                          0
                      ],
                      [
                          -73.564253,
                          45.585406,
                          0
                      ],
                      [
                          -73.563955,
                          45.584988,
                          0
                      ],
                      [
                          -73.566161,
                          45.584264,
                          0
                      ],
                      [
                          -73.56478,
                          45.583,
                          0
                      ],
                      [
                          -73.564895,
                          45.58296,
                          0
                      ],
                      [
                          -73.565115,
                          45.582827,
                          0
                      ],
                      [
                          -73.565169,
                          45.582854,
                          0
                      ],
                      [
                          -73.565492,
                          45.582811,
                          0
                      ],
                      [
                          -73.565782,
                          45.582843,
                          0
                      ],
                      [
                          -73.566,
                          45.582803,
                          0
                      ],
                      [
                          -73.566028,
                          45.582511,
                          0
                      ],
                      [
                          -73.566071,
                          45.582488,
                          0
                      ],
                      [
                          -73.566139,
                          45.58252,
                          0
                      ],
                      [
                          -73.56629,
                          45.582491,
                          0
                      ],
                      [
                          -73.566392,
                          45.582373,
                          0
                      ],
                      [
                          -73.566529,
                          45.582483,
                          0
                      ],
                      [
                          -73.566625,
                          45.582479,
                          0
                      ],
                      [
                          -73.566992,
                          45.582033,
                          0
                      ],
                      [
                          -73.567083,
                          45.581973,
                          0
                      ],
                      [
                          -73.567302,
                          45.582071,
                          0
                      ],
                      [
                          -73.567383,
                          45.582034,
                          0
                      ],
                      [
                          -73.567444,
                          45.581896,
                          0
                      ],
                      [
                          -73.567566,
                          45.581868,
                          0
                      ],
                      [
                          -73.567617,
                          45.581928,
                          0
                      ],
                      [
                          -73.567812,
                          45.581875,
                          0
                      ],
                      [
                          -73.567967,
                          45.581909,
                          0
                      ],
                      [
                          -73.568099,
                          45.581735,
                          0
                      ],
                      [
                          -73.568246,
                          45.581783,
                          0
                      ],
                      [
                          -73.568483,
                          45.581443,
                          0
                      ],
                      [
                          -73.568572,
                          45.581318,
                          0
                      ],
                      [
                          -73.569599,
                          45.581658,
                          0
                      ],
                      [
                          -73.569863,
                          45.581214,
                          0
                      ],
                      [
                          -73.570653,
                          45.581473,
                          0
                      ],
                      [
                          -73.571022,
                          45.580922,
                          0
                      ],
                      [
                          -73.573972,
                          45.581919,
                          0
                      ],
                      [
                          -73.575244,
                          45.580524,
                          0
                      ],
                      [
                          -73.576772,
                          45.579314,
                          0
                      ],
                      [
                          -73.578122,
                          45.578065,
                          0
                      ],
                      [
                          -73.577255,
                          45.577665,
                          0
                      ],
                      [
                          -73.587116,
                          45.566405,
                          0
                      ],
                      [
                          -73.601644,
                          45.572776,
                          0
                      ],
                      [
                          -73.600654,
                          45.573897,
                          0
                      ],
                      [
                          -73.605928,
                          45.576267,
                          0
                      ],
                      [
                          -73.623025,
                          45.583851,
                          0
                      ],
                      [
                          -73.623979,
                          45.584524,
                          0
                      ],
                      [
                          -73.626888,
                          45.585521,
                          0
                      ],
                      [
                          -73.628827,
                          45.583151,
                          0
                      ],
                      [
                          -73.628951,
                          45.58323,
                          0
                      ],
                      [
                          -73.629643,
                          45.583748,
                          0
                      ],
                      [
                          -73.613621,
                          45.602935,
                          0
                      ],
                      [
                          -73.608816,
                          45.608522,
                          0
                      ],
                      [
                          -73.606173,
                          45.610719,
                          0
                      ],
                      [
                          -73.604742,
                          45.609369,
                          0
                      ],
                      [
                          -73.604741,
                          45.60937,
                          0
                      ],
                      [
                          -73.603542,
                          45.610015,
                          0
                      ],
                      [
                          -73.603542,
                          45.610015,
                          0
                      ],
                      [
                          -73.604197,
                          45.610608,
                          0
                      ],
                      [
                          -73.60321,
                          45.611155,
                          0
                      ],
                      [
                          -73.577103,
                          45.599784,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Jeanne-Mance-Viger",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -71.233237,
                          46.790888,
                          0
                      ],
                      [
                          -71.23153,
                          46.791612,
                          0
                      ],
                      [
                          -71.231316,
                          46.791448,
                          0
                      ],
                      [
                          -71.230098,
                          46.792544,
                          0
                      ],
                      [
                          -71.228983,
                          46.793529,
                          0
                      ],
                      [
                          -71.226256,
                          46.791357,
                          0
                      ],
                      [
                          -71.214145,
                          46.785257,
                          0
                      ],
                      [
                          -71.242364,
                          46.766465,
                          0
                      ],
                      [
                          -71.283704,
                          46.746729,
                          0
                      ],
                      [
                          -71.294994,
                          46.744962,
                          0
                      ],
                      [
                          -71.294994,
                          46.744962,
                          0
                      ],
                      [
                          -71.2964,
                          46.749739,
                          0
                      ],
                      [
                          -71.309787,
                          46.751399,
                          0
                      ],
                      [
                          -71.339663,
                          46.772893,
                          0
                      ],
                      [
                          -71.333307,
                          46.778802,
                          0
                      ],
                      [
                          -71.341182,
                          46.783186,
                          0
                      ],
                      [
                          -71.342275,
                          46.783635,
                          0
                      ],
                      [
                          -71.343405,
                          46.783905,
                          0
                      ],
                      [
                          -71.348456,
                          46.784973,
                          0
                      ],
                      [
                          -71.349695,
                          46.785304,
                          0
                      ],
                      [
                          -71.334132,
                          46.795319,
                          0
                      ],
                      [
                          -71.331558,
                          46.793444,
                          0
                      ],
                      [
                          -71.329851,
                          46.794562,
                          0
                      ],
                      [
                          -71.323566,
                          46.789989,
                          0
                      ],
                      [
                          -71.321546,
                          46.791305,
                          0
                      ],
                      [
                          -71.322349,
                          46.79188,
                          0
                      ],
                      [
                          -71.318708,
                          46.793156,
                          0
                      ],
                      [
                          -71.318705,
                          46.793157,
                          0
                      ],
                      [
                          -71.318203,
                          46.793483,
                          0
                      ],
                      [
                          -71.31857,
                          46.793876,
                          0
                      ],
                      [
                          -71.317974,
                          46.79363,
                          0
                      ],
                      [
                          -71.316617,
                          46.794509,
                          0
                      ],
                      [
                          -71.316615,
                          46.79451,
                          0
                      ],
                      [
                          -71.316615,
                          46.79451,
                          0
                      ],
                      [
                          -71.313577,
                          46.789262,
                          0
                      ],
                      [
                          -71.304639,
                          46.791697,
                          0
                      ],
                      [
                          -71.301776,
                          46.79248,
                          0
                      ],
                      [
                          -71.300368,
                          46.792851,
                          0
                      ],
                      [
                          -71.299349,
                          46.793092,
                          0
                      ],
                      [
                          -71.297804,
                          46.793375,
                          0
                      ],
                      [
                          -71.296258,
                          46.793579,
                          0
                      ],
                      [
                          -71.285873,
                          46.794466,
                          0
                      ],
                      [
                          -71.267911,
                          46.795983,
                          0
                      ],
                      [
                          -71.266869,
                          46.79515,
                          0
                      ],
                      [
                          -71.268072,
                          46.794637,
                          0
                      ],
                      [
                          -71.268265,
                          46.794647,
                          0
                      ],
                      [
                          -71.265823,
                          46.792661,
                          0
                      ],
                      [
                          -71.26696,
                          46.791924,
                          0
                      ],
                      [
                          -71.265664,
                          46.790913,
                          0
                      ],
                      [
                          -71.265664,
                          46.790913,
                          0
                      ],
                      [
                          -71.264545,
                          46.791656,
                          0
                      ],
                      [
                          -71.26321,
                          46.790567,
                          0
                      ],
                      [
                          -71.263463,
                          46.790388,
                          0
                      ],
                      [
                          -71.263077,
                          46.790081,
                          0
                      ],
                      [
                          -71.263172,
                          46.790019,
                          0
                      ],
                      [
                          -71.261317,
                          46.788549,
                          0
                      ],
                      [
                          -71.260998,
                          46.788736,
                          0
                      ],
                      [
                          -71.257903,
                          46.786299,
                          0
                      ],
                      [
                          -71.23937,
                          46.796988,
                          0
                      ],
                      [
                          -71.23937,
                          46.796988,
                          0
                      ],
                      [
                          -71.236702,
                          46.794712,
                          0
                      ],
                      [
                          -71.236699,
                          46.794709,
                          0
                      ],
                      [
                          -71.238962,
                          46.793437,
                          0
                      ],
                      [
                          -71.237033,
                          46.791839,
                          0
                      ],
                      [
                          -71.234278,
                          46.791717,
                          0
                      ],
                      [
                          -71.233727,
                          46.791356,
                          0
                      ],
                      [
                          -71.233612,
                          46.791198,
                          0
                      ],
                      [
                          -71.233237,
                          46.790888,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Jean-Talon",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -72.563924,
                          45.974401,
                          0
                      ],
                      [
                          -72.557905,
                          45.974622,
                          0
                      ],
                      [
                          -72.553522,
                          45.973643,
                          0
                      ],
                      [
                          -72.552906,
                          45.971307,
                          0
                      ],
                      [
                          -72.551616,
                          45.96699,
                          0
                      ],
                      [
                          -72.550293,
                          45.965974,
                          0
                      ],
                      [
                          -72.550291,
                          45.965974,
                          0
                      ],
                      [
                          -72.543705,
                          45.963185,
                          0
                      ],
                      [
                          -72.541076,
                          45.961355,
                          0
                      ],
                      [
                          -72.540097,
                          45.958956,
                          0
                      ],
                      [
                          -72.538158,
                          45.955677,
                          0
                      ],
                      [
                          -72.532522,
                          45.951865,
                          0
                      ],
                      [
                          -72.520238,
                          45.94446,
                          0
                      ],
                      [
                          -72.520245,
                          45.944453,
                          0
                      ],
                      [
                          -72.553423,
                          45.915398,
                          0
                      ],
                      [
                          -72.492215,
                          45.880495,
                          0
                      ],
                      [
                          -72.48411,
                          45.875484,
                          0
                      ],
                      [
                          -72.473863,
                          45.861402,
                          0
                      ],
                      [
                          -72.466591,
                          45.857317,
                          0
                      ],
                      [
                          -72.46655,
                          45.85729,
                          0
                      ],
                      [
                          -72.442981,
                          45.844341,
                          0
                      ],
                      [
                          -72.401065,
                          45.821015,
                          0
                      ],
                      [
                          -72.378702,
                          45.808403,
                          0
                      ],
                      [
                          -72.373785,
                          45.805162,
                          0
                      ],
                      [
                          -72.369143,
                          45.800285,
                          0
                      ],
                      [
                          -72.365877,
                          45.796791,
                          0
                      ],
                      [
                          -72.363191,
                          45.793924,
                          0
                      ],
                      [
                          -72.359209,
                          45.790859,
                          0
                      ],
                      [
                          -72.347428,
                          45.78512,
                          0
                      ],
                      [
                          -72.29638,
                          45.826968,
                          0
                      ],
                      [
                          -72.296272,
                          45.827056,
                          0
                      ],
                      [
                          -72.295364,
                          45.822079,
                          0
                      ],
                      [
                          -72.292092,
                          45.818481,
                          0
                      ],
                      [
                          -72.28985,
                          45.815903,
                          0
                      ],
                      [
                          -72.285428,
                          45.809992,
                          0
                      ],
                      [
                          -72.284255,
                          45.807039,
                          0
                      ],
                      [
                          -72.283728,
                          45.802441,
                          0
                      ],
                      [
                          -72.279309,
                          45.799796,
                          0
                      ],
                      [
                          -72.275251,
                          45.79816,
                          0
                      ],
                      [
                          -72.273923,
                          45.79565,
                          0
                      ],
                      [
                          -72.270653,
                          45.789795,
                          0
                      ],
                      [
                          -72.266204,
                          45.784677,
                          0
                      ],
                      [
                          -72.262261,
                          45.783578,
                          0
                      ],
                      [
                          -72.254154,
                          45.783721,
                          0
                      ],
                      [
                          -72.245932,
                          45.782194,
                          0
                      ],
                      [
                          -72.240474,
                          45.7765,
                          0
                      ],
                      [
                          -72.234086,
                          45.76858,
                          0
                      ],
                      [
                          -72.229265,
                          45.765181,
                          0
                      ],
                      [
                          -72.227477,
                          45.762978,
                          0
                      ],
                      [
                          -72.222293,
                          45.753622,
                          0
                      ],
                      [
                          -72.2271,
                          45.756215,
                          0
                      ],
                      [
                          -72.281663,
                          45.710703,
                          0
                      ],
                      [
                          -72.281648,
                          45.710695,
                          0
                      ],
                      [
                          -72.310667,
                          45.688617,
                          0
                      ],
                      [
                          -72.310761,
                          45.688542,
                          0
                      ],
                      [
                          -72.237091,
                          45.646277,
                          0
                      ],
                      [
                          -72.237089,
                          45.646276,
                          0
                      ],
                      [
                          -72.237116,
                          45.646252,
                          0
                      ],
                      [
                          -72.297579,
                          45.589443,
                          0
                      ],
                      [
                          -72.297581,
                          45.58944,
                          0
                      ],
                      [
                          -72.297592,
                          45.589448,
                          0
                      ],
                      [
                          -72.297606,
                          45.589457,
                          0
                      ],
                      [
                          -72.297647,
                          45.589484,
                          0
                      ],
                      [
                          -72.315551,
                          45.599224,
                          0
                      ],
                      [
                          -72.343473,
                          45.599101,
                          0
                      ],
                      [
                          -72.343663,
                          45.593853,
                          0
                      ],
                      [
                          -72.350645,
                          45.594019,
                          0
                      ],
                      [
                          -72.350619,
                          45.588963,
                          0
                      ],
                      [
                          -72.359676,
                          45.589395,
                          0
                      ],
                      [
                          -72.360781,
                          45.567317,
                          0
                      ],
                      [
                          -72.360868,
                          45.567313,
                          0
                      ],
                      [
                          -72.378021,
                          45.566506,
                          0
                      ],
                      [
                          -72.378116,
                          45.566501,
                          0
                      ],
                      [
                          -72.378635,
                          45.529776,
                          0
                      ],
                      [
                          -72.378652,
                          45.529712,
                          0
                      ],
                      [
                          -72.402813,
                          45.528567,
                          0
                      ],
                      [
                          -72.404853,
                          45.503883,
                          0
                      ],
                      [
                          -72.404873,
                          45.503739,
                          0
                      ],
                      [
                          -72.404239,
                          45.469854,
                          0
                      ],
                      [
                          -72.404006,
                          45.4516,
                          0
                      ],
                      [
                          -72.404014,
                          45.449189,
                          0
                      ],
                      [
                          -72.454528,
                          45.451469,
                          0
                      ],
                      [
                          -72.458629,
                          45.422211,
                          0
                      ],
                      [
                          -72.461348,
                          45.383421,
                          0
                      ],
                      [
                          -72.462313,
                          45.383445,
                          0
                      ],
                      [
                          -72.603057,
                          45.39268,
                          0
                      ],
                      [
                          -72.597036,
                          45.403203,
                          0
                      ],
                      [
                          -72.597015,
                          45.40324,
                          0
                      ],
                      [
                          -72.634546,
                          45.405584,
                          0
                      ],
                      [
                          -72.63455,
                          45.418435,
                          0
                      ],
                      [
                          -72.634549,
                          45.418523,
                          0
                      ],
                      [
                          -72.664283,
                          45.420559,
                          0
                      ],
                      [
                          -72.664424,
                          45.420572,
                          0
                      ],
                      [
                          -72.663401,
                          45.44734,
                          0
                      ],
                      [
                          -72.6634,
                          45.447352,
                          0
                      ],
                      [
                          -72.7004,
                          45.448452,
                          0
                      ],
                      [
                          -72.700418,
                          45.448452,
                          0
                      ],
                      [
                          -72.699237,
                          45.457364,
                          0
                      ],
                      [
                          -72.699251,
                          45.457365,
                          0
                      ],
                      [
                          -72.794771,
                          45.457954,
                          0
                      ],
                      [
                          -72.819861,
                          45.456699,
                          0
                      ],
                      [
                          -72.819862,
                          45.456699,
                          0
                      ],
                      [
                          -72.816935,
                          45.4725,
                          0
                      ],
                      [
                          -72.811039,
                          45.499651,
                          0
                      ],
                      [
                          -72.811087,
                          45.500082,
                          0
                      ],
                      [
                          -72.81062,
                          45.501705,
                          0
                      ],
                      [
                          -72.810457,
                          45.502212,
                          0
                      ],
                      [
                          -72.810455,
                          45.502222,
                          0
                      ],
                      [
                          -72.810455,
                          45.502222,
                          0
                      ],
                      [
                          -72.810455,
                          45.502226,
                          0
                      ],
                      [
                          -72.810457,
                          45.502258,
                          0
                      ],
                      [
                          -72.770949,
                          45.617671,
                          0
                      ],
                      [
                          -72.770945,
                          45.617669,
                          0
                      ],
                      [
                          -72.7709,
                          45.617638,
                          0
                      ],
                      [
                          -72.732454,
                          45.590959,
                          0
                      ],
                      [
                          -72.706389,
                          45.666779,
                          0
                      ],
                      [
                          -72.803689,
                          45.733848,
                          0
                      ],
                      [
                          -72.80365,
                          45.733876,
                          0
                      ],
                      [
                          -72.771736,
                          45.756173,
                          0
                      ],
                      [
                          -72.795189,
                          45.772727,
                          0
                      ],
                      [
                          -72.7592,
                          45.797521,
                          0
                      ],
                      [
                          -72.75918,
                          45.797534,
                          0
                      ],
                      [
                          -72.75918,
                          45.797534,
                          0
                      ],
                      [
                          -72.785517,
                          45.816828,
                          0
                      ],
                      [
                          -72.769366,
                          45.828191,
                          0
                      ],
                      [
                          -72.769347,
                          45.828179,
                          0
                      ],
                      [
                          -72.76437,
                          45.825171,
                          0
                      ],
                      [
                          -72.764276,
                          45.825114,
                          0
                      ],
                      [
                          -72.742628,
                          45.842236,
                          0
                      ],
                      [
                          -72.707943,
                          45.870918,
                          0
                      ],
                      [
                          -72.720172,
                          45.879592,
                          0
                      ],
                      [
                          -72.708884,
                          45.887762,
                          0
                      ],
                      [
                          -72.72239,
                          45.897204,
                          0
                      ],
                      [
                          -72.707497,
                          45.908422,
                          0
                      ],
                      [
                          -72.70702,
                          45.908191,
                          0
                      ],
                      [
                          -72.696706,
                          45.916945,
                          0
                      ],
                      [
                          -72.69246,
                          45.9169,
                          0
                      ],
                      [
                          -72.69135,
                          45.916658,
                          0
                      ],
                      [
                          -72.678974,
                          45.909664,
                          0
                      ],
                      [
                          -72.675773,
                          45.91177,
                          0
                      ],
                      [
                          -72.666835,
                          45.905757,
                          0
                      ],
                      [
                          -72.66681,
                          45.90578,
                          0
                      ],
                      [
                          -72.630208,
                          45.940167,
                          0
                      ],
                      [
                          -72.608006,
                          45.958814,
                          0
                      ],
                      [
                          -72.583879,
                          45.979278,
                          0
                      ],
                      [
                          -72.583873,
                          45.979284,
                          0
                      ],
                      [
                          -72.578011,
                          45.979711,
                          0
                      ],
                      [
                          -72.577954,
                          45.97971,
                          0
                      ],
                      [
                          -72.575824,
                          45.979175,
                          0
                      ],
                      [
                          -72.568317,
                          45.975289,
                          0
                      ],
                      [
                          -72.563924,
                          45.974401,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Johnson",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -71.400796,
                          48.484129,
                          0
                      ],
                      [
                          -71.382633,
                          48.482295,
                          0
                      ],
                      [
                          -71.348963,
                          48.480604,
                          0
                      ],
                      [
                          -71.300122,
                          48.468521,
                          0
                      ],
                      [
                          -71.273911,
                          48.462212,
                          0
                      ],
                      [
                          -71.261164,
                          48.454855,
                          0
                      ],
                      [
                          -71.243098,
                          48.452031,
                          0
                      ],
                      [
                          -71.217311,
                          48.449855,
                          0
                      ],
                      [
                          -71.209849,
                          48.445162,
                          0
                      ],
                      [
                          -71.205202,
                          48.44625,
                          0
                      ],
                      [
                          -71.18698,
                          48.454081,
                          0
                      ],
                      [
                          -71.17752,
                          48.45476,
                          0
                      ],
                      [
                          -71.121778,
                          48.441173,
                          0
                      ],
                      [
                          -71.129663,
                          48.431193,
                          0
                      ],
                      [
                          -71.125247,
                          48.429411,
                          0
                      ],
                      [
                          -71.13948,
                          48.41605,
                          0
                      ],
                      [
                          -71.135666,
                          48.413075,
                          0
                      ],
                      [
                          -71.12759,
                          48.410717,
                          0
                      ],
                      [
                          -71.156453,
                          48.383534,
                          0
                      ],
                      [
                          -71.170042,
                          48.387234,
                          0
                      ],
                      [
                          -71.184477,
                          48.373724,
                          0
                      ],
                      [
                          -71.208297,
                          48.346841,
                          0
                      ],
                      [
                          -71.220841,
                          48.352449,
                          0
                      ],
                      [
                          -71.273911,
                          48.302759,
                          0
                      ],
                      [
                          -71.322652,
                          48.307684,
                          0
                      ],
                      [
                          -71.450307,
                          48.33127,
                          0
                      ],
                      [
                          -71.482661,
                          48.339716,
                          0
                      ],
                      [
                          -71.509294,
                          48.342431,
                          0
                      ],
                      [
                          -71.539671,
                          48.349672,
                          0
                      ],
                      [
                          -71.539671,
                          48.349672,
                          0
                      ],
                      [
                          -71.563099,
                          48.36472,
                          0
                      ],
                      [
                          -71.550623,
                          48.393902,
                          0
                      ],
                      [
                          -71.524222,
                          48.389958,
                          0
                      ],
                      [
                          -71.518147,
                          48.403902,
                          0
                      ],
                      [
                          -71.488728,
                          48.39957,
                          0
                      ],
                      [
                          -71.488459,
                          48.399519,
                          0
                      ],
                      [
                          -71.475549,
                          48.435175,
                          0
                      ],
                      [
                          -71.38277,
                          48.419531,
                          0
                      ],
                      [
                          -71.377191,
                          48.431396,
                          0
                      ],
                      [
                          -71.377817,
                          48.431554,
                          0
                      ],
                      [
                          -71.377225,
                          48.433023,
                          0
                      ],
                      [
                          -71.486862,
                          48.452367,
                          0
                      ],
                      [
                          -71.465178,
                          48.50648,
                          0
                      ],
                      [
                          -71.455299,
                          48.504211,
                          0
                      ],
                      [
                          -71.419087,
                          48.491312,
                          0
                      ],
                      [
                          -71.400796,
                          48.484129,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Jonquière",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.551782,
                          45.644764,
                          0
                      ],
                      [
                          -73.568341,
                          45.634206,
                          0
                      ],
                      [
                          -73.568407,
                          45.634234,
                          0
                      ],
                      [
                          -73.577345,
                          45.628286,
                          0
                      ],
                      [
                          -73.596471,
                          45.615188,
                          0
                      ],
                      [
                          -73.599126,
                          45.613778,
                          0
                      ],
                      [
                          -73.600638,
                          45.613202,
                          0
                      ],
                      [
                          -73.60263,
                          45.61288,
                          0
                      ],
                      [
                          -73.624576,
                          45.633092,
                          0
                      ],
                      [
                          -73.618081,
                          45.638924,
                          0
                      ],
                      [
                          -73.604421,
                          45.647342,
                          0
                      ],
                      [
                          -73.579677,
                          45.658694,
                          0
                      ],
                      [
                          -73.568806,
                          45.663992,
                          0
                      ],
                      [
                          -73.559899,
                          45.668707,
                          0
                      ],
                      [
                          -73.554181,
                          45.672698,
                          0
                      ],
                      [
                          -73.538653,
                          45.6788,
                          0
                      ],
                      [
                          -73.534206,
                          45.682671,
                          0
                      ],
                      [
                          -73.529306,
                          45.688087,
                          0
                      ],
                      [
                          -73.527643,
                          45.692553,
                          0
                      ],
                      [
                          -73.523099,
                          45.697883,
                          0
                      ],
                      [
                          -73.523096,
                          45.697885,
                          0
                      ],
                      [
                          -73.508874,
                          45.702316,
                          0
                      ],
                      [
                          -73.508785,
                          45.702334,
                          0
                      ],
                      [
                          -73.509099,
                          45.695673,
                          0
                      ],
                      [
                          -73.519319,
                          45.672539,
                          0
                      ],
                      [
                          -73.51797,
                          45.663165,
                          0
                      ],
                      [
                          -73.518228,
                          45.660553,
                          0
                      ],
                      [
                          -73.522165,
                          45.659152,
                          0
                      ],
                      [
                          -73.528515,
                          45.656112,
                          0
                      ],
                      [
                          -73.551782,
                          45.644764,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "LaFontaine",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -71.420617,
                          46.980702,
                          0
                      ],
                      [
                          -71.448041,
                          46.967528,
                          0
                      ],
                      [
                          -71.405611,
                          46.923226,
                          0
                      ],
                      [
                          -71.433411,
                          46.911049,
                          0
                      ],
                      [
                          -71.438447,
                          46.914708,
                          0
                      ],
                      [
                          -71.473042,
                          46.892152,
                          0
                      ],
                      [
                          -71.460162,
                          46.883318,
                          0
                      ],
                      [
                          -71.46522,
                          46.879958,
                          0
                      ],
                      [
                          -71.465184,
                          46.879951,
                          0
                      ],
                      [
                          -71.458091,
                          46.87853,
                          0
                      ],
                      [
                          -71.449338,
                          46.876054,
                          0
                      ],
                      [
                          -71.431142,
                          46.870758,
                          0
                      ],
                      [
                          -71.423595,
                          46.867812,
                          0
                      ],
                      [
                          -71.412744,
                          46.862925,
                          0
                      ],
                      [
                          -71.400607,
                          46.868,
                          0
                      ],
                      [
                          -71.390737,
                          46.861005,
                          0
                      ],
                      [
                          -71.3906,
                          46.860789,
                          0
                      ],
                      [
                          -71.388719,
                          46.860588,
                          0
                      ],
                      [
                          -71.387565,
                          46.860134,
                          0
                      ],
                      [
                          -71.385957,
                          46.858327,
                          0
                      ],
                      [
                          -71.350027,
                          46.831889,
                          0
                      ],
                      [
                          -71.356135,
                          46.82772,
                          0
                      ],
                      [
                          -71.364009,
                          46.82247,
                          0
                      ],
                      [
                          -71.347617,
                          46.817154,
                          0
                      ],
                      [
                          -71.345981,
                          46.816464,
                          0
                      ],
                      [
                          -71.339103,
                          46.811593,
                          0
                      ],
                      [
                          -71.34502,
                          46.807752,
                          0
                      ],
                      [
                          -71.34035,
                          46.804414,
                          0
                      ],
                      [
                          -71.341415,
                          46.803711,
                          0
                      ],
                      [
                          -71.335603,
                          46.799621,
                          0
                      ],
                      [
                          -71.335653,
                          46.799502,
                          0
                      ],
                      [
                          -71.33578,
                          46.799463,
                          0
                      ],
                      [
                          -71.335973,
                          46.79947,
                          0
                      ],
                      [
                          -71.336084,
                          46.799518,
                          0
                      ],
                      [
                          -71.336085,
                          46.799517,
                          0
                      ],
                      [
                          -71.336122,
                          46.79949,
                          0
                      ],
                      [
                          -71.336307,
                          46.799624,
                          0
                      ],
                      [
                          -71.336691,
                          46.799644,
                          0
                      ],
                      [
                          -71.337007,
                          46.799453,
                          0
                      ],
                      [
                          -71.337128,
                          46.799148,
                          0
                      ],
                      [
                          -71.337016,
                          46.798907,
                          0
                      ],
                      [
                          -71.338099,
                          46.798198,
                          0
                      ],
                      [
                          -71.334132,
                          46.795319,
                          0
                      ],
                      [
                          -71.349695,
                          46.785304,
                          0
                      ],
                      [
                          -71.409649,
                          46.826255,
                          0
                      ],
                      [
                          -71.410436,
                          46.826774,
                          0
                      ],
                      [
                          -71.412771,
                          46.826117,
                          0
                      ],
                      [
                          -71.415047,
                          46.82769,
                          0
                      ],
                      [
                          -71.416454,
                          46.826449,
                          0
                      ],
                      [
                          -71.41946,
                          46.828516,
                          0
                      ],
                      [
                          -71.440621,
                          46.813316,
                          0
                      ],
                      [
                          -71.512888,
                          46.799993,
                          0
                      ],
                      [
                          -71.501969,
                          46.79247,
                          0
                      ],
                      [
                          -71.552567,
                          46.783976,
                          0
                      ],
                      [
                          -71.569683,
                          46.796478,
                          0
                      ],
                      [
                          -71.580429,
                          46.795129,
                          0
                      ],
                      [
                          -71.595852,
                          46.806587,
                          0
                      ],
                      [
                          -71.595873,
                          46.806603,
                          0
                      ],
                      [
                          -71.637969,
                          46.8012,
                          0
                      ],
                      [
                          -71.714759,
                          46.853987,
                          0
                      ],
                      [
                          -71.714767,
                          46.853992,
                          0
                      ],
                      [
                          -71.708186,
                          46.858369,
                          0
                      ],
                      [
                          -71.708195,
                          46.858377,
                          0
                      ],
                      [
                          -71.712234,
                          46.86129,
                          0
                      ],
                      [
                          -71.709338,
                          46.864452,
                          0
                      ],
                      [
                          -71.705912,
                          46.865909,
                          0
                      ],
                      [
                          -71.712459,
                          46.870444,
                          0
                      ],
                      [
                          -71.707897,
                          46.874525,
                          0
                      ],
                      [
                          -71.712565,
                          46.877842,
                          0
                      ],
                      [
                          -71.703966,
                          46.883288,
                          0
                      ],
                      [
                          -71.732124,
                          46.90301,
                          0
                      ],
                      [
                          -71.708167,
                          46.918999,
                          0
                      ],
                      [
                          -71.72152,
                          46.927406,
                          0
                      ],
                      [
                          -71.633241,
                          46.9873,
                          0
                      ],
                      [
                          -71.813871,
                          47.113959,
                          0
                      ],
                      [
                          -71.631695,
                          47.192537,
                          0
                      ],
                      [
                          -71.420617,
                          46.980702,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "La Peltrie",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.437977,
                          45.471164,
                          0
                      ],
                      [
                          -73.423279,
                          45.461471,
                          0
                      ],
                      [
                          -73.383764,
                          45.435223,
                          0
                      ],
                      [
                          -73.38142,
                          45.433661,
                          0
                      ],
                      [
                          -73.379884,
                          45.432639,
                          0
                      ],
                      [
                          -73.38243,
                          45.430874,
                          0
                      ],
                      [
                          -73.385621,
                          45.426902,
                          0
                      ],
                      [
                          -73.382053,
                          45.42434,
                          0
                      ],
                      [
                          -73.383293,
                          45.420207,
                          0
                      ],
                      [
                          -73.38201,
                          45.417051,
                          0
                      ],
                      [
                          -73.383237,
                          45.415626,
                          0
                      ],
                      [
                          -73.386899,
                          45.413047,
                          0
                      ],
                      [
                          -73.38872,
                          45.414386,
                          0
                      ],
                      [
                          -73.438394,
                          45.416607,
                          0
                      ],
                      [
                          -73.438087,
                          45.42165,
                          0
                      ],
                      [
                          -73.454975,
                          45.42245,
                          0
                      ],
                      [
                          -73.455845,
                          45.421027,
                          0
                      ],
                      [
                          -73.458412,
                          45.421801,
                          0
                      ],
                      [
                          -73.458882,
                          45.421156,
                          0
                      ],
                      [
                          -73.460127,
                          45.421538,
                          0
                      ],
                      [
                          -73.461553,
                          45.421681,
                          0
                      ],
                      [
                          -73.463141,
                          45.422165,
                          0
                      ],
                      [
                          -73.464133,
                          45.421683,
                          0
                      ],
                      [
                          -73.464452,
                          45.422037,
                          0
                      ],
                      [
                          -73.464255,
                          45.423003,
                          0
                      ],
                      [
                          -73.464461,
                          45.423222,
                          0
                      ],
                      [
                          -73.465324,
                          45.423131,
                          0
                      ],
                      [
                          -73.466973,
                          45.423529,
                          0
                      ],
                      [
                          -73.467865,
                          45.423077,
                          0
                      ],
                      [
                          -73.468843,
                          45.422615,
                          0
                      ],
                      [
                          -73.469657,
                          45.423102,
                          0
                      ],
                      [
                          -73.469442,
                          45.424076,
                          0
                      ],
                      [
                          -73.46945,
                          45.424081,
                          0
                      ],
                      [
                          -73.469403,
                          45.424697,
                          0
                      ],
                      [
                          -73.469787,
                          45.424943,
                          0
                      ],
                      [
                          -73.471091,
                          45.425372,
                          0
                      ],
                      [
                          -73.471835,
                          45.426571,
                          0
                      ],
                      [
                          -73.472326,
                          45.426844,
                          0
                      ],
                      [
                          -73.474123,
                          45.42665,
                          0
                      ],
                      [
                          -73.474891,
                          45.427052,
                          0
                      ],
                      [
                          -73.475382,
                          45.428061,
                          0
                      ],
                      [
                          -73.475932,
                          45.428475,
                          0
                      ],
                      [
                          -73.477679,
                          45.429798,
                          0
                      ],
                      [
                          -73.478872,
                          45.430452,
                          0
                      ],
                      [
                          -73.479881,
                          45.430618,
                          0
                      ],
                      [
                          -73.480271,
                          45.430336,
                          0
                      ],
                      [
                          -73.481532,
                          45.429846,
                          0
                      ],
                      [
                          -73.482751,
                          45.430312,
                          0
                      ],
                      [
                          -73.483424,
                          45.431641,
                          0
                      ],
                      [
                          -73.484306,
                          45.432026,
                          0
                      ],
                      [
                          -73.486398,
                          45.431168,
                          0
                      ],
                      [
                          -73.487201,
                          45.431097,
                          0
                      ],
                      [
                          -73.489144,
                          45.431702,
                          0
                      ],
                      [
                          -73.492125,
                          45.431545,
                          0
                      ],
                      [
                          -73.497612,
                          45.435425,
                          0
                      ],
                      [
                          -73.521829,
                          45.452539,
                          0
                      ],
                      [
                          -73.521197,
                          45.468694,
                          0
                      ],
                      [
                          -73.521195,
                          45.468694,
                          0
                      ],
                      [
                          -73.521138,
                          45.468708,
                          0
                      ],
                      [
                          -73.494324,
                          45.466532,
                          0
                      ],
                      [
                          -73.479252,
                          45.465228,
                          0
                      ],
                      [
                          -73.466007,
                          45.464719,
                          0
                      ],
                      [
                          -73.467637,
                          45.46813,
                          0
                      ],
                      [
                          -73.467964,
                          45.469478,
                          0
                      ],
                      [
                          -73.467738,
                          45.471851,
                          0
                      ],
                      [
                          -73.473424,
                          45.475896,
                          0
                      ],
                      [
                          -73.47346,
                          45.475965,
                          0
                      ],
                      [
                          -73.472739,
                          45.476453,
                          0
                      ],
                      [
                          -73.472605,
                          45.476355,
                          0
                      ],
                      [
                          -73.472564,
                          45.476382,
                          0
                      ],
                      [
                          -73.472166,
                          45.476091,
                          0
                      ],
                      [
                          -73.472159,
                          45.476086,
                          0
                      ],
                      [
                          -73.466077,
                          45.480199,
                          0
                      ],
                      [
                          -73.466409,
                          45.480742,
                          0
                      ],
                      [
                          -73.459427,
                          45.485479,
                          0
                      ],
                      [
                          -73.459419,
                          45.485474,
                          0
                      ],
                      [
                          -73.456497,
                          45.483505,
                          0
                      ],
                      [
                          -73.455545,
                          45.482889,
                          0
                      ],
                      [
                          -73.452807,
                          45.481115,
                          0
                      ],
                      [
                          -73.437977,
                          45.471164,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "La Pinière",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.503512,
                          45.511466,
                          0
                      ],
                      [
                          -73.503469,
                          45.511529,
                          0
                      ],
                      [
                          -73.504966,
                          45.514137,
                          0
                      ],
                      [
                          -73.498704,
                          45.512111,
                          0
                      ],
                      [
                          -73.494925,
                          45.510949,
                          0
                      ],
                      [
                          -73.493706,
                          45.510277,
                          0
                      ],
                      [
                          -73.492703,
                          45.509594,
                          0
                      ],
                      [
                          -73.491874,
                          45.50888,
                          0
                      ],
                      [
                          -73.49102,
                          45.507942,
                          0
                      ],
                      [
                          -73.490396,
                          45.506818,
                          0
                      ],
                      [
                          -73.489596,
                          45.504368,
                          0
                      ],
                      [
                          -73.488376,
                          45.504542,
                          0
                      ],
                      [
                          -73.487658,
                          45.504761,
                          0
                      ],
                      [
                          -73.487633,
                          45.504563,
                          0
                      ],
                      [
                          -73.48472,
                          45.504844,
                          0
                      ],
                      [
                          -73.482735,
                          45.504981,
                          0
                      ],
                      [
                          -73.478869,
                          45.505236,
                          0
                      ],
                      [
                          -73.480061,
                          45.504526,
                          0
                      ],
                      [
                          -73.469883,
                          45.505206,
                          0
                      ],
                      [
                          -73.461729,
                          45.505796,
                          0
                      ],
                      [
                          -73.459421,
                          45.504433,
                          0
                      ],
                      [
                          -73.446724,
                          45.49742,
                          0
                      ],
                      [
                          -73.445971,
                          45.497008,
                          0
                      ],
                      [
                          -73.445977,
                          45.496912,
                          0
                      ],
                      [
                          -73.442455,
                          45.494964,
                          0
                      ],
                      [
                          -73.435799,
                          45.491278,
                          0
                      ],
                      [
                          -73.452584,
                          45.481248,
                          0
                      ],
                      [
                          -73.452807,
                          45.481115,
                          0
                      ],
                      [
                          -73.455545,
                          45.482889,
                          0
                      ],
                      [
                          -73.456497,
                          45.483505,
                          0
                      ],
                      [
                          -73.459419,
                          45.485474,
                          0
                      ],
                      [
                          -73.459427,
                          45.485479,
                          0
                      ],
                      [
                          -73.466409,
                          45.480742,
                          0
                      ],
                      [
                          -73.466077,
                          45.480199,
                          0
                      ],
                      [
                          -73.472159,
                          45.476086,
                          0
                      ],
                      [
                          -73.472166,
                          45.476091,
                          0
                      ],
                      [
                          -73.472564,
                          45.476382,
                          0
                      ],
                      [
                          -73.472605,
                          45.476355,
                          0
                      ],
                      [
                          -73.472739,
                          45.476453,
                          0
                      ],
                      [
                          -73.47346,
                          45.475965,
                          0
                      ],
                      [
                          -73.473424,
                          45.475896,
                          0
                      ],
                      [
                          -73.467738,
                          45.471851,
                          0
                      ],
                      [
                          -73.467964,
                          45.469478,
                          0
                      ],
                      [
                          -73.467637,
                          45.46813,
                          0
                      ],
                      [
                          -73.466007,
                          45.464719,
                          0
                      ],
                      [
                          -73.479252,
                          45.465228,
                          0
                      ],
                      [
                          -73.494324,
                          45.466532,
                          0
                      ],
                      [
                          -73.521138,
                          45.468708,
                          0
                      ],
                      [
                          -73.521195,
                          45.468694,
                          0
                      ],
                      [
                          -73.521197,
                          45.468694,
                          0
                      ],
                      [
                          -73.523292,
                          45.478191,
                          0
                      ],
                      [
                          -73.529675,
                          45.49157,
                          0
                      ],
                      [
                          -73.519235,
                          45.495194,
                          0
                      ],
                      [
                          -73.526005,
                          45.518958,
                          0
                      ],
                      [
                          -73.520314,
                          45.517448,
                          0
                      ],
                      [
                          -73.519895,
                          45.517336,
                          0
                      ],
                      [
                          -73.519825,
                          45.517103,
                          0
                      ],
                      [
                          -73.51932,
                          45.517076,
                          0
                      ],
                      [
                          -73.518205,
                          45.51678,
                          0
                      ],
                      [
                          -73.514709,
                          45.515535,
                          0
                      ],
                      [
                          -73.506224,
                          45.512547,
                          0
                      ],
                      [
                          -73.503512,
                          45.511466,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Laporte",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.497612,
                          45.435425,
                          0
                      ],
                      [
                          -73.492125,
                          45.431545,
                          0
                      ],
                      [
                          -73.489144,
                          45.431702,
                          0
                      ],
                      [
                          -73.487201,
                          45.431097,
                          0
                      ],
                      [
                          -73.486398,
                          45.431168,
                          0
                      ],
                      [
                          -73.484306,
                          45.432026,
                          0
                      ],
                      [
                          -73.483424,
                          45.431641,
                          0
                      ],
                      [
                          -73.482751,
                          45.430312,
                          0
                      ],
                      [
                          -73.481532,
                          45.429846,
                          0
                      ],
                      [
                          -73.480271,
                          45.430336,
                          0
                      ],
                      [
                          -73.479881,
                          45.430618,
                          0
                      ],
                      [
                          -73.478872,
                          45.430452,
                          0
                      ],
                      [
                          -73.477679,
                          45.429798,
                          0
                      ],
                      [
                          -73.475932,
                          45.428475,
                          0
                      ],
                      [
                          -73.475382,
                          45.428061,
                          0
                      ],
                      [
                          -73.474891,
                          45.427052,
                          0
                      ],
                      [
                          -73.474123,
                          45.42665,
                          0
                      ],
                      [
                          -73.472326,
                          45.426844,
                          0
                      ],
                      [
                          -73.471835,
                          45.426571,
                          0
                      ],
                      [
                          -73.471091,
                          45.425372,
                          0
                      ],
                      [
                          -73.469787,
                          45.424943,
                          0
                      ],
                      [
                          -73.469403,
                          45.424697,
                          0
                      ],
                      [
                          -73.46945,
                          45.424081,
                          0
                      ],
                      [
                          -73.469442,
                          45.424076,
                          0
                      ],
                      [
                          -73.469657,
                          45.423102,
                          0
                      ],
                      [
                          -73.468843,
                          45.422615,
                          0
                      ],
                      [
                          -73.467865,
                          45.423077,
                          0
                      ],
                      [
                          -73.466973,
                          45.423529,
                          0
                      ],
                      [
                          -73.465324,
                          45.423131,
                          0
                      ],
                      [
                          -73.464461,
                          45.423222,
                          0
                      ],
                      [
                          -73.464255,
                          45.423003,
                          0
                      ],
                      [
                          -73.464452,
                          45.422037,
                          0
                      ],
                      [
                          -73.464133,
                          45.421683,
                          0
                      ],
                      [
                          -73.463141,
                          45.422165,
                          0
                      ],
                      [
                          -73.461553,
                          45.421681,
                          0
                      ],
                      [
                          -73.460127,
                          45.421538,
                          0
                      ],
                      [
                          -73.458882,
                          45.421156,
                          0
                      ],
                      [
                          -73.458412,
                          45.421801,
                          0
                      ],
                      [
                          -73.455845,
                          45.421027,
                          0
                      ],
                      [
                          -73.454975,
                          45.42245,
                          0
                      ],
                      [
                          -73.438087,
                          45.42165,
                          0
                      ],
                      [
                          -73.438394,
                          45.416607,
                          0
                      ],
                      [
                          -73.38872,
                          45.414386,
                          0
                      ],
                      [
                          -73.386899,
                          45.413047,
                          0
                      ],
                      [
                          -73.386018,
                          45.412414,
                          0
                      ],
                      [
                          -73.385305,
                          45.410233,
                          0
                      ],
                      [
                          -73.392982,
                          45.410534,
                          0
                      ],
                      [
                          -73.392991,
                          45.410535,
                          0
                      ],
                      [
                          -73.392993,
                          45.410534,
                          0
                      ],
                      [
                          -73.380638,
                          45.401328,
                          0
                      ],
                      [
                          -73.380634,
                          45.401326,
                          0
                      ],
                      [
                          -73.380129,
                          45.401304,
                          0
                      ],
                      [
                          -73.380122,
                          45.401304,
                          0
                      ],
                      [
                          -73.381889,
                          45.400337,
                          0
                      ],
                      [
                          -73.381124,
                          45.39974,
                          0
                      ],
                      [
                          -73.381123,
                          45.399739,
                          0
                      ],
                      [
                          -73.382206,
                          45.399075,
                          0
                      ],
                      [
                          -73.383101,
                          45.399705,
                          0
                      ],
                      [
                          -73.386147,
                          45.399926,
                          0
                      ],
                      [
                          -73.385577,
                          45.398319,
                          0
                      ],
                      [
                          -73.385765,
                          45.395067,
                          0
                      ],
                      [
                          -73.386976,
                          45.387566,
                          0
                      ],
                      [
                          -73.388709,
                          45.383,
                          0
                      ],
                      [
                          -73.391377,
                          45.380757,
                          0
                      ],
                      [
                          -73.395725,
                          45.376472,
                          0
                      ],
                      [
                          -73.397815,
                          45.373294,
                          0
                      ],
                      [
                          -73.395517,
                          45.373068,
                          0
                      ],
                      [
                          -73.394713,
                          45.368433,
                          0
                      ],
                      [
                          -73.393677,
                          45.366926,
                          0
                      ],
                      [
                          -73.392592,
                          45.363631,
                          0
                      ],
                      [
                          -73.393642,
                          45.360315,
                          0
                      ],
                      [
                          -73.387703,
                          45.357708,
                          0
                      ],
                      [
                          -73.389216,
                          45.354653,
                          0
                      ],
                      [
                          -73.393271,
                          45.349904,
                          0
                      ],
                      [
                          -73.402756,
                          45.348141,
                          0
                      ],
                      [
                          -73.402812,
                          45.348132,
                          0
                      ],
                      [
                          -73.405201,
                          45.346613,
                          0
                      ],
                      [
                          -73.405771,
                          45.346373,
                          0
                      ],
                      [
                          -73.405846,
                          45.346353,
                          0
                      ],
                      [
                          -73.405548,
                          45.344942,
                          0
                      ],
                      [
                          -73.405888,
                          45.341961,
                          0
                      ],
                      [
                          -73.408008,
                          45.340991,
                          0
                      ],
                      [
                          -73.40785,
                          45.340365,
                          0
                      ],
                      [
                          -73.404546,
                          45.338878,
                          0
                      ],
                      [
                          -73.406835,
                          45.336088,
                          0
                      ],
                      [
                          -73.409194,
                          45.333002,
                          0
                      ],
                      [
                          -73.409199,
                          45.332994,
                          0
                      ],
                      [
                          -73.409202,
                          45.33299,
                          0
                      ],
                      [
                          -73.409609,
                          45.332111,
                          0
                      ],
                      [
                          -73.409658,
                          45.332102,
                          0
                      ],
                      [
                          -73.410775,
                          45.331909,
                          0
                      ],
                      [
                          -73.410795,
                          45.331905,
                          0
                      ],
                      [
                          -73.410796,
                          45.331899,
                          0
                      ],
                      [
                          -73.411239,
                          45.330215,
                          0
                      ],
                      [
                          -73.412593,
                          45.325051,
                          0
                      ],
                      [
                          -73.421466,
                          45.317587,
                          0
                      ],
                      [
                          -73.420368,
                          45.317097,
                          0
                      ],
                      [
                          -73.421568,
                          45.315777,
                          0
                      ],
                      [
                          -73.423245,
                          45.316492,
                          0
                      ],
                      [
                          -73.424799,
                          45.319278,
                          0
                      ],
                      [
                          -73.443849,
                          45.31058,
                          0
                      ],
                      [
                          -73.442671,
                          45.308915,
                          0
                      ],
                      [
                          -73.44223,
                          45.308066,
                          0
                      ],
                      [
                          -73.442755,
                          45.306771,
                          0
                      ],
                      [
                          -73.442398,
                          45.306168,
                          0
                      ],
                      [
                          -73.44393,
                          45.304576,
                          0
                      ],
                      [
                          -73.443472,
                          45.303795,
                          0
                      ],
                      [
                          -73.463956,
                          45.294072,
                          0
                      ],
                      [
                          -73.463907,
                          45.294003,
                          0
                      ],
                      [
                          -73.463894,
                          45.293985,
                          0
                      ],
                      [
                          -73.460765,
                          45.289011,
                          0
                      ],
                      [
                          -73.476193,
                          45.281429,
                          0
                      ],
                      [
                          -73.476421,
                          45.282107,
                          0
                      ],
                      [
                          -73.477833,
                          45.284475,
                          0
                      ],
                      [
                          -73.47839,
                          45.284204,
                          0
                      ],
                      [
                          -73.479821,
                          45.285527,
                          0
                      ],
                      [
                          -73.47705,
                          45.286893,
                          0
                      ],
                      [
                          -73.477063,
                          45.286913,
                          0
                      ],
                      [
                          -73.478058,
                          45.28836,
                          0
                      ],
                      [
                          -73.478717,
                          45.288104,
                          0
                      ],
                      [
                          -73.480056,
                          45.29031,
                          0
                      ],
                      [
                          -73.480049,
                          45.290314,
                          0
                      ],
                      [
                          -73.477104,
                          45.291793,
                          0
                      ],
                      [
                          -73.478626,
                          45.294065,
                          0
                      ],
                      [
                          -73.479715,
                          45.293526,
                          0
                      ],
                      [
                          -73.480224,
                          45.294627,
                          0
                      ],
                      [
                          -73.486312,
                          45.291573,
                          0
                      ],
                      [
                          -73.488431,
                          45.294237,
                          0
                      ],
                      [
                          -73.491341,
                          45.2928,
                          0
                      ],
                      [
                          -73.491982,
                          45.293475,
                          0
                      ],
                      [
                          -73.492198,
                          45.294258,
                          0
                      ],
                      [
                          -73.492872,
                          45.295114,
                          0
                      ],
                      [
                          -73.492682,
                          45.295882,
                          0
                      ],
                      [
                          -73.492644,
                          45.296967,
                          0
                      ],
                      [
                          -73.492617,
                          45.297237,
                          0
                      ],
                      [
                          -73.492209,
                          45.297886,
                          0
                      ],
                      [
                          -73.492299,
                          45.298614,
                          0
                      ],
                      [
                          -73.492668,
                          45.299397,
                          0
                      ],
                      [
                          -73.492897,
                          45.30009,
                          0
                      ],
                      [
                          -73.493383,
                          45.300999,
                          0
                      ],
                      [
                          -73.493612,
                          45.301422,
                          0
                      ],
                      [
                          -73.493637,
                          45.301701,
                          0
                      ],
                      [
                          -73.493459,
                          45.302394,
                          0
                      ],
                      [
                          -73.493471,
                          45.302772,
                          0
                      ],
                      [
                          -73.49356,
                          45.303033,
                          0
                      ],
                      [
                          -73.49337,
                          45.304058,
                          0
                      ],
                      [
                          -73.49314,
                          45.304617,
                          0
                      ],
                      [
                          -73.493305,
                          45.304913,
                          0
                      ],
                      [
                          -73.493864,
                          45.305777,
                          0
                      ],
                      [
                          -73.494781,
                          45.306804,
                          0
                      ],
                      [
                          -73.495651,
                          45.307721,
                          0
                      ],
                      [
                          -73.497053,
                          45.309053,
                          0
                      ],
                      [
                          -73.497717,
                          45.309436,
                          0
                      ],
                      [
                          -73.498561,
                          45.310056,
                          0
                      ],
                      [
                          -73.498564,
                          45.310058,
                          0
                      ],
                      [
                          -73.499074,
                          45.310382,
                          0
                      ],
                      [
                          -73.499956,
                          45.311011,
                          0
                      ],
                      [
                          -73.486473,
                          45.317804,
                          0
                      ],
                      [
                          -73.487955,
                          45.318609,
                          0
                      ],
                      [
                          -73.504058,
                          45.310578,
                          0
                      ],
                      [
                          -73.504583,
                          45.310313,
                          0
                      ],
                      [
                          -73.505253,
                          45.310594,
                          0
                      ],
                      [
                          -73.504253,
                          45.311014,
                          0
                      ],
                      [
                          -73.503725,
                          45.311872,
                          0
                      ],
                      [
                          -73.503745,
                          45.312041,
                          0
                      ],
                      [
                          -73.504907,
                          45.312536,
                          0
                      ],
                      [
                          -73.502922,
                          45.314828,
                          0
                      ],
                      [
                          -73.505405,
                          45.315877,
                          0
                      ],
                      [
                          -73.505389,
                          45.315885,
                          0
                      ],
                      [
                          -73.50135,
                          45.317913,
                          0
                      ],
                      [
                          -73.501373,
                          45.317922,
                          0
                      ],
                      [
                          -73.506324,
                          45.319997,
                          0
                      ],
                      [
                          -73.506281,
                          45.320018,
                          0
                      ],
                      [
                          -73.504342,
                          45.320994,
                          0
                      ],
                      [
                          -73.509347,
                          45.323144,
                          0
                      ],
                      [
                          -73.509343,
                          45.323147,
                          0
                      ],
                      [
                          -73.508146,
                          45.324027,
                          0
                      ],
                      [
                          -73.508151,
                          45.324029,
                          0
                      ],
                      [
                          -73.509883,
                          45.324835,
                          0
                      ],
                      [
                          -73.509573,
                          45.324999,
                          0
                      ],
                      [
                          -73.510205,
                          45.326977,
                          0
                      ],
                      [
                          -73.510666,
                          45.327175,
                          0
                      ],
                      [
                          -73.510239,
                          45.32783,
                          0
                      ],
                      [
                          -73.512274,
                          45.32885,
                          0
                      ],
                      [
                          -73.511685,
                          45.329613,
                          0
                      ],
                      [
                          -73.512235,
                          45.329865,
                          0
                      ],
                      [
                          -73.508299,
                          45.331864,
                          0
                      ],
                      [
                          -73.508732,
                          45.333416,
                          0
                      ],
                      [
                          -73.509859,
                          45.334679,
                          0
                      ],
                      [
                          -73.502816,
                          45.338237,
                          0
                      ],
                      [
                          -73.504212,
                          45.339567,
                          0
                      ],
                      [
                          -73.502847,
                          45.340267,
                          0
                      ],
                      [
                          -73.504077,
                          45.341613,
                          0
                      ],
                      [
                          -73.491624,
                          45.347906,
                          0
                      ],
                      [
                          -73.492089,
                          45.348238,
                          0
                      ],
                      [
                          -73.491437,
                          45.348521,
                          0
                      ],
                      [
                          -73.491437,
                          45.348521,
                          0
                      ],
                      [
                          -73.491857,
                          45.348962,
                          0
                      ],
                      [
                          -73.492674,
                          45.348809,
                          0
                      ],
                      [
                          -73.492964,
                          45.34925,
                          0
                      ],
                      [
                          -73.516159,
                          45.3374,
                          0
                      ],
                      [
                          -73.519604,
                          45.343915,
                          0
                      ],
                      [
                          -73.519716,
                          45.353406,
                          0
                      ],
                      [
                          -73.520489,
                          45.353767,
                          0
                      ],
                      [
                          -73.520496,
                          45.353202,
                          0
                      ],
                      [
                          -73.520547,
                          45.349322,
                          0
                      ],
                      [
                          -73.525827,
                          45.351812,
                          0
                      ],
                      [
                          -73.525827,
                          45.351812,
                          0
                      ],
                      [
                          -73.524625,
                          45.353184,
                          0
                      ],
                      [
                          -73.537338,
                          45.359097,
                          0
                      ],
                      [
                          -73.537446,
                          45.359703,
                          0
                      ],
                      [
                          -73.53812,
                          45.359964,
                          0
                      ],
                      [
                          -73.537893,
                          45.361777,
                          0
                      ],
                      [
                          -73.539776,
                          45.36263,
                          0
                      ],
                      [
                          -73.538565,
                          45.362661,
                          0
                      ],
                      [
                          -73.538388,
                          45.362882,
                          0
                      ],
                      [
                          -73.538585,
                          45.363172,
                          0
                      ],
                      [
                          -73.540188,
                          45.363661,
                          0
                      ],
                      [
                          -73.540716,
                          45.36413,
                          0
                      ],
                      [
                          -73.559025,
                          45.36909,
                          0
                      ],
                      [
                          -73.558114,
                          45.370993,
                          0
                      ],
                      [
                          -73.558122,
                          45.370994,
                          0
                      ],
                      [
                          -73.568808,
                          45.373543,
                          0
                      ],
                      [
                          -73.568583,
                          45.373802,
                          0
                      ],
                      [
                          -73.566776,
                          45.373339,
                          0
                      ],
                      [
                          -73.566343,
                          45.374335,
                          0
                      ],
                      [
                          -73.565965,
                          45.374608,
                          0
                      ],
                      [
                          -73.562855,
                          45.373926,
                          0
                      ],
                      [
                          -73.560461,
                          45.378534,
                          0
                      ],
                      [
                          -73.561241,
                          45.378736,
                          0
                      ],
                      [
                          -73.556734,
                          45.378772,
                          0
                      ],
                      [
                          -73.554882,
                          45.378597,
                          0
                      ],
                      [
                          -73.5514,
                          45.378658,
                          0
                      ],
                      [
                          -73.550446,
                          45.380598,
                          0
                      ],
                      [
                          -73.553435,
                          45.38131,
                          0
                      ],
                      [
                          -73.553235,
                          45.381808,
                          0
                      ],
                      [
                          -73.553457,
                          45.382264,
                          0
                      ],
                      [
                          -73.553399,
                          45.382542,
                          0
                      ],
                      [
                          -73.553257,
                          45.382634,
                          0
                      ],
                      [
                          -73.552916,
                          45.382619,
                          0
                      ],
                      [
                          -73.55239,
                          45.383658,
                          0
                      ],
                      [
                          -73.553957,
                          45.385009,
                          0
                      ],
                      [
                          -73.55396,
                          45.385011,
                          0
                      ],
                      [
                          -73.554536,
                          45.385322,
                          0
                      ],
                      [
                          -73.554608,
                          45.385561,
                          0
                      ],
                      [
                          -73.554259,
                          45.385918,
                          0
                      ],
                      [
                          -73.553547,
                          45.385684,
                          0
                      ],
                      [
                          -73.553556,
                          45.386249,
                          0
                      ],
                      [
                          -73.551937,
                          45.386537,
                          0
                      ],
                      [
                          -73.55073,
                          45.386893,
                          0
                      ],
                      [
                          -73.549614,
                          45.387511,
                          0
                      ],
                      [
                          -73.54954,
                          45.387937,
                          0
                      ],
                      [
                          -73.547942,
                          45.387529,
                          0
                      ],
                      [
                          -73.541367,
                          45.40038,
                          0
                      ],
                      [
                          -73.54182,
                          45.400609,
                          0
                      ],
                      [
                          -73.54192,
                          45.40142,
                          0
                      ],
                      [
                          -73.540992,
                          45.401684,
                          0
                      ],
                      [
                          -73.54109,
                          45.401983,
                          0
                      ],
                      [
                          -73.540746,
                          45.401994,
                          0
                      ],
                      [
                          -73.540197,
                          45.401762,
                          0
                      ],
                      [
                          -73.540037,
                          45.401919,
                          0
                      ],
                      [
                          -73.540349,
                          45.402176,
                          0
                      ],
                      [
                          -73.540046,
                          45.402475,
                          0
                      ],
                      [
                          -73.539336,
                          45.428376,
                          0
                      ],
                      [
                          -73.521829,
                          45.452539,
                          0
                      ],
                      [
                          -73.497612,
                          45.435425,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "La Prairie",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.620775,
                          45.523652,
                          0
                      ],
                      [
                          -73.622145,
                          45.524025,
                          0
                      ],
                      [
                          -73.622767,
                          45.523352,
                          0
                      ],
                      [
                          -73.64831,
                          45.530755,
                          0
                      ],
                      [
                          -73.648303,
                          45.530767,
                          0
                      ],
                      [
                          -73.641655,
                          45.541656,
                          0
                      ],
                      [
                          -73.635941,
                          45.548607,
                          0
                      ],
                      [
                          -73.63371,
                          45.549754,
                          0
                      ],
                      [
                          -73.629659,
                          45.550615,
                          0
                      ],
                      [
                          -73.62162,
                          45.554538,
                          0
                      ],
                      [
                          -73.607051,
                          45.547946,
                          0
                      ],
                      [
                          -73.607051,
                          45.547945,
                          0
                      ],
                      [
                          -73.612652,
                          45.541685,
                          0
                      ],
                      [
                          -73.613895,
                          45.539037,
                          0
                      ],
                      [
                          -73.614433,
                          45.538299,
                          0
                      ],
                      [
                          -73.616862,
                          45.5357,
                          0
                      ],
                      [
                          -73.617248,
                          45.535292,
                          0
                      ],
                      [
                          -73.6182,
                          45.53479,
                          0
                      ],
                      [
                          -73.621522,
                          45.531115,
                          0
                      ],
                      [
                          -73.619224,
                          45.530722,
                          0
                      ],
                      [
                          -73.61802,
                          45.530063,
                          0
                      ],
                      [
                          -73.617627,
                          45.529668,
                          0
                      ],
                      [
                          -73.617133,
                          45.528898,
                          0
                      ],
                      [
                          -73.617064,
                          45.528431,
                          0
                      ],
                      [
                          -73.617294,
                          45.527784,
                          0
                      ],
                      [
                          -73.620775,
                          45.523652,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Laurier-Dorion",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.692505,
                          45.549364,
                          0
                      ],
                      [
                          -73.703632,
                          45.546593,
                          0
                      ],
                      [
                          -73.708668,
                          45.543766,
                          0
                      ],
                      [
                          -73.712328,
                          45.540361,
                          0
                      ],
                      [
                          -73.71233,
                          45.540362,
                          0
                      ],
                      [
                          -73.712535,
                          45.540485,
                          0
                      ],
                      [
                          -73.714372,
                          45.541861,
                          0
                      ],
                      [
                          -73.718794,
                          45.546361,
                          0
                      ],
                      [
                          -73.732068,
                          45.558065,
                          0
                      ],
                      [
                          -73.737225,
                          45.562911,
                          0
                      ],
                      [
                          -73.75109,
                          45.5759,
                          0
                      ],
                      [
                          -73.734863,
                          45.586068,
                          0
                      ],
                      [
                          -73.727958,
                          45.588883,
                          0
                      ],
                      [
                          -73.713203,
                          45.593967,
                          0
                      ],
                      [
                          -73.704235,
                          45.601452,
                          0
                      ],
                      [
                          -73.69789,
                          45.604978,
                          0
                      ],
                      [
                          -73.690427,
                          45.594665,
                          0
                      ],
                      [
                          -73.681559,
                          45.58621,
                          0
                      ],
                      [
                          -73.679192,
                          45.584969,
                          0
                      ],
                      [
                          -73.677637,
                          45.584486,
                          0
                      ],
                      [
                          -73.674576,
                          45.583089,
                          0
                      ],
                      [
                          -73.666815,
                          45.576188,
                          0
                      ],
                      [
                          -73.667843,
                          45.573137,
                          0
                      ],
                      [
                          -73.66717,
                          45.567378,
                          0
                      ],
                      [
                          -73.675098,
                          45.558046,
                          0
                      ],
                      [
                          -73.679815,
                          45.552976,
                          0
                      ],
                      [
                          -73.692505,
                          45.549364,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Laval-des-Rapides",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.779345,
                          45.815015,
                          0
                      ],
                      [
                          -73.776623,
                          45.81055,
                          0
                      ],
                      [
                          -73.770642,
                          45.810296,
                          0
                      ],
                      [
                          -73.770638,
                          45.810322,
                          0
                      ],
                      [
                          -73.769664,
                          45.816733,
                          0
                      ],
                      [
                          -73.769631,
                          45.816725,
                          0
                      ],
                      [
                          -73.755378,
                          45.81343,
                          0
                      ],
                      [
                          -73.755626,
                          45.811622,
                          0
                      ],
                      [
                          -73.747235,
                          45.810039,
                          0
                      ],
                      [
                          -73.740605,
                          45.805618,
                          0
                      ],
                      [
                          -73.696866,
                          45.804394,
                          0
                      ],
                      [
                          -73.696865,
                          45.804394,
                          0
                      ],
                      [
                          -73.700513,
                          45.796941,
                          0
                      ],
                      [
                          -73.696106,
                          45.795765,
                          0
                      ],
                      [
                          -73.695684,
                          45.796571,
                          0
                      ],
                      [
                          -73.693872,
                          45.796227,
                          0
                      ],
                      [
                          -73.694217,
                          45.795621,
                          0
                      ],
                      [
                          -73.690221,
                          45.79444,
                          0
                      ],
                      [
                          -73.688111,
                          45.794258,
                          0
                      ],
                      [
                          -73.687975,
                          45.794164,
                          0
                      ],
                      [
                          -73.687901,
                          45.793767,
                          0
                      ],
                      [
                          -73.701049,
                          45.768633,
                          0
                      ],
                      [
                          -73.690361,
                          45.764969,
                          0
                      ],
                      [
                          -73.699299,
                          45.74704,
                          0
                      ],
                      [
                          -73.699335,
                          45.746967,
                          0
                      ],
                      [
                          -73.714809,
                          45.757272,
                          0
                      ],
                      [
                          -73.714811,
                          45.757274,
                          0
                      ],
                      [
                          -73.751139,
                          45.750492,
                          0
                      ],
                      [
                          -73.745793,
                          45.732437,
                          0
                      ],
                      [
                          -73.746369,
                          45.732579,
                          0
                      ],
                      [
                          -73.747784,
                          45.732585,
                          0
                      ],
                      [
                          -73.748885,
                          45.7327,
                          0
                      ],
                      [
                          -73.749594,
                          45.73292,
                          0
                      ],
                      [
                          -73.750231,
                          45.732808,
                          0
                      ],
                      [
                          -73.751037,
                          45.732698,
                          0
                      ],
                      [
                          -73.751291,
                          45.732162,
                          0
                      ],
                      [
                          -73.751291,
                          45.732159,
                          0
                      ],
                      [
                          -73.751089,
                          45.731653,
                          0
                      ],
                      [
                          -73.751248,
                          45.731369,
                          0
                      ],
                      [
                          -73.752269,
                          45.730505,
                          0
                      ],
                      [
                          -73.752549,
                          45.730459,
                          0
                      ],
                      [
                          -73.753408,
                          45.73061,
                          0
                      ],
                      [
                          -73.754015,
                          45.730472,
                          0
                      ],
                      [
                          -73.755443,
                          45.729919,
                          0
                      ],
                      [
                          -73.75648,
                          45.729659,
                          0
                      ],
                      [
                          -73.757188,
                          45.72967,
                          0
                      ],
                      [
                          -73.757435,
                          45.729725,
                          0
                      ],
                      [
                          -73.757736,
                          45.729606,
                          0
                      ],
                      [
                          -73.757855,
                          45.729297,
                          0
                      ],
                      [
                          -73.758749,
                          45.728626,
                          0
                      ],
                      [
                          -73.75907,
                          45.728273,
                          0
                      ],
                      [
                          -73.758498,
                          45.726331,
                          0
                      ],
                      [
                          -73.758651,
                          45.726309,
                          0
                      ],
                      [
                          -73.756206,
                          45.717768,
                          0
                      ],
                      [
                          -73.759208,
                          45.715184,
                          0
                      ],
                      [
                          -73.761401,
                          45.714396,
                          0
                      ],
                      [
                          -73.764767,
                          45.713186,
                          0
                      ],
                      [
                          -73.767137,
                          45.713023,
                          0
                      ],
                      [
                          -73.770029,
                          45.713299,
                          0
                      ],
                      [
                          -73.774448,
                          45.713659,
                          0
                      ],
                      [
                          -73.777205,
                          45.713729,
                          0
                      ],
                      [
                          -73.77848,
                          45.714089,
                          0
                      ],
                      [
                          -73.778605,
                          45.714124,
                          0
                      ],
                      [
                          -73.779985,
                          45.714505,
                          0
                      ],
                      [
                          -73.780002,
                          45.714513,
                          0
                      ],
                      [
                          -73.781204,
                          45.714716,
                          0
                      ],
                      [
                          -73.781297,
                          45.714729,
                          0
                      ],
                      [
                          -73.783112,
                          45.714975,
                          0
                      ],
                      [
                          -73.786468,
                          45.715733,
                          0
                      ],
                      [
                          -73.789617,
                          45.715468,
                          0
                      ],
                      [
                          -73.792753,
                          45.716057,
                          0
                      ],
                      [
                          -73.794457,
                          45.717439,
                          0
                      ],
                      [
                          -73.797531,
                          45.716531,
                          0
                      ],
                      [
                          -73.801698,
                          45.719278,
                          0
                      ],
                      [
                          -73.809993,
                          45.719504,
                          0
                      ],
                      [
                          -73.812481,
                          45.71995,
                          0
                      ],
                      [
                          -73.816675,
                          45.719786,
                          0
                      ],
                      [
                          -73.818878,
                          45.719853,
                          0
                      ],
                      [
                          -73.822249,
                          45.720079,
                          0
                      ],
                      [
                          -73.832708,
                          45.721417,
                          0
                      ],
                      [
                          -73.834138,
                          45.721997,
                          0
                      ],
                      [
                          -73.839223,
                          45.722622,
                          0
                      ],
                      [
                          -73.841129,
                          45.722123,
                          0
                      ],
                      [
                          -73.846171,
                          45.721947,
                          0
                      ],
                      [
                          -73.850393,
                          45.724717,
                          0
                      ],
                      [
                          -73.855915,
                          45.723876,
                          0
                      ],
                      [
                          -73.857881,
                          45.722968,
                          0
                      ],
                      [
                          -73.863675,
                          45.722179,
                          0
                      ],
                      [
                          -73.865622,
                          45.721165,
                          0
                      ],
                      [
                          -73.868993,
                          45.721191,
                          0
                      ],
                      [
                          -73.875837,
                          45.719104,
                          0
                      ],
                      [
                          -73.875725,
                          45.71682,
                          0
                      ],
                      [
                          -73.881923,
                          45.715394,
                          0
                      ],
                      [
                          -73.883016,
                          45.71486,
                          0
                      ],
                      [
                          -73.886168,
                          45.714703,
                          0
                      ],
                      [
                          -73.88782,
                          45.713971,
                          0
                      ],
                      [
                          -73.892829,
                          45.715081,
                          0
                      ],
                      [
                          -73.893843,
                          45.713459,
                          0
                      ],
                      [
                          -73.894196,
                          45.711385,
                          0
                      ],
                      [
                          -73.8963,
                          45.710985,
                          0
                      ],
                      [
                          -73.898544,
                          45.70903,
                          0
                      ],
                      [
                          -73.900934,
                          45.704967,
                          0
                      ],
                      [
                          -73.908572,
                          45.702221,
                          0
                      ],
                      [
                          -73.916035,
                          45.696672,
                          0
                      ],
                      [
                          -73.916083,
                          45.696637,
                          0
                      ],
                      [
                          -73.916018,
                          45.696594,
                          0
                      ],
                      [
                          -73.913347,
                          45.694873,
                          0
                      ],
                      [
                          -73.925832,
                          45.68541,
                          0
                      ],
                      [
                          -73.926011,
                          45.685251,
                          0
                      ],
                      [
                          -73.969557,
                          45.714285,
                          0
                      ],
                      [
                          -73.970908,
                          45.717481,
                          0
                      ],
                      [
                          -73.970386,
                          45.723743,
                          0
                      ],
                      [
                          -73.972064,
                          45.726865,
                          0
                      ],
                      [
                          -73.976823,
                          45.730277,
                          0
                      ],
                      [
                          -74.015302,
                          45.753234,
                          0
                      ],
                      [
                          -74.015305,
                          45.753235,
                          0
                      ],
                      [
                          -74.014086,
                          45.753336,
                          0
                      ],
                      [
                          -74.011177,
                          45.75264,
                          0
                      ],
                      [
                          -74.0083,
                          45.751292,
                          0
                      ],
                      [
                          -74.005722,
                          45.749353,
                          0
                      ],
                      [
                          -74.004137,
                          45.748807,
                          0
                      ],
                      [
                          -74.002047,
                          45.748551,
                          0
                      ],
                      [
                          -74.000737,
                          45.748931,
                          0
                      ],
                      [
                          -74.000582,
                          45.752396,
                          0
                      ],
                      [
                          -73.997978,
                          45.755164,
                          0
                      ],
                      [
                          -73.981057,
                          45.74444,
                          0
                      ],
                      [
                          -73.976274,
                          45.748083,
                          0
                      ],
                      [
                          -73.979383,
                          45.7504,
                          0
                      ],
                      [
                          -73.968118,
                          45.75871,
                          0
                      ],
                      [
                          -73.976084,
                          45.763843,
                          0
                      ],
                      [
                          -73.975834,
                          45.76434,
                          0
                      ],
                      [
                          -73.975065,
                          45.764303,
                          0
                      ],
                      [
                          -73.975205,
                          45.764581,
                          0
                      ],
                      [
                          -73.97497,
                          45.764697,
                          0
                      ],
                      [
                          -73.974747,
                          45.764885,
                          0
                      ],
                      [
                          -73.973697,
                          45.764773,
                          0
                      ],
                      [
                          -73.973311,
                          45.764857,
                          0
                      ],
                      [
                          -73.97332,
                          45.765147,
                          0
                      ],
                      [
                          -73.973144,
                          45.765197,
                          0
                      ],
                      [
                          -73.972629,
                          45.765083,
                          0
                      ],
                      [
                          -73.972107,
                          45.765324,
                          0
                      ],
                      [
                          -73.971946,
                          45.765551,
                          0
                      ],
                      [
                          -73.971256,
                          45.76536,
                          0
                      ],
                      [
                          -73.971084,
                          45.765583,
                          0
                      ],
                      [
                          -73.970972,
                          45.765615,
                          0
                      ],
                      [
                          -73.970827,
                          45.765526,
                          0
                      ],
                      [
                          -73.96955,
                          45.765415,
                          0
                      ],
                      [
                          -73.968914,
                          45.764946,
                          0
                      ],
                      [
                          -73.96774,
                          45.76492,
                          0
                      ],
                      [
                          -73.972641,
                          45.768145,
                          0
                      ],
                      [
                          -73.97263,
                          45.768157,
                          0
                      ],
                      [
                          -73.972623,
                          45.768163,
                          0
                      ],
                      [
                          -73.972629,
                          45.768167,
                          0
                      ],
                      [
                          -73.966081,
                          45.77471,
                          0
                      ],
                      [
                          -73.959698,
                          45.779206,
                          0
                      ],
                      [
                          -73.960669,
                          45.779845,
                          0
                      ],
                      [
                          -73.947658,
                          45.789491,
                          0
                      ],
                      [
                          -73.947455,
                          45.789323,
                          0
                      ],
                      [
                          -73.913575,
                          45.766559,
                          0
                      ],
                      [
                          -73.860249,
                          45.802312,
                          0
                      ],
                      [
                          -73.86018,
                          45.802357,
                          0
                      ],
                      [
                          -73.868921,
                          45.808817,
                          0
                      ],
                      [
                          -73.868965,
                          45.808849,
                          0
                      ],
                      [
                          -73.810045,
                          45.82074,
                          0
                      ],
                      [
                          -73.809492,
                          45.820369,
                          0
                      ],
                      [
                          -73.807612,
                          45.821661,
                          0
                      ],
                      [
                          -73.80039,
                          45.826485,
                          0
                      ],
                      [
                          -73.797655,
                          45.827175,
                          0
                      ],
                      [
                          -73.779345,
                          45.815015,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Les Plaines",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -71.172551,
                          46.842564,
                          0
                      ],
                      [
                          -71.131725,
                          46.837561,
                          0
                      ],
                      [
                          -71.078856,
                          46.839139,
                          0
                      ],
                      [
                          -71.046189,
                          46.816006,
                          0
                      ],
                      [
                          -71.063774,
                          46.812793,
                          0
                      ],
                      [
                          -71.091082,
                          46.814681,
                          0
                      ],
                      [
                          -71.129639,
                          46.801302,
                          0
                      ],
                      [
                          -71.16671,
                          46.778211,
                          0
                      ],
                      [
                          -71.18438,
                          46.755575,
                          0
                      ],
                      [
                          -71.186996,
                          46.751955,
                          0
                      ],
                      [
                          -71.190057,
                          46.74918,
                          0
                      ],
                      [
                          -71.192178,
                          46.748434,
                          0
                      ],
                      [
                          -71.234404,
                          46.736422,
                          0
                      ],
                      [
                          -71.257764,
                          46.728486,
                          0
                      ],
                      [
                          -71.268981,
                          46.7266,
                          0
                      ],
                      [
                          -71.278542,
                          46.724947,
                          0
                      ],
                      [
                          -71.286246,
                          46.721401,
                          0
                      ],
                      [
                          -71.286079,
                          46.723147,
                          0
                      ],
                      [
                          -71.285903,
                          46.725953,
                          0
                      ],
                      [
                          -71.286996,
                          46.727675,
                          0
                      ],
                      [
                          -71.287048,
                          46.728761,
                          0
                      ],
                      [
                          -71.28705,
                          46.728786,
                          0
                      ],
                      [
                          -71.286755,
                          46.729758,
                          0
                      ],
                      [
                          -71.285666,
                          46.730772,
                          0
                      ],
                      [
                          -71.284323,
                          46.731919,
                          0
                      ],
                      [
                          -71.28432,
                          46.733017,
                          0
                      ],
                      [
                          -71.284812,
                          46.733923,
                          0
                      ],
                      [
                          -71.284485,
                          46.734377,
                          0
                      ],
                      [
                          -71.282859,
                          46.735954,
                          0
                      ],
                      [
                          -71.282844,
                          46.735964,
                          0
                      ],
                      [
                          -71.281147,
                          46.736714,
                          0
                      ],
                      [
                          -71.277786,
                          46.73731,
                          0
                      ],
                      [
                          -71.274266,
                          46.738344,
                          0
                      ],
                      [
                          -71.273269,
                          46.73952,
                          0
                      ],
                      [
                          -71.274225,
                          46.740519,
                          0
                      ],
                      [
                          -71.283704,
                          46.746729,
                          0
                      ],
                      [
                          -71.242364,
                          46.766465,
                          0
                      ],
                      [
                          -71.214145,
                          46.785257,
                          0
                      ],
                      [
                          -71.206895,
                          46.792145,
                          0
                      ],
                      [
                          -71.199561,
                          46.800728,
                          0
                      ],
                      [
                          -71.194033,
                          46.813396,
                          0
                      ],
                      [
                          -71.187646,
                          46.825105,
                          0
                      ],
                      [
                          -71.194568,
                          46.830182,
                          0
                      ],
                      [
                          -71.180596,
                          46.836687,
                          0
                      ],
                      [
                          -71.172551,
                          46.842564,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Lévis",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -71.427587,
                          46.63662,
                          0
                      ],
                      [
                          -71.419873,
                          46.639278,
                          0
                      ],
                      [
                          -71.403232,
                          46.628295,
                          0
                      ],
                      [
                          -71.403396,
                          46.628245,
                          0
                      ],
                      [
                          -71.403528,
                          46.628192,
                          0
                      ],
                      [
                          -71.409553,
                          46.625734,
                          0
                      ],
                      [
                          -71.332132,
                          46.573015,
                          0
                      ],
                      [
                          -71.300773,
                          46.551076,
                          0
                      ],
                      [
                          -71.268628,
                          46.52876,
                          0
                      ],
                      [
                          -71.249779,
                          46.541798,
                          0
                      ],
                      [
                          -71.24994,
                          46.541909,
                          0
                      ],
                      [
                          -71.249772,
                          46.542025,
                          0
                      ],
                      [
                          -71.242839,
                          46.546812,
                          0
                      ],
                      [
                          -71.241266,
                          46.544116,
                          0
                      ],
                      [
                          -71.239667,
                          46.542891,
                          0
                      ],
                      [
                          -71.235421,
                          46.534264,
                          0
                      ],
                      [
                          -71.233899,
                          46.532248,
                          0
                      ],
                      [
                          -71.232151,
                          46.53117,
                          0
                      ],
                      [
                          -71.232078,
                          46.529831,
                          0
                      ],
                      [
                          -71.215917,
                          46.541044,
                          0
                      ],
                      [
                          -71.194856,
                          46.526468,
                          0
                      ],
                      [
                          -71.211023,
                          46.515186,
                          0
                      ],
                      [
                          -71.191605,
                          46.501676,
                          0
                      ],
                      [
                          -71.208456,
                          46.494728,
                          0
                      ],
                      [
                          -71.208265,
                          46.494541,
                          0
                      ],
                      [
                          -71.225951,
                          46.482672,
                          0
                      ],
                      [
                          -71.227895,
                          46.481381,
                          0
                      ],
                      [
                          -71.227621,
                          46.481136,
                          0
                      ],
                      [
                          -71.226959,
                          46.480514,
                          0
                      ],
                      [
                          -71.215493,
                          46.470055,
                          0
                      ],
                      [
                          -71.181324,
                          46.438538,
                          0
                      ],
                      [
                          -71.166642,
                          46.424963,
                          0
                      ],
                      [
                          -71.166639,
                          46.424961,
                          0
                      ],
                      [
                          -71.159018,
                          46.417921,
                          0
                      ],
                      [
                          -71.154795,
                          46.410599,
                          0
                      ],
                      [
                          -71.148821,
                          46.39918,
                          0
                      ],
                      [
                          -71.145153,
                          46.394978,
                          0
                      ],
                      [
                          -71.136666,
                          46.386992,
                          0
                      ],
                      [
                          -71.132314,
                          46.3828,
                          0
                      ],
                      [
                          -71.13062,
                          46.38112,
                          0
                      ],
                      [
                          -71.122279,
                          46.376895,
                          0
                      ],
                      [
                          -71.117535,
                          46.37446,
                          0
                      ],
                      [
                          -71.115169,
                          46.373209,
                          0
                      ],
                      [
                          -71.113393,
                          46.371694,
                          0
                      ],
                      [
                          -71.110591,
                          46.363915,
                          0
                      ],
                      [
                          -71.109607,
                          46.363091,
                          0
                      ],
                      [
                          -71.103204,
                          46.358953,
                          0
                      ],
                      [
                          -71.121712,
                          46.349569,
                          0
                      ],
                      [
                          -71.124799,
                          46.351728,
                          0
                      ],
                      [
                          -71.131562,
                          46.348393,
                          0
                      ],
                      [
                          -71.145466,
                          46.341312,
                          0
                      ],
                      [
                          -71.145662,
                          46.341213,
                          0
                      ],
                      [
                          -71.13748,
                          46.334838,
                          0
                      ],
                      [
                          -71.132534,
                          46.332071,
                          0
                      ],
                      [
                          -71.122865,
                          46.325825,
                          0
                      ],
                      [
                          -71.100104,
                          46.308906,
                          0
                      ],
                      [
                          -71.104962,
                          46.308721,
                          0
                      ],
                      [
                          -71.105155,
                          46.308709,
                          0
                      ],
                      [
                          -71.106479,
                          46.307852,
                          0
                      ],
                      [
                          -71.119519,
                          46.299062,
                          0
                      ],
                      [
                          -71.117135,
                          46.297092,
                          0
                      ],
                      [
                          -71.11019,
                          46.290892,
                          0
                      ],
                      [
                          -71.109057,
                          46.289891,
                          0
                      ],
                      [
                          -71.106017,
                          46.286525,
                          0
                      ],
                      [
                          -71.104046,
                          46.284378,
                          0
                      ],
                      [
                          -71.104043,
                          46.283722,
                          0
                      ],
                      [
                          -71.098602,
                          46.278888,
                          0
                      ],
                      [
                          -71.099782,
                          46.27815,
                          0
                      ],
                      [
                          -71.098341,
                          46.277238,
                          0
                      ],
                      [
                          -71.083411,
                          46.287289,
                          0
                      ],
                      [
                          -71.082715,
                          46.287711,
                          0
                      ],
                      [
                          -71.065966,
                          46.272296,
                          0
                      ],
                      [
                          -71.049721,
                          46.256671,
                          0
                      ],
                      [
                          -71.003567,
                          46.212872,
                          0
                      ],
                      [
                          -70.978977,
                          46.190123,
                          0
                      ],
                      [
                          -71.013391,
                          46.167953,
                          0
                      ],
                      [
                          -71.013388,
                          46.167951,
                          0
                      ],
                      [
                          -71.001944,
                          46.158987,
                          0
                      ],
                      [
                          -71.002038,
                          46.158924,
                          0
                      ],
                      [
                          -71.015285,
                          46.150116,
                          0
                      ],
                      [
                          -71.015294,
                          46.150111,
                          0
                      ],
                      [
                          -71.015569,
                          46.150315,
                          0
                      ],
                      [
                          -71.027241,
                          46.158915,
                          0
                      ],
                      [
                          -71.036109,
                          46.153328,
                          0
                      ],
                      [
                          -71.036381,
                          46.15359,
                          0
                      ],
                      [
                          -71.037429,
                          46.154603,
                          0
                      ],
                      [
                          -71.037756,
                          46.154807,
                          0
                      ],
                      [
                          -71.040381,
                          46.157239,
                          0
                      ],
                      [
                          -71.043469,
                          46.160215,
                          0
                      ],
                      [
                          -71.043523,
                          46.16035,
                          0
                      ],
                      [
                          -71.043589,
                          46.160421,
                          0
                      ],
                      [
                          -71.04373,
                          46.16057,
                          0
                      ],
                      [
                          -71.048749,
                          46.165339,
                          0
                      ],
                      [
                          -71.048751,
                          46.16534,
                          0
                      ],
                      [
                          -71.048854,
                          46.165419,
                          0
                      ],
                      [
                          -71.062063,
                          46.156477,
                          0
                      ],
                      [
                          -71.062106,
                          46.156448,
                          0
                      ],
                      [
                          -71.066447,
                          46.16051,
                          0
                      ],
                      [
                          -71.062059,
                          46.163481,
                          0
                      ],
                      [
                          -71.062051,
                          46.163485,
                          0
                      ],
                      [
                          -71.066601,
                          46.167687,
                          0
                      ],
                      [
                          -71.066602,
                          46.167689,
                          0
                      ],
                      [
                          -71.070974,
                          46.164802,
                          0
                      ],
                      [
                          -71.074668,
                          46.168169,
                          0
                      ],
                      [
                          -71.088576,
                          46.159242,
                          0
                      ],
                      [
                          -71.079897,
                          46.151327,
                          0
                      ],
                      [
                          -71.094731,
                          46.141716,
                          0
                      ],
                      [
                          -71.094733,
                          46.141714,
                          0
                      ],
                      [
                          -71.10503,
                          46.135143,
                          0
                      ],
                      [
                          -71.105027,
                          46.135142,
                          0
                      ],
                      [
                          -71.103503,
                          46.134565,
                          0
                      ],
                      [
                          -71.101813,
                          46.134177,
                          0
                      ],
                      [
                          -71.101086,
                          46.13404,
                          0
                      ],
                      [
                          -71.100625,
                          46.134013,
                          0
                      ],
                      [
                          -71.100483,
                          46.133749,
                          0
                      ],
                      [
                          -71.099501,
                          46.133785,
                          0
                      ],
                      [
                          -71.097781,
                          46.133299,
                          0
                      ],
                      [
                          -71.097663,
                          46.133263,
                          0
                      ],
                      [
                          -71.097561,
                          46.133238,
                          0
                      ],
                      [
                          -71.097343,
                          46.133277,
                          0
                      ],
                      [
                          -71.094475,
                          46.133557,
                          0
                      ],
                      [
                          -71.093011,
                          46.133563,
                          0
                      ],
                      [
                          -71.090961,
                          46.133295,
                          0
                      ],
                      [
                          -71.089528,
                          46.132944,
                          0
                      ],
                      [
                          -71.087983,
                          46.132432,
                          0
                      ],
                      [
                          -71.087941,
                          46.132415,
                          0
                      ],
                      [
                          -71.100544,
                          46.124687,
                          0
                      ],
                      [
                          -71.100542,
                          46.124685,
                          0
                      ],
                      [
                          -71.096716,
                          46.121009,
                          0
                      ],
                      [
                          -71.079822,
                          46.105612,
                          0
                      ],
                      [
                          -71.042248,
                          46.073531,
                          0
                      ],
                      [
                          -71.042248,
                          46.073531,
                          0
                      ],
                      [
                          -71.029552,
                          46.081686,
                          0
                      ],
                      [
                          -71.02955,
                          46.081685,
                          0
                      ],
                      [
                          -71.029514,
                          46.081649,
                          0
                      ],
                      [
                          -71.011318,
                          46.066358,
                          0
                      ],
                      [
                          -71.024738,
                          46.057732,
                          0
                      ],
                      [
                          -70.987608,
                          46.024734,
                          0
                      ],
                      [
                          -71.00204,
                          46.010578,
                          0
                      ],
                      [
                          -71.002042,
                          46.010576,
                          0
                      ],
                      [
                          -70.990366,
                          46.004852,
                          0
                      ],
                      [
                          -70.990367,
                          46.00485,
                          0
                      ],
                      [
                          -71.007584,
                          45.987987,
                          0
                      ],
                      [
                          -71.03314,
                          46.000006,
                          0
                      ],
                      [
                          -71.046496,
                          46.006349,
                          0
                      ],
                      [
                          -71.064174,
                          45.988798,
                          0
                      ],
                      [
                          -71.090336,
                          45.962602,
                          0
                      ],
                      [
                          -71.10677,
                          45.946136,
                          0
                      ],
                      [
                          -71.12977,
                          45.957376,
                          0
                      ],
                      [
                          -71.129778,
                          45.957377,
                          0
                      ],
                      [
                          -71.163281,
                          45.924403,
                          0
                      ],
                      [
                          -71.163282,
                          45.924404,
                          0
                      ],
                      [
                          -71.189322,
                          45.952306,
                          0
                      ],
                      [
                          -71.194853,
                          45.956223,
                          0
                      ],
                      [
                          -71.203355,
                          45.960278,
                          0
                      ],
                      [
                          -71.224601,
                          45.967504,
                          0
                      ],
                      [
                          -71.228098,
                          45.968113,
                          0
                      ],
                      [
                          -71.234713,
                          45.967923,
                          0
                      ],
                      [
                          -71.243471,
                          45.965636,
                          0
                      ],
                      [
                          -71.258167,
                          45.957212,
                          0
                      ],
                      [
                          -71.269479,
                          45.951134,
                          0
                      ],
                      [
                          -71.269552,
                          45.951113,
                          0
                      ],
                      [
                          -71.273579,
                          45.94945,
                          0
                      ],
                      [
                          -71.274495,
                          45.94782,
                          0
                      ],
                      [
                          -71.277714,
                          45.94538,
                          0
                      ],
                      [
                          -71.279323,
                          45.942023,
                          0
                      ],
                      [
                          -71.282333,
                          45.939532,
                          0
                      ],
                      [
                          -71.285062,
                          45.938781,
                          0
                      ],
                      [
                          -71.286775,
                          45.937546,
                          0
                      ],
                      [
                          -71.289578,
                          45.936722,
                          0
                      ],
                      [
                          -71.290084,
                          45.9347,
                          0
                      ],
                      [
                          -71.29183,
                          45.933816,
                          0
                      ],
                      [
                          -71.297949,
                          45.933517,
                          0
                      ],
                      [
                          -71.302341,
                          45.931722,
                          0
                      ],
                      [
                          -71.305189,
                          45.932201,
                          0
                      ],
                      [
                          -71.306652,
                          45.930898,
                          0
                      ],
                      [
                          -71.307269,
                          45.929189,
                          0
                      ],
                      [
                          -71.309081,
                          45.928943,
                          0
                      ],
                      [
                          -71.310878,
                          45.927535,
                          0
                      ],
                      [
                          -71.311716,
                          45.924889,
                          0
                      ],
                      [
                          -71.412342,
                          45.977683,
                          0
                      ],
                      [
                          -71.458518,
                          45.932806,
                          0
                      ],
                      [
                          -71.445812,
                          45.926195,
                          0
                      ],
                      [
                          -71.456485,
                          45.915777,
                          0
                      ],
                      [
                          -71.455617,
                          45.915285,
                          0
                      ],
                      [
                          -71.45649,
                          45.91468,
                          0
                      ],
                      [
                          -71.456171,
                          45.91292,
                          0
                      ],
                      [
                          -71.456882,
                          45.912029,
                          0
                      ],
                      [
                          -71.456132,
                          45.910969,
                          0
                      ],
                      [
                          -71.456021,
                          45.910673,
                          0
                      ],
                      [
                          -71.457397,
                          45.90963,
                          0
                      ],
                      [
                          -71.45885,
                          45.908999,
                          0
                      ],
                      [
                          -71.464328,
                          45.907982,
                          0
                      ],
                      [
                          -71.471469,
                          45.906336,
                          0
                      ],
                      [
                          -71.472542,
                          45.905784,
                          0
                      ],
                      [
                          -71.476626,
                          45.904306,
                          0
                      ],
                      [
                          -71.481932,
                          45.901102,
                          0
                      ],
                      [
                          -71.482617,
                          45.899606,
                          0
                      ],
                      [
                          -71.482342,
                          45.897869,
                          0
                      ],
                      [
                          -71.483211,
                          45.896497,
                          0
                      ],
                      [
                          -71.485157,
                          45.894761,
                          0
                      ],
                      [
                          -71.486011,
                          45.894528,
                          0
                      ],
                      [
                          -71.487405,
                          45.894211,
                          0
                      ],
                      [
                          -71.486007,
                          45.893273,
                          0
                      ],
                      [
                          -71.485973,
                          45.89325,
                          0
                      ],
                      [
                          -71.489786,
                          45.889464,
                          0
                      ],
                      [
                          -71.489788,
                          45.889465,
                          0
                      ],
                      [
                          -71.489811,
                          45.889477,
                          0
                      ],
                      [
                          -71.490043,
                          45.889591,
                          0
                      ],
                      [
                          -71.498463,
                          45.893819,
                          0
                      ],
                      [
                          -71.501667,
                          45.890859,
                          0
                      ],
                      [
                          -71.551399,
                          45.916105,
                          0
                      ],
                      [
                          -71.551401,
                          45.916104,
                          0
                      ],
                      [
                          -71.562141,
                          45.906038,
                          0
                      ],
                      [
                          -71.562201,
                          45.906067,
                          0
                      ],
                      [
                          -71.686121,
                          45.966709,
                          0
                      ],
                      [
                          -71.626521,
                          46.02442,
                          0
                      ],
                      [
                          -71.625733,
                          46.025058,
                          0
                      ],
                      [
                          -71.625733,
                          46.025058,
                          0
                      ],
                      [
                          -71.55163,
                          46.09709,
                          0
                      ],
                      [
                          -71.509702,
                          46.137345,
                          0
                      ],
                      [
                          -71.50973,
                          46.137359,
                          0
                      ],
                      [
                          -71.514664,
                          46.13978,
                          0
                      ],
                      [
                          -71.533884,
                          46.149153,
                          0
                      ],
                      [
                          -71.523122,
                          46.159975,
                          0
                      ],
                      [
                          -71.525671,
                          46.16128,
                          0
                      ],
                      [
                          -71.529479,
                          46.163136,
                          0
                      ],
                      [
                          -71.517808,
                          46.174023,
                          0
                      ],
                      [
                          -71.517854,
                          46.174048,
                          0
                      ],
                      [
                          -71.483258,
                          46.206644,
                          0
                      ],
                      [
                          -71.488004,
                          46.209082,
                          0
                      ],
                      [
                          -71.486819,
                          46.212149,
                          0
                      ],
                      [
                          -71.485769,
                          46.21347,
                          0
                      ],
                      [
                          -71.466822,
                          46.231194,
                          0
                      ],
                      [
                          -71.466801,
                          46.231183,
                          0
                      ],
                      [
                          -71.445487,
                          46.220213,
                          0
                      ],
                      [
                          -71.434587,
                          46.230834,
                          0
                      ],
                      [
                          -71.419268,
                          46.223292,
                          0
                      ],
                      [
                          -71.371979,
                          46.267767,
                          0
                      ],
                      [
                          -71.371931,
                          46.267811,
                          0
                      ],
                      [
                          -71.508494,
                          46.338389,
                          0
                      ],
                      [
                          -71.481429,
                          46.356011,
                          0
                      ],
                      [
                          -71.481431,
                          46.356014,
                          0
                      ],
                      [
                          -71.494104,
                          46.366472,
                          0
                      ],
                      [
                          -71.508142,
                          46.376578,
                          0
                      ],
                      [
                          -71.478982,
                          46.395866,
                          0
                      ],
                      [
                          -71.478987,
                          46.395869,
                          0
                      ],
                      [
                          -71.531409,
                          46.434387,
                          0
                      ],
                      [
                          -71.565531,
                          46.412848,
                          0
                      ],
                      [
                          -71.617574,
                          46.451706,
                          0
                      ],
                      [
                          -71.617574,
                          46.451706,
                          0
                      ],
                      [
                          -71.720678,
                          46.389632,
                          0
                      ],
                      [
                          -71.759699,
                          46.363932,
                          0
                      ],
                      [
                          -71.881162,
                          46.450756,
                          0
                      ],
                      [
                          -71.881198,
                          46.450781,
                          0
                      ],
                      [
                          -72.01181,
                          46.543665,
                          0
                      ],
                      [
                          -72.013345,
                          46.542691,
                          0
                      ],
                      [
                          -72.028408,
                          46.55297,
                          0
                      ],
                      [
                          -72.029782,
                          46.553214,
                          0
                      ],
                      [
                          -72.030717,
                          46.552682,
                          0
                      ],
                      [
                          -72.033079,
                          46.555369,
                          0
                      ],
                      [
                          -72.035468,
                          46.556949,
                          0
                      ],
                      [
                          -72.037665,
                          46.557239,
                          0
                      ],
                      [
                          -72.035656,
                          46.557905,
                          0
                      ],
                      [
                          -72.054318,
                          46.570508,
                          0
                      ],
                      [
                          -72.05432,
                          46.570509,
                          0
                      ],
                      [
                          -72.05432,
                          46.570509,
                          0
                      ],
                      [
                          -72.013291,
                          46.585569,
                          0
                      ],
                      [
                          -71.944024,
                          46.627818,
                          0
                      ],
                      [
                          -71.881667,
                          46.673953,
                          0
                      ],
                      [
                          -71.836529,
                          46.676508,
                          0
                      ],
                      [
                          -71.783025,
                          46.649956,
                          0
                      ],
                      [
                          -71.672553,
                          46.652894,
                          0
                      ],
                      [
                          -71.614797,
                          46.676262,
                          0
                      ],
                      [
                          -71.519721,
                          46.699467,
                          0
                      ],
                      [
                          -71.427587,
                          46.63662,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Lotbinière-Frontenac",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -71.41946,
                          46.828516,
                          0
                      ],
                      [
                          -71.416454,
                          46.826449,
                          0
                      ],
                      [
                          -71.415047,
                          46.82769,
                          0
                      ],
                      [
                          -71.412771,
                          46.826117,
                          0
                      ],
                      [
                          -71.410436,
                          46.826774,
                          0
                      ],
                      [
                          -71.409649,
                          46.826255,
                          0
                      ],
                      [
                          -71.349695,
                          46.785304,
                          0
                      ],
                      [
                          -71.348456,
                          46.784973,
                          0
                      ],
                      [
                          -71.343405,
                          46.783905,
                          0
                      ],
                      [
                          -71.342275,
                          46.783635,
                          0
                      ],
                      [
                          -71.341182,
                          46.783186,
                          0
                      ],
                      [
                          -71.333307,
                          46.778802,
                          0
                      ],
                      [
                          -71.339663,
                          46.772893,
                          0
                      ],
                      [
                          -71.309787,
                          46.751399,
                          0
                      ],
                      [
                          -71.2964,
                          46.749739,
                          0
                      ],
                      [
                          -71.294994,
                          46.744962,
                          0
                      ],
                      [
                          -71.354637,
                          46.727464,
                          0
                      ],
                      [
                          -71.506252,
                          46.703228,
                          0
                      ],
                      [
                          -71.606651,
                          46.776156,
                          0
                      ],
                      [
                          -71.552567,
                          46.783976,
                          0
                      ],
                      [
                          -71.501969,
                          46.79247,
                          0
                      ],
                      [
                          -71.512888,
                          46.799993,
                          0
                      ],
                      [
                          -71.440621,
                          46.813316,
                          0
                      ],
                      [
                          -71.41946,
                          46.828516,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Louis-Hébert",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.606829,
                          45.454209,
                          0
                      ],
                      [
                          -73.605007,
                          45.44938,
                          0
                      ],
                      [
                          -73.60477,
                          45.448715,
                          0
                      ],
                      [
                          -73.605012,
                          45.448219,
                          0
                      ],
                      [
                          -73.6101,
                          45.44369,
                          0
                      ],
                      [
                          -73.610405,
                          45.443258,
                          0
                      ],
                      [
                          -73.611752,
                          45.439474,
                          0
                      ],
                      [
                          -73.599502,
                          45.437522,
                          0
                      ],
                      [
                          -73.585924,
                          45.435357,
                          0
                      ],
                      [
                          -73.539336,
                          45.428376,
                          0
                      ],
                      [
                          -73.597412,
                          45.415994,
                          0
                      ],
                      [
                          -73.597487,
                          45.415973,
                          0
                      ],
                      [
                          -73.597627,
                          45.415933,
                          0
                      ],
                      [
                          -73.612161,
                          45.411578,
                          0
                      ],
                      [
                          -73.643594,
                          45.410927,
                          0
                      ],
                      [
                          -73.666389,
                          45.421853,
                          0
                      ],
                      [
                          -73.666009,
                          45.42844,
                          0
                      ],
                      [
                          -73.665859,
                          45.432101,
                          0
                      ],
                      [
                          -73.662125,
                          45.433443,
                          0
                      ],
                      [
                          -73.65204,
                          45.43802,
                          0
                      ],
                      [
                          -73.647432,
                          45.440554,
                          0
                      ],
                      [
                          -73.639406,
                          45.443797,
                          0
                      ],
                      [
                          -73.634751,
                          45.445564,
                          0
                      ],
                      [
                          -73.630984,
                          45.447206,
                          0
                      ],
                      [
                          -73.629145,
                          45.448425,
                          0
                      ],
                      [
                          -73.629103,
                          45.448461,
                          0
                      ],
                      [
                          -73.628558,
                          45.448253,
                          0
                      ],
                      [
                          -73.626312,
                          45.450469,
                          0
                      ],
                      [
                          -73.624717,
                          45.451613,
                          0
                      ],
                      [
                          -73.621321,
                          45.453974,
                          0
                      ],
                      [
                          -73.616499,
                          45.456181,
                          0
                      ],
                      [
                          -73.613434,
                          45.457523,
                          0
                      ],
                      [
                          -73.606829,
                          45.454209,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Marguerite-Bourgeoys",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.439779,
                          45.511825,
                          0
                      ],
                      [
                          -73.441308,
                          45.509928,
                          0
                      ],
                      [
                          -73.442146,
                          45.50925,
                          0
                      ],
                      [
                          -73.442946,
                          45.508858,
                          0
                      ],
                      [
                          -73.442425,
                          45.507705,
                          0
                      ],
                      [
                          -73.442564,
                          45.507502,
                          0
                      ],
                      [
                          -73.460073,
                          45.506358,
                          0
                      ],
                      [
                          -73.460371,
                          45.50594,
                          0
                      ],
                      [
                          -73.461729,
                          45.505796,
                          0
                      ],
                      [
                          -73.469883,
                          45.505206,
                          0
                      ],
                      [
                          -73.480061,
                          45.504526,
                          0
                      ],
                      [
                          -73.478869,
                          45.505236,
                          0
                      ],
                      [
                          -73.482735,
                          45.504981,
                          0
                      ],
                      [
                          -73.48472,
                          45.504844,
                          0
                      ],
                      [
                          -73.487633,
                          45.504563,
                          0
                      ],
                      [
                          -73.487658,
                          45.504761,
                          0
                      ],
                      [
                          -73.488376,
                          45.504542,
                          0
                      ],
                      [
                          -73.489596,
                          45.504368,
                          0
                      ],
                      [
                          -73.490396,
                          45.506818,
                          0
                      ],
                      [
                          -73.49102,
                          45.507942,
                          0
                      ],
                      [
                          -73.491874,
                          45.50888,
                          0
                      ],
                      [
                          -73.492703,
                          45.509594,
                          0
                      ],
                      [
                          -73.493706,
                          45.510277,
                          0
                      ],
                      [
                          -73.494925,
                          45.510949,
                          0
                      ],
                      [
                          -73.498704,
                          45.512111,
                          0
                      ],
                      [
                          -73.504966,
                          45.514137,
                          0
                      ],
                      [
                          -73.503469,
                          45.511529,
                          0
                      ],
                      [
                          -73.503512,
                          45.511466,
                          0
                      ],
                      [
                          -73.506224,
                          45.512547,
                          0
                      ],
                      [
                          -73.514709,
                          45.515535,
                          0
                      ],
                      [
                          -73.518205,
                          45.51678,
                          0
                      ],
                      [
                          -73.51932,
                          45.517076,
                          0
                      ],
                      [
                          -73.519825,
                          45.517103,
                          0
                      ],
                      [
                          -73.519895,
                          45.517336,
                          0
                      ],
                      [
                          -73.520314,
                          45.517448,
                          0
                      ],
                      [
                          -73.526005,
                          45.518958,
                          0
                      ],
                      [
                          -73.530887,
                          45.534675,
                          0
                      ],
                      [
                          -73.531445,
                          45.536469,
                          0
                      ],
                      [
                          -73.519986,
                          45.545352,
                          0
                      ],
                      [
                          -73.519436,
                          45.545151,
                          0
                      ],
                      [
                          -73.509833,
                          45.541552,
                          0
                      ],
                      [
                          -73.499488,
                          45.536861,
                          0
                      ],
                      [
                          -73.457255,
                          45.519162,
                          0
                      ],
                      [
                          -73.439779,
                          45.511825,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Marie-Victorin",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.766731,
                          45.488468,
                          0
                      ],
                      [
                          -73.766909,
                          45.488429,
                          0
                      ],
                      [
                          -73.76688,
                          45.488351,
                          0
                      ],
                      [
                          -73.766662,
                          45.488312,
                          0
                      ],
                      [
                          -73.76662,
                          45.488263,
                          0
                      ],
                      [
                          -73.766828,
                          45.487921,
                          0
                      ],
                      [
                          -73.766828,
                          45.487921,
                          0
                      ],
                      [
                          -73.766342,
                          45.487794,
                          0
                      ],
                      [
                          -73.766583,
                          45.48761,
                          0
                      ],
                      [
                          -73.766583,
                          45.48761,
                          0
                      ],
                      [
                          -73.766583,
                          45.48761,
                          0
                      ],
                      [
                          -73.766296,
                          45.486999,
                          0
                      ],
                      [
                          -73.766176,
                          45.487047,
                          0
                      ],
                      [
                          -73.766079,
                          45.487037,
                          0
                      ],
                      [
                          -73.766084,
                          45.486946,
                          0
                      ],
                      [
                          -73.766164,
                          45.48691,
                          0
                      ],
                      [
                          -73.766118,
                          45.486643,
                          0
                      ],
                      [
                          -73.766803,
                          45.486128,
                          0
                      ],
                      [
                          -73.766935,
                          45.485756,
                          0
                      ],
                      [
                          -73.768002,
                          45.485418,
                          0
                      ],
                      [
                          -73.768096,
                          45.484758,
                          0
                      ],
                      [
                          -73.7742,
                          45.481813,
                          0
                      ],
                      [
                          -73.766161,
                          45.47676,
                          0
                      ],
                      [
                          -73.768388,
                          45.474962,
                          0
                      ],
                      [
                          -73.76236,
                          45.468565,
                          0
                      ],
                      [
                          -73.762412,
                          45.468524,
                          0
                      ],
                      [
                          -73.762757,
                          45.468249,
                          0
                      ],
                      [
                          -73.750593,
                          45.46073,
                          0
                      ],
                      [
                          -73.722355,
                          45.482681,
                          0
                      ],
                      [
                          -73.694871,
                          45.464614,
                          0
                      ],
                      [
                          -73.693304,
                          45.470326,
                          0
                      ],
                      [
                          -73.682806,
                          45.463179,
                          0
                      ],
                      [
                          -73.681287,
                          45.462153,
                          0
                      ],
                      [
                          -73.681831,
                          45.461364,
                          0
                      ],
                      [
                          -73.6828,
                          45.461012,
                          0
                      ],
                      [
                          -73.683193,
                          45.460095,
                          0
                      ],
                      [
                          -73.683023,
                          45.460029,
                          0
                      ],
                      [
                          -73.685658,
                          45.457428,
                          0
                      ],
                      [
                          -73.686497,
                          45.456756,
                          0
                      ],
                      [
                          -73.687234,
                          45.455402,
                          0
                      ],
                      [
                          -73.677506,
                          45.452732,
                          0
                      ],
                      [
                          -73.677498,
                          45.452732,
                          0
                      ],
                      [
                          -73.671789,
                          45.452692,
                          0
                      ],
                      [
                          -73.66942,
                          45.455633,
                          0
                      ],
                      [
                          -73.668167,
                          45.45655,
                          0
                      ],
                      [
                          -73.644813,
                          45.447597,
                          0
                      ],
                      [
                          -73.643134,
                          45.447899,
                          0
                      ],
                      [
                          -73.643142,
                          45.448312,
                          0
                      ],
                      [
                          -73.638807,
                          45.446692,
                          0
                      ],
                      [
                          -73.636861,
                          45.44773,
                          0
                      ],
                      [
                          -73.635826,
                          45.448146,
                          0
                      ],
                      [
                          -73.631966,
                          45.449508,
                          0
                      ],
                      [
                          -73.629145,
                          45.448425,
                          0
                      ],
                      [
                          -73.630984,
                          45.447206,
                          0
                      ],
                      [
                          -73.634751,
                          45.445564,
                          0
                      ],
                      [
                          -73.639406,
                          45.443797,
                          0
                      ],
                      [
                          -73.647432,
                          45.440554,
                          0
                      ],
                      [
                          -73.65204,
                          45.43802,
                          0
                      ],
                      [
                          -73.662125,
                          45.433443,
                          0
                      ],
                      [
                          -73.665859,
                          45.432101,
                          0
                      ],
                      [
                          -73.666009,
                          45.42844,
                          0
                      ],
                      [
                          -73.666389,
                          45.421853,
                          0
                      ],
                      [
                          -73.697203,
                          45.423069,
                          0
                      ],
                      [
                          -73.767955,
                          45.418681,
                          0
                      ],
                      [
                          -73.782282,
                          45.45445,
                          0
                      ],
                      [
                          -73.780021,
                          45.454868,
                          0
                      ],
                      [
                          -73.7832,
                          45.463413,
                          0
                      ],
                      [
                          -73.784563,
                          45.465083,
                          0
                      ],
                      [
                          -73.78027,
                          45.467175,
                          0
                      ],
                      [
                          -73.794534,
                          45.481681,
                          0
                      ],
                      [
                          -73.768427,
                          45.494448,
                          0
                      ],
                      [
                          -73.764415,
                          45.490392,
                          0
                      ],
                      [
                          -73.767045,
                          45.48917,
                          0
                      ],
                      [
                          -73.766949,
                          45.48894,
                          0
                      ],
                      [
                          -73.767116,
                          45.488882,
                          0
                      ],
                      [
                          -73.767095,
                          45.488711,
                          0
                      ],
                      [
                          -73.766819,
                          45.488626,
                          0
                      ],
                      [
                          -73.766731,
                          45.488468,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Marquette",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.550079,
                          45.816911,
                          0
                      ],
                      [
                          -73.550045,
                          45.816906,
                          0
                      ],
                      [
                          -73.550042,
                          45.816852,
                          0
                      ],
                      [
                          -73.551784,
                          45.793472,
                          0
                      ],
                      [
                          -73.524937,
                          45.792398,
                          0
                      ],
                      [
                          -73.528004,
                          45.753359,
                          0
                      ],
                      [
                          -73.520498,
                          45.749468,
                          0
                      ],
                      [
                          -73.5205,
                          45.748919,
                          0
                      ],
                      [
                          -73.518016,
                          45.74771,
                          0
                      ],
                      [
                          -73.518693,
                          45.744879,
                          0
                      ],
                      [
                          -73.502022,
                          45.736602,
                          0
                      ],
                      [
                          -73.502009,
                          45.736594,
                          0
                      ],
                      [
                          -73.500337,
                          45.735343,
                          0
                      ],
                      [
                          -73.500429,
                          45.728264,
                          0
                      ],
                      [
                          -73.500429,
                          45.728217,
                          0
                      ],
                      [
                          -73.497212,
                          45.725808,
                          0
                      ],
                      [
                          -73.497191,
                          45.722107,
                          0
                      ],
                      [
                          -73.493214,
                          45.719254,
                          0
                      ],
                      [
                          -73.492958,
                          45.720468,
                          0
                      ],
                      [
                          -73.488348,
                          45.717165,
                          0
                      ],
                      [
                          -73.487189,
                          45.71254,
                          0
                      ],
                      [
                          -73.487187,
                          45.712528,
                          0
                      ],
                      [
                          -73.491829,
                          45.711175,
                          0
                      ],
                      [
                          -73.497243,
                          45.706833,
                          0
                      ],
                      [
                          -73.497604,
                          45.704868,
                          0
                      ],
                      [
                          -73.508785,
                          45.702334,
                          0
                      ],
                      [
                          -73.508874,
                          45.702316,
                          0
                      ],
                      [
                          -73.523096,
                          45.697885,
                          0
                      ],
                      [
                          -73.528076,
                          45.699804,
                          0
                      ],
                      [
                          -73.543437,
                          45.698655,
                          0
                      ],
                      [
                          -73.557623,
                          45.700324,
                          0
                      ],
                      [
                          -73.580601,
                          45.69456,
                          0
                      ],
                      [
                          -73.578513,
                          45.697421,
                          0
                      ],
                      [
                          -73.577722,
                          45.700561,
                          0
                      ],
                      [
                          -73.57658,
                          45.715102,
                          0
                      ],
                      [
                          -73.668525,
                          45.726414,
                          0
                      ],
                      [
                          -73.699299,
                          45.74704,
                          0
                      ],
                      [
                          -73.690361,
                          45.764969,
                          0
                      ],
                      [
                          -73.701049,
                          45.768633,
                          0
                      ],
                      [
                          -73.687901,
                          45.793767,
                          0
                      ],
                      [
                          -73.687975,
                          45.794164,
                          0
                      ],
                      [
                          -73.685826,
                          45.79247,
                          0
                      ],
                      [
                          -73.68185,
                          45.791199,
                          0
                      ],
                      [
                          -73.679855,
                          45.790257,
                          0
                      ],
                      [
                          -73.677717,
                          45.789745,
                          0
                      ],
                      [
                          -73.677708,
                          45.789743,
                          0
                      ],
                      [
                          -73.677496,
                          45.790029,
                          0
                      ],
                      [
                          -73.675741,
                          45.789602,
                          0
                      ],
                      [
                          -73.67561,
                          45.789174,
                          0
                      ],
                      [
                          -73.669107,
                          45.787643,
                          0
                      ],
                      [
                          -73.669462,
                          45.786983,
                          0
                      ],
                      [
                          -73.669096,
                          45.786716,
                          0
                      ],
                      [
                          -73.660644,
                          45.791356,
                          0
                      ],
                      [
                          -73.661567,
                          45.792244,
                          0
                      ],
                      [
                          -73.654022,
                          45.79604,
                          0
                      ],
                      [
                          -73.653999,
                          45.796053,
                          0
                      ],
                      [
                          -73.653092,
                          45.798443,
                          0
                      ],
                      [
                          -73.65393,
                          45.799225,
                          0
                      ],
                      [
                          -73.652883,
                          45.799927,
                          0
                      ],
                      [
                          -73.654001,
                          45.801034,
                          0
                      ],
                      [
                          -73.6537,
                          45.803042,
                          0
                      ],
                      [
                          -73.653733,
                          45.803072,
                          0
                      ],
                      [
                          -73.645947,
                          45.81272,
                          0
                      ],
                      [
                          -73.64429,
                          45.813567,
                          0
                      ],
                      [
                          -73.619519,
                          45.79641,
                          0
                      ],
                      [
                          -73.619491,
                          45.796392,
                          0
                      ],
                      [
                          -73.594555,
                          45.81323,
                          0
                      ],
                      [
                          -73.594555,
                          45.81323,
                          0
                      ],
                      [
                          -73.594509,
                          45.813261,
                          0
                      ],
                      [
                          -73.593294,
                          45.81246,
                          0
                      ],
                      [
                          -73.591141,
                          45.812224,
                          0
                      ],
                      [
                          -73.590593,
                          45.816952,
                          0
                      ],
                      [
                          -73.580422,
                          45.816,
                          0
                      ],
                      [
                          -73.580381,
                          45.815996,
                          0
                      ],
                      [
                          -73.580173,
                          45.819282,
                          0
                      ],
                      [
                          -73.580821,
                          45.819351,
                          0
                      ],
                      [
                          -73.580689,
                          45.820453,
                          0
                      ],
                      [
                          -73.580689,
                          45.820453,
                          0
                      ],
                      [
                          -73.550079,
                          45.816911,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Masson",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.641655,
                          45.541656,
                          0
                      ],
                      [
                          -73.679815,
                          45.552976,
                          0
                      ],
                      [
                          -73.675098,
                          45.558046,
                          0
                      ],
                      [
                          -73.66717,
                          45.567378,
                          0
                      ],
                      [
                          -73.667843,
                          45.573137,
                          0
                      ],
                      [
                          -73.666815,
                          45.576188,
                          0
                      ],
                      [
                          -73.646745,
                          45.599181,
                          0
                      ],
                      [
                          -73.644488,
                          45.597583,
                          0
                      ],
                      [
                          -73.642592,
                          45.595832,
                          0
                      ],
                      [
                          -73.647883,
                          45.589401,
                          0
                      ],
                      [
                          -73.64872,
                          45.587753,
                          0
                      ],
                      [
                          -73.650321,
                          45.583897,
                          0
                      ],
                      [
                          -73.640014,
                          45.577406,
                          0
                      ],
                      [
                          -73.636516,
                          45.575937,
                          0
                      ],
                      [
                          -73.636277,
                          45.575836,
                          0
                      ],
                      [
                          -73.64463,
                          45.565848,
                          0
                      ],
                      [
                          -73.645379,
                          45.564733,
                          0
                      ],
                      [
                          -73.62162,
                          45.554538,
                          0
                      ],
                      [
                          -73.629659,
                          45.550615,
                          0
                      ],
                      [
                          -73.63371,
                          45.549754,
                          0
                      ],
                      [
                          -73.635941,
                          45.548607,
                          0
                      ],
                      [
                          -73.641655,
                          45.541656,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Maurice-Richard",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.57368,
                          45.526171,
                          0
                      ],
                      [
                          -73.574005,
                          45.525793,
                          0
                      ],
                      [
                          -73.574679,
                          45.525562,
                          0
                      ],
                      [
                          -73.581696,
                          45.517994,
                          0
                      ],
                      [
                          -73.583349,
                          45.516257,
                          0
                      ],
                      [
                          -73.587958,
                          45.518354,
                          0
                      ],
                      [
                          -73.590264,
                          45.515779,
                          0
                      ],
                      [
                          -73.61232,
                          45.525679,
                          0
                      ],
                      [
                          -73.609458,
                          45.52726,
                          0
                      ],
                      [
                          -73.607129,
                          45.528235,
                          0
                      ],
                      [
                          -73.605709,
                          45.528356,
                          0
                      ],
                      [
                          -73.601292,
                          45.528466,
                          0
                      ],
                      [
                          -73.598024,
                          45.529261,
                          0
                      ],
                      [
                          -73.596069,
                          45.530184,
                          0
                      ],
                      [
                          -73.591873,
                          45.533588,
                          0
                      ],
                      [
                          -73.586315,
                          45.538344,
                          0
                      ],
                      [
                          -73.585416,
                          45.5391,
                          0
                      ],
                      [
                          -73.584048,
                          45.539975,
                          0
                      ],
                      [
                          -73.582719,
                          45.540597,
                          0
                      ],
                      [
                          -73.58066,
                          45.54123,
                          0
                      ],
                      [
                          -73.579206,
                          45.541491,
                          0
                      ],
                      [
                          -73.577917,
                          45.541584,
                          0
                      ],
                      [
                          -73.576688,
                          45.541596,
                          0
                      ],
                      [
                          -73.574131,
                          45.541385,
                          0
                      ],
                      [
                          -73.561459,
                          45.540144,
                          0
                      ],
                      [
                          -73.561419,
                          45.539564,
                          0
                      ],
                      [
                          -73.561627,
                          45.539034,
                          0
                      ],
                      [
                          -73.563005,
                          45.537405,
                          0
                      ],
                      [
                          -73.57368,
                          45.526171,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Mercier",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.528076,
                          45.699804,
                          0
                      ],
                      [
                          -73.523096,
                          45.697885,
                          0
                      ],
                      [
                          -73.523099,
                          45.697883,
                          0
                      ],
                      [
                          -73.527643,
                          45.692553,
                          0
                      ],
                      [
                          -73.529306,
                          45.688087,
                          0
                      ],
                      [
                          -73.534206,
                          45.682671,
                          0
                      ],
                      [
                          -73.538653,
                          45.6788,
                          0
                      ],
                      [
                          -73.554181,
                          45.672698,
                          0
                      ],
                      [
                          -73.559899,
                          45.668707,
                          0
                      ],
                      [
                          -73.568806,
                          45.663992,
                          0
                      ],
                      [
                          -73.579677,
                          45.658694,
                          0
                      ],
                      [
                          -73.604421,
                          45.647342,
                          0
                      ],
                      [
                          -73.618081,
                          45.638924,
                          0
                      ],
                      [
                          -73.624576,
                          45.633092,
                          0
                      ],
                      [
                          -73.636329,
                          45.620637,
                          0
                      ],
                      [
                          -73.641283,
                          45.609069,
                          0
                      ],
                      [
                          -73.646745,
                          45.599181,
                          0
                      ],
                      [
                          -73.666815,
                          45.576188,
                          0
                      ],
                      [
                          -73.674576,
                          45.583089,
                          0
                      ],
                      [
                          -73.677637,
                          45.584486,
                          0
                      ],
                      [
                          -73.679192,
                          45.584969,
                          0
                      ],
                      [
                          -73.681559,
                          45.58621,
                          0
                      ],
                      [
                          -73.690427,
                          45.594665,
                          0
                      ],
                      [
                          -73.69789,
                          45.604978,
                          0
                      ],
                      [
                          -73.700676,
                          45.608858,
                          0
                      ],
                      [
                          -73.702926,
                          45.611421,
                          0
                      ],
                      [
                          -73.70395,
                          45.612416,
                          0
                      ],
                      [
                          -73.70965,
                          45.617455,
                          0
                      ],
                      [
                          -73.71542,
                          45.622608,
                          0
                      ],
                      [
                          -73.680965,
                          45.649731,
                          0
                      ],
                      [
                          -73.683464,
                          45.652066,
                          0
                      ],
                      [
                          -73.685081,
                          45.651905,
                          0
                      ],
                      [
                          -73.690187,
                          45.662531,
                          0
                      ],
                      [
                          -73.691036,
                          45.677975,
                          0
                      ],
                      [
                          -73.691386,
                          45.682965,
                          0
                      ],
                      [
                          -73.685721,
                          45.68608,
                          0
                      ],
                      [
                          -73.685612,
                          45.687262,
                          0
                      ],
                      [
                          -73.688382,
                          45.688555,
                          0
                      ],
                      [
                          -73.692489,
                          45.68912,
                          0
                      ],
                      [
                          -73.687085,
                          45.693489,
                          0
                      ],
                      [
                          -73.681545,
                          45.694223,
                          0
                      ],
                      [
                          -73.667057,
                          45.693045,
                          0
                      ],
                      [
                          -73.657752,
                          45.691272,
                          0
                      ],
                      [
                          -73.653738,
                          45.689037,
                          0
                      ],
                      [
                          -73.649045,
                          45.689085,
                          0
                      ],
                      [
                          -73.644562,
                          45.690251,
                          0
                      ],
                      [
                          -73.629288,
                          45.68958,
                          0
                      ],
                      [
                          -73.623386,
                          45.692924,
                          0
                      ],
                      [
                          -73.614827,
                          45.693193,
                          0
                      ],
                      [
                          -73.602244,
                          45.689224,
                          0
                      ],
                      [
                          -73.580601,
                          45.69456,
                          0
                      ],
                      [
                          -73.557623,
                          45.700324,
                          0
                      ],
                      [
                          -73.543437,
                          45.698655,
                          0
                      ],
                      [
                          -73.528076,
                          45.699804,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Mille-Îles",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -74.027537,
                          45.743575,
                          0
                      ],
                      [
                          -74.026006,
                          45.745126,
                          0
                      ],
                      [
                          -74.024171,
                          45.747445,
                          0
                      ],
                      [
                          -74.017371,
                          45.751153,
                          0
                      ],
                      [
                          -74.015305,
                          45.753235,
                          0
                      ],
                      [
                          -74.015302,
                          45.753234,
                          0
                      ],
                      [
                          -73.976823,
                          45.730277,
                          0
                      ],
                      [
                          -73.972064,
                          45.726865,
                          0
                      ],
                      [
                          -73.970386,
                          45.723743,
                          0
                      ],
                      [
                          -73.970908,
                          45.717481,
                          0
                      ],
                      [
                          -73.969557,
                          45.714285,
                          0
                      ],
                      [
                          -73.926011,
                          45.685251,
                          0
                      ],
                      [
                          -73.925832,
                          45.68541,
                          0
                      ],
                      [
                          -73.880912,
                          45.655103,
                          0
                      ],
                      [
                          -73.866504,
                          45.645496,
                          0
                      ],
                      [
                          -73.866028,
                          45.645548,
                          0
                      ],
                      [
                          -73.865388,
                          45.645049,
                          0
                      ],
                      [
                          -73.865796,
                          45.645008,
                          0
                      ],
                      [
                          -73.86392,
                          45.643731,
                          0
                      ],
                      [
                          -73.865035,
                          45.643512,
                          0
                      ],
                      [
                          -73.866484,
                          45.642597,
                          0
                      ],
                      [
                          -73.867106,
                          45.642503,
                          0
                      ],
                      [
                          -73.870096,
                          45.644442,
                          0
                      ],
                      [
                          -73.87809,
                          45.649726,
                          0
                      ],
                      [
                          -73.88244,
                          45.649157,
                          0
                      ],
                      [
                          -73.871529,
                          45.64186,
                          0
                      ],
                      [
                          -73.873947,
                          45.641378,
                          0
                      ],
                      [
                          -73.876528,
                          45.640837,
                          0
                      ],
                      [
                          -73.878742,
                          45.640021,
                          0
                      ],
                      [
                          -73.879526,
                          45.639687,
                          0
                      ],
                      [
                          -73.879781,
                          45.639299,
                          0
                      ],
                      [
                          -73.879913,
                          45.638809,
                          0
                      ],
                      [
                          -73.880194,
                          45.638619,
                          0
                      ],
                      [
                          -73.880635,
                          45.638675,
                          0
                      ],
                      [
                          -73.881721,
                          45.63896,
                          0
                      ],
                      [
                          -73.883021,
                          45.639014,
                          0
                      ],
                      [
                          -73.883922,
                          45.639216,
                          0
                      ],
                      [
                          -73.88493,
                          45.638775,
                          0
                      ],
                      [
                          -73.88819,
                          45.64107,
                          0
                      ],
                      [
                          -73.88932,
                          45.640908,
                          0
                      ],
                      [
                          -73.8899,
                          45.640742,
                          0
                      ],
                      [
                          -73.890619,
                          45.640393,
                          0
                      ],
                      [
                          -73.890158,
                          45.640058,
                          0
                      ],
                      [
                          -73.890524,
                          45.639766,
                          0
                      ],
                      [
                          -73.892602,
                          45.639232,
                          0
                      ],
                      [
                          -73.893319,
                          45.639721,
                          0
                      ],
                      [
                          -73.895232,
                          45.639004,
                          0
                      ],
                      [
                          -73.893076,
                          45.637604,
                          0
                      ],
                      [
                          -73.896174,
                          45.637296,
                          0
                      ],
                      [
                          -73.898519,
                          45.636709,
                          0
                      ],
                      [
                          -73.899777,
                          45.636529,
                          0
                      ],
                      [
                          -73.899834,
                          45.636257,
                          0
                      ],
                      [
                          -73.903385,
                          45.635687,
                          0
                      ],
                      [
                          -73.904263,
                          45.635891,
                          0
                      ],
                      [
                          -73.915742,
                          45.633771,
                          0
                      ],
                      [
                          -73.91729,
                          45.633699,
                          0
                      ],
                      [
                          -73.91835,
                          45.633796,
                          0
                      ],
                      [
                          -73.919635,
                          45.633282,
                          0
                      ],
                      [
                          -73.929848,
                          45.640437,
                          0
                      ],
                      [
                          -73.931384,
                          45.639443,
                          0
                      ],
                      [
                          -73.896495,
                          45.616992,
                          0
                      ],
                      [
                          -73.896198,
                          45.606648,
                          0
                      ],
                      [
                          -73.896198,
                          45.606645,
                          0
                      ],
                      [
                          -73.898454,
                          45.606628,
                          0
                      ],
                      [
                          -73.898456,
                          45.607015,
                          0
                      ],
                      [
                          -73.900806,
                          45.60704,
                          0
                      ],
                      [
                          -73.900914,
                          45.610315,
                          0
                      ],
                      [
                          -73.903136,
                          45.610326,
                          0
                      ],
                      [
                          -73.903198,
                          45.611548,
                          0
                      ],
                      [
                          -73.905263,
                          45.61153,
                          0
                      ],
                      [
                          -73.905319,
                          45.613004,
                          0
                      ],
                      [
                          -73.914317,
                          45.606164,
                          0
                      ],
                      [
                          -73.91432,
                          45.606162,
                          0
                      ],
                      [
                          -73.91438,
                          45.60615,
                          0
                      ],
                      [
                          -73.91438,
                          45.60615,
                          0
                      ],
                      [
                          -73.916304,
                          45.605763,
                          0
                      ],
                      [
                          -73.91633,
                          45.605758,
                          0
                      ],
                      [
                          -73.933117,
                          45.61656,
                          0
                      ],
                      [
                          -73.933289,
                          45.619264,
                          0
                      ],
                      [
                          -73.933295,
                          45.619345,
                          0
                      ],
                      [
                          -73.937838,
                          45.619345,
                          0
                      ],
                      [
                          -73.937863,
                          45.618803,
                          0
                      ],
                      [
                          -73.93998,
                          45.61879,
                          0
                      ],
                      [
                          -73.93997,
                          45.618665,
                          0
                      ],
                      [
                          -73.942202,
                          45.61863,
                          0
                      ],
                      [
                          -73.94221,
                          45.618747,
                          0
                      ],
                      [
                          -73.944441,
                          45.618728,
                          0
                      ],
                      [
                          -73.944507,
                          45.618727,
                          0
                      ],
                      [
                          -73.944665,
                          45.621588,
                          0
                      ],
                      [
                          -73.94467,
                          45.621676,
                          0
                      ],
                      [
                          -73.951288,
                          45.616682,
                          0
                      ],
                      [
                          -73.950604,
                          45.602413,
                          0
                      ],
                      [
                          -73.952874,
                          45.605375,
                          0
                      ],
                      [
                          -73.956598,
                          45.604709,
                          0
                      ],
                      [
                          -73.957518,
                          45.60555,
                          0
                      ],
                      [
                          -73.959608,
                          45.606222,
                          0
                      ],
                      [
                          -73.961193,
                          45.606119,
                          0
                      ],
                      [
                          -73.961072,
                          45.604828,
                          0
                      ],
                      [
                          -73.979944,
                          45.602505,
                          0
                      ],
                      [
                          -73.982887,
                          45.599426,
                          0
                      ],
                      [
                          -73.982652,
                          45.595715,
                          0
                      ],
                      [
                          -73.981573,
                          45.595196,
                          0
                      ],
                      [
                          -73.980016,
                          45.588857,
                          0
                      ],
                      [
                          -73.977964,
                          45.581619,
                          0
                      ],
                      [
                          -73.978746,
                          45.581706,
                          0
                      ],
                      [
                          -73.987743,
                          45.572031,
                          0
                      ],
                      [
                          -73.988139,
                          45.573763,
                          0
                      ],
                      [
                          -73.988369,
                          45.574952,
                          0
                      ],
                      [
                          -73.990542,
                          45.576423,
                          0
                      ],
                      [
                          -73.991433,
                          45.577012,
                          0
                      ],
                      [
                          -73.993705,
                          45.577227,
                          0
                      ],
                      [
                          -73.995353,
                          45.576618,
                          0
                      ],
                      [
                          -73.997663,
                          45.57628,
                          0
                      ],
                      [
                          -73.998291,
                          45.576096,
                          0
                      ],
                      [
                          -73.998907,
                          45.57611,
                          0
                      ],
                      [
                          -73.998884,
                          45.576506,
                          0
                      ],
                      [
                          -73.998284,
                          45.577744,
                          0
                      ],
                      [
                          -73.998936,
                          45.578072,
                          0
                      ],
                      [
                          -73.999996,
                          45.577835,
                          0
                      ],
                      [
                          -74.002996,
                          45.577312,
                          0
                      ],
                      [
                          -74.0036,
                          45.577488,
                          0
                      ],
                      [
                          -74.003425,
                          45.578241,
                          0
                      ],
                      [
                          -74.00409,
                          45.579073,
                          0
                      ],
                      [
                          -74.005189,
                          45.578681,
                          0
                      ],
                      [
                          -74.006015,
                          45.578579,
                          0
                      ],
                      [
                          -74.007532,
                          45.579136,
                          0
                      ],
                      [
                          -74.009637,
                          45.579339,
                          0
                      ],
                      [
                          -74.011399,
                          45.579562,
                          0
                      ],
                      [
                          -74.011933,
                          45.578299,
                          0
                      ],
                      [
                          -74.009769,
                          45.577478,
                          0
                      ],
                      [
                          -74.009697,
                          45.57685,
                          0
                      ],
                      [
                          -74.011842,
                          45.576231,
                          0
                      ],
                      [
                          -74.014666,
                          45.576064,
                          0
                      ],
                      [
                          -74.017853,
                          45.57666,
                          0
                      ],
                      [
                          -74.020212,
                          45.577519,
                          0
                      ],
                      [
                          -74.025508,
                          45.567497,
                          0
                      ],
                      [
                          -74.021283,
                          45.566383,
                          0
                      ],
                      [
                          -74.021405,
                          45.566118,
                          0
                      ],
                      [
                          -74.01938,
                          45.565624,
                          0
                      ],
                      [
                          -74.018821,
                          45.566735,
                          0
                      ],
                      [
                          -73.999997,
                          45.55321,
                          0
                      ],
                      [
                          -73.971765,
                          45.532883,
                          0
                      ],
                      [
                          -73.9735,
                          45.539628,
                          0
                      ],
                      [
                          -73.971223,
                          45.539864,
                          0
                      ],
                      [
                          -73.970736,
                          45.537945,
                          0
                      ],
                      [
                          -73.968704,
                          45.538207,
                          0
                      ],
                      [
                          -73.968651,
                          45.538002,
                          0
                      ],
                      [
                          -73.961731,
                          45.538892,
                          0
                      ],
                      [
                          -73.96163,
                          45.538545,
                          0
                      ],
                      [
                          -73.961627,
                          45.538545,
                          0
                      ],
                      [
                          -73.957196,
                          45.539082,
                          0
                      ],
                      [
                          -73.95719,
                          45.539065,
                          0
                      ],
                      [
                          -73.957117,
                          45.538878,
                          0
                      ],
                      [
                          -73.957105,
                          45.53888,
                          0
                      ],
                      [
                          -73.954857,
                          45.539167,
                          0
                      ],
                      [
                          -73.955005,
                          45.539793,
                          0
                      ],
                      [
                          -73.95282,
                          45.54006,
                          0
                      ],
                      [
                          -73.952914,
                          45.54048,
                          0
                      ],
                      [
                          -73.95059,
                          45.540771,
                          0
                      ],
                      [
                          -73.950586,
                          45.540757,
                          0
                      ],
                      [
                          -73.95052,
                          45.540512,
                          0
                      ],
                      [
                          -73.950509,
                          45.540514,
                          0
                      ],
                      [
                          -73.945948,
                          45.541092,
                          0
                      ],
                      [
                          -73.945969,
                          45.541206,
                          0
                      ],
                      [
                          -73.943497,
                          45.541563,
                          0
                      ],
                      [
                          -73.943408,
                          45.541408,
                          0
                      ],
                      [
                          -73.941516,
                          45.541647,
                          0
                      ],
                      [
                          -73.941568,
                          45.541838,
                          0
                      ],
                      [
                          -73.937648,
                          45.542399,
                          0
                      ],
                      [
                          -73.937648,
                          45.542399,
                          0
                      ],
                      [
                          -73.936931,
                          45.541866,
                          0
                      ],
                      [
                          -73.935444,
                          45.542055,
                          0
                      ],
                      [
                          -73.935314,
                          45.541672,
                          0
                      ],
                      [
                          -73.933992,
                          45.541819,
                          0
                      ],
                      [
                          -73.934281,
                          45.542961,
                          0
                      ],
                      [
                          -73.932066,
                          45.54331,
                          0
                      ],
                      [
                          -73.932063,
                          45.543311,
                          0
                      ],
                      [
                          -73.932281,
                          45.544126,
                          0
                      ],
                      [
                          -73.932292,
                          45.544168,
                          0
                      ],
                      [
                          -73.927809,
                          45.544742,
                          0
                      ],
                      [
                          -73.92705,
                          45.544184,
                          0
                      ],
                      [
                          -73.910769,
                          45.532253,
                          0
                      ],
                      [
                          -73.910542,
                          45.532291,
                          0
                      ],
                      [
                          -73.910513,
                          45.532297,
                          0
                      ],
                      [
                          -73.910478,
                          45.532304,
                          0
                      ],
                      [
                          -73.902116,
                          45.526114,
                          0
                      ],
                      [
                          -73.899882,
                          45.524459,
                          0
                      ],
                      [
                          -73.899878,
                          45.524457,
                          0
                      ],
                      [
                          -73.912029,
                          45.519771,
                          0
                      ],
                      [
                          -73.94195,
                          45.499646,
                          0
                      ],
                      [
                          -73.974353,
                          45.466428,
                          0
                      ],
                      [
                          -73.996602,
                          45.457486,
                          0
                      ],
                      [
                          -73.996602,
                          45.457486,
                          0
                      ],
                      [
                          -73.996605,
                          45.457485,
                          0
                      ],
                      [
                          -73.996605,
                          45.457485,
                          0
                      ],
                      [
                          -74.03975,
                          45.446381,
                          0
                      ],
                      [
                          -74.05922,
                          45.44974,
                          0
                      ],
                      [
                          -74.071132,
                          45.452792,
                          0
                      ],
                      [
                          -74.091964,
                          45.4526,
                          0
                      ],
                      [
                          -74.136007,
                          45.468849,
                          0
                      ],
                      [
                          -74.158635,
                          45.482571,
                          0
                      ],
                      [
                          -74.196548,
                          45.494873,
                          0
                      ],
                      [
                          -74.211661,
                          45.500043,
                          0
                      ],
                      [
                          -74.245533,
                          45.502675,
                          0
                      ],
                      [
                          -74.24575,
                          45.50268,
                          0
                      ],
                      [
                          -74.24575,
                          45.502682,
                          0
                      ],
                      [
                          -74.245748,
                          45.522436,
                          0
                      ],
                      [
                          -74.2486,
                          45.523822,
                          0
                      ],
                      [
                          -74.257006,
                          45.525458,
                          0
                      ],
                      [
                          -74.270279,
                          45.524734,
                          0
                      ],
                      [
                          -74.279735,
                          45.523522,
                          0
                      ],
                      [
                          -74.274255,
                          45.542988,
                          0
                      ],
                      [
                          -74.2672,
                          45.543369,
                          0
                      ],
                      [
                          -74.265737,
                          45.548678,
                          0
                      ],
                      [
                          -74.260513,
                          45.550645,
                          0
                      ],
                      [
                          -74.260507,
                          45.550645,
                          0
                      ],
                      [
                          -74.253298,
                          45.552468,
                          0
                      ],
                      [
                          -74.250839,
                          45.552651,
                          0
                      ],
                      [
                          -74.249394,
                          45.553099,
                          0
                      ],
                      [
                          -74.249144,
                          45.553477,
                          0
                      ],
                      [
                          -74.245917,
                          45.553125,
                          0
                      ],
                      [
                          -74.244016,
                          45.551672,
                          0
                      ],
                      [
                          -74.241985,
                          45.551528,
                          0
                      ],
                      [
                          -74.222818,
                          45.625863,
                          0
                      ],
                      [
                          -74.267606,
                          45.632033,
                          0
                      ],
                      [
                          -74.262628,
                          45.649312,
                          0
                      ],
                      [
                          -74.245447,
                          45.646825,
                          0
                      ],
                      [
                          -74.249526,
                          45.664686,
                          0
                      ],
                      [
                          -74.250782,
                          45.663884,
                          0
                      ],
                      [
                          -74.252529,
                          45.664981,
                          0
                      ],
                      [
                          -74.25626,
                          45.671246,
                          0
                      ],
                      [
                          -74.265566,
                          45.680702,
                          0
                      ],
                      [
                          -74.261573,
                          45.682701,
                          0
                      ],
                      [
                          -74.263286,
                          45.684476,
                          0
                      ],
                      [
                          -74.25843,
                          45.685603,
                          0
                      ],
                      [
                          -74.255021,
                          45.687481,
                          0
                      ],
                      [
                          -74.250922,
                          45.683388,
                          0
                      ],
                      [
                          -74.248984,
                          45.684328,
                          0
                      ],
                      [
                          -74.248335,
                          45.683663,
                          0
                      ],
                      [
                          -74.24065,
                          45.687493,
                          0
                      ],
                      [
                          -74.243297,
                          45.690117,
                          0
                      ],
                      [
                          -74.240527,
                          45.692335,
                          0
                      ],
                      [
                          -74.2396,
                          45.692234,
                          0
                      ],
                      [
                          -74.242962,
                          45.695794,
                          0
                      ],
                      [
                          -74.242216,
                          45.696642,
                          0
                      ],
                      [
                          -74.2422,
                          45.696659,
                          0
                      ],
                      [
                          -74.240646,
                          45.696263,
                          0
                      ],
                      [
                          -74.237485,
                          45.697007,
                          0
                      ],
                      [
                          -74.23742,
                          45.697934,
                          0
                      ],
                      [
                          -74.233697,
                          45.694666,
                          0
                      ],
                      [
                          -74.226414,
                          45.687777,
                          0
                      ],
                      [
                          -74.223527,
                          45.699259,
                          0
                      ],
                      [
                          -74.223504,
                          45.699262,
                          0
                      ],
                      [
                          -74.211905,
                          45.70043,
                          0
                      ],
                      [
                          -74.215336,
                          45.686546,
                          0
                      ],
                      [
                          -74.210283,
                          45.687682,
                          0
                      ],
                      [
                          -74.210551,
                          45.686647,
                          0
                      ],
                      [
                          -74.208002,
                          45.687224,
                          0
                      ],
                      [
                          -74.212092,
                          45.670586,
                          0
                      ],
                      [
                          -74.203024,
                          45.6754,
                          0
                      ],
                      [
                          -74.202019,
                          45.677941,
                          0
                      ],
                      [
                          -74.206252,
                          45.681295,
                          0
                      ],
                      [
                          -74.205776,
                          45.682594,
                          0
                      ],
                      [
                          -74.200672,
                          45.681859,
                          0
                      ],
                      [
                          -74.197348,
                          45.681634,
                          0
                      ],
                      [
                          -74.198636,
                          45.683547,
                          0
                      ],
                      [
                          -74.197579,
                          45.685567,
                          0
                      ],
                      [
                          -74.192457,
                          45.687849,
                          0
                      ],
                      [
                          -74.188404,
                          45.686527,
                          0
                      ],
                      [
                          -74.187041,
                          45.684961,
                          0
                      ],
                      [
                          -74.185449,
                          45.685311,
                          0
                      ],
                      [
                          -74.186206,
                          45.687477,
                          0
                      ],
                      [
                          -74.185374,
                          45.688972,
                          0
                      ],
                      [
                          -74.183192,
                          45.690381,
                          0
                      ],
                      [
                          -74.180758,
                          45.690985,
                          0
                      ],
                      [
                          -74.180757,
                          45.690985,
                          0
                      ],
                      [
                          -74.170399,
                          45.68885,
                          0
                      ],
                      [
                          -74.16587,
                          45.68886,
                          0
                      ],
                      [
                          -74.162839,
                          45.69043,
                          0
                      ],
                      [
                          -74.153761,
                          45.693055,
                          0
                      ],
                      [
                          -74.149008,
                          45.695349,
                          0
                      ],
                      [
                          -74.141793,
                          45.697555,
                          0
                      ],
                      [
                          -74.133347,
                          45.700194,
                          0
                      ],
                      [
                          -74.129469,
                          45.700652,
                          0
                      ],
                      [
                          -74.128539,
                          45.697763,
                          0
                      ],
                      [
                          -74.127795,
                          45.697663,
                          0
                      ],
                      [
                          -74.123965,
                          45.699565,
                          0
                      ],
                      [
                          -74.119886,
                          45.699963,
                          0
                      ],
                      [
                          -74.117573,
                          45.699218,
                          0
                      ],
                      [
                          -74.106753,
                          45.703464,
                          0
                      ],
                      [
                          -74.103615,
                          45.705987,
                          0
                      ],
                      [
                          -74.103612,
                          45.705989,
                          0
                      ],
                      [
                          -74.091761,
                          45.712235,
                          0
                      ],
                      [
                          -74.089909,
                          45.71853,
                          0
                      ],
                      [
                          -74.086312,
                          45.720885,
                          0
                      ],
                      [
                          -74.084938,
                          45.722701,
                          0
                      ],
                      [
                          -74.080429,
                          45.724161,
                          0
                      ],
                      [
                          -74.079341,
                          45.72593,
                          0
                      ],
                      [
                          -74.076596,
                          45.726208,
                          0
                      ],
                      [
                          -74.065313,
                          45.732454,
                          0
                      ],
                      [
                          -74.062081,
                          45.730852,
                          0
                      ],
                      [
                          -74.057643,
                          45.731292,
                          0
                      ],
                      [
                          -74.057425,
                          45.736623,
                          0
                      ],
                      [
                          -74.057422,
                          45.736625,
                          0
                      ],
                      [
                          -74.074271,
                          45.748234,
                          0
                      ],
                      [
                          -74.05211,
                          45.757823,
                          0
                      ],
                      [
                          -74.030294,
                          45.743592,
                          0
                      ],
                      [
                          -74.027537,
                          45.743575,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Mirabel",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.360054,
                          45.589885,
                          0
                      ],
                      [
                          -73.359778,
                          45.586067,
                          0
                      ],
                      [
                          -73.361074,
                          45.579481,
                          0
                      ],
                      [
                          -73.362131,
                          45.573794,
                          0
                      ],
                      [
                          -73.362129,
                          45.573793,
                          0
                      ],
                      [
                          -73.349688,
                          45.563688,
                          0
                      ],
                      [
                          -73.349685,
                          45.563686,
                          0
                      ],
                      [
                          -73.348262,
                          45.57114,
                          0
                      ],
                      [
                          -73.338422,
                          45.563553,
                          0
                      ],
                      [
                          -73.333819,
                          45.566627,
                          0
                      ],
                      [
                          -73.324534,
                          45.559023,
                          0
                      ],
                      [
                          -73.322554,
                          45.560397,
                          0
                      ],
                      [
                          -73.318746,
                          45.557451,
                          0
                      ],
                      [
                          -73.318153,
                          45.557406,
                          0
                      ],
                      [
                          -73.317064,
                          45.557403,
                          0
                      ],
                      [
                          -73.315859,
                          45.557188,
                          0
                      ],
                      [
                          -73.313986,
                          45.556876,
                          0
                      ],
                      [
                          -73.312444,
                          45.558351,
                          0
                      ],
                      [
                          -73.31253,
                          45.566435,
                          0
                      ],
                      [
                          -73.312039,
                          45.567837,
                          0
                      ],
                      [
                          -73.311951,
                          45.567914,
                          0
                      ],
                      [
                          -73.292625,
                          45.553594,
                          0
                      ],
                      [
                          -73.292775,
                          45.552707,
                          0
                      ],
                      [
                          -73.294771,
                          45.540439,
                          0
                      ],
                      [
                          -73.294821,
                          45.540178,
                          0
                      ],
                      [
                          -73.300839,
                          45.534701,
                          0
                      ],
                      [
                          -73.308714,
                          45.524127,
                          0
                      ],
                      [
                          -73.310015,
                          45.522406,
                          0
                      ],
                      [
                          -73.311838,
                          45.521846,
                          0
                      ],
                      [
                          -73.312484,
                          45.520269,
                          0
                      ],
                      [
                          -73.316196,
                          45.515835,
                          0
                      ],
                      [
                          -73.316777,
                          45.515494,
                          0
                      ],
                      [
                          -73.318064,
                          45.515323,
                          0
                      ],
                      [
                          -73.326538,
                          45.511579,
                          0
                      ],
                      [
                          -73.326888,
                          45.511857,
                          0
                      ],
                      [
                          -73.331888,
                          45.508634,
                          0
                      ],
                      [
                          -73.326758,
                          45.504739,
                          0
                      ],
                      [
                          -73.335314,
                          45.479686,
                          0
                      ],
                      [
                          -73.335327,
                          45.479648,
                          0
                      ],
                      [
                          -73.338627,
                          45.482124,
                          0
                      ],
                      [
                          -73.338637,
                          45.482132,
                          0
                      ],
                      [
                          -73.371415,
                          45.50753,
                          0
                      ],
                      [
                          -73.3715,
                          45.507474,
                          0
                      ],
                      [
                          -73.37197,
                          45.507843,
                          0
                      ],
                      [
                          -73.377149,
                          45.497281,
                          0
                      ],
                      [
                          -73.377231,
                          45.497321,
                          0
                      ],
                      [
                          -73.378991,
                          45.498176,
                          0
                      ],
                      [
                          -73.378647,
                          45.498887,
                          0
                      ],
                      [
                          -73.380828,
                          45.499407,
                          0
                      ],
                      [
                          -73.380996,
                          45.499066,
                          0
                      ],
                      [
                          -73.394169,
                          45.50959,
                          0
                      ],
                      [
                          -73.382855,
                          45.52145,
                          0
                      ],
                      [
                          -73.378575,
                          45.525321,
                          0
                      ],
                      [
                          -73.377644,
                          45.526192,
                          0
                      ],
                      [
                          -73.377011,
                          45.527662,
                          0
                      ],
                      [
                          -73.377018,
                          45.527667,
                          0
                      ],
                      [
                          -73.410666,
                          45.552969,
                          0
                      ],
                      [
                          -73.410674,
                          45.552949,
                          0
                      ],
                      [
                          -73.411093,
                          45.551753,
                          0
                      ],
                      [
                          -73.411336,
                          45.551139,
                          0
                      ],
                      [
                          -73.415103,
                          45.54824,
                          0
                      ],
                      [
                          -73.419615,
                          45.545265,
                          0
                      ],
                      [
                          -73.424062,
                          45.5487,
                          0
                      ],
                      [
                          -73.472578,
                          45.586403,
                          0
                      ],
                      [
                          -73.470878,
                          45.588604,
                          0
                      ],
                      [
                          -73.472843,
                          45.589177,
                          0
                      ],
                      [
                          -73.474409,
                          45.589099,
                          0
                      ],
                      [
                          -73.47947,
                          45.58704,
                          0
                      ],
                      [
                          -73.479228,
                          45.58646,
                          0
                      ],
                      [
                          -73.480051,
                          45.585908,
                          0
                      ],
                      [
                          -73.481311,
                          45.585865,
                          0
                      ],
                      [
                          -73.482166,
                          45.586442,
                          0
                      ],
                      [
                          -73.484376,
                          45.585678,
                          0
                      ],
                      [
                          -73.485666,
                          45.585632,
                          0
                      ],
                      [
                          -73.49884,
                          45.588198,
                          0
                      ],
                      [
                          -73.500082,
                          45.595417,
                          0
                      ],
                      [
                          -73.499456,
                          45.609371,
                          0
                      ],
                      [
                          -73.494861,
                          45.618869,
                          0
                      ],
                      [
                          -73.484105,
                          45.631597,
                          0
                      ],
                      [
                          -73.476913,
                          45.643633,
                          0
                      ],
                      [
                          -73.444975,
                          45.656698,
                          0
                      ],
                      [
                          -73.360054,
                          45.589885,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Montarville",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -71.129124,
                          47.004762,
                          0
                      ],
                      [
                          -71.172119,
                          46.982795,
                          0
                      ],
                      [
                          -71.191553,
                          46.970368,
                          0
                      ],
                      [
                          -71.205315,
                          46.966136,
                          0
                      ],
                      [
                          -71.205296,
                          46.966121,
                          0
                      ],
                      [
                          -71.192864,
                          46.948682,
                          0
                      ],
                      [
                          -71.190579,
                          46.942937,
                          0
                      ],
                      [
                          -71.192511,
                          46.938012,
                          0
                      ],
                      [
                          -71.168719,
                          46.912293,
                          0
                      ],
                      [
                          -71.172073,
                          46.906229,
                          0
                      ],
                      [
                          -71.172309,
                          46.904664,
                          0
                      ],
                      [
                          -71.169567,
                          46.904179,
                          0
                      ],
                      [
                          -71.170042,
                          46.902332,
                          0
                      ],
                      [
                          -71.165967,
                          46.897266,
                          0
                      ],
                      [
                          -71.161563,
                          46.894103,
                          0
                      ],
                      [
                          -71.159325,
                          46.894022,
                          0
                      ],
                      [
                          -71.155313,
                          46.896015,
                          0
                      ],
                      [
                          -71.151547,
                          46.895286,
                          0
                      ],
                      [
                          -71.148325,
                          46.89324,
                          0
                      ],
                      [
                          -71.14825,
                          46.891607,
                          0
                      ],
                      [
                          -71.147003,
                          46.88857,
                          0
                      ],
                      [
                          -71.144017,
                          46.885862,
                          0
                      ],
                      [
                          -71.1314,
                          46.88025,
                          0
                      ],
                      [
                          -71.131397,
                          46.880246,
                          0
                      ],
                      [
                          -71.172551,
                          46.842564,
                          0
                      ],
                      [
                          -71.199699,
                          46.850525,
                          0
                      ],
                      [
                          -71.199679,
                          46.852136,
                          0
                      ],
                      [
                          -71.199261,
                          46.853316,
                          0
                      ],
                      [
                          -71.199741,
                          46.854068,
                          0
                      ],
                      [
                          -71.200648,
                          46.854583,
                          0
                      ],
                      [
                          -71.20076,
                          46.855592,
                          0
                      ],
                      [
                          -71.199948,
                          46.856371,
                          0
                      ],
                      [
                          -71.199663,
                          46.857373,
                          0
                      ],
                      [
                          -71.199081,
                          46.858472,
                          0
                      ],
                      [
                          -71.197708,
                          46.859674,
                          0
                      ],
                      [
                          -71.196637,
                          46.860207,
                          0
                      ],
                      [
                          -71.197735,
                          46.861244,
                          0
                      ],
                      [
                          -71.200057,
                          46.861171,
                          0
                      ],
                      [
                          -71.201198,
                          46.861325,
                          0
                      ],
                      [
                          -71.202833,
                          46.862114,
                          0
                      ],
                      [
                          -71.204206,
                          46.862685,
                          0
                      ],
                      [
                          -71.205762,
                          46.863861,
                          0
                      ],
                      [
                          -71.208503,
                          46.864427,
                          0
                      ],
                      [
                          -71.20902,
                          46.865455,
                          0
                      ],
                      [
                          -71.211591,
                          46.863481,
                          0
                      ],
                      [
                          -71.212796,
                          46.862019,
                          0
                      ],
                      [
                          -71.216772,
                          46.866055,
                          0
                      ],
                      [
                          -71.216897,
                          46.867305,
                          0
                      ],
                      [
                          -71.218125,
                          46.867814,
                          0
                      ],
                      [
                          -71.219257,
                          46.868735,
                          0
                      ],
                      [
                          -71.221213,
                          46.870832,
                          0
                      ],
                      [
                          -71.221626,
                          46.871459,
                          0
                      ],
                      [
                          -71.22172,
                          46.871827,
                          0
                      ],
                      [
                          -71.221928,
                          46.87174,
                          0
                      ],
                      [
                          -71.223163,
                          46.873075,
                          0
                      ],
                      [
                          -71.222311,
                          46.87346,
                          0
                      ],
                      [
                          -71.26321,
                          46.917845,
                          0
                      ],
                      [
                          -71.285141,
                          46.940277,
                          0
                      ],
                      [
                          -71.222929,
                          46.969449,
                          0
                      ],
                      [
                          -71.270698,
                          47.018438,
                          0
                      ],
                      [
                          -71.270721,
                          47.018462,
                          0
                      ],
                      [
                          -71.207756,
                          47.048449,
                          0
                      ],
                      [
                          -71.216256,
                          47.057167,
                          0
                      ],
                      [
                          -71.216258,
                          47.057168,
                          0
                      ],
                      [
                          -71.183692,
                          47.072961,
                          0
                      ],
                      [
                          -71.183693,
                          47.072964,
                          0
                      ],
                      [
                          -71.187108,
                          47.077984,
                          0
                      ],
                      [
                          -71.189524,
                          47.079336,
                          0
                      ],
                      [
                          -71.192034,
                          47.083055,
                          0
                      ],
                      [
                          -71.191404,
                          47.085408,
                          0
                      ],
                      [
                          -71.19279,
                          47.087314,
                          0
                      ],
                      [
                          -71.172849,
                          47.087918,
                          0
                      ],
                      [
                          -71.14708,
                          47.103856,
                          0
                      ],
                      [
                          -71.147055,
                          47.103872,
                          0
                      ],
                      [
                          -71.085683,
                          47.050217,
                          0
                      ],
                      [
                          -71.094463,
                          47.043715,
                          0
                      ],
                      [
                          -71.112759,
                          47.024122,
                          0
                      ],
                      [
                          -71.115082,
                          47.017629,
                          0
                      ],
                      [
                          -71.129124,
                          47.004762,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Montmorency",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.622767,
                          45.523352,
                          0
                      ],
                      [
                          -73.622145,
                          45.524025,
                          0
                      ],
                      [
                          -73.620775,
                          45.523652,
                          0
                      ],
                      [
                          -73.617294,
                          45.527784,
                          0
                      ],
                      [
                          -73.616531,
                          45.527531,
                          0
                      ],
                      [
                          -73.61232,
                          45.525679,
                          0
                      ],
                      [
                          -73.590264,
                          45.515779,
                          0
                      ],
                      [
                          -73.590478,
                          45.515707,
                          0
                      ],
                      [
                          -73.590872,
                          45.515294,
                          0
                      ],
                      [
                          -73.590646,
                          45.514649,
                          0
                      ],
                      [
                          -73.589131,
                          45.513642,
                          0
                      ],
                      [
                          -73.589085,
                          45.513255,
                          0
                      ],
                      [
                          -73.589501,
                          45.512944,
                          0
                      ],
                      [
                          -73.592616,
                          45.513435,
                          0
                      ],
                      [
                          -73.594232,
                          45.513357,
                          0
                      ],
                      [
                          -73.596232,
                          45.512549,
                          0
                      ],
                      [
                          -73.596719,
                          45.512173,
                          0
                      ],
                      [
                          -73.596539,
                          45.511602,
                          0
                      ],
                      [
                          -73.595738,
                          45.511295,
                          0
                      ],
                      [
                          -73.593564,
                          45.510763,
                          0
                      ],
                      [
                          -73.591907,
                          45.509916,
                          0
                      ],
                      [
                          -73.591226,
                          45.509489,
                          0
                      ],
                      [
                          -73.591144,
                          45.508249,
                          0
                      ],
                      [
                          -73.591852,
                          45.507335,
                          0
                      ],
                      [
                          -73.593397,
                          45.505357,
                          0
                      ],
                      [
                          -73.593774,
                          45.503706,
                          0
                      ],
                      [
                          -73.594764,
                          45.502816,
                          0
                      ],
                      [
                          -73.597003,
                          45.501987,
                          0
                      ],
                      [
                          -73.598729,
                          45.500985,
                          0
                      ],
                      [
                          -73.602045,
                          45.498103,
                          0
                      ],
                      [
                          -73.604162,
                          45.496034,
                          0
                      ],
                      [
                          -73.60495,
                          45.495577,
                          0
                      ],
                      [
                          -73.605133,
                          45.495259,
                          0
                      ],
                      [
                          -73.606149,
                          45.495322,
                          0
                      ],
                      [
                          -73.60663,
                          45.495431,
                          0
                      ],
                      [
                          -73.608386,
                          45.493666,
                          0
                      ],
                      [
                          -73.609399,
                          45.494054,
                          0
                      ],
                      [
                          -73.611606,
                          45.491714,
                          0
                      ],
                      [
                          -73.614757,
                          45.49303,
                          0
                      ],
                      [
                          -73.618006,
                          45.489419,
                          0
                      ],
                      [
                          -73.618927,
                          45.489786,
                          0
                      ],
                      [
                          -73.619199,
                          45.489787,
                          0
                      ],
                      [
                          -73.622386,
                          45.491269,
                          0
                      ],
                      [
                          -73.624788,
                          45.488626,
                          0
                      ],
                      [
                          -73.629956,
                          45.490948,
                          0
                      ],
                      [
                          -73.629955,
                          45.49095,
                          0
                      ],
                      [
                          -73.624305,
                          45.497234,
                          0
                      ],
                      [
                          -73.640191,
                          45.50443,
                          0
                      ],
                      [
                          -73.652483,
                          45.49052,
                          0
                      ],
                      [
                          -73.655792,
                          45.486876,
                          0
                      ],
                      [
                          -73.655457,
                          45.486726,
                          0
                      ],
                      [
                          -73.655527,
                          45.486566,
                          0
                      ],
                      [
                          -73.655994,
                          45.484425,
                          0
                      ],
                      [
                          -73.655963,
                          45.478983,
                          0
                      ],
                      [
                          -73.66097,
                          45.481152,
                          0
                      ],
                      [
                          -73.66185,
                          45.480258,
                          0
                      ],
                      [
                          -73.669757,
                          45.483714,
                          0
                      ],
                      [
                          -73.674636,
                          45.481961,
                          0
                      ],
                      [
                          -73.677211,
                          45.483689,
                          0
                      ],
                      [
                          -73.677211,
                          45.483689,
                          0
                      ],
                      [
                          -73.685836,
                          45.489457,
                          0
                      ],
                      [
                          -73.682323,
                          45.490718,
                          0
                      ],
                      [
                          -73.681211,
                          45.491246,
                          0
                      ],
                      [
                          -73.667909,
                          45.501743,
                          0
                      ],
                      [
                          -73.6654,
                          45.503852,
                          0
                      ],
                      [
                          -73.664113,
                          45.505092,
                          0
                      ],
                      [
                          -73.662468,
                          45.507752,
                          0
                      ],
                      [
                          -73.65447,
                          45.520121,
                          0
                      ],
                      [
                          -73.64831,
                          45.530755,
                          0
                      ],
                      [
                          -73.622767,
                          45.523352,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Mont-Royal-Outremont",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.88216,
                          45.521991,
                          0
                      ],
                      [
                          -73.878328,
                          45.520606,
                          0
                      ],
                      [
                          -73.863215,
                          45.519758,
                          0
                      ],
                      [
                          -73.862267,
                          45.517993,
                          0
                      ],
                      [
                          -73.856581,
                          45.515069,
                          0
                      ],
                      [
                          -73.854515,
                          45.512183,
                          0
                      ],
                      [
                          -73.852536,
                          45.511441,
                          0
                      ],
                      [
                          -73.849826,
                          45.513668,
                          0
                      ],
                      [
                          -73.846382,
                          45.517555,
                          0
                      ],
                      [
                          -73.846376,
                          45.517561,
                          0
                      ],
                      [
                          -73.844056,
                          45.518591,
                          0
                      ],
                      [
                          -73.828501,
                          45.518505,
                          0
                      ],
                      [
                          -73.823895,
                          45.511441,
                          0
                      ],
                      [
                          -73.819893,
                          45.506763,
                          0
                      ],
                      [
                          -73.82426,
                          45.504965,
                          0
                      ],
                      [
                          -73.835832,
                          45.50163,
                          0
                      ],
                      [
                          -73.841107,
                          45.499274,
                          0
                      ],
                      [
                          -73.851884,
                          45.491859,
                          0
                      ],
                      [
                          -73.854931,
                          45.489752,
                          0
                      ],
                      [
                          -73.857768,
                          45.485392,
                          0
                      ],
                      [
                          -73.862236,
                          45.482128,
                          0
                      ],
                      [
                          -73.857148,
                          45.474268,
                          0
                      ],
                      [
                          -73.856835,
                          45.473944,
                          0
                      ],
                      [
                          -73.857413,
                          45.473665,
                          0
                      ],
                      [
                          -73.856948,
                          45.472938,
                          0
                      ],
                      [
                          -73.854562,
                          45.470598,
                          0
                      ],
                      [
                          -73.846552,
                          45.462571,
                          0
                      ],
                      [
                          -73.833858,
                          45.449728,
                          0
                      ],
                      [
                          -73.836156,
                          45.449748,
                          0
                      ],
                      [
                          -73.832132,
                          45.445378,
                          0
                      ],
                      [
                          -73.852035,
                          45.44553,
                          0
                      ],
                      [
                          -73.864852,
                          45.439047,
                          0
                      ],
                      [
                          -73.868443,
                          45.442816,
                          0
                      ],
                      [
                          -73.868514,
                          45.442867,
                          0
                      ],
                      [
                          -73.871585,
                          45.441344,
                          0
                      ],
                      [
                          -73.872776,
                          45.442541,
                          0
                      ],
                      [
                          -73.872799,
                          45.442564,
                          0
                      ],
                      [
                          -73.875245,
                          45.441758,
                          0
                      ],
                      [
                          -73.876862,
                          45.441453,
                          0
                      ],
                      [
                          -73.875957,
                          45.438676,
                          0
                      ],
                      [
                          -73.876627,
                          45.438401,
                          0
                      ],
                      [
                          -73.87713,
                          45.437986,
                          0
                      ],
                      [
                          -73.878215,
                          45.438228,
                          0
                      ],
                      [
                          -73.878689,
                          45.43816,
                          0
                      ],
                      [
                          -73.879337,
                          45.43847,
                          0
                      ],
                      [
                          -73.879825,
                          45.439764,
                          0
                      ],
                      [
                          -73.881899,
                          45.438841,
                          0
                      ],
                      [
                          -73.881136,
                          45.436692,
                          0
                      ],
                      [
                          -73.88324,
                          45.436141,
                          0
                      ],
                      [
                          -73.883703,
                          45.437419,
                          0
                      ],
                      [
                          -73.885975,
                          45.437331,
                          0
                      ],
                      [
                          -73.885866,
                          45.437017,
                          0
                      ],
                      [
                          -73.893519,
                          45.435394,
                          0
                      ],
                      [
                          -73.893922,
                          45.436595,
                          0
                      ],
                      [
                          -73.900109,
                          45.435181,
                          0
                      ],
                      [
                          -73.900226,
                          45.435623,
                          0
                      ],
                      [
                          -73.901322,
                          45.435304,
                          0
                      ],
                      [
                          -73.902883,
                          45.440096,
                          0
                      ],
                      [
                          -73.903231,
                          45.440406,
                          0
                      ],
                      [
                          -73.904413,
                          45.440496,
                          0
                      ],
                      [
                          -73.905569,
                          45.444834,
                          0
                      ],
                      [
                          -73.904148,
                          45.445024,
                          0
                      ],
                      [
                          -73.90429,
                          45.4468,
                          0
                      ],
                      [
                          -73.905565,
                          45.44843,
                          0
                      ],
                      [
                          -73.923027,
                          45.442041,
                          0
                      ],
                      [
                          -73.922793,
                          45.441637,
                          0
                      ],
                      [
                          -73.922807,
                          45.441634,
                          0
                      ],
                      [
                          -73.925204,
                          45.440835,
                          0
                      ],
                      [
                          -73.937024,
                          45.450965,
                          0
                      ],
                      [
                          -73.974348,
                          45.46643,
                          0
                      ],
                      [
                          -73.974353,
                          45.466428,
                          0
                      ],
                      [
                          -73.94195,
                          45.499646,
                          0
                      ],
                      [
                          -73.912029,
                          45.519771,
                          0
                      ],
                      [
                          -73.899878,
                          45.524457,
                          0
                      ],
                      [
                          -73.896139,
                          45.525546,
                          0
                      ],
                      [
                          -73.89151,
                          45.522446,
                          0
                      ],
                      [
                          -73.88216,
                          45.521991,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Nelligan",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.612378,
                          45.482607,
                          0
                      ],
                      [
                          -73.614341,
                          45.480671,
                          0
                      ],
                      [
                          -73.610457,
                          45.478871,
                          0
                      ],
                      [
                          -73.610459,
                          45.47887,
                          0
                      ],
                      [
                          -73.610466,
                          45.478866,
                          0
                      ],
                      [
                          -73.605404,
                          45.476573,
                          0
                      ],
                      [
                          -73.604998,
                          45.477017,
                          0
                      ],
                      [
                          -73.598474,
                          45.474103,
                          0
                      ],
                      [
                          -73.595163,
                          45.476427,
                          0
                      ],
                      [
                          -73.596543,
                          45.473243,
                          0
                      ],
                      [
                          -73.597521,
                          45.47275,
                          0
                      ],
                      [
                          -73.598467,
                          45.47221,
                          0
                      ],
                      [
                          -73.599835,
                          45.47138,
                          0
                      ],
                      [
                          -73.602153,
                          45.469378,
                          0
                      ],
                      [
                          -73.602154,
                          45.469378,
                          0
                      ],
                      [
                          -73.603526,
                          45.469316,
                          0
                      ],
                      [
                          -73.604089,
                          45.469036,
                          0
                      ],
                      [
                          -73.604535,
                          45.46892,
                          0
                      ],
                      [
                          -73.604575,
                          45.468821,
                          0
                      ],
                      [
                          -73.604817,
                          45.468505,
                          0
                      ],
                      [
                          -73.605104,
                          45.468323,
                          0
                      ],
                      [
                          -73.605187,
                          45.468207,
                          0
                      ],
                      [
                          -73.605442,
                          45.468028,
                          0
                      ],
                      [
                          -73.606017,
                          45.467721,
                          0
                      ],
                      [
                          -73.606208,
                          45.467596,
                          0
                      ],
                      [
                          -73.606502,
                          45.467433,
                          0
                      ],
                      [
                          -73.606911,
                          45.467135,
                          0
                      ],
                      [
                          -73.607793,
                          45.466873,
                          0
                      ],
                      [
                          -73.608058,
                          45.466847,
                          0
                      ],
                      [
                          -73.608061,
                          45.466846,
                          0
                      ],
                      [
                          -73.608573,
                          45.466495,
                          0
                      ],
                      [
                          -73.609161,
                          45.46618,
                          0
                      ],
                      [
                          -73.609607,
                          45.465927,
                          0
                      ],
                      [
                          -73.610412,
                          45.465549,
                          0
                      ],
                      [
                          -73.610987,
                          45.465242,
                          0
                      ],
                      [
                          -73.611498,
                          45.465026,
                          0
                      ],
                      [
                          -73.611984,
                          45.464791,
                          0
                      ],
                      [
                          -73.612802,
                          45.464611,
                          0
                      ],
                      [
                          -73.61309,
                          45.464433,
                          0
                      ],
                      [
                          -73.613095,
                          45.46443,
                          0
                      ],
                      [
                          -73.613785,
                          45.464277,
                          0
                      ],
                      [
                          -73.614454,
                          45.463984,
                          0
                      ],
                      [
                          -73.615089,
                          45.463547,
                          0
                      ],
                      [
                          -73.615497,
                          45.463375,
                          0
                      ],
                      [
                          -73.616443,
                          45.463203,
                          0
                      ],
                      [
                          -73.616955,
                          45.462987,
                          0
                      ],
                      [
                          -73.617656,
                          45.462699,
                          0
                      ],
                      [
                          -73.617657,
                          45.462698,
                          0
                      ],
                      [
                          -73.618282,
                          45.462247,
                          0
                      ],
                      [
                          -73.618991,
                          45.461979,
                          0
                      ],
                      [
                          -73.619497,
                          45.461877,
                          0
                      ],
                      [
                          -73.620047,
                          45.462129,
                          0
                      ],
                      [
                          -73.620636,
                          45.462137,
                          0
                      ],
                      [
                          -73.62107,
                          45.462272,
                          0
                      ],
                      [
                          -73.62107,
                          45.46227,
                          0
                      ],
                      [
                          -73.621058,
                          45.462208,
                          0
                      ],
                      [
                          -73.620981,
                          45.462155,
                          0
                      ],
                      [
                          -73.62075,
                          45.462028,
                          0
                      ],
                      [
                          -73.620749,
                          45.462028,
                          0
                      ],
                      [
                          -73.620434,
                          45.461871,
                          0
                      ],
                      [
                          -73.620379,
                          45.461787,
                          0
                      ],
                      [
                          -73.620405,
                          45.461732,
                          0
                      ],
                      [
                          -73.620558,
                          45.461651,
                          0
                      ],
                      [
                          -73.621018,
                          45.461426,
                          0
                      ],
                      [
                          -73.621669,
                          45.460984,
                          0
                      ],
                      [
                          -73.622206,
                          45.460713,
                          0
                      ],
                      [
                          -73.622705,
                          45.460329,
                          0
                      ],
                      [
                          -73.622971,
                          45.460227,
                          0
                      ],
                      [
                          -73.623252,
                          45.459956,
                          0
                      ],
                      [
                          -73.623711,
                          45.459542,
                          0
                      ],
                      [
                          -73.623883,
                          45.459462,
                          0
                      ],
                      [
                          -73.623865,
                          45.459308,
                          0
                      ],
                      [
                          -73.623865,
                          45.459308,
                          0
                      ],
                      [
                          -73.62403,
                          45.459227,
                          0
                      ],
                      [
                          -73.624646,
                          45.458919,
                          0
                      ],
                      [
                          -73.625244,
                          45.458775,
                          0
                      ],
                      [
                          -73.625793,
                          45.458415,
                          0
                      ],
                      [
                          -73.626559,
                          45.457829,
                          0
                      ],
                      [
                          -73.62675,
                          45.457397,
                          0
                      ],
                      [
                          -73.627553,
                          45.456631,
                          0
                      ],
                      [
                          -73.628754,
                          45.455829,
                          0
                      ],
                      [
                          -73.629239,
                          45.455648,
                          0
                      ],
                      [
                          -73.629482,
                          45.455297,
                          0
                      ],
                      [
                          -73.630438,
                          45.454522,
                          0
                      ],
                      [
                          -73.631174,
                          45.4537,
                          0
                      ],
                      [
                          -73.631356,
                          45.453495,
                          0
                      ],
                      [
                          -73.631484,
                          45.453423,
                          0
                      ],
                      [
                          -73.631497,
                          45.453351,
                          0
                      ],
                      [
                          -73.63133,
                          45.453118,
                          0
                      ],
                      [
                          -73.63147,
                          45.452947,
                          0
                      ],
                      [
                          -73.631993,
                          45.452685,
                          0
                      ],
                      [
                          -73.63275,
                          45.45234,
                          0
                      ],
                      [
                          -73.635231,
                          45.450751,
                          0
                      ],
                      [
                          -73.631966,
                          45.449508,
                          0
                      ],
                      [
                          -73.635826,
                          45.448146,
                          0
                      ],
                      [
                          -73.636861,
                          45.44773,
                          0
                      ],
                      [
                          -73.638807,
                          45.446692,
                          0
                      ],
                      [
                          -73.643142,
                          45.448312,
                          0
                      ],
                      [
                          -73.643134,
                          45.447899,
                          0
                      ],
                      [
                          -73.644813,
                          45.447597,
                          0
                      ],
                      [
                          -73.668167,
                          45.45655,
                          0
                      ],
                      [
                          -73.667316,
                          45.457566,
                          0
                      ],
                      [
                          -73.659556,
                          45.454576,
                          0
                      ],
                      [
                          -73.657991,
                          45.455941,
                          0
                      ],
                      [
                          -73.661324,
                          45.457222,
                          0
                      ],
                      [
                          -73.659696,
                          45.458314,
                          0
                      ],
                      [
                          -73.659577,
                          45.458473,
                          0
                      ],
                      [
                          -73.657963,
                          45.45953,
                          0
                      ],
                      [
                          -73.658442,
                          45.459711,
                          0
                      ],
                      [
                          -73.658305,
                          45.459826,
                          0
                      ],
                      [
                          -73.657837,
                          45.459685,
                          0
                      ],
                      [
                          -73.657603,
                          45.459694,
                          0
                      ],
                      [
                          -73.657567,
                          45.459906,
                          0
                      ],
                      [
                          -73.657531,
                          45.459954,
                          0
                      ],
                      [
                          -73.657223,
                          45.460105,
                          0
                      ],
                      [
                          -73.657293,
                          45.460216,
                          0
                      ],
                      [
                          -73.657292,
                          45.460469,
                          0
                      ],
                      [
                          -73.657449,
                          45.460502,
                          0
                      ],
                      [
                          -73.657729,
                          45.460671,
                          0
                      ],
                      [
                          -73.657752,
                          45.460805,
                          0
                      ],
                      [
                          -73.657587,
                          45.461005,
                          0
                      ],
                      [
                          -73.657713,
                          45.46125,
                          0
                      ],
                      [
                          -73.658272,
                          45.461455,
                          0
                      ],
                      [
                          -73.657402,
                          45.461973,
                          0
                      ],
                      [
                          -73.657471,
                          45.461999,
                          0
                      ],
                      [
                          -73.655759,
                          45.462955,
                          0
                      ],
                      [
                          -73.653092,
                          45.466475,
                          0
                      ],
                      [
                          -73.652214,
                          45.467626,
                          0
                      ],
                      [
                          -73.65125,
                          45.468167,
                          0
                      ],
                      [
                          -73.650636,
                          45.468539,
                          0
                      ],
                      [
                          -73.650348,
                          45.468732,
                          0
                      ],
                      [
                          -73.649834,
                          45.469681,
                          0
                      ],
                      [
                          -73.649453,
                          45.470382,
                          0
                      ],
                      [
                          -73.649334,
                          45.470335,
                          0
                      ],
                      [
                          -73.649022,
                          45.470583,
                          0
                      ],
                      [
                          -73.647874,
                          45.471503,
                          0
                      ],
                      [
                          -73.646947,
                          45.472241,
                          0
                      ],
                      [
                          -73.645888,
                          45.473091,
                          0
                      ],
                      [
                          -73.645922,
                          45.473277,
                          0
                      ],
                      [
                          -73.64462,
                          45.473838,
                          0
                      ],
                      [
                          -73.644391,
                          45.473938,
                          0
                      ],
                      [
                          -73.64446,
                          45.473967,
                          0
                      ],
                      [
                          -73.643889,
                          45.47422,
                          0
                      ],
                      [
                          -73.643119,
                          45.474603,
                          0
                      ],
                      [
                          -73.640377,
                          45.476334,
                          0
                      ],
                      [
                          -73.639734,
                          45.476747,
                          0
                      ],
                      [
                          -73.639323,
                          45.476863,
                          0
                      ],
                      [
                          -73.635669,
                          45.476972,
                          0
                      ],
                      [
                          -73.634965,
                          45.477105,
                          0
                      ],
                      [
                          -73.632559,
                          45.477883,
                          0
                      ],
                      [
                          -73.629924,
                          45.478365,
                          0
                      ],
                      [
                          -73.629669,
                          45.478309,
                          0
                      ],
                      [
                          -73.62614,
                          45.478948,
                          0
                      ],
                      [
                          -73.624781,
                          45.47921,
                          0
                      ],
                      [
                          -73.624212,
                          45.479405,
                          0
                      ],
                      [
                          -73.623517,
                          45.479846,
                          0
                      ],
                      [
                          -73.622,
                          45.481526,
                          0
                      ],
                      [
                          -73.619837,
                          45.483065,
                          0
                      ],
                      [
                          -73.619218,
                          45.483374,
                          0
                      ],
                      [
                          -73.618088,
                          45.483528,
                          0
                      ],
                      [
                          -73.61689,
                          45.483406,
                          0
                      ],
                      [
                          -73.615722,
                          45.483552,
                          0
                      ],
                      [
                          -73.615151,
                          45.483858,
                          0
                      ],
                      [
                          -73.612378,
                          45.482607,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Notre-Dame-de-Grâce",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -72.363212,
                          45.450482,
                          0
                      ],
                      [
                          -72.306107,
                          45.45116,
                          0
                      ],
                      [
                          -72.218759,
                          45.449001,
                          0
                      ],
                      [
                          -72.178903,
                          45.445417,
                          0
                      ],
                      [
                          -72.182304,
                          45.430517,
                          0
                      ],
                      [
                          -72.147682,
                          45.428339,
                          0
                      ],
                      [
                          -72.143037,
                          45.416678,
                          0
                      ],
                      [
                          -72.141566,
                          45.41148,
                          0
                      ],
                      [
                          -72.14556,
                          45.382209,
                          0
                      ],
                      [
                          -72.145529,
                          45.382207,
                          0
                      ],
                      [
                          -72.125854,
                          45.381127,
                          0
                      ],
                      [
                          -72.125556,
                          45.386558,
                          0
                      ],
                      [
                          -72.125491,
                          45.386552,
                          0
                      ],
                      [
                          -72.105449,
                          45.38479,
                          0
                      ],
                      [
                          -72.1053,
                          45.384778,
                          0
                      ],
                      [
                          -72.107778,
                          45.324029,
                          0
                      ],
                      [
                          -72.108747,
                          45.301517,
                          0
                      ],
                      [
                          -72.108748,
                          45.301468,
                          0
                      ],
                      [
                          -72.042551,
                          45.299889,
                          0
                      ],
                      [
                          -72.037476,
                          45.308502,
                          0
                      ],
                      [
                          -71.95345,
                          45.307894,
                          0
                      ],
                      [
                          -71.953448,
                          45.307891,
                          0
                      ],
                      [
                          -71.953445,
                          45.307884,
                          0
                      ],
                      [
                          -71.953437,
                          45.307867,
                          0
                      ],
                      [
                          -71.953435,
                          45.307863,
                          0
                      ],
                      [
                          -71.948728,
                          45.307775,
                          0
                      ],
                      [
                          -71.949743,
                          45.333696,
                          0
                      ],
                      [
                          -71.949743,
                          45.333696,
                          0
                      ],
                      [
                          -71.9416,
                          45.334105,
                          0
                      ],
                      [
                          -71.941593,
                          45.334105,
                          0
                      ],
                      [
                          -71.942978,
                          45.335428,
                          0
                      ],
                      [
                          -71.943086,
                          45.337173,
                          0
                      ],
                      [
                          -71.941967,
                          45.340831,
                          0
                      ],
                      [
                          -71.941067,
                          45.342864,
                          0
                      ],
                      [
                          -71.94048,
                          45.344063,
                          0
                      ],
                      [
                          -71.939343,
                          45.346084,
                          0
                      ],
                      [
                          -71.937935,
                          45.348378,
                          0
                      ],
                      [
                          -71.937066,
                          45.349865,
                          0
                      ],
                      [
                          -71.933128,
                          45.355646,
                          0
                      ],
                      [
                          -71.929547,
                          45.360461,
                          0
                      ],
                      [
                          -71.923214,
                          45.360405,
                          0
                      ],
                      [
                          -71.923243,
                          45.361034,
                          0
                      ],
                      [
                          -71.923238,
                          45.361034,
                          0
                      ],
                      [
                          -71.922167,
                          45.361063,
                          0
                      ],
                      [
                          -71.922165,
                          45.361063,
                          0
                      ],
                      [
                          -71.922104,
                          45.36041,
                          0
                      ],
                      [
                          -71.922103,
                          45.360409,
                          0
                      ],
                      [
                          -71.91419,
                          45.3604,
                          0
                      ],
                      [
                          -71.914053,
                          45.360395,
                          0
                      ],
                      [
                          -71.91405,
                          45.360395,
                          0
                      ],
                      [
                          -71.914106,
                          45.361728,
                          0
                      ],
                      [
                          -71.914107,
                          45.361729,
                          0
                      ],
                      [
                          -71.910248,
                          45.361702,
                          0
                      ],
                      [
                          -71.904802,
                          45.36254,
                          0
                      ],
                      [
                          -71.904802,
                          45.362539,
                          0
                      ],
                      [
                          -71.904755,
                          45.360395,
                          0
                      ],
                      [
                          -71.894863,
                          45.360358,
                          0
                      ],
                      [
                          -71.876177,
                          45.359496,
                          0
                      ],
                      [
                          -71.875714,
                          45.350719,
                          0
                      ],
                      [
                          -71.862859,
                          45.35046,
                          0
                      ],
                      [
                          -71.869886,
                          45.346352,
                          0
                      ],
                      [
                          -71.868142,
                          45.344509,
                          0
                      ],
                      [
                          -71.867674,
                          45.343727,
                          0
                      ],
                      [
                          -71.867666,
                          45.343361,
                          0
                      ],
                      [
                          -71.868275,
                          45.342864,
                          0
                      ],
                      [
                          -71.870724,
                          45.340978,
                          0
                      ],
                      [
                          -71.871777,
                          45.337534,
                          0
                      ],
                      [
                          -71.87256,
                          45.334703,
                          0
                      ],
                      [
                          -71.873821,
                          45.333872,
                          0
                      ],
                      [
                          -71.876503,
                          45.332135,
                          0
                      ],
                      [
                          -71.881605,
                          45.333243,
                          0
                      ],
                      [
                          -71.883752,
                          45.331911,
                          0
                      ],
                      [
                          -71.883441,
                          45.330308,
                          0
                      ],
                      [
                          -71.883982,
                          45.329343,
                          0
                      ],
                      [
                          -71.883985,
                          45.329342,
                          0
                      ],
                      [
                          -71.884921,
                          45.328841,
                          0
                      ],
                      [
                          -71.884948,
                          45.327603,
                          0
                      ],
                      [
                          -71.884423,
                          45.325872,
                          0
                      ],
                      [
                          -71.885346,
                          45.324164,
                          0
                      ],
                      [
                          -71.887393,
                          45.323159,
                          0
                      ],
                      [
                          -71.88885,
                          45.323245,
                          0
                      ],
                      [
                          -71.890826,
                          45.322972,
                          0
                      ],
                      [
                          -71.891807,
                          45.32219,
                          0
                      ],
                      [
                          -71.894522,
                          45.318805,
                          0
                      ],
                      [
                          -71.896105,
                          45.317274,
                          0
                      ],
                      [
                          -71.899131,
                          45.313571,
                          0
                      ],
                      [
                          -71.900197,
                          45.31232,
                          0
                      ],
                      [
                          -71.898793,
                          45.311193,
                          0
                      ],
                      [
                          -71.898647,
                          45.310647,
                          0
                      ],
                      [
                          -71.899871,
                          45.309942,
                          0
                      ],
                      [
                          -71.901358,
                          45.310879,
                          0
                      ],
                      [
                          -71.902517,
                          45.3112,
                          0
                      ],
                      [
                          -71.903123,
                          45.311055,
                          0
                      ],
                      [
                          -71.904301,
                          45.31008,
                          0
                      ],
                      [
                          -71.904378,
                          45.308639,
                          0
                      ],
                      [
                          -71.905919,
                          45.307498,
                          0
                      ],
                      [
                          -71.909554,
                          45.307542,
                          0
                      ],
                      [
                          -71.91225,
                          45.304195,
                          0
                      ],
                      [
                          -71.913012,
                          45.303072,
                          0
                      ],
                      [
                          -71.914341,
                          45.303632,
                          0
                      ],
                      [
                          -71.915897,
                          45.303777,
                          0
                      ],
                      [
                          -71.917955,
                          45.304979,
                          0
                      ],
                      [
                          -71.918794,
                          45.305498,
                          0
                      ],
                      [
                          -71.919968,
                          45.305695,
                          0
                      ],
                      [
                          -71.919949,
                          45.304597,
                          0
                      ],
                      [
                          -71.919525,
                          45.302604,
                          0
                      ],
                      [
                          -71.920876,
                          45.300011,
                          0
                      ],
                      [
                          -71.917105,
                          45.160103,
                          0
                      ],
                      [
                          -71.917164,
                          45.160105,
                          0
                      ],
                      [
                          -71.917244,
                          45.160109,
                          0
                      ],
                      [
                          -72.098311,
                          45.160909,
                          0
                      ],
                      [
                          -72.09831,
                          45.160861,
                          0
                      ],
                      [
                          -72.095928,
                          45.033448,
                          0
                      ],
                      [
                          -72.095927,
                          45.033425,
                          0
                      ],
                      [
                          -72.077514,
                          45.032619,
                          0
                      ],
                      [
                          -72.077189,
                          45.021723,
                          0
                      ],
                      [
                          -72.040637,
                          45.022766,
                          0
                      ],
                      [
                          -72.040373,
                          45.006501,
                          0
                      ],
                      [
                          -72.040373,
                          45.006501,
                          0
                      ],
                      [
                          -72.040372,
                          45.006473,
                          0
                      ],
                      [
                          -72.040372,
                          45.006473,
                          0
                      ],
                      [
                          -72.040372,
                          45.006473,
                          0
                      ],
                      [
                          -72.154011,
                          45.005992,
                          0
                      ],
                      [
                          -72.314235,
                          45.003845,
                          0
                      ],
                      [
                          -72.394092,
                          45.006485,
                          0
                      ],
                      [
                          -72.484689,
                          45.008734,
                          0
                      ],
                      [
                          -72.485073,
                          45.008725,
                          0
                      ],
                      [
                          -72.485073,
                          45.008727,
                          0
                      ],
                      [
                          -72.485071,
                          45.008774,
                          0
                      ],
                      [
                          -72.479949,
                          45.157642,
                          0
                      ],
                      [
                          -72.479638,
                          45.157659,
                          0
                      ],
                      [
                          -72.479638,
                          45.157659,
                          0
                      ],
                      [
                          -72.479425,
                          45.15767,
                          0
                      ],
                      [
                          -72.403398,
                          45.155721,
                          0
                      ],
                      [
                          -72.403398,
                          45.155722,
                          0
                      ],
                      [
                          -72.400213,
                          45.308032,
                          0
                      ],
                      [
                          -72.462,
                          45.307425,
                          0
                      ],
                      [
                          -72.462028,
                          45.307424,
                          0
                      ],
                      [
                          -72.46209,
                          45.307423,
                          0
                      ],
                      [
                          -72.464947,
                          45.331446,
                          0
                      ],
                      [
                          -72.461348,
                          45.38342,
                          0
                      ],
                      [
                          -72.461348,
                          45.383421,
                          0
                      ],
                      [
                          -72.458629,
                          45.422211,
                          0
                      ],
                      [
                          -72.454528,
                          45.451469,
                          0
                      ],
                      [
                          -72.404014,
                          45.449189,
                          0
                      ],
                      [
                          -72.403998,
                          45.449189,
                          0
                      ],
                      [
                          -72.363212,
                          45.450482,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Orford",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.47503,
                          45.701724,
                          0
                      ],
                      [
                          -73.474717,
                          45.700326,
                          0
                      ],
                      [
                          -73.477925,
                          45.698046,
                          0
                      ],
                      [
                          -73.482219,
                          45.690175,
                          0
                      ],
                      [
                          -73.486588,
                          45.67153,
                          0
                      ],
                      [
                          -73.486433,
                          45.665638,
                          0
                      ],
                      [
                          -73.480899,
                          45.65391,
                          0
                      ],
                      [
                          -73.476424,
                          45.647434,
                          0
                      ],
                      [
                          -73.476913,
                          45.643633,
                          0
                      ],
                      [
                          -73.484105,
                          45.631597,
                          0
                      ],
                      [
                          -73.494861,
                          45.618869,
                          0
                      ],
                      [
                          -73.499456,
                          45.609371,
                          0
                      ],
                      [
                          -73.537186,
                          45.615837,
                          0
                      ],
                      [
                          -73.535497,
                          45.619899,
                          0
                      ],
                      [
                          -73.568168,
                          45.634131,
                          0
                      ],
                      [
                          -73.568341,
                          45.634206,
                          0
                      ],
                      [
                          -73.551782,
                          45.644764,
                          0
                      ],
                      [
                          -73.528515,
                          45.656112,
                          0
                      ],
                      [
                          -73.522165,
                          45.659152,
                          0
                      ],
                      [
                          -73.518228,
                          45.660553,
                          0
                      ],
                      [
                          -73.51797,
                          45.663165,
                          0
                      ],
                      [
                          -73.519319,
                          45.672539,
                          0
                      ],
                      [
                          -73.509099,
                          45.695673,
                          0
                      ],
                      [
                          -73.508785,
                          45.702334,
                          0
                      ],
                      [
                          -73.497604,
                          45.704868,
                          0
                      ],
                      [
                          -73.493715,
                          45.705163,
                          0
                      ],
                      [
                          -73.489049,
                          45.702357,
                          0
                      ],
                      [
                          -73.475621,
                          45.707582,
                          0
                      ],
                      [
                          -73.474127,
                          45.704922,
                          0
                      ],
                      [
                          -73.474127,
                          45.704919,
                          0
                      ],
                      [
                          -73.47503,
                          45.701724,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Pointe-aux-Trembles",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -72.074595,
                          47.409636,
                          0
                      ],
                      [
                          -72.032714,
                          47.401813,
                          0
                      ],
                      [
                          -72.012889,
                          47.406218,
                          0
                      ],
                      [
                          -72.007084,
                          47.399749,
                          0
                      ],
                      [
                          -71.990917,
                          47.38987,
                          0
                      ],
                      [
                          -71.969219,
                          47.387512,
                          0
                      ],
                      [
                          -71.916266,
                          47.353192,
                          0
                      ],
                      [
                          -71.828202,
                          47.329278,
                          0
                      ],
                      [
                          -71.7133,
                          47.248618,
                          0
                      ],
                      [
                          -71.631695,
                          47.192537,
                          0
                      ],
                      [
                          -71.813871,
                          47.113959,
                          0
                      ],
                      [
                          -71.633241,
                          46.9873,
                          0
                      ],
                      [
                          -71.72152,
                          46.927406,
                          0
                      ],
                      [
                          -71.708167,
                          46.918999,
                          0
                      ],
                      [
                          -71.732124,
                          46.90301,
                          0
                      ],
                      [
                          -71.703966,
                          46.883288,
                          0
                      ],
                      [
                          -71.712565,
                          46.877842,
                          0
                      ],
                      [
                          -71.707897,
                          46.874525,
                          0
                      ],
                      [
                          -71.712459,
                          46.870444,
                          0
                      ],
                      [
                          -71.705912,
                          46.865909,
                          0
                      ],
                      [
                          -71.709338,
                          46.864452,
                          0
                      ],
                      [
                          -71.712234,
                          46.86129,
                          0
                      ],
                      [
                          -71.708186,
                          46.858369,
                          0
                      ],
                      [
                          -71.714767,
                          46.853992,
                          0
                      ],
                      [
                          -71.637969,
                          46.8012,
                          0
                      ],
                      [
                          -71.595873,
                          46.806603,
                          0
                      ],
                      [
                          -71.580429,
                          46.795129,
                          0
                      ],
                      [
                          -71.569683,
                          46.796478,
                          0
                      ],
                      [
                          -71.552567,
                          46.783976,
                          0
                      ],
                      [
                          -71.606651,
                          46.776156,
                          0
                      ],
                      [
                          -71.506252,
                          46.703228,
                          0
                      ],
                      [
                          -71.519721,
                          46.699467,
                          0
                      ],
                      [
                          -71.614797,
                          46.676262,
                          0
                      ],
                      [
                          -71.672553,
                          46.652894,
                          0
                      ],
                      [
                          -71.783025,
                          46.649956,
                          0
                      ],
                      [
                          -71.836529,
                          46.676508,
                          0
                      ],
                      [
                          -71.881667,
                          46.673953,
                          0
                      ],
                      [
                          -71.944024,
                          46.627818,
                          0
                      ],
                      [
                          -72.013291,
                          46.585569,
                          0
                      ],
                      [
                          -72.05432,
                          46.570509,
                          0
                      ],
                      [
                          -72.083727,
                          46.570207,
                          0
                      ],
                      [
                          -72.393284,
                          46.79195,
                          0
                      ],
                      [
                          -72.345075,
                          46.822429,
                          0
                      ],
                      [
                          -72.334203,
                          46.814437,
                          0
                      ],
                      [
                          -72.305854,
                          46.833785,
                          0
                      ],
                      [
                          -72.298149,
                          46.82882,
                          0
                      ],
                      [
                          -72.272449,
                          46.844936,
                          0
                      ],
                      [
                          -72.248496,
                          46.828395,
                          0
                      ],
                      [
                          -72.23372,
                          46.838375,
                          0
                      ],
                      [
                          -72.228684,
                          46.835173,
                          0
                      ],
                      [
                          -72.167646,
                          46.874268,
                          0
                      ],
                      [
                          -72.438049,
                          47.057588,
                          0
                      ],
                      [
                          -72.378898,
                          47.096495,
                          0
                      ],
                      [
                          -72.394896,
                          47.117765,
                          0
                      ],
                      [
                          -72.416036,
                          47.118548,
                          0
                      ],
                      [
                          -72.459999,
                          47.143071,
                          0
                      ],
                      [
                          -72.51469,
                          47.143983,
                          0
                      ],
                      [
                          -72.540781,
                          47.190653,
                          0
                      ],
                      [
                          -72.53855,
                          47.218851,
                          0
                      ],
                      [
                          -72.510361,
                          47.268066,
                          0
                      ],
                      [
                          -72.503381,
                          47.311928,
                          0
                      ],
                      [
                          -72.470693,
                          47.331599,
                          0
                      ],
                      [
                          -72.485629,
                          47.36782,
                          0
                      ],
                      [
                          -72.459303,
                          47.385763,
                          0
                      ],
                      [
                          -72.45955,
                          47.369847,
                          0
                      ],
                      [
                          -72.412045,
                          47.345324,
                          0
                      ],
                      [
                          -72.410633,
                          47.291042,
                          0
                      ],
                      [
                          -72.355351,
                          47.289738,
                          0
                      ],
                      [
                          -72.340962,
                          47.278098,
                          0
                      ],
                      [
                          -72.317572,
                          47.301429,
                          0
                      ],
                      [
                          -72.284243,
                          47.29015,
                          0
                      ],
                      [
                          -72.23885,
                          47.290066,
                          0
                      ],
                      [
                          -72.215765,
                          47.313856,
                          0
                      ],
                      [
                          -72.20251,
                          47.357699,
                          0
                      ],
                      [
                          -72.184606,
                          47.365851,
                          0
                      ],
                      [
                          -72.161367,
                          47.386482,
                          0
                      ],
                      [
                          -72.14668,
                          47.379405,
                          0
                      ],
                      [
                          -72.145406,
                          47.372236,
                          0
                      ],
                      [
                          -72.127043,
                          47.379217,
                          0
                      ],
                      [
                          -72.107277,
                          47.382088,
                          0
                      ],
                      [
                          -72.097717,
                          47.394572,
                          0
                      ],
                      [
                          -72.074595,
                          47.409636,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Portneuf",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.974013,
                          45.978329,
                          0
                      ],
                      [
                          -73.976808,
                          45.976624,
                          0
                      ],
                      [
                          -73.976808,
                          45.976624,
                          0
                      ],
                      [
                          -73.964729,
                          45.968077,
                          0
                      ],
                      [
                          -73.964722,
                          45.968073,
                          0
                      ],
                      [
                          -73.964698,
                          45.96806,
                          0
                      ],
                      [
                          -73.947146,
                          45.958032,
                          0
                      ],
                      [
                          -73.947144,
                          45.958031,
                          0
                      ],
                      [
                          -73.952519,
                          45.954382,
                          0
                      ],
                      [
                          -73.95252,
                          45.954381,
                          0
                      ],
                      [
                          -73.935502,
                          45.944295,
                          0
                      ],
                      [
                          -73.935414,
                          45.944245,
                          0
                      ],
                      [
                          -73.935414,
                          45.944245,
                          0
                      ],
                      [
                          -73.950367,
                          45.933315,
                          0
                      ],
                      [
                          -73.950547,
                          45.933183,
                          0
                      ],
                      [
                          -73.882587,
                          45.888475,
                          0
                      ],
                      [
                          -73.882593,
                          45.888473,
                          0
                      ],
                      [
                          -73.901327,
                          45.884534,
                          0
                      ],
                      [
                          -73.807631,
                          45.821674,
                          0
                      ],
                      [
                          -73.807612,
                          45.821661,
                          0
                      ],
                      [
                          -73.809492,
                          45.820369,
                          0
                      ],
                      [
                          -73.810045,
                          45.82074,
                          0
                      ],
                      [
                          -73.868965,
                          45.808849,
                          0
                      ],
                      [
                          -73.868921,
                          45.808817,
                          0
                      ],
                      [
                          -73.86018,
                          45.802357,
                          0
                      ],
                      [
                          -73.860249,
                          45.802312,
                          0
                      ],
                      [
                          -73.913575,
                          45.766559,
                          0
                      ],
                      [
                          -73.947455,
                          45.789323,
                          0
                      ],
                      [
                          -73.947658,
                          45.789491,
                          0
                      ],
                      [
                          -74.023242,
                          45.841543,
                          0
                      ],
                      [
                          -74.023248,
                          45.841545,
                          0
                      ],
                      [
                          -74.023257,
                          45.841548,
                          0
                      ],
                      [
                          -74.023265,
                          45.841551,
                          0
                      ],
                      [
                          -74.023089,
                          45.840809,
                          0
                      ],
                      [
                          -74.038268,
                          45.837254,
                          0
                      ],
                      [
                          -74.036356,
                          45.835072,
                          0
                      ],
                      [
                          -74.034341,
                          45.833859,
                          0
                      ],
                      [
                          -74.030746,
                          45.834657,
                          0
                      ],
                      [
                          -74.030518,
                          45.833065,
                          0
                      ],
                      [
                          -74.029115,
                          45.833323,
                          0
                      ],
                      [
                          -74.028521,
                          45.831804,
                          0
                      ],
                      [
                          -74.030462,
                          45.831398,
                          0
                      ],
                      [
                          -74.029725,
                          45.829234,
                          0
                      ],
                      [
                          -74.033842,
                          45.828535,
                          0
                      ],
                      [
                          -74.032968,
                          45.826292,
                          0
                      ],
                      [
                          -74.031914,
                          45.826383,
                          0
                      ],
                      [
                          -74.031526,
                          45.824828,
                          0
                      ],
                      [
                          -74.026249,
                          45.82546,
                          0
                      ],
                      [
                          -74.02571,
                          45.82397,
                          0
                      ],
                      [
                          -74.022129,
                          45.824374,
                          0
                      ],
                      [
                          -74.022123,
                          45.824374,
                          0
                      ],
                      [
                          -74.024177,
                          45.818717,
                          0
                      ],
                      [
                          -74.026617,
                          45.820683,
                          0
                      ],
                      [
                          -74.043118,
                          45.818907,
                          0
                      ],
                      [
                          -74.050025,
                          45.818158,
                          0
                      ],
                      [
                          -74.050032,
                          45.818158,
                          0
                      ],
                      [
                          -74.050036,
                          45.818167,
                          0
                      ],
                      [
                          -74.052283,
                          45.820369,
                          0
                      ],
                      [
                          -74.055732,
                          45.823884,
                          0
                      ],
                      [
                          -74.056203,
                          45.8262,
                          0
                      ],
                      [
                          -74.057092,
                          45.830131,
                          0
                      ],
                      [
                          -74.058855,
                          45.831985,
                          0
                      ],
                      [
                          -74.061775,
                          45.834697,
                          0
                      ],
                      [
                          -74.064739,
                          45.837876,
                          0
                      ],
                      [
                          -74.06448,
                          45.840931,
                          0
                      ],
                      [
                          -74.064462,
                          45.841041,
                          0
                      ],
                      [
                          -74.063755,
                          45.844476,
                          0
                      ],
                      [
                          -74.06376,
                          45.844542,
                          0
                      ],
                      [
                          -74.064798,
                          45.84618,
                          0
                      ],
                      [
                          -74.066233,
                          45.848785,
                          0
                      ],
                      [
                          -74.067678,
                          45.850396,
                          0
                      ],
                      [
                          -74.090722,
                          45.847407,
                          0
                      ],
                      [
                          -74.090722,
                          45.847406,
                          0
                      ],
                      [
                          -74.089252,
                          45.840605,
                          0
                      ],
                      [
                          -74.093744,
                          45.840099,
                          0
                      ],
                      [
                          -74.093505,
                          45.83906,
                          0
                      ],
                      [
                          -74.094547,
                          45.838942,
                          0
                      ],
                      [
                          -74.094174,
                          45.837322,
                          0
                      ],
                      [
                          -74.094169,
                          45.837296,
                          0
                      ],
                      [
                          -74.096353,
                          45.837052,
                          0
                      ],
                      [
                          -74.094392,
                          45.834104,
                          0
                      ],
                      [
                          -74.093328,
                          45.831066,
                          0
                      ],
                      [
                          -74.091287,
                          45.831315,
                          0
                      ],
                      [
                          -74.09076,
                          45.830617,
                          0
                      ],
                      [
                          -74.106151,
                          45.828562,
                          0
                      ],
                      [
                          -74.106182,
                          45.828607,
                          0
                      ],
                      [
                          -74.108541,
                          45.832016,
                          0
                      ],
                      [
                          -74.123503,
                          45.830372,
                          0
                      ],
                      [
                          -74.123812,
                          45.831746,
                          0
                      ],
                      [
                          -74.126088,
                          45.831474,
                          0
                      ],
                      [
                          -74.143336,
                          45.819718,
                          0
                      ],
                      [
                          -74.21914,
                          45.845148,
                          0
                      ],
                      [
                          -74.219843,
                          45.84423,
                          0
                      ],
                      [
                          -74.219859,
                          45.844507,
                          0
                      ],
                      [
                          -74.22035,
                          45.844729,
                          0
                      ],
                      [
                          -74.220295,
                          45.845331,
                          0
                      ],
                      [
                          -74.220281,
                          45.84553,
                          0
                      ],
                      [
                          -74.224418,
                          45.847107,
                          0
                      ],
                      [
                          -74.224374,
                          45.846179,
                          0
                      ],
                      [
                          -74.227171,
                          45.845016,
                          0
                      ],
                      [
                          -74.226681,
                          45.846745,
                          0
                      ],
                      [
                          -74.226446,
                          45.847604,
                          0
                      ],
                      [
                          -74.254867,
                          45.857505,
                          0
                      ],
                      [
                          -74.263312,
                          45.859645,
                          0
                      ],
                      [
                          -74.192079,
                          45.91083,
                          0
                      ],
                      [
                          -74.192133,
                          45.910871,
                          0
                      ],
                      [
                          -74.176355,
                          45.92158,
                          0
                      ],
                      [
                          -74.176328,
                          45.921562,
                          0
                      ],
                      [
                          -74.164868,
                          45.913554,
                          0
                      ],
                      [
                          -74.162217,
                          45.915479,
                          0
                      ],
                      [
                          -74.160598,
                          45.914377,
                          0
                      ],
                      [
                          -74.158471,
                          45.915901,
                          0
                      ],
                      [
                          -74.158447,
                          45.915919,
                          0
                      ],
                      [
                          -74.154248,
                          45.913144,
                          0
                      ],
                      [
                          -74.152107,
                          45.913654,
                          0
                      ],
                      [
                          -74.151667,
                          45.913687,
                          0
                      ],
                      [
                          -74.151269,
                          45.913508,
                          0
                      ],
                      [
                          -74.149753,
                          45.9131,
                          0
                      ],
                      [
                          -74.148888,
                          45.913041,
                          0
                      ],
                      [
                          -74.148066,
                          45.912823,
                          0
                      ],
                      [
                          -74.147737,
                          45.912956,
                          0
                      ],
                      [
                          -74.147727,
                          45.913183,
                          0
                      ],
                      [
                          -74.148406,
                          45.91341,
                          0
                      ],
                      [
                          -74.148312,
                          45.913646,
                          0
                      ],
                      [
                          -74.14792,
                          45.913801,
                          0
                      ],
                      [
                          -74.147731,
                          45.914032,
                          0
                      ],
                      [
                          -74.147736,
                          45.914374,
                          0
                      ],
                      [
                          -74.14724,
                          45.915026,
                          0
                      ],
                      [
                          -74.146597,
                          45.91511,
                          0
                      ],
                      [
                          -74.145004,
                          45.915622,
                          0
                      ],
                      [
                          -74.144612,
                          45.916335,
                          0
                      ],
                      [
                          -74.144502,
                          45.916814,
                          0
                      ],
                      [
                          -74.144545,
                          45.917119,
                          0
                      ],
                      [
                          -74.144237,
                          45.917328,
                          0
                      ],
                      [
                          -74.143467,
                          45.917602,
                          0
                      ],
                      [
                          -74.143046,
                          45.917919,
                          0
                      ],
                      [
                          -74.142483,
                          45.9184,
                          0
                      ],
                      [
                          -74.141869,
                          45.918744,
                          0
                      ],
                      [
                          -74.141432,
                          45.91881,
                          0
                      ],
                      [
                          -74.141083,
                          45.918731,
                          0
                      ],
                      [
                          -74.14083,
                          45.918067,
                          0
                      ],
                      [
                          -74.1401,
                          45.917407,
                          0
                      ],
                      [
                          -74.139212,
                          45.918743,
                          0
                      ],
                      [
                          -74.139191,
                          45.918953,
                          0
                      ],
                      [
                          -74.139324,
                          45.919457,
                          0
                      ],
                      [
                          -74.139844,
                          45.919929,
                          0
                      ],
                      [
                          -74.140748,
                          45.920405,
                          0
                      ],
                      [
                          -74.140807,
                          45.921248,
                          0
                      ],
                      [
                          -74.140647,
                          45.921765,
                          0
                      ],
                      [
                          -74.139757,
                          45.923127,
                          0
                      ],
                      [
                          -74.126785,
                          45.914361,
                          0
                      ],
                      [
                          -74.071211,
                          45.952816,
                          0
                      ],
                      [
                          -74.068212,
                          45.950756,
                          0
                      ],
                      [
                          -74.002335,
                          45.996972,
                          0
                      ],
                      [
                          -73.974013,
                          45.978329,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Prévost",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.31533,
                          45.850036,
                          0
                      ],
                      [
                          -73.305299,
                          45.845726,
                          0
                      ],
                      [
                          -73.286166,
                          45.850983,
                          0
                      ],
                      [
                          -73.27478,
                          45.849924,
                          0
                      ],
                      [
                          -73.274774,
                          45.849923,
                          0
                      ],
                      [
                          -73.274774,
                          45.849921,
                          0
                      ],
                      [
                          -73.287445,
                          45.834439,
                          0
                      ],
                      [
                          -73.306542,
                          45.820215,
                          0
                      ],
                      [
                          -73.325812,
                          45.803673,
                          0
                      ],
                      [
                          -73.338401,
                          45.800185,
                          0
                      ],
                      [
                          -73.348704,
                          45.793081,
                          0
                      ],
                      [
                          -73.356256,
                          45.790715,
                          0
                      ],
                      [
                          -73.370502,
                          45.785579,
                          0
                      ],
                      [
                          -73.379975,
                          45.789443,
                          0
                      ],
                      [
                          -73.38556,
                          45.789243,
                          0
                      ],
                      [
                          -73.4192,
                          45.753842,
                          0
                      ],
                      [
                          -73.421265,
                          45.74984,
                          0
                      ],
                      [
                          -73.422644,
                          45.739391,
                          0
                      ],
                      [
                          -73.446757,
                          45.716219,
                          0
                      ],
                      [
                          -73.45953,
                          45.705767,
                          0
                      ],
                      [
                          -73.474712,
                          45.700327,
                          0
                      ],
                      [
                          -73.474717,
                          45.700326,
                          0
                      ],
                      [
                          -73.47503,
                          45.701724,
                          0
                      ],
                      [
                          -73.474127,
                          45.704919,
                          0
                      ],
                      [
                          -73.474127,
                          45.704922,
                          0
                      ],
                      [
                          -73.475621,
                          45.707582,
                          0
                      ],
                      [
                          -73.489049,
                          45.702357,
                          0
                      ],
                      [
                          -73.493715,
                          45.705163,
                          0
                      ],
                      [
                          -73.497604,
                          45.704868,
                          0
                      ],
                      [
                          -73.497243,
                          45.706833,
                          0
                      ],
                      [
                          -73.491829,
                          45.711175,
                          0
                      ],
                      [
                          -73.487187,
                          45.712528,
                          0
                      ],
                      [
                          -73.480935,
                          45.713438,
                          0
                      ],
                      [
                          -73.481763,
                          45.717115,
                          0
                      ],
                      [
                          -73.481763,
                          45.717122,
                          0
                      ],
                      [
                          -73.481306,
                          45.721576,
                          0
                      ],
                      [
                          -73.481832,
                          45.723757,
                          0
                      ],
                      [
                          -73.482148,
                          45.724866,
                          0
                      ],
                      [
                          -73.483259,
                          45.728349,
                          0
                      ],
                      [
                          -73.482089,
                          45.732098,
                          0
                      ],
                      [
                          -73.481339,
                          45.735521,
                          0
                      ],
                      [
                          -73.481339,
                          45.735521,
                          0
                      ],
                      [
                          -73.48018,
                          45.739188,
                          0
                      ],
                      [
                          -73.478953,
                          45.741788,
                          0
                      ],
                      [
                          -73.477392,
                          45.743662,
                          0
                      ],
                      [
                          -73.472983,
                          45.746185,
                          0
                      ],
                      [
                          -73.467611,
                          45.751244,
                          0
                      ],
                      [
                          -73.462083,
                          45.755599,
                          0
                      ],
                      [
                          -73.457004,
                          45.761498,
                          0
                      ],
                      [
                          -73.451415,
                          45.7685,
                          0
                      ],
                      [
                          -73.449957,
                          45.773885,
                          0
                      ],
                      [
                          -73.4502,
                          45.777972,
                          0
                      ],
                      [
                          -73.450849,
                          45.779875,
                          0
                      ],
                      [
                          -73.449997,
                          45.78296,
                          0
                      ],
                      [
                          -73.448017,
                          45.783866,
                          0
                      ],
                      [
                          -73.444976,
                          45.784412,
                          0
                      ],
                      [
                          -73.441592,
                          45.78585,
                          0
                      ],
                      [
                          -73.438957,
                          45.789111,
                          0
                      ],
                      [
                          -73.424041,
                          45.783074,
                          0
                      ],
                      [
                          -73.422973,
                          45.784305,
                          0
                      ],
                      [
                          -73.424578,
                          45.785038,
                          0
                      ],
                      [
                          -73.423425,
                          45.786362,
                          0
                      ],
                      [
                          -73.425274,
                          45.787167,
                          0
                      ],
                      [
                          -73.425279,
                          45.787169,
                          0
                      ],
                      [
                          -73.42409,
                          45.788502,
                          0
                      ],
                      [
                          -73.424092,
                          45.788503,
                          0
                      ],
                      [
                          -73.426098,
                          45.789331,
                          0
                      ],
                      [
                          -73.424827,
                          45.790793,
                          0
                      ],
                      [
                          -73.425687,
                          45.791163,
                          0
                      ],
                      [
                          -73.424164,
                          45.792318,
                          0
                      ],
                      [
                          -73.422374,
                          45.791619,
                          0
                      ],
                      [
                          -73.422369,
                          45.791617,
                          0
                      ],
                      [
                          -73.42118,
                          45.79294,
                          0
                      ],
                      [
                          -73.421179,
                          45.792943,
                          0
                      ],
                      [
                          -73.423463,
                          45.793905,
                          0
                      ],
                      [
                          -73.421146,
                          45.796671,
                          0
                      ],
                      [
                          -73.417026,
                          45.794947,
                          0
                      ],
                      [
                          -73.414397,
                          45.798017,
                          0
                      ],
                      [
                          -73.409606,
                          45.800856,
                          0
                      ],
                      [
                          -73.405815,
                          45.804638,
                          0
                      ],
                      [
                          -73.40433,
                          45.808854,
                          0
                      ],
                      [
                          -73.402856,
                          45.810169,
                          0
                      ],
                      [
                          -73.408034,
                          45.814181,
                          0
                      ],
                      [
                          -73.407261,
                          45.814868,
                          0
                      ],
                      [
                          -73.409846,
                          45.816868,
                          0
                      ],
                      [
                          -73.4089,
                          45.817591,
                          0
                      ],
                      [
                          -73.408855,
                          45.817559,
                          0
                      ],
                      [
                          -73.401255,
                          45.812163,
                          0
                      ],
                      [
                          -73.401191,
                          45.812214,
                          0
                      ],
                      [
                          -73.399333,
                          45.813697,
                          0
                      ],
                      [
                          -73.399307,
                          45.813718,
                          0
                      ],
                      [
                          -73.399887,
                          45.814133,
                          0
                      ],
                      [
                          -73.396846,
                          45.818663,
                          0
                      ],
                      [
                          -73.396846,
                          45.818663,
                          0
                      ],
                      [
                          -73.395999,
                          45.820018,
                          0
                      ],
                      [
                          -73.398335,
                          45.821781,
                          0
                      ],
                      [
                          -73.397102,
                          45.822656,
                          0
                      ],
                      [
                          -73.397052,
                          45.822758,
                          0
                      ],
                      [
                          -73.395424,
                          45.826099,
                          0
                      ],
                      [
                          -73.394068,
                          45.828881,
                          0
                      ],
                      [
                          -73.394353,
                          45.829097,
                          0
                      ],
                      [
                          -73.391718,
                          45.831241,
                          0
                      ],
                      [
                          -73.392043,
                          45.831501,
                          0
                      ],
                      [
                          -73.389706,
                          45.833869,
                          0
                      ],
                      [
                          -73.389197,
                          45.83438,
                          0
                      ],
                      [
                          -73.390358,
                          45.835209,
                          0
                      ],
                      [
                          -73.388086,
                          45.836972,
                          0
                      ],
                      [
                          -73.387348,
                          45.836733,
                          0
                      ],
                      [
                          -73.386026,
                          45.837629,
                          0
                      ],
                      [
                          -73.39024,
                          45.840714,
                          0
                      ],
                      [
                          -73.389316,
                          45.841391,
                          0
                      ],
                      [
                          -73.394518,
                          45.84522,
                          0
                      ],
                      [
                          -73.391034,
                          45.84738,
                          0
                      ],
                      [
                          -73.391026,
                          45.847374,
                          0
                      ],
                      [
                          -73.386248,
                          45.843818,
                          0
                      ],
                      [
                          -73.382514,
                          45.845706,
                          0
                      ],
                      [
                          -73.382509,
                          45.845702,
                          0
                      ],
                      [
                          -73.379658,
                          45.843705,
                          0
                      ],
                      [
                          -73.376776,
                          45.845837,
                          0
                      ],
                      [
                          -73.376795,
                          45.845851,
                          0
                      ],
                      [
                          -73.379661,
                          45.847865,
                          0
                      ],
                      [
                          -73.375797,
                          45.850568,
                          0
                      ],
                      [
                          -73.375734,
                          45.850593,
                          0
                      ],
                      [
                          -73.372919,
                          45.851718,
                          0
                      ],
                      [
                          -73.372718,
                          45.851599,
                          0
                      ],
                      [
                          -73.364586,
                          45.855917,
                          0
                      ],
                      [
                          -73.362674,
                          45.856572,
                          0
                      ],
                      [
                          -73.361147,
                          45.855482,
                          0
                      ],
                      [
                          -73.355923,
                          45.859802,
                          0
                      ],
                      [
                          -73.356417,
                          45.860149,
                          0
                      ],
                      [
                          -73.35291,
                          45.864964,
                          0
                      ],
                      [
                          -73.345016,
                          45.871195,
                          0
                      ],
                      [
                          -73.31533,
                          45.850036,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Repentigny",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -72.956128,
                          46.086899,
                          0
                      ],
                      [
                          -72.955686,
                          46.0871,
                          0
                      ],
                      [
                          -72.954423,
                          46.087133,
                          0
                      ],
                      [
                          -72.953008,
                          46.086539,
                          0
                      ],
                      [
                          -72.952349,
                          46.085478,
                          0
                      ],
                      [
                          -72.953626,
                          46.08539,
                          0
                      ],
                      [
                          -72.953733,
                          46.085234,
                          0
                      ],
                      [
                          -72.94566,
                          46.079686,
                          0
                      ],
                      [
                          -72.944747,
                          46.080445,
                          0
                      ],
                      [
                          -72.944714,
                          46.08381,
                          0
                      ],
                      [
                          -72.944836,
                          46.085763,
                          0
                      ],
                      [
                          -72.944133,
                          46.089455,
                          0
                      ],
                      [
                          -72.94226,
                          46.089135,
                          0
                      ],
                      [
                          -72.942869,
                          46.086324,
                          0
                      ],
                      [
                          -72.942369,
                          46.084494,
                          0
                      ],
                      [
                          -72.941525,
                          46.081414,
                          0
                      ],
                      [
                          -72.942225,
                          46.079925,
                          0
                      ],
                      [
                          -72.940793,
                          46.078895,
                          0
                      ],
                      [
                          -72.940827,
                          46.076338,
                          0
                      ],
                      [
                          -72.880304,
                          46.034184,
                          0
                      ],
                      [
                          -72.874617,
                          46.03805,
                          0
                      ],
                      [
                          -72.86837,
                          46.033639,
                          0
                      ],
                      [
                          -72.866158,
                          46.035055,
                          0
                      ],
                      [
                          -72.86336,
                          46.038288,
                          0
                      ],
                      [
                          -72.860952,
                          46.042702,
                          0
                      ],
                      [
                          -72.829695,
                          46.021148,
                          0
                      ],
                      [
                          -72.82808,
                          46.032948,
                          0
                      ],
                      [
                          -72.821712,
                          46.029077,
                          0
                      ],
                      [
                          -72.811882,
                          46.022026,
                          0
                      ],
                      [
                          -72.804996,
                          46.015909,
                          0
                      ],
                      [
                          -72.798628,
                          46.014448,
                          0
                      ],
                      [
                          -72.802545,
                          46.011513,
                          0
                      ],
                      [
                          -72.803564,
                          45.997363,
                          0
                      ],
                      [
                          -72.790266,
                          45.991802,
                          0
                      ],
                      [
                          -72.805245,
                          45.981322,
                          0
                      ],
                      [
                          -72.80521,
                          45.981298,
                          0
                      ],
                      [
                          -72.791856,
                          45.972191,
                          0
                      ],
                      [
                          -72.791859,
                          45.972189,
                          0
                      ],
                      [
                          -72.793106,
                          45.971308,
                          0
                      ],
                      [
                          -72.793102,
                          45.971306,
                          0
                      ],
                      [
                          -72.752657,
                          45.942919,
                          0
                      ],
                      [
                          -72.752655,
                          45.942918,
                          0
                      ],
                      [
                          -72.752655,
                          45.942918,
                          0
                      ],
                      [
                          -72.752684,
                          45.942891,
                          0
                      ],
                      [
                          -72.752749,
                          45.942835,
                          0
                      ],
                      [
                          -72.793014,
                          45.907317,
                          0
                      ],
                      [
                          -72.793181,
                          45.907167,
                          0
                      ],
                      [
                          -72.793434,
                          45.906938,
                          0
                      ],
                      [
                          -72.793706,
                          45.906714,
                          0
                      ],
                      [
                          -72.793707,
                          45.906713,
                          0
                      ],
                      [
                          -72.79392,
                          45.90661,
                          0
                      ],
                      [
                          -72.794097,
                          45.906551,
                          0
                      ],
                      [
                          -72.794097,
                          45.906553,
                          0
                      ],
                      [
                          -72.794158,
                          45.906721,
                          0
                      ],
                      [
                          -72.794306,
                          45.906852,
                          0
                      ],
                      [
                          -72.794669,
                          45.906906,
                          0
                      ],
                      [
                          -72.795147,
                          45.906651,
                          0
                      ],
                      [
                          -72.795583,
                          45.906343,
                          0
                      ],
                      [
                          -72.796116,
                          45.905943,
                          0
                      ],
                      [
                          -72.796246,
                          45.905723,
                          0
                      ],
                      [
                          -72.796351,
                          45.905569,
                          0
                      ],
                      [
                          -72.796709,
                          45.90545,
                          0
                      ],
                      [
                          -72.797208,
                          45.905357,
                          0
                      ],
                      [
                          -72.797582,
                          45.905382,
                          0
                      ],
                      [
                          -72.797937,
                          45.905508,
                          0
                      ],
                      [
                          -72.798281,
                          45.905689,
                          0
                      ],
                      [
                          -72.798576,
                          45.905907,
                          0
                      ],
                      [
                          -72.798829,
                          45.906162,
                          0
                      ],
                      [
                          -72.799044,
                          45.906453,
                          0
                      ],
                      [
                          -72.799378,
                          45.906679,
                          0
                      ],
                      [
                          -72.799696,
                          45.906683,
                          0
                      ],
                      [
                          -72.799954,
                          45.906601,
                          0
                      ],
                      [
                          -72.800076,
                          45.906454,
                          0
                      ],
                      [
                          -72.800062,
                          45.906106,
                          0
                      ],
                      [
                          -72.800062,
                          45.906104,
                          0
                      ],
                      [
                          -72.799759,
                          45.905597,
                          0
                      ],
                      [
                          -72.799699,
                          45.90541,
                          0
                      ],
                      [
                          -72.799738,
                          45.905128,
                          0
                      ],
                      [
                          -72.799919,
                          45.904955,
                          0
                      ],
                      [
                          -72.800123,
                          45.904886,
                          0
                      ],
                      [
                          -72.800245,
                          45.904922,
                          0
                      ],
                      [
                          -72.800521,
                          45.905093,
                          0
                      ],
                      [
                          -72.800799,
                          45.905247,
                          0
                      ],
                      [
                          -72.800891,
                          45.905272,
                          0
                      ],
                      [
                          -72.800892,
                          45.905272,
                          0
                      ],
                      [
                          -72.800894,
                          45.905272,
                          0
                      ],
                      [
                          -72.801322,
                          45.905126,
                          0
                      ],
                      [
                          -72.801394,
                          45.905069,
                          0
                      ],
                      [
                          -72.801711,
                          45.90469,
                          0
                      ],
                      [
                          -72.802081,
                          45.904603,
                          0
                      ],
                      [
                          -72.819533,
                          45.892682,
                          0
                      ],
                      [
                          -72.819536,
                          45.892679,
                          0
                      ],
                      [
                          -72.821221,
                          45.893901,
                          0
                      ],
                      [
                          -72.821226,
                          45.893905,
                          0
                      ],
                      [
                          -72.831856,
                          45.886598,
                          0
                      ],
                      [
                          -72.831862,
                          45.886594,
                          0
                      ],
                      [
                          -72.83412,
                          45.889075,
                          0
                      ],
                      [
                          -72.835849,
                          45.889859,
                          0
                      ],
                      [
                          -72.838016,
                          45.890927,
                          0
                      ],
                      [
                          -72.838499,
                          45.891438,
                          0
                      ],
                      [
                          -72.8385,
                          45.891437,
                          0
                      ],
                      [
                          -72.839916,
                          45.890474,
                          0
                      ],
                      [
                          -72.850455,
                          45.883522,
                          0
                      ],
                      [
                          -72.851884,
                          45.880803,
                          0
                      ],
                      [
                          -72.853967,
                          45.878525,
                          0
                      ],
                      [
                          -72.853965,
                          45.878524,
                          0
                      ],
                      [
                          -72.848691,
                          45.874662,
                          0
                      ],
                      [
                          -72.853024,
                          45.863893,
                          0
                      ],
                      [
                          -72.837018,
                          45.852847,
                          0
                      ],
                      [
                          -72.837065,
                          45.852815,
                          0
                      ],
                      [
                          -72.845625,
                          45.8468,
                          0
                      ],
                      [
                          -72.845629,
                          45.846802,
                          0
                      ],
                      [
                          -72.846745,
                          45.847561,
                          0
                      ],
                      [
                          -72.84836,
                          45.846537,
                          0
                      ],
                      [
                          -72.848368,
                          45.846531,
                          0
                      ],
                      [
                          -72.84943,
                          45.847265,
                          0
                      ],
                      [
                          -72.863831,
                          45.836963,
                          0
                      ],
                      [
                          -72.878109,
                          45.801324,
                          0
                      ],
                      [
                          -72.878125,
                          45.801282,
                          0
                      ],
                      [
                          -72.878127,
                          45.801278,
                          0
                      ],
                      [
                          -72.920508,
                          45.830429,
                          0
                      ],
                      [
                          -72.920518,
                          45.830422,
                          0
                      ],
                      [
                          -72.934716,
                          45.82032,
                          0
                      ],
                      [
                          -72.934712,
                          45.820317,
                          0
                      ],
                      [
                          -72.934704,
                          45.82031,
                          0
                      ],
                      [
                          -72.932585,
                          45.815246,
                          0
                      ],
                      [
                          -72.932585,
                          45.815246,
                          0
                      ],
                      [
                          -72.932143,
                          45.814708,
                          0
                      ],
                      [
                          -72.942175,
                          45.798274,
                          0
                      ],
                      [
                          -72.942175,
                          45.798273,
                          0
                      ],
                      [
                          -72.936967,
                          45.794275,
                          0
                      ],
                      [
                          -72.94422,
                          45.776804,
                          0
                      ],
                      [
                          -72.943722,
                          45.77642,
                          0
                      ],
                      [
                          -72.953596,
                          45.753464,
                          0
                      ],
                      [
                          -72.953594,
                          45.753463,
                          0
                      ],
                      [
                          -72.953591,
                          45.753461,
                          0
                      ],
                      [
                          -72.953259,
                          45.753207,
                          0
                      ],
                      [
                          -72.953259,
                          45.753207,
                          0
                      ],
                      [
                          -72.953259,
                          45.753207,
                          0
                      ],
                      [
                          -73.001556,
                          45.721517,
                          0
                      ],
                      [
                          -73.001559,
                          45.721516,
                          0
                      ],
                      [
                          -73.053453,
                          45.759574,
                          0
                      ],
                      [
                          -73.053589,
                          45.759381,
                          0
                      ],
                      [
                          -73.05944,
                          45.747461,
                          0
                      ],
                      [
                          -73.07467,
                          45.75883,
                          0
                      ],
                      [
                          -73.074722,
                          45.758628,
                          0
                      ],
                      [
                          -73.094745,
                          45.773338,
                          0
                      ],
                      [
                          -73.094738,
                          45.773353,
                          0
                      ],
                      [
                          -73.093786,
                          45.775679,
                          0
                      ],
                      [
                          -73.094727,
                          45.776391,
                          0
                      ],
                      [
                          -73.094716,
                          45.776412,
                          0
                      ],
                      [
                          -73.093006,
                          45.779564,
                          0
                      ],
                      [
                          -73.099145,
                          45.784036,
                          0
                      ],
                      [
                          -73.102929,
                          45.786792,
                          0
                      ],
                      [
                          -73.102926,
                          45.786818,
                          0
                      ],
                      [
                          -73.102538,
                          45.789811,
                          0
                      ],
                      [
                          -73.098151,
                          45.798577,
                          0
                      ],
                      [
                          -73.184718,
                          45.861395,
                          0
                      ],
                      [
                          -73.204073,
                          45.875542,
                          0
                      ],
                      [
                          -73.204079,
                          45.875546,
                          0
                      ],
                      [
                          -73.200496,
                          45.879309,
                          0
                      ],
                      [
                          -73.202159,
                          45.879861,
                          0
                      ],
                      [
                          -73.199383,
                          45.883039,
                          0
                      ],
                      [
                          -73.199373,
                          45.883036,
                          0
                      ],
                      [
                          -73.196542,
                          45.882113,
                          0
                      ],
                      [
                          -73.191599,
                          45.893322,
                          0
                      ],
                      [
                          -73.191577,
                          45.893315,
                          0
                      ],
                      [
                          -73.187759,
                          45.892064,
                          0
                      ],
                      [
                          -73.187751,
                          45.892131,
                          0
                      ],
                      [
                          -73.18714,
                          45.897323,
                          0
                      ],
                      [
                          -73.187133,
                          45.897385,
                          0
                      ],
                      [
                          -73.188388,
                          45.909432,
                          0
                      ],
                      [
                          -73.188941,
                          45.914828,
                          0
                      ],
                      [
                          -73.188449,
                          45.920443,
                          0
                      ],
                      [
                          -73.183286,
                          45.941986,
                          0
                      ],
                      [
                          -73.17968,
                          45.950833,
                          0
                      ],
                      [
                          -73.168409,
                          45.96101,
                          0
                      ],
                      [
                          -73.164727,
                          45.968505,
                          0
                      ],
                      [
                          -73.179255,
                          45.979268,
                          0
                      ],
                      [
                          -73.186376,
                          45.98454,
                          0
                      ],
                      [
                          -73.186378,
                          45.984541,
                          0
                      ],
                      [
                          -73.186353,
                          45.984591,
                          0
                      ],
                      [
                          -73.164835,
                          46.035657,
                          0
                      ],
                      [
                          -73.164742,
                          46.035797,
                          0
                      ],
                      [
                          -73.164742,
                          46.035797,
                          0
                      ],
                      [
                          -73.145585,
                          46.051315,
                          0
                      ],
                      [
                          -73.084658,
                          46.065907,
                          0
                      ],
                      [
                          -73.074599,
                          46.073885,
                          0
                      ],
                      [
                          -73.050759,
                          46.100783,
                          0
                      ],
                      [
                          -73.028231,
                          46.109144,
                          0
                      ],
                      [
                          -72.998119,
                          46.112783,
                          0
                      ],
                      [
                          -72.990819,
                          46.111594,
                          0
                      ],
                      [
                          -72.956128,
                          46.086899,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Richelieu",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -71.635518,
                          45.813913,
                          0
                      ],
                      [
                          -71.617309,
                          45.830495,
                          0
                      ],
                      [
                          -71.617308,
                          45.830495,
                          0
                      ],
                      [
                          -71.603203,
                          45.822827,
                          0
                      ],
                      [
                          -71.600242,
                          45.825426,
                          0
                      ],
                      [
                          -71.592839,
                          45.821808,
                          0
                      ],
                      [
                          -71.593025,
                          45.821553,
                          0
                      ],
                      [
                          -71.593183,
                          45.821263,
                          0
                      ],
                      [
                          -71.593198,
                          45.820876,
                          0
                      ],
                      [
                          -71.593073,
                          45.82059,
                          0
                      ],
                      [
                          -71.592452,
                          45.820077,
                          0
                      ],
                      [
                          -71.590271,
                          45.818525,
                          0
                      ],
                      [
                          -71.59427,
                          45.815399,
                          0
                      ],
                      [
                          -71.594199,
                          45.815361,
                          0
                      ],
                      [
                          -71.541559,
                          45.787806,
                          0
                      ],
                      [
                          -71.541531,
                          45.787832,
                          0
                      ],
                      [
                          -71.522467,
                          45.804402,
                          0
                      ],
                      [
                          -71.52238,
                          45.804357,
                          0
                      ],
                      [
                          -71.497972,
                          45.79189,
                          0
                      ],
                      [
                          -71.461406,
                          45.81662,
                          0
                      ],
                      [
                          -71.461405,
                          45.816619,
                          0
                      ],
                      [
                          -71.436094,
                          45.799182,
                          0
                      ],
                      [
                          -71.585529,
                          45.692807,
                          0
                      ],
                      [
                          -71.611474,
                          45.712512,
                          0
                      ],
                      [
                          -71.707984,
                          45.645661,
                          0
                      ],
                      [
                          -71.691845,
                          45.634944,
                          0
                      ],
                      [
                          -71.715824,
                          45.617275,
                          0
                      ],
                      [
                          -71.774639,
                          45.650751,
                          0
                      ],
                      [
                          -71.77466,
                          45.650731,
                          0
                      ],
                      [
                          -71.919865,
                          45.524064,
                          0
                      ],
                      [
                          -71.919963,
                          45.523982,
                          0
                      ],
                      [
                          -71.932525,
                          45.513246,
                          0
                      ],
                      [
                          -71.948152,
                          45.522127,
                          0
                      ],
                      [
                          -71.960981,
                          45.510794,
                          0
                      ],
                      [
                          -71.966325,
                          45.513708,
                          0
                      ],
                      [
                          -71.978697,
                          45.50261,
                          0
                      ],
                      [
                          -71.982601,
                          45.50568,
                          0
                      ],
                      [
                          -71.983333,
                          45.51297,
                          0
                      ],
                      [
                          -71.987141,
                          45.518969,
                          0
                      ],
                      [
                          -72.012689,
                          45.49618,
                          0
                      ],
                      [
                          -72.023282,
                          45.482224,
                          0
                      ],
                      [
                          -72.023344,
                          45.482142,
                          0
                      ],
                      [
                          -72.035375,
                          45.470187,
                          0
                      ],
                      [
                          -72.03545,
                          45.470115,
                          0
                      ],
                      [
                          -72.035444,
                          45.470112,
                          0
                      ],
                      [
                          -71.994979,
                          45.448673,
                          0
                      ],
                      [
                          -71.994977,
                          45.448582,
                          0
                      ],
                      [
                          -71.975471,
                          45.448754,
                          0
                      ],
                      [
                          -71.975523,
                          45.438064,
                          0
                      ],
                      [
                          -71.958074,
                          45.438135,
                          0
                      ],
                      [
                          -71.960854,
                          45.434293,
                          0
                      ],
                      [
                          -71.96692,
                          45.42619,
                          0
                      ],
                      [
                          -71.985034,
                          45.408728,
                          0
                      ],
                      [
                          -71.985017,
                          45.394763,
                          0
                      ],
                      [
                          -71.985015,
                          45.391818,
                          0
                      ],
                      [
                          -71.985076,
                          45.38505,
                          0
                      ],
                      [
                          -71.975568,
                          45.384392,
                          0
                      ],
                      [
                          -71.964724,
                          45.38437,
                          0
                      ],
                      [
                          -71.964447,
                          45.384526,
                          0
                      ],
                      [
                          -71.963961,
                          45.384103,
                          0
                      ],
                      [
                          -71.963469,
                          45.384382,
                          0
                      ],
                      [
                          -71.958078,
                          45.38441,
                          0
                      ],
                      [
                          -71.958678,
                          45.383112,
                          0
                      ],
                      [
                          -71.960687,
                          45.380056,
                          0
                      ],
                      [
                          -71.960792,
                          45.379103,
                          0
                      ],
                      [
                          -71.960239,
                          45.377797,
                          0
                      ],
                      [
                          -71.959111,
                          45.37405,
                          0
                      ],
                      [
                          -71.959056,
                          45.373329,
                          0
                      ],
                      [
                          -71.959223,
                          45.372885,
                          0
                      ],
                      [
                          -71.960664,
                          45.371775,
                          0
                      ],
                      [
                          -71.963327,
                          45.369889,
                          0
                      ],
                      [
                          -71.964159,
                          45.368947,
                          0
                      ],
                      [
                          -71.964381,
                          45.368115,
                          0
                      ],
                      [
                          -71.96427,
                          45.367394,
                          0
                      ],
                      [
                          -71.964271,
                          45.36645,
                          0
                      ],
                      [
                          -71.964602,
                          45.36584,
                          0
                      ],
                      [
                          -71.964936,
                          45.365451,
                          0
                      ],
                      [
                          -71.965268,
                          45.364454,
                          0
                      ],
                      [
                          -71.965491,
                          45.363511,
                          0
                      ],
                      [
                          -71.965491,
                          45.362014,
                          0
                      ],
                      [
                          -71.964991,
                          45.360737,
                          0
                      ],
                      [
                          -71.96488,
                          45.359906,
                          0
                      ],
                      [
                          -71.965213,
                          45.359074,
                          0
                      ],
                      [
                          -71.966386,
                          45.358542,
                          0
                      ],
                      [
                          -71.966765,
                          45.358353,
                          0
                      ],
                      [
                          -71.967321,
                          45.358298,
                          0
                      ],
                      [
                          -71.968465,
                          45.357633,
                          0
                      ],
                      [
                          -71.969428,
                          45.356634,
                          0
                      ],
                      [
                          -71.969711,
                          45.355965,
                          0
                      ],
                      [
                          -71.970205,
                          45.355636,
                          0
                      ],
                      [
                          -71.970616,
                          45.355577,
                          0
                      ],
                      [
                          -71.969858,
                          45.353578,
                          0
                      ],
                      [
                          -71.969611,
                          45.353374,
                          0
                      ],
                      [
                          -71.964541,
                          45.350262,
                          0
                      ],
                      [
                          -71.956195,
                          45.34983,
                          0
                      ],
                      [
                          -71.955309,
                          45.349643,
                          0
                      ],
                      [
                          -71.954658,
                          45.349102,
                          0
                      ],
                      [
                          -71.954336,
                          45.348989,
                          0
                      ],
                      [
                          -71.951137,
                          45.349201,
                          0
                      ],
                      [
                          -71.946032,
                          45.349662,
                          0
                      ],
                      [
                          -71.938369,
                          45.350073,
                          0
                      ],
                      [
                          -71.937066,
                          45.349865,
                          0
                      ],
                      [
                          -71.937935,
                          45.348378,
                          0
                      ],
                      [
                          -71.939343,
                          45.346084,
                          0
                      ],
                      [
                          -71.94048,
                          45.344063,
                          0
                      ],
                      [
                          -71.941067,
                          45.342864,
                          0
                      ],
                      [
                          -71.941967,
                          45.340831,
                          0
                      ],
                      [
                          -71.943086,
                          45.337173,
                          0
                      ],
                      [
                          -71.942978,
                          45.335428,
                          0
                      ],
                      [
                          -71.941593,
                          45.334105,
                          0
                      ],
                      [
                          -71.9416,
                          45.334105,
                          0
                      ],
                      [
                          -71.949743,
                          45.333696,
                          0
                      ],
                      [
                          -71.949743,
                          45.333696,
                          0
                      ],
                      [
                          -71.948728,
                          45.307775,
                          0
                      ],
                      [
                          -71.953435,
                          45.307863,
                          0
                      ],
                      [
                          -71.953437,
                          45.307867,
                          0
                      ],
                      [
                          -71.953445,
                          45.307884,
                          0
                      ],
                      [
                          -71.953448,
                          45.307891,
                          0
                      ],
                      [
                          -71.95345,
                          45.307894,
                          0
                      ],
                      [
                          -72.037476,
                          45.308502,
                          0
                      ],
                      [
                          -72.042551,
                          45.299889,
                          0
                      ],
                      [
                          -72.108748,
                          45.301468,
                          0
                      ],
                      [
                          -72.108747,
                          45.301517,
                          0
                      ],
                      [
                          -72.107778,
                          45.324029,
                          0
                      ],
                      [
                          -72.1053,
                          45.384778,
                          0
                      ],
                      [
                          -72.105449,
                          45.38479,
                          0
                      ],
                      [
                          -72.125491,
                          45.386552,
                          0
                      ],
                      [
                          -72.125556,
                          45.386558,
                          0
                      ],
                      [
                          -72.125854,
                          45.381127,
                          0
                      ],
                      [
                          -72.145529,
                          45.382207,
                          0
                      ],
                      [
                          -72.14556,
                          45.382209,
                          0
                      ],
                      [
                          -72.141566,
                          45.41148,
                          0
                      ],
                      [
                          -72.143037,
                          45.416678,
                          0
                      ],
                      [
                          -72.147682,
                          45.428339,
                          0
                      ],
                      [
                          -72.182304,
                          45.430517,
                          0
                      ],
                      [
                          -72.178903,
                          45.445417,
                          0
                      ],
                      [
                          -72.218759,
                          45.449001,
                          0
                      ],
                      [
                          -72.306107,
                          45.45116,
                          0
                      ],
                      [
                          -72.363212,
                          45.450482,
                          0
                      ],
                      [
                          -72.403998,
                          45.449189,
                          0
                      ],
                      [
                          -72.404014,
                          45.449189,
                          0
                      ],
                      [
                          -72.404006,
                          45.4516,
                          0
                      ],
                      [
                          -72.404239,
                          45.469854,
                          0
                      ],
                      [
                          -72.404873,
                          45.503739,
                          0
                      ],
                      [
                          -72.404853,
                          45.503883,
                          0
                      ],
                      [
                          -72.402813,
                          45.528567,
                          0
                      ],
                      [
                          -72.378652,
                          45.529712,
                          0
                      ],
                      [
                          -72.378635,
                          45.529776,
                          0
                      ],
                      [
                          -72.378116,
                          45.566501,
                          0
                      ],
                      [
                          -72.378021,
                          45.566506,
                          0
                      ],
                      [
                          -72.360868,
                          45.567313,
                          0
                      ],
                      [
                          -72.360781,
                          45.567317,
                          0
                      ],
                      [
                          -72.359676,
                          45.589395,
                          0
                      ],
                      [
                          -72.350619,
                          45.588963,
                          0
                      ],
                      [
                          -72.350645,
                          45.594019,
                          0
                      ],
                      [
                          -72.343663,
                          45.593853,
                          0
                      ],
                      [
                          -72.343473,
                          45.599101,
                          0
                      ],
                      [
                          -72.315551,
                          45.599224,
                          0
                      ],
                      [
                          -72.297647,
                          45.589484,
                          0
                      ],
                      [
                          -72.297606,
                          45.589457,
                          0
                      ],
                      [
                          -72.297592,
                          45.589448,
                          0
                      ],
                      [
                          -72.297581,
                          45.58944,
                          0
                      ],
                      [
                          -72.297579,
                          45.589443,
                          0
                      ],
                      [
                          -72.237116,
                          45.646252,
                          0
                      ],
                      [
                          -72.237089,
                          45.646276,
                          0
                      ],
                      [
                          -72.237091,
                          45.646277,
                          0
                      ],
                      [
                          -72.310761,
                          45.688542,
                          0
                      ],
                      [
                          -72.310667,
                          45.688617,
                          0
                      ],
                      [
                          -72.281648,
                          45.710695,
                          0
                      ],
                      [
                          -72.281663,
                          45.710703,
                          0
                      ],
                      [
                          -72.2271,
                          45.756215,
                          0
                      ],
                      [
                          -72.222293,
                          45.753622,
                          0
                      ],
                      [
                          -72.221652,
                          45.751709,
                          0
                      ],
                      [
                          -72.221973,
                          45.747933,
                          0
                      ],
                      [
                          -72.226055,
                          45.742837,
                          0
                      ],
                      [
                          -72.228858,
                          45.741322,
                          0
                      ],
                      [
                          -72.234445,
                          45.741191,
                          0
                      ],
                      [
                          -72.236297,
                          45.740022,
                          0
                      ],
                      [
                          -72.235826,
                          45.738119,
                          0
                      ],
                      [
                          -72.23375,
                          45.736229,
                          0
                      ],
                      [
                          -72.228809,
                          45.735456,
                          0
                      ],
                      [
                          -72.224865,
                          45.734546,
                          0
                      ],
                      [
                          -72.221592,
                          45.735275,
                          0
                      ],
                      [
                          -72.218821,
                          45.734443,
                          0
                      ],
                      [
                          -72.218047,
                          45.733145,
                          0
                      ],
                      [
                          -72.215649,
                          45.732644,
                          0
                      ],
                      [
                          -72.215659,
                          45.730839,
                          0
                      ],
                      [
                          -72.215058,
                          45.729903,
                          0
                      ],
                      [
                          -72.213435,
                          45.727734,
                          0
                      ],
                      [
                          -72.212535,
                          45.726091,
                          0
                      ],
                      [
                          -72.214122,
                          45.72327,
                          0
                      ],
                      [
                          -72.218793,
                          45.720638,
                          0
                      ],
                      [
                          -72.223765,
                          45.717838,
                          0
                      ],
                      [
                          -72.223937,
                          45.716404,
                          0
                      ],
                      [
                          -72.220937,
                          45.713117,
                          0
                      ],
                      [
                          -72.220892,
                          45.713099,
                          0
                      ],
                      [
                          -72.218068,
                          45.712707,
                          0
                      ],
                      [
                          -72.215108,
                          45.711337,
                          0
                      ],
                      [
                          -72.213971,
                          45.709261,
                          0
                      ],
                      [
                          -72.213321,
                          45.707087,
                          0
                      ],
                      [
                          -72.209525,
                          45.702332,
                          0
                      ],
                      [
                          -72.209654,
                          45.698875,
                          0
                      ],
                      [
                          -72.206342,
                          45.696384,
                          0
                      ],
                      [
                          -72.206339,
                          45.696388,
                          0
                      ],
                      [
                          -72.029655,
                          45.853411,
                          0
                      ],
                      [
                          -72.029648,
                          45.853417,
                          0
                      ],
                      [
                          -71.921238,
                          45.796311,
                          0
                      ],
                      [
                          -71.921234,
                          45.796308,
                          0
                      ],
                      [
                          -71.918201,
                          45.797846,
                          0
                      ],
                      [
                          -71.917615,
                          45.799062,
                          0
                      ],
                      [
                          -71.917914,
                          45.800632,
                          0
                      ],
                      [
                          -71.917728,
                          45.801131,
                          0
                      ],
                      [
                          -71.917039,
                          45.80169,
                          0
                      ],
                      [
                          -71.911955,
                          45.803111,
                          0
                      ],
                      [
                          -71.909031,
                          45.803719,
                          0
                      ],
                      [
                          -71.906715,
                          45.804512,
                          0
                      ],
                      [
                          -71.904668,
                          45.804821,
                          0
                      ],
                      [
                          -71.900312,
                          45.803853,
                          0
                      ],
                      [
                          -71.896438,
                          45.802353,
                          0
                      ],
                      [
                          -71.895011,
                          45.801649,
                          0
                      ],
                      [
                          -71.893544,
                          45.801049,
                          0
                      ],
                      [
                          -71.891158,
                          45.800726,
                          0
                      ],
                      [
                          -71.88947,
                          45.801135,
                          0
                      ],
                      [
                          -71.88739,
                          45.802247,
                          0
                      ],
                      [
                          -71.885528,
                          45.8038,
                          0
                      ],
                      [
                          -71.883728,
                          45.804405,
                          0
                      ],
                      [
                          -71.883699,
                          45.804408,
                          0
                      ],
                      [
                          -71.883696,
                          45.804408,
                          0
                      ],
                      [
                          -71.882303,
                          45.804314,
                          0
                      ],
                      [
                          -71.881868,
                          45.804403,
                          0
                      ],
                      [
                          -71.881098,
                          45.804464,
                          0
                      ],
                      [
                          -71.880071,
                          45.804129,
                          0
                      ],
                      [
                          -71.88007,
                          45.804127,
                          0
                      ],
                      [
                          -71.880051,
                          45.803939,
                          0
                      ],
                      [
                          -71.880053,
                          45.803919,
                          0
                      ],
                      [
                          -71.879782,
                          45.802512,
                          0
                      ],
                      [
                          -71.878737,
                          45.800955,
                          0
                      ],
                      [
                          -71.874473,
                          45.798352,
                          0
                      ],
                      [
                          -71.874471,
                          45.798352,
                          0
                      ],
                      [
                          -71.874465,
                          45.798347,
                          0
                      ],
                      [
                          -71.869463,
                          45.803166,
                          0
                      ],
                      [
                          -71.869438,
                          45.803192,
                          0
                      ],
                      [
                          -71.869437,
                          45.803193,
                          0
                      ],
                      [
                          -71.861486,
                          45.798593,
                          0
                      ],
                      [
                          -71.861443,
                          45.798568,
                          0
                      ],
                      [
                          -71.861441,
                          45.798567,
                          0
                      ],
                      [
                          -71.86131,
                          45.798709,
                          0
                      ],
                      [
                          -71.858841,
                          45.801383,
                          0
                      ],
                      [
                          -71.856913,
                          45.803423,
                          0
                      ],
                      [
                          -71.853071,
                          45.806895,
                          0
                      ],
                      [
                          -71.851386,
                          45.808437,
                          0
                      ],
                      [
                          -71.844361,
                          45.815017,
                          0
                      ],
                      [
                          -71.769975,
                          45.885287,
                          0
                      ],
                      [
                          -71.769965,
                          45.885296,
                          0
                      ],
                      [
                          -71.769951,
                          45.885289,
                          0
                      ],
                      [
                          -71.635518,
                          45.813913,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Richmond",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.821466,
                          45.516018,
                          0
                      ],
                      [
                          -73.812881,
                          45.517609,
                          0
                      ],
                      [
                          -73.805086,
                          45.516159,
                          0
                      ],
                      [
                          -73.799638,
                          45.515327,
                          0
                      ],
                      [
                          -73.795909,
                          45.512767,
                          0
                      ],
                      [
                          -73.775667,
                          45.511543,
                          0
                      ],
                      [
                          -73.76472,
                          45.511629,
                          0
                      ],
                      [
                          -73.763914,
                          45.511847,
                          0
                      ],
                      [
                          -73.751723,
                          45.504322,
                          0
                      ],
                      [
                          -73.752917,
                          45.503546,
                          0
                      ],
                      [
                          -73.7531,
                          45.503664,
                          0
                      ],
                      [
                          -73.754743,
                          45.503287,
                          0
                      ],
                      [
                          -73.758309,
                          45.501994,
                          0
                      ],
                      [
                          -73.759485,
                          45.502704,
                          0
                      ],
                      [
                          -73.760968,
                          45.501546,
                          0
                      ],
                      [
                          -73.763913,
                          45.503407,
                          0
                      ],
                      [
                          -73.764637,
                          45.502846,
                          0
                      ],
                      [
                          -73.765416,
                          45.503361,
                          0
                      ],
                      [
                          -73.76724,
                          45.502702,
                          0
                      ],
                      [
                          -73.769251,
                          45.503974,
                          0
                      ],
                      [
                          -73.769265,
                          45.503969,
                          0
                      ],
                      [
                          -73.772517,
                          45.502512,
                          0
                      ],
                      [
                          -73.772526,
                          45.502506,
                          0
                      ],
                      [
                          -73.770909,
                          45.501465,
                          0
                      ],
                      [
                          -73.773882,
                          45.50051,
                          0
                      ],
                      [
                          -73.771678,
                          45.49911,
                          0
                      ],
                      [
                          -73.773621,
                          45.498202,
                          0
                      ],
                      [
                          -73.769708,
                          45.495712,
                          0
                      ],
                      [
                          -73.768427,
                          45.494448,
                          0
                      ],
                      [
                          -73.794534,
                          45.481681,
                          0
                      ],
                      [
                          -73.79604,
                          45.483099,
                          0
                      ],
                      [
                          -73.812612,
                          45.475219,
                          0
                      ],
                      [
                          -73.815747,
                          45.478342,
                          0
                      ],
                      [
                          -73.846552,
                          45.462571,
                          0
                      ],
                      [
                          -73.854562,
                          45.470598,
                          0
                      ],
                      [
                          -73.856948,
                          45.472938,
                          0
                      ],
                      [
                          -73.857413,
                          45.473665,
                          0
                      ],
                      [
                          -73.856835,
                          45.473944,
                          0
                      ],
                      [
                          -73.857148,
                          45.474268,
                          0
                      ],
                      [
                          -73.862236,
                          45.482128,
                          0
                      ],
                      [
                          -73.857768,
                          45.485392,
                          0
                      ],
                      [
                          -73.854931,
                          45.489752,
                          0
                      ],
                      [
                          -73.851884,
                          45.491859,
                          0
                      ],
                      [
                          -73.841107,
                          45.499274,
                          0
                      ],
                      [
                          -73.835832,
                          45.50163,
                          0
                      ],
                      [
                          -73.82426,
                          45.504965,
                          0
                      ],
                      [
                          -73.819893,
                          45.506763,
                          0
                      ],
                      [
                          -73.823895,
                          45.511441,
                          0
                      ],
                      [
                          -73.828501,
                          45.518505,
                          0
                      ],
                      [
                          -73.821466,
                          45.516018,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Robert-Baldwin",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.571022,
                          45.580922,
                          0
                      ],
                      [
                          -73.570653,
                          45.581473,
                          0
                      ],
                      [
                          -73.569863,
                          45.581214,
                          0
                      ],
                      [
                          -73.569599,
                          45.581658,
                          0
                      ],
                      [
                          -73.568572,
                          45.581318,
                          0
                      ],
                      [
                          -73.568483,
                          45.581443,
                          0
                      ],
                      [
                          -73.558393,
                          45.578081,
                          0
                      ],
                      [
                          -73.55576,
                          45.575716,
                          0
                      ],
                      [
                          -73.548988,
                          45.573467,
                          0
                      ],
                      [
                          -73.552548,
                          45.568224,
                          0
                      ],
                      [
                          -73.554188,
                          45.565788,
                          0
                      ],
                      [
                          -73.554584,
                          45.564727,
                          0
                      ],
                      [
                          -73.554293,
                          45.55693,
                          0
                      ],
                      [
                          -73.554293,
                          45.556908,
                          0
                      ],
                      [
                          -73.554406,
                          45.55572,
                          0
                      ],
                      [
                          -73.554874,
                          45.554822,
                          0
                      ],
                      [
                          -73.561286,
                          45.54429,
                          0
                      ],
                      [
                          -73.56172,
                          45.543488,
                          0
                      ],
                      [
                          -73.561459,
                          45.540144,
                          0
                      ],
                      [
                          -73.574131,
                          45.541385,
                          0
                      ],
                      [
                          -73.576688,
                          45.541596,
                          0
                      ],
                      [
                          -73.577917,
                          45.541584,
                          0
                      ],
                      [
                          -73.579206,
                          45.541491,
                          0
                      ],
                      [
                          -73.58066,
                          45.54123,
                          0
                      ],
                      [
                          -73.579327,
                          45.541846,
                          0
                      ],
                      [
                          -73.577792,
                          45.543434,
                          0
                      ],
                      [
                          -73.577324,
                          45.544108,
                          0
                      ],
                      [
                          -73.574898,
                          45.548103,
                          0
                      ],
                      [
                          -73.595926,
                          45.554438,
                          0
                      ],
                      [
                          -73.596376,
                          45.554595,
                          0
                      ],
                      [
                          -73.596359,
                          45.554614,
                          0
                      ],
                      [
                          -73.58629,
                          45.566149,
                          0
                      ],
                      [
                          -73.587116,
                          45.566405,
                          0
                      ],
                      [
                          -73.577255,
                          45.577665,
                          0
                      ],
                      [
                          -73.578122,
                          45.578065,
                          0
                      ],
                      [
                          -73.576772,
                          45.579314,
                          0
                      ],
                      [
                          -73.575244,
                          45.580524,
                          0
                      ],
                      [
                          -73.573972,
                          45.581919,
                          0
                      ],
                      [
                          -73.571022,
                          45.580922,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Rosemont",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.552136,
                          45.491192,
                          0
                      ],
                      [
                          -73.550829,
                          45.490417,
                          0
                      ],
                      [
                          -73.550022,
                          45.490142,
                          0
                      ],
                      [
                          -73.539979,
                          45.488073,
                          0
                      ],
                      [
                          -73.53994,
                          45.48806,
                          0
                      ],
                      [
                          -73.539937,
                          45.488059,
                          0
                      ],
                      [
                          -73.529675,
                          45.49157,
                          0
                      ],
                      [
                          -73.523292,
                          45.478191,
                          0
                      ],
                      [
                          -73.540941,
                          45.477121,
                          0
                      ],
                      [
                          -73.551733,
                          45.471502,
                          0
                      ],
                      [
                          -73.551735,
                          45.471501,
                          0
                      ],
                      [
                          -73.551739,
                          45.471499,
                          0
                      ],
                      [
                          -73.553331,
                          45.470597,
                          0
                      ],
                      [
                          -73.55529,
                          45.470165,
                          0
                      ],
                      [
                          -73.556119,
                          45.469944,
                          0
                      ],
                      [
                          -73.556269,
                          45.469785,
                          0
                      ],
                      [
                          -73.559881,
                          45.47109,
                          0
                      ],
                      [
                          -73.569071,
                          45.474482,
                          0
                      ],
                      [
                          -73.569692,
                          45.47466,
                          0
                      ],
                      [
                          -73.572228,
                          45.474325,
                          0
                      ],
                      [
                          -73.571337,
                          45.472514,
                          0
                      ],
                      [
                          -73.571609,
                          45.472274,
                          0
                      ],
                      [
                          -73.572247,
                          45.466395,
                          0
                      ],
                      [
                          -73.572435,
                          45.466334,
                          0
                      ],
                      [
                          -73.573227,
                          45.466318,
                          0
                      ],
                      [
                          -73.576084,
                          45.466443,
                          0
                      ],
                      [
                          -73.575969,
                          45.46773,
                          0
                      ],
                      [
                          -73.577457,
                          45.467795,
                          0
                      ],
                      [
                          -73.577696,
                          45.467531,
                          0
                      ],
                      [
                          -73.578764,
                          45.467578,
                          0
                      ],
                      [
                          -73.578861,
                          45.466483,
                          0
                      ],
                      [
                          -73.580727,
                          45.466565,
                          0
                      ],
                      [
                          -73.580963,
                          45.465456,
                          0
                      ],
                      [
                          -73.581085,
                          45.462655,
                          0
                      ],
                      [
                          -73.58067,
                          45.460666,
                          0
                      ],
                      [
                          -73.580757,
                          45.458297,
                          0
                      ],
                      [
                          -73.58109,
                          45.457016,
                          0
                      ],
                      [
                          -73.582019,
                          45.455195,
                          0
                      ],
                      [
                          -73.583015,
                          45.453952,
                          0
                      ],
                      [
                          -73.583697,
                          45.453279,
                          0
                      ],
                      [
                          -73.585417,
                          45.451981,
                          0
                      ],
                      [
                          -73.587191,
                          45.450415,
                          0
                      ],
                      [
                          -73.588889,
                          45.448767,
                          0
                      ],
                      [
                          -73.591224,
                          45.446738,
                          0
                      ],
                      [
                          -73.5926,
                          45.445301,
                          0
                      ],
                      [
                          -73.594762,
                          45.443072,
                          0
                      ],
                      [
                          -73.599502,
                          45.437522,
                          0
                      ],
                      [
                          -73.611752,
                          45.439474,
                          0
                      ],
                      [
                          -73.610405,
                          45.443258,
                          0
                      ],
                      [
                          -73.6101,
                          45.44369,
                          0
                      ],
                      [
                          -73.605012,
                          45.448219,
                          0
                      ],
                      [
                          -73.60477,
                          45.448715,
                          0
                      ],
                      [
                          -73.605007,
                          45.44938,
                          0
                      ],
                      [
                          -73.606829,
                          45.454209,
                          0
                      ],
                      [
                          -73.613434,
                          45.457523,
                          0
                      ],
                      [
                          -73.616499,
                          45.456181,
                          0
                      ],
                      [
                          -73.621321,
                          45.453974,
                          0
                      ],
                      [
                          -73.624717,
                          45.451613,
                          0
                      ],
                      [
                          -73.626312,
                          45.450469,
                          0
                      ],
                      [
                          -73.628558,
                          45.448253,
                          0
                      ],
                      [
                          -73.629103,
                          45.448461,
                          0
                      ],
                      [
                          -73.629145,
                          45.448425,
                          0
                      ],
                      [
                          -73.631966,
                          45.449508,
                          0
                      ],
                      [
                          -73.635231,
                          45.450751,
                          0
                      ],
                      [
                          -73.63275,
                          45.45234,
                          0
                      ],
                      [
                          -73.631993,
                          45.452685,
                          0
                      ],
                      [
                          -73.63147,
                          45.452947,
                          0
                      ],
                      [
                          -73.63133,
                          45.453118,
                          0
                      ],
                      [
                          -73.631497,
                          45.453351,
                          0
                      ],
                      [
                          -73.631484,
                          45.453423,
                          0
                      ],
                      [
                          -73.631356,
                          45.453495,
                          0
                      ],
                      [
                          -73.631174,
                          45.4537,
                          0
                      ],
                      [
                          -73.630438,
                          45.454522,
                          0
                      ],
                      [
                          -73.629482,
                          45.455297,
                          0
                      ],
                      [
                          -73.629239,
                          45.455648,
                          0
                      ],
                      [
                          -73.628754,
                          45.455829,
                          0
                      ],
                      [
                          -73.627553,
                          45.456631,
                          0
                      ],
                      [
                          -73.62675,
                          45.457397,
                          0
                      ],
                      [
                          -73.626559,
                          45.457829,
                          0
                      ],
                      [
                          -73.625793,
                          45.458415,
                          0
                      ],
                      [
                          -73.625244,
                          45.458775,
                          0
                      ],
                      [
                          -73.624646,
                          45.458919,
                          0
                      ],
                      [
                          -73.62403,
                          45.459227,
                          0
                      ],
                      [
                          -73.623865,
                          45.459308,
                          0
                      ],
                      [
                          -73.623865,
                          45.459308,
                          0
                      ],
                      [
                          -73.623883,
                          45.459462,
                          0
                      ],
                      [
                          -73.623711,
                          45.459542,
                          0
                      ],
                      [
                          -73.623252,
                          45.459956,
                          0
                      ],
                      [
                          -73.622971,
                          45.460227,
                          0
                      ],
                      [
                          -73.622705,
                          45.460329,
                          0
                      ],
                      [
                          -73.622206,
                          45.460713,
                          0
                      ],
                      [
                          -73.621669,
                          45.460984,
                          0
                      ],
                      [
                          -73.621018,
                          45.461426,
                          0
                      ],
                      [
                          -73.620558,
                          45.461651,
                          0
                      ],
                      [
                          -73.620405,
                          45.461732,
                          0
                      ],
                      [
                          -73.620379,
                          45.461787,
                          0
                      ],
                      [
                          -73.620434,
                          45.461871,
                          0
                      ],
                      [
                          -73.620749,
                          45.462028,
                          0
                      ],
                      [
                          -73.62075,
                          45.462028,
                          0
                      ],
                      [
                          -73.620981,
                          45.462155,
                          0
                      ],
                      [
                          -73.621058,
                          45.462208,
                          0
                      ],
                      [
                          -73.62107,
                          45.46227,
                          0
                      ],
                      [
                          -73.62107,
                          45.462272,
                          0
                      ],
                      [
                          -73.620636,
                          45.462137,
                          0
                      ],
                      [
                          -73.620047,
                          45.462129,
                          0
                      ],
                      [
                          -73.619497,
                          45.461877,
                          0
                      ],
                      [
                          -73.618991,
                          45.461979,
                          0
                      ],
                      [
                          -73.618282,
                          45.462247,
                          0
                      ],
                      [
                          -73.617657,
                          45.462698,
                          0
                      ],
                      [
                          -73.617656,
                          45.462699,
                          0
                      ],
                      [
                          -73.616955,
                          45.462987,
                          0
                      ],
                      [
                          -73.616443,
                          45.463203,
                          0
                      ],
                      [
                          -73.615497,
                          45.463375,
                          0
                      ],
                      [
                          -73.615089,
                          45.463547,
                          0
                      ],
                      [
                          -73.614454,
                          45.463984,
                          0
                      ],
                      [
                          -73.613785,
                          45.464277,
                          0
                      ],
                      [
                          -73.613095,
                          45.46443,
                          0
                      ],
                      [
                          -73.61309,
                          45.464433,
                          0
                      ],
                      [
                          -73.612802,
                          45.464611,
                          0
                      ],
                      [
                          -73.611984,
                          45.464791,
                          0
                      ],
                      [
                          -73.611498,
                          45.465026,
                          0
                      ],
                      [
                          -73.610987,
                          45.465242,
                          0
                      ],
                      [
                          -73.610412,
                          45.465549,
                          0
                      ],
                      [
                          -73.609607,
                          45.465927,
                          0
                      ],
                      [
                          -73.609161,
                          45.46618,
                          0
                      ],
                      [
                          -73.608573,
                          45.466495,
                          0
                      ],
                      [
                          -73.608061,
                          45.466846,
                          0
                      ],
                      [
                          -73.608058,
                          45.466847,
                          0
                      ],
                      [
                          -73.607793,
                          45.466873,
                          0
                      ],
                      [
                          -73.606911,
                          45.467135,
                          0
                      ],
                      [
                          -73.606502,
                          45.467433,
                          0
                      ],
                      [
                          -73.606208,
                          45.467596,
                          0
                      ],
                      [
                          -73.606017,
                          45.467721,
                          0
                      ],
                      [
                          -73.605442,
                          45.468028,
                          0
                      ],
                      [
                          -73.605187,
                          45.468207,
                          0
                      ],
                      [
                          -73.605104,
                          45.468323,
                          0
                      ],
                      [
                          -73.604817,
                          45.468505,
                          0
                      ],
                      [
                          -73.604575,
                          45.468821,
                          0
                      ],
                      [
                          -73.604535,
                          45.46892,
                          0
                      ],
                      [
                          -73.604089,
                          45.469036,
                          0
                      ],
                      [
                          -73.603526,
                          45.469316,
                          0
                      ],
                      [
                          -73.602154,
                          45.469378,
                          0
                      ],
                      [
                          -73.602153,
                          45.469378,
                          0
                      ],
                      [
                          -73.599835,
                          45.47138,
                          0
                      ],
                      [
                          -73.598467,
                          45.47221,
                          0
                      ],
                      [
                          -73.597521,
                          45.47275,
                          0
                      ],
                      [
                          -73.596543,
                          45.473243,
                          0
                      ],
                      [
                          -73.595163,
                          45.476427,
                          0
                      ],
                      [
                          -73.590635,
                          45.479576,
                          0
                      ],
                      [
                          -73.586657,
                          45.482392,
                          0
                      ],
                      [
                          -73.586271,
                          45.482525,
                          0
                      ],
                      [
                          -73.585643,
                          45.482447,
                          0
                      ],
                      [
                          -73.585132,
                          45.482208,
                          0
                      ],
                      [
                          -73.584351,
                          45.483045,
                          0
                      ],
                      [
                          -73.580609,
                          45.485568,
                          0
                      ],
                      [
                          -73.581551,
                          45.486743,
                          0
                      ],
                      [
                          -73.581551,
                          45.486746,
                          0
                      ],
                      [
                          -73.576957,
                          45.490057,
                          0
                      ],
                      [
                          -73.573046,
                          45.492894,
                          0
                      ],
                      [
                          -73.567213,
                          45.490107,
                          0
                      ],
                      [
                          -73.564805,
                          45.492937,
                          0
                      ],
                      [
                          -73.563038,
                          45.495444,
                          0
                      ],
                      [
                          -73.561343,
                          45.4983,
                          0
                      ],
                      [
                          -73.558269,
                          45.496934,
                          0
                      ],
                      [
                          -73.555705,
                          45.495932,
                          0
                      ],
                      [
                          -73.555134,
                          45.495539,
                          0
                      ],
                      [
                          -73.554824,
                          45.495242,
                          0
                      ],
                      [
                          -73.554329,
                          45.494144,
                          0
                      ],
                      [
                          -73.553902,
                          45.493196,
                          0
                      ],
                      [
                          -73.553897,
                          45.493187,
                          0
                      ],
                      [
                          -73.553456,
                          45.492246,
                          0
                      ],
                      [
                          -73.552136,
                          45.491192,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Saint-Henri-Sainte-Anne",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -72.785517,
                          45.816828,
                          0
                      ],
                      [
                          -72.75918,
                          45.797534,
                          0
                      ],
                      [
                          -72.75918,
                          45.797534,
                          0
                      ],
                      [
                          -72.7592,
                          45.797521,
                          0
                      ],
                      [
                          -72.795189,
                          45.772727,
                          0
                      ],
                      [
                          -72.771736,
                          45.756173,
                          0
                      ],
                      [
                          -72.80365,
                          45.733876,
                          0
                      ],
                      [
                          -72.803689,
                          45.733848,
                          0
                      ],
                      [
                          -72.706389,
                          45.666779,
                          0
                      ],
                      [
                          -72.732454,
                          45.590959,
                          0
                      ],
                      [
                          -72.7709,
                          45.617638,
                          0
                      ],
                      [
                          -72.770945,
                          45.617669,
                          0
                      ],
                      [
                          -72.770949,
                          45.617671,
                          0
                      ],
                      [
                          -72.810457,
                          45.502258,
                          0
                      ],
                      [
                          -72.810455,
                          45.502226,
                          0
                      ],
                      [
                          -72.810455,
                          45.502222,
                          0
                      ],
                      [
                          -72.810455,
                          45.502222,
                          0
                      ],
                      [
                          -72.810457,
                          45.502212,
                          0
                      ],
                      [
                          -72.81062,
                          45.501705,
                          0
                      ],
                      [
                          -72.811087,
                          45.500082,
                          0
                      ],
                      [
                          -72.811039,
                          45.499651,
                          0
                      ],
                      [
                          -72.816935,
                          45.4725,
                          0
                      ],
                      [
                          -72.837448,
                          45.47556,
                          0
                      ],
                      [
                          -72.838466,
                          45.474004,
                          0
                      ],
                      [
                          -72.85199,
                          45.476704,
                          0
                      ],
                      [
                          -72.85901,
                          45.477826,
                          0
                      ],
                      [
                          -72.863246,
                          45.477642,
                          0
                      ],
                      [
                          -72.866813,
                          45.476865,
                          0
                      ],
                      [
                          -72.871773,
                          45.475817,
                          0
                      ],
                      [
                          -72.875479,
                          45.474037,
                          0
                      ],
                      [
                          -72.880171,
                          45.468945,
                          0
                      ],
                      [
                          -72.880185,
                          45.468945,
                          0
                      ],
                      [
                          -72.880248,
                          45.468916,
                          0
                      ],
                      [
                          -72.883836,
                          45.469767,
                          0
                      ],
                      [
                          -72.883856,
                          45.469901,
                          0
                      ],
                      [
                          -72.896613,
                          45.479164,
                          0
                      ],
                      [
                          -72.896619,
                          45.479168,
                          0
                      ],
                      [
                          -72.90435,
                          45.47161,
                          0
                      ],
                      [
                          -72.903909,
                          45.470541,
                          0
                      ],
                      [
                          -72.905657,
                          45.464023,
                          0
                      ],
                      [
                          -72.92169,
                          45.466486,
                          0
                      ],
                      [
                          -72.925684,
                          45.452554,
                          0
                      ],
                      [
                          -72.943105,
                          45.454995,
                          0
                      ],
                      [
                          -72.943281,
                          45.45437,
                          0
                      ],
                      [
                          -72.964965,
                          45.457444,
                          0
                      ],
                      [
                          -72.964982,
                          45.457447,
                          0
                      ],
                      [
                          -72.962564,
                          45.459175,
                          0
                      ],
                      [
                          -72.962545,
                          45.459187,
                          0
                      ],
                      [
                          -72.961908,
                          45.462057,
                          0
                      ],
                      [
                          -72.962699,
                          45.46903,
                          0
                      ],
                      [
                          -72.958067,
                          45.474978,
                          0
                      ],
                      [
                          -72.958065,
                          45.47498,
                          0
                      ],
                      [
                          -72.95806,
                          45.474997,
                          0
                      ],
                      [
                          -72.958109,
                          45.475002,
                          0
                      ],
                      [
                          -73.003532,
                          45.479254,
                          0
                      ],
                      [
                          -73.007854,
                          45.474298,
                          0
                      ],
                      [
                          -73.007856,
                          45.474299,
                          0
                      ],
                      [
                          -73.033714,
                          45.491029,
                          0
                      ],
                      [
                          -73.033752,
                          45.490934,
                          0
                      ],
                      [
                          -73.039117,
                          45.477403,
                          0
                      ],
                      [
                          -73.072756,
                          45.476043,
                          0
                      ],
                      [
                          -73.062567,
                          45.493854,
                          0
                      ],
                      [
                          -73.079017,
                          45.505799,
                          0
                      ],
                      [
                          -73.062104,
                          45.539389,
                          0
                      ],
                      [
                          -73.062083,
                          45.539433,
                          0
                      ],
                      [
                          -73.058648,
                          45.537577,
                          0
                      ],
                      [
                          -73.054829,
                          45.569099,
                          0
                      ],
                      [
                          -73.052425,
                          45.568518,
                          0
                      ],
                      [
                          -73.002388,
                          45.570008,
                          0
                      ],
                      [
                          -73.001262,
                          45.577826,
                          0
                      ],
                      [
                          -72.998488,
                          45.581447,
                          0
                      ],
                      [
                          -72.997735,
                          45.59214,
                          0
                      ],
                      [
                          -72.993648,
                          45.596392,
                          0
                      ],
                      [
                          -72.997926,
                          45.601477,
                          0
                      ],
                      [
                          -73.051129,
                          45.600538,
                          0
                      ],
                      [
                          -73.099234,
                          45.612075,
                          0
                      ],
                      [
                          -73.099236,
                          45.612075,
                          0
                      ],
                      [
                          -73.098805,
                          45.613642,
                          0
                      ],
                      [
                          -73.100963,
                          45.661789,
                          0
                      ],
                      [
                          -73.116888,
                          45.673672,
                          0
                      ],
                      [
                          -73.116427,
                          45.675477,
                          0
                      ],
                      [
                          -73.116942,
                          45.681071,
                          0
                      ],
                      [
                          -73.116424,
                          45.683885,
                          0
                      ],
                      [
                          -73.115541,
                          45.68907,
                          0
                      ],
                      [
                          -73.114888,
                          45.692878,
                          0
                      ],
                      [
                          -73.113993,
                          45.69473,
                          0
                      ],
                      [
                          -73.111837,
                          45.699239,
                          0
                      ],
                      [
                          -73.111735,
                          45.699467,
                          0
                      ],
                      [
                          -73.10613,
                          45.711325,
                          0
                      ],
                      [
                          -73.105058,
                          45.713577,
                          0
                      ],
                      [
                          -73.103956,
                          45.715001,
                          0
                      ],
                      [
                          -73.100884,
                          45.717455,
                          0
                      ],
                      [
                          -73.09442,
                          45.722634,
                          0
                      ],
                      [
                          -73.089343,
                          45.729375,
                          0
                      ],
                      [
                          -73.080578,
                          45.722869,
                          0
                      ],
                      [
                          -73.073928,
                          45.717807,
                          0
                      ],
                      [
                          -73.05944,
                          45.747461,
                          0
                      ],
                      [
                          -73.053589,
                          45.759381,
                          0
                      ],
                      [
                          -73.053453,
                          45.759574,
                          0
                      ],
                      [
                          -73.001559,
                          45.721516,
                          0
                      ],
                      [
                          -73.001556,
                          45.721517,
                          0
                      ],
                      [
                          -72.953259,
                          45.753207,
                          0
                      ],
                      [
                          -72.953259,
                          45.753207,
                          0
                      ],
                      [
                          -72.953259,
                          45.753207,
                          0
                      ],
                      [
                          -72.953591,
                          45.753461,
                          0
                      ],
                      [
                          -72.953594,
                          45.753463,
                          0
                      ],
                      [
                          -72.953596,
                          45.753464,
                          0
                      ],
                      [
                          -72.943722,
                          45.77642,
                          0
                      ],
                      [
                          -72.94422,
                          45.776804,
                          0
                      ],
                      [
                          -72.936967,
                          45.794275,
                          0
                      ],
                      [
                          -72.942175,
                          45.798273,
                          0
                      ],
                      [
                          -72.942175,
                          45.798274,
                          0
                      ],
                      [
                          -72.932143,
                          45.814708,
                          0
                      ],
                      [
                          -72.932585,
                          45.815246,
                          0
                      ],
                      [
                          -72.932585,
                          45.815246,
                          0
                      ],
                      [
                          -72.934704,
                          45.82031,
                          0
                      ],
                      [
                          -72.934712,
                          45.820317,
                          0
                      ],
                      [
                          -72.934716,
                          45.82032,
                          0
                      ],
                      [
                          -72.920518,
                          45.830422,
                          0
                      ],
                      [
                          -72.920508,
                          45.830429,
                          0
                      ],
                      [
                          -72.878127,
                          45.801278,
                          0
                      ],
                      [
                          -72.878125,
                          45.801282,
                          0
                      ],
                      [
                          -72.878109,
                          45.801324,
                          0
                      ],
                      [
                          -72.863831,
                          45.836963,
                          0
                      ],
                      [
                          -72.84943,
                          45.847265,
                          0
                      ],
                      [
                          -72.848368,
                          45.846531,
                          0
                      ],
                      [
                          -72.84836,
                          45.846537,
                          0
                      ],
                      [
                          -72.846745,
                          45.847561,
                          0
                      ],
                      [
                          -72.845629,
                          45.846802,
                          0
                      ],
                      [
                          -72.845625,
                          45.8468,
                          0
                      ],
                      [
                          -72.837065,
                          45.852815,
                          0
                      ],
                      [
                          -72.837018,
                          45.852847,
                          0
                      ],
                      [
                          -72.837018,
                          45.852847,
                          0
                      ],
                      [
                          -72.785517,
                          45.816828,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Saint-Hyacinthe",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.381241,
                          45.409018,
                          0
                      ],
                      [
                          -73.379364,
                          45.408197,
                          0
                      ],
                      [
                          -73.379361,
                          45.408194,
                          0
                      ],
                      [
                          -73.348693,
                          45.386092,
                          0
                      ],
                      [
                          -73.34869,
                          45.386089,
                          0
                      ],
                      [
                          -73.347311,
                          45.389113,
                          0
                      ],
                      [
                          -73.347378,
                          45.390315,
                          0
                      ],
                      [
                          -73.347378,
                          45.390315,
                          0
                      ],
                      [
                          -73.338664,
                          45.385497,
                          0
                      ],
                      [
                          -73.326655,
                          45.382973,
                          0
                      ],
                      [
                          -73.323747,
                          45.38911,
                          0
                      ],
                      [
                          -73.323221,
                          45.39194,
                          0
                      ],
                      [
                          -73.323219,
                          45.391963,
                          0
                      ],
                      [
                          -73.312549,
                          45.40047,
                          0
                      ],
                      [
                          -73.308344,
                          45.406131,
                          0
                      ],
                      [
                          -73.308344,
                          45.406131,
                          0
                      ],
                      [
                          -73.274782,
                          45.382631,
                          0
                      ],
                      [
                          -73.257016,
                          45.382243,
                          0
                      ],
                      [
                          -73.25655,
                          45.382572,
                          0
                      ],
                      [
                          -73.256421,
                          45.382708,
                          0
                      ],
                      [
                          -73.256432,
                          45.382895,
                          0
                      ],
                      [
                          -73.256522,
                          45.383185,
                          0
                      ],
                      [
                          -73.256473,
                          45.383628,
                          0
                      ],
                      [
                          -73.255593,
                          45.383654,
                          0
                      ],
                      [
                          -73.255141,
                          45.384208,
                          0
                      ],
                      [
                          -73.25485,
                          45.384693,
                          0
                      ],
                      [
                          -73.254775,
                          45.384939,
                          0
                      ],
                      [
                          -73.252442,
                          45.385791,
                          0
                      ],
                      [
                          -73.251601,
                          45.38424,
                          0
                      ],
                      [
                          -73.247964,
                          45.378708,
                          0
                      ],
                      [
                          -73.247399,
                          45.370529,
                          0
                      ],
                      [
                          -73.253244,
                          45.35611,
                          0
                      ],
                      [
                          -73.261832,
                          45.34471,
                          0
                      ],
                      [
                          -73.2625,
                          45.340788,
                          0
                      ],
                      [
                          -73.26089,
                          45.33268,
                          0
                      ],
                      [
                          -73.258381,
                          45.323422,
                          0
                      ],
                      [
                          -73.250867,
                          45.316457,
                          0
                      ],
                      [
                          -73.246994,
                          45.308461,
                          0
                      ],
                      [
                          -73.247031,
                          45.302168,
                          0
                      ],
                      [
                          -73.247559,
                          45.290463,
                          0
                      ],
                      [
                          -73.244572,
                          45.27633,
                          0
                      ],
                      [
                          -73.244894,
                          45.258932,
                          0
                      ],
                      [
                          -73.244913,
                          45.258797,
                          0
                      ],
                      [
                          -73.245552,
                          45.25348,
                          0
                      ],
                      [
                          -73.246005,
                          45.245307,
                          0
                      ],
                      [
                          -73.246631,
                          45.230607,
                          0
                      ],
                      [
                          -73.253151,
                          45.213725,
                          0
                      ],
                      [
                          -73.254051,
                          45.205337,
                          0
                      ],
                      [
                          -73.255423,
                          45.192514,
                          0
                      ],
                      [
                          -73.255109,
                          45.184127,
                          0
                      ],
                      [
                          -73.286387,
                          45.183529,
                          0
                      ],
                      [
                          -73.286337,
                          45.177013,
                          0
                      ],
                      [
                          -73.291858,
                          45.172958,
                          0
                      ],
                      [
                          -73.287506,
                          45.169983,
                          0
                      ],
                      [
                          -73.301721,
                          45.159132,
                          0
                      ],
                      [
                          -73.30174,
                          45.159145,
                          0
                      ],
                      [
                          -73.373111,
                          45.206893,
                          0
                      ],
                      [
                          -73.373073,
                          45.206921,
                          0
                      ],
                      [
                          -73.373051,
                          45.206948,
                          0
                      ],
                      [
                          -73.373051,
                          45.206948,
                          0
                      ],
                      [
                          -73.373019,
                          45.206972,
                          0
                      ],
                      [
                          -73.380127,
                          45.211693,
                          0
                      ],
                      [
                          -73.380171,
                          45.211723,
                          0
                      ],
                      [
                          -73.365541,
                          45.222625,
                          0
                      ],
                      [
                          -73.366693,
                          45.223383,
                          0
                      ],
                      [
                          -73.352086,
                          45.234383,
                          0
                      ],
                      [
                          -73.385748,
                          45.257162,
                          0
                      ],
                      [
                          -73.384849,
                          45.258486,
                          0
                      ],
                      [
                          -73.383085,
                          45.259621,
                          0
                      ],
                      [
                          -73.411811,
                          45.254284,
                          0
                      ],
                      [
                          -73.411898,
                          45.254267,
                          0
                      ],
                      [
                          -73.400948,
                          45.26606,
                          0
                      ],
                      [
                          -73.389499,
                          45.268064,
                          0
                      ],
                      [
                          -73.389482,
                          45.268084,
                          0
                      ],
                      [
                          -73.389439,
                          45.268138,
                          0
                      ],
                      [
                          -73.379511,
                          45.280688,
                          0
                      ],
                      [
                          -73.379765,
                          45.282526,
                          0
                      ],
                      [
                          -73.37952,
                          45.284966,
                          0
                      ],
                      [
                          -73.379205,
                          45.285829,
                          0
                      ],
                      [
                          -73.381,
                          45.288645,
                          0
                      ],
                      [
                          -73.381001,
                          45.288645,
                          0
                      ],
                      [
                          -73.382715,
                          45.288349,
                          0
                      ],
                      [
                          -73.381721,
                          45.290189,
                          0
                      ],
                      [
                          -73.381918,
                          45.291713,
                          0
                      ],
                      [
                          -73.382019,
                          45.293555,
                          0
                      ],
                      [
                          -73.382436,
                          45.295136,
                          0
                      ],
                      [
                          -73.379738,
                          45.295624,
                          0
                      ],
                      [
                          -73.379606,
                          45.299247,
                          0
                      ],
                      [
                          -73.379846,
                          45.300248,
                          0
                      ],
                      [
                          -73.375447,
                          45.301078,
                          0
                      ],
                      [
                          -73.375874,
                          45.30263,
                          0
                      ],
                      [
                          -73.373506,
                          45.3031,
                          0
                      ],
                      [
                          -73.374123,
                          45.304653,
                          0
                      ],
                      [
                          -73.374125,
                          45.304656,
                          0
                      ],
                      [
                          -73.370997,
                          45.305221,
                          0
                      ],
                      [
                          -73.371479,
                          45.306835,
                          0
                      ],
                      [
                          -73.371472,
                          45.306844,
                          0
                      ],
                      [
                          -73.369498,
                          45.30966,
                          0
                      ],
                      [
                          -73.369744,
                          45.311796,
                          0
                      ],
                      [
                          -73.411239,
                          45.330215,
                          0
                      ],
                      [
                          -73.410796,
                          45.331899,
                          0
                      ],
                      [
                          -73.410795,
                          45.331905,
                          0
                      ],
                      [
                          -73.410775,
                          45.331909,
                          0
                      ],
                      [
                          -73.409658,
                          45.332102,
                          0
                      ],
                      [
                          -73.409609,
                          45.332111,
                          0
                      ],
                      [
                          -73.409202,
                          45.33299,
                          0
                      ],
                      [
                          -73.409199,
                          45.332994,
                          0
                      ],
                      [
                          -73.409194,
                          45.333002,
                          0
                      ],
                      [
                          -73.406835,
                          45.336088,
                          0
                      ],
                      [
                          -73.404546,
                          45.338878,
                          0
                      ],
                      [
                          -73.40785,
                          45.340365,
                          0
                      ],
                      [
                          -73.408008,
                          45.340991,
                          0
                      ],
                      [
                          -73.405888,
                          45.341961,
                          0
                      ],
                      [
                          -73.405548,
                          45.344942,
                          0
                      ],
                      [
                          -73.405846,
                          45.346353,
                          0
                      ],
                      [
                          -73.405771,
                          45.346373,
                          0
                      ],
                      [
                          -73.405201,
                          45.346613,
                          0
                      ],
                      [
                          -73.402812,
                          45.348132,
                          0
                      ],
                      [
                          -73.402756,
                          45.348141,
                          0
                      ],
                      [
                          -73.393271,
                          45.349904,
                          0
                      ],
                      [
                          -73.389216,
                          45.354653,
                          0
                      ],
                      [
                          -73.387703,
                          45.357708,
                          0
                      ],
                      [
                          -73.393642,
                          45.360315,
                          0
                      ],
                      [
                          -73.392592,
                          45.363631,
                          0
                      ],
                      [
                          -73.393677,
                          45.366926,
                          0
                      ],
                      [
                          -73.394713,
                          45.368433,
                          0
                      ],
                      [
                          -73.395517,
                          45.373068,
                          0
                      ],
                      [
                          -73.397815,
                          45.373294,
                          0
                      ],
                      [
                          -73.395725,
                          45.376472,
                          0
                      ],
                      [
                          -73.391377,
                          45.380757,
                          0
                      ],
                      [
                          -73.388709,
                          45.383,
                          0
                      ],
                      [
                          -73.386976,
                          45.387566,
                          0
                      ],
                      [
                          -73.385765,
                          45.395067,
                          0
                      ],
                      [
                          -73.385577,
                          45.398319,
                          0
                      ],
                      [
                          -73.386147,
                          45.399926,
                          0
                      ],
                      [
                          -73.383101,
                          45.399705,
                          0
                      ],
                      [
                          -73.382206,
                          45.399075,
                          0
                      ],
                      [
                          -73.381123,
                          45.399739,
                          0
                      ],
                      [
                          -73.381124,
                          45.39974,
                          0
                      ],
                      [
                          -73.381889,
                          45.400337,
                          0
                      ],
                      [
                          -73.380122,
                          45.401304,
                          0
                      ],
                      [
                          -73.380129,
                          45.401304,
                          0
                      ],
                      [
                          -73.380634,
                          45.401326,
                          0
                      ],
                      [
                          -73.380638,
                          45.401328,
                          0
                      ],
                      [
                          -73.392993,
                          45.410534,
                          0
                      ],
                      [
                          -73.392991,
                          45.410535,
                          0
                      ],
                      [
                          -73.392982,
                          45.410534,
                          0
                      ],
                      [
                          -73.385305,
                          45.410233,
                          0
                      ],
                      [
                          -73.386018,
                          45.412414,
                          0
                      ],
                      [
                          -73.381241,
                          45.409018,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Saint-Jean",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -74.058855,
                          45.831985,
                          0
                      ],
                      [
                          -74.057092,
                          45.830131,
                          0
                      ],
                      [
                          -74.056203,
                          45.8262,
                          0
                      ],
                      [
                          -74.055732,
                          45.823884,
                          0
                      ],
                      [
                          -74.052283,
                          45.820369,
                          0
                      ],
                      [
                          -74.050036,
                          45.818167,
                          0
                      ],
                      [
                          -74.050032,
                          45.818158,
                          0
                      ],
                      [
                          -74.050025,
                          45.818158,
                          0
                      ],
                      [
                          -74.043118,
                          45.818907,
                          0
                      ],
                      [
                          -74.026617,
                          45.820683,
                          0
                      ],
                      [
                          -74.026617,
                          45.820683,
                          0
                      ],
                      [
                          -74.024177,
                          45.818717,
                          0
                      ],
                      [
                          -74.022123,
                          45.824374,
                          0
                      ],
                      [
                          -74.022129,
                          45.824374,
                          0
                      ],
                      [
                          -74.02571,
                          45.82397,
                          0
                      ],
                      [
                          -74.026249,
                          45.82546,
                          0
                      ],
                      [
                          -74.031526,
                          45.824828,
                          0
                      ],
                      [
                          -74.031914,
                          45.826383,
                          0
                      ],
                      [
                          -74.032968,
                          45.826292,
                          0
                      ],
                      [
                          -74.033842,
                          45.828535,
                          0
                      ],
                      [
                          -74.029725,
                          45.829234,
                          0
                      ],
                      [
                          -74.030462,
                          45.831398,
                          0
                      ],
                      [
                          -74.028521,
                          45.831804,
                          0
                      ],
                      [
                          -74.029115,
                          45.833323,
                          0
                      ],
                      [
                          -74.030518,
                          45.833065,
                          0
                      ],
                      [
                          -74.030746,
                          45.834657,
                          0
                      ],
                      [
                          -74.034341,
                          45.833859,
                          0
                      ],
                      [
                          -74.036356,
                          45.835072,
                          0
                      ],
                      [
                          -74.038268,
                          45.837254,
                          0
                      ],
                      [
                          -74.023089,
                          45.840809,
                          0
                      ],
                      [
                          -74.023265,
                          45.841551,
                          0
                      ],
                      [
                          -74.023257,
                          45.841548,
                          0
                      ],
                      [
                          -74.023248,
                          45.841545,
                          0
                      ],
                      [
                          -74.023242,
                          45.841543,
                          0
                      ],
                      [
                          -73.947658,
                          45.789491,
                          0
                      ],
                      [
                          -73.960669,
                          45.779845,
                          0
                      ],
                      [
                          -73.959698,
                          45.779206,
                          0
                      ],
                      [
                          -73.966081,
                          45.77471,
                          0
                      ],
                      [
                          -73.972629,
                          45.768167,
                          0
                      ],
                      [
                          -73.972623,
                          45.768163,
                          0
                      ],
                      [
                          -73.97263,
                          45.768157,
                          0
                      ],
                      [
                          -73.972641,
                          45.768145,
                          0
                      ],
                      [
                          -73.96774,
                          45.76492,
                          0
                      ],
                      [
                          -73.968914,
                          45.764946,
                          0
                      ],
                      [
                          -73.96955,
                          45.765415,
                          0
                      ],
                      [
                          -73.970827,
                          45.765526,
                          0
                      ],
                      [
                          -73.970972,
                          45.765615,
                          0
                      ],
                      [
                          -73.971084,
                          45.765583,
                          0
                      ],
                      [
                          -73.971256,
                          45.76536,
                          0
                      ],
                      [
                          -73.971946,
                          45.765551,
                          0
                      ],
                      [
                          -73.972107,
                          45.765324,
                          0
                      ],
                      [
                          -73.972629,
                          45.765083,
                          0
                      ],
                      [
                          -73.973144,
                          45.765197,
                          0
                      ],
                      [
                          -73.97332,
                          45.765147,
                          0
                      ],
                      [
                          -73.973311,
                          45.764857,
                          0
                      ],
                      [
                          -73.973697,
                          45.764773,
                          0
                      ],
                      [
                          -73.974747,
                          45.764885,
                          0
                      ],
                      [
                          -73.97497,
                          45.764697,
                          0
                      ],
                      [
                          -73.975205,
                          45.764581,
                          0
                      ],
                      [
                          -73.975065,
                          45.764303,
                          0
                      ],
                      [
                          -73.975834,
                          45.76434,
                          0
                      ],
                      [
                          -73.976084,
                          45.763843,
                          0
                      ],
                      [
                          -73.968118,
                          45.75871,
                          0
                      ],
                      [
                          -73.979383,
                          45.7504,
                          0
                      ],
                      [
                          -73.976274,
                          45.748083,
                          0
                      ],
                      [
                          -73.981057,
                          45.74444,
                          0
                      ],
                      [
                          -73.997978,
                          45.755164,
                          0
                      ],
                      [
                          -74.000582,
                          45.752396,
                          0
                      ],
                      [
                          -74.000737,
                          45.748931,
                          0
                      ],
                      [
                          -74.002047,
                          45.748551,
                          0
                      ],
                      [
                          -74.004137,
                          45.748807,
                          0
                      ],
                      [
                          -74.005722,
                          45.749353,
                          0
                      ],
                      [
                          -74.0083,
                          45.751292,
                          0
                      ],
                      [
                          -74.011177,
                          45.75264,
                          0
                      ],
                      [
                          -74.014086,
                          45.753336,
                          0
                      ],
                      [
                          -74.015305,
                          45.753235,
                          0
                      ],
                      [
                          -74.017371,
                          45.751153,
                          0
                      ],
                      [
                          -74.024171,
                          45.747445,
                          0
                      ],
                      [
                          -74.026006,
                          45.745126,
                          0
                      ],
                      [
                          -74.027537,
                          45.743575,
                          0
                      ],
                      [
                          -74.030294,
                          45.743592,
                          0
                      ],
                      [
                          -74.05211,
                          45.757823,
                          0
                      ],
                      [
                          -74.039016,
                          45.763668,
                          0
                      ],
                      [
                          -74.121414,
                          45.781293,
                          0
                      ],
                      [
                          -74.1062,
                          45.783037,
                          0
                      ],
                      [
                          -74.105049,
                          45.78517,
                          0
                      ],
                      [
                          -74.105585,
                          45.787203,
                          0
                      ],
                      [
                          -74.106506,
                          45.790596,
                          0
                      ],
                      [
                          -74.109752,
                          45.793198,
                          0
                      ],
                      [
                          -74.109479,
                          45.794284,
                          0
                      ],
                      [
                          -74.111926,
                          45.795623,
                          0
                      ],
                      [
                          -74.112298,
                          45.796729,
                          0
                      ],
                      [
                          -74.115567,
                          45.798225,
                          0
                      ],
                      [
                          -74.116207,
                          45.799781,
                          0
                      ],
                      [
                          -74.121432,
                          45.800722,
                          0
                      ],
                      [
                          -74.12286,
                          45.802648,
                          0
                      ],
                      [
                          -74.110823,
                          45.803792,
                          0
                      ],
                      [
                          -74.113201,
                          45.806799,
                          0
                      ],
                      [
                          -74.113962,
                          45.808073,
                          0
                      ],
                      [
                          -74.11429,
                          45.809289,
                          0
                      ],
                      [
                          -74.115751,
                          45.810229,
                          0
                      ],
                      [
                          -74.118973,
                          45.809651,
                          0
                      ],
                      [
                          -74.12257,
                          45.811486,
                          0
                      ],
                      [
                          -74.143336,
                          45.819718,
                          0
                      ],
                      [
                          -74.126088,
                          45.831474,
                          0
                      ],
                      [
                          -74.123812,
                          45.831746,
                          0
                      ],
                      [
                          -74.123503,
                          45.830372,
                          0
                      ],
                      [
                          -74.108541,
                          45.832016,
                          0
                      ],
                      [
                          -74.106182,
                          45.828607,
                          0
                      ],
                      [
                          -74.106151,
                          45.828562,
                          0
                      ],
                      [
                          -74.09076,
                          45.830617,
                          0
                      ],
                      [
                          -74.091287,
                          45.831315,
                          0
                      ],
                      [
                          -74.093328,
                          45.831066,
                          0
                      ],
                      [
                          -74.094392,
                          45.834104,
                          0
                      ],
                      [
                          -74.096353,
                          45.837052,
                          0
                      ],
                      [
                          -74.094169,
                          45.837296,
                          0
                      ],
                      [
                          -74.094174,
                          45.837322,
                          0
                      ],
                      [
                          -74.094547,
                          45.838942,
                          0
                      ],
                      [
                          -74.093505,
                          45.83906,
                          0
                      ],
                      [
                          -74.093744,
                          45.840099,
                          0
                      ],
                      [
                          -74.089252,
                          45.840605,
                          0
                      ],
                      [
                          -74.090722,
                          45.847406,
                          0
                      ],
                      [
                          -74.090722,
                          45.847407,
                          0
                      ],
                      [
                          -74.067678,
                          45.850396,
                          0
                      ],
                      [
                          -74.067181,
                          45.850073,
                          0
                      ],
                      [
                          -74.066233,
                          45.848785,
                          0
                      ],
                      [
                          -74.064798,
                          45.84618,
                          0
                      ],
                      [
                          -74.06376,
                          45.844542,
                          0
                      ],
                      [
                          -74.063755,
                          45.844476,
                          0
                      ],
                      [
                          -74.064462,
                          45.841041,
                          0
                      ],
                      [
                          -74.06448,
                          45.840931,
                          0
                      ],
                      [
                          -74.064739,
                          45.837876,
                          0
                      ],
                      [
                          -74.061775,
                          45.834697,
                          0
                      ],
                      [
                          -74.058855,
                          45.831985,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Saint-Jérôme",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.68699,
                          45.530156,
                          0
                      ],
                      [
                          -73.684709,
                          45.530497,
                          0
                      ],
                      [
                          -73.68498,
                          45.530162,
                          0
                      ],
                      [
                          -73.686937,
                          45.528802,
                          0
                      ],
                      [
                          -73.689925,
                          45.528973,
                          0
                      ],
                      [
                          -73.694147,
                          45.528471,
                          0
                      ],
                      [
                          -73.698457,
                          45.527051,
                          0
                      ],
                      [
                          -73.680621,
                          45.515798,
                          0
                      ],
                      [
                          -73.679991,
                          45.516247,
                          0
                      ],
                      [
                          -73.672167,
                          45.511416,
                          0
                      ],
                      [
                          -73.66858,
                          45.509705,
                          0
                      ],
                      [
                          -73.662468,
                          45.507752,
                          0
                      ],
                      [
                          -73.664113,
                          45.505092,
                          0
                      ],
                      [
                          -73.6654,
                          45.503852,
                          0
                      ],
                      [
                          -73.667909,
                          45.501743,
                          0
                      ],
                      [
                          -73.681211,
                          45.491246,
                          0
                      ],
                      [
                          -73.682323,
                          45.490718,
                          0
                      ],
                      [
                          -73.685836,
                          45.489457,
                          0
                      ],
                      [
                          -73.677211,
                          45.483689,
                          0
                      ],
                      [
                          -73.677211,
                          45.483689,
                          0
                      ],
                      [
                          -73.679226,
                          45.47624,
                          0
                      ],
                      [
                          -73.682806,
                          45.463179,
                          0
                      ],
                      [
                          -73.693304,
                          45.470326,
                          0
                      ],
                      [
                          -73.694871,
                          45.464614,
                          0
                      ],
                      [
                          -73.722355,
                          45.482681,
                          0
                      ],
                      [
                          -73.750593,
                          45.46073,
                          0
                      ],
                      [
                          -73.762757,
                          45.468249,
                          0
                      ],
                      [
                          -73.762412,
                          45.468524,
                          0
                      ],
                      [
                          -73.76236,
                          45.468565,
                          0
                      ],
                      [
                          -73.768388,
                          45.474962,
                          0
                      ],
                      [
                          -73.766161,
                          45.47676,
                          0
                      ],
                      [
                          -73.7742,
                          45.481813,
                          0
                      ],
                      [
                          -73.768096,
                          45.484758,
                          0
                      ],
                      [
                          -73.768002,
                          45.485418,
                          0
                      ],
                      [
                          -73.766935,
                          45.485756,
                          0
                      ],
                      [
                          -73.766803,
                          45.486128,
                          0
                      ],
                      [
                          -73.766118,
                          45.486643,
                          0
                      ],
                      [
                          -73.766164,
                          45.48691,
                          0
                      ],
                      [
                          -73.766084,
                          45.486946,
                          0
                      ],
                      [
                          -73.766079,
                          45.487037,
                          0
                      ],
                      [
                          -73.766176,
                          45.487047,
                          0
                      ],
                      [
                          -73.766296,
                          45.486999,
                          0
                      ],
                      [
                          -73.766583,
                          45.48761,
                          0
                      ],
                      [
                          -73.766342,
                          45.487794,
                          0
                      ],
                      [
                          -73.766828,
                          45.487921,
                          0
                      ],
                      [
                          -73.766828,
                          45.487921,
                          0
                      ],
                      [
                          -73.76662,
                          45.488263,
                          0
                      ],
                      [
                          -73.766662,
                          45.488312,
                          0
                      ],
                      [
                          -73.76688,
                          45.488351,
                          0
                      ],
                      [
                          -73.766909,
                          45.488429,
                          0
                      ],
                      [
                          -73.766731,
                          45.488468,
                          0
                      ],
                      [
                          -73.766819,
                          45.488626,
                          0
                      ],
                      [
                          -73.767095,
                          45.488711,
                          0
                      ],
                      [
                          -73.767116,
                          45.488882,
                          0
                      ],
                      [
                          -73.766949,
                          45.48894,
                          0
                      ],
                      [
                          -73.766949,
                          45.48894,
                          0
                      ],
                      [
                          -73.767045,
                          45.48917,
                          0
                      ],
                      [
                          -73.764415,
                          45.490392,
                          0
                      ],
                      [
                          -73.768427,
                          45.494448,
                          0
                      ],
                      [
                          -73.769708,
                          45.495712,
                          0
                      ],
                      [
                          -73.773621,
                          45.498202,
                          0
                      ],
                      [
                          -73.771678,
                          45.49911,
                          0
                      ],
                      [
                          -73.773882,
                          45.50051,
                          0
                      ],
                      [
                          -73.770909,
                          45.501465,
                          0
                      ],
                      [
                          -73.772526,
                          45.502506,
                          0
                      ],
                      [
                          -73.772517,
                          45.502512,
                          0
                      ],
                      [
                          -73.769265,
                          45.503969,
                          0
                      ],
                      [
                          -73.769251,
                          45.503974,
                          0
                      ],
                      [
                          -73.76724,
                          45.502702,
                          0
                      ],
                      [
                          -73.765416,
                          45.503361,
                          0
                      ],
                      [
                          -73.764637,
                          45.502846,
                          0
                      ],
                      [
                          -73.763913,
                          45.503407,
                          0
                      ],
                      [
                          -73.760968,
                          45.501546,
                          0
                      ],
                      [
                          -73.759485,
                          45.502704,
                          0
                      ],
                      [
                          -73.758309,
                          45.501994,
                          0
                      ],
                      [
                          -73.754743,
                          45.503287,
                          0
                      ],
                      [
                          -73.7531,
                          45.503664,
                          0
                      ],
                      [
                          -73.752917,
                          45.503546,
                          0
                      ],
                      [
                          -73.751723,
                          45.504322,
                          0
                      ],
                      [
                          -73.763914,
                          45.511847,
                          0
                      ],
                      [
                          -73.75621,
                          45.517924,
                          0
                      ],
                      [
                          -73.749739,
                          45.519135,
                          0
                      ],
                      [
                          -73.745724,
                          45.521272,
                          0
                      ],
                      [
                          -73.742634,
                          45.525853,
                          0
                      ],
                      [
                          -73.736469,
                          45.530449,
                          0
                      ],
                      [
                          -73.729785,
                          45.532198,
                          0
                      ],
                      [
                          -73.729777,
                          45.532203,
                          0
                      ],
                      [
                          -73.727999,
                          45.533241,
                          0
                      ],
                      [
                          -73.725375,
                          45.53551,
                          0
                      ],
                      [
                          -73.719284,
                          45.538357,
                          0
                      ],
                      [
                          -73.712328,
                          45.540361,
                          0
                      ],
                      [
                          -73.709552,
                          45.538693,
                          0
                      ],
                      [
                          -73.707527,
                          45.537816,
                          0
                      ],
                      [
                          -73.704123,
                          45.537019,
                          0
                      ],
                      [
                          -73.70197,
                          45.536161,
                          0
                      ],
                      [
                          -73.69851,
                          45.534035,
                          0
                      ],
                      [
                          -73.693718,
                          45.531228,
                          0
                      ],
                      [
                          -73.692705,
                          45.530865,
                          0
                      ],
                      [
                          -73.690166,
                          45.530322,
                          0
                      ],
                      [
                          -73.68699,
                          45.530156,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Saint-Laurent",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.543048,
                          45.528937,
                          0
                      ],
                      [
                          -73.540715,
                          45.528103,
                          0
                      ],
                      [
                          -73.538986,
                          45.531499,
                          0
                      ],
                      [
                          -73.536549,
                          45.533647,
                          0
                      ],
                      [
                          -73.536525,
                          45.533661,
                          0
                      ],
                      [
                          -73.536525,
                          45.533661,
                          0
                      ],
                      [
                          -73.530887,
                          45.534675,
                          0
                      ],
                      [
                          -73.526005,
                          45.518958,
                          0
                      ],
                      [
                          -73.519235,
                          45.495194,
                          0
                      ],
                      [
                          -73.529675,
                          45.49157,
                          0
                      ],
                      [
                          -73.539937,
                          45.488059,
                          0
                      ],
                      [
                          -73.53994,
                          45.48806,
                          0
                      ],
                      [
                          -73.539979,
                          45.488073,
                          0
                      ],
                      [
                          -73.550022,
                          45.490142,
                          0
                      ],
                      [
                          -73.550829,
                          45.490417,
                          0
                      ],
                      [
                          -73.552136,
                          45.491192,
                          0
                      ],
                      [
                          -73.553456,
                          45.492246,
                          0
                      ],
                      [
                          -73.553897,
                          45.493187,
                          0
                      ],
                      [
                          -73.5533,
                          45.493401,
                          0
                      ],
                      [
                          -73.552622,
                          45.49585,
                          0
                      ],
                      [
                          -73.553078,
                          45.496065,
                          0
                      ],
                      [
                          -73.552949,
                          45.496305,
                          0
                      ],
                      [
                          -73.552882,
                          45.498737,
                          0
                      ],
                      [
                          -73.561632,
                          45.501723,
                          0
                      ],
                      [
                          -73.557259,
                          45.506872,
                          0
                      ],
                      [
                          -73.555052,
                          45.509874,
                          0
                      ],
                      [
                          -73.558995,
                          45.511696,
                          0
                      ],
                      [
                          -73.561587,
                          45.508827,
                          0
                      ],
                      [
                          -73.581696,
                          45.517994,
                          0
                      ],
                      [
                          -73.574679,
                          45.525562,
                          0
                      ],
                      [
                          -73.574005,
                          45.525793,
                          0
                      ],
                      [
                          -73.57368,
                          45.526171,
                          0
                      ],
                      [
                          -73.563005,
                          45.537405,
                          0
                      ],
                      [
                          -73.543048,
                          45.528937,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Sainte-Marie-Saint-Jacques",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.727958,
                          45.588883,
                          0
                      ],
                      [
                          -73.734863,
                          45.586068,
                          0
                      ],
                      [
                          -73.75109,
                          45.5759,
                          0
                      ],
                      [
                          -73.762936,
                          45.568342,
                          0
                      ],
                      [
                          -73.766858,
                          45.567001,
                          0
                      ],
                      [
                          -73.791662,
                          45.559667,
                          0
                      ],
                      [
                          -73.809076,
                          45.554569,
                          0
                      ],
                      [
                          -73.824122,
                          45.568392,
                          0
                      ],
                      [
                          -73.837682,
                          45.580845,
                          0
                      ],
                      [
                          -73.837682,
                          45.580845,
                          0
                      ],
                      [
                          -73.837663,
                          45.580861,
                          0
                      ],
                      [
                          -73.836037,
                          45.584322,
                          0
                      ],
                      [
                          -73.832551,
                          45.586534,
                          0
                      ],
                      [
                          -73.831816,
                          45.594835,
                          0
                      ],
                      [
                          -73.829591,
                          45.597047,
                          0
                      ],
                      [
                          -73.825831,
                          45.598891,
                          0
                      ],
                      [
                          -73.825671,
                          45.602778,
                          0
                      ],
                      [
                          -73.826054,
                          45.607157,
                          0
                      ],
                      [
                          -73.825357,
                          45.6074,
                          0
                      ],
                      [
                          -73.822614,
                          45.608531,
                          0
                      ],
                      [
                          -73.818822,
                          45.607517,
                          0
                      ],
                      [
                          -73.808554,
                          45.610981,
                          0
                      ],
                      [
                          -73.804701,
                          45.610369,
                          0
                      ],
                      [
                          -73.795553,
                          45.615144,
                          0
                      ],
                      [
                          -73.788142,
                          45.623384,
                          0
                      ],
                      [
                          -73.78797,
                          45.630167,
                          0
                      ],
                      [
                          -73.778327,
                          45.636113,
                          0
                      ],
                      [
                          -73.776428,
                          45.627922,
                          0
                      ],
                      [
                          -73.767766,
                          45.626611,
                          0
                      ],
                      [
                          -73.764875,
                          45.625484,
                          0
                      ],
                      [
                          -73.762017,
                          45.623357,
                          0
                      ],
                      [
                          -73.727958,
                          45.588883,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Sainte-Rose",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.539336,
                          45.428376,
                          0
                      ],
                      [
                          -73.540046,
                          45.402475,
                          0
                      ],
                      [
                          -73.540349,
                          45.402176,
                          0
                      ],
                      [
                          -73.540037,
                          45.401919,
                          0
                      ],
                      [
                          -73.540197,
                          45.401762,
                          0
                      ],
                      [
                          -73.540746,
                          45.401994,
                          0
                      ],
                      [
                          -73.54109,
                          45.401983,
                          0
                      ],
                      [
                          -73.540992,
                          45.401684,
                          0
                      ],
                      [
                          -73.54192,
                          45.40142,
                          0
                      ],
                      [
                          -73.54182,
                          45.400609,
                          0
                      ],
                      [
                          -73.541367,
                          45.40038,
                          0
                      ],
                      [
                          -73.547942,
                          45.387529,
                          0
                      ],
                      [
                          -73.54954,
                          45.387937,
                          0
                      ],
                      [
                          -73.549614,
                          45.387511,
                          0
                      ],
                      [
                          -73.55073,
                          45.386893,
                          0
                      ],
                      [
                          -73.551937,
                          45.386537,
                          0
                      ],
                      [
                          -73.553556,
                          45.386249,
                          0
                      ],
                      [
                          -73.553547,
                          45.385684,
                          0
                      ],
                      [
                          -73.554259,
                          45.385918,
                          0
                      ],
                      [
                          -73.554608,
                          45.385561,
                          0
                      ],
                      [
                          -73.554536,
                          45.385322,
                          0
                      ],
                      [
                          -73.55396,
                          45.385011,
                          0
                      ],
                      [
                          -73.553957,
                          45.385009,
                          0
                      ],
                      [
                          -73.55239,
                          45.383658,
                          0
                      ],
                      [
                          -73.552916,
                          45.382619,
                          0
                      ],
                      [
                          -73.553257,
                          45.382634,
                          0
                      ],
                      [
                          -73.553399,
                          45.382542,
                          0
                      ],
                      [
                          -73.553457,
                          45.382264,
                          0
                      ],
                      [
                          -73.553235,
                          45.381808,
                          0
                      ],
                      [
                          -73.553435,
                          45.38131,
                          0
                      ],
                      [
                          -73.550446,
                          45.380598,
                          0
                      ],
                      [
                          -73.5514,
                          45.378658,
                          0
                      ],
                      [
                          -73.554882,
                          45.378597,
                          0
                      ],
                      [
                          -73.556734,
                          45.378772,
                          0
                      ],
                      [
                          -73.561241,
                          45.378736,
                          0
                      ],
                      [
                          -73.560461,
                          45.378534,
                          0
                      ],
                      [
                          -73.562855,
                          45.373926,
                          0
                      ],
                      [
                          -73.565965,
                          45.374608,
                          0
                      ],
                      [
                          -73.566343,
                          45.374335,
                          0
                      ],
                      [
                          -73.566776,
                          45.373339,
                          0
                      ],
                      [
                          -73.568583,
                          45.373802,
                          0
                      ],
                      [
                          -73.568808,
                          45.373543,
                          0
                      ],
                      [
                          -73.558122,
                          45.370994,
                          0
                      ],
                      [
                          -73.558114,
                          45.370993,
                          0
                      ],
                      [
                          -73.559025,
                          45.36909,
                          0
                      ],
                      [
                          -73.540716,
                          45.36413,
                          0
                      ],
                      [
                          -73.540188,
                          45.363661,
                          0
                      ],
                      [
                          -73.538585,
                          45.363172,
                          0
                      ],
                      [
                          -73.538388,
                          45.362882,
                          0
                      ],
                      [
                          -73.538565,
                          45.362661,
                          0
                      ],
                      [
                          -73.539776,
                          45.36263,
                          0
                      ],
                      [
                          -73.537893,
                          45.361777,
                          0
                      ],
                      [
                          -73.53812,
                          45.359964,
                          0
                      ],
                      [
                          -73.537446,
                          45.359703,
                          0
                      ],
                      [
                          -73.537338,
                          45.359097,
                          0
                      ],
                      [
                          -73.524625,
                          45.353184,
                          0
                      ],
                      [
                          -73.525827,
                          45.351812,
                          0
                      ],
                      [
                          -73.525827,
                          45.351812,
                          0
                      ],
                      [
                          -73.520547,
                          45.349322,
                          0
                      ],
                      [
                          -73.520496,
                          45.353202,
                          0
                      ],
                      [
                          -73.520489,
                          45.353767,
                          0
                      ],
                      [
                          -73.519716,
                          45.353406,
                          0
                      ],
                      [
                          -73.519604,
                          45.343915,
                          0
                      ],
                      [
                          -73.516159,
                          45.3374,
                          0
                      ],
                      [
                          -73.492964,
                          45.34925,
                          0
                      ],
                      [
                          -73.492674,
                          45.348809,
                          0
                      ],
                      [
                          -73.491857,
                          45.348962,
                          0
                      ],
                      [
                          -73.491437,
                          45.348521,
                          0
                      ],
                      [
                          -73.491437,
                          45.348521,
                          0
                      ],
                      [
                          -73.492089,
                          45.348238,
                          0
                      ],
                      [
                          -73.491624,
                          45.347906,
                          0
                      ],
                      [
                          -73.504077,
                          45.341613,
                          0
                      ],
                      [
                          -73.502847,
                          45.340267,
                          0
                      ],
                      [
                          -73.504212,
                          45.339567,
                          0
                      ],
                      [
                          -73.502816,
                          45.338237,
                          0
                      ],
                      [
                          -73.509859,
                          45.334679,
                          0
                      ],
                      [
                          -73.508732,
                          45.333416,
                          0
                      ],
                      [
                          -73.508299,
                          45.331864,
                          0
                      ],
                      [
                          -73.512235,
                          45.329865,
                          0
                      ],
                      [
                          -73.511685,
                          45.329613,
                          0
                      ],
                      [
                          -73.512274,
                          45.32885,
                          0
                      ],
                      [
                          -73.510239,
                          45.32783,
                          0
                      ],
                      [
                          -73.510666,
                          45.327175,
                          0
                      ],
                      [
                          -73.510205,
                          45.326977,
                          0
                      ],
                      [
                          -73.509573,
                          45.324999,
                          0
                      ],
                      [
                          -73.509883,
                          45.324835,
                          0
                      ],
                      [
                          -73.508151,
                          45.324029,
                          0
                      ],
                      [
                          -73.508146,
                          45.324027,
                          0
                      ],
                      [
                          -73.509343,
                          45.323147,
                          0
                      ],
                      [
                          -73.509347,
                          45.323144,
                          0
                      ],
                      [
                          -73.504342,
                          45.320994,
                          0
                      ],
                      [
                          -73.506281,
                          45.320018,
                          0
                      ],
                      [
                          -73.506324,
                          45.319997,
                          0
                      ],
                      [
                          -73.501373,
                          45.317922,
                          0
                      ],
                      [
                          -73.50135,
                          45.317913,
                          0
                      ],
                      [
                          -73.505389,
                          45.315885,
                          0
                      ],
                      [
                          -73.505405,
                          45.315877,
                          0
                      ],
                      [
                          -73.502922,
                          45.314828,
                          0
                      ],
                      [
                          -73.504907,
                          45.312536,
                          0
                      ],
                      [
                          -73.503745,
                          45.312041,
                          0
                      ],
                      [
                          -73.503725,
                          45.311872,
                          0
                      ],
                      [
                          -73.504253,
                          45.311014,
                          0
                      ],
                      [
                          -73.505253,
                          45.310594,
                          0
                      ],
                      [
                          -73.504583,
                          45.310313,
                          0
                      ],
                      [
                          -73.504058,
                          45.310578,
                          0
                      ],
                      [
                          -73.487955,
                          45.318609,
                          0
                      ],
                      [
                          -73.486473,
                          45.317804,
                          0
                      ],
                      [
                          -73.499956,
                          45.311011,
                          0
                      ],
                      [
                          -73.499074,
                          45.310382,
                          0
                      ],
                      [
                          -73.498564,
                          45.310058,
                          0
                      ],
                      [
                          -73.498561,
                          45.310056,
                          0
                      ],
                      [
                          -73.497717,
                          45.309436,
                          0
                      ],
                      [
                          -73.497053,
                          45.309053,
                          0
                      ],
                      [
                          -73.495651,
                          45.307721,
                          0
                      ],
                      [
                          -73.494781,
                          45.306804,
                          0
                      ],
                      [
                          -73.493864,
                          45.305777,
                          0
                      ],
                      [
                          -73.493305,
                          45.304913,
                          0
                      ],
                      [
                          -73.49314,
                          45.304617,
                          0
                      ],
                      [
                          -73.49337,
                          45.304058,
                          0
                      ],
                      [
                          -73.49356,
                          45.303033,
                          0
                      ],
                      [
                          -73.493471,
                          45.302772,
                          0
                      ],
                      [
                          -73.493459,
                          45.302394,
                          0
                      ],
                      [
                          -73.493637,
                          45.301701,
                          0
                      ],
                      [
                          -73.493612,
                          45.301422,
                          0
                      ],
                      [
                          -73.493383,
                          45.300999,
                          0
                      ],
                      [
                          -73.492897,
                          45.30009,
                          0
                      ],
                      [
                          -73.492668,
                          45.299397,
                          0
                      ],
                      [
                          -73.492299,
                          45.298614,
                          0
                      ],
                      [
                          -73.492209,
                          45.297886,
                          0
                      ],
                      [
                          -73.492617,
                          45.297237,
                          0
                      ],
                      [
                          -73.492644,
                          45.296967,
                          0
                      ],
                      [
                          -73.492682,
                          45.295882,
                          0
                      ],
                      [
                          -73.494367,
                          45.295756,
                          0
                      ],
                      [
                          -73.502671,
                          45.284951,
                          0
                      ],
                      [
                          -73.505796,
                          45.285958,
                          0
                      ],
                      [
                          -73.50888,
                          45.283743,
                          0
                      ],
                      [
                          -73.509264,
                          45.283156,
                          0
                      ],
                      [
                          -73.510262,
                          45.280656,
                          0
                      ],
                      [
                          -73.510259,
                          45.280655,
                          0
                      ],
                      [
                          -73.510256,
                          45.280654,
                          0
                      ],
                      [
                          -73.507028,
                          45.279356,
                          0
                      ],
                      [
                          -73.50805,
                          45.27799,
                          0
                      ],
                      [
                          -73.508053,
                          45.277991,
                          0
                      ],
                      [
                          -73.510006,
                          45.278735,
                          0
                      ],
                      [
                          -73.510011,
                          45.278737,
                          0
                      ],
                      [
                          -73.510307,
                          45.277585,
                          0
                      ],
                      [
                          -73.51031,
                          45.277586,
                          0
                      ],
                      [
                          -73.517406,
                          45.2803,
                          0
                      ],
                      [
                          -73.516578,
                          45.2813,
                          0
                      ],
                      [
                          -73.518006,
                          45.281884,
                          0
                      ],
                      [
                          -73.516845,
                          45.283272,
                          0
                      ],
                      [
                          -73.517338,
                          45.283481,
                          0
                      ],
                      [
                          -73.515312,
                          45.28673,
                          0
                      ],
                      [
                          -73.515298,
                          45.286751,
                          0
                      ],
                      [
                          -73.515298,
                          45.286751,
                          0
                      ],
                      [
                          -73.530419,
                          45.292771,
                          0
                      ],
                      [
                          -73.535505,
                          45.292337,
                          0
                      ],
                      [
                          -73.535505,
                          45.292337,
                          0
                      ],
                      [
                          -73.536617,
                          45.292769,
                          0
                      ],
                      [
                          -73.536559,
                          45.291087,
                          0
                      ],
                      [
                          -73.537064,
                          45.290414,
                          0
                      ],
                      [
                          -73.537064,
                          45.290414,
                          0
                      ],
                      [
                          -73.550038,
                          45.2932,
                          0
                      ],
                      [
                          -73.548916,
                          45.292415,
                          0
                      ],
                      [
                          -73.547136,
                          45.290727,
                          0
                      ],
                      [
                          -73.547591,
                          45.289724,
                          0
                      ],
                      [
                          -73.547593,
                          45.289722,
                          0
                      ],
                      [
                          -73.547596,
                          45.289723,
                          0
                      ],
                      [
                          -73.568902,
                          45.295698,
                          0
                      ],
                      [
                          -73.569381,
                          45.294836,
                          0
                      ],
                      [
                          -73.568236,
                          45.294486,
                          0
                      ],
                      [
                          -73.568237,
                          45.294482,
                          0
                      ],
                      [
                          -73.570949,
                          45.289811,
                          0
                      ],
                      [
                          -73.570952,
                          45.289805,
                          0
                      ],
                      [
                          -73.576119,
                          45.291383,
                          0
                      ],
                      [
                          -73.576121,
                          45.29138,
                          0
                      ],
                      [
                          -73.576914,
                          45.28989,
                          0
                      ],
                      [
                          -73.576911,
                          45.289889,
                          0
                      ],
                      [
                          -73.571774,
                          45.288356,
                          0
                      ],
                      [
                          -73.571774,
                          45.288356,
                          0
                      ],
                      [
                          -73.572846,
                          45.287384,
                          0
                      ],
                      [
                          -73.577008,
                          45.287068,
                          0
                      ],
                      [
                          -73.577881,
                          45.28534,
                          0
                      ],
                      [
                          -73.578778,
                          45.282563,
                          0
                      ],
                      [
                          -73.580273,
                          45.279537,
                          0
                      ],
                      [
                          -73.582672,
                          45.275863,
                          0
                      ],
                      [
                          -73.581761,
                          45.275578,
                          0
                      ],
                      [
                          -73.581762,
                          45.275576,
                          0
                      ],
                      [
                          -73.582243,
                          45.274769,
                          0
                      ],
                      [
                          -73.582242,
                          45.274769,
                          0
                      ],
                      [
                          -73.581897,
                          45.273407,
                          0
                      ],
                      [
                          -73.581898,
                          45.273403,
                          0
                      ],
                      [
                          -73.581664,
                          45.273331,
                          0
                      ],
                      [
                          -73.582296,
                          45.272094,
                          0
                      ],
                      [
                          -73.582292,
                          45.272092,
                          0
                      ],
                      [
                          -73.581311,
                          45.271785,
                          0
                      ],
                      [
                          -73.581888,
                          45.270759,
                          0
                      ],
                      [
                          -73.58189,
                          45.270757,
                          0
                      ],
                      [
                          -73.581013,
                          45.269853,
                          0
                      ],
                      [
                          -73.578776,
                          45.269158,
                          0
                      ],
                      [
                          -73.581228,
                          45.265737,
                          0
                      ],
                      [
                          -73.581147,
                          45.263543,
                          0
                      ],
                      [
                          -73.580228,
                          45.26216,
                          0
                      ],
                      [
                          -73.580232,
                          45.262043,
                          0
                      ],
                      [
                          -73.586327,
                          45.25509,
                          0
                      ],
                      [
                          -73.583237,
                          45.253868,
                          0
                      ],
                      [
                          -73.583266,
                          45.253819,
                          0
                      ],
                      [
                          -73.585021,
                          45.250749,
                          0
                      ],
                      [
                          -73.585023,
                          45.25075,
                          0
                      ],
                      [
                          -73.589724,
                          45.252098,
                          0
                      ],
                      [
                          -73.6031,
                          45.231031,
                          0
                      ],
                      [
                          -73.598233,
                          45.229489,
                          0
                      ],
                      [
                          -73.598208,
                          45.229482,
                          0
                      ],
                      [
                          -73.605276,
                          45.218075,
                          0
                      ],
                      [
                          -73.613988,
                          45.220749,
                          0
                      ],
                      [
                          -73.639241,
                          45.184617,
                          0
                      ],
                      [
                          -73.698191,
                          45.229073,
                          0
                      ],
                      [
                          -73.682688,
                          45.242776,
                          0
                      ],
                      [
                          -73.682755,
                          45.242819,
                          0
                      ],
                      [
                          -73.682758,
                          45.242864,
                          0
                      ],
                      [
                          -73.68089,
                          45.244457,
                          0
                      ],
                      [
                          -73.688312,
                          45.246017,
                          0
                      ],
                      [
                          -73.688311,
                          45.246019,
                          0
                      ],
                      [
                          -73.677491,
                          45.268268,
                          0
                      ],
                      [
                          -73.677491,
                          45.268269,
                          0
                      ],
                      [
                          -73.677161,
                          45.268253,
                          0
                      ],
                      [
                          -73.677158,
                          45.268253,
                          0
                      ],
                      [
                          -73.650845,
                          45.301323,
                          0
                      ],
                      [
                          -73.650844,
                          45.301325,
                          0
                      ],
                      [
                          -73.650794,
                          45.301287,
                          0
                      ],
                      [
                          -73.64563,
                          45.297591,
                          0
                      ],
                      [
                          -73.645623,
                          45.297602,
                          0
                      ],
                      [
                          -73.63811,
                          45.310654,
                          0
                      ],
                      [
                          -73.638104,
                          45.310652,
                          0
                      ],
                      [
                          -73.634467,
                          45.314755,
                          0
                      ],
                      [
                          -73.63104,
                          45.318871,
                          0
                      ],
                      [
                          -73.627911,
                          45.32494,
                          0
                      ],
                      [
                          -73.620082,
                          45.329416,
                          0
                      ],
                      [
                          -73.634304,
                          45.332732,
                          0
                      ],
                      [
                          -73.635923,
                          45.33347,
                          0
                      ],
                      [
                          -73.641108,
                          45.3345,
                          0
                      ],
                      [
                          -73.639461,
                          45.336153,
                          0
                      ],
                      [
                          -73.638558,
                          45.337353,
                          0
                      ],
                      [
                          -73.635034,
                          45.340018,
                          0
                      ],
                      [
                          -73.631231,
                          45.342221,
                          0
                      ],
                      [
                          -73.644807,
                          45.345499,
                          0
                      ],
                      [
                          -73.644811,
                          45.3455,
                          0
                      ],
                      [
                          -73.644095,
                          45.345943,
                          0
                      ],
                      [
                          -73.644087,
                          45.345947,
                          0
                      ],
                      [
                          -73.661721,
                          45.350111,
                          0
                      ],
                      [
                          -73.661711,
                          45.350124,
                          0
                      ],
                      [
                          -73.660827,
                          45.351438,
                          0
                      ],
                      [
                          -73.656037,
                          45.351427,
                          0
                      ],
                      [
                          -73.656004,
                          45.351436,
                          0
                      ],
                      [
                          -73.65173,
                          45.352706,
                          0
                      ],
                      [
                          -73.651489,
                          45.353101,
                          0
                      ],
                      [
                          -73.648636,
                          45.352458,
                          0
                      ],
                      [
                          -73.648602,
                          45.352484,
                          0
                      ],
                      [
                          -73.646095,
                          45.354155,
                          0
                      ],
                      [
                          -73.643599,
                          45.357705,
                          0
                      ],
                      [
                          -73.640875,
                          45.360695,
                          0
                      ],
                      [
                          -73.639151,
                          45.361841,
                          0
                      ],
                      [
                          -73.636185,
                          45.363317,
                          0
                      ],
                      [
                          -73.633839,
                          45.365656,
                          0
                      ],
                      [
                          -73.632042,
                          45.367066,
                          0
                      ],
                      [
                          -73.629418,
                          45.368701,
                          0
                      ],
                      [
                          -73.628881,
                          45.368512,
                          0
                      ],
                      [
                          -73.628857,
                          45.368523,
                          0
                      ],
                      [
                          -73.62609,
                          45.369542,
                          0
                      ],
                      [
                          -73.624108,
                          45.372984,
                          0
                      ],
                      [
                          -73.623318,
                          45.375117,
                          0
                      ],
                      [
                          -73.622901,
                          45.375563,
                          0
                      ],
                      [
                          -73.620241,
                          45.378208,
                          0
                      ],
                      [
                          -73.620141,
                          45.378229,
                          0
                      ],
                      [
                          -73.620102,
                          45.378222,
                          0
                      ],
                      [
                          -73.620091,
                          45.37824,
                          0
                      ],
                      [
                          -73.616336,
                          45.385756,
                          0
                      ],
                      [
                          -73.611652,
                          45.38466,
                          0
                      ],
                      [
                          -73.597412,
                          45.415993,
                          0
                      ],
                      [
                          -73.597412,
                          45.415994,
                          0
                      ],
                      [
                          -73.539336,
                          45.428376,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Sanguinet",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -71.915812,
                          45.438111,
                          0
                      ],
                      [
                          -71.915074,
                          45.436169,
                          0
                      ],
                      [
                          -71.914278,
                          45.433565,
                          0
                      ],
                      [
                          -71.912995,
                          45.43174,
                          0
                      ],
                      [
                          -71.911597,
                          45.429952,
                          0
                      ],
                      [
                          -71.910237,
                          45.428262,
                          0
                      ],
                      [
                          -71.909149,
                          45.424804,
                          0
                      ],
                      [
                          -71.907964,
                          45.422881,
                          0
                      ],
                      [
                          -71.906845,
                          45.421084,
                          0
                      ],
                      [
                          -71.904934,
                          45.418976,
                          0
                      ],
                      [
                          -71.903671,
                          45.417228,
                          0
                      ],
                      [
                          -71.902312,
                          45.414547,
                          0
                      ],
                      [
                          -71.901611,
                          45.412391,
                          0
                      ],
                      [
                          -71.901028,
                          45.411031,
                          0
                      ],
                      [
                          -71.900096,
                          45.410506,
                          0
                      ],
                      [
                          -71.894443,
                          45.41004,
                          0
                      ],
                      [
                          -71.892514,
                          45.408797,
                          0
                      ],
                      [
                          -71.890169,
                          45.406563,
                          0
                      ],
                      [
                          -71.887948,
                          45.40394,
                          0
                      ],
                      [
                          -71.8855,
                          45.401668,
                          0
                      ],
                      [
                          -71.884451,
                          45.399823,
                          0
                      ],
                      [
                          -71.88245,
                          45.396694,
                          0
                      ],
                      [
                          -71.881321,
                          45.39513,
                          0
                      ],
                      [
                          -71.880294,
                          45.391499,
                          0
                      ],
                      [
                          -71.880372,
                          45.389235,
                          0
                      ],
                      [
                          -71.880482,
                          45.388155,
                          0
                      ],
                      [
                          -71.879284,
                          45.386088,
                          0
                      ],
                      [
                          -71.878343,
                          45.385475,
                          0
                      ],
                      [
                          -71.882607,
                          45.385839,
                          0
                      ],
                      [
                          -71.88031,
                          45.380469,
                          0
                      ],
                      [
                          -71.877273,
                          45.380271,
                          0
                      ],
                      [
                          -71.876177,
                          45.359496,
                          0
                      ],
                      [
                          -71.894863,
                          45.360358,
                          0
                      ],
                      [
                          -71.904755,
                          45.360395,
                          0
                      ],
                      [
                          -71.904802,
                          45.362539,
                          0
                      ],
                      [
                          -71.904802,
                          45.36254,
                          0
                      ],
                      [
                          -71.910248,
                          45.361702,
                          0
                      ],
                      [
                          -71.914107,
                          45.361729,
                          0
                      ],
                      [
                          -71.914106,
                          45.361728,
                          0
                      ],
                      [
                          -71.91405,
                          45.360395,
                          0
                      ],
                      [
                          -71.914053,
                          45.360395,
                          0
                      ],
                      [
                          -71.91419,
                          45.3604,
                          0
                      ],
                      [
                          -71.922103,
                          45.360409,
                          0
                      ],
                      [
                          -71.922104,
                          45.36041,
                          0
                      ],
                      [
                          -71.922165,
                          45.361063,
                          0
                      ],
                      [
                          -71.922167,
                          45.361063,
                          0
                      ],
                      [
                          -71.923238,
                          45.361034,
                          0
                      ],
                      [
                          -71.923243,
                          45.361034,
                          0
                      ],
                      [
                          -71.923214,
                          45.360405,
                          0
                      ],
                      [
                          -71.929547,
                          45.360461,
                          0
                      ],
                      [
                          -71.933128,
                          45.355646,
                          0
                      ],
                      [
                          -71.937066,
                          45.349865,
                          0
                      ],
                      [
                          -71.938369,
                          45.350073,
                          0
                      ],
                      [
                          -71.946032,
                          45.349662,
                          0
                      ],
                      [
                          -71.951137,
                          45.349201,
                          0
                      ],
                      [
                          -71.954336,
                          45.348989,
                          0
                      ],
                      [
                          -71.954658,
                          45.349102,
                          0
                      ],
                      [
                          -71.955309,
                          45.349643,
                          0
                      ],
                      [
                          -71.956195,
                          45.34983,
                          0
                      ],
                      [
                          -71.964541,
                          45.350262,
                          0
                      ],
                      [
                          -71.969611,
                          45.353374,
                          0
                      ],
                      [
                          -71.969858,
                          45.353578,
                          0
                      ],
                      [
                          -71.970616,
                          45.355577,
                          0
                      ],
                      [
                          -71.970205,
                          45.355636,
                          0
                      ],
                      [
                          -71.969711,
                          45.355965,
                          0
                      ],
                      [
                          -71.969428,
                          45.356634,
                          0
                      ],
                      [
                          -71.968465,
                          45.357633,
                          0
                      ],
                      [
                          -71.967321,
                          45.358298,
                          0
                      ],
                      [
                          -71.966765,
                          45.358353,
                          0
                      ],
                      [
                          -71.966386,
                          45.358542,
                          0
                      ],
                      [
                          -71.965213,
                          45.359074,
                          0
                      ],
                      [
                          -71.96488,
                          45.359906,
                          0
                      ],
                      [
                          -71.964991,
                          45.360737,
                          0
                      ],
                      [
                          -71.965491,
                          45.362014,
                          0
                      ],
                      [
                          -71.965491,
                          45.363511,
                          0
                      ],
                      [
                          -71.965268,
                          45.364454,
                          0
                      ],
                      [
                          -71.964936,
                          45.365451,
                          0
                      ],
                      [
                          -71.964602,
                          45.36584,
                          0
                      ],
                      [
                          -71.964271,
                          45.36645,
                          0
                      ],
                      [
                          -71.96427,
                          45.367394,
                          0
                      ],
                      [
                          -71.964381,
                          45.368115,
                          0
                      ],
                      [
                          -71.964159,
                          45.368947,
                          0
                      ],
                      [
                          -71.963327,
                          45.369889,
                          0
                      ],
                      [
                          -71.960664,
                          45.371775,
                          0
                      ],
                      [
                          -71.959223,
                          45.372885,
                          0
                      ],
                      [
                          -71.959056,
                          45.373329,
                          0
                      ],
                      [
                          -71.959111,
                          45.37405,
                          0
                      ],
                      [
                          -71.960239,
                          45.377797,
                          0
                      ],
                      [
                          -71.960792,
                          45.379103,
                          0
                      ],
                      [
                          -71.960687,
                          45.380056,
                          0
                      ],
                      [
                          -71.958678,
                          45.383112,
                          0
                      ],
                      [
                          -71.958078,
                          45.38441,
                          0
                      ],
                      [
                          -71.963469,
                          45.384382,
                          0
                      ],
                      [
                          -71.963961,
                          45.384103,
                          0
                      ],
                      [
                          -71.964447,
                          45.384526,
                          0
                      ],
                      [
                          -71.964724,
                          45.38437,
                          0
                      ],
                      [
                          -71.975568,
                          45.384392,
                          0
                      ],
                      [
                          -71.985076,
                          45.38505,
                          0
                      ],
                      [
                          -71.985015,
                          45.391818,
                          0
                      ],
                      [
                          -71.985017,
                          45.394763,
                          0
                      ],
                      [
                          -71.985034,
                          45.408728,
                          0
                      ],
                      [
                          -71.96692,
                          45.42619,
                          0
                      ],
                      [
                          -71.960854,
                          45.434293,
                          0
                      ],
                      [
                          -71.958074,
                          45.438135,
                          0
                      ],
                      [
                          -71.956852,
                          45.439748,
                          0
                      ],
                      [
                          -71.948568,
                          45.43825,
                          0
                      ],
                      [
                          -71.946799,
                          45.438091,
                          0
                      ],
                      [
                          -71.943017,
                          45.438361,
                          0
                      ],
                      [
                          -71.94301,
                          45.438362,
                          0
                      ],
                      [
                          -71.922454,
                          45.442395,
                          0
                      ],
                      [
                          -71.918241,
                          45.440033,
                          0
                      ],
                      [
                          -71.915812,
                          45.438111,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Sherbrooke",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "GeometryCollection",
              "geometries": [
                  {
                      "type": "Polygon",
                      "coordinates": [
                          [
                              [
                                  -74.294172,
                                  45.50503,
                                  0
                              ],
                              [
                                  -74.294172,
                                  45.50503,
                                  0
                              ],
                              [
                                  -74.267836,
                                  45.503834,
                                  0
                              ],
                              [
                                  -74.245762,
                                  45.502681,
                                  0
                              ],
                              [
                                  -74.245762,
                                  45.502681,
                                  0
                              ],
                              [
                                  -74.245753,
                                  45.50268,
                                  0
                              ],
                              [
                                  -74.24575,
                                  45.50268,
                                  0
                              ],
                              [
                                  -74.245533,
                                  45.502675,
                                  0
                              ],
                              [
                                  -74.211661,
                                  45.500043,
                                  0
                              ],
                              [
                                  -74.196548,
                                  45.494873,
                                  0
                              ],
                              [
                                  -74.158635,
                                  45.482571,
                                  0
                              ],
                              [
                                  -74.136007,
                                  45.468849,
                                  0
                              ],
                              [
                                  -74.091964,
                                  45.4526,
                                  0
                              ],
                              [
                                  -74.071132,
                                  45.452792,
                                  0
                              ],
                              [
                                  -74.05922,
                                  45.44974,
                                  0
                              ],
                              [
                                  -74.059222,
                                  45.44974,
                                  0
                              ],
                              [
                                  -74.059225,
                                  45.449738,
                                  0
                              ],
                              [
                                  -74.05909,
                                  45.449648,
                                  0
                              ],
                              [
                                  -74.093259,
                                  45.432244,
                                  0
                              ],
                              [
                                  -74.094927,
                                  45.431386,
                                  0
                              ],
                              [
                                  -74.096124,
                                  45.432635,
                                  0
                              ],
                              [
                                  -74.097602,
                                  45.431877,
                                  0
                              ],
                              [
                                  -74.09871,
                                  45.43131,
                                  0
                              ],
                              [
                                  -74.09872,
                                  45.43132,
                                  0
                              ],
                              [
                                  -74.100034,
                                  45.432584,
                                  0
                              ],
                              [
                                  -74.101761,
                                  45.43172,
                                  0
                              ],
                              [
                                  -74.103085,
                                  45.431059,
                                  0
                              ],
                              [
                                  -74.104432,
                                  45.432313,
                                  0
                              ],
                              [
                                  -74.108409,
                                  45.43028,
                                  0
                              ],
                              [
                                  -74.108447,
                                  45.430322,
                                  0
                              ],
                              [
                                  -74.12239,
                                  45.434687,
                                  0
                              ],
                              [
                                  -74.124442,
                                  45.435474,
                                  0
                              ],
                              [
                                  -74.126246,
                                  45.436678,
                                  0
                              ],
                              [
                                  -74.136449,
                                  45.444564,
                                  0
                              ],
                              [
                                  -74.137338,
                                  45.445255,
                                  0
                              ],
                              [
                                  -74.138379,
                                  45.444705,
                                  0
                              ],
                              [
                                  -74.138406,
                                  45.444718,
                                  0
                              ],
                              [
                                  -74.139847,
                                  45.445407,
                                  0
                              ],
                              [
                                  -74.14206,
                                  45.447153,
                                  0
                              ],
                              [
                                  -74.149081,
                                  45.443889,
                                  0
                              ],
                              [
                                  -74.153465,
                                  45.444599,
                                  0
                              ],
                              [
                                  -74.156313,
                                  45.444437,
                                  0
                              ],
                              [
                                  -74.158732,
                                  45.443907,
                                  0
                              ],
                              [
                                  -74.152585,
                                  45.442147,
                                  0
                              ],
                              [
                                  -74.154932,
                                  45.440078,
                                  0
                              ],
                              [
                                  -74.147264,
                                  45.43716,
                                  0
                              ],
                              [
                                  -74.136287,
                                  45.428712,
                                  0
                              ],
                              [
                                  -74.133493,
                                  45.430137,
                                  0
                              ],
                              [
                                  -74.133478,
                                  45.430123,
                                  0
                              ],
                              [
                                  -74.120723,
                                  45.418226,
                                  0
                              ],
                              [
                                  -74.111782,
                                  45.422785,
                                  0
                              ],
                              [
                                  -74.111719,
                                  45.422723,
                                  0
                              ],
                              [
                                  -74.104587,
                                  45.415901,
                                  0
                              ],
                              [
                                  -74.104574,
                                  45.415889,
                                  0
                              ],
                              [
                                  -74.107694,
                                  45.414386,
                                  0
                              ],
                              [
                                  -74.100299,
                                  45.410476,
                                  0
                              ],
                              [
                                  -74.081935,
                                  45.392696,
                                  0
                              ],
                              [
                                  -74.08434,
                                  45.391448,
                                  0
                              ],
                              [
                                  -74.079599,
                                  45.386698,
                                  0
                              ],
                              [
                                  -74.072451,
                                  45.378072,
                                  0
                              ],
                              [
                                  -74.073765,
                                  45.377216,
                                  0
                              ],
                              [
                                  -74.074251,
                                  45.376763,
                                  0
                              ],
                              [
                                  -74.075403,
                                  45.377212,
                                  0
                              ],
                              [
                                  -74.075863,
                                  45.376966,
                                  0
                              ],
                              [
                                  -74.077015,
                                  45.376939,
                                  0
                              ],
                              [
                                  -74.077648,
                                  45.376777,
                                  0
                              ],
                              [
                                  -74.077754,
                                  45.37634,
                                  0
                              ],
                              [
                                  -74.078639,
                                  45.37628,
                                  0
                              ],
                              [
                                  -74.07877,
                                  45.376781,
                                  0
                              ],
                              [
                                  -74.078987,
                                  45.376916,
                                  0
                              ],
                              [
                                  -74.079313,
                                  45.376643,
                                  0
                              ],
                              [
                                  -74.07972,
                                  45.376649,
                                  0
                              ],
                              [
                                  -74.079666,
                                  45.37689,
                                  0
                              ],
                              [
                                  -74.079889,
                                  45.376994,
                                  0
                              ],
                              [
                                  -74.08027,
                                  45.377394,
                                  0
                              ],
                              [
                                  -74.080781,
                                  45.377411,
                                  0
                              ],
                              [
                                  -74.081031,
                                  45.377803,
                                  0
                              ],
                              [
                                  -74.081619,
                                  45.377934,
                                  0
                              ],
                              [
                                  -74.082553,
                                  45.378364,
                                  0
                              ],
                              [
                                  -74.08389,
                                  45.378498,
                                  0
                              ],
                              [
                                  -74.084325,
                                  45.378335,
                                  0
                              ],
                              [
                                  -74.085159,
                                  45.378619,
                                  0
                              ],
                              [
                                  -74.085362,
                                  45.378953,
                                  0
                              ],
                              [
                                  -74.086076,
                                  45.378815,
                                  0
                              ],
                              [
                                  -74.084668,
                                  45.377499,
                                  0
                              ],
                              [
                                  -74.096058,
                                  45.371447,
                                  0
                              ],
                              [
                                  -74.000682,
                                  45.334664,
                                  0
                              ],
                              [
                                  -73.99288,
                                  45.334148,
                                  0
                              ],
                              [
                                  -73.981211,
                                  45.333369,
                                  0
                              ],
                              [
                                  -73.981204,
                                  45.333368,
                                  0
                              ],
                              [
                                  -73.980574,
                                  45.333481,
                                  0
                              ],
                              [
                                  -73.980171,
                                  45.334251,
                                  0
                              ],
                              [
                                  -73.979781,
                                  45.334347,
                                  0
                              ],
                              [
                                  -73.977571,
                                  45.333446,
                                  0
                              ],
                              [
                                  -73.973245,
                                  45.334531,
                                  0
                              ],
                              [
                                  -73.972758,
                                  45.335017,
                                  0
                              ],
                              [
                                  -73.971313,
                                  45.335169,
                                  0
                              ],
                              [
                                  -73.963166,
                                  45.342868,
                                  0
                              ],
                              [
                                  -73.96315,
                                  45.34286,
                                  0
                              ],
                              [
                                  -73.952759,
                                  45.342164,
                                  0
                              ],
                              [
                                  -73.946734,
                                  45.342913,
                                  0
                              ],
                              [
                                  -73.932164,
                                  45.343258,
                                  0
                              ],
                              [
                                  -73.924249,
                                  45.33959,
                                  0
                              ],
                              [
                                  -73.916744,
                                  45.332376,
                                  0
                              ],
                              [
                                  -73.931383,
                                  45.326303,
                                  0
                              ],
                              [
                                  -73.939006,
                                  45.325443,
                                  0
                              ],
                              [
                                  -73.946413,
                                  45.323023,
                                  0
                              ],
                              [
                                  -73.95402,
                                  45.319847,
                                  0
                              ],
                              [
                                  -73.9577,
                                  45.319912,
                                  0
                              ],
                              [
                                  -73.963536,
                                  45.323078,
                                  0
                              ],
                              [
                                  -73.968651,
                                  45.321983,
                                  0
                              ],
                              [
                                  -73.980325,
                                  45.314929,
                                  0
                              ],
                              [
                                  -73.986955,
                                  45.312049,
                                  0
                              ],
                              [
                                  -74.000086,
                                  45.309227,
                                  0
                              ],
                              [
                                  -74.006943,
                                  45.308949,
                                  0
                              ],
                              [
                                  -74.013284,
                                  45.307707,
                                  0
                              ],
                              [
                                  -74.019085,
                                  45.305386,
                                  0
                              ],
                              [
                                  -74.027727,
                                  45.304365,
                                  0
                              ],
                              [
                                  -74.030586,
                                  45.303692,
                                  0
                              ],
                              [
                                  -74.034326,
                                  45.300771,
                                  0
                              ],
                              [
                                  -74.040274,
                                  45.296678,
                                  0
                              ],
                              [
                                  -74.042383,
                                  45.295358,
                                  0
                              ],
                              [
                                  -74.047595,
                                  45.295093,
                                  0
                              ],
                              [
                                  -74.051529,
                                  45.295162,
                                  0
                              ],
                              [
                                  -74.053901,
                                  45.294192,
                                  0
                              ],
                              [
                                  -74.053969,
                                  45.294162,
                                  0
                              ],
                              [
                                  -74.058101,
                                  45.293871,
                                  0
                              ],
                              [
                                  -74.058261,
                                  45.293868,
                                  0
                              ],
                              [
                                  -74.060924,
                                  45.294858,
                                  0
                              ],
                              [
                                  -74.061154,
                                  45.295913,
                                  0
                              ],
                              [
                                  -74.061159,
                                  45.295939,
                                  0
                              ],
                              [
                                  -74.063056,
                                  45.297093,
                                  0
                              ],
                              [
                                  -74.069152,
                                  45.295857,
                                  0
                              ],
                              [
                                  -74.075307,
                                  45.294727,
                                  0
                              ],
                              [
                                  -74.078643,
                                  45.294792,
                                  0
                              ],
                              [
                                  -74.084513,
                                  45.296679,
                                  0
                              ],
                              [
                                  -74.088683,
                                  45.299347,
                                  0
                              ],
                              [
                                  -74.090271,
                                  45.300598,
                                  0
                              ],
                              [
                                  -74.09427,
                                  45.303213,
                                  0
                              ],
                              [
                                  -74.099495,
                                  45.304296,
                                  0
                              ],
                              [
                                  -74.105325,
                                  45.304395,
                                  0
                              ],
                              [
                                  -74.105604,
                                  45.304385,
                                  0
                              ],
                              [
                                  -74.119754,
                                  45.302352,
                                  0
                              ],
                              [
                                  -74.126697,
                                  45.300847,
                                  0
                              ],
                              [
                                  -74.1267,
                                  45.300846,
                                  0
                              ],
                              [
                                  -74.137307,
                                  45.29805,
                                  0
                              ],
                              [
                                  -74.143764,
                                  45.295211,
                                  0
                              ],
                              [
                                  -74.153673,
                                  45.29197,
                                  0
                              ],
                              [
                                  -74.157516,
                                  45.290986,
                                  0
                              ],
                              [
                                  -74.157516,
                                  45.290985,
                                  0
                              ],
                              [
                                  -74.156637,
                                  45.285973,
                                  0
                              ],
                              [
                                  -74.156975,
                                  45.283739,
                                  0
                              ],
                              [
                                  -74.159261,
                                  45.281435,
                                  0
                              ],
                              [
                                  -74.160716,
                                  45.28011,
                                  0
                              ],
                              [
                                  -74.1613,
                                  45.279404,
                                  0
                              ],
                              [
                                  -74.161524,
                                  45.278675,
                                  0
                              ],
                              [
                                  -74.162279,
                                  45.27688,
                                  0
                              ],
                              [
                                  -74.16352,
                                  45.276743,
                                  0
                              ],
                              [
                                  -74.164762,
                                  45.276488,
                                  0
                              ],
                              [
                                  -74.165766,
                                  45.276423,
                                  0
                              ],
                              [
                                  -74.165802,
                                  45.275566,
                                  0
                              ],
                              [
                                  -74.165621,
                                  45.275213,
                                  0
                              ],
                              [
                                  -74.165495,
                                  45.274065,
                                  0
                              ],
                              [
                                  -74.165729,
                                  45.272282,
                                  0
                              ],
                              [
                                  -74.166613,
                                  45.270492,
                                  0
                              ],
                              [
                                  -74.167541,
                                  45.270456,
                                  0
                              ],
                              [
                                  -74.168249,
                                  45.27088,
                                  0
                              ],
                              [
                                  -74.168528,
                                  45.271526,
                                  0
                              ],
                              [
                                  -74.168365,
                                  45.272055,
                                  0
                              ],
                              [
                                  -74.16863,
                                  45.273017,
                                  0
                              ],
                              [
                                  -74.169116,
                                  45.274026,
                                  0
                              ],
                              [
                                  -74.169657,
                                  45.274275,
                                  0
                              ],
                              [
                                  -74.170766,
                                  45.273926,
                                  0
                              ],
                              [
                                  -74.17327,
                                  45.273146,
                                  0
                              ],
                              [
                                  -74.174418,
                                  45.273156,
                                  0
                              ],
                              [
                                  -74.174418,
                                  45.273156,
                                  0
                              ],
                              [
                                  -74.177579,
                                  45.274432,
                                  0
                              ],
                              [
                                  -74.179731,
                                  45.274061,
                                  0
                              ],
                              [
                                  -74.18086,
                                  45.272144,
                                  0
                              ],
                              [
                                  -74.179221,
                                  45.269748,
                                  0
                              ],
                              [
                                  -74.178423,
                                  45.269175,
                                  0
                              ],
                              [
                                  -74.177756,
                                  45.26812,
                                  0
                              ],
                              [
                                  -74.175701,
                                  45.267766,
                                  0
                              ],
                              [
                                  -74.174647,
                                  45.267158,
                                  0
                              ],
                              [
                                  -74.169292,
                                  45.263688,
                                  0
                              ],
                              [
                                  -74.168951,
                                  45.260371,
                                  0
                              ],
                              [
                                  -74.172859,
                                  45.255977,
                                  0
                              ],
                              [
                                  -74.180182,
                                  45.248479,
                                  0
                              ],
                              [
                                  -74.180247,
                                  45.248409,
                                  0
                              ],
                              [
                                  -74.197935,
                                  45.232182,
                                  0
                              ],
                              [
                                  -74.212704,
                                  45.220878,
                                  0
                              ],
                              [
                                  -74.223116,
                                  45.214792,
                                  0
                              ],
                              [
                                  -74.254672,
                                  45.202477,
                                  0
                              ],
                              [
                                  -74.275299,
                                  45.198563,
                                  0
                              ],
                              [
                                  -74.300778,
                                  45.194543,
                                  0
                              ],
                              [
                                  -74.318816,
                                  45.187992,
                                  0
                              ],
                              [
                                  -74.472297,
                                  45.302819,
                                  0
                              ],
                              [
                                  -74.472298,
                                  45.302821,
                                  0
                              ],
                              [
                                  -74.381345,
                                  45.564016,
                                  0
                              ],
                              [
                                  -74.381345,
                                  45.564016,
                                  0
                              ],
                              [
                                  -74.381344,
                                  45.564021,
                                  0
                              ],
                              [
                                  -74.380894,
                                  45.565543,
                                  0
                              ],
                              [
                                  -74.380892,
                                  45.565548,
                                  0
                              ],
                              [
                                  -74.366712,
                                  45.557195,
                                  0
                              ],
                              [
                                  -74.366658,
                                  45.557152,
                                  0
                              ],
                              [
                                  -74.355309,
                                  45.546819,
                                  0
                              ],
                              [
                                  -74.340327,
                                  45.51526,
                                  0
                              ],
                              [
                                  -74.335904,
                                  45.510742,
                                  0
                              ],
                              [
                                  -74.315689,
                                  45.504537,
                                  0
                              ],
                              [
                                  -74.294172,
                                  45.50503,
                                  0
                              ]
                          ]
                      ]
                  },
                  {
                      "type": "Polygon",
                      "coordinates": [
                          [
                              [
                                  -74.219391,
                                  45.430199,
                                  0
                              ],
                              [
                                  -74.219368,
                                  45.430195,
                                  0
                              ],
                              [
                                  -74.217896,
                                  45.435079,
                                  0
                              ],
                              [
                                  -74.217893,
                                  45.435085,
                                  0
                              ],
                              [
                                  -74.217883,
                                  45.435124,
                                  0
                              ],
                              [
                                  -74.217839,
                                  45.435112,
                                  0
                              ],
                              [
                                  -74.206462,
                                  45.432037,
                                  0
                              ],
                              [
                                  -74.206231,
                                  45.432451,
                                  0
                              ],
                              [
                                  -74.193535,
                                  45.429046,
                                  0
                              ],
                              [
                                  -74.181757,
                                  45.450503,
                                  0
                              ],
                              [
                                  -74.187388,
                                  45.45217,
                                  0
                              ],
                              [
                                  -74.182377,
                                  45.457396,
                                  0
                              ],
                              [
                                  -74.209932,
                                  45.464285,
                                  0
                              ],
                              [
                                  -74.209442,
                                  45.467042,
                                  0
                              ],
                              [
                                  -74.21995,
                                  45.468646,
                                  0
                              ],
                              [
                                  -74.230078,
                                  45.431783,
                                  0
                              ],
                              [
                                  -74.219391,
                                  45.430199,
                                  0
                              ]
                          ]
                      ]
                  }
              ]
          },
          "properties": {
              "name": "Soulanges",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.424062,
                          45.5487,
                          0
                      ],
                      [
                          -73.43781,
                          45.537,
                          0
                      ],
                      [
                          -73.438213,
                          45.536484,
                          0
                      ],
                      [
                          -73.438384,
                          45.534731,
                          0
                      ],
                      [
                          -73.438712,
                          45.533843,
                          0
                      ],
                      [
                          -73.439182,
                          45.532926,
                          0
                      ],
                      [
                          -73.439187,
                          45.532916,
                          0
                      ],
                      [
                          -73.437111,
                          45.532335,
                          0
                      ],
                      [
                          -73.436627,
                          45.53204,
                          0
                      ],
                      [
                          -73.431017,
                          45.527762,
                          0
                      ],
                      [
                          -73.437783,
                          45.514167,
                          0
                      ],
                      [
                          -73.439779,
                          45.511825,
                          0
                      ],
                      [
                          -73.457255,
                          45.519162,
                          0
                      ],
                      [
                          -73.499488,
                          45.536861,
                          0
                      ],
                      [
                          -73.509833,
                          45.541552,
                          0
                      ],
                      [
                          -73.519436,
                          45.545151,
                          0
                      ],
                      [
                          -73.519986,
                          45.545352,
                          0
                      ],
                      [
                          -73.512173,
                          45.555364,
                          0
                      ],
                      [
                          -73.497372,
                          45.58289,
                          0
                      ],
                      [
                          -73.49884,
                          45.588198,
                          0
                      ],
                      [
                          -73.485666,
                          45.585632,
                          0
                      ],
                      [
                          -73.484376,
                          45.585678,
                          0
                      ],
                      [
                          -73.482166,
                          45.586442,
                          0
                      ],
                      [
                          -73.481311,
                          45.585865,
                          0
                      ],
                      [
                          -73.480051,
                          45.585908,
                          0
                      ],
                      [
                          -73.479228,
                          45.58646,
                          0
                      ],
                      [
                          -73.47947,
                          45.58704,
                          0
                      ],
                      [
                          -73.474409,
                          45.589099,
                          0
                      ],
                      [
                          -73.472843,
                          45.589177,
                          0
                      ],
                      [
                          -73.470878,
                          45.588604,
                          0
                      ],
                      [
                          -73.472578,
                          45.586403,
                          0
                      ],
                      [
                          -73.424062,
                          45.5487,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Taillon",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -71.194033,
                          46.813396,
                          0
                      ],
                      [
                          -71.199561,
                          46.800728,
                          0
                      ],
                      [
                          -71.206895,
                          46.792145,
                          0
                      ],
                      [
                          -71.214145,
                          46.785257,
                          0
                      ],
                      [
                          -71.226256,
                          46.791357,
                          0
                      ],
                      [
                          -71.228983,
                          46.793529,
                          0
                      ],
                      [
                          -71.230098,
                          46.792544,
                          0
                      ],
                      [
                          -71.231316,
                          46.791448,
                          0
                      ],
                      [
                          -71.23153,
                          46.791612,
                          0
                      ],
                      [
                          -71.233237,
                          46.790888,
                          0
                      ],
                      [
                          -71.233612,
                          46.791198,
                          0
                      ],
                      [
                          -71.233727,
                          46.791356,
                          0
                      ],
                      [
                          -71.234278,
                          46.791717,
                          0
                      ],
                      [
                          -71.237033,
                          46.791839,
                          0
                      ],
                      [
                          -71.238962,
                          46.793437,
                          0
                      ],
                      [
                          -71.236699,
                          46.794709,
                          0
                      ],
                      [
                          -71.236702,
                          46.794712,
                          0
                      ],
                      [
                          -71.23937,
                          46.796988,
                          0
                      ],
                      [
                          -71.23937,
                          46.796988,
                          0
                      ],
                      [
                          -71.257903,
                          46.786299,
                          0
                      ],
                      [
                          -71.260998,
                          46.788736,
                          0
                      ],
                      [
                          -71.261317,
                          46.788549,
                          0
                      ],
                      [
                          -71.263172,
                          46.790019,
                          0
                      ],
                      [
                          -71.263077,
                          46.790081,
                          0
                      ],
                      [
                          -71.263463,
                          46.790388,
                          0
                      ],
                      [
                          -71.26321,
                          46.790567,
                          0
                      ],
                      [
                          -71.264545,
                          46.791656,
                          0
                      ],
                      [
                          -71.265664,
                          46.790913,
                          0
                      ],
                      [
                          -71.265664,
                          46.790913,
                          0
                      ],
                      [
                          -71.26696,
                          46.791924,
                          0
                      ],
                      [
                          -71.265823,
                          46.792661,
                          0
                      ],
                      [
                          -71.268265,
                          46.794647,
                          0
                      ],
                      [
                          -71.268072,
                          46.794637,
                          0
                      ],
                      [
                          -71.266869,
                          46.79515,
                          0
                      ],
                      [
                          -71.267911,
                          46.795983,
                          0
                      ],
                      [
                          -71.267071,
                          46.796041,
                          0
                      ],
                      [
                          -71.26566,
                          46.796189,
                          0
                      ],
                      [
                          -71.264748,
                          46.796349,
                          0
                      ],
                      [
                          -71.263979,
                          46.796521,
                          0
                      ],
                      [
                          -71.263039,
                          46.796792,
                          0
                      ],
                      [
                          -71.260598,
                          46.79775,
                          0
                      ],
                      [
                          -71.25793,
                          46.798848,
                          0
                      ],
                      [
                          -71.257932,
                          46.798851,
                          0
                      ],
                      [
                          -71.257932,
                          46.798852,
                          0
                      ],
                      [
                          -71.259581,
                          46.800301,
                          0
                      ],
                      [
                          -71.266473,
                          46.806069,
                          0
                      ],
                      [
                          -71.266942,
                          46.806355,
                          0
                      ],
                      [
                          -71.267324,
                          46.806545,
                          0
                      ],
                      [
                          -71.267866,
                          46.806747,
                          0
                      ],
                      [
                          -71.268453,
                          46.806976,
                          0
                      ],
                      [
                          -71.268772,
                          46.80717,
                          0
                      ],
                      [
                          -71.269014,
                          46.807373,
                          0
                      ],
                      [
                          -71.269332,
                          46.807776,
                          0
                      ],
                      [
                          -71.268344,
                          46.808066,
                          0
                      ],
                      [
                          -71.265012,
                          46.808083,
                          0
                      ],
                      [
                          -71.263893,
                          46.808377,
                          0
                      ],
                      [
                          -71.260141,
                          46.8079,
                          0
                      ],
                      [
                          -71.256987,
                          46.808729,
                          0
                      ],
                      [
                          -71.252849,
                          46.810806,
                          0
                      ],
                      [
                          -71.252188,
                          46.811889,
                          0
                      ],
                      [
                          -71.250624,
                          46.813136,
                          0
                      ],
                      [
                          -71.249257,
                          46.814376,
                          0
                      ],
                      [
                          -71.247407,
                          46.814941,
                          0
                      ],
                      [
                          -71.244885,
                          46.814844,
                          0
                      ],
                      [
                          -71.243344,
                          46.815002,
                          0
                      ],
                      [
                          -71.241129,
                          46.815847,
                          0
                      ],
                      [
                          -71.239845,
                          46.817113,
                          0
                      ],
                      [
                          -71.23925,
                          46.818912,
                          0
                      ],
                      [
                          -71.239388,
                          46.819871,
                          0
                      ],
                      [
                          -71.239757,
                          46.820819,
                          0
                      ],
                      [
                          -71.238873,
                          46.823071,
                          0
                      ],
                      [
                          -71.237959,
                          46.823489,
                          0
                      ],
                      [
                          -71.235477,
                          46.823757,
                          0
                      ],
                      [
                          -71.233348,
                          46.823024,
                          0
                      ],
                      [
                          -71.230984,
                          46.821514,
                          0
                      ],
                      [
                          -71.228472,
                          46.819947,
                          0
                      ],
                      [
                          -71.225582,
                          46.819157,
                          0
                      ],
                      [
                          -71.220888,
                          46.820007,
                          0
                      ],
                      [
                          -71.216309,
                          46.821417,
                          0
                      ],
                      [
                          -71.194568,
                          46.830182,
                          0
                      ],
                      [
                          -71.187646,
                          46.825105,
                          0
                      ],
                      [
                          -71.194033,
                          46.813396,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Taschereau",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.699335,
                          45.746967,
                          0
                      ],
                      [
                          -73.699299,
                          45.74704,
                          0
                      ],
                      [
                          -73.668525,
                          45.726414,
                          0
                      ],
                      [
                          -73.57658,
                          45.715102,
                          0
                      ],
                      [
                          -73.577722,
                          45.700561,
                          0
                      ],
                      [
                          -73.578513,
                          45.697421,
                          0
                      ],
                      [
                          -73.580601,
                          45.69456,
                          0
                      ],
                      [
                          -73.602244,
                          45.689224,
                          0
                      ],
                      [
                          -73.614827,
                          45.693193,
                          0
                      ],
                      [
                          -73.623386,
                          45.692924,
                          0
                      ],
                      [
                          -73.629288,
                          45.68958,
                          0
                      ],
                      [
                          -73.644562,
                          45.690251,
                          0
                      ],
                      [
                          -73.649045,
                          45.689085,
                          0
                      ],
                      [
                          -73.653738,
                          45.689037,
                          0
                      ],
                      [
                          -73.657752,
                          45.691272,
                          0
                      ],
                      [
                          -73.667057,
                          45.693045,
                          0
                      ],
                      [
                          -73.681545,
                          45.694223,
                          0
                      ],
                      [
                          -73.687085,
                          45.693489,
                          0
                      ],
                      [
                          -73.692489,
                          45.68912,
                          0
                      ],
                      [
                          -73.694166,
                          45.688281,
                          0
                      ],
                      [
                          -73.694169,
                          45.688279,
                          0
                      ],
                      [
                          -73.697295,
                          45.685139,
                          0
                      ],
                      [
                          -73.711567,
                          45.680057,
                          0
                      ],
                      [
                          -73.723097,
                          45.680775,
                          0
                      ],
                      [
                          -73.727845,
                          45.680406,
                          0
                      ],
                      [
                          -73.733767,
                          45.677233,
                          0
                      ],
                      [
                          -73.736542,
                          45.675347,
                          0
                      ],
                      [
                          -73.738288,
                          45.671782,
                          0
                      ],
                      [
                          -73.745493,
                          45.668666,
                          0
                      ],
                      [
                          -73.745502,
                          45.668675,
                          0
                      ],
                      [
                          -73.757078,
                          45.681588,
                          0
                      ],
                      [
                          -73.759162,
                          45.680142,
                          0
                      ],
                      [
                          -73.767067,
                          45.685805,
                          0
                      ],
                      [
                          -73.772341,
                          45.681819,
                          0
                      ],
                      [
                          -73.777596,
                          45.685573,
                          0
                      ],
                      [
                          -73.777287,
                          45.68308,
                          0
                      ],
                      [
                          -73.778629,
                          45.681525,
                          0
                      ],
                      [
                          -73.780705,
                          45.680733,
                          0
                      ],
                      [
                          -73.781662,
                          45.681597,
                          0
                      ],
                      [
                          -73.783452,
                          45.680275,
                          0
                      ],
                      [
                          -73.846171,
                          45.721947,
                          0
                      ],
                      [
                          -73.841129,
                          45.722123,
                          0
                      ],
                      [
                          -73.839223,
                          45.722622,
                          0
                      ],
                      [
                          -73.834138,
                          45.721997,
                          0
                      ],
                      [
                          -73.832708,
                          45.721417,
                          0
                      ],
                      [
                          -73.822249,
                          45.720079,
                          0
                      ],
                      [
                          -73.818878,
                          45.719853,
                          0
                      ],
                      [
                          -73.816675,
                          45.719786,
                          0
                      ],
                      [
                          -73.812481,
                          45.71995,
                          0
                      ],
                      [
                          -73.809993,
                          45.719504,
                          0
                      ],
                      [
                          -73.801698,
                          45.719278,
                          0
                      ],
                      [
                          -73.797531,
                          45.716531,
                          0
                      ],
                      [
                          -73.794457,
                          45.717439,
                          0
                      ],
                      [
                          -73.792753,
                          45.716057,
                          0
                      ],
                      [
                          -73.789617,
                          45.715468,
                          0
                      ],
                      [
                          -73.786468,
                          45.715733,
                          0
                      ],
                      [
                          -73.783112,
                          45.714975,
                          0
                      ],
                      [
                          -73.781297,
                          45.714729,
                          0
                      ],
                      [
                          -73.781204,
                          45.714716,
                          0
                      ],
                      [
                          -73.780002,
                          45.714513,
                          0
                      ],
                      [
                          -73.779985,
                          45.714505,
                          0
                      ],
                      [
                          -73.778605,
                          45.714124,
                          0
                      ],
                      [
                          -73.77848,
                          45.714089,
                          0
                      ],
                      [
                          -73.777205,
                          45.713729,
                          0
                      ],
                      [
                          -73.774448,
                          45.713659,
                          0
                      ],
                      [
                          -73.770029,
                          45.713299,
                          0
                      ],
                      [
                          -73.767137,
                          45.713023,
                          0
                      ],
                      [
                          -73.764767,
                          45.713186,
                          0
                      ],
                      [
                          -73.761401,
                          45.714396,
                          0
                      ],
                      [
                          -73.759208,
                          45.715184,
                          0
                      ],
                      [
                          -73.756206,
                          45.717768,
                          0
                      ],
                      [
                          -73.758651,
                          45.726309,
                          0
                      ],
                      [
                          -73.758498,
                          45.726331,
                          0
                      ],
                      [
                          -73.75907,
                          45.728273,
                          0
                      ],
                      [
                          -73.758749,
                          45.728626,
                          0
                      ],
                      [
                          -73.757855,
                          45.729297,
                          0
                      ],
                      [
                          -73.757736,
                          45.729606,
                          0
                      ],
                      [
                          -73.757435,
                          45.729725,
                          0
                      ],
                      [
                          -73.757188,
                          45.72967,
                          0
                      ],
                      [
                          -73.75648,
                          45.729659,
                          0
                      ],
                      [
                          -73.755443,
                          45.729919,
                          0
                      ],
                      [
                          -73.754015,
                          45.730472,
                          0
                      ],
                      [
                          -73.753408,
                          45.73061,
                          0
                      ],
                      [
                          -73.752549,
                          45.730459,
                          0
                      ],
                      [
                          -73.752269,
                          45.730505,
                          0
                      ],
                      [
                          -73.751248,
                          45.731369,
                          0
                      ],
                      [
                          -73.751089,
                          45.731653,
                          0
                      ],
                      [
                          -73.751291,
                          45.732159,
                          0
                      ],
                      [
                          -73.751291,
                          45.732162,
                          0
                      ],
                      [
                          -73.751037,
                          45.732698,
                          0
                      ],
                      [
                          -73.750231,
                          45.732808,
                          0
                      ],
                      [
                          -73.749594,
                          45.73292,
                          0
                      ],
                      [
                          -73.748885,
                          45.7327,
                          0
                      ],
                      [
                          -73.747784,
                          45.732585,
                          0
                      ],
                      [
                          -73.746369,
                          45.732579,
                          0
                      ],
                      [
                          -73.745793,
                          45.732437,
                          0
                      ],
                      [
                          -73.751139,
                          45.750492,
                          0
                      ],
                      [
                          -73.714811,
                          45.757274,
                          0
                      ],
                      [
                          -73.714809,
                          45.757272,
                          0
                      ],
                      [
                          -73.699335,
                          45.746967,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Terrebonne",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -72.680312,
                          46.411762,
                          0
                      ],
                      [
                          -72.679101,
                          46.410845,
                          0
                      ],
                      [
                          -72.671938,
                          46.407007,
                          0
                      ],
                      [
                          -72.660183,
                          46.403421,
                          0
                      ],
                      [
                          -72.641855,
                          46.392685,
                          0
                      ],
                      [
                          -72.628043,
                          46.397126,
                          0
                      ],
                      [
                          -72.622038,
                          46.393706,
                          0
                      ],
                      [
                          -72.611687,
                          46.385754,
                          0
                      ],
                      [
                          -72.607483,
                          46.377201,
                          0
                      ],
                      [
                          -72.60446,
                          46.37526,
                          0
                      ],
                      [
                          -72.591905,
                          46.37482,
                          0
                      ],
                      [
                          -72.582417,
                          46.372224,
                          0
                      ],
                      [
                          -72.577483,
                          46.369597,
                          0
                      ],
                      [
                          -72.574153,
                          46.368804,
                          0
                      ],
                      [
                          -72.553237,
                          46.366338,
                          0
                      ],
                      [
                          -72.539099,
                          46.368139,
                          0
                      ],
                      [
                          -72.536406,
                          46.36756,
                          0
                      ],
                      [
                          -72.534089,
                          46.362832,
                          0
                      ],
                      [
                          -72.530926,
                          46.361111,
                          0
                      ],
                      [
                          -72.522618,
                          46.360595,
                          0
                      ],
                      [
                          -72.494586,
                          46.355291,
                          0
                      ],
                      [
                          -72.494586,
                          46.355291,
                          0
                      ],
                      [
                          -72.532112,
                          46.334096,
                          0
                      ],
                      [
                          -72.560513,
                          46.306761,
                          0
                      ],
                      [
                          -72.616285,
                          46.340228,
                          0
                      ],
                      [
                          -72.645931,
                          46.35819,
                          0
                      ],
                      [
                          -72.723091,
                          46.411771,
                          0
                      ],
                      [
                          -72.704912,
                          46.424248,
                          0
                      ],
                      [
                          -72.704912,
                          46.424248,
                          0
                      ],
                      [
                          -72.700568,
                          46.423346,
                          0
                      ],
                      [
                          -72.691411,
                          46.421713,
                          0
                      ],
                      [
                          -72.687761,
                          46.420613,
                          0
                      ],
                      [
                          -72.685974,
                          46.419756,
                          0
                      ],
                      [
                          -72.684382,
                          46.41839,
                          0
                      ],
                      [
                          -72.682599,
                          46.41551,
                          0
                      ],
                      [
                          -72.681593,
                          46.413326,
                          0
                      ],
                      [
                          -72.680312,
                          46.411762,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Trois-Rivières",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.377018,
                          45.527667,
                          0
                      ],
                      [
                          -73.377011,
                          45.527662,
                          0
                      ],
                      [
                          -73.377644,
                          45.526192,
                          0
                      ],
                      [
                          -73.378575,
                          45.525321,
                          0
                      ],
                      [
                          -73.382855,
                          45.52145,
                          0
                      ],
                      [
                          -73.394169,
                          45.50959,
                          0
                      ],
                      [
                          -73.380996,
                          45.499066,
                          0
                      ],
                      [
                          -73.380828,
                          45.499407,
                          0
                      ],
                      [
                          -73.378647,
                          45.498887,
                          0
                      ],
                      [
                          -73.378991,
                          45.498176,
                          0
                      ],
                      [
                          -73.377231,
                          45.497321,
                          0
                      ],
                      [
                          -73.377149,
                          45.497281,
                          0
                      ],
                      [
                          -73.37197,
                          45.507843,
                          0
                      ],
                      [
                          -73.3715,
                          45.507474,
                          0
                      ],
                      [
                          -73.371415,
                          45.50753,
                          0
                      ],
                      [
                          -73.338637,
                          45.482132,
                          0
                      ],
                      [
                          -73.338627,
                          45.482124,
                          0
                      ],
                      [
                          -73.346362,
                          45.466904,
                          0
                      ],
                      [
                          -73.346627,
                          45.46707,
                          0
                      ],
                      [
                          -73.363015,
                          45.445102,
                          0
                      ],
                      [
                          -73.363396,
                          45.444995,
                          0
                      ],
                      [
                          -73.363399,
                          45.444994,
                          0
                      ],
                      [
                          -73.367718,
                          45.448283,
                          0
                      ],
                      [
                          -73.369409,
                          45.446984,
                          0
                      ],
                      [
                          -73.36832,
                          45.446217,
                          0
                      ],
                      [
                          -73.371495,
                          45.443928,
                          0
                      ],
                      [
                          -73.371998,
                          45.444296,
                          0
                      ],
                      [
                          -73.373563,
                          45.443101,
                          0
                      ],
                      [
                          -73.374086,
                          45.443477,
                          0
                      ],
                      [
                          -73.37563,
                          45.4423,
                          0
                      ],
                      [
                          -73.37534,
                          45.442091,
                          0
                      ],
                      [
                          -73.372804,
                          45.437995,
                          0
                      ],
                      [
                          -73.376619,
                          45.438378,
                          0
                      ],
                      [
                          -73.378985,
                          45.437703,
                          0
                      ],
                      [
                          -73.37747,
                          45.436595,
                          0
                      ],
                      [
                          -73.379079,
                          45.435467,
                          0
                      ],
                      [
                          -73.377527,
                          45.434365,
                          0
                      ],
                      [
                          -73.379884,
                          45.432639,
                          0
                      ],
                      [
                          -73.38142,
                          45.433661,
                          0
                      ],
                      [
                          -73.383764,
                          45.435223,
                          0
                      ],
                      [
                          -73.423279,
                          45.461471,
                          0
                      ],
                      [
                          -73.437977,
                          45.471164,
                          0
                      ],
                      [
                          -73.452807,
                          45.481115,
                          0
                      ],
                      [
                          -73.452584,
                          45.481248,
                          0
                      ],
                      [
                          -73.435799,
                          45.491278,
                          0
                      ],
                      [
                          -73.442455,
                          45.494964,
                          0
                      ],
                      [
                          -73.445977,
                          45.496912,
                          0
                      ],
                      [
                          -73.445971,
                          45.497008,
                          0
                      ],
                      [
                          -73.446724,
                          45.49742,
                          0
                      ],
                      [
                          -73.459421,
                          45.504433,
                          0
                      ],
                      [
                          -73.461729,
                          45.505796,
                          0
                      ],
                      [
                          -73.460371,
                          45.50594,
                          0
                      ],
                      [
                          -73.460073,
                          45.506358,
                          0
                      ],
                      [
                          -73.442564,
                          45.507502,
                          0
                      ],
                      [
                          -73.442425,
                          45.507705,
                          0
                      ],
                      [
                          -73.442946,
                          45.508858,
                          0
                      ],
                      [
                          -73.442146,
                          45.50925,
                          0
                      ],
                      [
                          -73.441308,
                          45.509928,
                          0
                      ],
                      [
                          -73.439779,
                          45.511825,
                          0
                      ],
                      [
                          -73.437783,
                          45.514167,
                          0
                      ],
                      [
                          -73.431017,
                          45.527762,
                          0
                      ],
                      [
                          -73.436627,
                          45.53204,
                          0
                      ],
                      [
                          -73.437111,
                          45.532335,
                          0
                      ],
                      [
                          -73.439187,
                          45.532916,
                          0
                      ],
                      [
                          -73.439182,
                          45.532926,
                          0
                      ],
                      [
                          -73.438712,
                          45.533843,
                          0
                      ],
                      [
                          -73.438384,
                          45.534731,
                          0
                      ],
                      [
                          -73.438213,
                          45.536484,
                          0
                      ],
                      [
                          -73.43781,
                          45.537,
                          0
                      ],
                      [
                          -73.424062,
                          45.5487,
                          0
                      ],
                      [
                          -73.419615,
                          45.545265,
                          0
                      ],
                      [
                          -73.415103,
                          45.54824,
                          0
                      ],
                      [
                          -73.411336,
                          45.551139,
                          0
                      ],
                      [
                          -73.411093,
                          45.551753,
                          0
                      ],
                      [
                          -73.410674,
                          45.552949,
                          0
                      ],
                      [
                          -73.410666,
                          45.552969,
                          0
                      ],
                      [
                          -73.377018,
                          45.527667,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Vachon",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -71.304666,
                          46.868848,
                          0
                      ],
                      [
                          -71.304091,
                          46.869059,
                          0
                      ],
                      [
                          -71.303691,
                          46.869467,
                          0
                      ],
                      [
                          -71.303378,
                          46.869532,
                          0
                      ],
                      [
                          -71.30272,
                          46.869464,
                          0
                      ],
                      [
                          -71.300456,
                          46.868949,
                          0
                      ],
                      [
                          -71.299801,
                          46.868927,
                          0
                      ],
                      [
                          -71.299578,
                          46.869,
                          0
                      ],
                      [
                          -71.29893,
                          46.869473,
                          0
                      ],
                      [
                          -71.29863,
                          46.869628,
                          0
                      ],
                      [
                          -71.29799,
                          46.869786,
                          0
                      ],
                      [
                          -71.297093,
                          46.8698,
                          0
                      ],
                      [
                          -71.29186,
                          46.864378,
                          0
                      ],
                      [
                          -71.292203,
                          46.864091,
                          0
                      ],
                      [
                          -71.288938,
                          46.860557,
                          0
                      ],
                      [
                          -71.288033,
                          46.860562,
                          0
                      ],
                      [
                          -71.284311,
                          46.856047,
                          0
                      ],
                      [
                          -71.279861,
                          46.851276,
                          0
                      ],
                      [
                          -71.278564,
                          46.850077,
                          0
                      ],
                      [
                          -71.27301,
                          46.845984,
                          0
                      ],
                      [
                          -71.27074,
                          46.844125,
                          0
                      ],
                      [
                          -71.268378,
                          46.841681,
                          0
                      ],
                      [
                          -71.262606,
                          46.835421,
                          0
                      ],
                      [
                          -71.255372,
                          46.82756,
                          0
                      ],
                      [
                          -71.254755,
                          46.826953,
                          0
                      ],
                      [
                          -71.252781,
                          46.825648,
                          0
                      ],
                      [
                          -71.250331,
                          46.824637,
                          0
                      ],
                      [
                          -71.24667,
                          46.823143,
                          0
                      ],
                      [
                          -71.245302,
                          46.822254,
                          0
                      ],
                      [
                          -71.244401,
                          46.821524,
                          0
                      ],
                      [
                          -71.243563,
                          46.821019,
                          0
                      ],
                      [
                          -71.241823,
                          46.820358,
                          0
                      ],
                      [
                          -71.239388,
                          46.819871,
                          0
                      ],
                      [
                          -71.23925,
                          46.818912,
                          0
                      ],
                      [
                          -71.239845,
                          46.817113,
                          0
                      ],
                      [
                          -71.241129,
                          46.815847,
                          0
                      ],
                      [
                          -71.243344,
                          46.815002,
                          0
                      ],
                      [
                          -71.244885,
                          46.814844,
                          0
                      ],
                      [
                          -71.247407,
                          46.814941,
                          0
                      ],
                      [
                          -71.249257,
                          46.814376,
                          0
                      ],
                      [
                          -71.250624,
                          46.813136,
                          0
                      ],
                      [
                          -71.252188,
                          46.811889,
                          0
                      ],
                      [
                          -71.252849,
                          46.810806,
                          0
                      ],
                      [
                          -71.256987,
                          46.808729,
                          0
                      ],
                      [
                          -71.260141,
                          46.8079,
                          0
                      ],
                      [
                          -71.263893,
                          46.808377,
                          0
                      ],
                      [
                          -71.265012,
                          46.808083,
                          0
                      ],
                      [
                          -71.268344,
                          46.808066,
                          0
                      ],
                      [
                          -71.269332,
                          46.807776,
                          0
                      ],
                      [
                          -71.269014,
                          46.807373,
                          0
                      ],
                      [
                          -71.268772,
                          46.80717,
                          0
                      ],
                      [
                          -71.268453,
                          46.806976,
                          0
                      ],
                      [
                          -71.267866,
                          46.806747,
                          0
                      ],
                      [
                          -71.267324,
                          46.806545,
                          0
                      ],
                      [
                          -71.266942,
                          46.806355,
                          0
                      ],
                      [
                          -71.266473,
                          46.806069,
                          0
                      ],
                      [
                          -71.259581,
                          46.800301,
                          0
                      ],
                      [
                          -71.257932,
                          46.798852,
                          0
                      ],
                      [
                          -71.257932,
                          46.798851,
                          0
                      ],
                      [
                          -71.25793,
                          46.798848,
                          0
                      ],
                      [
                          -71.260598,
                          46.79775,
                          0
                      ],
                      [
                          -71.263039,
                          46.796792,
                          0
                      ],
                      [
                          -71.263979,
                          46.796521,
                          0
                      ],
                      [
                          -71.264748,
                          46.796349,
                          0
                      ],
                      [
                          -71.26566,
                          46.796189,
                          0
                      ],
                      [
                          -71.267071,
                          46.796041,
                          0
                      ],
                      [
                          -71.267911,
                          46.795983,
                          0
                      ],
                      [
                          -71.285873,
                          46.794466,
                          0
                      ],
                      [
                          -71.296258,
                          46.793579,
                          0
                      ],
                      [
                          -71.297804,
                          46.793375,
                          0
                      ],
                      [
                          -71.299349,
                          46.793092,
                          0
                      ],
                      [
                          -71.300368,
                          46.792851,
                          0
                      ],
                      [
                          -71.301776,
                          46.79248,
                          0
                      ],
                      [
                          -71.304639,
                          46.791697,
                          0
                      ],
                      [
                          -71.313577,
                          46.789262,
                          0
                      ],
                      [
                          -71.316615,
                          46.79451,
                          0
                      ],
                      [
                          -71.316615,
                          46.79451,
                          0
                      ],
                      [
                          -71.316617,
                          46.794509,
                          0
                      ],
                      [
                          -71.317974,
                          46.79363,
                          0
                      ],
                      [
                          -71.31857,
                          46.793876,
                          0
                      ],
                      [
                          -71.318203,
                          46.793483,
                          0
                      ],
                      [
                          -71.318705,
                          46.793157,
                          0
                      ],
                      [
                          -71.318708,
                          46.793156,
                          0
                      ],
                      [
                          -71.322349,
                          46.79188,
                          0
                      ],
                      [
                          -71.321546,
                          46.791305,
                          0
                      ],
                      [
                          -71.322584,
                          46.790629,
                          0
                      ],
                      [
                          -71.323566,
                          46.789989,
                          0
                      ],
                      [
                          -71.329851,
                          46.794562,
                          0
                      ],
                      [
                          -71.331558,
                          46.793444,
                          0
                      ],
                      [
                          -71.334132,
                          46.795319,
                          0
                      ],
                      [
                          -71.338099,
                          46.798198,
                          0
                      ],
                      [
                          -71.337016,
                          46.798907,
                          0
                      ],
                      [
                          -71.337128,
                          46.799148,
                          0
                      ],
                      [
                          -71.337007,
                          46.799453,
                          0
                      ],
                      [
                          -71.336691,
                          46.799644,
                          0
                      ],
                      [
                          -71.336307,
                          46.799624,
                          0
                      ],
                      [
                          -71.336122,
                          46.79949,
                          0
                      ],
                      [
                          -71.336085,
                          46.799517,
                          0
                      ],
                      [
                          -71.336084,
                          46.799518,
                          0
                      ],
                      [
                          -71.335973,
                          46.79947,
                          0
                      ],
                      [
                          -71.33578,
                          46.799463,
                          0
                      ],
                      [
                          -71.335653,
                          46.799502,
                          0
                      ],
                      [
                          -71.335603,
                          46.799621,
                          0
                      ],
                      [
                          -71.341415,
                          46.803711,
                          0
                      ],
                      [
                          -71.34035,
                          46.804414,
                          0
                      ],
                      [
                          -71.34502,
                          46.807752,
                          0
                      ],
                      [
                          -71.339103,
                          46.811593,
                          0
                      ],
                      [
                          -71.345981,
                          46.816464,
                          0
                      ],
                      [
                          -71.347617,
                          46.817154,
                          0
                      ],
                      [
                          -71.364009,
                          46.82247,
                          0
                      ],
                      [
                          -71.356135,
                          46.82772,
                          0
                      ],
                      [
                          -71.350027,
                          46.831889,
                          0
                      ],
                      [
                          -71.341979,
                          46.836273,
                          0
                      ],
                      [
                          -71.342053,
                          46.836911,
                          0
                      ],
                      [
                          -71.342055,
                          46.837563,
                          0
                      ],
                      [
                          -71.342481,
                          46.838658,
                          0
                      ],
                      [
                          -71.342238,
                          46.839783,
                          0
                      ],
                      [
                          -71.341532,
                          46.84098,
                          0
                      ],
                      [
                          -71.34156,
                          46.841476,
                          0
                      ],
                      [
                          -71.342097,
                          46.842097,
                          0
                      ],
                      [
                          -71.342373,
                          46.842764,
                          0
                      ],
                      [
                          -71.342389,
                          46.842787,
                          0
                      ],
                      [
                          -71.343722,
                          46.843478,
                          0
                      ],
                      [
                          -71.343725,
                          46.843483,
                          0
                      ],
                      [
                          -71.343499,
                          46.844224,
                          0
                      ],
                      [
                          -71.343619,
                          46.844588,
                          0
                      ],
                      [
                          -71.344148,
                          46.844817,
                          0
                      ],
                      [
                          -71.345069,
                          46.844817,
                          0
                      ],
                      [
                          -71.345716,
                          46.844903,
                          0
                      ],
                      [
                          -71.346157,
                          46.845396,
                          0
                      ],
                      [
                          -71.346979,
                          46.846477,
                          0
                      ],
                      [
                          -71.347045,
                          46.847536,
                          0
                      ],
                      [
                          -71.347294,
                          46.847921,
                          0
                      ],
                      [
                          -71.347649,
                          46.849044,
                          0
                      ],
                      [
                          -71.348263,
                          46.849609,
                          0
                      ],
                      [
                          -71.34827,
                          46.84995,
                          0
                      ],
                      [
                          -71.348082,
                          46.850286,
                          0
                      ],
                      [
                          -71.347912,
                          46.851594,
                          0
                      ],
                      [
                          -71.346884,
                          46.851694,
                          0
                      ],
                      [
                          -71.347344,
                          46.852822,
                          0
                      ],
                      [
                          -71.341136,
                          46.855667,
                          0
                      ],
                      [
                          -71.341797,
                          46.856419,
                          0
                      ],
                      [
                          -71.32008,
                          46.865937,
                          0
                      ],
                      [
                          -71.319328,
                          46.866396,
                          0
                      ],
                      [
                          -71.314016,
                          46.868717,
                          0
                      ],
                      [
                          -71.314014,
                          46.868718,
                          0
                      ],
                      [
                          -71.313789,
                          46.868815,
                          0
                      ],
                      [
                          -71.313829,
                          46.868858,
                          0
                      ],
                      [
                          -71.313732,
                          46.868991,
                          0
                      ],
                      [
                          -71.312011,
                          46.869714,
                          0
                      ],
                      [
                          -71.311692,
                          46.86977,
                          0
                      ],
                      [
                          -71.308879,
                          46.869862,
                          0
                      ],
                      [
                          -71.307899,
                          46.870148,
                          0
                      ],
                      [
                          -71.30757,
                          46.870105,
                          0
                      ],
                      [
                          -71.307399,
                          46.870008,
                          0
                      ],
                      [
                          -71.307345,
                          46.869927,
                          0
                      ],
                      [
                          -71.306865,
                          46.869454,
                          0
                      ],
                      [
                          -71.306166,
                          46.869117,
                          0
                      ],
                      [
                          -71.305639,
                          46.868958,
                          0
                      ],
                      [
                          -71.304666,
                          46.868848,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Vanier-Les Rivières",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "GeometryCollection",
              "geometries": [
                  {
                      "type": "Polygon",
                      "coordinates": [
                          [
                              [
                                  -73.983296,
                                  45.421182,
                                  0
                              ],
                              [
                                  -73.981818,
                                  45.416487,
                                  0
                              ],
                              [
                                  -73.981815,
                                  45.416483,
                                  0
                              ],
                              [
                                  -73.964563,
                                  45.40965,
                                  0
                              ],
                              [
                                  -73.961708,
                                  45.40814,
                                  0
                              ],
                              [
                                  -73.957015,
                                  45.403394,
                                  0
                              ],
                              [
                                  -73.950265,
                                  45.401286,
                                  0
                              ],
                              [
                                  -73.918877,
                                  45.398348,
                                  0
                              ],
                              [
                                  -73.899626,
                                  45.408793,
                                  0
                              ],
                              [
                                  -73.879125,
                                  45.408459,
                                  0
                              ],
                              [
                                  -73.822179,
                                  45.385403,
                                  0
                              ],
                              [
                                  -73.846461,
                                  45.357972,
                                  0
                              ],
                              [
                                  -73.878377,
                                  45.338257,
                                  0
                              ],
                              [
                                  -73.896991,
                                  45.335541,
                                  0
                              ],
                              [
                                  -73.916744,
                                  45.332376,
                                  0
                              ],
                              [
                                  -73.924249,
                                  45.33959,
                                  0
                              ],
                              [
                                  -73.932164,
                                  45.343258,
                                  0
                              ],
                              [
                                  -73.946734,
                                  45.342913,
                                  0
                              ],
                              [
                                  -73.952759,
                                  45.342164,
                                  0
                              ],
                              [
                                  -73.96315,
                                  45.34286,
                                  0
                              ],
                              [
                                  -73.963166,
                                  45.342868,
                                  0
                              ],
                              [
                                  -73.971313,
                                  45.335169,
                                  0
                              ],
                              [
                                  -73.972758,
                                  45.335017,
                                  0
                              ],
                              [
                                  -73.973245,
                                  45.334531,
                                  0
                              ],
                              [
                                  -73.977571,
                                  45.333446,
                                  0
                              ],
                              [
                                  -73.979781,
                                  45.334347,
                                  0
                              ],
                              [
                                  -73.980171,
                                  45.334251,
                                  0
                              ],
                              [
                                  -73.980574,
                                  45.333481,
                                  0
                              ],
                              [
                                  -73.981204,
                                  45.333368,
                                  0
                              ],
                              [
                                  -73.981211,
                                  45.333369,
                                  0
                              ],
                              [
                                  -73.99288,
                                  45.334148,
                                  0
                              ],
                              [
                                  -74.000682,
                                  45.334664,
                                  0
                              ],
                              [
                                  -74.096058,
                                  45.371447,
                                  0
                              ],
                              [
                                  -74.084668,
                                  45.377499,
                                  0
                              ],
                              [
                                  -74.086076,
                                  45.378815,
                                  0
                              ],
                              [
                                  -74.085362,
                                  45.378953,
                                  0
                              ],
                              [
                                  -74.085159,
                                  45.378619,
                                  0
                              ],
                              [
                                  -74.084325,
                                  45.378335,
                                  0
                              ],
                              [
                                  -74.08389,
                                  45.378498,
                                  0
                              ],
                              [
                                  -74.082553,
                                  45.378364,
                                  0
                              ],
                              [
                                  -74.081619,
                                  45.377934,
                                  0
                              ],
                              [
                                  -74.081031,
                                  45.377803,
                                  0
                              ],
                              [
                                  -74.080781,
                                  45.377411,
                                  0
                              ],
                              [
                                  -74.08027,
                                  45.377394,
                                  0
                              ],
                              [
                                  -74.079889,
                                  45.376994,
                                  0
                              ],
                              [
                                  -74.079666,
                                  45.37689,
                                  0
                              ],
                              [
                                  -74.07972,
                                  45.376649,
                                  0
                              ],
                              [
                                  -74.079313,
                                  45.376643,
                                  0
                              ],
                              [
                                  -74.078987,
                                  45.376916,
                                  0
                              ],
                              [
                                  -74.07877,
                                  45.376781,
                                  0
                              ],
                              [
                                  -74.078639,
                                  45.37628,
                                  0
                              ],
                              [
                                  -74.077754,
                                  45.37634,
                                  0
                              ],
                              [
                                  -74.077648,
                                  45.376777,
                                  0
                              ],
                              [
                                  -74.077015,
                                  45.376939,
                                  0
                              ],
                              [
                                  -74.075863,
                                  45.376966,
                                  0
                              ],
                              [
                                  -74.075403,
                                  45.377212,
                                  0
                              ],
                              [
                                  -74.074251,
                                  45.376763,
                                  0
                              ],
                              [
                                  -74.073765,
                                  45.377216,
                                  0
                              ],
                              [
                                  -74.072451,
                                  45.378072,
                                  0
                              ],
                              [
                                  -74.079599,
                                  45.386698,
                                  0
                              ],
                              [
                                  -74.08434,
                                  45.391448,
                                  0
                              ],
                              [
                                  -74.081935,
                                  45.392696,
                                  0
                              ],
                              [
                                  -74.100299,
                                  45.410476,
                                  0
                              ],
                              [
                                  -74.107694,
                                  45.414386,
                                  0
                              ],
                              [
                                  -74.104574,
                                  45.415889,
                                  0
                              ],
                              [
                                  -74.104587,
                                  45.415901,
                                  0
                              ],
                              [
                                  -74.111719,
                                  45.422723,
                                  0
                              ],
                              [
                                  -74.111782,
                                  45.422785,
                                  0
                              ],
                              [
                                  -74.120723,
                                  45.418226,
                                  0
                              ],
                              [
                                  -74.133478,
                                  45.430123,
                                  0
                              ],
                              [
                                  -74.133493,
                                  45.430137,
                                  0
                              ],
                              [
                                  -74.136287,
                                  45.428712,
                                  0
                              ],
                              [
                                  -74.147264,
                                  45.43716,
                                  0
                              ],
                              [
                                  -74.154932,
                                  45.440078,
                                  0
                              ],
                              [
                                  -74.152585,
                                  45.442147,
                                  0
                              ],
                              [
                                  -74.158732,
                                  45.443907,
                                  0
                              ],
                              [
                                  -74.156313,
                                  45.444437,
                                  0
                              ],
                              [
                                  -74.153465,
                                  45.444599,
                                  0
                              ],
                              [
                                  -74.149081,
                                  45.443889,
                                  0
                              ],
                              [
                                  -74.14206,
                                  45.447153,
                                  0
                              ],
                              [
                                  -74.139847,
                                  45.445407,
                                  0
                              ],
                              [
                                  -74.138406,
                                  45.444718,
                                  0
                              ],
                              [
                                  -74.138379,
                                  45.444705,
                                  0
                              ],
                              [
                                  -74.137338,
                                  45.445255,
                                  0
                              ],
                              [
                                  -74.136449,
                                  45.444564,
                                  0
                              ],
                              [
                                  -74.126246,
                                  45.436678,
                                  0
                              ],
                              [
                                  -74.124442,
                                  45.435474,
                                  0
                              ],
                              [
                                  -74.12239,
                                  45.434687,
                                  0
                              ],
                              [
                                  -74.108447,
                                  45.430322,
                                  0
                              ],
                              [
                                  -74.108409,
                                  45.43028,
                                  0
                              ],
                              [
                                  -74.104432,
                                  45.432313,
                                  0
                              ],
                              [
                                  -74.103085,
                                  45.431059,
                                  0
                              ],
                              [
                                  -74.101761,
                                  45.43172,
                                  0
                              ],
                              [
                                  -74.100034,
                                  45.432584,
                                  0
                              ],
                              [
                                  -74.09872,
                                  45.43132,
                                  0
                              ],
                              [
                                  -74.09871,
                                  45.43131,
                                  0
                              ],
                              [
                                  -74.097602,
                                  45.431877,
                                  0
                              ],
                              [
                                  -74.096124,
                                  45.432635,
                                  0
                              ],
                              [
                                  -74.094927,
                                  45.431386,
                                  0
                              ],
                              [
                                  -74.093259,
                                  45.432244,
                                  0
                              ],
                              [
                                  -74.05909,
                                  45.449648,
                                  0
                              ],
                              [
                                  -74.059225,
                                  45.449738,
                                  0
                              ],
                              [
                                  -74.059222,
                                  45.44974,
                                  0
                              ],
                              [
                                  -74.05922,
                                  45.44974,
                                  0
                              ],
                              [
                                  -74.03975,
                                  45.446381,
                                  0
                              ],
                              [
                                  -73.996605,
                                  45.457485,
                                  0
                              ],
                              [
                                  -73.996579,
                                  45.457442,
                                  0
                              ],
                              [
                                  -73.996579,
                                  45.457441,
                                  0
                              ],
                              [
                                  -73.983296,
                                  45.421182,
                                  0
                              ]
                          ]
                      ]
                  },
                  {
                      "type": "Polygon",
                      "coordinates": [
                          [
                              [
                                  -74.193535,
                                  45.429046,
                                  0
                              ],
                              [
                                  -74.206231,
                                  45.432451,
                                  0
                              ],
                              [
                                  -74.206462,
                                  45.432037,
                                  0
                              ],
                              [
                                  -74.217839,
                                  45.435112,
                                  0
                              ],
                              [
                                  -74.217883,
                                  45.435124,
                                  0
                              ],
                              [
                                  -74.217893,
                                  45.435085,
                                  0
                              ],
                              [
                                  -74.217896,
                                  45.435079,
                                  0
                              ],
                              [
                                  -74.219368,
                                  45.430195,
                                  0
                              ],
                              [
                                  -74.219391,
                                  45.430199,
                                  0
                              ],
                              [
                                  -74.230078,
                                  45.431783,
                                  0
                              ],
                              [
                                  -74.21995,
                                  45.468646,
                                  0
                              ],
                              [
                                  -74.209442,
                                  45.467042,
                                  0
                              ],
                              [
                                  -74.209932,
                                  45.464285,
                                  0
                              ],
                              [
                                  -74.182377,
                                  45.457396,
                                  0
                              ],
                              [
                                  -74.187388,
                                  45.45217,
                                  0
                              ],
                              [
                                  -74.181757,
                                  45.450503,
                                  0
                              ],
                              [
                                  -74.193535,
                                  45.429046,
                                  0
                              ]
                          ]
                      ]
                  }
              ]
          },
          "properties": {
              "name": "Vaudreuil",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.18714,
                          45.897323,
                          0
                      ],
                      [
                          -73.187751,
                          45.892131,
                          0
                      ],
                      [
                          -73.187759,
                          45.892064,
                          0
                      ],
                      [
                          -73.191577,
                          45.893315,
                          0
                      ],
                      [
                          -73.191599,
                          45.893322,
                          0
                      ],
                      [
                          -73.196542,
                          45.882113,
                          0
                      ],
                      [
                          -73.199373,
                          45.883036,
                          0
                      ],
                      [
                          -73.199383,
                          45.883039,
                          0
                      ],
                      [
                          -73.202159,
                          45.879861,
                          0
                      ],
                      [
                          -73.200496,
                          45.879309,
                          0
                      ],
                      [
                          -73.204079,
                          45.875546,
                          0
                      ],
                      [
                          -73.204073,
                          45.875542,
                          0
                      ],
                      [
                          -73.184718,
                          45.861395,
                          0
                      ],
                      [
                          -73.184769,
                          45.861364,
                          0
                      ],
                      [
                          -73.18821,
                          45.859225,
                          0
                      ],
                      [
                          -73.188261,
                          45.859195,
                          0
                      ],
                      [
                          -73.193632,
                          45.850873,
                          0
                      ],
                      [
                          -73.206457,
                          45.842109,
                          0
                      ],
                      [
                          -73.209182,
                          45.838063,
                          0
                      ],
                      [
                          -73.209192,
                          45.838049,
                          0
                      ],
                      [
                          -73.212598,
                          45.834672,
                          0
                      ],
                      [
                          -73.217243,
                          45.82619,
                          0
                      ],
                      [
                          -73.217723,
                          45.825402,
                          0
                      ],
                      [
                          -73.218083,
                          45.825382,
                          0
                      ],
                      [
                          -73.226479,
                          45.814827,
                          0
                      ],
                      [
                          -73.228807,
                          45.816515,
                          0
                      ],
                      [
                          -73.242301,
                          45.808177,
                          0
                      ],
                      [
                          -73.241666,
                          45.807665,
                          0
                      ],
                      [
                          -73.242679,
                          45.80651,
                          0
                      ],
                      [
                          -73.242683,
                          45.805735,
                          0
                      ],
                      [
                          -73.240889,
                          45.804374,
                          0
                      ],
                      [
                          -73.246935,
                          45.800027,
                          0
                      ],
                      [
                          -73.246942,
                          45.800032,
                          0
                      ],
                      [
                          -73.249468,
                          45.801906,
                          0
                      ],
                      [
                          -73.251634,
                          45.799808,
                          0
                      ],
                      [
                          -73.254083,
                          45.79689,
                          0
                      ],
                      [
                          -73.256063,
                          45.795571,
                          0
                      ],
                      [
                          -73.258641,
                          45.794567,
                          0
                      ],
                      [
                          -73.261943,
                          45.793839,
                          0
                      ],
                      [
                          -73.263708,
                          45.792277,
                          0
                      ],
                      [
                          -73.264619,
                          45.79295,
                          0
                      ],
                      [
                          -73.268593,
                          45.791201,
                          0
                      ],
                      [
                          -73.25961,
                          45.784625,
                          0
                      ],
                      [
                          -73.257786,
                          45.783383,
                          0
                      ],
                      [
                          -73.259929,
                          45.782011,
                          0
                      ],
                      [
                          -73.260027,
                          45.781948,
                          0
                      ],
                      [
                          -73.262115,
                          45.783511,
                          0
                      ],
                      [
                          -73.262144,
                          45.783533,
                          0
                      ],
                      [
                          -73.26358,
                          45.781264,
                          0
                      ],
                      [
                          -73.219726,
                          45.748794,
                          0
                      ],
                      [
                          -73.231057,
                          45.73745,
                          0
                      ],
                      [
                          -73.240168,
                          45.732014,
                          0
                      ],
                      [
                          -73.23861,
                          45.730889,
                          0
                      ],
                      [
                          -73.23861,
                          45.730889,
                          0
                      ],
                      [
                          -73.238617,
                          45.730882,
                          0
                      ],
                      [
                          -73.238619,
                          45.730882,
                          0
                      ],
                      [
                          -73.244062,
                          45.72621,
                          0
                      ],
                      [
                          -73.244432,
                          45.725757,
                          0
                      ],
                      [
                          -73.276829,
                          45.69847,
                          0
                      ],
                      [
                          -73.280581,
                          45.695936,
                          0
                      ],
                      [
                          -73.283538,
                          45.69813,
                          0
                      ],
                      [
                          -73.287144,
                          45.695924,
                          0
                      ],
                      [
                          -73.279523,
                          45.689936,
                          0
                      ],
                      [
                          -73.258357,
                          45.674668,
                          0
                      ],
                      [
                          -73.258189,
                          45.667578,
                          0
                      ],
                      [
                          -73.262844,
                          45.659153,
                          0
                      ],
                      [
                          -73.263038,
                          45.658801,
                          0
                      ],
                      [
                          -73.265779,
                          45.656198,
                          0
                      ],
                      [
                          -73.270165,
                          45.65348,
                          0
                      ],
                      [
                          -73.27631,
                          45.649924,
                          0
                      ],
                      [
                          -73.261064,
                          45.638696,
                          0
                      ],
                      [
                          -73.26618,
                          45.636433,
                          0
                      ],
                      [
                          -73.267402,
                          45.635807,
                          0
                      ],
                      [
                          -73.268569,
                          45.635139,
                          0
                      ],
                      [
                          -73.27071,
                          45.634018,
                          0
                      ],
                      [
                          -73.27148,
                          45.633463,
                          0
                      ],
                      [
                          -73.27196,
                          45.632598,
                          0
                      ],
                      [
                          -73.272407,
                          45.631788,
                          0
                      ],
                      [
                          -73.273007,
                          45.630712,
                          0
                      ],
                      [
                          -73.273469,
                          45.629877,
                          0
                      ],
                      [
                          -73.273595,
                          45.628981,
                          0
                      ],
                      [
                          -73.273836,
                          45.628298,
                          0
                      ],
                      [
                          -73.274293,
                          45.627127,
                          0
                      ],
                      [
                          -73.275071,
                          45.625806,
                          0
                      ],
                      [
                          -73.275599,
                          45.625013,
                          0
                      ],
                      [
                          -73.27736,
                          45.626318,
                          0
                      ],
                      [
                          -73.278938,
                          45.624813,
                          0
                      ],
                      [
                          -73.279235,
                          45.624274,
                          0
                      ],
                      [
                          -73.279713,
                          45.62349,
                          0
                      ],
                      [
                          -73.279951,
                          45.623284,
                          0
                      ],
                      [
                          -73.280527,
                          45.622922,
                          0
                      ],
                      [
                          -73.281563,
                          45.622161,
                          0
                      ],
                      [
                          -73.281578,
                          45.622151,
                          0
                      ],
                      [
                          -73.281944,
                          45.621505,
                          0
                      ],
                      [
                          -73.283029,
                          45.619366,
                          0
                      ],
                      [
                          -73.283474,
                          45.618563,
                          0
                      ],
                      [
                          -73.284091,
                          45.617512,
                          0
                      ],
                      [
                          -73.284708,
                          45.616356,
                          0
                      ],
                      [
                          -73.285012,
                          45.61579,
                          0
                      ],
                      [
                          -73.285389,
                          45.614218,
                          0
                      ],
                      [
                          -73.287066,
                          45.611104,
                          0
                      ],
                      [
                          -73.287196,
                          45.610523,
                          0
                      ],
                      [
                          -73.288579,
                          45.609162,
                          0
                      ],
                      [
                          -73.291094,
                          45.606452,
                          0
                      ],
                      [
                          -73.292447,
                          45.605164,
                          0
                      ],
                      [
                          -73.27611,
                          45.593699,
                          0
                      ],
                      [
                          -73.276628,
                          45.593199,
                          0
                      ],
                      [
                          -73.284996,
                          45.589827,
                          0
                      ],
                      [
                          -73.291232,
                          45.58609,
                          0
                      ],
                      [
                          -73.290535,
                          45.5856,
                          0
                      ],
                      [
                          -73.290534,
                          45.585599,
                          0
                      ],
                      [
                          -73.29643,
                          45.581361,
                          0
                      ],
                      [
                          -73.296998,
                          45.580833,
                          0
                      ],
                      [
                          -73.296999,
                          45.580831,
                          0
                      ],
                      [
                          -73.297049,
                          45.580604,
                          0
                      ],
                      [
                          -73.289005,
                          45.574814,
                          0
                      ],
                      [
                          -73.292587,
                          45.55378,
                          0
                      ],
                      [
                          -73.292625,
                          45.553594,
                          0
                      ],
                      [
                          -73.311951,
                          45.567914,
                          0
                      ],
                      [
                          -73.312039,
                          45.567837,
                          0
                      ],
                      [
                          -73.31253,
                          45.566435,
                          0
                      ],
                      [
                          -73.312444,
                          45.558351,
                          0
                      ],
                      [
                          -73.313986,
                          45.556876,
                          0
                      ],
                      [
                          -73.315859,
                          45.557188,
                          0
                      ],
                      [
                          -73.317064,
                          45.557403,
                          0
                      ],
                      [
                          -73.318153,
                          45.557406,
                          0
                      ],
                      [
                          -73.318746,
                          45.557451,
                          0
                      ],
                      [
                          -73.322554,
                          45.560397,
                          0
                      ],
                      [
                          -73.324534,
                          45.559023,
                          0
                      ],
                      [
                          -73.333819,
                          45.566627,
                          0
                      ],
                      [
                          -73.338422,
                          45.563553,
                          0
                      ],
                      [
                          -73.348262,
                          45.57114,
                          0
                      ],
                      [
                          -73.349685,
                          45.563686,
                          0
                      ],
                      [
                          -73.349688,
                          45.563688,
                          0
                      ],
                      [
                          -73.362129,
                          45.573793,
                          0
                      ],
                      [
                          -73.362131,
                          45.573794,
                          0
                      ],
                      [
                          -73.361074,
                          45.579481,
                          0
                      ],
                      [
                          -73.359778,
                          45.586067,
                          0
                      ],
                      [
                          -73.360054,
                          45.589885,
                          0
                      ],
                      [
                          -73.444975,
                          45.656698,
                          0
                      ],
                      [
                          -73.476913,
                          45.643633,
                          0
                      ],
                      [
                          -73.476424,
                          45.647434,
                          0
                      ],
                      [
                          -73.480899,
                          45.65391,
                          0
                      ],
                      [
                          -73.486433,
                          45.665638,
                          0
                      ],
                      [
                          -73.486588,
                          45.67153,
                          0
                      ],
                      [
                          -73.482219,
                          45.690175,
                          0
                      ],
                      [
                          -73.477925,
                          45.698046,
                          0
                      ],
                      [
                          -73.474717,
                          45.700326,
                          0
                      ],
                      [
                          -73.474712,
                          45.700327,
                          0
                      ],
                      [
                          -73.45953,
                          45.705767,
                          0
                      ],
                      [
                          -73.446757,
                          45.716219,
                          0
                      ],
                      [
                          -73.422644,
                          45.739391,
                          0
                      ],
                      [
                          -73.421265,
                          45.74984,
                          0
                      ],
                      [
                          -73.4192,
                          45.753842,
                          0
                      ],
                      [
                          -73.38556,
                          45.789243,
                          0
                      ],
                      [
                          -73.379975,
                          45.789443,
                          0
                      ],
                      [
                          -73.370502,
                          45.785579,
                          0
                      ],
                      [
                          -73.356256,
                          45.790715,
                          0
                      ],
                      [
                          -73.348704,
                          45.793081,
                          0
                      ],
                      [
                          -73.338401,
                          45.800185,
                          0
                      ],
                      [
                          -73.325812,
                          45.803673,
                          0
                      ],
                      [
                          -73.306542,
                          45.820215,
                          0
                      ],
                      [
                          -73.287445,
                          45.834439,
                          0
                      ],
                      [
                          -73.274774,
                          45.849921,
                          0
                      ],
                      [
                          -73.274774,
                          45.849923,
                          0
                      ],
                      [
                          -73.271701,
                          45.860217,
                          0
                      ],
                      [
                          -73.243416,
                          45.892309,
                          0
                      ],
                      [
                          -73.230877,
                          45.924225,
                          0
                      ],
                      [
                          -73.186378,
                          45.984541,
                          0
                      ],
                      [
                          -73.186376,
                          45.98454,
                          0
                      ],
                      [
                          -73.179255,
                          45.979268,
                          0
                      ],
                      [
                          -73.164727,
                          45.968505,
                          0
                      ],
                      [
                          -73.168409,
                          45.96101,
                          0
                      ],
                      [
                          -73.17968,
                          45.950833,
                          0
                      ],
                      [
                          -73.183286,
                          45.941986,
                          0
                      ],
                      [
                          -73.188449,
                          45.920443,
                          0
                      ],
                      [
                          -73.188941,
                          45.914828,
                          0
                      ],
                      [
                          -73.188388,
                          45.909432,
                          0
                      ],
                      [
                          -73.187133,
                          45.897385,
                          0
                      ],
                      [
                          -73.18714,
                          45.897323,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Verchères",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.523292,
                          45.478191,
                          0
                      ],
                      [
                          -73.521197,
                          45.468694,
                          0
                      ],
                      [
                          -73.521829,
                          45.452539,
                          0
                      ],
                      [
                          -73.539336,
                          45.428376,
                          0
                      ],
                      [
                          -73.585924,
                          45.435357,
                          0
                      ],
                      [
                          -73.599502,
                          45.437522,
                          0
                      ],
                      [
                          -73.594762,
                          45.443072,
                          0
                      ],
                      [
                          -73.5926,
                          45.445301,
                          0
                      ],
                      [
                          -73.591224,
                          45.446738,
                          0
                      ],
                      [
                          -73.588889,
                          45.448767,
                          0
                      ],
                      [
                          -73.587191,
                          45.450415,
                          0
                      ],
                      [
                          -73.585417,
                          45.451981,
                          0
                      ],
                      [
                          -73.583697,
                          45.453279,
                          0
                      ],
                      [
                          -73.583015,
                          45.453952,
                          0
                      ],
                      [
                          -73.582019,
                          45.455195,
                          0
                      ],
                      [
                          -73.58109,
                          45.457016,
                          0
                      ],
                      [
                          -73.580757,
                          45.458297,
                          0
                      ],
                      [
                          -73.58067,
                          45.460666,
                          0
                      ],
                      [
                          -73.581085,
                          45.462655,
                          0
                      ],
                      [
                          -73.580963,
                          45.465456,
                          0
                      ],
                      [
                          -73.580727,
                          45.466565,
                          0
                      ],
                      [
                          -73.578861,
                          45.466483,
                          0
                      ],
                      [
                          -73.578764,
                          45.467578,
                          0
                      ],
                      [
                          -73.577696,
                          45.467531,
                          0
                      ],
                      [
                          -73.577457,
                          45.467795,
                          0
                      ],
                      [
                          -73.575969,
                          45.46773,
                          0
                      ],
                      [
                          -73.576084,
                          45.466443,
                          0
                      ],
                      [
                          -73.573227,
                          45.466318,
                          0
                      ],
                      [
                          -73.572435,
                          45.466334,
                          0
                      ],
                      [
                          -73.572247,
                          45.466395,
                          0
                      ],
                      [
                          -73.571609,
                          45.472274,
                          0
                      ],
                      [
                          -73.571337,
                          45.472514,
                          0
                      ],
                      [
                          -73.572228,
                          45.474325,
                          0
                      ],
                      [
                          -73.569692,
                          45.47466,
                          0
                      ],
                      [
                          -73.569071,
                          45.474482,
                          0
                      ],
                      [
                          -73.559881,
                          45.47109,
                          0
                      ],
                      [
                          -73.556269,
                          45.469785,
                          0
                      ],
                      [
                          -73.556119,
                          45.469944,
                          0
                      ],
                      [
                          -73.55529,
                          45.470165,
                          0
                      ],
                      [
                          -73.553331,
                          45.470597,
                          0
                      ],
                      [
                          -73.551739,
                          45.471499,
                          0
                      ],
                      [
                          -73.551735,
                          45.471501,
                          0
                      ],
                      [
                          -73.551733,
                          45.471502,
                          0
                      ],
                      [
                          -73.540941,
                          45.477121,
                          0
                      ],
                      [
                          -73.523292,
                          45.478191,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Verdun",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.605928,
                          45.576267,
                          0
                      ],
                      [
                          -73.600654,
                          45.573897,
                          0
                      ],
                      [
                          -73.601644,
                          45.572776,
                          0
                      ],
                      [
                          -73.587116,
                          45.566405,
                          0
                      ],
                      [
                          -73.58629,
                          45.566149,
                          0
                      ],
                      [
                          -73.596359,
                          45.554614,
                          0
                      ],
                      [
                          -73.596376,
                          45.554595,
                          0
                      ],
                      [
                          -73.603546,
                          45.546348,
                          0
                      ],
                      [
                          -73.603546,
                          45.546348,
                          0
                      ],
                      [
                          -73.60705,
                          45.547945,
                          0
                      ],
                      [
                          -73.607051,
                          45.547946,
                          0
                      ],
                      [
                          -73.62162,
                          45.554538,
                          0
                      ],
                      [
                          -73.645379,
                          45.564733,
                          0
                      ],
                      [
                          -73.64463,
                          45.565848,
                          0
                      ],
                      [
                          -73.636277,
                          45.575836,
                          0
                      ],
                      [
                          -73.636516,
                          45.575937,
                          0
                      ],
                      [
                          -73.632984,
                          45.580119,
                          0
                      ],
                      [
                          -73.631946,
                          45.579715,
                          0
                      ],
                      [
                          -73.628951,
                          45.58323,
                          0
                      ],
                      [
                          -73.628827,
                          45.583151,
                          0
                      ],
                      [
                          -73.626888,
                          45.585521,
                          0
                      ],
                      [
                          -73.623979,
                          45.584524,
                          0
                      ],
                      [
                          -73.623025,
                          45.583851,
                          0
                      ],
                      [
                          -73.605928,
                          45.576267,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Viau",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.691036,
                          45.677975,
                          0
                      ],
                      [
                          -73.690187,
                          45.662531,
                          0
                      ],
                      [
                          -73.685081,
                          45.651905,
                          0
                      ],
                      [
                          -73.683464,
                          45.652066,
                          0
                      ],
                      [
                          -73.680965,
                          45.649731,
                          0
                      ],
                      [
                          -73.71542,
                          45.622608,
                          0
                      ],
                      [
                          -73.70965,
                          45.617455,
                          0
                      ],
                      [
                          -73.70395,
                          45.612416,
                          0
                      ],
                      [
                          -73.702926,
                          45.611421,
                          0
                      ],
                      [
                          -73.700676,
                          45.608858,
                          0
                      ],
                      [
                          -73.69789,
                          45.604978,
                          0
                      ],
                      [
                          -73.704235,
                          45.601452,
                          0
                      ],
                      [
                          -73.713203,
                          45.593967,
                          0
                      ],
                      [
                          -73.727958,
                          45.588883,
                          0
                      ],
                      [
                          -73.762017,
                          45.623357,
                          0
                      ],
                      [
                          -73.764875,
                          45.625484,
                          0
                      ],
                      [
                          -73.767766,
                          45.626611,
                          0
                      ],
                      [
                          -73.776428,
                          45.627922,
                          0
                      ],
                      [
                          -73.778327,
                          45.636113,
                          0
                      ],
                      [
                          -73.777296,
                          45.64136,
                          0
                      ],
                      [
                          -73.768051,
                          45.648802,
                          0
                      ],
                      [
                          -73.768045,
                          45.648804,
                          0
                      ],
                      [
                          -73.757061,
                          45.65731,
                          0
                      ],
                      [
                          -73.753611,
                          45.661333,
                          0
                      ],
                      [
                          -73.75029,
                          45.663088,
                          0
                      ],
                      [
                          -73.749338,
                          45.667057,
                          0
                      ],
                      [
                          -73.745498,
                          45.668664,
                          0
                      ],
                      [
                          -73.745496,
                          45.668665,
                          0
                      ],
                      [
                          -73.745493,
                          45.668666,
                          0
                      ],
                      [
                          -73.738288,
                          45.671782,
                          0
                      ],
                      [
                          -73.736542,
                          45.675347,
                          0
                      ],
                      [
                          -73.733767,
                          45.677233,
                          0
                      ],
                      [
                          -73.727845,
                          45.680406,
                          0
                      ],
                      [
                          -73.723097,
                          45.680775,
                          0
                      ],
                      [
                          -73.711567,
                          45.680057,
                          0
                      ],
                      [
                          -73.697295,
                          45.685139,
                          0
                      ],
                      [
                          -73.694169,
                          45.688279,
                          0
                      ],
                      [
                          -73.694166,
                          45.688281,
                          0
                      ],
                      [
                          -73.692489,
                          45.68912,
                          0
                      ],
                      [
                          -73.688382,
                          45.688555,
                          0
                      ],
                      [
                          -73.685612,
                          45.687262,
                          0
                      ],
                      [
                          -73.685721,
                          45.68608,
                          0
                      ],
                      [
                          -73.691386,
                          45.682965,
                          0
                      ],
                      [
                          -73.691036,
                          45.677975,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Vimont",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.583349,
                          45.516257,
                          0
                      ],
                      [
                          -73.581696,
                          45.517994,
                          0
                      ],
                      [
                          -73.561587,
                          45.508827,
                          0
                      ],
                      [
                          -73.558995,
                          45.511696,
                          0
                      ],
                      [
                          -73.555052,
                          45.509874,
                          0
                      ],
                      [
                          -73.557259,
                          45.506872,
                          0
                      ],
                      [
                          -73.561632,
                          45.501723,
                          0
                      ],
                      [
                          -73.552882,
                          45.498737,
                          0
                      ],
                      [
                          -73.552949,
                          45.496305,
                          0
                      ],
                      [
                          -73.553078,
                          45.496065,
                          0
                      ],
                      [
                          -73.552622,
                          45.49585,
                          0
                      ],
                      [
                          -73.5533,
                          45.493401,
                          0
                      ],
                      [
                          -73.553897,
                          45.493187,
                          0
                      ],
                      [
                          -73.553902,
                          45.493196,
                          0
                      ],
                      [
                          -73.554329,
                          45.494144,
                          0
                      ],
                      [
                          -73.554824,
                          45.495242,
                          0
                      ],
                      [
                          -73.555134,
                          45.495539,
                          0
                      ],
                      [
                          -73.555705,
                          45.495932,
                          0
                      ],
                      [
                          -73.558269,
                          45.496934,
                          0
                      ],
                      [
                          -73.561343,
                          45.4983,
                          0
                      ],
                      [
                          -73.563038,
                          45.495444,
                          0
                      ],
                      [
                          -73.564805,
                          45.492937,
                          0
                      ],
                      [
                          -73.567213,
                          45.490107,
                          0
                      ],
                      [
                          -73.573046,
                          45.492894,
                          0
                      ],
                      [
                          -73.576957,
                          45.490057,
                          0
                      ],
                      [
                          -73.581551,
                          45.486746,
                          0
                      ],
                      [
                          -73.581551,
                          45.486743,
                          0
                      ],
                      [
                          -73.580609,
                          45.485568,
                          0
                      ],
                      [
                          -73.584351,
                          45.483045,
                          0
                      ],
                      [
                          -73.585132,
                          45.482208,
                          0
                      ],
                      [
                          -73.585643,
                          45.482447,
                          0
                      ],
                      [
                          -73.586271,
                          45.482525,
                          0
                      ],
                      [
                          -73.586657,
                          45.482392,
                          0
                      ],
                      [
                          -73.590635,
                          45.479576,
                          0
                      ],
                      [
                          -73.595163,
                          45.476427,
                          0
                      ],
                      [
                          -73.598474,
                          45.474103,
                          0
                      ],
                      [
                          -73.604998,
                          45.477017,
                          0
                      ],
                      [
                          -73.605404,
                          45.476573,
                          0
                      ],
                      [
                          -73.610466,
                          45.478866,
                          0
                      ],
                      [
                          -73.610459,
                          45.47887,
                          0
                      ],
                      [
                          -73.610457,
                          45.478871,
                          0
                      ],
                      [
                          -73.614341,
                          45.480671,
                          0
                      ],
                      [
                          -73.612378,
                          45.482607,
                          0
                      ],
                      [
                          -73.615151,
                          45.483858,
                          0
                      ],
                      [
                          -73.617218,
                          45.484789,
                          0
                      ],
                      [
                          -73.615291,
                          45.486789,
                          0
                      ],
                      [
                          -73.618122,
                          45.488074,
                          0
                      ],
                      [
                          -73.618424,
                          45.488211,
                          0
                      ],
                      [
                          -73.618317,
                          45.488671,
                          0
                      ],
                      [
                          -73.618408,
                          45.488977,
                          0
                      ],
                      [
                          -73.618006,
                          45.489419,
                          0
                      ],
                      [
                          -73.614757,
                          45.49303,
                          0
                      ],
                      [
                          -73.611606,
                          45.491714,
                          0
                      ],
                      [
                          -73.609399,
                          45.494054,
                          0
                      ],
                      [
                          -73.608386,
                          45.493666,
                          0
                      ],
                      [
                          -73.60663,
                          45.495431,
                          0
                      ],
                      [
                          -73.606149,
                          45.495322,
                          0
                      ],
                      [
                          -73.605133,
                          45.495259,
                          0
                      ],
                      [
                          -73.60495,
                          45.495577,
                          0
                      ],
                      [
                          -73.604162,
                          45.496034,
                          0
                      ],
                      [
                          -73.602045,
                          45.498103,
                          0
                      ],
                      [
                          -73.598729,
                          45.500985,
                          0
                      ],
                      [
                          -73.597003,
                          45.501987,
                          0
                      ],
                      [
                          -73.594764,
                          45.502816,
                          0
                      ],
                      [
                          -73.593774,
                          45.503706,
                          0
                      ],
                      [
                          -73.593397,
                          45.505357,
                          0
                      ],
                      [
                          -73.591852,
                          45.507335,
                          0
                      ],
                      [
                          -73.591144,
                          45.508249,
                          0
                      ],
                      [
                          -73.591226,
                          45.509489,
                          0
                      ],
                      [
                          -73.591907,
                          45.509916,
                          0
                      ],
                      [
                          -73.593564,
                          45.510763,
                          0
                      ],
                      [
                          -73.595738,
                          45.511295,
                          0
                      ],
                      [
                          -73.596539,
                          45.511602,
                          0
                      ],
                      [
                          -73.596719,
                          45.512173,
                          0
                      ],
                      [
                          -73.596232,
                          45.512549,
                          0
                      ],
                      [
                          -73.594232,
                          45.513357,
                          0
                      ],
                      [
                          -73.592616,
                          45.513435,
                          0
                      ],
                      [
                          -73.589501,
                          45.512944,
                          0
                      ],
                      [
                          -73.589085,
                          45.513255,
                          0
                      ],
                      [
                          -73.589131,
                          45.513642,
                          0
                      ],
                      [
                          -73.590646,
                          45.514649,
                          0
                      ],
                      [
                          -73.590872,
                          45.515294,
                          0
                      ],
                      [
                          -73.590478,
                          45.515707,
                          0
                      ],
                      [
                          -73.590264,
                          45.515779,
                          0
                      ],
                      [
                          -73.587958,
                          45.518354,
                          0
                      ],
                      [
                          -73.583349,
                          45.516257,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Westmount-Saint-Louis",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -69.673174,
                          47.983449,
                          0
                      ],
                      [
                          -69.548373,
                          48.049452,
                          0
                      ],
                      [
                          -69.548371,
                          48.049453,
                          0
                      ],
                      [
                          -69.124341,
                          48.366118,
                          0
                      ],
                      [
                          -68.927854,
                          48.242913,
                          0
                      ],
                      [
                          -68.935598,
                          48.237833,
                          0
                      ],
                      [
                          -68.939909,
                          48.240645,
                          0
                      ],
                      [
                          -68.944455,
                          48.237635,
                          0
                      ],
                      [
                          -68.92381,
                          48.222748,
                          0
                      ],
                      [
                          -68.909817,
                          48.232672,
                          0
                      ],
                      [
                          -68.505327,
                          47.962286,
                          0
                      ],
                      [
                          -68.505311,
                          47.962275,
                          0
                      ],
                      [
                          -68.38309,
                          47.880026,
                          0
                      ],
                      [
                          -68.382966,
                          47.55835,
                          0
                      ],
                      [
                          -68.563239,
                          47.431154,
                          0
                      ],
                      [
                          -68.798347,
                          47.349162,
                          0
                      ],
                      [
                          -69.052896,
                          47.298842,
                          0
                      ],
                      [
                          -69.054413,
                          47.376526,
                          0
                      ],
                      [
                          -69.045839,
                          47.383889,
                          0
                      ],
                      [
                          -69.042725,
                          47.401971,
                          0
                      ],
                      [
                          -69.031007,
                          47.406975,
                          0
                      ],
                      [
                          -69.038197,
                          47.414614,
                          0
                      ],
                      [
                          -69.041995,
                          47.423328,
                          0
                      ],
                      [
                          -69.062341,
                          47.430066,
                          0
                      ],
                      [
                          -69.090824,
                          47.42348,
                          0
                      ],
                      [
                          -69.111368,
                          47.431663,
                          0
                      ],
                      [
                          -69.120123,
                          47.441156,
                          0
                      ],
                      [
                          -69.145288,
                          47.448323,
                          0
                      ],
                      [
                          -69.158694,
                          47.448673,
                          0
                      ],
                      [
                          -69.185615,
                          47.455403,
                          0
                      ],
                      [
                          -69.20504,
                          47.451607,
                          0
                      ],
                      [
                          -69.22719,
                          47.457168,
                          0
                      ],
                      [
                          -69.389388,
                          47.298959,
                          0
                      ],
                      [
                          -69.38939,
                          47.29896,
                          0
                      ],
                      [
                          -69.588499,
                          47.431541,
                          0
                      ],
                      [
                          -69.58851,
                          47.431549,
                          0
                      ],
                      [
                          -69.305741,
                          47.626979,
                          0
                      ],
                      [
                          -69.305745,
                          47.626981,
                          0
                      ],
                      [
                          -69.305721,
                          47.62699,
                          0
                      ],
                      [
                          -69.439679,
                          47.718254,
                          0
                      ],
                      [
                          -69.493293,
                          47.665848,
                          0
                      ],
                      [
                          -69.493335,
                          47.665803,
                          0
                      ],
                      [
                          -69.493364,
                          47.665822,
                          0
                      ],
                      [
                          -69.521935,
                          47.686364,
                          0
                      ],
                      [
                          -69.538991,
                          47.697493,
                          0
                      ],
                      [
                          -69.540849,
                          47.701229,
                          0
                      ],
                      [
                          -69.539255,
                          47.703697,
                          0
                      ],
                      [
                          -69.540041,
                          47.705393,
                          0
                      ],
                      [
                          -69.546451,
                          47.707154,
                          0
                      ],
                      [
                          -69.545236,
                          47.708859,
                          0
                      ],
                      [
                          -69.537173,
                          47.715919,
                          0
                      ],
                      [
                          -69.527052,
                          47.724517,
                          0
                      ],
                      [
                          -69.527329,
                          47.727327,
                          0
                      ],
                      [
                          -69.527335,
                          47.727343,
                          0
                      ],
                      [
                          -69.544574,
                          47.728107,
                          0
                      ],
                      [
                          -69.567625,
                          47.734842,
                          0
                      ],
                      [
                          -69.587913,
                          47.735779,
                          0
                      ],
                      [
                          -69.593096,
                          47.732079,
                          0
                      ],
                      [
                          -69.600768,
                          47.733483,
                          0
                      ],
                      [
                          -69.604044,
                          47.735347,
                          0
                      ],
                      [
                          -69.604088,
                          47.735366,
                          0
                      ],
                      [
                          -69.626197,
                          47.716344,
                          0
                      ],
                      [
                          -69.639586,
                          47.712189,
                          0
                      ],
                      [
                          -69.6396,
                          47.712175,
                          0
                      ],
                      [
                          -69.726319,
                          47.769671,
                          0
                      ],
                      [
                          -69.726272,
                          47.769719,
                          0
                      ],
                      [
                          -69.591372,
                          47.929231,
                          0
                      ],
                      [
                          -69.673174,
                          47.983449,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Rivière-du-Loup-Témiscouata",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -69.673174,
                          47.983449,
                          0
                      ],
                      [
                          -69.591372,
                          47.929231,
                          0
                      ],
                      [
                          -69.726272,
                          47.769719,
                          0
                      ],
                      [
                          -69.726319,
                          47.769671,
                          0
                      ],
                      [
                          -69.6396,
                          47.712175,
                          0
                      ],
                      [
                          -69.639586,
                          47.712189,
                          0
                      ],
                      [
                          -69.626197,
                          47.716344,
                          0
                      ],
                      [
                          -69.604088,
                          47.735366,
                          0
                      ],
                      [
                          -69.604044,
                          47.735347,
                          0
                      ],
                      [
                          -69.600768,
                          47.733483,
                          0
                      ],
                      [
                          -69.593096,
                          47.732079,
                          0
                      ],
                      [
                          -69.587913,
                          47.735779,
                          0
                      ],
                      [
                          -69.567625,
                          47.734842,
                          0
                      ],
                      [
                          -69.544574,
                          47.728107,
                          0
                      ],
                      [
                          -69.527335,
                          47.727343,
                          0
                      ],
                      [
                          -69.527329,
                          47.727327,
                          0
                      ],
                      [
                          -69.527052,
                          47.724517,
                          0
                      ],
                      [
                          -69.537173,
                          47.715919,
                          0
                      ],
                      [
                          -69.545236,
                          47.708859,
                          0
                      ],
                      [
                          -69.546451,
                          47.707154,
                          0
                      ],
                      [
                          -69.540041,
                          47.705393,
                          0
                      ],
                      [
                          -69.539255,
                          47.703697,
                          0
                      ],
                      [
                          -69.540849,
                          47.701229,
                          0
                      ],
                      [
                          -69.538991,
                          47.697493,
                          0
                      ],
                      [
                          -69.521935,
                          47.686364,
                          0
                      ],
                      [
                          -69.493364,
                          47.665822,
                          0
                      ],
                      [
                          -69.493335,
                          47.665803,
                          0
                      ],
                      [
                          -69.493293,
                          47.665848,
                          0
                      ],
                      [
                          -69.439679,
                          47.718254,
                          0
                      ],
                      [
                          -69.305721,
                          47.62699,
                          0
                      ],
                      [
                          -69.305745,
                          47.626981,
                          0
                      ],
                      [
                          -69.305741,
                          47.626979,
                          0
                      ],
                      [
                          -69.58851,
                          47.431549,
                          0
                      ],
                      [
                          -69.588499,
                          47.431541,
                          0
                      ],
                      [
                          -69.38939,
                          47.29896,
                          0
                      ],
                      [
                          -69.389388,
                          47.298959,
                          0
                      ],
                      [
                          -69.998435,
                          46.689328,
                          0
                      ],
                      [
                          -70.046754,
                          46.462761,
                          0
                      ],
                      [
                          -70.046791,
                          46.462756,
                          0
                      ],
                      [
                          -70.18897,
                          46.563261,
                          0
                      ],
                      [
                          -70.188968,
                          46.563262,
                          0
                      ],
                      [
                          -70.162693,
                          46.581461,
                          0
                      ],
                      [
                          -70.297184,
                          46.676423,
                          0
                      ],
                      [
                          -70.324384,
                          46.658016,
                          0
                      ],
                      [
                          -70.324427,
                          46.658046,
                          0
                      ],
                      [
                          -70.44345,
                          46.742379,
                          0
                      ],
                      [
                          -70.477583,
                          46.718946,
                          0
                      ],
                      [
                          -70.48025,
                          46.72078,
                          0
                      ],
                      [
                          -70.481412,
                          46.723253,
                          0
                      ],
                      [
                          -70.480226,
                          46.725053,
                          0
                      ],
                      [
                          -70.480602,
                          46.730244,
                          0
                      ],
                      [
                          -70.484104,
                          46.731068,
                          0
                      ],
                      [
                          -70.484105,
                          46.731069,
                          0
                      ],
                      [
                          -70.48494,
                          46.732046,
                          0
                      ],
                      [
                          -70.48898,
                          46.731912,
                          0
                      ],
                      [
                          -70.490921,
                          46.732286,
                          0
                      ],
                      [
                          -70.492943,
                          46.734952,
                          0
                      ],
                      [
                          -70.492336,
                          46.735929,
                          0
                      ],
                      [
                          -70.493795,
                          46.737127,
                          0
                      ],
                      [
                          -70.489969,
                          46.739756,
                          0
                      ],
                      [
                          -70.496718,
                          46.744397,
                          0
                      ],
                      [
                          -70.495201,
                          46.745807,
                          0
                      ],
                      [
                          -70.503223,
                          46.751308,
                          0
                      ],
                      [
                          -70.501039,
                          46.753422,
                          0
                      ],
                      [
                          -70.497589,
                          46.755004,
                          0
                      ],
                      [
                          -70.496333,
                          46.754764,
                          0
                      ],
                      [
                          -70.494995,
                          46.755783,
                          0
                      ],
                      [
                          -70.4939,
                          46.75636,
                          0
                      ],
                      [
                          -70.493898,
                          46.756359,
                          0
                      ],
                      [
                          -70.493675,
                          46.755148,
                          0
                      ],
                      [
                          -70.492371,
                          46.75612,
                          0
                      ],
                      [
                          -70.48286,
                          46.757509,
                          0
                      ],
                      [
                          -70.481552,
                          46.758891,
                          0
                      ],
                      [
                          -70.475065,
                          46.762465,
                          0
                      ],
                      [
                          -70.497762,
                          46.777691,
                          0
                      ],
                      [
                          -70.497754,
                          46.777696,
                          0
                      ],
                      [
                          -70.495991,
                          46.778931,
                          0
                      ],
                      [
                          -70.579244,
                          46.836781,
                          0
                      ],
                      [
                          -70.629957,
                          46.802125,
                          0
                      ],
                      [
                          -70.678269,
                          46.835441,
                          0
                      ],
                      [
                          -70.684897,
                          46.834348,
                          0
                      ],
                      [
                          -70.691474,
                          46.818691,
                          0
                      ],
                      [
                          -70.695554,
                          46.81353,
                          0
                      ],
                      [
                          -70.703742,
                          46.806452,
                          0
                      ],
                      [
                          -70.725632,
                          46.821191,
                          0
                      ],
                      [
                          -70.72562,
                          46.8212,
                          0
                      ],
                      [
                          -70.724785,
                          46.821725,
                          0
                      ],
                      [
                          -70.746954,
                          46.836779,
                          0
                      ],
                      [
                          -70.7455,
                          46.837682,
                          0
                      ],
                      [
                          -70.77119,
                          46.855275,
                          0
                      ],
                      [
                          -70.766617,
                          46.857767,
                          0
                      ],
                      [
                          -70.769281,
                          46.859648,
                          0
                      ],
                      [
                          -70.767912,
                          46.860561,
                          0
                      ],
                      [
                          -70.763337,
                          46.857373,
                          0
                      ],
                      [
                          -70.762273,
                          46.858132,
                          0
                      ],
                      [
                          -70.767468,
                          46.861797,
                          0
                      ],
                      [
                          -70.766438,
                          46.86272,
                          0
                      ],
                      [
                          -70.765084,
                          46.861812,
                          0
                      ],
                      [
                          -70.760817,
                          46.865474,
                          0
                      ],
                      [
                          -70.756012,
                          46.868233,
                          0
                      ],
                      [
                          -70.757444,
                          46.869242,
                          0
                      ],
                      [
                          -70.757424,
                          46.869257,
                          0
                      ],
                      [
                          -70.756335,
                          46.870067,
                          0
                      ],
                      [
                          -70.759134,
                          46.871985,
                          0
                      ],
                      [
                          -70.758115,
                          46.872713,
                          0
                      ],
                      [
                          -70.759665,
                          46.874001,
                          0
                      ],
                      [
                          -70.756089,
                          46.87564,
                          0
                      ],
                      [
                          -70.75511,
                          46.874919,
                          0
                      ],
                      [
                          -70.754184,
                          46.87551,
                          0
                      ],
                      [
                          -70.755727,
                          46.876587,
                          0
                      ],
                      [
                          -70.754184,
                          46.877595,
                          0
                      ],
                      [
                          -70.759057,
                          46.880993,
                          0
                      ],
                      [
                          -70.75756,
                          46.881941,
                          0
                      ],
                      [
                          -70.757979,
                          46.882253,
                          0
                      ],
                      [
                          -70.756997,
                          46.882918,
                          0
                      ],
                      [
                          -70.752502,
                          46.885137,
                          0
                      ],
                      [
                          -70.782551,
                          46.906511,
                          0
                      ],
                      [
                          -70.782552,
                          46.906512,
                          0
                      ],
                      [
                          -70.781788,
                          46.906307,
                          0
                      ],
                      [
                          -70.780209,
                          46.90542,
                          0
                      ],
                      [
                          -70.778602,
                          46.905868,
                          0
                      ],
                      [
                          -70.781378,
                          46.908174,
                          0
                      ],
                      [
                          -70.817919,
                          46.934176,
                          0
                      ],
                      [
                          -70.671882,
                          47.068055,
                          0
                      ],
                      [
                          -70.514474,
                          47.173587,
                          0
                      ],
                      [
                          -70.463304,
                          47.246116,
                          0
                      ],
                      [
                          -70.300433,
                          47.326589,
                          0
                      ],
                      [
                          -70.103777,
                          47.523009,
                          0
                      ],
                      [
                          -69.673174,
                          47.983449,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Côte-du-Sud",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -67.153346,
                          49.189066,
                          0
                      ],
                      [
                          -66.832404,
                          48.98514,
                          0
                      ],
                      [
                          -66.733571,
                          49.01748,
                          0
                      ],
                      [
                          -66.706177,
                          49.000007,
                          0
                      ],
                      [
                          -66.701071,
                          48.986573,
                          0
                      ],
                      [
                          -66.702313,
                          48.977171,
                          0
                      ],
                      [
                          -66.698048,
                          48.973548,
                          0
                      ],
                      [
                          -66.700349,
                          48.969282,
                          0
                      ],
                      [
                          -66.696713,
                          48.965394,
                          0
                      ],
                      [
                          -66.69901,
                          48.961086,
                          0
                      ],
                      [
                          -66.700639,
                          48.956639,
                          0
                      ],
                      [
                          -66.695579,
                          48.953506,
                          0
                      ],
                      [
                          -66.698313,
                          48.949165,
                          0
                      ],
                      [
                          -66.69562,
                          48.944691,
                          0
                      ],
                      [
                          -66.69758,
                          48.940179,
                          0
                      ],
                      [
                          -66.6953,
                          48.93573,
                          0
                      ],
                      [
                          -66.700304,
                          48.926886,
                          0
                      ],
                      [
                          -66.695715,
                          48.923488,
                          0
                      ],
                      [
                          -66.699114,
                          48.919334,
                          0
                      ],
                      [
                          -66.713129,
                          48.910169,
                          0
                      ],
                      [
                          -66.704415,
                          48.902873,
                          0
                      ],
                      [
                          -66.537316,
                          48.957827,
                          0
                      ],
                      [
                          -65.948499,
                          48.567089,
                          0
                      ],
                      [
                          -66.97416,
                          48.303156,
                          0
                      ],
                      [
                          -66.843247,
                          48.152108,
                          0
                      ],
                      [
                          -66.836773,
                          48.147513,
                          0
                      ],
                      [
                          -66.870208,
                          48.177387,
                          0
                      ],
                      [
                          -66.900372,
                          48.200355,
                          0
                      ],
                      [
                          -66.934527,
                          48.18113,
                          0
                      ],
                      [
                          -66.879576,
                          48.136969,
                          0
                      ],
                      [
                          -67.277548,
                          48.031538,
                          0
                      ],
                      [
                          -67.374604,
                          48.194893,
                          0
                      ],
                      [
                          -67.665399,
                          48.118834,
                          0
                      ],
                      [
                          -67.649476,
                          48.081511,
                          0
                      ],
                      [
                          -67.630002,
                          48.076717,
                          0
                      ],
                      [
                          -67.606251,
                          48.061057,
                          0
                      ],
                      [
                          -67.586707,
                          48.048543,
                          0
                      ],
                      [
                          -67.595549,
                          48.027138,
                          0
                      ],
                      [
                          -67.591981,
                          48.007755,
                          0
                      ],
                      [
                          -67.596574,
                          48.001552,
                          0
                      ],
                      [
                          -67.950226,
                          48.000059,
                          0
                      ],
                      [
                          -67.950395,
                          48.096603,
                          0
                      ],
                      [
                          -68.257184,
                          48.293665,
                          0
                      ],
                      [
                          -68.183069,
                          48.349084,
                          0
                      ],
                      [
                          -68.267673,
                          48.40601,
                          0
                      ],
                      [
                          -68.299418,
                          48.417334,
                          0
                      ],
                      [
                          -68.273742,
                          48.436801,
                          0
                      ],
                      [
                          -68.644172,
                          48.66379,
                          0
                      ],
                      [
                          -67.825346,
                          49.000068,
                          0
                      ],
                      [
                          -67.153346,
                          49.189066,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Matane-Matapédia",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -72.095927,
                          45.033425,
                          0
                      ],
                      [
                          -72.095928,
                          45.033448,
                          0
                      ],
                      [
                          -72.09831,
                          45.160861,
                          0
                      ],
                      [
                          -72.098311,
                          45.160909,
                          0
                      ],
                      [
                          -71.917244,
                          45.160109,
                          0
                      ],
                      [
                          -71.917164,
                          45.160105,
                          0
                      ],
                      [
                          -71.917105,
                          45.160103,
                          0
                      ],
                      [
                          -71.920876,
                          45.300011,
                          0
                      ],
                      [
                          -71.919525,
                          45.302604,
                          0
                      ],
                      [
                          -71.919949,
                          45.304597,
                          0
                      ],
                      [
                          -71.919968,
                          45.305695,
                          0
                      ],
                      [
                          -71.918794,
                          45.305498,
                          0
                      ],
                      [
                          -71.917955,
                          45.304979,
                          0
                      ],
                      [
                          -71.915897,
                          45.303777,
                          0
                      ],
                      [
                          -71.914341,
                          45.303632,
                          0
                      ],
                      [
                          -71.913012,
                          45.303072,
                          0
                      ],
                      [
                          -71.91225,
                          45.304195,
                          0
                      ],
                      [
                          -71.909554,
                          45.307542,
                          0
                      ],
                      [
                          -71.905919,
                          45.307498,
                          0
                      ],
                      [
                          -71.904378,
                          45.308639,
                          0
                      ],
                      [
                          -71.904301,
                          45.31008,
                          0
                      ],
                      [
                          -71.903123,
                          45.311055,
                          0
                      ],
                      [
                          -71.902517,
                          45.3112,
                          0
                      ],
                      [
                          -71.901358,
                          45.310879,
                          0
                      ],
                      [
                          -71.899871,
                          45.309942,
                          0
                      ],
                      [
                          -71.898647,
                          45.310647,
                          0
                      ],
                      [
                          -71.898793,
                          45.311193,
                          0
                      ],
                      [
                          -71.900197,
                          45.31232,
                          0
                      ],
                      [
                          -71.899131,
                          45.313571,
                          0
                      ],
                      [
                          -71.896105,
                          45.317274,
                          0
                      ],
                      [
                          -71.894522,
                          45.318805,
                          0
                      ],
                      [
                          -71.891807,
                          45.32219,
                          0
                      ],
                      [
                          -71.890826,
                          45.322972,
                          0
                      ],
                      [
                          -71.88885,
                          45.323245,
                          0
                      ],
                      [
                          -71.887393,
                          45.323159,
                          0
                      ],
                      [
                          -71.885346,
                          45.324164,
                          0
                      ],
                      [
                          -71.884423,
                          45.325872,
                          0
                      ],
                      [
                          -71.884948,
                          45.327603,
                          0
                      ],
                      [
                          -71.884921,
                          45.328841,
                          0
                      ],
                      [
                          -71.883985,
                          45.329342,
                          0
                      ],
                      [
                          -71.883982,
                          45.329343,
                          0
                      ],
                      [
                          -71.883441,
                          45.330308,
                          0
                      ],
                      [
                          -71.883752,
                          45.331911,
                          0
                      ],
                      [
                          -71.881605,
                          45.333243,
                          0
                      ],
                      [
                          -71.876503,
                          45.332135,
                          0
                      ],
                      [
                          -71.873821,
                          45.333872,
                          0
                      ],
                      [
                          -71.87256,
                          45.334703,
                          0
                      ],
                      [
                          -71.871777,
                          45.337534,
                          0
                      ],
                      [
                          -71.870724,
                          45.340978,
                          0
                      ],
                      [
                          -71.868275,
                          45.342864,
                          0
                      ],
                      [
                          -71.867666,
                          45.343361,
                          0
                      ],
                      [
                          -71.868142,
                          45.344509,
                          0
                      ],
                      [
                          -71.869886,
                          45.346352,
                          0
                      ],
                      [
                          -71.862859,
                          45.35046,
                          0
                      ],
                      [
                          -71.875714,
                          45.350719,
                          0
                      ],
                      [
                          -71.876177,
                          45.359496,
                          0
                      ],
                      [
                          -71.877273,
                          45.380271,
                          0
                      ],
                      [
                          -71.88031,
                          45.380469,
                          0
                      ],
                      [
                          -71.882607,
                          45.385839,
                          0
                      ],
                      [
                          -71.878343,
                          45.385475,
                          0
                      ],
                      [
                          -71.879284,
                          45.386088,
                          0
                      ],
                      [
                          -71.880482,
                          45.388155,
                          0
                      ],
                      [
                          -71.880372,
                          45.389235,
                          0
                      ],
                      [
                          -71.880294,
                          45.391499,
                          0
                      ],
                      [
                          -71.881321,
                          45.39513,
                          0
                      ],
                      [
                          -71.88245,
                          45.396694,
                          0
                      ],
                      [
                          -71.884451,
                          45.399823,
                          0
                      ],
                      [
                          -71.8855,
                          45.401668,
                          0
                      ],
                      [
                          -71.887948,
                          45.40394,
                          0
                      ],
                      [
                          -71.890169,
                          45.406563,
                          0
                      ],
                      [
                          -71.892514,
                          45.408797,
                          0
                      ],
                      [
                          -71.894443,
                          45.41004,
                          0
                      ],
                      [
                          -71.900096,
                          45.410506,
                          0
                      ],
                      [
                          -71.901028,
                          45.411031,
                          0
                      ],
                      [
                          -71.901611,
                          45.412391,
                          0
                      ],
                      [
                          -71.902312,
                          45.414547,
                          0
                      ],
                      [
                          -71.903671,
                          45.417228,
                          0
                      ],
                      [
                          -71.904934,
                          45.418976,
                          0
                      ],
                      [
                          -71.906845,
                          45.421084,
                          0
                      ],
                      [
                          -71.907964,
                          45.422881,
                          0
                      ],
                      [
                          -71.909149,
                          45.424804,
                          0
                      ],
                      [
                          -71.910237,
                          45.428262,
                          0
                      ],
                      [
                          -71.911597,
                          45.429952,
                          0
                      ],
                      [
                          -71.912995,
                          45.43174,
                          0
                      ],
                      [
                          -71.914278,
                          45.433565,
                          0
                      ],
                      [
                          -71.915074,
                          45.436169,
                          0
                      ],
                      [
                          -71.915812,
                          45.438111,
                          0
                      ],
                      [
                          -71.918241,
                          45.440033,
                          0
                      ],
                      [
                          -71.922454,
                          45.442395,
                          0
                      ],
                      [
                          -71.94301,
                          45.438362,
                          0
                      ],
                      [
                          -71.943017,
                          45.438361,
                          0
                      ],
                      [
                          -71.946799,
                          45.438091,
                          0
                      ],
                      [
                          -71.948568,
                          45.43825,
                          0
                      ],
                      [
                          -71.956852,
                          45.439748,
                          0
                      ],
                      [
                          -71.958074,
                          45.438135,
                          0
                      ],
                      [
                          -71.975523,
                          45.438064,
                          0
                      ],
                      [
                          -71.975471,
                          45.448754,
                          0
                      ],
                      [
                          -71.994977,
                          45.448582,
                          0
                      ],
                      [
                          -71.994979,
                          45.448673,
                          0
                      ],
                      [
                          -72.035444,
                          45.470112,
                          0
                      ],
                      [
                          -72.03545,
                          45.470115,
                          0
                      ],
                      [
                          -72.035375,
                          45.470187,
                          0
                      ],
                      [
                          -72.023344,
                          45.482142,
                          0
                      ],
                      [
                          -72.023282,
                          45.482224,
                          0
                      ],
                      [
                          -72.012689,
                          45.49618,
                          0
                      ],
                      [
                          -71.987141,
                          45.518969,
                          0
                      ],
                      [
                          -71.983333,
                          45.51297,
                          0
                      ],
                      [
                          -71.982601,
                          45.50568,
                          0
                      ],
                      [
                          -71.978697,
                          45.50261,
                          0
                      ],
                      [
                          -71.966325,
                          45.513708,
                          0
                      ],
                      [
                          -71.960981,
                          45.510794,
                          0
                      ],
                      [
                          -71.948152,
                          45.522127,
                          0
                      ],
                      [
                          -71.932525,
                          45.513246,
                          0
                      ],
                      [
                          -71.919963,
                          45.523982,
                          0
                      ],
                      [
                          -71.919865,
                          45.524064,
                          0
                      ],
                      [
                          -71.914547,
                          45.521001,
                          0
                      ],
                      [
                          -71.913271,
                          45.519623,
                          0
                      ],
                      [
                          -71.910561,
                          45.514113,
                          0
                      ],
                      [
                          -71.877598,
                          45.495538,
                          0
                      ],
                      [
                          -71.890079,
                          45.48485,
                          0
                      ],
                      [
                          -71.890091,
                          45.48484,
                          0
                      ],
                      [
                          -71.890088,
                          45.484838,
                          0
                      ],
                      [
                          -71.879534,
                          45.478674,
                          0
                      ],
                      [
                          -71.893619,
                          45.466848,
                          0
                      ],
                      [
                          -71.893613,
                          45.466845,
                          0
                      ],
                      [
                          -71.869628,
                          45.453128,
                          0
                      ],
                      [
                          -71.821567,
                          45.453332,
                          0
                      ],
                      [
                          -71.821567,
                          45.453329,
                          0
                      ],
                      [
                          -71.820022,
                          45.407924,
                          0
                      ],
                      [
                          -71.819996,
                          45.407922,
                          0
                      ],
                      [
                          -71.802686,
                          45.408123,
                          0
                      ],
                      [
                          -71.806189,
                          45.400422,
                          0
                      ],
                      [
                          -71.806338,
                          45.398786,
                          0
                      ],
                      [
                          -71.808911,
                          45.392546,
                          0
                      ],
                      [
                          -71.809874,
                          45.391676,
                          0
                      ],
                      [
                          -71.811222,
                          45.390617,
                          0
                      ],
                      [
                          -71.814068,
                          45.389537,
                          0
                      ],
                      [
                          -71.815322,
                          45.387897,
                          0
                      ],
                      [
                          -71.815582,
                          45.386228,
                          0
                      ],
                      [
                          -71.815507,
                          45.384404,
                          0
                      ],
                      [
                          -71.816462,
                          45.382602,
                          0
                      ],
                      [
                          -71.816673,
                          45.381187,
                          0
                      ],
                      [
                          -71.817602,
                          45.379411,
                          0
                      ],
                      [
                          -71.818725,
                          45.378736,
                          0
                      ],
                      [
                          -71.818393,
                          45.368424,
                          0
                      ],
                      [
                          -71.812079,
                          45.367118,
                          0
                      ],
                      [
                          -71.81161,
                          45.358248,
                          0
                      ],
                      [
                          -71.817695,
                          45.358085,
                          0
                      ],
                      [
                          -71.815731,
                          45.306272,
                          0
                      ],
                      [
                          -71.814898,
                          45.306143,
                          0
                      ],
                      [
                          -71.738645,
                          45.305116,
                          0
                      ],
                      [
                          -71.646359,
                          45.306591,
                          0
                      ],
                      [
                          -71.64628,
                          45.306484,
                          0
                      ],
                      [
                          -71.644406,
                          45.306494,
                          0
                      ],
                      [
                          -71.643057,
                          45.248357,
                          0
                      ],
                      [
                          -71.643057,
                          45.248351,
                          0
                      ],
                      [
                          -71.624084,
                          45.247645,
                          0
                      ],
                      [
                          -71.605028,
                          45.248179,
                          0
                      ],
                      [
                          -71.605025,
                          45.248083,
                          0
                      ],
                      [
                          -71.604596,
                          45.237774,
                          0
                      ],
                      [
                          -71.407742,
                          45.240866,
                          0
                      ],
                      [
                          -71.417692,
                          45.234971,
                          0
                      ],
                      [
                          -71.430341,
                          45.236271,
                          0
                      ],
                      [
                          -71.44282,
                          45.238293,
                          0
                      ],
                      [
                          -71.434617,
                          45.23089,
                          0
                      ],
                      [
                          -71.42503,
                          45.224948,
                          0
                      ],
                      [
                          -71.415827,
                          45.218383,
                          0
                      ],
                      [
                          -71.403782,
                          45.215417,
                          0
                      ],
                      [
                          -71.398741,
                          45.207099,
                          0
                      ],
                      [
                          -71.403553,
                          45.198582,
                          0
                      ],
                      [
                          -71.407049,
                          45.189814,
                          0
                      ],
                      [
                          -71.415386,
                          45.1825,
                          0
                      ],
                      [
                          -71.417699,
                          45.173197,
                          0
                      ],
                      [
                          -71.42491,
                          45.16574,
                          0
                      ],
                      [
                          -71.42486,
                          45.156725,
                          0
                      ],
                      [
                          -71.432218,
                          45.148884,
                          0
                      ],
                      [
                          -71.436609,
                          45.14039,
                          0
                      ],
                      [
                          -71.428643,
                          45.133192,
                          0
                      ],
                      [
                          -71.429106,
                          45.124192,
                          0
                      ],
                      [
                          -71.438103,
                          45.11751,
                          0
                      ],
                      [
                          -71.448781,
                          45.112555,
                          0
                      ],
                      [
                          -71.450694,
                          45.103488,
                          0
                      ],
                      [
                          -71.459353,
                          45.09683,
                          0
                      ],
                      [
                          -71.466357,
                          45.08881,
                          0
                      ],
                      [
                          -71.476417,
                          45.083,
                          0
                      ],
                      [
                          -71.486801,
                          45.077722,
                          0
                      ],
                      [
                          -71.494755,
                          45.070579,
                          0
                      ],
                      [
                          -71.497444,
                          45.061224,
                          0
                      ],
                      [
                          -71.500558,
                          45.052251,
                          0
                      ],
                      [
                          -71.492221,
                          45.045171,
                          0
                      ],
                      [
                          -71.492253,
                          45.036141,
                          0
                      ],
                      [
                          -71.498698,
                          45.028308,
                          0
                      ],
                      [
                          -71.50086,
                          45.019359,
                          0
                      ],
                      [
                          -71.538836,
                          45.012945,
                          0
                      ],
                      [
                          -71.631963,
                          45.012467,
                          0
                      ],
                      [
                          -71.754116,
                          45.011337,
                          0
                      ],
                      [
                          -71.846987,
                          45.009276,
                          0
                      ],
                      [
                          -72.03206,
                          45.00662,
                          0
                      ],
                      [
                          -72.040373,
                          45.006501,
                          0
                      ],
                      [
                          -72.040637,
                          45.022766,
                          0
                      ],
                      [
                          -72.077189,
                          45.021723,
                          0
                      ],
                      [
                          -72.077514,
                          45.032619,
                          0
                      ],
                      [
                          -72.095927,
                          45.033425,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Saint-François",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -71.919865,
                          45.524064,
                          0
                      ],
                      [
                          -71.77466,
                          45.650731,
                          0
                      ],
                      [
                          -71.774639,
                          45.650751,
                          0
                      ],
                      [
                          -71.715824,
                          45.617275,
                          0
                      ],
                      [
                          -71.691845,
                          45.634944,
                          0
                      ],
                      [
                          -71.707984,
                          45.645661,
                          0
                      ],
                      [
                          -71.611474,
                          45.712512,
                          0
                      ],
                      [
                          -71.585529,
                          45.692807,
                          0
                      ],
                      [
                          -71.436094,
                          45.799182,
                          0
                      ],
                      [
                          -71.461405,
                          45.816619,
                          0
                      ],
                      [
                          -71.461406,
                          45.81662,
                          0
                      ],
                      [
                          -71.45921,
                          45.818122,
                          0
                      ],
                      [
                          -71.459207,
                          45.818124,
                          0
                      ],
                      [
                          -71.466356,
                          45.821665,
                          0
                      ],
                      [
                          -71.463396,
                          45.834517,
                          0
                      ],
                      [
                          -71.463364,
                          45.834509,
                          0
                      ],
                      [
                          -71.455441,
                          45.872098,
                          0
                      ],
                      [
                          -71.489736,
                          45.889435,
                          0
                      ],
                      [
                          -71.489786,
                          45.889464,
                          0
                      ],
                      [
                          -71.485973,
                          45.89325,
                          0
                      ],
                      [
                          -71.486007,
                          45.893273,
                          0
                      ],
                      [
                          -71.487405,
                          45.894211,
                          0
                      ],
                      [
                          -71.486011,
                          45.894528,
                          0
                      ],
                      [
                          -71.485157,
                          45.894761,
                          0
                      ],
                      [
                          -71.483211,
                          45.896497,
                          0
                      ],
                      [
                          -71.482342,
                          45.897869,
                          0
                      ],
                      [
                          -71.482617,
                          45.899606,
                          0
                      ],
                      [
                          -71.481932,
                          45.901102,
                          0
                      ],
                      [
                          -71.476626,
                          45.904306,
                          0
                      ],
                      [
                          -71.472542,
                          45.905784,
                          0
                      ],
                      [
                          -71.471469,
                          45.906336,
                          0
                      ],
                      [
                          -71.464328,
                          45.907982,
                          0
                      ],
                      [
                          -71.45885,
                          45.908999,
                          0
                      ],
                      [
                          -71.457397,
                          45.90963,
                          0
                      ],
                      [
                          -71.456021,
                          45.910673,
                          0
                      ],
                      [
                          -71.456132,
                          45.910969,
                          0
                      ],
                      [
                          -71.456882,
                          45.912029,
                          0
                      ],
                      [
                          -71.456171,
                          45.91292,
                          0
                      ],
                      [
                          -71.45649,
                          45.91468,
                          0
                      ],
                      [
                          -71.455617,
                          45.915285,
                          0
                      ],
                      [
                          -71.456485,
                          45.915777,
                          0
                      ],
                      [
                          -71.445812,
                          45.926195,
                          0
                      ],
                      [
                          -71.458518,
                          45.932806,
                          0
                      ],
                      [
                          -71.412342,
                          45.977683,
                          0
                      ],
                      [
                          -71.311716,
                          45.924889,
                          0
                      ],
                      [
                          -71.310878,
                          45.927535,
                          0
                      ],
                      [
                          -71.309081,
                          45.928943,
                          0
                      ],
                      [
                          -71.307269,
                          45.929189,
                          0
                      ],
                      [
                          -71.306652,
                          45.930898,
                          0
                      ],
                      [
                          -71.305189,
                          45.932201,
                          0
                      ],
                      [
                          -71.302341,
                          45.931722,
                          0
                      ],
                      [
                          -71.297949,
                          45.933517,
                          0
                      ],
                      [
                          -71.29183,
                          45.933816,
                          0
                      ],
                      [
                          -71.290084,
                          45.9347,
                          0
                      ],
                      [
                          -71.289578,
                          45.936722,
                          0
                      ],
                      [
                          -71.286775,
                          45.937546,
                          0
                      ],
                      [
                          -71.285062,
                          45.938781,
                          0
                      ],
                      [
                          -71.282333,
                          45.939532,
                          0
                      ],
                      [
                          -71.279323,
                          45.942023,
                          0
                      ],
                      [
                          -71.277714,
                          45.94538,
                          0
                      ],
                      [
                          -71.274495,
                          45.94782,
                          0
                      ],
                      [
                          -71.273579,
                          45.94945,
                          0
                      ],
                      [
                          -71.269552,
                          45.951113,
                          0
                      ],
                      [
                          -71.269479,
                          45.951134,
                          0
                      ],
                      [
                          -71.258167,
                          45.957212,
                          0
                      ],
                      [
                          -71.243471,
                          45.965636,
                          0
                      ],
                      [
                          -71.234713,
                          45.967923,
                          0
                      ],
                      [
                          -71.228098,
                          45.968113,
                          0
                      ],
                      [
                          -71.224601,
                          45.967504,
                          0
                      ],
                      [
                          -71.203355,
                          45.960278,
                          0
                      ],
                      [
                          -71.194853,
                          45.956223,
                          0
                      ],
                      [
                          -71.189322,
                          45.952306,
                          0
                      ],
                      [
                          -71.163282,
                          45.924404,
                          0
                      ],
                      [
                          -71.163281,
                          45.924403,
                          0
                      ],
                      [
                          -71.129778,
                          45.957377,
                          0
                      ],
                      [
                          -71.12977,
                          45.957376,
                          0
                      ],
                      [
                          -71.070687,
                          45.928299,
                          0
                      ],
                      [
                          -71.070661,
                          45.92829,
                          0
                      ],
                      [
                          -71.070656,
                          45.928288,
                          0
                      ],
                      [
                          -71.070622,
                          45.928273,
                          0
                      ],
                      [
                          -71.070624,
                          45.928271,
                          0
                      ],
                      [
                          -71.081161,
                          45.917607,
                          0
                      ],
                      [
                          -71.081166,
                          45.917609,
                          0
                      ],
                      [
                          -71.082318,
                          45.918135,
                          0
                      ],
                      [
                          -71.08232,
                          45.918134,
                          0
                      ],
                      [
                          -71.093746,
                          45.90701,
                          0
                      ],
                      [
                          -71.01829,
                          45.869809,
                          0
                      ],
                      [
                          -71.029207,
                          45.859407,
                          0
                      ],
                      [
                          -71.029308,
                          45.859316,
                          0
                      ],
                      [
                          -71.029232,
                          45.859279,
                          0
                      ],
                      [
                          -70.990245,
                          45.839917,
                          0
                      ],
                      [
                          -70.990244,
                          45.839916,
                          0
                      ],
                      [
                          -70.979022,
                          45.850722,
                          0
                      ],
                      [
                          -70.947803,
                          45.835421,
                          0
                      ],
                      [
                          -70.936879,
                          45.845952,
                          0
                      ],
                      [
                          -70.915134,
                          45.836022,
                          0
                      ],
                      [
                          -70.914842,
                          45.841566,
                          0
                      ],
                      [
                          -70.898397,
                          45.84171,
                          0
                      ],
                      [
                          -70.898394,
                          45.84171,
                          0
                      ],
                      [
                          -70.895632,
                          45.780026,
                          0
                      ],
                      [
                          -70.89563,
                          45.779991,
                          0
                      ],
                      [
                          -70.89563,
                          45.779989,
                          0
                      ],
                      [
                          -70.724448,
                          45.782119,
                          0
                      ],
                      [
                          -70.724347,
                          45.782121,
                          0
                      ],
                      [
                          -70.724348,
                          45.78212,
                          0
                      ],
                      [
                          -70.783881,
                          45.725767,
                          0
                      ],
                      [
                          -70.783865,
                          45.725758,
                          0
                      ],
                      [
                          -70.751196,
                          45.709133,
                          0
                      ],
                      [
                          -70.751166,
                          45.709118,
                          0
                      ],
                      [
                          -70.749824,
                          45.709672,
                          0
                      ],
                      [
                          -70.74673,
                          45.710506,
                          0
                      ],
                      [
                          -70.746716,
                          45.71051,
                          0
                      ],
                      [
                          -70.745026,
                          45.71077,
                          0
                      ],
                      [
                          -70.74376,
                          45.710239,
                          0
                      ],
                      [
                          -70.742707,
                          45.709346,
                          0
                      ],
                      [
                          -70.743447,
                          45.708009,
                          0
                      ],
                      [
                          -70.745498,
                          45.706881,
                          0
                      ],
                      [
                          -70.745844,
                          45.705844,
                          0
                      ],
                      [
                          -70.744687,
                          45.704834,
                          0
                      ],
                      [
                          -70.743906,
                          45.704306,
                          0
                      ],
                      [
                          -70.743085,
                          45.704135,
                          0
                      ],
                      [
                          -70.742437,
                          45.704616,
                          0
                      ],
                      [
                          -70.598567,
                          45.628328,
                          0
                      ],
                      [
                          -70.608541,
                          45.62178,
                          0
                      ],
                      [
                          -70.618147,
                          45.615483,
                          0
                      ],
                      [
                          -70.629097,
                          45.61016,
                          0
                      ],
                      [
                          -70.64191,
                          45.607683,
                          0
                      ],
                      [
                          -70.649828,
                          45.60008,
                          0
                      ],
                      [
                          -70.655411,
                          45.591581,
                          0
                      ],
                      [
                          -70.663633,
                          45.584666,
                          0
                      ],
                      [
                          -70.672504,
                          45.577932,
                          0
                      ],
                      [
                          -70.67926,
                          45.570199,
                          0
                      ],
                      [
                          -70.688703,
                          45.563529,
                          0
                      ],
                      [
                          -70.6824,
                          45.555673,
                          0
                      ],
                      [
                          -70.689222,
                          45.546983,
                          0
                      ],
                      [
                          -70.696488,
                          45.53898,
                          0
                      ],
                      [
                          -70.699094,
                          45.530127,
                          0
                      ],
                      [
                          -70.709858,
                          45.522512,
                          0
                      ],
                      [
                          -70.719913,
                          45.5165,
                          0
                      ],
                      [
                          -70.722929,
                          45.507498,
                          0
                      ],
                      [
                          -70.712241,
                          45.502257,
                          0
                      ],
                      [
                          -70.716713,
                          45.492244,
                          0
                      ],
                      [
                          -70.711728,
                          45.483587,
                          0
                      ],
                      [
                          -70.700846,
                          45.478728,
                          0
                      ],
                      [
                          -70.691671,
                          45.471987,
                          0
                      ],
                      [
                          -70.68591,
                          45.463307,
                          0
                      ],
                      [
                          -70.681346,
                          45.45417,
                          0
                      ],
                      [
                          -70.66969,
                          45.449916,
                          0
                      ],
                      [
                          -70.657743,
                          45.44565,
                          0
                      ],
                      [
                          -70.646172,
                          45.43777,
                          0
                      ],
                      [
                          -70.637458,
                          45.428908,
                          0
                      ],
                      [
                          -70.627867,
                          45.422604,
                          0
                      ],
                      [
                          -70.635506,
                          45.414887,
                          0
                      ],
                      [
                          -70.628529,
                          45.406888,
                          0
                      ],
                      [
                          -70.633039,
                          45.398406,
                          0
                      ],
                      [
                          -70.638336,
                          45.389516,
                          0
                      ],
                      [
                          -70.641732,
                          45.380754,
                          0
                      ],
                      [
                          -70.653793,
                          45.377528,
                          0
                      ],
                      [
                          -70.661137,
                          45.385621,
                          0
                      ],
                      [
                          -70.67158,
                          45.391279,
                          0
                      ],
                      [
                          -70.684022,
                          45.394917,
                          0
                      ],
                      [
                          -70.696702,
                          45.3926,
                          0
                      ],
                      [
                          -70.709836,
                          45.390919,
                          0
                      ],
                      [
                          -70.72194,
                          45.394924,
                          0
                      ],
                      [
                          -70.731442,
                          45.401876,
                          0
                      ],
                      [
                          -70.740543,
                          45.410244,
                          0
                      ],
                      [
                          -70.744077,
                          45.41917,
                          0
                      ],
                      [
                          -70.752332,
                          45.42691,
                          0
                      ],
                      [
                          -70.765173,
                          45.42621,
                          0
                      ],
                      [
                          -70.776978,
                          45.429931,
                          0
                      ],
                      [
                          -70.789911,
                          45.426841,
                          0
                      ],
                      [
                          -70.800635,
                          45.421386,
                          0
                      ],
                      [
                          -70.811334,
                          45.416164,
                          0
                      ],
                      [
                          -70.817152,
                          45.408061,
                          0
                      ],
                      [
                          -70.8251,
                          45.400625,
                          0
                      ],
                      [
                          -70.824031,
                          45.391567,
                          0
                      ],
                      [
                          -70.813259,
                          45.386352,
                          0
                      ],
                      [
                          -70.807013,
                          45.377769,
                          0
                      ],
                      [
                          -70.80339,
                          45.368255,
                          0
                      ],
                      [
                          -70.809519,
                          45.359988,
                          0
                      ],
                      [
                          -70.809712,
                          45.350533,
                          0
                      ],
                      [
                          -70.819047,
                          45.342117,
                          0
                      ],
                      [
                          -70.818197,
                          45.333128,
                          0
                      ],
                      [
                          -70.807656,
                          45.327712,
                          0
                      ],
                      [
                          -70.807335,
                          45.318694,
                          0
                      ],
                      [
                          -70.811828,
                          45.310001,
                          0
                      ],
                      [
                          -70.814099,
                          45.300422,
                          0
                      ],
                      [
                          -70.826673,
                          45.296785,
                          0
                      ],
                      [
                          -70.829971,
                          45.287124,
                          0
                      ],
                      [
                          -70.833813,
                          45.277933,
                          0
                      ],
                      [
                          -70.839188,
                          45.269554,
                          0
                      ],
                      [
                          -70.848478,
                          45.263348,
                          0
                      ],
                      [
                          -70.84812,
                          45.254316,
                          0
                      ],
                      [
                          -70.848628,
                          45.24518,
                          0
                      ],
                      [
                          -70.839267,
                          45.237545,
                          0
                      ],
                      [
                          -70.850607,
                          45.232538,
                          0
                      ],
                      [
                          -70.863489,
                          45.230934,
                          0
                      ],
                      [
                          -70.876398,
                          45.233733,
                          0
                      ],
                      [
                          -70.889045,
                          45.23787,
                          0
                      ],
                      [
                          -70.898145,
                          45.244875,
                          0
                      ],
                      [
                          -70.897864,
                          45.254287,
                          0
                      ],
                      [
                          -70.906469,
                          45.261358,
                          0
                      ],
                      [
                          -70.910871,
                          45.269906,
                          0
                      ],
                      [
                          -70.918668,
                          45.277908,
                          0
                      ],
                      [
                          -70.918698,
                          45.287279,
                          0
                      ],
                      [
                          -70.912608,
                          45.295668,
                          0
                      ],
                      [
                          -70.919614,
                          45.30526,
                          0
                      ],
                      [
                          -70.923178,
                          45.314063,
                          0
                      ],
                      [
                          -70.933569,
                          45.319286,
                          0
                      ],
                      [
                          -70.944269,
                          45.32472,
                          0
                      ],
                      [
                          -70.949931,
                          45.333438,
                          0
                      ],
                      [
                          -70.962427,
                          45.335598,
                          0
                      ],
                      [
                          -70.976058,
                          45.333408,
                          0
                      ],
                      [
                          -70.98891,
                          45.334043,
                          0
                      ],
                      [
                          -70.997917,
                          45.340504,
                          0
                      ],
                      [
                          -71.008734,
                          45.347039,
                          0
                      ],
                      [
                          -71.010898,
                          45.338054,
                          0
                      ],
                      [
                          -71.004007,
                          45.330394,
                          0
                      ],
                      [
                          -71.008235,
                          45.321332,
                          0
                      ],
                      [
                          -71.018082,
                          45.315288,
                          0
                      ],
                      [
                          -71.030586,
                          45.312671,
                          0
                      ],
                      [
                          -71.043574,
                          45.313132,
                          0
                      ],
                      [
                          -71.056637,
                          45.313048,
                          0
                      ],
                      [
                          -71.068745,
                          45.310234,
                          0
                      ],
                      [
                          -71.080583,
                          45.306808,
                          0
                      ],
                      [
                          -71.093158,
                          45.303505,
                          0
                      ],
                      [
                          -71.102427,
                          45.297209,
                          0
                      ],
                      [
                          -71.108826,
                          45.286711,
                          0
                      ],
                      [
                          -71.10804,
                          45.27762,
                          0
                      ],
                      [
                          -71.116249,
                          45.270586,
                          0
                      ],
                      [
                          -71.119548,
                          45.26162,
                          0
                      ],
                      [
                          -71.126383,
                          45.253982,
                          0
                      ],
                      [
                          -71.131635,
                          45.245787,
                          0
                      ],
                      [
                          -71.144078,
                          45.242266,
                          0
                      ],
                      [
                          -71.155633,
                          45.246444,
                          0
                      ],
                      [
                          -71.168574,
                          45.246477,
                          0
                      ],
                      [
                          -71.179102,
                          45.241147,
                          0
                      ],
                      [
                          -71.188008,
                          45.247712,
                          0
                      ],
                      [
                          -71.197135,
                          45.254173,
                          0
                      ],
                      [
                          -71.209638,
                          45.250599,
                          0
                      ],
                      [
                          -71.222101,
                          45.253365,
                          0
                      ],
                      [
                          -71.233737,
                          45.257751,
                          0
                      ],
                      [
                          -71.241734,
                          45.264944,
                          0
                      ],
                      [
                          -71.252345,
                          45.270525,
                          0
                      ],
                      [
                          -71.262205,
                          45.276573,
                          0
                      ],
                      [
                          -71.263953,
                          45.28549,
                          0
                      ],
                      [
                          -71.266288,
                          45.29438,
                          0
                      ],
                      [
                          -71.27911,
                          45.294482,
                          0
                      ],
                      [
                          -71.289869,
                          45.300005,
                          0
                      ],
                      [
                          -71.30174,
                          45.295432,
                          0
                      ],
                      [
                          -71.308074,
                          45.287361,
                          0
                      ],
                      [
                          -71.319636,
                          45.282918,
                          0
                      ],
                      [
                          -71.331706,
                          45.279507,
                          0
                      ],
                      [
                          -71.339753,
                          45.272224,
                          0
                      ],
                      [
                          -71.352047,
                          45.268984,
                          0
                      ],
                      [
                          -71.358712,
                          45.259466,
                          0
                      ],
                      [
                          -71.359964,
                          45.250218,
                          0
                      ],
                      [
                          -71.372376,
                          45.246235,
                          0
                      ],
                      [
                          -71.380347,
                          45.238706,
                          0
                      ],
                      [
                          -71.393042,
                          45.239804,
                          0
                      ],
                      [
                          -71.40547,
                          45.24189,
                          0
                      ],
                      [
                          -71.407742,
                          45.240866,
                          0
                      ],
                      [
                          -71.604596,
                          45.237774,
                          0
                      ],
                      [
                          -71.605025,
                          45.248083,
                          0
                      ],
                      [
                          -71.605028,
                          45.248179,
                          0
                      ],
                      [
                          -71.624084,
                          45.247645,
                          0
                      ],
                      [
                          -71.643057,
                          45.248351,
                          0
                      ],
                      [
                          -71.643057,
                          45.248357,
                          0
                      ],
                      [
                          -71.644406,
                          45.306494,
                          0
                      ],
                      [
                          -71.64628,
                          45.306484,
                          0
                      ],
                      [
                          -71.646359,
                          45.306591,
                          0
                      ],
                      [
                          -71.738645,
                          45.305116,
                          0
                      ],
                      [
                          -71.814898,
                          45.306143,
                          0
                      ],
                      [
                          -71.815731,
                          45.306272,
                          0
                      ],
                      [
                          -71.817695,
                          45.358085,
                          0
                      ],
                      [
                          -71.81161,
                          45.358248,
                          0
                      ],
                      [
                          -71.812079,
                          45.367118,
                          0
                      ],
                      [
                          -71.818393,
                          45.368424,
                          0
                      ],
                      [
                          -71.818725,
                          45.378736,
                          0
                      ],
                      [
                          -71.817602,
                          45.379411,
                          0
                      ],
                      [
                          -71.816673,
                          45.381187,
                          0
                      ],
                      [
                          -71.816462,
                          45.382602,
                          0
                      ],
                      [
                          -71.815507,
                          45.384404,
                          0
                      ],
                      [
                          -71.815582,
                          45.386228,
                          0
                      ],
                      [
                          -71.815322,
                          45.387897,
                          0
                      ],
                      [
                          -71.814068,
                          45.389537,
                          0
                      ],
                      [
                          -71.811222,
                          45.390617,
                          0
                      ],
                      [
                          -71.809874,
                          45.391676,
                          0
                      ],
                      [
                          -71.808911,
                          45.392546,
                          0
                      ],
                      [
                          -71.806338,
                          45.398786,
                          0
                      ],
                      [
                          -71.806189,
                          45.400422,
                          0
                      ],
                      [
                          -71.802686,
                          45.408123,
                          0
                      ],
                      [
                          -71.819996,
                          45.407922,
                          0
                      ],
                      [
                          -71.820022,
                          45.407924,
                          0
                      ],
                      [
                          -71.821567,
                          45.453329,
                          0
                      ],
                      [
                          -71.821567,
                          45.453332,
                          0
                      ],
                      [
                          -71.869628,
                          45.453128,
                          0
                      ],
                      [
                          -71.893613,
                          45.466845,
                          0
                      ],
                      [
                          -71.893619,
                          45.466848,
                          0
                      ],
                      [
                          -71.879534,
                          45.478674,
                          0
                      ],
                      [
                          -71.890088,
                          45.484838,
                          0
                      ],
                      [
                          -71.890091,
                          45.48484,
                          0
                      ],
                      [
                          -71.890079,
                          45.48485,
                          0
                      ],
                      [
                          -71.877598,
                          45.495538,
                          0
                      ],
                      [
                          -71.910561,
                          45.514113,
                          0
                      ],
                      [
                          -71.913271,
                          45.519623,
                          0
                      ],
                      [
                          -71.914547,
                          45.521001,
                          0
                      ],
                      [
                          -71.919865,
                          45.524064,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Mégantic",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -71.10677,
                          45.946136,
                          0
                      ],
                      [
                          -71.090336,
                          45.962602,
                          0
                      ],
                      [
                          -71.064174,
                          45.988798,
                          0
                      ],
                      [
                          -71.046496,
                          46.006349,
                          0
                      ],
                      [
                          -71.03314,
                          46.000006,
                          0
                      ],
                      [
                          -71.007584,
                          45.987987,
                          0
                      ],
                      [
                          -70.990367,
                          46.00485,
                          0
                      ],
                      [
                          -70.990366,
                          46.004852,
                          0
                      ],
                      [
                          -71.002042,
                          46.010576,
                          0
                      ],
                      [
                          -71.00204,
                          46.010578,
                          0
                      ],
                      [
                          -70.987608,
                          46.024734,
                          0
                      ],
                      [
                          -71.024738,
                          46.057732,
                          0
                      ],
                      [
                          -71.011318,
                          46.066358,
                          0
                      ],
                      [
                          -71.029514,
                          46.081649,
                          0
                      ],
                      [
                          -71.02955,
                          46.081685,
                          0
                      ],
                      [
                          -71.029552,
                          46.081686,
                          0
                      ],
                      [
                          -71.042248,
                          46.073531,
                          0
                      ],
                      [
                          -71.079822,
                          46.105612,
                          0
                      ],
                      [
                          -71.096716,
                          46.121009,
                          0
                      ],
                      [
                          -71.100542,
                          46.124685,
                          0
                      ],
                      [
                          -71.100544,
                          46.124687,
                          0
                      ],
                      [
                          -71.087941,
                          46.132415,
                          0
                      ],
                      [
                          -71.087983,
                          46.132432,
                          0
                      ],
                      [
                          -71.089528,
                          46.132944,
                          0
                      ],
                      [
                          -71.090961,
                          46.133295,
                          0
                      ],
                      [
                          -71.093011,
                          46.133563,
                          0
                      ],
                      [
                          -71.094475,
                          46.133557,
                          0
                      ],
                      [
                          -71.097343,
                          46.133277,
                          0
                      ],
                      [
                          -71.097561,
                          46.133238,
                          0
                      ],
                      [
                          -71.097663,
                          46.133263,
                          0
                      ],
                      [
                          -71.097781,
                          46.133299,
                          0
                      ],
                      [
                          -71.099501,
                          46.133785,
                          0
                      ],
                      [
                          -71.100483,
                          46.133749,
                          0
                      ],
                      [
                          -71.100625,
                          46.134013,
                          0
                      ],
                      [
                          -71.101086,
                          46.13404,
                          0
                      ],
                      [
                          -71.101813,
                          46.134177,
                          0
                      ],
                      [
                          -71.103503,
                          46.134565,
                          0
                      ],
                      [
                          -71.105027,
                          46.135142,
                          0
                      ],
                      [
                          -71.10503,
                          46.135143,
                          0
                      ],
                      [
                          -71.094733,
                          46.141714,
                          0
                      ],
                      [
                          -71.094731,
                          46.141716,
                          0
                      ],
                      [
                          -71.079897,
                          46.151327,
                          0
                      ],
                      [
                          -71.088576,
                          46.159242,
                          0
                      ],
                      [
                          -71.074668,
                          46.168169,
                          0
                      ],
                      [
                          -71.070974,
                          46.164802,
                          0
                      ],
                      [
                          -71.066602,
                          46.167689,
                          0
                      ],
                      [
                          -71.066601,
                          46.167687,
                          0
                      ],
                      [
                          -71.062051,
                          46.163485,
                          0
                      ],
                      [
                          -71.062059,
                          46.163481,
                          0
                      ],
                      [
                          -71.066447,
                          46.16051,
                          0
                      ],
                      [
                          -71.062106,
                          46.156448,
                          0
                      ],
                      [
                          -71.062063,
                          46.156477,
                          0
                      ],
                      [
                          -71.048854,
                          46.165419,
                          0
                      ],
                      [
                          -71.048751,
                          46.16534,
                          0
                      ],
                      [
                          -71.048749,
                          46.165339,
                          0
                      ],
                      [
                          -71.04373,
                          46.16057,
                          0
                      ],
                      [
                          -71.043589,
                          46.160421,
                          0
                      ],
                      [
                          -71.043523,
                          46.16035,
                          0
                      ],
                      [
                          -71.043469,
                          46.160215,
                          0
                      ],
                      [
                          -71.040381,
                          46.157239,
                          0
                      ],
                      [
                          -71.037756,
                          46.154807,
                          0
                      ],
                      [
                          -71.037429,
                          46.154603,
                          0
                      ],
                      [
                          -71.036381,
                          46.15359,
                          0
                      ],
                      [
                          -71.036109,
                          46.153328,
                          0
                      ],
                      [
                          -71.027241,
                          46.158915,
                          0
                      ],
                      [
                          -71.015569,
                          46.150315,
                          0
                      ],
                      [
                          -71.015294,
                          46.150111,
                          0
                      ],
                      [
                          -71.015285,
                          46.150116,
                          0
                      ],
                      [
                          -71.002038,
                          46.158924,
                          0
                      ],
                      [
                          -71.001944,
                          46.158987,
                          0
                      ],
                      [
                          -71.001942,
                          46.158986,
                          0
                      ],
                      [
                          -70.993264,
                          46.15247,
                          0
                      ],
                      [
                          -70.993266,
                          46.152469,
                          0
                      ],
                      [
                          -71.006541,
                          46.143591,
                          0
                      ],
                      [
                          -71.004632,
                          46.142137,
                          0
                      ],
                      [
                          -70.98865,
                          46.129358,
                          0
                      ],
                      [
                          -70.987292,
                          46.128418,
                          0
                      ],
                      [
                          -70.986254,
                          46.128255,
                          0
                      ],
                      [
                          -70.985387,
                          46.128255,
                          0
                      ],
                      [
                          -70.984936,
                          46.128349,
                          0
                      ],
                      [
                          -70.984456,
                          46.128343,
                          0
                      ],
                      [
                          -70.984054,
                          46.128221,
                          0
                      ],
                      [
                          -70.980357,
                          46.124997,
                          0
                      ],
                      [
                          -70.969862,
                          46.11565,
                          0
                      ],
                      [
                          -70.955219,
                          46.102567,
                          0
                      ],
                      [
                          -70.946569,
                          46.108024,
                          0
                      ],
                      [
                          -70.940912,
                          46.111544,
                          0
                      ],
                      [
                          -70.940911,
                          46.111542,
                          0
                      ],
                      [
                          -70.937141,
                          46.108155,
                          0
                      ],
                      [
                          -70.923082,
                          46.095598,
                          0
                      ],
                      [
                          -70.909245,
                          46.083033,
                          0
                      ],
                      [
                          -70.90921,
                          46.082999,
                          0
                      ],
                      [
                          -70.893833,
                          46.092904,
                          0
                      ],
                      [
                          -70.875744,
                          46.076814,
                          0
                      ],
                      [
                          -70.860109,
                          46.062746,
                          0
                      ],
                      [
                          -70.860074,
                          46.062715,
                          0
                      ],
                      [
                          -70.846164,
                          46.071824,
                          0
                      ],
                      [
                          -70.859322,
                          46.083963,
                          0
                      ],
                      [
                          -70.859329,
                          46.083973,
                          0
                      ],
                      [
                          -70.844465,
                          46.092554,
                          0
                      ],
                      [
                          -70.842019,
                          46.095998,
                          0
                      ],
                      [
                          -70.84196,
                          46.096082,
                          0
                      ],
                      [
                          -70.832432,
                          46.102077,
                          0
                      ],
                      [
                          -70.832392,
                          46.102102,
                          0
                      ],
                      [
                          -70.845972,
                          46.114405,
                          0
                      ],
                      [
                          -70.846012,
                          46.114442,
                          0
                      ],
                      [
                          -70.831676,
                          46.12354,
                          0
                      ],
                      [
                          -70.823424,
                          46.116115,
                          0
                      ],
                      [
                          -70.79374,
                          46.136036,
                          0
                      ],
                      [
                          -70.786153,
                          46.129612,
                          0
                      ],
                      [
                          -70.784436,
                          46.128167,
                          0
                      ],
                      [
                          -70.78384,
                          46.127672,
                          0
                      ],
                      [
                          -70.783834,
                          46.127667,
                          0
                      ],
                      [
                          -70.784859,
                          46.126994,
                          0
                      ],
                      [
                          -70.775046,
                          46.118705,
                          0
                      ],
                      [
                          -70.774906,
                          46.118796,
                          0
                      ],
                      [
                          -70.749433,
                          46.135846,
                          0
                      ],
                      [
                          -70.753843,
                          46.142892,
                          0
                      ],
                      [
                          -70.755356,
                          46.145207,
                          0
                      ],
                      [
                          -70.756159,
                          46.146273,
                          0
                      ],
                      [
                          -70.754723,
                          46.152796,
                          0
                      ],
                      [
                          -70.740423,
                          46.16267,
                          0
                      ],
                      [
                          -70.74017,
                          46.163916,
                          0
                      ],
                      [
                          -70.739776,
                          46.167159,
                          0
                      ],
                      [
                          -70.740338,
                          46.16813,
                          0
                      ],
                      [
                          -70.740666,
                          46.168547,
                          0
                      ],
                      [
                          -70.740688,
                          46.168574,
                          0
                      ],
                      [
                          -70.74322,
                          46.171137,
                          0
                      ],
                      [
                          -70.744819,
                          46.172815,
                          0
                      ],
                      [
                          -70.745941,
                          46.175474,
                          0
                      ],
                      [
                          -70.74819,
                          46.18058,
                          0
                      ],
                      [
                          -70.742693,
                          46.184324,
                          0
                      ],
                      [
                          -70.727373,
                          46.194759,
                          0
                      ],
                      [
                          -70.727155,
                          46.194908,
                          0
                      ],
                      [
                          -70.725952,
                          46.195727,
                          0
                      ],
                      [
                          -70.725909,
                          46.1957,
                          0
                      ],
                      [
                          -70.725382,
                          46.195558,
                          0
                      ],
                      [
                          -70.724997,
                          46.195559,
                          0
                      ],
                      [
                          -70.72457,
                          46.195604,
                          0
                      ],
                      [
                          -70.724132,
                          46.195828,
                          0
                      ],
                      [
                          -70.724379,
                          46.196053,
                          0
                      ],
                      [
                          -70.724572,
                          46.196345,
                          0
                      ],
                      [
                          -70.724684,
                          46.196659,
                          0
                      ],
                      [
                          -70.724919,
                          46.196982,
                          0
                      ],
                      [
                          -70.725182,
                          46.197196,
                          0
                      ],
                      [
                          -70.725184,
                          46.197197,
                          0
                      ],
                      [
                          -70.725425,
                          46.197328,
                          0
                      ],
                      [
                          -70.724804,
                          46.197747,
                          0
                      ],
                      [
                          -70.724121,
                          46.19821,
                          0
                      ],
                      [
                          -70.723777,
                          46.198443,
                          0
                      ],
                      [
                          -70.723156,
                          46.198873,
                          0
                      ],
                      [
                          -70.722267,
                          46.19946,
                          0
                      ],
                      [
                          -70.703451,
                          46.212346,
                          0
                      ],
                      [
                          -70.70455,
                          46.213055,
                          0
                      ],
                      [
                          -70.705982,
                          46.214119,
                          0
                      ],
                      [
                          -70.707516,
                          46.215423,
                          0
                      ],
                      [
                          -70.707896,
                          46.215672,
                          0
                      ],
                      [
                          -70.709936,
                          46.216019,
                          0
                      ],
                      [
                          -70.713586,
                          46.21668,
                          0
                      ],
                      [
                          -70.714419,
                          46.21689,
                          0
                      ],
                      [
                          -70.70048,
                          46.226544,
                          0
                      ],
                      [
                          -70.700463,
                          46.226556,
                          0
                      ],
                      [
                          -70.702945,
                          46.227031,
                          0
                      ],
                      [
                          -70.706034,
                          46.227218,
                          0
                      ],
                      [
                          -70.715397,
                          46.234419,
                          0
                      ],
                      [
                          -70.711202,
                          46.237205,
                          0
                      ],
                      [
                          -70.70355,
                          46.242641,
                          0
                      ],
                      [
                          -70.701007,
                          46.244619,
                          0
                      ],
                      [
                          -70.686763,
                          46.25364,
                          0
                      ],
                      [
                          -70.686713,
                          46.253674,
                          0
                      ],
                      [
                          -70.686684,
                          46.253693,
                          0
                      ],
                      [
                          -70.685803,
                          46.254284,
                          0
                      ],
                      [
                          -70.657861,
                          46.272695,
                          0
                      ],
                      [
                          -70.657808,
                          46.272731,
                          0
                      ],
                      [
                          -70.665622,
                          46.278681,
                          0
                      ],
                      [
                          -70.558014,
                          46.349901,
                          0
                      ],
                      [
                          -70.557914,
                          46.349967,
                          0
                      ],
                      [
                          -70.557888,
                          46.349939,
                          0
                      ],
                      [
                          -70.537691,
                          46.335292,
                          0
                      ],
                      [
                          -70.537494,
                          46.335158,
                          0
                      ],
                      [
                          -70.537453,
                          46.335185,
                          0
                      ],
                      [
                          -70.53481,
                          46.336916,
                          0
                      ],
                      [
                          -70.534749,
                          46.336873,
                          0
                      ],
                      [
                          -70.511964,
                          46.320507,
                          0
                      ],
                      [
                          -70.512173,
                          46.320365,
                          0
                      ],
                      [
                          -70.54036,
                          46.301274,
                          0
                      ],
                      [
                          -70.54048,
                          46.301193,
                          0
                      ],
                      [
                          -70.513883,
                          46.28203,
                          0
                      ],
                      [
                          -70.513823,
                          46.281986,
                          0
                      ],
                      [
                          -70.515773,
                          46.280652,
                          0
                      ],
                      [
                          -70.516476,
                          46.280171,
                          0
                      ],
                      [
                          -70.510921,
                          46.27636,
                          0
                      ],
                      [
                          -70.510615,
                          46.27615,
                          0
                      ],
                      [
                          -70.508681,
                          46.274824,
                          0
                      ],
                      [
                          -70.508535,
                          46.274724,
                          0
                      ],
                      [
                          -70.50561,
                          46.272716,
                          0
                      ],
                      [
                          -70.50547,
                          46.27262,
                          0
                      ],
                      [
                          -70.505515,
                          46.27261,
                          0
                      ],
                      [
                          -70.505592,
                          46.272593,
                          0
                      ],
                      [
                          -70.505709,
                          46.272574,
                          0
                      ],
                      [
                          -70.505825,
                          46.272565,
                          0
                      ],
                      [
                          -70.50602,
                          46.272575,
                          0
                      ],
                      [
                          -70.506202,
                          46.272583,
                          0
                      ],
                      [
                          -70.506352,
                          46.272598,
                          0
                      ],
                      [
                          -70.506488,
                          46.27261,
                          0
                      ],
                      [
                          -70.506786,
                          46.272637,
                          0
                      ],
                      [
                          -70.506955,
                          46.272655,
                          0
                      ],
                      [
                          -70.507136,
                          46.272664,
                          0
                      ],
                      [
                          -70.507227,
                          46.272669,
                          0
                      ],
                      [
                          -70.507304,
                          46.272673,
                          0
                      ],
                      [
                          -70.507474,
                          46.272681,
                          0
                      ],
                      [
                          -70.507655,
                          46.272691,
                          0
                      ],
                      [
                          -70.50785,
                          46.2727,
                          0
                      ],
                      [
                          -70.508174,
                          46.272718,
                          0
                      ],
                      [
                          -70.508225,
                          46.272718,
                          0
                      ],
                      [
                          -70.50825,
                          46.27272,
                          0
                      ],
                      [
                          -70.508499,
                          46.272736,
                          0
                      ],
                      [
                          -70.508667,
                          46.272745,
                          0
                      ],
                      [
                          -70.508732,
                          46.272745,
                          0
                      ],
                      [
                          -70.508798,
                          46.272745,
                          0
                      ],
                      [
                          -70.508836,
                          46.272745,
                          0
                      ],
                      [
                          -70.508891,
                          46.272741,
                          0
                      ],
                      [
                          -70.508965,
                          46.272736,
                          0
                      ],
                      [
                          -70.509147,
                          46.272718,
                          0
                      ],
                      [
                          -70.509315,
                          46.272682,
                          0
                      ],
                      [
                          -70.509457,
                          46.272645,
                          0
                      ],
                      [
                          -70.509485,
                          46.272637,
                          0
                      ],
                      [
                          -70.509576,
                          46.272601,
                          0
                      ],
                      [
                          -70.509705,
                          46.272582,
                          0
                      ],
                      [
                          -70.509848,
                          46.272466,
                          0
                      ],
                      [
                          -70.50999,
                          46.272349,
                          0
                      ],
                      [
                          -70.510003,
                          46.272232,
                          0
                      ],
                      [
                          -70.510016,
                          46.272124,
                          0
                      ],
                      [
                          -70.510003,
                          46.272016,
                          0
                      ],
                      [
                          -70.510043,
                          46.271845,
                          0
                      ],
                      [
                          -70.510055,
                          46.271719,
                          0
                      ],
                      [
                          -70.510055,
                          46.271651,
                          0
                      ],
                      [
                          -70.510056,
                          46.271585,
                          0
                      ],
                      [
                          -70.510145,
                          46.271458,
                          0
                      ],
                      [
                          -70.510302,
                          46.271315,
                          0
                      ],
                      [
                          -70.510353,
                          46.271224,
                          0
                      ],
                      [
                          -70.510432,
                          46.271179,
                          0
                      ],
                      [
                          -70.510484,
                          46.271143,
                          0
                      ],
                      [
                          -70.510535,
                          46.271125,
                          0
                      ],
                      [
                          -70.510652,
                          46.271071,
                          0
                      ],
                      [
                          -70.510756,
                          46.271026,
                          0
                      ],
                      [
                          -70.510833,
                          46.270999,
                          0
                      ],
                      [
                          -70.510962,
                          46.270963,
                          0
                      ],
                      [
                          -70.511053,
                          46.270945,
                          0
                      ],
                      [
                          -70.511158,
                          46.2709,
                          0
                      ],
                      [
                          -70.511209,
                          46.270892,
                          0
                      ],
                      [
                          -70.511287,
                          46.270828,
                          0
                      ],
                      [
                          -70.511418,
                          46.270738,
                          0
                      ],
                      [
                          -70.51156,
                          46.270603,
                          0
                      ],
                      [
                          -70.511755,
                          46.27046,
                          0
                      ],
                      [
                          -70.511935,
                          46.270307,
                          0
                      ],
                      [
                          -70.512058,
                          46.270194,
                          0
                      ],
                      [
                          -70.512092,
                          46.270162,
                          0
                      ],
                      [
                          -70.512248,
                          46.270009,
                          0
                      ],
                      [
                          -70.51239,
                          46.269857,
                          0
                      ],
                      [
                          -70.512507,
                          46.269694,
                          0
                      ],
                      [
                          -70.512623,
                          46.269524,
                          0
                      ],
                      [
                          -70.512691,
                          46.269405,
                          0
                      ],
                      [
                          -70.512728,
                          46.269343,
                          0
                      ],
                      [
                          -70.512806,
                          46.269236,
                          0
                      ],
                      [
                          -70.512883,
                          46.269119,
                          0
                      ],
                      [
                          -70.512947,
                          46.26902,
                          0
                      ],
                      [
                          -70.513,
                          46.268921,
                          0
                      ],
                      [
                          -70.513021,
                          46.268856,
                          0
                      ],
                      [
                          -70.513051,
                          46.268768,
                          0
                      ],
                      [
                          -70.513064,
                          46.268606,
                          0
                      ],
                      [
                          -70.513065,
                          46.268534,
                          0
                      ],
                      [
                          -70.513052,
                          46.268462,
                          0
                      ],
                      [
                          -70.513038,
                          46.268381,
                          0
                      ],
                      [
                          -70.513025,
                          46.268273,
                          0
                      ],
                      [
                          -70.512974,
                          46.268093,
                          0
                      ],
                      [
                          -70.512909,
                          46.267904,
                          0
                      ],
                      [
                          -70.512817,
                          46.267724,
                          0
                      ],
                      [
                          -70.512714,
                          46.267535,
                          0
                      ],
                      [
                          -70.512649,
                          46.267419,
                          0
                      ],
                      [
                          -70.51261,
                          46.267346,
                          0
                      ],
                      [
                          -70.512546,
                          46.267247,
                          0
                      ],
                      [
                          -70.51248,
                          46.267167,
                          0
                      ],
                      [
                          -70.512383,
                          46.267025,
                          0
                      ],
                      [
                          -70.512364,
                          46.266996,
                          0
                      ],
                      [
                          -70.512234,
                          46.266842,
                          0
                      ],
                      [
                          -70.512116,
                          46.266702,
                          0
                      ],
                      [
                          -70.512052,
                          46.266627,
                          0
                      ],
                      [
                          -70.512027,
                          46.2666,
                          0
                      ],
                      [
                          -70.511961,
                          46.266519,
                          0
                      ],
                      [
                          -70.511919,
                          46.266469,
                          0
                      ],
                      [
                          -70.51187,
                          46.26641,
                          0
                      ],
                      [
                          -70.511781,
                          46.266292,
                          0
                      ],
                      [
                          -70.511641,
                          46.266181,
                          0
                      ],
                      [
                          -70.511514,
                          46.266081,
                          0
                      ],
                      [
                          -70.511415,
                          46.265976,
                          0
                      ],
                      [
                          -70.511382,
                          46.265941,
                          0
                      ],
                      [
                          -70.51126,
                          46.265733,
                          0
                      ],
                      [
                          -70.511252,
                          46.26572,
                          0
                      ],
                      [
                          -70.511162,
                          46.265486,
                          0
                      ],
                      [
                          -70.511094,
                          46.265333,
                          0
                      ],
                      [
                          -70.511108,
                          46.26517,
                          0
                      ],
                      [
                          -70.511218,
                          46.264866,
                          0
                      ],
                      [
                          -70.511328,
                          46.264658,
                          0
                      ],
                      [
                          -70.511366,
                          46.264587,
                          0
                      ],
                      [
                          -70.511569,
                          46.264339,
                          0
                      ],
                      [
                          -70.511871,
                          46.264181,
                          0
                      ],
                      [
                          -70.512193,
                          46.264092,
                          0
                      ],
                      [
                          -70.512316,
                          46.264057,
                          0
                      ],
                      [
                          -70.512371,
                          46.264043,
                          0
                      ],
                      [
                          -70.512504,
                          46.264008,
                          0
                      ],
                      [
                          -70.512775,
                          46.264089,
                          0
                      ],
                      [
                          -70.512965,
                          46.264099,
                          0
                      ],
                      [
                          -70.513353,
                          46.264119,
                          0
                      ],
                      [
                          -70.513487,
                          46.26407,
                          0
                      ],
                      [
                          -70.513547,
                          46.264048,
                          0
                      ],
                      [
                          -70.513667,
                          46.263913,
                          0
                      ],
                      [
                          -70.513708,
                          46.263764,
                          0
                      ],
                      [
                          -70.513696,
                          46.263722,
                          0
                      ],
                      [
                          -70.513676,
                          46.263642,
                          0
                      ],
                      [
                          -70.513599,
                          46.263533,
                          0
                      ],
                      [
                          -70.513591,
                          46.263521,
                          0
                      ],
                      [
                          -70.513419,
                          46.263413,
                          0
                      ],
                      [
                          -70.513271,
                          46.263327,
                          0
                      ],
                      [
                          -70.513269,
                          46.263327,
                          0
                      ],
                      [
                          -70.513235,
                          46.26329,
                          0
                      ],
                      [
                          -70.513184,
                          46.263235,
                          0
                      ],
                      [
                          -70.513106,
                          46.263059,
                          0
                      ],
                      [
                          -70.513106,
                          46.263056,
                          0
                      ],
                      [
                          -70.513109,
                          46.263039,
                          0
                      ],
                      [
                          -70.513125,
                          46.262924,
                          0
                      ],
                      [
                          -70.513279,
                          46.262755,
                          0
                      ],
                      [
                          -70.513385,
                          46.262682,
                          0
                      ],
                      [
                          -70.513482,
                          46.262615,
                          0
                      ],
                      [
                          -70.513676,
                          46.262541,
                          0
                      ],
                      [
                          -70.513714,
                          46.262547,
                          0
                      ],
                      [
                          -70.513857,
                          46.26257,
                          0
                      ],
                      [
                          -70.514013,
                          46.262634,
                          0
                      ],
                      [
                          -70.514195,
                          46.262706,
                          0
                      ],
                      [
                          -70.514316,
                          46.262753,
                          0
                      ],
                      [
                          -70.514392,
                          46.262782,
                          0
                      ],
                      [
                          -70.514582,
                          46.262841,
                          0
                      ],
                      [
                          -70.514806,
                          46.262864,
                          0
                      ],
                      [
                          -70.515008,
                          46.262915,
                          0
                      ],
                      [
                          -70.515221,
                          46.263,
                          0
                      ],
                      [
                          -70.515474,
                          46.263126,
                          0
                      ],
                      [
                          -70.515626,
                          46.263183,
                          0
                      ],
                      [
                          -70.51573,
                          46.263213,
                          0
                      ],
                      [
                          -70.515733,
                          46.263214,
                          0
                      ],
                      [
                          -70.515777,
                          46.26322,
                          0
                      ],
                      [
                          -70.515902,
                          46.263239,
                          0
                      ],
                      [
                          -70.515936,
                          46.263244,
                          0
                      ],
                      [
                          -70.516056,
                          46.263261,
                          0
                      ],
                      [
                          -70.516167,
                          46.263328,
                          0
                      ],
                      [
                          -70.516316,
                          46.263437,
                          0
                      ],
                      [
                          -70.516407,
                          46.263534,
                          0
                      ],
                      [
                          -70.51641,
                          46.26354,
                          0
                      ],
                      [
                          -70.516465,
                          46.263643,
                          0
                      ],
                      [
                          -70.51654,
                          46.263742,
                          0
                      ],
                      [
                          -70.516541,
                          46.263745,
                          0
                      ],
                      [
                          -70.516625,
                          46.263803,
                          0
                      ],
                      [
                          -70.516737,
                          46.263824,
                          0
                      ],
                      [
                          -70.516911,
                          46.263824,
                          0
                      ],
                      [
                          -70.516978,
                          46.263815,
                          0
                      ],
                      [
                          -70.517027,
                          46.263808,
                          0
                      ],
                      [
                          -70.517208,
                          46.263706,
                          0
                      ],
                      [
                          -70.517297,
                          46.263616,
                          0
                      ],
                      [
                          -70.517298,
                          46.263614,
                          0
                      ],
                      [
                          -70.517326,
                          46.263546,
                          0
                      ],
                      [
                          -70.517354,
                          46.263474,
                          0
                      ],
                      [
                          -70.517355,
                          46.263203,
                          0
                      ],
                      [
                          -70.517259,
                          46.26287,
                          0
                      ],
                      [
                          -70.517243,
                          46.262812,
                          0
                      ],
                      [
                          -70.517104,
                          46.262385,
                          0
                      ],
                      [
                          -70.51708,
                          46.262289,
                          0
                      ],
                      [
                          -70.517034,
                          46.262107,
                          0
                      ],
                      [
                          -70.516989,
                          46.261913,
                          0
                      ],
                      [
                          -70.517039,
                          46.261707,
                          0
                      ],
                      [
                          -70.517028,
                          46.261643,
                          0
                      ],
                      [
                          -70.517007,
                          46.261542,
                          0
                      ],
                      [
                          -70.516981,
                          46.261467,
                          0
                      ],
                      [
                          -70.516934,
                          46.261333,
                          0
                      ],
                      [
                          -70.51667,
                          46.261038,
                          0
                      ],
                      [
                          -70.516543,
                          46.260915,
                          0
                      ],
                      [
                          -70.516467,
                          46.260841,
                          0
                      ],
                      [
                          -70.516372,
                          46.260765,
                          0
                      ],
                      [
                          -70.516221,
                          46.260643,
                          0
                      ],
                      [
                          -70.516116,
                          46.260518,
                          0
                      ],
                      [
                          -70.515884,
                          46.260294,
                          0
                      ],
                      [
                          -70.515699,
                          46.260153,
                          0
                      ],
                      [
                          -70.515529,
                          46.260017,
                          0
                      ],
                      [
                          -70.51538,
                          46.259908,
                          0
                      ],
                      [
                          -70.515276,
                          46.25983,
                          0
                      ],
                      [
                          -70.515041,
                          46.259642,
                          0
                      ],
                      [
                          -70.515033,
                          46.259635,
                          0
                      ],
                      [
                          -70.51503,
                          46.259633,
                          0
                      ],
                      [
                          -70.514804,
                          46.25947,
                          0
                      ],
                      [
                          -70.514664,
                          46.259305,
                          0
                      ],
                      [
                          -70.514557,
                          46.259144,
                          0
                      ],
                      [
                          -70.514485,
                          46.258997,
                          0
                      ],
                      [
                          -70.514466,
                          46.258959,
                          0
                      ],
                      [
                          -70.514394,
                          46.25891,
                          0
                      ],
                      [
                          -70.514269,
                          46.258824,
                          0
                      ],
                      [
                          -70.51393,
                          46.258653,
                          0
                      ],
                      [
                          -70.513761,
                          46.258577,
                          0
                      ],
                      [
                          -70.513651,
                          46.258527,
                          0
                      ],
                      [
                          -70.513428,
                          46.258437,
                          0
                      ],
                      [
                          -70.513319,
                          46.258394,
                          0
                      ],
                      [
                          -70.513225,
                          46.25835,
                          0
                      ],
                      [
                          -70.513129,
                          46.258304,
                          0
                      ],
                      [
                          -70.512998,
                          46.258246,
                          0
                      ],
                      [
                          -70.51293,
                          46.258216,
                          0
                      ],
                      [
                          -70.512846,
                          46.258173,
                          0
                      ],
                      [
                          -70.512724,
                          46.258108,
                          0
                      ],
                      [
                          -70.512643,
                          46.258081,
                          0
                      ],
                      [
                          -70.512533,
                          46.258044,
                          0
                      ],
                      [
                          -70.512435,
                          46.25799,
                          0
                      ],
                      [
                          -70.512254,
                          46.257892,
                          0
                      ],
                      [
                          -70.512016,
                          46.257765,
                          0
                      ],
                      [
                          -70.511853,
                          46.25763,
                          0
                      ],
                      [
                          -70.51153,
                          46.25748,
                          0
                      ],
                      [
                          -70.511268,
                          46.25737,
                          0
                      ],
                      [
                          -70.51104,
                          46.257212,
                          0
                      ],
                      [
                          -70.510888,
                          46.257053,
                          0
                      ],
                      [
                          -70.510837,
                          46.256891,
                          0
                      ],
                      [
                          -70.510932,
                          46.256758,
                          0
                      ],
                      [
                          -70.511205,
                          46.256636,
                          0
                      ],
                      [
                          -70.511492,
                          46.256538,
                          0
                      ],
                      [
                          -70.511767,
                          46.256463,
                          0
                      ],
                      [
                          -70.511932,
                          46.256398,
                          0
                      ],
                      [
                          -70.512028,
                          46.256303,
                          0
                      ],
                      [
                          -70.512086,
                          46.256141,
                          0
                      ],
                      [
                          -70.512087,
                          46.256138,
                          0
                      ],
                      [
                          -70.512042,
                          46.256085,
                          0
                      ],
                      [
                          -70.512034,
                          46.256066,
                          0
                      ],
                      [
                          -70.512009,
                          46.256005,
                          0
                      ],
                      [
                          -70.512027,
                          46.255958,
                          0
                      ],
                      [
                          -70.512042,
                          46.255918,
                          0
                      ],
                      [
                          -70.512069,
                          46.25587,
                          0
                      ],
                      [
                          -70.51211,
                          46.255796,
                          0
                      ],
                      [
                          -70.512165,
                          46.255706,
                          0
                      ],
                      [
                          -70.512239,
                          46.255671,
                          0
                      ],
                      [
                          -70.512308,
                          46.255639,
                          0
                      ],
                      [
                          -70.512405,
                          46.255616,
                          0
                      ],
                      [
                          -70.512439,
                          46.255607,
                          0
                      ],
                      [
                          -70.512533,
                          46.255613,
                          0
                      ],
                      [
                          -70.512595,
                          46.255617,
                          0
                      ],
                      [
                          -70.512623,
                          46.255621,
                          0
                      ],
                      [
                          -70.512845,
                          46.255656,
                          0
                      ],
                      [
                          -70.512921,
                          46.255684,
                          0
                      ],
                      [
                          -70.51298,
                          46.255706,
                          0
                      ],
                      [
                          -70.513062,
                          46.255745,
                          0
                      ],
                      [
                          -70.513101,
                          46.255765,
                          0
                      ],
                      [
                          -70.513196,
                          46.255827,
                          0
                      ],
                      [
                          -70.513283,
                          46.255938,
                          0
                      ],
                      [
                          -70.513313,
                          46.255977,
                          0
                      ],
                      [
                          -70.513361,
                          46.256019,
                          0
                      ],
                      [
                          -70.513417,
                          46.256069,
                          0
                      ],
                      [
                          -70.513478,
                          46.256103,
                          0
                      ],
                      [
                          -70.513581,
                          46.256161,
                          0
                      ],
                      [
                          -70.51374,
                          46.256224,
                          0
                      ],
                      [
                          -70.513946,
                          46.256267,
                          0
                      ],
                      [
                          -70.514147,
                          46.256278,
                          0
                      ],
                      [
                          -70.514233,
                          46.256267,
                          0
                      ],
                      [
                          -70.514349,
                          46.256252,
                          0
                      ],
                      [
                          -70.514534,
                          46.256206,
                          0
                      ],
                      [
                          -70.514626,
                          46.256185,
                          0
                      ],
                      [
                          -70.514705,
                          46.256165,
                          0
                      ],
                      [
                          -70.514831,
                          46.256126,
                          0
                      ],
                      [
                          -70.514923,
                          46.256097,
                          0
                      ],
                      [
                          -70.515002,
                          46.256088,
                          0
                      ],
                      [
                          -70.515101,
                          46.256076,
                          0
                      ],
                      [
                          -70.515159,
                          46.256065,
                          0
                      ],
                      [
                          -70.515238,
                          46.25605,
                          0
                      ],
                      [
                          -70.515361,
                          46.256022,
                          0
                      ],
                      [
                          -70.515369,
                          46.256022,
                          0
                      ],
                      [
                          -70.515624,
                          46.256027,
                          0
                      ],
                      [
                          -70.515461,
                          46.255965,
                          0
                      ],
                      [
                          -70.515332,
                          46.255874,
                          0
                      ],
                      [
                          -70.515189,
                          46.255793,
                          0
                      ],
                      [
                          -70.515032,
                          46.255703,
                          0
                      ],
                      [
                          -70.514972,
                          46.255667,
                          0
                      ],
                      [
                          -70.514942,
                          46.255649,
                          0
                      ],
                      [
                          -70.514838,
                          46.255596,
                          0
                      ],
                      [
                          -70.514747,
                          46.25555,
                          0
                      ],
                      [
                          -70.514604,
                          46.255506,
                          0
                      ],
                      [
                          -70.514462,
                          46.255452,
                          0
                      ],
                      [
                          -70.514319,
                          46.255398,
                          0
                      ],
                      [
                          -70.514176,
                          46.255344,
                          0
                      ],
                      [
                          -70.514046,
                          46.255299,
                          0
                      ],
                      [
                          -70.513992,
                          46.255273,
                          0
                      ],
                      [
                          -70.513969,
                          46.255262,
                          0
                      ],
                      [
                          -70.513879,
                          46.255226,
                          0
                      ],
                      [
                          -70.513775,
                          46.2552,
                          0
                      ],
                      [
                          -70.513671,
                          46.255173,
                          0
                      ],
                      [
                          -70.513568,
                          46.255155,
                          0
                      ],
                      [
                          -70.513476,
                          46.255137,
                          0
                      ],
                      [
                          -70.513334,
                          46.2551,
                          0
                      ],
                      [
                          -70.513192,
                          46.255056,
                          0
                      ],
                      [
                          -70.513048,
                          46.255011,
                          0
                      ],
                      [
                          -70.512905,
                          46.254966,
                          0
                      ],
                      [
                          -70.512763,
                          46.25493,
                          0
                      ],
                      [
                          -70.51262,
                          46.254903,
                          0
                      ],
                      [
                          -70.512616,
                          46.254902,
                          0
                      ],
                      [
                          -70.512478,
                          46.254867,
                          0
                      ],
                      [
                          -70.512335,
                          46.254822,
                          0
                      ],
                      [
                          -70.512191,
                          46.254777,
                          0
                      ],
                      [
                          -70.512146,
                          46.254764,
                          0
                      ],
                      [
                          -70.512037,
                          46.254733,
                          0
                      ],
                      [
                          -70.511894,
                          46.254696,
                          0
                      ],
                      [
                          -70.511752,
                          46.25467,
                          0
                      ],
                      [
                          -70.511634,
                          46.254642,
                          0
                      ],
                      [
                          -70.511531,
                          46.254642,
                          0
                      ],
                      [
                          -70.511427,
                          46.254642,
                          0
                      ],
                      [
                          -70.511363,
                          46.254651,
                          0
                      ],
                      [
                          -70.511346,
                          46.254655,
                          0
                      ],
                      [
                          -70.511298,
                          46.25467,
                          0
                      ],
                      [
                          -70.511207,
                          46.254705,
                          0
                      ],
                      [
                          -70.511077,
                          46.254813,
                          0
                      ],
                      [
                          -70.510987,
                          46.254894,
                          0
                      ],
                      [
                          -70.510895,
                          46.254976,
                          0
                      ],
                      [
                          -70.510841,
                          46.255023,
                          0
                      ],
                      [
                          -70.510804,
                          46.255056,
                          0
                      ],
                      [
                          -70.510714,
                          46.255137,
                          0
                      ],
                      [
                          -70.510662,
                          46.255191,
                          0
                      ],
                      [
                          -70.510596,
                          46.255245,
                          0
                      ],
                      [
                          -70.51051,
                          46.255305,
                          0
                      ],
                      [
                          -70.510493,
                          46.255317,
                          0
                      ],
                      [
                          -70.510389,
                          46.25538,
                          0
                      ],
                      [
                          -70.510286,
                          46.255443,
                          0
                      ],
                      [
                          -70.510194,
                          46.255506,
                          0
                      ],
                      [
                          -70.510091,
                          46.25557,
                          0
                      ],
                      [
                          -70.510053,
                          46.2556,
                          0
                      ],
                      [
                          -70.510001,
                          46.255641,
                          0
                      ],
                      [
                          -70.509909,
                          46.255722,
                          0
                      ],
                      [
                          -70.509819,
                          46.255803,
                          0
                      ],
                      [
                          -70.509728,
                          46.255875,
                          0
                      ],
                      [
                          -70.509624,
                          46.255947,
                          0
                      ],
                      [
                          -70.509562,
                          46.255981,
                          0
                      ],
                      [
                          -70.509559,
                          46.255983,
                          0
                      ],
                      [
                          -70.509507,
                          46.256027,
                          0
                      ],
                      [
                          -70.509443,
                          46.256091,
                          0
                      ],
                      [
                          -70.509377,
                          46.256153,
                          0
                      ],
                      [
                          -70.509287,
                          46.256226,
                          0
                      ],
                      [
                          -70.50917,
                          46.256325,
                          0
                      ],
                      [
                          -70.509157,
                          46.256361,
                          0
                      ],
                      [
                          -70.50914,
                          46.256386,
                          0
                      ],
                      [
                          -70.509119,
                          46.256415,
                          0
                      ],
                      [
                          -70.509067,
                          46.256469,
                          0
                      ],
                      [
                          -70.508976,
                          46.256541,
                          0
                      ],
                      [
                          -70.508936,
                          46.256568,
                          0
                      ],
                      [
                          -70.508781,
                          46.256657,
                          0
                      ],
                      [
                          -70.508772,
                          46.256662,
                          0
                      ],
                      [
                          -70.5086,
                          46.25673,
                          0
                      ],
                      [
                          -70.508444,
                          46.256775,
                          0
                      ],
                      [
                          -70.508301,
                          46.256811,
                          0
                      ],
                      [
                          -70.508159,
                          46.256856,
                          0
                      ],
                      [
                          -70.508096,
                          46.256874,
                          0
                      ],
                      [
                          -70.508004,
                          46.256901,
                          0
                      ],
                      [
                          -70.507847,
                          46.256946,
                          0
                      ],
                      [
                          -70.507679,
                          46.256982,
                          0
                      ],
                      [
                          -70.507575,
                          46.257009,
                          0
                      ],
                      [
                          -70.507471,
                          46.257036,
                          0
                      ],
                      [
                          -70.507315,
                          46.257072,
                          0
                      ],
                      [
                          -70.507147,
                          46.257099,
                          0
                      ],
                      [
                          -70.507124,
                          46.257102,
                          0
                      ],
                      [
                          -70.506965,
                          46.257126,
                          0
                      ],
                      [
                          -70.506797,
                          46.257161,
                          0
                      ],
                      [
                          -70.506627,
                          46.257189,
                          0
                      ],
                      [
                          -70.506472,
                          46.257225,
                          0
                      ],
                      [
                          -70.506356,
                          46.257252,
                          0
                      ],
                      [
                          -70.506227,
                          46.25727,
                          0
                      ],
                      [
                          -70.506174,
                          46.25727,
                          0
                      ],
                      [
                          -70.506122,
                          46.257269,
                          0
                      ],
                      [
                          -70.50604,
                          46.25727,
                          0
                      ],
                      [
                          -70.505953,
                          46.25727,
                          0
                      ],
                      [
                          -70.505797,
                          46.25727,
                          0
                      ],
                      [
                          -70.505629,
                          46.257261,
                          0
                      ],
                      [
                          -70.505474,
                          46.257242,
                          0
                      ],
                      [
                          -70.505318,
                          46.257225,
                          0
                      ],
                      [
                          -70.505176,
                          46.257207,
                          0
                      ],
                      [
                          -70.505103,
                          46.2572,
                          0
                      ],
                      [
                          -70.505085,
                          46.257198,
                          0
                      ],
                      [
                          -70.50504,
                          46.257193,
                          0
                      ],
                      [
                          -70.504994,
                          46.257189,
                          0
                      ],
                      [
                          -70.504989,
                          46.257189,
                          0
                      ],
                      [
                          -70.504876,
                          46.257189,
                          0
                      ],
                      [
                          -70.504734,
                          46.257171,
                          0
                      ],
                      [
                          -70.504566,
                          46.257189,
                          0
                      ],
                      [
                          -70.504384,
                          46.257198,
                          0
                      ],
                      [
                          -70.504253,
                          46.257194,
                          0
                      ],
                      [
                          -70.504073,
                          46.257189,
                          0
                      ],
                      [
                          -70.503775,
                          46.257179,
                          0
                      ],
                      [
                          -70.503567,
                          46.25718,
                          0
                      ],
                      [
                          -70.503372,
                          46.257171,
                          0
                      ],
                      [
                          -70.503153,
                          46.257144,
                          0
                      ],
                      [
                          -70.503057,
                          46.257137,
                          0
                      ],
                      [
                          -70.502919,
                          46.257126,
                          0
                      ],
                      [
                          -70.502659,
                          46.25709,
                          0
                      ],
                      [
                          -70.502321,
                          46.25709,
                          0
                      ],
                      [
                          -70.502145,
                          46.25709,
                          0
                      ],
                      [
                          -70.501958,
                          46.25709,
                          0
                      ],
                      [
                          -70.501479,
                          46.257153,
                          0
                      ],
                      [
                          -70.501426,
                          46.257162,
                          0
                      ],
                      [
                          -70.501409,
                          46.257164,
                          0
                      ],
                      [
                          -70.501258,
                          46.25718,
                          0
                      ],
                      [
                          -70.501038,
                          46.257216,
                          0
                      ],
                      [
                          -70.500597,
                          46.25727,
                          0
                      ],
                      [
                          -70.500143,
                          46.257279,
                          0
                      ],
                      [
                          -70.500064,
                          46.257274,
                          0
                      ],
                      [
                          -70.5,
                          46.25727,
                          0
                      ],
                      [
                          -70.499909,
                          46.257261,
                          0
                      ],
                      [
                          -70.499702,
                          46.257261,
                          0
                      ],
                      [
                          -70.499482,
                          46.257269,
                          0
                      ],
                      [
                          -70.499352,
                          46.25727,
                          0
                      ],
                      [
                          -70.499208,
                          46.257279,
                          0
                      ],
                      [
                          -70.499139,
                          46.257303,
                          0
                      ],
                      [
                          -70.499131,
                          46.257306,
                          0
                      ],
                      [
                          -70.498781,
                          46.25745,
                          0
                      ],
                      [
                          -70.498444,
                          46.257593,
                          0
                      ],
                      [
                          -70.498235,
                          46.257765,
                          0
                      ],
                      [
                          -70.498137,
                          46.257851,
                          0
                      ],
                      [
                          -70.498041,
                          46.257936,
                          0
                      ],
                      [
                          -70.49795,
                          46.258017,
                          0
                      ],
                      [
                          -70.497846,
                          46.258097,
                          0
                      ],
                      [
                          -70.497718,
                          46.258206,
                          0
                      ],
                      [
                          -70.497587,
                          46.258304,
                          0
                      ],
                      [
                          -70.49747,
                          46.258376,
                          0
                      ],
                      [
                          -70.497341,
                          46.258439,
                          0
                      ],
                      [
                          -70.497277,
                          46.258466,
                          0
                      ],
                      [
                          -70.497211,
                          46.258485,
                          0
                      ],
                      [
                          -70.497072,
                          46.258514,
                          0
                      ],
                      [
                          -70.497043,
                          46.258521,
                          0
                      ],
                      [
                          -70.496874,
                          46.258539,
                          0
                      ],
                      [
                          -70.496706,
                          46.258538,
                          0
                      ],
                      [
                          -70.49655,
                          46.258538,
                          0
                      ],
                      [
                          -70.496342,
                          46.258547,
                          0
                      ],
                      [
                          -70.496147,
                          46.258565,
                          0
                      ],
                      [
                          -70.496089,
                          46.258584,
                          0
                      ],
                      [
                          -70.49603,
                          46.258601,
                          0
                      ],
                      [
                          -70.495915,
                          46.258647,
                          0
                      ],
                      [
                          -70.495693,
                          46.258745,
                          0
                      ],
                      [
                          -70.495486,
                          46.258853,
                          0
                      ],
                      [
                          -70.495279,
                          46.259006,
                          0
                      ],
                      [
                          -70.49507,
                          46.259159,
                          0
                      ],
                      [
                          -70.494981,
                          46.259217,
                          0
                      ],
                      [
                          -70.494903,
                          46.259267,
                          0
                      ],
                      [
                          -70.494734,
                          46.259384,
                          0
                      ],
                      [
                          -70.49463,
                          46.259456,
                          0
                      ],
                      [
                          -70.494539,
                          46.259537,
                          0
                      ],
                      [
                          -70.494458,
                          46.2596,
                          0
                      ],
                      [
                          -70.494435,
                          46.259619,
                          0
                      ],
                      [
                          -70.494434,
                          46.25962,
                          0
                      ],
                      [
                          -70.494418,
                          46.259633,
                          0
                      ],
                      [
                          -70.494345,
                          46.259699,
                          0
                      ],
                      [
                          -70.494228,
                          46.25978,
                          0
                      ],
                      [
                          -70.494167,
                          46.259818,
                          0
                      ],
                      [
                          -70.494098,
                          46.259861,
                          0
                      ],
                      [
                          -70.493942,
                          46.259942,
                          0
                      ],
                      [
                          -70.493761,
                          46.259996,
                          0
                      ],
                      [
                          -70.493566,
                          46.260013,
                          0
                      ],
                      [
                          -70.493384,
                          46.260005,
                          0
                      ],
                      [
                          -70.493174,
                          46.259977,
                          0
                      ],
                      [
                          -70.493112,
                          46.259969,
                          0
                      ],
                      [
                          -70.49284,
                          46.259933,
                          0
                      ],
                      [
                          -70.492683,
                          46.259906,
                          0
                      ],
                      [
                          -70.492541,
                          46.259879,
                          0
                      ],
                      [
                          -70.492373,
                          46.259843,
                          0
                      ],
                      [
                          -70.492204,
                          46.259807,
                          0
                      ],
                      [
                          -70.492097,
                          46.259782,
                          0
                      ],
                      [
                          -70.492009,
                          46.259762,
                          0
                      ],
                      [
                          -70.491815,
                          46.259717,
                          0
                      ],
                      [
                          -70.49175,
                          46.259708,
                          0
                      ],
                      [
                          -70.491608,
                          46.259663,
                          0
                      ],
                      [
                          -70.491504,
                          46.259618,
                          0
                      ],
                      [
                          -70.491503,
                          46.259618,
                          0
                      ],
                      [
                          -70.491485,
                          46.259604,
                          0
                      ],
                      [
                          -70.491447,
                          46.259573,
                          0
                      ],
                      [
                          -70.491412,
                          46.259546,
                          0
                      ],
                      [
                          -70.491412,
                          46.259544,
                          0
                      ],
                      [
                          -70.490677,
                          46.260026,
                          0
                      ],
                      [
                          -70.490302,
                          46.26027,
                          0
                      ],
                      [
                          -70.468747,
                          46.274355,
                          0
                      ],
                      [
                          -70.467386,
                          46.275258,
                          0
                      ],
                      [
                          -70.45423,
                          46.265654,
                          0
                      ],
                      [
                          -70.440923,
                          46.255996,
                          0
                      ],
                      [
                          -70.440903,
                          46.255981,
                          0
                      ],
                      [
                          -70.440917,
                          46.255973,
                          0
                      ],
                      [
                          -70.445855,
                          46.252748,
                          0
                      ],
                      [
                          -70.447784,
                          46.251488,
                          0
                      ],
                      [
                          -70.449117,
                          46.250618,
                          0
                      ],
                      [
                          -70.444962,
                          46.251049,
                          0
                      ],
                      [
                          -70.444893,
                          46.251057,
                          0
                      ],
                      [
                          -70.444763,
                          46.251075,
                          0
                      ],
                      [
                          -70.444634,
                          46.251102,
                          0
                      ],
                      [
                          -70.444504,
                          46.25113,
                          0
                      ],
                      [
                          -70.444426,
                          46.251147,
                          0
                      ],
                      [
                          -70.444322,
                          46.251193,
                          0
                      ],
                      [
                          -70.444258,
                          46.25122,
                          0
                      ],
                      [
                          -70.444193,
                          46.251237,
                          0
                      ],
                      [
                          -70.444127,
                          46.251246,
                          0
                      ],
                      [
                          -70.44405,
                          46.251255,
                          0
                      ],
                      [
                          -70.443932,
                          46.251264,
                          0
                      ],
                      [
                          -70.443878,
                          46.251268,
                          0
                      ],
                      [
                          -70.443817,
                          46.251273,
                          0
                      ],
                      [
                          -70.443738,
                          46.251264,
                          0
                      ],
                      [
                          -70.443596,
                          46.251228,
                          0
                      ],
                      [
                          -70.443414,
                          46.251183,
                          0
                      ],
                      [
                          -70.443272,
                          46.251129,
                          0
                      ],
                      [
                          -70.443129,
                          46.251057,
                          0
                      ],
                      [
                          -70.442999,
                          46.250985,
                          0
                      ],
                      [
                          -70.442948,
                          46.250958,
                          0
                      ],
                      [
                          -70.442883,
                          46.250922,
                          0
                      ],
                      [
                          -70.442752,
                          46.250858,
                          0
                      ],
                      [
                          -70.442739,
                          46.250855,
                          0
                      ],
                      [
                          -70.442689,
                          46.25084,
                          0
                      ],
                      [
                          -70.442612,
                          46.250822,
                          0
                      ],
                      [
                          -70.44261,
                          46.250822,
                          0
                      ],
                      [
                          -70.442587,
                          46.250813,
                          0
                      ],
                      [
                          -70.442482,
                          46.250768,
                          0
                      ],
                      [
                          -70.442364,
                          46.250724,
                          0
                      ],
                      [
                          -70.442299,
                          46.250724,
                          0
                      ],
                      [
                          -70.442157,
                          46.250723,
                          0
                      ],
                      [
                          -70.442027,
                          46.250723,
                          0
                      ],
                      [
                          -70.441924,
                          46.250723,
                          0
                      ],
                      [
                          -70.441877,
                          46.250723,
                          0
                      ],
                      [
                          -70.441833,
                          46.250724,
                          0
                      ],
                      [
                          -70.441768,
                          46.250714,
                          0
                      ],
                      [
                          -70.441715,
                          46.250705,
                          0
                      ],
                      [
                          -70.441599,
                          46.25066,
                          0
                      ],
                      [
                          -70.441548,
                          46.250624,
                          0
                      ],
                      [
                          -70.441495,
                          46.250588,
                          0
                      ],
                      [
                          -70.441392,
                          46.250507,
                          0
                      ],
                      [
                          -70.441291,
                          46.250416,
                          0
                      ],
                      [
                          -70.441262,
                          46.25039,
                          0
                      ],
                      [
                          -70.441237,
                          46.250354,
                          0
                      ],
                      [
                          -70.441198,
                          46.250282,
                          0
                      ],
                      [
                          -70.441185,
                          46.250237,
                          0
                      ],
                      [
                          -70.441133,
                          46.250138,
                          0
                      ],
                      [
                          -70.441094,
                          46.250039,
                          0
                      ],
                      [
                          -70.441081,
                          46.250003,
                          0
                      ],
                      [
                          -70.441056,
                          46.249922,
                          0
                      ],
                      [
                          -70.441037,
                          46.249888,
                          0
                      ],
                      [
                          -70.441016,
                          46.24985,
                          0
                      ],
                      [
                          -70.44099,
                          46.249805,
                          0
                      ],
                      [
                          -70.440965,
                          46.24976,
                          0
                      ],
                      [
                          -70.440926,
                          46.249715,
                          0
                      ],
                      [
                          -70.440887,
                          46.24967,
                          0
                      ],
                      [
                          -70.440836,
                          46.249634,
                          0
                      ],
                      [
                          -70.440783,
                          46.249616,
                          0
                      ],
                      [
                          -70.440733,
                          46.249609,
                          0
                      ],
                      [
                          -70.440718,
                          46.249607,
                          0
                      ],
                      [
                          -70.440641,
                          46.249607,
                          0
                      ],
                      [
                          -70.440576,
                          46.249607,
                          0
                      ],
                      [
                          -70.439109,
                          46.249642,
                          0
                      ],
                      [
                          -70.439054,
                          46.249649,
                          0
                      ],
                      [
                          -70.439032,
                          46.249651,
                          0
                      ],
                      [
                          -70.438915,
                          46.249705,
                          0
                      ],
                      [
                          -70.438799,
                          46.249795,
                          0
                      ],
                      [
                          -70.438682,
                          46.249885,
                          0
                      ],
                      [
                          -70.43865,
                          46.249913,
                          0
                      ],
                      [
                          -70.43863,
                          46.24993,
                          0
                      ],
                      [
                          -70.438606,
                          46.249951,
                          0
                      ],
                      [
                          -70.438561,
                          46.249993,
                          0
                      ],
                      [
                          -70.438552,
                          46.250002,
                          0
                      ],
                      [
                          -70.438423,
                          46.250073,
                          0
                      ],
                      [
                          -70.438357,
                          46.250118,
                          0
                      ],
                      [
                          -70.438329,
                          46.250134,
                          0
                      ],
                      [
                          -70.438266,
                          46.250172,
                          0
                      ],
                      [
                          -70.438189,
                          46.250226,
                          0
                      ],
                      [
                          -70.438188,
                          46.250227,
                          0
                      ],
                      [
                          -70.438165,
                          46.250248,
                          0
                      ],
                      [
                          -70.438137,
                          46.250271,
                          0
                      ],
                      [
                          -70.438073,
                          46.250307,
                          0
                      ],
                      [
                          -70.437994,
                          46.250352,
                          0
                      ],
                      [
                          -70.437904,
                          46.250406,
                          0
                      ],
                      [
                          -70.437858,
                          46.250434,
                          0
                      ],
                      [
                          -70.437799,
                          46.250469,
                          0
                      ],
                      [
                          -70.437735,
                          46.250505,
                          0
                      ],
                      [
                          -70.437644,
                          46.250577,
                          0
                      ],
                      [
                          -70.437592,
                          46.250631,
                          0
                      ],
                      [
                          -70.437514,
                          46.250685,
                          0
                      ],
                      [
                          -70.437409,
                          46.250757,
                          0
                      ],
                      [
                          -70.437359,
                          46.250801,
                          0
                      ],
                      [
                          -70.437307,
                          46.250847,
                          0
                      ],
                      [
                          -70.437241,
                          46.250892,
                          0
                      ],
                      [
                          -70.437194,
                          46.250918,
                          0
                      ],
                      [
                          -70.437176,
                          46.250927,
                          0
                      ],
                      [
                          -70.437033,
                          46.251,
                          0
                      ],
                      [
                          -70.436956,
                          46.251045,
                          0
                      ],
                      [
                          -70.43684,
                          46.251126,
                          0
                      ],
                      [
                          -70.436773,
                          46.251153,
                          0
                      ],
                      [
                          -70.436709,
                          46.251179,
                          0
                      ],
                      [
                          -70.436658,
                          46.251206,
                          0
                      ],
                      [
                          -70.436606,
                          46.251233,
                          0
                      ],
                      [
                          -70.436554,
                          46.251278,
                          0
                      ],
                      [
                          -70.436515,
                          46.251305,
                          0
                      ],
                      [
                          -70.436501,
                          46.251315,
                          0
                      ],
                      [
                          -70.436449,
                          46.251342,
                          0
                      ],
                      [
                          -70.436444,
                          46.251342,
                          0
                      ],
                      [
                          -70.436358,
                          46.25135,
                          0
                      ],
                      [
                          -70.436242,
                          46.25135,
                          0
                      ],
                      [
                          -70.436151,
                          46.25135,
                          0
                      ],
                      [
                          -70.436009,
                          46.251341,
                          0
                      ],
                      [
                          -70.435924,
                          46.251323,
                          0
                      ],
                      [
                          -70.435879,
                          46.251314,
                          0
                      ],
                      [
                          -70.435827,
                          46.251304,
                          0
                      ],
                      [
                          -70.435776,
                          46.251296,
                          0
                      ],
                      [
                          -70.435672,
                          46.251269,
                          0
                      ],
                      [
                          -70.435594,
                          46.251242,
                          0
                      ],
                      [
                          -70.435515,
                          46.251215,
                          0
                      ],
                      [
                          -70.435464,
                          46.251205,
                          0
                      ],
                      [
                          -70.435373,
                          46.251169,
                          0
                      ],
                      [
                          -70.435322,
                          46.251151,
                          0
                      ],
                      [
                          -70.435257,
                          46.251133,
                          0
                      ],
                      [
                          -70.435075,
                          46.251124,
                          0
                      ],
                      [
                          -70.434933,
                          46.251125,
                          0
                      ],
                      [
                          -70.434896,
                          46.251128,
                          0
                      ],
                      [
                          -70.434751,
                          46.251143,
                          0
                      ],
                      [
                          -70.434621,
                          46.251152,
                          0
                      ],
                      [
                          -70.434518,
                          46.251161,
                          0
                      ],
                      [
                          -70.434453,
                          46.251169,
                          0
                      ],
                      [
                          -70.434297,
                          46.251187,
                          0
                      ],
                      [
                          -70.434206,
                          46.251205,
                          0
                      ],
                      [
                          -70.434127,
                          46.251223,
                          0
                      ],
                      [
                          -70.434038,
                          46.251232,
                          0
                      ],
                      [
                          -70.433921,
                          46.251258,
                          0
                      ],
                      [
                          -70.433898,
                          46.251263,
                          0
                      ],
                      [
                          -70.433868,
                          46.251268,
                          0
                      ],
                      [
                          -70.433751,
                          46.251304,
                          0
                      ],
                      [
                          -70.433648,
                          46.25134,
                          0
                      ],
                      [
                          -70.433584,
                          46.251375,
                          0
                      ],
                      [
                          -70.433505,
                          46.251402,
                          0
                      ],
                      [
                          -70.433349,
                          46.251447,
                          0
                      ],
                      [
                          -70.433208,
                          46.251483,
                          0
                      ],
                      [
                          -70.433091,
                          46.25151,
                          0
                      ],
                      [
                          -70.432986,
                          46.251537,
                          0
                      ],
                      [
                          -70.432921,
                          46.251564,
                          0
                      ],
                      [
                          -70.43283,
                          46.251582,
                          0
                      ],
                      [
                          -70.43277,
                          46.251589,
                          0
                      ],
                      [
                          -70.432753,
                          46.251591,
                          0
                      ],
                      [
                          -70.432752,
                          46.251592,
                          0
                      ],
                      [
                          -70.432719,
                          46.251598,
                          0
                      ],
                      [
                          -70.432675,
                          46.251609,
                          0
                      ],
                      [
                          -70.432519,
                          46.251645,
                          0
                      ],
                      [
                          -70.432377,
                          46.251681,
                          0
                      ],
                      [
                          -70.432298,
                          46.251698,
                          0
                      ],
                      [
                          -70.432234,
                          46.251717,
                          0
                      ],
                      [
                          -70.432091,
                          46.251761,
                          0
                      ],
                      [
                          -70.431975,
                          46.251779,
                          0
                      ],
                      [
                          -70.431899,
                          46.251794,
                          0
                      ],
                      [
                          -70.431793,
                          46.251815,
                          0
                      ],
                      [
                          -70.431741,
                          46.251824,
                          0
                      ],
                      [
                          -70.431663,
                          46.251834,
                          0
                      ],
                      [
                          -70.431534,
                          46.251842,
                          0
                      ],
                      [
                          -70.43139,
                          46.251851,
                          0
                      ],
                      [
                          -70.431261,
                          46.251851,
                          0
                      ],
                      [
                          -70.431171,
                          46.251851,
                          0
                      ],
                      [
                          -70.431066,
                          46.25186,
                          0
                      ],
                      [
                          -70.431015,
                          46.251887,
                          0
                      ],
                      [
                          -70.430937,
                          46.251895,
                          0
                      ],
                      [
                          -70.430872,
                          46.251914,
                          0
                      ],
                      [
                          -70.430853,
                          46.251918,
                          0
                      ],
                      [
                          -70.430793,
                          46.251931,
                          0
                      ],
                      [
                          -70.430742,
                          46.25194,
                          0
                      ],
                      [
                          -70.43069,
                          46.25194,
                          0
                      ],
                      [
                          -70.430587,
                          46.251959,
                          0
                      ],
                      [
                          -70.430534,
                          46.251968,
                          0
                      ],
                      [
                          -70.430483,
                          46.251968,
                          0
                      ],
                      [
                          -70.430431,
                          46.251967,
                          0
                      ],
                      [
                          -70.430378,
                          46.251967,
                          0
                      ],
                      [
                          -70.430327,
                          46.251976,
                          0
                      ],
                      [
                          -70.430236,
                          46.251976,
                          0
                      ],
                      [
                          -70.430145,
                          46.251977,
                          0
                      ],
                      [
                          -70.42999,
                          46.252013,
                          0
                      ],
                      [
                          -70.429934,
                          46.252028,
                          0
                      ],
                      [
                          -70.42986,
                          46.252048,
                          0
                      ],
                      [
                          -70.429704,
                          46.252066,
                          0
                      ],
                      [
                          -70.429548,
                          46.252066,
                          0
                      ],
                      [
                          -70.429459,
                          46.252048,
                          0
                      ],
                      [
                          -70.429406,
                          46.25203,
                          0
                      ],
                      [
                          -70.429355,
                          46.251994,
                          0
                      ],
                      [
                          -70.42929,
                          46.251976,
                          0
                      ],
                      [
                          -70.429212,
                          46.251948,
                          0
                      ],
                      [
                          -70.429133,
                          46.251903,
                          0
                      ],
                      [
                          -70.429019,
                          46.251853,
                          0
                      ],
                      [
                          -70.428991,
                          46.251841,
                          0
                      ],
                      [
                          -70.428932,
                          46.251834,
                          0
                      ],
                      [
                          -70.428913,
                          46.251831,
                          0
                      ],
                      [
                          -70.42881,
                          46.251804,
                          0
                      ],
                      [
                          -70.42879,
                          46.251801,
                          0
                      ],
                      [
                          -70.428711,
                          46.251787,
                          0
                      ],
                      [
                          -70.428706,
                          46.251786,
                          0
                      ],
                      [
                          -70.428538,
                          46.251786,
                          0
                      ],
                      [
                          -70.428408,
                          46.251786,
                          0
                      ],
                      [
                          -70.428356,
                          46.251795,
                          0
                      ],
                      [
                          -70.428291,
                          46.251795,
                          0
                      ],
                      [
                          -70.428187,
                          46.251795,
                          0
                      ],
                      [
                          -70.428096,
                          46.251795,
                          0
                      ],
                      [
                          -70.428032,
                          46.251804,
                          0
                      ],
                      [
                          -70.427876,
                          46.251822,
                          0
                      ],
                      [
                          -70.427805,
                          46.251835,
                          0
                      ],
                      [
                          -70.42772,
                          46.251849,
                          0
                      ],
                      [
                          -70.42759,
                          46.251867,
                          0
                      ],
                      [
                          -70.42754,
                          46.251875,
                          0
                      ],
                      [
                          -70.427461,
                          46.251894,
                          0
                      ],
                      [
                          -70.427344,
                          46.25193,
                          0
                      ],
                      [
                          -70.427227,
                          46.251974,
                          0
                      ],
                      [
                          -70.427098,
                          46.25202,
                          0
                      ],
                      [
                          -70.426981,
                          46.252064,
                          0
                      ],
                      [
                          -70.426938,
                          46.252089,
                          0
                      ],
                      [
                          -70.426904,
                          46.252109,
                          0
                      ],
                      [
                          -70.426825,
                          46.252154,
                          0
                      ],
                      [
                          -70.426785,
                          46.25219,
                          0
                      ],
                      [
                          -70.426709,
                          46.252244,
                          0
                      ],
                      [
                          -70.426592,
                          46.252316,
                          0
                      ],
                      [
                          -70.42654,
                          46.252343,
                          0
                      ],
                      [
                          -70.426449,
                          46.252387,
                          0
                      ],
                      [
                          -70.426358,
                          46.252424,
                          0
                      ],
                      [
                          -70.426293,
                          46.252433,
                          0
                      ],
                      [
                          -70.426164,
                          46.252442,
                          0
                      ],
                      [
                          -70.42602,
                          46.252433,
                          0
                      ],
                      [
                          -70.42593,
                          46.252405,
                          0
                      ],
                      [
                          -70.425888,
                          46.252396,
                          0
                      ],
                      [
                          -70.425813,
                          46.252378,
                          0
                      ],
                      [
                          -70.425762,
                          46.252378,
                          0
                      ],
                      [
                          -70.425618,
                          46.252369,
                          0
                      ],
                      [
                          -70.425488,
                          46.25237,
                          0
                      ],
                      [
                          -70.425334,
                          46.252369,
                          0
                      ],
                      [
                          -70.425281,
                          46.252369,
                          0
                      ],
                      [
                          -70.42519,
                          46.252369,
                          0
                      ],
                      [
                          -70.425099,
                          46.25236,
                          0
                      ],
                      [
                          -70.425022,
                          46.252342,
                          0
                      ],
                      [
                          -70.424971,
                          46.252324,
                          0
                      ],
                      [
                          -70.424932,
                          46.252297,
                          0
                      ],
                      [
                          -70.424905,
                          46.25226,
                          0
                      ],
                      [
                          -70.424866,
                          46.25218,
                          0
                      ],
                      [
                          -70.424829,
                          46.252117,
                          0
                      ],
                      [
                          -70.424813,
                          46.252104,
                          0
                      ],
                      [
                          -70.424776,
                          46.252072,
                          0
                      ],
                      [
                          -70.424751,
                          46.252036,
                          0
                      ],
                      [
                          -70.424698,
                          46.251982,
                          0
                      ],
                      [
                          -70.424634,
                          46.251937,
                          0
                      ],
                      [
                          -70.424477,
                          46.251846,
                          0
                      ],
                      [
                          -70.4244,
                          46.251802,
                          0
                      ],
                      [
                          -70.424335,
                          46.251774,
                          0
                      ],
                      [
                          -70.424193,
                          46.251748,
                          0
                      ],
                      [
                          -70.424128,
                          46.251747,
                          0
                      ],
                      [
                          -70.424122,
                          46.251747,
                          0
                      ],
                      [
                          -70.424024,
                          46.251747,
                          0
                      ],
                      [
                          -70.423981,
                          46.251748,
                          0
                      ],
                      [
                          -70.423895,
                          46.251747,
                          0
                      ],
                      [
                          -70.423816,
                          46.251784,
                          0
                      ],
                      [
                          -70.42379,
                          46.251802,
                          0
                      ],
                      [
                          -70.423778,
                          46.25181,
                          0
                      ],
                      [
                          -70.423739,
                          46.251837,
                          0
                      ],
                      [
                          -70.423699,
                          46.251864,
                          0
                      ],
                      [
                          -70.423661,
                          46.251891,
                          0
                      ],
                      [
                          -70.423635,
                          46.251945,
                          0
                      ],
                      [
                          -70.423622,
                          46.251999,
                          0
                      ],
                      [
                          -70.423596,
                          46.252089,
                          0
                      ],
                      [
                          -70.423557,
                          46.252161,
                          0
                      ],
                      [
                          -70.423517,
                          46.252232,
                          0
                      ],
                      [
                          -70.423388,
                          46.252313,
                          0
                      ],
                      [
                          -70.423271,
                          46.252322,
                          0
                      ],
                      [
                          -70.423167,
                          46.252323,
                          0
                      ],
                      [
                          -70.42309,
                          46.252305,
                          0
                      ],
                      [
                          -70.423025,
                          46.252295,
                          0
                      ],
                      [
                          -70.42296,
                          46.252287,
                          0
                      ],
                      [
                          -70.422869,
                          46.25226,
                          0
                      ],
                      [
                          -70.422779,
                          46.252223,
                          0
                      ],
                      [
                          -70.422723,
                          46.252195,
                          0
                      ],
                      [
                          -70.422675,
                          46.252169,
                          0
                      ],
                      [
                          -70.422598,
                          46.252133,
                          0
                      ],
                      [
                          -70.422467,
                          46.25207,
                          0
                      ],
                      [
                          -70.422364,
                          46.252025,
                          0
                      ],
                      [
                          -70.422235,
                          46.251953,
                          0
                      ],
                      [
                          -70.422104,
                          46.251873,
                          0
                      ],
                      [
                          -70.421988,
                          46.2518,
                          0
                      ],
                      [
                          -70.421858,
                          46.251728,
                          0
                      ],
                      [
                          -70.421807,
                          46.251699,
                          0
                      ],
                      [
                          -70.421794,
                          46.251692,
                          0
                      ],
                      [
                          -70.421715,
                          46.251665,
                          0
                      ],
                      [
                          -70.421664,
                          46.251629,
                          0
                      ],
                      [
                          -70.421599,
                          46.251601,
                          0
                      ],
                      [
                          -70.421509,
                          46.251557,
                          0
                      ],
                      [
                          -70.421418,
                          46.251512,
                          0
                      ],
                      [
                          -70.421414,
                          46.25151,
                          0
                      ],
                      [
                          -70.421366,
                          46.251476,
                          0
                      ],
                      [
                          -70.421249,
                          46.25143,
                          0
                      ],
                      [
                          -70.421215,
                          46.251419,
                          0
                      ],
                      [
                          -70.421094,
                          46.251376,
                          0
                      ],
                      [
                          -70.421016,
                          46.25135,
                          0
                      ],
                      [
                          -70.420886,
                          46.251304,
                          0
                      ],
                      [
                          -70.420756,
                          46.251268,
                          0
                      ],
                      [
                          -70.420705,
                          46.25126,
                          0
                      ],
                      [
                          -70.420873,
                          46.251151,
                          0
                      ],
                      [
                          -70.420991,
                          46.25107,
                          0
                      ],
                      [
                          -70.421997,
                          46.250408,
                          0
                      ],
                      [
                          -70.421997,
                          46.250407,
                          0
                      ],
                      [
                          -70.42202,
                          46.250391,
                          0
                      ],
                      [
                          -70.421978,
                          46.250341,
                          0
                      ],
                      [
                          -70.421942,
                          46.250316,
                          0
                      ],
                      [
                          -70.409068,
                          46.240955,
                          0
                      ],
                      [
                          -70.409991,
                          46.240317,
                          0
                      ],
                      [
                          -70.40995,
                          46.240288,
                          0
                      ],
                      [
                          -70.409888,
                          46.24024,
                          0
                      ],
                      [
                          -70.396711,
                          46.230272,
                          0
                      ],
                      [
                          -70.396599,
                          46.230191,
                          0
                      ],
                      [
                          -70.38351,
                          46.220614,
                          0
                      ],
                      [
                          -70.379218,
                          46.217472,
                          0
                      ],
                      [
                          -70.372327,
                          46.212437,
                          0
                      ],
                      [
                          -70.372225,
                          46.212365,
                          0
                      ],
                      [
                          -70.370164,
                          46.213712,
                          0
                      ],
                      [
                          -70.365829,
                          46.216543,
                          0
                      ],
                      [
                          -70.351169,
                          46.226272,
                          0
                      ],
                      [
                          -70.347761,
                          46.228577,
                          0
                      ],
                      [
                          -70.347744,
                          46.228553,
                          0
                      ],
                      [
                          -70.340431,
                          46.218712,
                          0
                      ],
                      [
                          -70.340427,
                          46.218708,
                          0
                      ],
                      [
                          -70.339824,
                          46.217895,
                          0
                      ],
                      [
                          -70.339707,
                          46.21794,
                          0
                      ],
                      [
                          -70.339565,
                          46.217994,
                          0
                      ],
                      [
                          -70.339345,
                          46.218066,
                          0
                      ],
                      [
                          -70.339125,
                          46.21813,
                          0
                      ],
                      [
                          -70.339035,
                          46.218157,
                          0
                      ],
                      [
                          -70.338983,
                          46.218167,
                          0
                      ],
                      [
                          -70.338762,
                          46.218221,
                          0
                      ],
                      [
                          -70.338685,
                          46.21824,
                          0
                      ],
                      [
                          -70.33853,
                          46.218267,
                          0
                      ],
                      [
                          -70.338362,
                          46.218294,
                          0
                      ],
                      [
                          -70.338289,
                          46.218302,
                          0
                      ],
                      [
                          -70.338194,
                          46.218313,
                          0
                      ],
                      [
                          -70.330381,
                          46.221054,
                          0
                      ],
                      [
                          -70.317999,
                          46.225447,
                          0
                      ],
                      [
                          -70.317995,
                          46.225449,
                          0
                      ],
                      [
                          -70.309514,
                          46.228457,
                          0
                      ],
                      [
                          -70.309398,
                          46.228502,
                          0
                      ],
                      [
                          -70.286301,
                          46.236662,
                          0
                      ],
                      [
                          -70.280041,
                          46.232394,
                          0
                      ],
                      [
                          -70.278727,
                          46.231408,
                          0
                      ],
                      [
                          -70.275604,
                          46.229184,
                          0
                      ],
                      [
                          -70.274211,
                          46.228207,
                          0
                      ],
                      [
                          -70.272845,
                          46.227328,
                          0
                      ],
                      [
                          -70.272624,
                          46.227176,
                          0
                      ],
                      [
                          -70.272494,
                          46.227059,
                          0
                      ],
                      [
                          -70.272298,
                          46.226845,
                          0
                      ],
                      [
                          -70.272233,
                          46.226763,
                          0
                      ],
                      [
                          -70.272103,
                          46.226682,
                          0
                      ],
                      [
                          -70.272038,
                          46.226682,
                          0
                      ],
                      [
                          -70.271778,
                          46.226548,
                          0
                      ],
                      [
                          -70.271713,
                          46.226495,
                          0
                      ],
                      [
                          -70.271453,
                          46.226326,
                          0
                      ],
                      [
                          -70.271284,
                          46.226217,
                          0
                      ],
                      [
                          -70.271115,
                          46.2261,
                          0
                      ],
                      [
                          -70.270945,
                          46.225975,
                          0
                      ],
                      [
                          -70.270932,
                          46.225939,
                          0
                      ],
                      [
                          -70.270905,
                          46.225759,
                          0
                      ],
                      [
                          -70.270893,
                          46.225723,
                          0
                      ],
                      [
                          -70.270749,
                          46.225651,
                          0
                      ],
                      [
                          -70.270332,
                          46.225103,
                          0
                      ],
                      [
                          -70.26766,
                          46.222402,
                          0
                      ],
                      [
                          -70.267178,
                          46.221931,
                          0
                      ],
                      [
                          -70.273334,
                          46.214003,
                          0
                      ],
                      [
                          -70.277086,
                          46.205139,
                          0
                      ],
                      [
                          -70.284615,
                          46.197698,
                          0
                      ],
                      [
                          -70.292351,
                          46.190291,
                          0
                      ],
                      [
                          -70.285276,
                          46.18236,
                          0
                      ],
                      [
                          -70.277589,
                          46.174583,
                          0
                      ],
                      [
                          -70.267135,
                          46.169245,
                          0
                      ],
                      [
                          -70.24977,
                          46.15576,
                          0
                      ],
                      [
                          -70.240021,
                          46.149802,
                          0
                      ],
                      [
                          -70.239707,
                          46.140798,
                          0
                      ],
                      [
                          -70.242412,
                          46.131927,
                          0
                      ],
                      [
                          -70.25217,
                          46.115081,
                          0
                      ],
                      [
                          -70.252188,
                          46.10531,
                          0
                      ],
                      [
                          -70.26329,
                          46.100513,
                          0
                      ],
                      [
                          -70.2774,
                          46.100491,
                          0
                      ],
                      [
                          -70.288157,
                          46.095217,
                          0
                      ],
                      [
                          -70.292532,
                          46.08647,
                          0
                      ],
                      [
                          -70.302274,
                          46.080433,
                          0
                      ],
                      [
                          -70.304987,
                          46.071606,
                          0
                      ],
                      [
                          -70.309437,
                          46.062509,
                          0
                      ],
                      [
                          -70.296656,
                          46.060413,
                          0
                      ],
                      [
                          -70.284014,
                          46.062478,
                          0
                      ],
                      [
                          -70.280492,
                          46.053788,
                          0
                      ],
                      [
                          -70.288555,
                          46.046707,
                          0
                      ],
                      [
                          -70.297412,
                          46.039947,
                          0
                      ],
                      [
                          -70.299037,
                          46.031013,
                          0
                      ],
                      [
                          -70.307979,
                          46.024502,
                          0
                      ],
                      [
                          -70.315074,
                          46.016817,
                          0
                      ],
                      [
                          -70.306123,
                          46.00977,
                          0
                      ],
                      [
                          -70.303451,
                          46.000555,
                          0
                      ],
                      [
                          -70.291439,
                          45.997106,
                          0
                      ],
                      [
                          -70.292565,
                          45.988062,
                          0
                      ],
                      [
                          -70.303895,
                          45.983729,
                          0
                      ],
                      [
                          -70.310137,
                          45.975823,
                          0
                      ],
                      [
                          -70.311582,
                          45.966397,
                          0
                      ],
                      [
                          -70.298935,
                          45.963546,
                          0
                      ],
                      [
                          -70.285544,
                          45.964475,
                          0
                      ],
                      [
                          -70.273544,
                          45.961092,
                          0
                      ],
                      [
                          -70.251111,
                          45.953613,
                          0
                      ],
                      [
                          -70.242328,
                          45.946637,
                          0
                      ],
                      [
                          -70.242967,
                          45.937341,
                          0
                      ],
                      [
                          -70.253529,
                          45.93191,
                          0
                      ],
                      [
                          -70.261993,
                          45.925124,
                          0
                      ],
                      [
                          -70.257337,
                          45.916307,
                          0
                      ],
                      [
                          -70.253507,
                          45.9074,
                          0
                      ],
                      [
                          -70.259569,
                          45.89927,
                          0
                      ],
                      [
                          -70.259747,
                          45.890013,
                          0
                      ],
                      [
                          -70.270219,
                          45.884496,
                          0
                      ],
                      [
                          -70.287576,
                          45.87085,
                          0
                      ],
                      [
                          -70.298522,
                          45.8642,
                          0
                      ],
                      [
                          -70.32318,
                          45.855389,
                          0
                      ],
                      [
                          -70.335914,
                          45.853269,
                          0
                      ],
                      [
                          -70.347801,
                          45.847861,
                          0
                      ],
                      [
                          -70.354582,
                          45.840054,
                          0
                      ],
                      [
                          -70.36522,
                          45.834841,
                          0
                      ],
                      [
                          -70.373551,
                          45.82767,
                          0
                      ],
                      [
                          -70.39016,
                          45.813274,
                          0
                      ],
                      [
                          -70.39678,
                          45.805523,
                          0
                      ],
                      [
                          -70.398913,
                          45.796627,
                          0
                      ],
                      [
                          -70.412616,
                          45.796265,
                          0
                      ],
                      [
                          -70.415175,
                          45.787345,
                          0
                      ],
                      [
                          -70.408004,
                          45.77952,
                          0
                      ],
                      [
                          -70.405768,
                          45.770614,
                          0
                      ],
                      [
                          -70.405452,
                          45.761392,
                          0
                      ],
                      [
                          -70.395021,
                          45.755607,
                          0
                      ],
                      [
                          -70.388492,
                          45.747824,
                          0
                      ],
                      [
                          -70.392278,
                          45.738646,
                          0
                      ],
                      [
                          -70.389143,
                          45.72948,
                          0
                      ],
                      [
                          -70.398642,
                          45.72274,
                          0
                      ],
                      [
                          -70.42207,
                          45.712204,
                          0
                      ],
                      [
                          -70.432789,
                          45.706314,
                          0
                      ],
                      [
                          -70.445307,
                          45.703675,
                          0
                      ],
                      [
                          -70.457377,
                          45.706998,
                          0
                      ],
                      [
                          -70.470198,
                          45.703409,
                          0
                      ],
                      [
                          -70.477989,
                          45.695973,
                          0
                      ],
                      [
                          -70.488905,
                          45.690524,
                          0
                      ],
                      [
                          -70.51175,
                          45.677621,
                          0
                      ],
                      [
                          -70.519334,
                          45.670216,
                          0
                      ],
                      [
                          -70.532373,
                          45.670531,
                          0
                      ],
                      [
                          -70.544527,
                          45.66693,
                          0
                      ],
                      [
                          -70.557409,
                          45.667064,
                          0
                      ],
                      [
                          -70.563258,
                          45.658209,
                          0
                      ],
                      [
                          -70.570695,
                          45.65066,
                          0
                      ],
                      [
                          -70.586571,
                          45.636,
                          0
                      ],
                      [
                          -70.598567,
                          45.628328,
                          0
                      ],
                      [
                          -70.742437,
                          45.704616,
                          0
                      ],
                      [
                          -70.743085,
                          45.704135,
                          0
                      ],
                      [
                          -70.743906,
                          45.704306,
                          0
                      ],
                      [
                          -70.744687,
                          45.704834,
                          0
                      ],
                      [
                          -70.745844,
                          45.705844,
                          0
                      ],
                      [
                          -70.745498,
                          45.706881,
                          0
                      ],
                      [
                          -70.743447,
                          45.708009,
                          0
                      ],
                      [
                          -70.742707,
                          45.709346,
                          0
                      ],
                      [
                          -70.74376,
                          45.710239,
                          0
                      ],
                      [
                          -70.745026,
                          45.71077,
                          0
                      ],
                      [
                          -70.746716,
                          45.71051,
                          0
                      ],
                      [
                          -70.74673,
                          45.710506,
                          0
                      ],
                      [
                          -70.749824,
                          45.709672,
                          0
                      ],
                      [
                          -70.751166,
                          45.709118,
                          0
                      ],
                      [
                          -70.751196,
                          45.709133,
                          0
                      ],
                      [
                          -70.783865,
                          45.725758,
                          0
                      ],
                      [
                          -70.783881,
                          45.725767,
                          0
                      ],
                      [
                          -70.724348,
                          45.78212,
                          0
                      ],
                      [
                          -70.724347,
                          45.782121,
                          0
                      ],
                      [
                          -70.724448,
                          45.782119,
                          0
                      ],
                      [
                          -70.89563,
                          45.779989,
                          0
                      ],
                      [
                          -70.89563,
                          45.779991,
                          0
                      ],
                      [
                          -70.895632,
                          45.780026,
                          0
                      ],
                      [
                          -70.898394,
                          45.84171,
                          0
                      ],
                      [
                          -70.898397,
                          45.84171,
                          0
                      ],
                      [
                          -70.914842,
                          45.841566,
                          0
                      ],
                      [
                          -70.915134,
                          45.836022,
                          0
                      ],
                      [
                          -70.936879,
                          45.845952,
                          0
                      ],
                      [
                          -70.947803,
                          45.835421,
                          0
                      ],
                      [
                          -70.979022,
                          45.850722,
                          0
                      ],
                      [
                          -70.990244,
                          45.839916,
                          0
                      ],
                      [
                          -70.990245,
                          45.839917,
                          0
                      ],
                      [
                          -71.029232,
                          45.859279,
                          0
                      ],
                      [
                          -71.029308,
                          45.859316,
                          0
                      ],
                      [
                          -71.029207,
                          45.859407,
                          0
                      ],
                      [
                          -71.01829,
                          45.869809,
                          0
                      ],
                      [
                          -71.093746,
                          45.90701,
                          0
                      ],
                      [
                          -71.08232,
                          45.918134,
                          0
                      ],
                      [
                          -71.082318,
                          45.918135,
                          0
                      ],
                      [
                          -71.081166,
                          45.917609,
                          0
                      ],
                      [
                          -71.081161,
                          45.917607,
                          0
                      ],
                      [
                          -71.070624,
                          45.928271,
                          0
                      ],
                      [
                          -71.070622,
                          45.928273,
                          0
                      ],
                      [
                          -71.070656,
                          45.928288,
                          0
                      ],
                      [
                          -71.070661,
                          45.92829,
                          0
                      ],
                      [
                          -71.070687,
                          45.928299,
                          0
                      ],
                      [
                          -71.10677,
                          45.946136,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Beauce-Sud",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -71.18438,
                          46.755575,
                          0
                      ],
                      [
                          -71.16671,
                          46.778211,
                          0
                      ],
                      [
                          -71.129639,
                          46.801302,
                          0
                      ],
                      [
                          -71.091082,
                          46.814681,
                          0
                      ],
                      [
                          -71.063774,
                          46.812793,
                          0
                      ],
                      [
                          -71.046189,
                          46.816006,
                          0
                      ],
                      [
                          -71.078856,
                          46.839139,
                          0
                      ],
                      [
                          -70.997192,
                          46.848338,
                          0
                      ],
                      [
                          -70.817919,
                          46.934176,
                          0
                      ],
                      [
                          -70.781378,
                          46.908174,
                          0
                      ],
                      [
                          -70.778602,
                          46.905868,
                          0
                      ],
                      [
                          -70.780209,
                          46.90542,
                          0
                      ],
                      [
                          -70.781788,
                          46.906307,
                          0
                      ],
                      [
                          -70.782552,
                          46.906512,
                          0
                      ],
                      [
                          -70.782551,
                          46.906511,
                          0
                      ],
                      [
                          -70.752502,
                          46.885137,
                          0
                      ],
                      [
                          -70.756997,
                          46.882918,
                          0
                      ],
                      [
                          -70.757979,
                          46.882253,
                          0
                      ],
                      [
                          -70.75756,
                          46.881941,
                          0
                      ],
                      [
                          -70.759057,
                          46.880993,
                          0
                      ],
                      [
                          -70.754184,
                          46.877595,
                          0
                      ],
                      [
                          -70.755727,
                          46.876587,
                          0
                      ],
                      [
                          -70.754184,
                          46.87551,
                          0
                      ],
                      [
                          -70.75511,
                          46.874919,
                          0
                      ],
                      [
                          -70.756089,
                          46.87564,
                          0
                      ],
                      [
                          -70.759665,
                          46.874001,
                          0
                      ],
                      [
                          -70.758115,
                          46.872713,
                          0
                      ],
                      [
                          -70.759134,
                          46.871985,
                          0
                      ],
                      [
                          -70.756335,
                          46.870067,
                          0
                      ],
                      [
                          -70.757424,
                          46.869257,
                          0
                      ],
                      [
                          -70.757444,
                          46.869242,
                          0
                      ],
                      [
                          -70.756012,
                          46.868233,
                          0
                      ],
                      [
                          -70.760817,
                          46.865474,
                          0
                      ],
                      [
                          -70.765084,
                          46.861812,
                          0
                      ],
                      [
                          -70.766438,
                          46.86272,
                          0
                      ],
                      [
                          -70.767468,
                          46.861797,
                          0
                      ],
                      [
                          -70.762273,
                          46.858132,
                          0
                      ],
                      [
                          -70.763337,
                          46.857373,
                          0
                      ],
                      [
                          -70.767912,
                          46.860561,
                          0
                      ],
                      [
                          -70.769281,
                          46.859648,
                          0
                      ],
                      [
                          -70.766617,
                          46.857767,
                          0
                      ],
                      [
                          -70.77119,
                          46.855275,
                          0
                      ],
                      [
                          -70.7455,
                          46.837682,
                          0
                      ],
                      [
                          -70.746954,
                          46.836779,
                          0
                      ],
                      [
                          -70.724785,
                          46.821725,
                          0
                      ],
                      [
                          -70.72562,
                          46.8212,
                          0
                      ],
                      [
                          -70.725632,
                          46.821191,
                          0
                      ],
                      [
                          -70.703742,
                          46.806452,
                          0
                      ],
                      [
                          -70.695554,
                          46.81353,
                          0
                      ],
                      [
                          -70.691474,
                          46.818691,
                          0
                      ],
                      [
                          -70.684897,
                          46.834348,
                          0
                      ],
                      [
                          -70.678269,
                          46.835441,
                          0
                      ],
                      [
                          -70.629957,
                          46.802125,
                          0
                      ],
                      [
                          -70.579244,
                          46.836781,
                          0
                      ],
                      [
                          -70.495991,
                          46.778931,
                          0
                      ],
                      [
                          -70.497754,
                          46.777696,
                          0
                      ],
                      [
                          -70.497762,
                          46.777691,
                          0
                      ],
                      [
                          -70.475065,
                          46.762465,
                          0
                      ],
                      [
                          -70.481552,
                          46.758891,
                          0
                      ],
                      [
                          -70.48286,
                          46.757509,
                          0
                      ],
                      [
                          -70.492371,
                          46.75612,
                          0
                      ],
                      [
                          -70.493675,
                          46.755148,
                          0
                      ],
                      [
                          -70.493898,
                          46.756359,
                          0
                      ],
                      [
                          -70.4939,
                          46.75636,
                          0
                      ],
                      [
                          -70.494995,
                          46.755783,
                          0
                      ],
                      [
                          -70.496333,
                          46.754764,
                          0
                      ],
                      [
                          -70.497589,
                          46.755004,
                          0
                      ],
                      [
                          -70.501039,
                          46.753422,
                          0
                      ],
                      [
                          -70.503223,
                          46.751308,
                          0
                      ],
                      [
                          -70.495201,
                          46.745807,
                          0
                      ],
                      [
                          -70.496718,
                          46.744397,
                          0
                      ],
                      [
                          -70.489969,
                          46.739756,
                          0
                      ],
                      [
                          -70.493795,
                          46.737127,
                          0
                      ],
                      [
                          -70.492336,
                          46.735929,
                          0
                      ],
                      [
                          -70.492943,
                          46.734952,
                          0
                      ],
                      [
                          -70.490921,
                          46.732286,
                          0
                      ],
                      [
                          -70.48898,
                          46.731912,
                          0
                      ],
                      [
                          -70.48494,
                          46.732046,
                          0
                      ],
                      [
                          -70.484105,
                          46.731069,
                          0
                      ],
                      [
                          -70.484104,
                          46.731068,
                          0
                      ],
                      [
                          -70.480602,
                          46.730244,
                          0
                      ],
                      [
                          -70.480226,
                          46.725053,
                          0
                      ],
                      [
                          -70.481412,
                          46.723253,
                          0
                      ],
                      [
                          -70.48025,
                          46.72078,
                          0
                      ],
                      [
                          -70.477583,
                          46.718946,
                          0
                      ],
                      [
                          -70.44345,
                          46.742379,
                          0
                      ],
                      [
                          -70.324427,
                          46.658046,
                          0
                      ],
                      [
                          -70.324384,
                          46.658016,
                          0
                      ],
                      [
                          -70.297184,
                          46.676423,
                          0
                      ],
                      [
                          -70.162693,
                          46.581461,
                          0
                      ],
                      [
                          -70.188968,
                          46.563262,
                          0
                      ],
                      [
                          -70.18897,
                          46.563261,
                          0
                      ],
                      [
                          -70.046791,
                          46.462756,
                          0
                      ],
                      [
                          -70.046762,
                          46.46276,
                          0
                      ],
                      [
                          -70.04676,
                          46.462733,
                          0
                      ],
                      [
                          -70.049037,
                          46.451981,
                          0
                      ],
                      [
                          -70.053694,
                          46.429976,
                          0
                      ],
                      [
                          -70.055804,
                          46.420141,
                          0
                      ],
                      [
                          -70.065326,
                          46.413403,
                          0
                      ],
                      [
                          -70.077362,
                          46.409582,
                          0
                      ],
                      [
                          -70.090333,
                          46.410607,
                          0
                      ],
                      [
                          -70.100911,
                          46.405253,
                          0
                      ],
                      [
                          -70.102949,
                          46.395641,
                          0
                      ],
                      [
                          -70.109703,
                          46.387867,
                          0
                      ],
                      [
                          -70.12253,
                          46.382583,
                          0
                      ],
                      [
                          -70.127306,
                          46.372513,
                          0
                      ],
                      [
                          -70.138102,
                          46.367332,
                          0
                      ],
                      [
                          -70.146998,
                          46.360089,
                          0
                      ],
                      [
                          -70.159989,
                          46.359556,
                          0
                      ],
                      [
                          -70.174109,
                          46.35867,
                          0
                      ],
                      [
                          -70.182616,
                          46.3518,
                          0
                      ],
                      [
                          -70.193301,
                          46.346286,
                          0
                      ],
                      [
                          -70.199662,
                          46.338039,
                          0
                      ],
                      [
                          -70.208748,
                          46.329915,
                          0
                      ],
                      [
                          -70.206801,
                          46.320337,
                          0
                      ],
                      [
                          -70.204897,
                          46.310793,
                          0
                      ],
                      [
                          -70.206637,
                          46.301532,
                          0
                      ],
                      [
                          -70.215243,
                          46.294776,
                          0
                      ],
                      [
                          -70.227719,
                          46.292088,
                          0
                      ],
                      [
                          -70.234327,
                          46.283472,
                          0
                      ],
                      [
                          -70.239967,
                          46.275301,
                          0
                      ],
                      [
                          -70.247794,
                          46.267922,
                          0
                      ],
                      [
                          -70.252641,
                          46.259514,
                          0
                      ],
                      [
                          -70.251419,
                          46.250287,
                          0
                      ],
                      [
                          -70.256982,
                          46.242142,
                          0
                      ],
                      [
                          -70.259705,
                          46.233327,
                          0
                      ],
                      [
                          -70.266826,
                          46.225326,
                          0
                      ],
                      [
                          -70.267178,
                          46.221931,
                          0
                      ],
                      [
                          -70.26766,
                          46.222402,
                          0
                      ],
                      [
                          -70.270332,
                          46.225103,
                          0
                      ],
                      [
                          -70.270749,
                          46.225651,
                          0
                      ],
                      [
                          -70.270893,
                          46.225723,
                          0
                      ],
                      [
                          -70.270905,
                          46.225759,
                          0
                      ],
                      [
                          -70.270932,
                          46.225939,
                          0
                      ],
                      [
                          -70.270945,
                          46.225975,
                          0
                      ],
                      [
                          -70.271115,
                          46.2261,
                          0
                      ],
                      [
                          -70.271284,
                          46.226217,
                          0
                      ],
                      [
                          -70.271453,
                          46.226326,
                          0
                      ],
                      [
                          -70.271713,
                          46.226495,
                          0
                      ],
                      [
                          -70.271778,
                          46.226548,
                          0
                      ],
                      [
                          -70.272038,
                          46.226682,
                          0
                      ],
                      [
                          -70.272103,
                          46.226682,
                          0
                      ],
                      [
                          -70.272233,
                          46.226763,
                          0
                      ],
                      [
                          -70.272298,
                          46.226845,
                          0
                      ],
                      [
                          -70.272494,
                          46.227059,
                          0
                      ],
                      [
                          -70.272624,
                          46.227176,
                          0
                      ],
                      [
                          -70.272845,
                          46.227328,
                          0
                      ],
                      [
                          -70.274211,
                          46.228207,
                          0
                      ],
                      [
                          -70.275604,
                          46.229184,
                          0
                      ],
                      [
                          -70.278727,
                          46.231408,
                          0
                      ],
                      [
                          -70.280041,
                          46.232394,
                          0
                      ],
                      [
                          -70.286301,
                          46.236662,
                          0
                      ],
                      [
                          -70.309398,
                          46.228502,
                          0
                      ],
                      [
                          -70.309514,
                          46.228457,
                          0
                      ],
                      [
                          -70.317995,
                          46.225449,
                          0
                      ],
                      [
                          -70.317999,
                          46.225447,
                          0
                      ],
                      [
                          -70.330381,
                          46.221054,
                          0
                      ],
                      [
                          -70.338194,
                          46.218313,
                          0
                      ],
                      [
                          -70.338289,
                          46.218302,
                          0
                      ],
                      [
                          -70.338362,
                          46.218294,
                          0
                      ],
                      [
                          -70.33853,
                          46.218267,
                          0
                      ],
                      [
                          -70.338685,
                          46.21824,
                          0
                      ],
                      [
                          -70.338762,
                          46.218221,
                          0
                      ],
                      [
                          -70.338983,
                          46.218167,
                          0
                      ],
                      [
                          -70.339035,
                          46.218157,
                          0
                      ],
                      [
                          -70.339125,
                          46.21813,
                          0
                      ],
                      [
                          -70.339345,
                          46.218066,
                          0
                      ],
                      [
                          -70.339565,
                          46.217994,
                          0
                      ],
                      [
                          -70.339707,
                          46.21794,
                          0
                      ],
                      [
                          -70.339824,
                          46.217895,
                          0
                      ],
                      [
                          -70.340427,
                          46.218708,
                          0
                      ],
                      [
                          -70.340431,
                          46.218712,
                          0
                      ],
                      [
                          -70.347744,
                          46.228553,
                          0
                      ],
                      [
                          -70.347761,
                          46.228577,
                          0
                      ],
                      [
                          -70.351169,
                          46.226272,
                          0
                      ],
                      [
                          -70.365829,
                          46.216543,
                          0
                      ],
                      [
                          -70.370164,
                          46.213712,
                          0
                      ],
                      [
                          -70.372225,
                          46.212365,
                          0
                      ],
                      [
                          -70.372327,
                          46.212437,
                          0
                      ],
                      [
                          -70.379218,
                          46.217472,
                          0
                      ],
                      [
                          -70.38351,
                          46.220614,
                          0
                      ],
                      [
                          -70.396599,
                          46.230191,
                          0
                      ],
                      [
                          -70.396711,
                          46.230272,
                          0
                      ],
                      [
                          -70.409888,
                          46.24024,
                          0
                      ],
                      [
                          -70.40995,
                          46.240288,
                          0
                      ],
                      [
                          -70.409991,
                          46.240317,
                          0
                      ],
                      [
                          -70.409068,
                          46.240955,
                          0
                      ],
                      [
                          -70.421942,
                          46.250316,
                          0
                      ],
                      [
                          -70.421978,
                          46.250341,
                          0
                      ],
                      [
                          -70.42202,
                          46.250391,
                          0
                      ],
                      [
                          -70.421997,
                          46.250407,
                          0
                      ],
                      [
                          -70.421997,
                          46.250408,
                          0
                      ],
                      [
                          -70.420991,
                          46.25107,
                          0
                      ],
                      [
                          -70.420873,
                          46.251151,
                          0
                      ],
                      [
                          -70.420705,
                          46.25126,
                          0
                      ],
                      [
                          -70.420756,
                          46.251268,
                          0
                      ],
                      [
                          -70.420886,
                          46.251304,
                          0
                      ],
                      [
                          -70.421016,
                          46.25135,
                          0
                      ],
                      [
                          -70.421094,
                          46.251376,
                          0
                      ],
                      [
                          -70.421215,
                          46.251419,
                          0
                      ],
                      [
                          -70.421249,
                          46.25143,
                          0
                      ],
                      [
                          -70.421366,
                          46.251476,
                          0
                      ],
                      [
                          -70.421414,
                          46.25151,
                          0
                      ],
                      [
                          -70.421418,
                          46.251512,
                          0
                      ],
                      [
                          -70.421509,
                          46.251557,
                          0
                      ],
                      [
                          -70.421599,
                          46.251601,
                          0
                      ],
                      [
                          -70.421664,
                          46.251629,
                          0
                      ],
                      [
                          -70.421715,
                          46.251665,
                          0
                      ],
                      [
                          -70.421794,
                          46.251692,
                          0
                      ],
                      [
                          -70.421807,
                          46.251699,
                          0
                      ],
                      [
                          -70.421858,
                          46.251728,
                          0
                      ],
                      [
                          -70.421988,
                          46.2518,
                          0
                      ],
                      [
                          -70.422104,
                          46.251873,
                          0
                      ],
                      [
                          -70.422235,
                          46.251953,
                          0
                      ],
                      [
                          -70.422364,
                          46.252025,
                          0
                      ],
                      [
                          -70.422467,
                          46.25207,
                          0
                      ],
                      [
                          -70.422598,
                          46.252133,
                          0
                      ],
                      [
                          -70.422675,
                          46.252169,
                          0
                      ],
                      [
                          -70.422723,
                          46.252195,
                          0
                      ],
                      [
                          -70.422779,
                          46.252223,
                          0
                      ],
                      [
                          -70.422869,
                          46.25226,
                          0
                      ],
                      [
                          -70.42296,
                          46.252287,
                          0
                      ],
                      [
                          -70.423025,
                          46.252295,
                          0
                      ],
                      [
                          -70.42309,
                          46.252305,
                          0
                      ],
                      [
                          -70.423167,
                          46.252323,
                          0
                      ],
                      [
                          -70.423271,
                          46.252322,
                          0
                      ],
                      [
                          -70.423388,
                          46.252313,
                          0
                      ],
                      [
                          -70.423517,
                          46.252232,
                          0
                      ],
                      [
                          -70.423557,
                          46.252161,
                          0
                      ],
                      [
                          -70.423596,
                          46.252089,
                          0
                      ],
                      [
                          -70.423622,
                          46.251999,
                          0
                      ],
                      [
                          -70.423635,
                          46.251945,
                          0
                      ],
                      [
                          -70.423661,
                          46.251891,
                          0
                      ],
                      [
                          -70.423699,
                          46.251864,
                          0
                      ],
                      [
                          -70.423739,
                          46.251837,
                          0
                      ],
                      [
                          -70.423778,
                          46.25181,
                          0
                      ],
                      [
                          -70.42379,
                          46.251802,
                          0
                      ],
                      [
                          -70.423816,
                          46.251784,
                          0
                      ],
                      [
                          -70.423895,
                          46.251747,
                          0
                      ],
                      [
                          -70.423981,
                          46.251748,
                          0
                      ],
                      [
                          -70.424024,
                          46.251747,
                          0
                      ],
                      [
                          -70.424122,
                          46.251747,
                          0
                      ],
                      [
                          -70.424128,
                          46.251747,
                          0
                      ],
                      [
                          -70.424193,
                          46.251748,
                          0
                      ],
                      [
                          -70.424335,
                          46.251774,
                          0
                      ],
                      [
                          -70.4244,
                          46.251802,
                          0
                      ],
                      [
                          -70.424477,
                          46.251846,
                          0
                      ],
                      [
                          -70.424634,
                          46.251937,
                          0
                      ],
                      [
                          -70.424698,
                          46.251982,
                          0
                      ],
                      [
                          -70.424751,
                          46.252036,
                          0
                      ],
                      [
                          -70.424776,
                          46.252072,
                          0
                      ],
                      [
                          -70.424813,
                          46.252104,
                          0
                      ],
                      [
                          -70.424829,
                          46.252117,
                          0
                      ],
                      [
                          -70.424866,
                          46.25218,
                          0
                      ],
                      [
                          -70.424905,
                          46.25226,
                          0
                      ],
                      [
                          -70.424932,
                          46.252297,
                          0
                      ],
                      [
                          -70.424971,
                          46.252324,
                          0
                      ],
                      [
                          -70.425022,
                          46.252342,
                          0
                      ],
                      [
                          -70.425099,
                          46.25236,
                          0
                      ],
                      [
                          -70.42519,
                          46.252369,
                          0
                      ],
                      [
                          -70.425281,
                          46.252369,
                          0
                      ],
                      [
                          -70.425334,
                          46.252369,
                          0
                      ],
                      [
                          -70.425488,
                          46.25237,
                          0
                      ],
                      [
                          -70.425618,
                          46.252369,
                          0
                      ],
                      [
                          -70.425762,
                          46.252378,
                          0
                      ],
                      [
                          -70.425813,
                          46.252378,
                          0
                      ],
                      [
                          -70.425888,
                          46.252396,
                          0
                      ],
                      [
                          -70.42593,
                          46.252405,
                          0
                      ],
                      [
                          -70.42602,
                          46.252433,
                          0
                      ],
                      [
                          -70.426164,
                          46.252442,
                          0
                      ],
                      [
                          -70.426293,
                          46.252433,
                          0
                      ],
                      [
                          -70.426358,
                          46.252424,
                          0
                      ],
                      [
                          -70.426449,
                          46.252387,
                          0
                      ],
                      [
                          -70.42654,
                          46.252343,
                          0
                      ],
                      [
                          -70.426592,
                          46.252316,
                          0
                      ],
                      [
                          -70.426709,
                          46.252244,
                          0
                      ],
                      [
                          -70.426785,
                          46.25219,
                          0
                      ],
                      [
                          -70.426825,
                          46.252154,
                          0
                      ],
                      [
                          -70.426904,
                          46.252109,
                          0
                      ],
                      [
                          -70.426938,
                          46.252089,
                          0
                      ],
                      [
                          -70.426981,
                          46.252064,
                          0
                      ],
                      [
                          -70.427098,
                          46.25202,
                          0
                      ],
                      [
                          -70.427227,
                          46.251974,
                          0
                      ],
                      [
                          -70.427344,
                          46.25193,
                          0
                      ],
                      [
                          -70.427461,
                          46.251894,
                          0
                      ],
                      [
                          -70.42754,
                          46.251875,
                          0
                      ],
                      [
                          -70.42759,
                          46.251867,
                          0
                      ],
                      [
                          -70.42772,
                          46.251849,
                          0
                      ],
                      [
                          -70.427805,
                          46.251835,
                          0
                      ],
                      [
                          -70.427876,
                          46.251822,
                          0
                      ],
                      [
                          -70.428032,
                          46.251804,
                          0
                      ],
                      [
                          -70.428096,
                          46.251795,
                          0
                      ],
                      [
                          -70.428187,
                          46.251795,
                          0
                      ],
                      [
                          -70.428291,
                          46.251795,
                          0
                      ],
                      [
                          -70.428356,
                          46.251795,
                          0
                      ],
                      [
                          -70.428408,
                          46.251786,
                          0
                      ],
                      [
                          -70.428538,
                          46.251786,
                          0
                      ],
                      [
                          -70.428706,
                          46.251786,
                          0
                      ],
                      [
                          -70.428711,
                          46.251787,
                          0
                      ],
                      [
                          -70.42879,
                          46.251801,
                          0
                      ],
                      [
                          -70.42881,
                          46.251804,
                          0
                      ],
                      [
                          -70.428913,
                          46.251831,
                          0
                      ],
                      [
                          -70.428932,
                          46.251834,
                          0
                      ],
                      [
                          -70.428991,
                          46.251841,
                          0
                      ],
                      [
                          -70.429019,
                          46.251853,
                          0
                      ],
                      [
                          -70.429133,
                          46.251903,
                          0
                      ],
                      [
                          -70.429212,
                          46.251948,
                          0
                      ],
                      [
                          -70.42929,
                          46.251976,
                          0
                      ],
                      [
                          -70.429355,
                          46.251994,
                          0
                      ],
                      [
                          -70.429406,
                          46.25203,
                          0
                      ],
                      [
                          -70.429459,
                          46.252048,
                          0
                      ],
                      [
                          -70.429548,
                          46.252066,
                          0
                      ],
                      [
                          -70.429704,
                          46.252066,
                          0
                      ],
                      [
                          -70.42986,
                          46.252048,
                          0
                      ],
                      [
                          -70.429934,
                          46.252028,
                          0
                      ],
                      [
                          -70.42999,
                          46.252013,
                          0
                      ],
                      [
                          -70.430145,
                          46.251977,
                          0
                      ],
                      [
                          -70.430236,
                          46.251976,
                          0
                      ],
                      [
                          -70.430327,
                          46.251976,
                          0
                      ],
                      [
                          -70.430378,
                          46.251967,
                          0
                      ],
                      [
                          -70.430431,
                          46.251967,
                          0
                      ],
                      [
                          -70.430483,
                          46.251968,
                          0
                      ],
                      [
                          -70.430534,
                          46.251968,
                          0
                      ],
                      [
                          -70.430587,
                          46.251959,
                          0
                      ],
                      [
                          -70.43069,
                          46.25194,
                          0
                      ],
                      [
                          -70.430742,
                          46.25194,
                          0
                      ],
                      [
                          -70.430793,
                          46.251931,
                          0
                      ],
                      [
                          -70.430853,
                          46.251918,
                          0
                      ],
                      [
                          -70.430872,
                          46.251914,
                          0
                      ],
                      [
                          -70.430937,
                          46.251895,
                          0
                      ],
                      [
                          -70.431015,
                          46.251887,
                          0
                      ],
                      [
                          -70.431066,
                          46.25186,
                          0
                      ],
                      [
                          -70.431171,
                          46.251851,
                          0
                      ],
                      [
                          -70.431261,
                          46.251851,
                          0
                      ],
                      [
                          -70.43139,
                          46.251851,
                          0
                      ],
                      [
                          -70.431534,
                          46.251842,
                          0
                      ],
                      [
                          -70.431663,
                          46.251834,
                          0
                      ],
                      [
                          -70.431741,
                          46.251824,
                          0
                      ],
                      [
                          -70.431793,
                          46.251815,
                          0
                      ],
                      [
                          -70.431899,
                          46.251794,
                          0
                      ],
                      [
                          -70.431975,
                          46.251779,
                          0
                      ],
                      [
                          -70.432091,
                          46.251761,
                          0
                      ],
                      [
                          -70.432234,
                          46.251717,
                          0
                      ],
                      [
                          -70.432298,
                          46.251698,
                          0
                      ],
                      [
                          -70.432377,
                          46.251681,
                          0
                      ],
                      [
                          -70.432519,
                          46.251645,
                          0
                      ],
                      [
                          -70.432675,
                          46.251609,
                          0
                      ],
                      [
                          -70.432719,
                          46.251598,
                          0
                      ],
                      [
                          -70.432752,
                          46.251592,
                          0
                      ],
                      [
                          -70.432753,
                          46.251591,
                          0
                      ],
                      [
                          -70.43277,
                          46.251589,
                          0
                      ],
                      [
                          -70.43283,
                          46.251582,
                          0
                      ],
                      [
                          -70.432921,
                          46.251564,
                          0
                      ],
                      [
                          -70.432986,
                          46.251537,
                          0
                      ],
                      [
                          -70.433091,
                          46.25151,
                          0
                      ],
                      [
                          -70.433208,
                          46.251483,
                          0
                      ],
                      [
                          -70.433349,
                          46.251447,
                          0
                      ],
                      [
                          -70.433505,
                          46.251402,
                          0
                      ],
                      [
                          -70.433584,
                          46.251375,
                          0
                      ],
                      [
                          -70.433648,
                          46.25134,
                          0
                      ],
                      [
                          -70.433751,
                          46.251304,
                          0
                      ],
                      [
                          -70.433868,
                          46.251268,
                          0
                      ],
                      [
                          -70.433898,
                          46.251263,
                          0
                      ],
                      [
                          -70.433921,
                          46.251258,
                          0
                      ],
                      [
                          -70.434038,
                          46.251232,
                          0
                      ],
                      [
                          -70.434127,
                          46.251223,
                          0
                      ],
                      [
                          -70.434206,
                          46.251205,
                          0
                      ],
                      [
                          -70.434297,
                          46.251187,
                          0
                      ],
                      [
                          -70.434453,
                          46.251169,
                          0
                      ],
                      [
                          -70.434518,
                          46.251161,
                          0
                      ],
                      [
                          -70.434621,
                          46.251152,
                          0
                      ],
                      [
                          -70.434751,
                          46.251143,
                          0
                      ],
                      [
                          -70.434896,
                          46.251128,
                          0
                      ],
                      [
                          -70.434933,
                          46.251125,
                          0
                      ],
                      [
                          -70.435075,
                          46.251124,
                          0
                      ],
                      [
                          -70.435257,
                          46.251133,
                          0
                      ],
                      [
                          -70.435322,
                          46.251151,
                          0
                      ],
                      [
                          -70.435373,
                          46.251169,
                          0
                      ],
                      [
                          -70.435464,
                          46.251205,
                          0
                      ],
                      [
                          -70.435515,
                          46.251215,
                          0
                      ],
                      [
                          -70.435594,
                          46.251242,
                          0
                      ],
                      [
                          -70.435672,
                          46.251269,
                          0
                      ],
                      [
                          -70.435776,
                          46.251296,
                          0
                      ],
                      [
                          -70.435827,
                          46.251304,
                          0
                      ],
                      [
                          -70.435879,
                          46.251314,
                          0
                      ],
                      [
                          -70.435924,
                          46.251323,
                          0
                      ],
                      [
                          -70.436009,
                          46.251341,
                          0
                      ],
                      [
                          -70.436151,
                          46.25135,
                          0
                      ],
                      [
                          -70.436242,
                          46.25135,
                          0
                      ],
                      [
                          -70.436358,
                          46.25135,
                          0
                      ],
                      [
                          -70.436444,
                          46.251342,
                          0
                      ],
                      [
                          -70.436449,
                          46.251342,
                          0
                      ],
                      [
                          -70.436501,
                          46.251315,
                          0
                      ],
                      [
                          -70.436515,
                          46.251305,
                          0
                      ],
                      [
                          -70.436554,
                          46.251278,
                          0
                      ],
                      [
                          -70.436606,
                          46.251233,
                          0
                      ],
                      [
                          -70.436658,
                          46.251206,
                          0
                      ],
                      [
                          -70.436709,
                          46.251179,
                          0
                      ],
                      [
                          -70.436773,
                          46.251153,
                          0
                      ],
                      [
                          -70.43684,
                          46.251126,
                          0
                      ],
                      [
                          -70.436956,
                          46.251045,
                          0
                      ],
                      [
                          -70.437033,
                          46.251,
                          0
                      ],
                      [
                          -70.437176,
                          46.250927,
                          0
                      ],
                      [
                          -70.437194,
                          46.250918,
                          0
                      ],
                      [
                          -70.437241,
                          46.250892,
                          0
                      ],
                      [
                          -70.437307,
                          46.250847,
                          0
                      ],
                      [
                          -70.437359,
                          46.250801,
                          0
                      ],
                      [
                          -70.437409,
                          46.250757,
                          0
                      ],
                      [
                          -70.437514,
                          46.250685,
                          0
                      ],
                      [
                          -70.437592,
                          46.250631,
                          0
                      ],
                      [
                          -70.437644,
                          46.250577,
                          0
                      ],
                      [
                          -70.437735,
                          46.250505,
                          0
                      ],
                      [
                          -70.437799,
                          46.250469,
                          0
                      ],
                      [
                          -70.437858,
                          46.250434,
                          0
                      ],
                      [
                          -70.437904,
                          46.250406,
                          0
                      ],
                      [
                          -70.437994,
                          46.250352,
                          0
                      ],
                      [
                          -70.438073,
                          46.250307,
                          0
                      ],
                      [
                          -70.438137,
                          46.250271,
                          0
                      ],
                      [
                          -70.438165,
                          46.250248,
                          0
                      ],
                      [
                          -70.438188,
                          46.250227,
                          0
                      ],
                      [
                          -70.438189,
                          46.250226,
                          0
                      ],
                      [
                          -70.438266,
                          46.250172,
                          0
                      ],
                      [
                          -70.438329,
                          46.250134,
                          0
                      ],
                      [
                          -70.438357,
                          46.250118,
                          0
                      ],
                      [
                          -70.438423,
                          46.250073,
                          0
                      ],
                      [
                          -70.438552,
                          46.250002,
                          0
                      ],
                      [
                          -70.438561,
                          46.249993,
                          0
                      ],
                      [
                          -70.438606,
                          46.249951,
                          0
                      ],
                      [
                          -70.43863,
                          46.24993,
                          0
                      ],
                      [
                          -70.43865,
                          46.249913,
                          0
                      ],
                      [
                          -70.438682,
                          46.249885,
                          0
                      ],
                      [
                          -70.438799,
                          46.249795,
                          0
                      ],
                      [
                          -70.438915,
                          46.249705,
                          0
                      ],
                      [
                          -70.439032,
                          46.249651,
                          0
                      ],
                      [
                          -70.439054,
                          46.249649,
                          0
                      ],
                      [
                          -70.439109,
                          46.249642,
                          0
                      ],
                      [
                          -70.440576,
                          46.249607,
                          0
                      ],
                      [
                          -70.440641,
                          46.249607,
                          0
                      ],
                      [
                          -70.440718,
                          46.249607,
                          0
                      ],
                      [
                          -70.440733,
                          46.249609,
                          0
                      ],
                      [
                          -70.440783,
                          46.249616,
                          0
                      ],
                      [
                          -70.440836,
                          46.249634,
                          0
                      ],
                      [
                          -70.440887,
                          46.24967,
                          0
                      ],
                      [
                          -70.440926,
                          46.249715,
                          0
                      ],
                      [
                          -70.440965,
                          46.24976,
                          0
                      ],
                      [
                          -70.44099,
                          46.249805,
                          0
                      ],
                      [
                          -70.441016,
                          46.24985,
                          0
                      ],
                      [
                          -70.441037,
                          46.249888,
                          0
                      ],
                      [
                          -70.441056,
                          46.249922,
                          0
                      ],
                      [
                          -70.441081,
                          46.250003,
                          0
                      ],
                      [
                          -70.441094,
                          46.250039,
                          0
                      ],
                      [
                          -70.441133,
                          46.250138,
                          0
                      ],
                      [
                          -70.441185,
                          46.250237,
                          0
                      ],
                      [
                          -70.441198,
                          46.250282,
                          0
                      ],
                      [
                          -70.441237,
                          46.250354,
                          0
                      ],
                      [
                          -70.441262,
                          46.25039,
                          0
                      ],
                      [
                          -70.441291,
                          46.250416,
                          0
                      ],
                      [
                          -70.441392,
                          46.250507,
                          0
                      ],
                      [
                          -70.441495,
                          46.250588,
                          0
                      ],
                      [
                          -70.441548,
                          46.250624,
                          0
                      ],
                      [
                          -70.441599,
                          46.25066,
                          0
                      ],
                      [
                          -70.441715,
                          46.250705,
                          0
                      ],
                      [
                          -70.441768,
                          46.250714,
                          0
                      ],
                      [
                          -70.441833,
                          46.250724,
                          0
                      ],
                      [
                          -70.441877,
                          46.250723,
                          0
                      ],
                      [
                          -70.441924,
                          46.250723,
                          0
                      ],
                      [
                          -70.442027,
                          46.250723,
                          0
                      ],
                      [
                          -70.442157,
                          46.250723,
                          0
                      ],
                      [
                          -70.442299,
                          46.250724,
                          0
                      ],
                      [
                          -70.442364,
                          46.250724,
                          0
                      ],
                      [
                          -70.442482,
                          46.250768,
                          0
                      ],
                      [
                          -70.442587,
                          46.250813,
                          0
                      ],
                      [
                          -70.44261,
                          46.250822,
                          0
                      ],
                      [
                          -70.442612,
                          46.250822,
                          0
                      ],
                      [
                          -70.442689,
                          46.25084,
                          0
                      ],
                      [
                          -70.442739,
                          46.250855,
                          0
                      ],
                      [
                          -70.442752,
                          46.250858,
                          0
                      ],
                      [
                          -70.442883,
                          46.250922,
                          0
                      ],
                      [
                          -70.442948,
                          46.250958,
                          0
                      ],
                      [
                          -70.442999,
                          46.250985,
                          0
                      ],
                      [
                          -70.443129,
                          46.251057,
                          0
                      ],
                      [
                          -70.443272,
                          46.251129,
                          0
                      ],
                      [
                          -70.443414,
                          46.251183,
                          0
                      ],
                      [
                          -70.443596,
                          46.251228,
                          0
                      ],
                      [
                          -70.443738,
                          46.251264,
                          0
                      ],
                      [
                          -70.443817,
                          46.251273,
                          0
                      ],
                      [
                          -70.443878,
                          46.251268,
                          0
                      ],
                      [
                          -70.443932,
                          46.251264,
                          0
                      ],
                      [
                          -70.44405,
                          46.251255,
                          0
                      ],
                      [
                          -70.444127,
                          46.251246,
                          0
                      ],
                      [
                          -70.444193,
                          46.251237,
                          0
                      ],
                      [
                          -70.444258,
                          46.25122,
                          0
                      ],
                      [
                          -70.444322,
                          46.251193,
                          0
                      ],
                      [
                          -70.444426,
                          46.251147,
                          0
                      ],
                      [
                          -70.444504,
                          46.25113,
                          0
                      ],
                      [
                          -70.444634,
                          46.251102,
                          0
                      ],
                      [
                          -70.444763,
                          46.251075,
                          0
                      ],
                      [
                          -70.444893,
                          46.251057,
                          0
                      ],
                      [
                          -70.444962,
                          46.251049,
                          0
                      ],
                      [
                          -70.449117,
                          46.250618,
                          0
                      ],
                      [
                          -70.447784,
                          46.251488,
                          0
                      ],
                      [
                          -70.445855,
                          46.252748,
                          0
                      ],
                      [
                          -70.440917,
                          46.255973,
                          0
                      ],
                      [
                          -70.440903,
                          46.255981,
                          0
                      ],
                      [
                          -70.440923,
                          46.255996,
                          0
                      ],
                      [
                          -70.45423,
                          46.265654,
                          0
                      ],
                      [
                          -70.467386,
                          46.275258,
                          0
                      ],
                      [
                          -70.468747,
                          46.274355,
                          0
                      ],
                      [
                          -70.490302,
                          46.26027,
                          0
                      ],
                      [
                          -70.490677,
                          46.260026,
                          0
                      ],
                      [
                          -70.491412,
                          46.259544,
                          0
                      ],
                      [
                          -70.491412,
                          46.259546,
                          0
                      ],
                      [
                          -70.491447,
                          46.259573,
                          0
                      ],
                      [
                          -70.491485,
                          46.259604,
                          0
                      ],
                      [
                          -70.491503,
                          46.259618,
                          0
                      ],
                      [
                          -70.491504,
                          46.259618,
                          0
                      ],
                      [
                          -70.491608,
                          46.259663,
                          0
                      ],
                      [
                          -70.49175,
                          46.259708,
                          0
                      ],
                      [
                          -70.491815,
                          46.259717,
                          0
                      ],
                      [
                          -70.492009,
                          46.259762,
                          0
                      ],
                      [
                          -70.492097,
                          46.259782,
                          0
                      ],
                      [
                          -70.492204,
                          46.259807,
                          0
                      ],
                      [
                          -70.492373,
                          46.259843,
                          0
                      ],
                      [
                          -70.492541,
                          46.259879,
                          0
                      ],
                      [
                          -70.492683,
                          46.259906,
                          0
                      ],
                      [
                          -70.49284,
                          46.259933,
                          0
                      ],
                      [
                          -70.493112,
                          46.259969,
                          0
                      ],
                      [
                          -70.493174,
                          46.259977,
                          0
                      ],
                      [
                          -70.493384,
                          46.260005,
                          0
                      ],
                      [
                          -70.493566,
                          46.260013,
                          0
                      ],
                      [
                          -70.493761,
                          46.259996,
                          0
                      ],
                      [
                          -70.493942,
                          46.259942,
                          0
                      ],
                      [
                          -70.494098,
                          46.259861,
                          0
                      ],
                      [
                          -70.494167,
                          46.259818,
                          0
                      ],
                      [
                          -70.494228,
                          46.25978,
                          0
                      ],
                      [
                          -70.494345,
                          46.259699,
                          0
                      ],
                      [
                          -70.494418,
                          46.259633,
                          0
                      ],
                      [
                          -70.494434,
                          46.25962,
                          0
                      ],
                      [
                          -70.494435,
                          46.259619,
                          0
                      ],
                      [
                          -70.494458,
                          46.2596,
                          0
                      ],
                      [
                          -70.494539,
                          46.259537,
                          0
                      ],
                      [
                          -70.49463,
                          46.259456,
                          0
                      ],
                      [
                          -70.494734,
                          46.259384,
                          0
                      ],
                      [
                          -70.494903,
                          46.259267,
                          0
                      ],
                      [
                          -70.494981,
                          46.259217,
                          0
                      ],
                      [
                          -70.49507,
                          46.259159,
                          0
                      ],
                      [
                          -70.495279,
                          46.259006,
                          0
                      ],
                      [
                          -70.495486,
                          46.258853,
                          0
                      ],
                      [
                          -70.495693,
                          46.258745,
                          0
                      ],
                      [
                          -70.495915,
                          46.258647,
                          0
                      ],
                      [
                          -70.49603,
                          46.258601,
                          0
                      ],
                      [
                          -70.496089,
                          46.258584,
                          0
                      ],
                      [
                          -70.496147,
                          46.258565,
                          0
                      ],
                      [
                          -70.496342,
                          46.258547,
                          0
                      ],
                      [
                          -70.49655,
                          46.258538,
                          0
                      ],
                      [
                          -70.496706,
                          46.258538,
                          0
                      ],
                      [
                          -70.496874,
                          46.258539,
                          0
                      ],
                      [
                          -70.497043,
                          46.258521,
                          0
                      ],
                      [
                          -70.497072,
                          46.258514,
                          0
                      ],
                      [
                          -70.497211,
                          46.258485,
                          0
                      ],
                      [
                          -70.497277,
                          46.258466,
                          0
                      ],
                      [
                          -70.497341,
                          46.258439,
                          0
                      ],
                      [
                          -70.49747,
                          46.258376,
                          0
                      ],
                      [
                          -70.497587,
                          46.258304,
                          0
                      ],
                      [
                          -70.497718,
                          46.258206,
                          0
                      ],
                      [
                          -70.497846,
                          46.258097,
                          0
                      ],
                      [
                          -70.49795,
                          46.258017,
                          0
                      ],
                      [
                          -70.498041,
                          46.257936,
                          0
                      ],
                      [
                          -70.498137,
                          46.257851,
                          0
                      ],
                      [
                          -70.498235,
                          46.257765,
                          0
                      ],
                      [
                          -70.498444,
                          46.257593,
                          0
                      ],
                      [
                          -70.498781,
                          46.25745,
                          0
                      ],
                      [
                          -70.499131,
                          46.257306,
                          0
                      ],
                      [
                          -70.499139,
                          46.257303,
                          0
                      ],
                      [
                          -70.499208,
                          46.257279,
                          0
                      ],
                      [
                          -70.499352,
                          46.25727,
                          0
                      ],
                      [
                          -70.499482,
                          46.257269,
                          0
                      ],
                      [
                          -70.499702,
                          46.257261,
                          0
                      ],
                      [
                          -70.499909,
                          46.257261,
                          0
                      ],
                      [
                          -70.5,
                          46.25727,
                          0
                      ],
                      [
                          -70.500064,
                          46.257274,
                          0
                      ],
                      [
                          -70.500143,
                          46.257279,
                          0
                      ],
                      [
                          -70.500597,
                          46.25727,
                          0
                      ],
                      [
                          -70.501038,
                          46.257216,
                          0
                      ],
                      [
                          -70.501258,
                          46.25718,
                          0
                      ],
                      [
                          -70.501409,
                          46.257164,
                          0
                      ],
                      [
                          -70.501426,
                          46.257162,
                          0
                      ],
                      [
                          -70.501479,
                          46.257153,
                          0
                      ],
                      [
                          -70.501958,
                          46.25709,
                          0
                      ],
                      [
                          -70.502145,
                          46.25709,
                          0
                      ],
                      [
                          -70.502321,
                          46.25709,
                          0
                      ],
                      [
                          -70.502659,
                          46.25709,
                          0
                      ],
                      [
                          -70.502919,
                          46.257126,
                          0
                      ],
                      [
                          -70.503057,
                          46.257137,
                          0
                      ],
                      [
                          -70.503153,
                          46.257144,
                          0
                      ],
                      [
                          -70.503372,
                          46.257171,
                          0
                      ],
                      [
                          -70.503567,
                          46.25718,
                          0
                      ],
                      [
                          -70.503775,
                          46.257179,
                          0
                      ],
                      [
                          -70.504073,
                          46.257189,
                          0
                      ],
                      [
                          -70.504253,
                          46.257194,
                          0
                      ],
                      [
                          -70.504384,
                          46.257198,
                          0
                      ],
                      [
                          -70.504566,
                          46.257189,
                          0
                      ],
                      [
                          -70.504734,
                          46.257171,
                          0
                      ],
                      [
                          -70.504876,
                          46.257189,
                          0
                      ],
                      [
                          -70.504989,
                          46.257189,
                          0
                      ],
                      [
                          -70.504994,
                          46.257189,
                          0
                      ],
                      [
                          -70.50504,
                          46.257193,
                          0
                      ],
                      [
                          -70.505085,
                          46.257198,
                          0
                      ],
                      [
                          -70.505103,
                          46.2572,
                          0
                      ],
                      [
                          -70.505176,
                          46.257207,
                          0
                      ],
                      [
                          -70.505318,
                          46.257225,
                          0
                      ],
                      [
                          -70.505474,
                          46.257242,
                          0
                      ],
                      [
                          -70.505629,
                          46.257261,
                          0
                      ],
                      [
                          -70.505797,
                          46.25727,
                          0
                      ],
                      [
                          -70.505953,
                          46.25727,
                          0
                      ],
                      [
                          -70.50604,
                          46.25727,
                          0
                      ],
                      [
                          -70.506122,
                          46.257269,
                          0
                      ],
                      [
                          -70.506174,
                          46.25727,
                          0
                      ],
                      [
                          -70.506227,
                          46.25727,
                          0
                      ],
                      [
                          -70.506356,
                          46.257252,
                          0
                      ],
                      [
                          -70.506472,
                          46.257225,
                          0
                      ],
                      [
                          -70.506627,
                          46.257189,
                          0
                      ],
                      [
                          -70.506797,
                          46.257161,
                          0
                      ],
                      [
                          -70.506965,
                          46.257126,
                          0
                      ],
                      [
                          -70.507124,
                          46.257102,
                          0
                      ],
                      [
                          -70.507147,
                          46.257099,
                          0
                      ],
                      [
                          -70.507315,
                          46.257072,
                          0
                      ],
                      [
                          -70.507471,
                          46.257036,
                          0
                      ],
                      [
                          -70.507575,
                          46.257009,
                          0
                      ],
                      [
                          -70.507679,
                          46.256982,
                          0
                      ],
                      [
                          -70.507847,
                          46.256946,
                          0
                      ],
                      [
                          -70.508004,
                          46.256901,
                          0
                      ],
                      [
                          -70.508096,
                          46.256874,
                          0
                      ],
                      [
                          -70.508159,
                          46.256856,
                          0
                      ],
                      [
                          -70.508301,
                          46.256811,
                          0
                      ],
                      [
                          -70.508444,
                          46.256775,
                          0
                      ],
                      [
                          -70.5086,
                          46.25673,
                          0
                      ],
                      [
                          -70.508772,
                          46.256662,
                          0
                      ],
                      [
                          -70.508781,
                          46.256657,
                          0
                      ],
                      [
                          -70.508936,
                          46.256568,
                          0
                      ],
                      [
                          -70.508976,
                          46.256541,
                          0
                      ],
                      [
                          -70.509067,
                          46.256469,
                          0
                      ],
                      [
                          -70.509119,
                          46.256415,
                          0
                      ],
                      [
                          -70.50914,
                          46.256386,
                          0
                      ],
                      [
                          -70.509157,
                          46.256361,
                          0
                      ],
                      [
                          -70.50917,
                          46.256325,
                          0
                      ],
                      [
                          -70.509287,
                          46.256226,
                          0
                      ],
                      [
                          -70.509377,
                          46.256153,
                          0
                      ],
                      [
                          -70.509443,
                          46.256091,
                          0
                      ],
                      [
                          -70.509507,
                          46.256027,
                          0
                      ],
                      [
                          -70.509559,
                          46.255983,
                          0
                      ],
                      [
                          -70.509562,
                          46.255981,
                          0
                      ],
                      [
                          -70.509624,
                          46.255947,
                          0
                      ],
                      [
                          -70.509728,
                          46.255875,
                          0
                      ],
                      [
                          -70.509819,
                          46.255803,
                          0
                      ],
                      [
                          -70.509909,
                          46.255722,
                          0
                      ],
                      [
                          -70.510001,
                          46.255641,
                          0
                      ],
                      [
                          -70.510053,
                          46.2556,
                          0
                      ],
                      [
                          -70.510091,
                          46.25557,
                          0
                      ],
                      [
                          -70.510194,
                          46.255506,
                          0
                      ],
                      [
                          -70.510286,
                          46.255443,
                          0
                      ],
                      [
                          -70.510389,
                          46.25538,
                          0
                      ],
                      [
                          -70.510493,
                          46.255317,
                          0
                      ],
                      [
                          -70.51051,
                          46.255305,
                          0
                      ],
                      [
                          -70.510596,
                          46.255245,
                          0
                      ],
                      [
                          -70.510662,
                          46.255191,
                          0
                      ],
                      [
                          -70.510714,
                          46.255137,
                          0
                      ],
                      [
                          -70.510804,
                          46.255056,
                          0
                      ],
                      [
                          -70.510841,
                          46.255023,
                          0
                      ],
                      [
                          -70.510895,
                          46.254976,
                          0
                      ],
                      [
                          -70.510987,
                          46.254894,
                          0
                      ],
                      [
                          -70.511077,
                          46.254813,
                          0
                      ],
                      [
                          -70.511207,
                          46.254705,
                          0
                      ],
                      [
                          -70.511298,
                          46.25467,
                          0
                      ],
                      [
                          -70.511346,
                          46.254655,
                          0
                      ],
                      [
                          -70.511363,
                          46.254651,
                          0
                      ],
                      [
                          -70.511427,
                          46.254642,
                          0
                      ],
                      [
                          -70.511531,
                          46.254642,
                          0
                      ],
                      [
                          -70.511634,
                          46.254642,
                          0
                      ],
                      [
                          -70.511752,
                          46.25467,
                          0
                      ],
                      [
                          -70.511894,
                          46.254696,
                          0
                      ],
                      [
                          -70.512037,
                          46.254733,
                          0
                      ],
                      [
                          -70.512146,
                          46.254764,
                          0
                      ],
                      [
                          -70.512191,
                          46.254777,
                          0
                      ],
                      [
                          -70.512335,
                          46.254822,
                          0
                      ],
                      [
                          -70.512478,
                          46.254867,
                          0
                      ],
                      [
                          -70.512616,
                          46.254902,
                          0
                      ],
                      [
                          -70.51262,
                          46.254903,
                          0
                      ],
                      [
                          -70.512763,
                          46.25493,
                          0
                      ],
                      [
                          -70.512905,
                          46.254966,
                          0
                      ],
                      [
                          -70.513048,
                          46.255011,
                          0
                      ],
                      [
                          -70.513192,
                          46.255056,
                          0
                      ],
                      [
                          -70.513334,
                          46.2551,
                          0
                      ],
                      [
                          -70.513476,
                          46.255137,
                          0
                      ],
                      [
                          -70.513568,
                          46.255155,
                          0
                      ],
                      [
                          -70.513671,
                          46.255173,
                          0
                      ],
                      [
                          -70.513775,
                          46.2552,
                          0
                      ],
                      [
                          -70.513879,
                          46.255226,
                          0
                      ],
                      [
                          -70.513969,
                          46.255262,
                          0
                      ],
                      [
                          -70.513992,
                          46.255273,
                          0
                      ],
                      [
                          -70.514046,
                          46.255299,
                          0
                      ],
                      [
                          -70.514176,
                          46.255344,
                          0
                      ],
                      [
                          -70.514319,
                          46.255398,
                          0
                      ],
                      [
                          -70.514462,
                          46.255452,
                          0
                      ],
                      [
                          -70.514604,
                          46.255506,
                          0
                      ],
                      [
                          -70.514747,
                          46.25555,
                          0
                      ],
                      [
                          -70.514838,
                          46.255596,
                          0
                      ],
                      [
                          -70.514942,
                          46.255649,
                          0
                      ],
                      [
                          -70.514972,
                          46.255667,
                          0
                      ],
                      [
                          -70.515032,
                          46.255703,
                          0
                      ],
                      [
                          -70.515189,
                          46.255793,
                          0
                      ],
                      [
                          -70.515332,
                          46.255874,
                          0
                      ],
                      [
                          -70.515461,
                          46.255965,
                          0
                      ],
                      [
                          -70.515624,
                          46.256027,
                          0
                      ],
                      [
                          -70.515369,
                          46.256022,
                          0
                      ],
                      [
                          -70.515361,
                          46.256022,
                          0
                      ],
                      [
                          -70.515238,
                          46.25605,
                          0
                      ],
                      [
                          -70.515159,
                          46.256065,
                          0
                      ],
                      [
                          -70.515101,
                          46.256076,
                          0
                      ],
                      [
                          -70.515002,
                          46.256088,
                          0
                      ],
                      [
                          -70.514923,
                          46.256097,
                          0
                      ],
                      [
                          -70.514831,
                          46.256126,
                          0
                      ],
                      [
                          -70.514705,
                          46.256165,
                          0
                      ],
                      [
                          -70.514626,
                          46.256185,
                          0
                      ],
                      [
                          -70.514534,
                          46.256206,
                          0
                      ],
                      [
                          -70.514349,
                          46.256252,
                          0
                      ],
                      [
                          -70.514233,
                          46.256267,
                          0
                      ],
                      [
                          -70.514147,
                          46.256278,
                          0
                      ],
                      [
                          -70.513946,
                          46.256267,
                          0
                      ],
                      [
                          -70.51374,
                          46.256224,
                          0
                      ],
                      [
                          -70.513581,
                          46.256161,
                          0
                      ],
                      [
                          -70.513478,
                          46.256103,
                          0
                      ],
                      [
                          -70.513417,
                          46.256069,
                          0
                      ],
                      [
                          -70.513361,
                          46.256019,
                          0
                      ],
                      [
                          -70.513313,
                          46.255977,
                          0
                      ],
                      [
                          -70.513283,
                          46.255938,
                          0
                      ],
                      [
                          -70.513196,
                          46.255827,
                          0
                      ],
                      [
                          -70.513101,
                          46.255765,
                          0
                      ],
                      [
                          -70.513062,
                          46.255745,
                          0
                      ],
                      [
                          -70.51298,
                          46.255706,
                          0
                      ],
                      [
                          -70.512921,
                          46.255684,
                          0
                      ],
                      [
                          -70.512845,
                          46.255656,
                          0
                      ],
                      [
                          -70.512623,
                          46.255621,
                          0
                      ],
                      [
                          -70.512595,
                          46.255617,
                          0
                      ],
                      [
                          -70.512533,
                          46.255613,
                          0
                      ],
                      [
                          -70.512439,
                          46.255607,
                          0
                      ],
                      [
                          -70.512405,
                          46.255616,
                          0
                      ],
                      [
                          -70.512308,
                          46.255639,
                          0
                      ],
                      [
                          -70.512239,
                          46.255671,
                          0
                      ],
                      [
                          -70.512165,
                          46.255706,
                          0
                      ],
                      [
                          -70.51211,
                          46.255796,
                          0
                      ],
                      [
                          -70.512069,
                          46.25587,
                          0
                      ],
                      [
                          -70.512042,
                          46.255918,
                          0
                      ],
                      [
                          -70.512027,
                          46.255958,
                          0
                      ],
                      [
                          -70.512009,
                          46.256005,
                          0
                      ],
                      [
                          -70.512034,
                          46.256066,
                          0
                      ],
                      [
                          -70.512042,
                          46.256085,
                          0
                      ],
                      [
                          -70.512087,
                          46.256138,
                          0
                      ],
                      [
                          -70.512086,
                          46.256141,
                          0
                      ],
                      [
                          -70.512028,
                          46.256303,
                          0
                      ],
                      [
                          -70.511932,
                          46.256398,
                          0
                      ],
                      [
                          -70.511767,
                          46.256463,
                          0
                      ],
                      [
                          -70.511492,
                          46.256538,
                          0
                      ],
                      [
                          -70.511205,
                          46.256636,
                          0
                      ],
                      [
                          -70.510932,
                          46.256758,
                          0
                      ],
                      [
                          -70.510837,
                          46.256891,
                          0
                      ],
                      [
                          -70.510888,
                          46.257053,
                          0
                      ],
                      [
                          -70.51104,
                          46.257212,
                          0
                      ],
                      [
                          -70.511268,
                          46.25737,
                          0
                      ],
                      [
                          -70.51153,
                          46.25748,
                          0
                      ],
                      [
                          -70.511853,
                          46.25763,
                          0
                      ],
                      [
                          -70.512016,
                          46.257765,
                          0
                      ],
                      [
                          -70.512254,
                          46.257892,
                          0
                      ],
                      [
                          -70.512435,
                          46.25799,
                          0
                      ],
                      [
                          -70.512533,
                          46.258044,
                          0
                      ],
                      [
                          -70.512643,
                          46.258081,
                          0
                      ],
                      [
                          -70.512724,
                          46.258108,
                          0
                      ],
                      [
                          -70.512846,
                          46.258173,
                          0
                      ],
                      [
                          -70.51293,
                          46.258216,
                          0
                      ],
                      [
                          -70.512998,
                          46.258246,
                          0
                      ],
                      [
                          -70.513129,
                          46.258304,
                          0
                      ],
                      [
                          -70.513225,
                          46.25835,
                          0
                      ],
                      [
                          -70.513319,
                          46.258394,
                          0
                      ],
                      [
                          -70.513428,
                          46.258437,
                          0
                      ],
                      [
                          -70.513651,
                          46.258527,
                          0
                      ],
                      [
                          -70.513761,
                          46.258577,
                          0
                      ],
                      [
                          -70.51393,
                          46.258653,
                          0
                      ],
                      [
                          -70.514269,
                          46.258824,
                          0
                      ],
                      [
                          -70.514394,
                          46.25891,
                          0
                      ],
                      [
                          -70.514466,
                          46.258959,
                          0
                      ],
                      [
                          -70.514485,
                          46.258997,
                          0
                      ],
                      [
                          -70.514557,
                          46.259144,
                          0
                      ],
                      [
                          -70.514664,
                          46.259305,
                          0
                      ],
                      [
                          -70.514804,
                          46.25947,
                          0
                      ],
                      [
                          -70.51503,
                          46.259633,
                          0
                      ],
                      [
                          -70.515033,
                          46.259635,
                          0
                      ],
                      [
                          -70.515041,
                          46.259642,
                          0
                      ],
                      [
                          -70.515276,
                          46.25983,
                          0
                      ],
                      [
                          -70.51538,
                          46.259908,
                          0
                      ],
                      [
                          -70.515529,
                          46.260017,
                          0
                      ],
                      [
                          -70.515699,
                          46.260153,
                          0
                      ],
                      [
                          -70.515884,
                          46.260294,
                          0
                      ],
                      [
                          -70.516116,
                          46.260518,
                          0
                      ],
                      [
                          -70.516221,
                          46.260643,
                          0
                      ],
                      [
                          -70.516372,
                          46.260765,
                          0
                      ],
                      [
                          -70.516467,
                          46.260841,
                          0
                      ],
                      [
                          -70.516543,
                          46.260915,
                          0
                      ],
                      [
                          -70.51667,
                          46.261038,
                          0
                      ],
                      [
                          -70.516934,
                          46.261333,
                          0
                      ],
                      [
                          -70.516981,
                          46.261467,
                          0
                      ],
                      [
                          -70.517007,
                          46.261542,
                          0
                      ],
                      [
                          -70.517028,
                          46.261643,
                          0
                      ],
                      [
                          -70.517039,
                          46.261707,
                          0
                      ],
                      [
                          -70.516989,
                          46.261913,
                          0
                      ],
                      [
                          -70.517034,
                          46.262107,
                          0
                      ],
                      [
                          -70.51708,
                          46.262289,
                          0
                      ],
                      [
                          -70.517104,
                          46.262385,
                          0
                      ],
                      [
                          -70.517243,
                          46.262812,
                          0
                      ],
                      [
                          -70.517259,
                          46.26287,
                          0
                      ],
                      [
                          -70.517355,
                          46.263203,
                          0
                      ],
                      [
                          -70.517354,
                          46.263474,
                          0
                      ],
                      [
                          -70.517326,
                          46.263546,
                          0
                      ],
                      [
                          -70.517298,
                          46.263614,
                          0
                      ],
                      [
                          -70.517297,
                          46.263616,
                          0
                      ],
                      [
                          -70.517208,
                          46.263706,
                          0
                      ],
                      [
                          -70.517027,
                          46.263808,
                          0
                      ],
                      [
                          -70.516978,
                          46.263815,
                          0
                      ],
                      [
                          -70.516911,
                          46.263824,
                          0
                      ],
                      [
                          -70.516737,
                          46.263824,
                          0
                      ],
                      [
                          -70.516625,
                          46.263803,
                          0
                      ],
                      [
                          -70.516541,
                          46.263745,
                          0
                      ],
                      [
                          -70.51654,
                          46.263742,
                          0
                      ],
                      [
                          -70.516465,
                          46.263643,
                          0
                      ],
                      [
                          -70.51641,
                          46.26354,
                          0
                      ],
                      [
                          -70.516407,
                          46.263534,
                          0
                      ],
                      [
                          -70.516316,
                          46.263437,
                          0
                      ],
                      [
                          -70.516167,
                          46.263328,
                          0
                      ],
                      [
                          -70.516056,
                          46.263261,
                          0
                      ],
                      [
                          -70.515936,
                          46.263244,
                          0
                      ],
                      [
                          -70.515902,
                          46.263239,
                          0
                      ],
                      [
                          -70.515777,
                          46.26322,
                          0
                      ],
                      [
                          -70.515733,
                          46.263214,
                          0
                      ],
                      [
                          -70.51573,
                          46.263213,
                          0
                      ],
                      [
                          -70.515626,
                          46.263183,
                          0
                      ],
                      [
                          -70.515474,
                          46.263126,
                          0
                      ],
                      [
                          -70.515221,
                          46.263,
                          0
                      ],
                      [
                          -70.515008,
                          46.262915,
                          0
                      ],
                      [
                          -70.514806,
                          46.262864,
                          0
                      ],
                      [
                          -70.514582,
                          46.262841,
                          0
                      ],
                      [
                          -70.514392,
                          46.262782,
                          0
                      ],
                      [
                          -70.514316,
                          46.262753,
                          0
                      ],
                      [
                          -70.514195,
                          46.262706,
                          0
                      ],
                      [
                          -70.514013,
                          46.262634,
                          0
                      ],
                      [
                          -70.513857,
                          46.26257,
                          0
                      ],
                      [
                          -70.513714,
                          46.262547,
                          0
                      ],
                      [
                          -70.513676,
                          46.262541,
                          0
                      ],
                      [
                          -70.513482,
                          46.262615,
                          0
                      ],
                      [
                          -70.513385,
                          46.262682,
                          0
                      ],
                      [
                          -70.513279,
                          46.262755,
                          0
                      ],
                      [
                          -70.513125,
                          46.262924,
                          0
                      ],
                      [
                          -70.513109,
                          46.263039,
                          0
                      ],
                      [
                          -70.513106,
                          46.263056,
                          0
                      ],
                      [
                          -70.513106,
                          46.263059,
                          0
                      ],
                      [
                          -70.513184,
                          46.263235,
                          0
                      ],
                      [
                          -70.513235,
                          46.26329,
                          0
                      ],
                      [
                          -70.513269,
                          46.263327,
                          0
                      ],
                      [
                          -70.513271,
                          46.263327,
                          0
                      ],
                      [
                          -70.513419,
                          46.263413,
                          0
                      ],
                      [
                          -70.513591,
                          46.263521,
                          0
                      ],
                      [
                          -70.513599,
                          46.263533,
                          0
                      ],
                      [
                          -70.513676,
                          46.263642,
                          0
                      ],
                      [
                          -70.513696,
                          46.263722,
                          0
                      ],
                      [
                          -70.513708,
                          46.263764,
                          0
                      ],
                      [
                          -70.513667,
                          46.263913,
                          0
                      ],
                      [
                          -70.513547,
                          46.264048,
                          0
                      ],
                      [
                          -70.513487,
                          46.26407,
                          0
                      ],
                      [
                          -70.513353,
                          46.264119,
                          0
                      ],
                      [
                          -70.512965,
                          46.264099,
                          0
                      ],
                      [
                          -70.512775,
                          46.264089,
                          0
                      ],
                      [
                          -70.512504,
                          46.264008,
                          0
                      ],
                      [
                          -70.512371,
                          46.264043,
                          0
                      ],
                      [
                          -70.512316,
                          46.264057,
                          0
                      ],
                      [
                          -70.512193,
                          46.264092,
                          0
                      ],
                      [
                          -70.511871,
                          46.264181,
                          0
                      ],
                      [
                          -70.511569,
                          46.264339,
                          0
                      ],
                      [
                          -70.511366,
                          46.264587,
                          0
                      ],
                      [
                          -70.511328,
                          46.264658,
                          0
                      ],
                      [
                          -70.511218,
                          46.264866,
                          0
                      ],
                      [
                          -70.511108,
                          46.26517,
                          0
                      ],
                      [
                          -70.511094,
                          46.265333,
                          0
                      ],
                      [
                          -70.511162,
                          46.265486,
                          0
                      ],
                      [
                          -70.511252,
                          46.26572,
                          0
                      ],
                      [
                          -70.51126,
                          46.265733,
                          0
                      ],
                      [
                          -70.511382,
                          46.265941,
                          0
                      ],
                      [
                          -70.511415,
                          46.265976,
                          0
                      ],
                      [
                          -70.511514,
                          46.266081,
                          0
                      ],
                      [
                          -70.511641,
                          46.266181,
                          0
                      ],
                      [
                          -70.511781,
                          46.266292,
                          0
                      ],
                      [
                          -70.51187,
                          46.26641,
                          0
                      ],
                      [
                          -70.511919,
                          46.266469,
                          0
                      ],
                      [
                          -70.511961,
                          46.266519,
                          0
                      ],
                      [
                          -70.512027,
                          46.2666,
                          0
                      ],
                      [
                          -70.512052,
                          46.266627,
                          0
                      ],
                      [
                          -70.512116,
                          46.266702,
                          0
                      ],
                      [
                          -70.512234,
                          46.266842,
                          0
                      ],
                      [
                          -70.512364,
                          46.266996,
                          0
                      ],
                      [
                          -70.512383,
                          46.267025,
                          0
                      ],
                      [
                          -70.51248,
                          46.267167,
                          0
                      ],
                      [
                          -70.512546,
                          46.267247,
                          0
                      ],
                      [
                          -70.51261,
                          46.267346,
                          0
                      ],
                      [
                          -70.512649,
                          46.267419,
                          0
                      ],
                      [
                          -70.512714,
                          46.267535,
                          0
                      ],
                      [
                          -70.512817,
                          46.267724,
                          0
                      ],
                      [
                          -70.512909,
                          46.267904,
                          0
                      ],
                      [
                          -70.512974,
                          46.268093,
                          0
                      ],
                      [
                          -70.513025,
                          46.268273,
                          0
                      ],
                      [
                          -70.513038,
                          46.268381,
                          0
                      ],
                      [
                          -70.513052,
                          46.268462,
                          0
                      ],
                      [
                          -70.513065,
                          46.268534,
                          0
                      ],
                      [
                          -70.513064,
                          46.268606,
                          0
                      ],
                      [
                          -70.513051,
                          46.268768,
                          0
                      ],
                      [
                          -70.513021,
                          46.268856,
                          0
                      ],
                      [
                          -70.513,
                          46.268921,
                          0
                      ],
                      [
                          -70.512947,
                          46.26902,
                          0
                      ],
                      [
                          -70.512883,
                          46.269119,
                          0
                      ],
                      [
                          -70.512806,
                          46.269236,
                          0
                      ],
                      [
                          -70.512728,
                          46.269343,
                          0
                      ],
                      [
                          -70.512691,
                          46.269405,
                          0
                      ],
                      [
                          -70.512623,
                          46.269524,
                          0
                      ],
                      [
                          -70.512507,
                          46.269694,
                          0
                      ],
                      [
                          -70.51239,
                          46.269857,
                          0
                      ],
                      [
                          -70.512248,
                          46.270009,
                          0
                      ],
                      [
                          -70.512092,
                          46.270162,
                          0
                      ],
                      [
                          -70.512058,
                          46.270194,
                          0
                      ],
                      [
                          -70.511935,
                          46.270307,
                          0
                      ],
                      [
                          -70.511755,
                          46.27046,
                          0
                      ],
                      [
                          -70.51156,
                          46.270603,
                          0
                      ],
                      [
                          -70.511418,
                          46.270738,
                          0
                      ],
                      [
                          -70.511287,
                          46.270828,
                          0
                      ],
                      [
                          -70.511209,
                          46.270892,
                          0
                      ],
                      [
                          -70.511158,
                          46.2709,
                          0
                      ],
                      [
                          -70.511053,
                          46.270945,
                          0
                      ],
                      [
                          -70.510962,
                          46.270963,
                          0
                      ],
                      [
                          -70.510833,
                          46.270999,
                          0
                      ],
                      [
                          -70.510756,
                          46.271026,
                          0
                      ],
                      [
                          -70.510652,
                          46.271071,
                          0
                      ],
                      [
                          -70.510535,
                          46.271125,
                          0
                      ],
                      [
                          -70.510484,
                          46.271143,
                          0
                      ],
                      [
                          -70.510432,
                          46.271179,
                          0
                      ],
                      [
                          -70.510353,
                          46.271224,
                          0
                      ],
                      [
                          -70.510302,
                          46.271315,
                          0
                      ],
                      [
                          -70.510145,
                          46.271458,
                          0
                      ],
                      [
                          -70.510056,
                          46.271585,
                          0
                      ],
                      [
                          -70.510055,
                          46.271651,
                          0
                      ],
                      [
                          -70.510055,
                          46.271719,
                          0
                      ],
                      [
                          -70.510043,
                          46.271845,
                          0
                      ],
                      [
                          -70.510003,
                          46.272016,
                          0
                      ],
                      [
                          -70.510016,
                          46.272124,
                          0
                      ],
                      [
                          -70.510003,
                          46.272232,
                          0
                      ],
                      [
                          -70.50999,
                          46.272349,
                          0
                      ],
                      [
                          -70.509848,
                          46.272466,
                          0
                      ],
                      [
                          -70.509705,
                          46.272582,
                          0
                      ],
                      [
                          -70.509576,
                          46.272601,
                          0
                      ],
                      [
                          -70.509485,
                          46.272637,
                          0
                      ],
                      [
                          -70.509457,
                          46.272645,
                          0
                      ],
                      [
                          -70.509315,
                          46.272682,
                          0
                      ],
                      [
                          -70.509147,
                          46.272718,
                          0
                      ],
                      [
                          -70.508965,
                          46.272736,
                          0
                      ],
                      [
                          -70.508891,
                          46.272741,
                          0
                      ],
                      [
                          -70.508836,
                          46.272745,
                          0
                      ],
                      [
                          -70.508798,
                          46.272745,
                          0
                      ],
                      [
                          -70.508732,
                          46.272745,
                          0
                      ],
                      [
                          -70.508667,
                          46.272745,
                          0
                      ],
                      [
                          -70.508499,
                          46.272736,
                          0
                      ],
                      [
                          -70.50825,
                          46.27272,
                          0
                      ],
                      [
                          -70.508225,
                          46.272718,
                          0
                      ],
                      [
                          -70.508174,
                          46.272718,
                          0
                      ],
                      [
                          -70.50785,
                          46.2727,
                          0
                      ],
                      [
                          -70.507655,
                          46.272691,
                          0
                      ],
                      [
                          -70.507474,
                          46.272681,
                          0
                      ],
                      [
                          -70.507304,
                          46.272673,
                          0
                      ],
                      [
                          -70.507227,
                          46.272669,
                          0
                      ],
                      [
                          -70.507136,
                          46.272664,
                          0
                      ],
                      [
                          -70.506955,
                          46.272655,
                          0
                      ],
                      [
                          -70.506786,
                          46.272637,
                          0
                      ],
                      [
                          -70.506488,
                          46.27261,
                          0
                      ],
                      [
                          -70.506352,
                          46.272598,
                          0
                      ],
                      [
                          -70.506202,
                          46.272583,
                          0
                      ],
                      [
                          -70.50602,
                          46.272575,
                          0
                      ],
                      [
                          -70.505825,
                          46.272565,
                          0
                      ],
                      [
                          -70.505709,
                          46.272574,
                          0
                      ],
                      [
                          -70.505592,
                          46.272593,
                          0
                      ],
                      [
                          -70.505515,
                          46.27261,
                          0
                      ],
                      [
                          -70.50547,
                          46.27262,
                          0
                      ],
                      [
                          -70.50561,
                          46.272716,
                          0
                      ],
                      [
                          -70.508535,
                          46.274724,
                          0
                      ],
                      [
                          -70.508681,
                          46.274824,
                          0
                      ],
                      [
                          -70.510615,
                          46.27615,
                          0
                      ],
                      [
                          -70.510921,
                          46.27636,
                          0
                      ],
                      [
                          -70.516476,
                          46.280171,
                          0
                      ],
                      [
                          -70.515773,
                          46.280652,
                          0
                      ],
                      [
                          -70.513823,
                          46.281986,
                          0
                      ],
                      [
                          -70.513883,
                          46.28203,
                          0
                      ],
                      [
                          -70.54048,
                          46.301193,
                          0
                      ],
                      [
                          -70.54036,
                          46.301274,
                          0
                      ],
                      [
                          -70.512173,
                          46.320365,
                          0
                      ],
                      [
                          -70.511964,
                          46.320507,
                          0
                      ],
                      [
                          -70.534749,
                          46.336873,
                          0
                      ],
                      [
                          -70.53481,
                          46.336916,
                          0
                      ],
                      [
                          -70.537453,
                          46.335185,
                          0
                      ],
                      [
                          -70.537494,
                          46.335158,
                          0
                      ],
                      [
                          -70.537691,
                          46.335292,
                          0
                      ],
                      [
                          -70.557888,
                          46.349939,
                          0
                      ],
                      [
                          -70.557914,
                          46.349967,
                          0
                      ],
                      [
                          -70.642529,
                          46.409098,
                          0
                      ],
                      [
                          -70.642529,
                          46.409099,
                          0
                      ],
                      [
                          -70.641386,
                          46.409818,
                          0
                      ],
                      [
                          -70.641386,
                          46.409819,
                          0
                      ],
                      [
                          -70.662537,
                          46.424569,
                          0
                      ],
                      [
                          -70.688794,
                          46.407331,
                          0
                      ],
                      [
                          -70.723532,
                          46.43471,
                          0
                      ],
                      [
                          -70.723506,
                          46.434728,
                          0
                      ],
                      [
                          -70.707823,
                          46.445444,
                          0
                      ],
                      [
                          -70.709819,
                          46.446957,
                          0
                      ],
                      [
                          -70.695919,
                          46.456421,
                          0
                      ],
                      [
                          -70.743414,
                          46.493655,
                          0
                      ],
                      [
                          -70.75446,
                          46.48594,
                          0
                      ],
                      [
                          -70.765057,
                          46.494095,
                          0
                      ],
                      [
                          -70.765084,
                          46.494076,
                          0
                      ],
                      [
                          -70.768264,
                          46.491891,
                          0
                      ],
                      [
                          -70.814108,
                          46.52837,
                          0
                      ],
                      [
                          -70.814188,
                          46.528433,
                          0
                      ],
                      [
                          -70.836219,
                          46.513686,
                          0
                      ],
                      [
                          -70.86335,
                          46.539724,
                          0
                      ],
                      [
                          -70.874974,
                          46.534877,
                          0
                      ],
                      [
                          -70.905212,
                          46.562924,
                          0
                      ],
                      [
                          -70.909188,
                          46.561364,
                          0
                      ],
                      [
                          -70.910521,
                          46.562667,
                          0
                      ],
                      [
                          -70.911376,
                          46.564274,
                          0
                      ],
                      [
                          -70.92324,
                          46.57779,
                          0
                      ],
                      [
                          -70.927304,
                          46.577583,
                          0
                      ],
                      [
                          -70.934269,
                          46.577629,
                          0
                      ],
                      [
                          -70.937419,
                          46.577382,
                          0
                      ],
                      [
                          -70.937422,
                          46.577382,
                          0
                      ],
                      [
                          -70.939135,
                          46.578385,
                          0
                      ],
                      [
                          -70.942025,
                          46.579994,
                          0
                      ],
                      [
                          -70.943999,
                          46.580332,
                          0
                      ],
                      [
                          -70.945344,
                          46.580976,
                          0
                      ],
                      [
                          -70.949154,
                          46.582299,
                          0
                      ],
                      [
                          -70.950657,
                          46.583616,
                          0
                      ],
                      [
                          -70.950278,
                          46.585395,
                          0
                      ],
                      [
                          -70.951285,
                          46.586782,
                          0
                      ],
                      [
                          -70.951293,
                          46.586786,
                          0
                      ],
                      [
                          -70.956885,
                          46.590499,
                          0
                      ],
                      [
                          -70.960899,
                          46.593768,
                          0
                      ],
                      [
                          -70.981903,
                          46.585355,
                          0
                      ],
                      [
                          -70.984191,
                          46.587466,
                          0
                      ],
                      [
                          -70.989177,
                          46.585719,
                          0
                      ],
                      [
                          -70.991018,
                          46.585346,
                          0
                      ],
                      [
                          -70.993343,
                          46.585709,
                          0
                      ],
                      [
                          -70.996051,
                          46.585105,
                          0
                      ],
                      [
                          -70.995939,
                          46.585934,
                          0
                      ],
                      [
                          -71.019185,
                          46.586824,
                          0
                      ],
                      [
                          -71.01889,
                          46.589211,
                          0
                      ],
                      [
                          -71.04216,
                          46.590324,
                          0
                      ],
                      [
                          -71.040597,
                          46.602955,
                          0
                      ],
                      [
                          -71.039237,
                          46.604738,
                          0
                      ],
                      [
                          -71.03901,
                          46.60617,
                          0
                      ],
                      [
                          -71.039323,
                          46.608493,
                          0
                      ],
                      [
                          -71.060783,
                          46.61274,
                          0
                      ],
                      [
                          -71.059935,
                          46.614754,
                          0
                      ],
                      [
                          -71.054436,
                          46.613659,
                          0
                      ],
                      [
                          -71.053298,
                          46.616364,
                          0
                      ],
                      [
                          -71.052201,
                          46.616184,
                          0
                      ],
                      [
                          -71.04716,
                          46.620816,
                          0
                      ],
                      [
                          -71.047182,
                          46.62082,
                          0
                      ],
                      [
                          -71.050533,
                          46.621376,
                          0
                      ],
                      [
                          -71.053082,
                          46.623868,
                          0
                      ],
                      [
                          -71.058475,
                          46.623288,
                          0
                      ],
                      [
                          -71.066753,
                          46.624984,
                          0
                      ],
                      [
                          -71.070673,
                          46.626107,
                          0
                      ],
                      [
                          -71.074077,
                          46.628825,
                          0
                      ],
                      [
                          -71.076329,
                          46.628441,
                          0
                      ],
                      [
                          -71.078178,
                          46.629711,
                          0
                      ],
                      [
                          -71.080239,
                          46.63027,
                          0
                      ],
                      [
                          -71.082715,
                          46.631955,
                          0
                      ],
                      [
                          -71.083526,
                          46.634965,
                          0
                      ],
                      [
                          -71.084054,
                          46.636172,
                          0
                      ],
                      [
                          -71.088137,
                          46.639056,
                          0
                      ],
                      [
                          -71.087667,
                          46.640766,
                          0
                      ],
                      [
                          -71.084399,
                          46.642916,
                          0
                      ],
                      [
                          -71.092954,
                          46.648473,
                          0
                      ],
                      [
                          -71.119025,
                          46.630387,
                          0
                      ],
                      [
                          -71.15157,
                          46.65289,
                          0
                      ],
                      [
                          -71.12791,
                          46.669215,
                          0
                      ],
                      [
                          -71.127758,
                          46.681996,
                          0
                      ],
                      [
                          -71.136725,
                          46.681797,
                          0
                      ],
                      [
                          -71.134211,
                          46.688026,
                          0
                      ],
                      [
                          -71.135621,
                          46.693407,
                          0
                      ],
                      [
                          -71.138227,
                          46.694437,
                          0
                      ],
                      [
                          -71.1377,
                          46.695745,
                          0
                      ],
                      [
                          -71.132987,
                          46.695906,
                          0
                      ],
                      [
                          -71.132787,
                          46.70788,
                          0
                      ],
                      [
                          -71.13945,
                          46.703243,
                          0
                      ],
                      [
                          -71.14081,
                          46.707932,
                          0
                      ],
                      [
                          -71.12429,
                          46.719454,
                          0
                      ],
                      [
                          -71.1272,
                          46.721704,
                          0
                      ],
                      [
                          -71.130739,
                          46.722885,
                          0
                      ],
                      [
                          -71.135308,
                          46.727011,
                          0
                      ],
                      [
                          -71.13747,
                          46.731852,
                          0
                      ],
                      [
                          -71.141745,
                          46.734474,
                          0
                      ],
                      [
                          -71.143486,
                          46.736372,
                          0
                      ],
                      [
                          -71.149847,
                          46.738742,
                          0
                      ],
                      [
                          -71.152134,
                          46.742375,
                          0
                      ],
                      [
                          -71.154258,
                          46.742357,
                          0
                      ],
                      [
                          -71.156296,
                          46.740528,
                          0
                      ],
                      [
                          -71.160449,
                          46.743385,
                          0
                      ],
                      [
                          -71.168724,
                          46.740042,
                          0
                      ],
                      [
                          -71.178145,
                          46.742921,
                          0
                      ],
                      [
                          -71.183827,
                          46.742748,
                          0
                      ],
                      [
                          -71.190111,
                          46.744275,
                          0
                      ],
                      [
                          -71.181076,
                          46.753301,
                          0
                      ],
                      [
                          -71.18438,
                          46.755575,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Bellechasse",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -72.083729,
                          46.570207,
                          0
                      ],
                      [
                          -72.083727,
                          46.570207,
                          0
                      ],
                      [
                          -72.05432,
                          46.570509,
                          0
                      ],
                      [
                          -72.054318,
                          46.570508,
                          0
                      ],
                      [
                          -72.035656,
                          46.557905,
                          0
                      ],
                      [
                          -72.037665,
                          46.557239,
                          0
                      ],
                      [
                          -72.035468,
                          46.556949,
                          0
                      ],
                      [
                          -72.033079,
                          46.555369,
                          0
                      ],
                      [
                          -72.030717,
                          46.552682,
                          0
                      ],
                      [
                          -72.029782,
                          46.553214,
                          0
                      ],
                      [
                          -72.028408,
                          46.55297,
                          0
                      ],
                      [
                          -72.013345,
                          46.542691,
                          0
                      ],
                      [
                          -72.01181,
                          46.543665,
                          0
                      ],
                      [
                          -71.881198,
                          46.450781,
                          0
                      ],
                      [
                          -71.881162,
                          46.450756,
                          0
                      ],
                      [
                          -71.974047,
                          46.386333,
                          0
                      ],
                      [
                          -71.89169,
                          46.32663,
                          0
                      ],
                      [
                          -71.891696,
                          46.326626,
                          0
                      ],
                      [
                          -71.944958,
                          46.292372,
                          0
                      ],
                      [
                          -71.945127,
                          46.292262,
                          0
                      ],
                      [
                          -71.945197,
                          46.292216,
                          0
                      ],
                      [
                          -71.945198,
                          46.292215,
                          0
                      ],
                      [
                          -71.959983,
                          46.304142,
                          0
                      ],
                      [
                          -71.960149,
                          46.30404,
                          0
                      ],
                      [
                          -71.974959,
                          46.294669,
                          0
                      ],
                      [
                          -72.005385,
                          46.318985,
                          0
                      ],
                      [
                          -72.020759,
                          46.308815,
                          0
                      ],
                      [
                          -72.015813,
                          46.305088,
                          0
                      ],
                      [
                          -72.064887,
                          46.274377,
                          0
                      ],
                      [
                          -72.064885,
                          46.274376,
                          0
                      ],
                      [
                          -72.055931,
                          46.26756,
                          0
                      ],
                      [
                          -72.055928,
                          46.267558,
                          0
                      ],
                      [
                          -72.082121,
                          46.250302,
                          0
                      ],
                      [
                          -72.092208,
                          46.257872,
                          0
                      ],
                      [
                          -72.092251,
                          46.257904,
                          0
                      ],
                      [
                          -72.092254,
                          46.257906,
                          0
                      ],
                      [
                          -72.117428,
                          46.241533,
                          0
                      ],
                      [
                          -72.084247,
                          46.216959,
                          0
                      ],
                      [
                          -72.081818,
                          46.218545,
                          0
                      ],
                      [
                          -72.082073,
                          46.167765,
                          0
                      ],
                      [
                          -72.102545,
                          46.163905,
                          0
                      ],
                      [
                          -72.102544,
                          46.1639,
                          0
                      ],
                      [
                          -72.102048,
                          46.13477,
                          0
                      ],
                      [
                          -72.149503,
                          46.12522,
                          0
                      ],
                      [
                          -72.149504,
                          46.12522,
                          0
                      ],
                      [
                          -72.149505,
                          46.125206,
                          0
                      ],
                      [
                          -72.1495,
                          46.110603,
                          0
                      ],
                      [
                          -72.17255,
                          46.105878,
                          0
                      ],
                      [
                          -72.172585,
                          46.09811,
                          0
                      ],
                      [
                          -72.178341,
                          46.097132,
                          0
                      ],
                      [
                          -72.178283,
                          46.100801,
                          0
                      ],
                      [
                          -72.178284,
                          46.100805,
                          0
                      ],
                      [
                          -72.19207,
                          46.091479,
                          0
                      ],
                      [
                          -72.192069,
                          46.091568,
                          0
                      ],
                      [
                          -72.191944,
                          46.102308,
                          0
                      ],
                      [
                          -72.204032,
                          46.099837,
                          0
                      ],
                      [
                          -72.20407,
                          46.099828,
                          0
                      ],
                      [
                          -72.214733,
                          46.097649,
                          0
                      ],
                      [
                          -72.214786,
                          46.094663,
                          0
                      ],
                      [
                          -72.214786,
                          46.094662,
                          0
                      ],
                      [
                          -72.214868,
                          46.090009,
                          0
                      ],
                      [
                          -72.214872,
                          46.089766,
                          0
                      ],
                      [
                          -72.214949,
                          46.082879,
                          0
                      ],
                      [
                          -72.21589,
                          46.082645,
                          0
                      ],
                      [
                          -72.21586,
                          46.081518,
                          0
                      ],
                      [
                          -72.215852,
                          46.081258,
                          0
                      ],
                      [
                          -72.215864,
                          46.081265,
                          0
                      ],
                      [
                          -72.224929,
                          46.087124,
                          0
                      ],
                      [
                          -72.225169,
                          46.087282,
                          0
                      ],
                      [
                          -72.229734,
                          46.083814,
                          0
                      ],
                      [
                          -72.230134,
                          46.083754,
                          0
                      ],
                      [
                          -72.23459,
                          46.080512,
                          0
                      ],
                      [
                          -72.239119,
                          46.077189,
                          0
                      ],
                      [
                          -72.23914,
                          46.077168,
                          0
                      ],
                      [
                          -72.239455,
                          46.076859,
                          0
                      ],
                      [
                          -72.243725,
                          46.07382,
                          0
                      ],
                      [
                          -72.254953,
                          46.065563,
                          0
                      ],
                      [
                          -72.25542,
                          46.065302,
                          0
                      ],
                      [
                          -72.257993,
                          46.06344,
                          0
                      ],
                      [
                          -72.258634,
                          46.063031,
                          0
                      ],
                      [
                          -72.267197,
                          46.058518,
                          0
                      ],
                      [
                          -72.286223,
                          46.048388,
                          0
                      ],
                      [
                          -72.289181,
                          46.046796,
                          0
                      ],
                      [
                          -72.290504,
                          46.046282,
                          0
                      ],
                      [
                          -72.291539,
                          46.045374,
                          0
                      ],
                      [
                          -72.301438,
                          46.036623,
                          0
                      ],
                      [
                          -72.307265,
                          46.03147,
                          0
                      ],
                      [
                          -72.327958,
                          46.043467,
                          0
                      ],
                      [
                          -72.349497,
                          46.055973,
                          0
                      ],
                      [
                          -72.349469,
                          46.056217,
                          0
                      ],
                      [
                          -72.350015,
                          46.056296,
                          0
                      ],
                      [
                          -72.354448,
                          46.058799,
                          0
                      ],
                      [
                          -72.367854,
                          46.047427,
                          0
                      ],
                      [
                          -72.367881,
                          46.047442,
                          0
                      ],
                      [
                          -72.368504,
                          46.047809,
                          0
                      ],
                      [
                          -72.368517,
                          46.047797,
                          0
                      ],
                      [
                          -72.383231,
                          46.034783,
                          0
                      ],
                      [
                          -72.397001,
                          46.023036,
                          0
                      ],
                      [
                          -72.398897,
                          46.024138,
                          0
                      ],
                      [
                          -72.402615,
                          46.026362,
                          0
                      ],
                      [
                          -72.402672,
                          46.026775,
                          0
                      ],
                      [
                          -72.402889,
                          46.027031,
                          0
                      ],
                      [
                          -72.403237,
                          46.027266,
                          0
                      ],
                      [
                          -72.403868,
                          46.027523,
                          0
                      ],
                      [
                          -72.405474,
                          46.028469,
                          0
                      ],
                      [
                          -72.406514,
                          46.029482,
                          0
                      ],
                      [
                          -72.407803,
                          46.030254,
                          0
                      ],
                      [
                          -72.410265,
                          46.031551,
                          0
                      ],
                      [
                          -72.411806,
                          46.030204,
                          0
                      ],
                      [
                          -72.409369,
                          46.027787,
                          0
                      ],
                      [
                          -72.420528,
                          46.017175,
                          0
                      ],
                      [
                          -72.420571,
                          46.017134,
                          0
                      ],
                      [
                          -72.415368,
                          46.014241,
                          0
                      ],
                      [
                          -72.415336,
                          46.014224,
                          0
                      ],
                      [
                          -72.427739,
                          46.00345,
                          0
                      ],
                      [
                          -72.422521,
                          46.000429,
                          0
                      ],
                      [
                          -72.424214,
                          45.999031,
                          0
                      ],
                      [
                          -72.445146,
                          45.982503,
                          0
                      ],
                      [
                          -72.479357,
                          46.001157,
                          0
                      ],
                      [
                          -72.479422,
                          46.001155,
                          0
                      ],
                      [
                          -72.479834,
                          46.001389,
                          0
                      ],
                      [
                          -72.507617,
                          45.988673,
                          0
                      ],
                      [
                          -72.507673,
                          45.988714,
                          0
                      ],
                      [
                          -72.544155,
                          46.015492,
                          0
                      ],
                      [
                          -72.533011,
                          46.022915,
                          0
                      ],
                      [
                          -72.532868,
                          46.023015,
                          0
                      ],
                      [
                          -72.540564,
                          46.028645,
                          0
                      ],
                      [
                          -72.551466,
                          46.021463,
                          0
                      ],
                      [
                          -72.5661,
                          46.031872,
                          0
                      ],
                      [
                          -72.577342,
                          46.024265,
                          0
                      ],
                      [
                          -72.588523,
                          46.016578,
                          0
                      ],
                      [
                          -72.593946,
                          46.020528,
                          0
                      ],
                      [
                          -72.600272,
                          46.016122,
                          0
                      ],
                      [
                          -72.600296,
                          46.01614,
                          0
                      ],
                      [
                          -72.61803,
                          46.029206,
                          0
                      ],
                      [
                          -72.629263,
                          46.021874,
                          0
                      ],
                      [
                          -72.629351,
                          46.021816,
                          0
                      ],
                      [
                          -72.630116,
                          46.022378,
                          0
                      ],
                      [
                          -72.630157,
                          46.022409,
                          0
                      ],
                      [
                          -72.641815,
                          46.014878,
                          0
                      ],
                      [
                          -72.641891,
                          46.014829,
                          0
                      ],
                      [
                          -72.642254,
                          46.015109,
                          0
                      ],
                      [
                          -72.643166,
                          46.015798,
                          0
                      ],
                      [
                          -72.643219,
                          46.015851,
                          0
                      ],
                      [
                          -72.646027,
                          46.014092,
                          0
                      ],
                      [
                          -72.646051,
                          46.014084,
                          0
                      ],
                      [
                          -72.656729,
                          46.007157,
                          0
                      ],
                      [
                          -72.656731,
                          46.007155,
                          0
                      ],
                      [
                          -72.65037,
                          46.00548,
                          0
                      ],
                      [
                          -72.646264,
                          46.005003,
                          0
                      ],
                      [
                          -72.642103,
                          46.004454,
                          0
                      ],
                      [
                          -72.640153,
                          46.003682,
                          0
                      ],
                      [
                          -72.637568,
                          46.00232,
                          0
                      ],
                      [
                          -72.634769,
                          46.000038,
                          0
                      ],
                      [
                          -72.63149,
                          45.997797,
                          0
                      ],
                      [
                          -72.627078,
                          45.995954,
                          0
                      ],
                      [
                          -72.623702,
                          45.994008,
                          0
                      ],
                      [
                          -72.620653,
                          45.991875,
                          0
                      ],
                      [
                          -72.608574,
                          45.98597,
                          0
                      ],
                      [
                          -72.603616,
                          45.984095,
                          0
                      ],
                      [
                          -72.598944,
                          45.98281,
                          0
                      ],
                      [
                          -72.595347,
                          45.981355,
                          0
                      ],
                      [
                          -72.592098,
                          45.979568,
                          0
                      ],
                      [
                          -72.588297,
                          45.978902,
                          0
                      ],
                      [
                          -72.583873,
                          45.979284,
                          0
                      ],
                      [
                          -72.583879,
                          45.979278,
                          0
                      ],
                      [
                          -72.608006,
                          45.958814,
                          0
                      ],
                      [
                          -72.630208,
                          45.940167,
                          0
                      ],
                      [
                          -72.66681,
                          45.90578,
                          0
                      ],
                      [
                          -72.666835,
                          45.905757,
                          0
                      ],
                      [
                          -72.675773,
                          45.91177,
                          0
                      ],
                      [
                          -72.678974,
                          45.909664,
                          0
                      ],
                      [
                          -72.69135,
                          45.916658,
                          0
                      ],
                      [
                          -72.69246,
                          45.9169,
                          0
                      ],
                      [
                          -72.696706,
                          45.916945,
                          0
                      ],
                      [
                          -72.70702,
                          45.908191,
                          0
                      ],
                      [
                          -72.707497,
                          45.908422,
                          0
                      ],
                      [
                          -72.72239,
                          45.897204,
                          0
                      ],
                      [
                          -72.708884,
                          45.887762,
                          0
                      ],
                      [
                          -72.720172,
                          45.879592,
                          0
                      ],
                      [
                          -72.707943,
                          45.870918,
                          0
                      ],
                      [
                          -72.742628,
                          45.842236,
                          0
                      ],
                      [
                          -72.764276,
                          45.825114,
                          0
                      ],
                      [
                          -72.769347,
                          45.828179,
                          0
                      ],
                      [
                          -72.769366,
                          45.828191,
                          0
                      ],
                      [
                          -72.785517,
                          45.816828,
                          0
                      ],
                      [
                          -72.837018,
                          45.852847,
                          0
                      ],
                      [
                          -72.853024,
                          45.863893,
                          0
                      ],
                      [
                          -72.848691,
                          45.874662,
                          0
                      ],
                      [
                          -72.853965,
                          45.878524,
                          0
                      ],
                      [
                          -72.853967,
                          45.878525,
                          0
                      ],
                      [
                          -72.851884,
                          45.880803,
                          0
                      ],
                      [
                          -72.850455,
                          45.883522,
                          0
                      ],
                      [
                          -72.839916,
                          45.890474,
                          0
                      ],
                      [
                          -72.8385,
                          45.891437,
                          0
                      ],
                      [
                          -72.838499,
                          45.891438,
                          0
                      ],
                      [
                          -72.838016,
                          45.890927,
                          0
                      ],
                      [
                          -72.835849,
                          45.889859,
                          0
                      ],
                      [
                          -72.83412,
                          45.889075,
                          0
                      ],
                      [
                          -72.831862,
                          45.886594,
                          0
                      ],
                      [
                          -72.831856,
                          45.886598,
                          0
                      ],
                      [
                          -72.821226,
                          45.893905,
                          0
                      ],
                      [
                          -72.821221,
                          45.893901,
                          0
                      ],
                      [
                          -72.819536,
                          45.892679,
                          0
                      ],
                      [
                          -72.819533,
                          45.892682,
                          0
                      ],
                      [
                          -72.802081,
                          45.904603,
                          0
                      ],
                      [
                          -72.801711,
                          45.90469,
                          0
                      ],
                      [
                          -72.801394,
                          45.905069,
                          0
                      ],
                      [
                          -72.801322,
                          45.905126,
                          0
                      ],
                      [
                          -72.800894,
                          45.905272,
                          0
                      ],
                      [
                          -72.800892,
                          45.905272,
                          0
                      ],
                      [
                          -72.800891,
                          45.905272,
                          0
                      ],
                      [
                          -72.800799,
                          45.905247,
                          0
                      ],
                      [
                          -72.800521,
                          45.905093,
                          0
                      ],
                      [
                          -72.800245,
                          45.904922,
                          0
                      ],
                      [
                          -72.800123,
                          45.904886,
                          0
                      ],
                      [
                          -72.799919,
                          45.904955,
                          0
                      ],
                      [
                          -72.799738,
                          45.905128,
                          0
                      ],
                      [
                          -72.799699,
                          45.90541,
                          0
                      ],
                      [
                          -72.799759,
                          45.905597,
                          0
                      ],
                      [
                          -72.800062,
                          45.906104,
                          0
                      ],
                      [
                          -72.800062,
                          45.906106,
                          0
                      ],
                      [
                          -72.800076,
                          45.906454,
                          0
                      ],
                      [
                          -72.799954,
                          45.906601,
                          0
                      ],
                      [
                          -72.799696,
                          45.906683,
                          0
                      ],
                      [
                          -72.799378,
                          45.906679,
                          0
                      ],
                      [
                          -72.799044,
                          45.906453,
                          0
                      ],
                      [
                          -72.798829,
                          45.906162,
                          0
                      ],
                      [
                          -72.798576,
                          45.905907,
                          0
                      ],
                      [
                          -72.798281,
                          45.905689,
                          0
                      ],
                      [
                          -72.797937,
                          45.905508,
                          0
                      ],
                      [
                          -72.797582,
                          45.905382,
                          0
                      ],
                      [
                          -72.797208,
                          45.905357,
                          0
                      ],
                      [
                          -72.796709,
                          45.90545,
                          0
                      ],
                      [
                          -72.796351,
                          45.905569,
                          0
                      ],
                      [
                          -72.796246,
                          45.905723,
                          0
                      ],
                      [
                          -72.796116,
                          45.905943,
                          0
                      ],
                      [
                          -72.795583,
                          45.906343,
                          0
                      ],
                      [
                          -72.795147,
                          45.906651,
                          0
                      ],
                      [
                          -72.794669,
                          45.906906,
                          0
                      ],
                      [
                          -72.794306,
                          45.906852,
                          0
                      ],
                      [
                          -72.794158,
                          45.906721,
                          0
                      ],
                      [
                          -72.794097,
                          45.906553,
                          0
                      ],
                      [
                          -72.794097,
                          45.906551,
                          0
                      ],
                      [
                          -72.79392,
                          45.90661,
                          0
                      ],
                      [
                          -72.793707,
                          45.906713,
                          0
                      ],
                      [
                          -72.793706,
                          45.906714,
                          0
                      ],
                      [
                          -72.793434,
                          45.906938,
                          0
                      ],
                      [
                          -72.793181,
                          45.907167,
                          0
                      ],
                      [
                          -72.793014,
                          45.907317,
                          0
                      ],
                      [
                          -72.752749,
                          45.942835,
                          0
                      ],
                      [
                          -72.752684,
                          45.942891,
                          0
                      ],
                      [
                          -72.752655,
                          45.942918,
                          0
                      ],
                      [
                          -72.752657,
                          45.942919,
                          0
                      ],
                      [
                          -72.793102,
                          45.971306,
                          0
                      ],
                      [
                          -72.793106,
                          45.971308,
                          0
                      ],
                      [
                          -72.791859,
                          45.972189,
                          0
                      ],
                      [
                          -72.791856,
                          45.972191,
                          0
                      ],
                      [
                          -72.80521,
                          45.981298,
                          0
                      ],
                      [
                          -72.805245,
                          45.981322,
                          0
                      ],
                      [
                          -72.790266,
                          45.991802,
                          0
                      ],
                      [
                          -72.803564,
                          45.997363,
                          0
                      ],
                      [
                          -72.802545,
                          46.011513,
                          0
                      ],
                      [
                          -72.798628,
                          46.014448,
                          0
                      ],
                      [
                          -72.804996,
                          46.015909,
                          0
                      ],
                      [
                          -72.811882,
                          46.022026,
                          0
                      ],
                      [
                          -72.821712,
                          46.029077,
                          0
                      ],
                      [
                          -72.82808,
                          46.032948,
                          0
                      ],
                      [
                          -72.829695,
                          46.021148,
                          0
                      ],
                      [
                          -72.860952,
                          46.042702,
                          0
                      ],
                      [
                          -72.86336,
                          46.038288,
                          0
                      ],
                      [
                          -72.866158,
                          46.035055,
                          0
                      ],
                      [
                          -72.86837,
                          46.033639,
                          0
                      ],
                      [
                          -72.874617,
                          46.03805,
                          0
                      ],
                      [
                          -72.880304,
                          46.034184,
                          0
                      ],
                      [
                          -72.940827,
                          46.076338,
                          0
                      ],
                      [
                          -72.940793,
                          46.078895,
                          0
                      ],
                      [
                          -72.942225,
                          46.079925,
                          0
                      ],
                      [
                          -72.941525,
                          46.081414,
                          0
                      ],
                      [
                          -72.942369,
                          46.084494,
                          0
                      ],
                      [
                          -72.942869,
                          46.086324,
                          0
                      ],
                      [
                          -72.94226,
                          46.089135,
                          0
                      ],
                      [
                          -72.944133,
                          46.089455,
                          0
                      ],
                      [
                          -72.944836,
                          46.085763,
                          0
                      ],
                      [
                          -72.944714,
                          46.08381,
                          0
                      ],
                      [
                          -72.944747,
                          46.080445,
                          0
                      ],
                      [
                          -72.94566,
                          46.079686,
                          0
                      ],
                      [
                          -72.953733,
                          46.085234,
                          0
                      ],
                      [
                          -72.953626,
                          46.08539,
                          0
                      ],
                      [
                          -72.952349,
                          46.085478,
                          0
                      ],
                      [
                          -72.953008,
                          46.086539,
                          0
                      ],
                      [
                          -72.954423,
                          46.087133,
                          0
                      ],
                      [
                          -72.955686,
                          46.0871,
                          0
                      ],
                      [
                          -72.956128,
                          46.086899,
                          0
                      ],
                      [
                          -72.990819,
                          46.111594,
                          0
                      ],
                      [
                          -72.967103,
                          46.113121,
                          0
                      ],
                      [
                          -72.936245,
                          46.151238,
                          0
                      ],
                      [
                          -72.87241,
                          46.185068,
                          0
                      ],
                      [
                          -72.872404,
                          46.18507,
                          0
                      ],
                      [
                          -72.731124,
                          46.23333,
                          0
                      ],
                      [
                          -72.731121,
                          46.233332,
                          0
                      ],
                      [
                          -72.576456,
                          46.296067,
                          0
                      ],
                      [
                          -72.560588,
                          46.306678,
                          0
                      ],
                      [
                          -72.56057,
                          46.306698,
                          0
                      ],
                      [
                          -72.560513,
                          46.306761,
                          0
                      ],
                      [
                          -72.532112,
                          46.334096,
                          0
                      ],
                      [
                          -72.494586,
                          46.355291,
                          0
                      ],
                      [
                          -72.494493,
                          46.355354,
                          0
                      ],
                      [
                          -72.475454,
                          46.37196,
                          0
                      ],
                      [
                          -72.457692,
                          46.382343,
                          0
                      ],
                      [
                          -72.378722,
                          46.410147,
                          0
                      ],
                      [
                          -72.332948,
                          46.421396,
                          0
                      ],
                      [
                          -72.294472,
                          46.424405,
                          0
                      ],
                      [
                          -72.249994,
                          46.44557,
                          0
                      ],
                      [
                          -72.218443,
                          46.515347,
                          0
                      ],
                      [
                          -72.179793,
                          46.545744,
                          0
                      ],
                      [
                          -72.139709,
                          46.563234,
                          0
                      ],
                      [
                          -72.139706,
                          46.563235,
                          0
                      ],
                      [
                          -72.083729,
                          46.570207,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Nicolet-Bécancour",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -63.554303,
                          55.000115,
                          0
                      ],
                      [
                          -66.259617,
                          55.000101,
                          0
                      ],
                      [
                          -66.34006,
                          55.071211,
                          0
                      ],
                      [
                          -66.474474,
                          55.170052,
                          0
                      ],
                      [
                          -66.575157,
                          55.243443,
                          0
                      ],
                      [
                          -66.693565,
                          55.282723,
                          0
                      ],
                      [
                          -66.814357,
                          55.354047,
                          0
                      ],
                      [
                          -67.233535,
                          55.355082,
                          0
                      ],
                      [
                          -67.224621,
                          55.004082,
                          0
                      ],
                      [
                          -67.260253,
                          55.065131,
                          0
                      ],
                      [
                          -67.39715,
                          55.085204,
                          0
                      ],
                      [
                          -67.417237,
                          55.000132,
                          0
                      ],
                      [
                          -69.999333,
                          55.000138,
                          0
                      ],
                      [
                          -70.02989,
                          52.916604,
                          0
                      ],
                      [
                          -70.02991,
                          52.83262,
                          0
                      ],
                      [
                          -70.121453,
                          52.812445,
                          0
                      ],
                      [
                          -70.141241,
                          52.759922,
                          0
                      ],
                      [
                          -70.198017,
                          52.718494,
                          0
                      ],
                      [
                          -70.182893,
                          52.66209,
                          0
                      ],
                      [
                          -70.165947,
                          52.605152,
                          0
                      ],
                      [
                          -70.240563,
                          52.564199,
                          0
                      ],
                      [
                          -70.188143,
                          52.512866,
                          0
                      ],
                      [
                          -70.228057,
                          52.464516,
                          0
                      ],
                      [
                          -70.281927,
                          52.42422,
                          0
                      ],
                      [
                          -70.261723,
                          52.37061,
                          0
                      ],
                      [
                          -70.333236,
                          52.340795,
                          0
                      ],
                      [
                          -70.43333,
                          52.326974,
                          0
                      ],
                      [
                          -70.603042,
                          52.295734,
                          0
                      ],
                      [
                          -70.687719,
                          52.281688,
                          0
                      ],
                      [
                          -70.754892,
                          52.24719,
                          0
                      ],
                      [
                          -70.834667,
                          52.275587,
                          0
                      ],
                      [
                          -70.84827,
                          52.27059,
                          0
                      ],
                      [
                          -70.926367,
                          52.241847,
                          0
                      ],
                      [
                          -71.084872,
                          52.205993,
                          0
                      ],
                      [
                          -71.14563,
                          52.157505,
                          0
                      ],
                      [
                          -71.192552,
                          52.110823,
                          0
                      ],
                      [
                          -71.252428,
                          52.059612,
                          0
                      ],
                      [
                          -71.296233,
                          51.974734,
                          0
                      ],
                      [
                          -71.336421,
                          51.914786,
                          0
                      ],
                      [
                          -71.373747,
                          51.857358,
                          0
                      ],
                      [
                          -71.527647,
                          51.833711,
                          0
                      ],
                      [
                          -71.574538,
                          51.777094,
                          0
                      ],
                      [
                          -71.603596,
                          51.673697,
                          0
                      ],
                      [
                          -71.582292,
                          51.562752,
                          0
                      ],
                      [
                          -71.59159,
                          51.505659,
                          0
                      ],
                      [
                          -71.65698,
                          51.476821,
                          0
                      ],
                      [
                          -71.70997,
                          51.431201,
                          0
                      ],
                      [
                          -71.721117,
                          51.366444,
                          0
                      ],
                      [
                          -71.81608,
                          51.362799,
                          0
                      ],
                      [
                          -71.876463,
                          51.311347,
                          0
                      ],
                      [
                          -71.990176,
                          51.23176,
                          0
                      ],
                      [
                          -72.014855,
                          51.177591,
                          0
                      ],
                      [
                          -72.067402,
                          51.136465,
                          0
                      ],
                      [
                          -72.124254,
                          51.085221,
                          0
                      ],
                      [
                          -72.151527,
                          51.03789,
                          0
                      ],
                      [
                          -72.164895,
                          50.978473,
                          0
                      ],
                      [
                          -72.202848,
                          51.027865,
                          0
                      ],
                      [
                          -72.267441,
                          50.99836,
                          0
                      ],
                      [
                          -72.296128,
                          50.949945,
                          0
                      ],
                      [
                          -72.345371,
                          50.885426,
                          0
                      ],
                      [
                          -72.397645,
                          50.817315,
                          0
                      ],
                      [
                          -72.469868,
                          50.784321,
                          0
                      ],
                      [
                          -72.546492,
                          50.759625,
                          0
                      ],
                      [
                          -72.566088,
                          50.831828,
                          0
                      ],
                      [
                          -72.563754,
                          50.884035,
                          0
                      ],
                      [
                          -72.607589,
                          50.809238,
                          0
                      ],
                      [
                          -72.666519,
                          50.744738,
                          0
                      ],
                      [
                          -72.730213,
                          50.77325,
                          0
                      ],
                      [
                          -72.7802,
                          50.82582,
                          0
                      ],
                      [
                          -72.875771,
                          50.805196,
                          0
                      ],
                      [
                          -72.928537,
                          50.757708,
                          0
                      ],
                      [
                          -72.959537,
                          50.705564,
                          0
                      ],
                      [
                          -73.017837,
                          50.670273,
                          0
                      ],
                      [
                          -73.079191,
                          50.731467,
                          0
                      ],
                      [
                          -73.167292,
                          50.73482,
                          0
                      ],
                      [
                          -73.232949,
                          50.694699,
                          0
                      ],
                      [
                          -73.306912,
                          50.586431,
                          0
                      ],
                      [
                          -73.37173,
                          50.521626,
                          0
                      ],
                      [
                          -73.399831,
                          50.468779,
                          0
                      ],
                      [
                          -73.445101,
                          50.419043,
                          0
                      ],
                      [
                          -73.471291,
                          50.368154,
                          0
                      ],
                      [
                          -73.609553,
                          50.331773,
                          0
                      ],
                      [
                          -73.637338,
                          50.280507,
                          0
                      ],
                      [
                          -73.671472,
                          50.230993,
                          0
                      ],
                      [
                          -73.757272,
                          50.225783,
                          0
                      ],
                      [
                          -73.783648,
                          50.175515,
                          0
                      ],
                      [
                          -73.785224,
                          50.06822,
                          0
                      ],
                      [
                          -73.827427,
                          50.011312,
                          0
                      ],
                      [
                          -73.890961,
                          49.958655,
                          0
                      ],
                      [
                          -73.911041,
                          49.896872,
                          0
                      ],
                      [
                          -74.012647,
                          49.803369,
                          0
                      ],
                      [
                          -74.048638,
                          49.735351,
                          0
                      ],
                      [
                          -74.117159,
                          49.703504,
                          0
                      ],
                      [
                          -74.188226,
                          49.661617,
                          0
                      ],
                      [
                          -74.249636,
                          49.570123,
                          0
                      ],
                      [
                          -74.257064,
                          49.519318,
                          0
                      ],
                      [
                          -74.311431,
                          49.48111,
                          0
                      ],
                      [
                          -74.39302,
                          49.475466,
                          0
                      ],
                      [
                          -74.406945,
                          49.40467,
                          0
                      ],
                      [
                          -74.368096,
                          49.300842,
                          0
                      ],
                      [
                          -74.3033,
                          49.268313,
                          0
                      ],
                      [
                          -74.228916,
                          49.256308,
                          0
                      ],
                      [
                          -74.249263,
                          49.148117,
                          0
                      ],
                      [
                          -74.278834,
                          49.102103,
                          0
                      ],
                      [
                          -74.294195,
                          48.982944,
                          0
                      ],
                      [
                          -74.330865,
                          48.933877,
                          0
                      ],
                      [
                          -74.406751,
                          48.922763,
                          0
                      ],
                      [
                          -74.438415,
                          48.973183,
                          0
                      ],
                      [
                          -74.471658,
                          48.945604,
                          0
                      ],
                      [
                          -74.487088,
                          48.932791,
                          0
                      ],
                      [
                          -74.541707,
                          48.978958,
                          0
                      ],
                      [
                          -74.676271,
                          48.99963,
                          0
                      ],
                      [
                          -75.520552,
                          49.00007,
                          0
                      ],
                      [
                          -77.059499,
                          49.000107,
                          0
                      ],
                      [
                          -78.752875,
                          49.000082,
                          0
                      ],
                      [
                          -78.903012,
                          49.009179,
                          0
                      ],
                      [
                          -79.05335,
                          48.999986,
                          0
                      ],
                      [
                          -79.517775,
                          49.009117,
                          0
                      ],
                      [
                          -79.51851,
                          51.568215,
                          0
                      ],
                      [
                          -79.433781,
                          51.632361,
                          0
                      ],
                      [
                          -79.322233,
                          51.671387,
                          0
                      ],
                      [
                          -79.218086,
                          51.62758,
                          0
                      ],
                      [
                          -79.223137,
                          51.546581,
                          0
                      ],
                      [
                          -79.07238,
                          51.522852,
                          0
                      ],
                      [
                          -78.81967,
                          51.450572,
                          0
                      ],
                      [
                          -78.879902,
                          51.636241,
                          0
                      ],
                      [
                          -79.026625,
                          51.743024,
                          0
                      ],
                      [
                          -78.896289,
                          51.901305,
                          0
                      ],
                      [
                          -78.884989,
                          51.956691,
                          0
                      ],
                      [
                          -78.72017,
                          52.047916,
                          0
                      ],
                      [
                          -78.601645,
                          52.106975,
                          0
                      ],
                      [
                          -78.558946,
                          52.287838,
                          0
                      ],
                      [
                          -78.585189,
                          52.417533,
                          0
                      ],
                      [
                          -78.565612,
                          52.494169,
                          0
                      ],
                      [
                          -78.709857,
                          52.521537,
                          0
                      ],
                      [
                          -78.828117,
                          52.692339,
                          0
                      ],
                      [
                          -78.869566,
                          52.736982,
                          0
                      ],
                      [
                          -78.874845,
                          52.813979,
                          0
                      ],
                      [
                          -78.876736,
                          52.870735,
                          0
                      ],
                      [
                          -79.003679,
                          52.906791,
                          0
                      ],
                      [
                          -78.96785,
                          52.997622,
                          0
                      ],
                      [
                          -78.975849,
                          53.12343,
                          0
                      ],
                      [
                          -78.944187,
                          53.222905,
                          0
                      ],
                      [
                          -79.01677,
                          53.269519,
                          0
                      ],
                      [
                          -78.950229,
                          53.375379,
                          0
                      ],
                      [
                          -79.065268,
                          53.408019,
                          0
                      ],
                      [
                          -79.128637,
                          53.485061,
                          0
                      ],
                      [
                          -79.095033,
                          53.533799,
                          0
                      ],
                      [
                          -79.075239,
                          53.632445,
                          0
                      ],
                      [
                          -79.112386,
                          53.678858,
                          0
                      ],
                      [
                          -79.202354,
                          53.989279,
                          0
                      ],
                      [
                          -79.288238,
                          54.063365,
                          0
                      ],
                      [
                          -79.4633,
                          54.221059,
                          0
                      ],
                      [
                          -79.473612,
                          54.323901,
                          0
                      ],
                      [
                          -79.596695,
                          54.546039,
                          0
                      ],
                      [
                          -79.723589,
                          54.625877,
                          0
                      ],
                      [
                          -79.669282,
                          54.674817,
                          0
                      ],
                      [
                          -79.479194,
                          54.740572,
                          0
                      ],
                      [
                          -79.18182,
                          54.799769,
                          0
                      ],
                      [
                          -78.854692,
                          54.905521,
                          0
                      ],
                      [
                          -78.610365,
                          54.95986,
                          0
                      ],
                      [
                          -78.413649,
                          55.018464,
                          0
                      ],
                      [
                          -78.135472,
                          55.145976,
                          0
                      ],
                      [
                          -78.015763,
                          55.187163,
                          0
                      ],
                      [
                          -77.695436,
                          55.345134,
                          0
                      ],
                      [
                          -77.630708,
                          55.381809,
                          0
                      ],
                      [
                          -77.509963,
                          55.429391,
                          0
                      ],
                      [
                          -77.275852,
                          55.576815,
                          0
                      ],
                      [
                          -77.174604,
                          55.683927,
                          0
                      ],
                      [
                          -77.042219,
                          55.779398,
                          0
                      ],
                      [
                          -76.875363,
                          55.923481,
                          0
                      ],
                      [
                          -76.689953,
                          56.058591,
                          0
                      ],
                      [
                          -76.626254,
                          56.231259,
                          0
                      ],
                      [
                          -76.531116,
                          56.373455,
                          0
                      ],
                      [
                          -76.546053,
                          56.621256,
                          0
                      ],
                      [
                          -76.537718,
                          56.788636,
                          0
                      ],
                      [
                          -76.561501,
                          56.906417,
                          0
                      ],
                      [
                          -76.586705,
                          57.148929,
                          0
                      ],
                      [
                          -76.666016,
                          57.371384,
                          0
                      ],
                      [
                          -76.779189,
                          57.517018,
                          0
                      ],
                      [
                          -76.926145,
                          57.756467,
                          0
                      ],
                      [
                          -77.039819,
                          57.859773,
                          0
                      ],
                      [
                          -77.310116,
                          58.074367,
                          0
                      ],
                      [
                          -77.561173,
                          58.210177,
                          0
                      ],
                      [
                          -77.662575,
                          58.267196,
                          0
                      ],
                      [
                          -77.995166,
                          58.352565,
                          0
                      ],
                      [
                          -78.240316,
                          58.494179,
                          0
                      ],
                      [
                          -78.363341,
                          58.530872,
                          0
                      ],
                      [
                          -78.593337,
                          58.6503,
                          0
                      ],
                      [
                          -78.575855,
                          58.766361,
                          0
                      ],
                      [
                          -78.576218,
                          58.919669,
                          0
                      ],
                      [
                          -78.35733,
                          59.031552,
                          0
                      ],
                      [
                          -78.092851,
                          59.220232,
                          0
                      ],
                      [
                          -77.986688,
                          59.276314,
                          0
                      ],
                      [
                          -77.950257,
                          59.403487,
                          0
                      ],
                      [
                          -77.846054,
                          59.552319,
                          0
                      ],
                      [
                          -77.79721,
                          59.7483,
                          0
                      ],
                      [
                          -77.509676,
                          59.775406,
                          0
                      ],
                      [
                          -77.560913,
                          60.000306,
                          0
                      ],
                      [
                          -77.642119,
                          60.07572,
                          0
                      ],
                      [
                          -77.607698,
                          60.227054,
                          0
                      ],
                      [
                          -77.66664,
                          60.331021,
                          0
                      ],
                      [
                          -77.771088,
                          60.386602,
                          0
                      ],
                      [
                          -77.761133,
                          60.46424,
                          0
                      ],
                      [
                          -77.870972,
                          60.554545,
                          0
                      ],
                      [
                          -77.845953,
                          60.64219,
                          0
                      ],
                      [
                          -78.000001,
                          60.748991,
                          0
                      ],
                      [
                          -78.075994,
                          60.791498,
                          0
                      ],
                      [
                          -78.21255,
                          60.824529,
                          0
                      ],
                      [
                          -78.142442,
                          60.892251,
                          0
                      ],
                      [
                          -77.87027,
                          61.05345,
                          0
                      ],
                      [
                          -77.79072,
                          61.166838,
                          0
                      ],
                      [
                          -77.785248,
                          61.394614,
                          0
                      ],
                      [
                          -77.806525,
                          61.466401,
                          0
                      ],
                      [
                          -77.959688,
                          61.665323,
                          0
                      ],
                      [
                          -78.058719,
                          61.809805,
                          0
                      ],
                      [
                          -78.148593,
                          62.034653,
                          0
                      ],
                      [
                          -78.175881,
                          62.159653,
                          0
                      ],
                      [
                          -78.190674,
                          62.241356,
                          0
                      ],
                      [
                          -78.153153,
                          62.311803,
                          0
                      ],
                      [
                          -77.944802,
                          62.414194,
                          0
                      ],
                      [
                          -77.677825,
                          62.510905,
                          0
                      ],
                      [
                          -77.568817,
                          62.532859,
                          0
                      ],
                      [
                          -77.509975,
                          62.575899,
                          0
                      ],
                      [
                          -77.031812,
                          62.532178,
                          0
                      ],
                      [
                          -76.733152,
                          62.506757,
                          0
                      ],
                      [
                          -76.357048,
                          62.422667,
                          0
                      ],
                      [
                          -76.194307,
                          62.390924,
                          0
                      ],
                      [
                          -75.938067,
                          62.350153,
                          0
                      ],
                      [
                          -75.624489,
                          62.291437,
                          0
                      ],
                      [
                          -75.113556,
                          62.283908,
                          0
                      ],
                      [
                          -74.943494,
                          62.261134,
                          0
                      ],
                      [
                          -74.475369,
                          62.24779,
                          0
                      ],
                      [
                          -74.301193,
                          62.285484,
                          0
                      ],
                      [
                          -74.01368,
                          62.369464,
                          0
                      ],
                      [
                          -73.853886,
                          62.461159,
                          0
                      ],
                      [
                          -73.687482,
                          62.481349,
                          0
                      ],
                      [
                          -73.388674,
                          62.376506,
                          0
                      ],
                      [
                          -73.183118,
                          62.307297,
                          0
                      ],
                      [
                          -72.976693,
                          62.18171,
                          0
                      ],
                      [
                          -72.715774,
                          62.140079,
                          0
                      ],
                      [
                          -72.603581,
                          62.086215,
                          0
                      ],
                      [
                          -72.491111,
                          61.929625,
                          0
                      ],
                      [
                          -72.249118,
                          61.88227,
                          0
                      ],
                      [
                          -72.072821,
                          61.757408,
                          0
                      ],
                      [
                          -71.805023,
                          61.694773,
                          0
                      ],
                      [
                          -71.571994,
                          61.628447,
                          0
                      ],
                      [
                          -71.530686,
                          61.568224,
                          0
                      ],
                      [
                          -71.563807,
                          61.407556,
                          0
                      ],
                      [
                          -71.636259,
                          61.325381,
                          0
                      ],
                      [
                          -71.460319,
                          61.225631,
                          0
                      ],
                      [
                          -71.075454,
                          61.13104,
                          0
                      ],
                      [
                          -70.843408,
                          61.078611,
                          0
                      ],
                      [
                          -70.685303,
                          61.064225,
                          0
                      ],
                      [
                          -70.304375,
                          61.100783,
                          0
                      ],
                      [
                          -70.166727,
                          61.090336,
                          0
                      ],
                      [
                          -70.142854,
                          61.028384,
                          0
                      ],
                      [
                          -70.101287,
                          60.973579,
                          0
                      ],
                      [
                          -69.999175,
                          60.949931,
                          0
                      ],
                      [
                          -69.9816,
                          61.032521,
                          0
                      ],
                      [
                          -69.762361,
                          60.918293,
                          0
                      ],
                      [
                          -69.684323,
                          61.015858,
                          0
                      ],
                      [
                          -69.568106,
                          61.078,
                          0
                      ],
                      [
                          -69.480922,
                          61.025115,
                          0
                      ],
                      [
                          -69.417698,
                          60.94949,
                          0
                      ],
                      [
                          -69.37192,
                          60.822654,
                          0
                      ],
                      [
                          -69.542436,
                          60.734623,
                          0
                      ],
                      [
                          -69.674638,
                          60.673787,
                          0
                      ],
                      [
                          -69.622434,
                          60.588488,
                          0
                      ],
                      [
                          -69.717989,
                          60.410399,
                          0
                      ],
                      [
                          -69.660172,
                          60.310797,
                          0
                      ],
                      [
                          -69.55531,
                          60.099839,
                          0
                      ],
                      [
                          -69.589674,
                          59.976222,
                          0
                      ],
                      [
                          -69.522873,
                          59.833769,
                          0
                      ],
                      [
                          -69.5113,
                          59.695244,
                          0
                      ],
                      [
                          -69.616667,
                          59.54052,
                          0
                      ],
                      [
                          -69.402719,
                          59.378397,
                          0
                      ],
                      [
                          -69.231215,
                          59.319187,
                          0
                      ],
                      [
                          -69.091986,
                          59.128313,
                          0
                      ],
                      [
                          -68.892262,
                          58.924139,
                          0
                      ],
                      [
                          -68.632457,
                          58.938751,
                          0
                      ],
                      [
                          -68.491823,
                          58.924387,
                          0
                      ],
                      [
                          -68.299534,
                          58.847243,
                          0
                      ],
                      [
                          -68.274039,
                          58.762861,
                          0
                      ],
                      [
                          -68.244943,
                          58.595494,
                          0
                      ],
                      [
                          -67.893855,
                          58.526932,
                          0
                      ],
                      [
                          -67.620602,
                          58.424877,
                          0
                      ],
                      [
                          -67.551051,
                          58.388992,
                          0
                      ],
                      [
                          -67.504269,
                          58.462655,
                          0
                      ],
                      [
                          -67.397639,
                          58.36114,
                          0
                      ],
                      [
                          -67.200441,
                          58.382474,
                          0
                      ],
                      [
                          -66.939049,
                          58.515771,
                          0
                      ],
                      [
                          -66.711529,
                          58.54519,
                          0
                      ],
                      [
                          -66.586393,
                          58.661751,
                          0
                      ],
                      [
                          -66.493349,
                          58.77678,
                          0
                      ],
                      [
                          -66.472943,
                          58.866424,
                          0
                      ],
                      [
                          -66.371825,
                          58.86706,
                          0
                      ],
                      [
                          -66.056588,
                          58.883316,
                          0
                      ],
                      [
                          -65.952332,
                          58.958231,
                          0
                      ],
                      [
                          -65.923748,
                          59.054097,
                          0
                      ],
                      [
                          -65.853755,
                          59.130297,
                          0
                      ],
                      [
                          -65.853647,
                          59.235235,
                          0
                      ],
                      [
                          -65.64847,
                          59.406768,
                          0
                      ],
                      [
                          -65.530204,
                          59.467384,
                          0
                      ],
                      [
                          -65.534041,
                          59.669745,
                          0
                      ],
                      [
                          -65.54534,
                          59.748192,
                          0
                      ],
                      [
                          -65.493756,
                          59.790862,
                          0
                      ],
                      [
                          -65.166927,
                          60.00019,
                          0
                      ],
                      [
                          -65.084107,
                          60.096725,
                          0
                      ],
                      [
                          -64.950854,
                          60.236367,
                          0
                      ],
                      [
                          -64.898848,
                          60.28237,
                          0
                      ],
                      [
                          -64.682899,
                          60.351842,
                          0
                      ],
                      [
                          -64.647536,
                          60.296609,
                          0
                      ],
                      [
                          -64.751265,
                          60.285385,
                          0
                      ],
                      [
                          -64.851075,
                          60.233844,
                          0
                      ],
                      [
                          -64.736687,
                          60.167256,
                          0
                      ],
                      [
                          -64.591969,
                          60.151725,
                          0
                      ],
                      [
                          -64.689975,
                          60.106496,
                          0
                      ],
                      [
                          -64.810212,
                          60.050614,
                          0
                      ],
                      [
                          -64.844968,
                          59.977082,
                          0
                      ],
                      [
                          -64.641775,
                          59.93085,
                          0
                      ],
                      [
                          -64.738464,
                          59.890163,
                          0
                      ],
                      [
                          -64.820389,
                          59.823575,
                          0
                      ],
                      [
                          -64.760586,
                          59.707684,
                          0
                      ],
                      [
                          -64.803468,
                          59.661214,
                          0
                      ],
                      [
                          -64.812302,
                          59.50227,
                          0
                      ],
                      [
                          -64.685475,
                          59.454261,
                          0
                      ],
                      [
                          -64.556713,
                          59.489957,
                          0
                      ],
                      [
                          -64.43382,
                          59.534429,
                          0
                      ],
                      [
                          -64.388824,
                          59.475514,
                          0
                      ],
                      [
                          -64.536442,
                          59.369883,
                          0
                      ],
                      [
                          -64.476789,
                          59.173409,
                          0
                      ],
                      [
                          -64.447653,
                          59.101194,
                          0
                      ],
                      [
                          -64.278213,
                          59.030028,
                          0
                      ],
                      [
                          -64.442667,
                          58.99069,
                          0
                      ],
                      [
                          -64.572421,
                          59.03392,
                          0
                      ],
                      [
                          -64.69652,
                          59.074257,
                          0
                      ],
                      [
                          -64.826475,
                          59.045251,
                          0
                      ],
                      [
                          -64.840933,
                          58.977219,
                          0
                      ],
                      [
                          -64.634305,
                          58.934836,
                          0
                      ],
                      [
                          -64.517512,
                          58.882502,
                          0
                      ],
                      [
                          -64.383597,
                          58.904252,
                          0
                      ],
                      [
                          -64.252694,
                          58.868883,
                          0
                      ],
                      [
                          -64.160916,
                          58.76224,
                          0
                      ],
                      [
                          -63.973823,
                          58.831549,
                          0
                      ],
                      [
                          -63.780889,
                          58.879716,
                          0
                      ],
                      [
                          -63.639839,
                          58.851434,
                          0
                      ],
                      [
                          -63.491221,
                          58.773048,
                          0
                      ],
                      [
                          -63.763102,
                          58.713685,
                          0
                      ],
                      [
                          -64.054767,
                          58.683962,
                          0
                      ],
                      [
                          -64.144836,
                          58.607432,
                          0
                      ],
                      [
                          -64.063799,
                          58.539436,
                          0
                      ],
                      [
                          -63.928394,
                          58.557346,
                          0
                      ],
                      [
                          -63.83908,
                          58.460848,
                          0
                      ],
                      [
                          -64.016358,
                          58.411903,
                          0
                      ],
                      [
                          -64.135836,
                          58.367513,
                          0
                      ],
                      [
                          -64.219149,
                          58.246764,
                          0
                      ],
                      [
                          -64.36994,
                          58.209204,
                          0
                      ],
                      [
                          -64.41858,
                          58.063976,
                          0
                      ],
                      [
                          -64.27124,
                          58.060505,
                          0
                      ],
                      [
                          -64.201929,
                          57.976778,
                          0
                      ],
                      [
                          -63.99949,
                          57.911722,
                          0
                      ],
                      [
                          -63.873607,
                          57.711688,
                          0
                      ],
                      [
                          -63.752211,
                          57.707251,
                          0
                      ],
                      [
                          -63.844369,
                          57.609777,
                          0
                      ],
                      [
                          -63.755876,
                          57.487742,
                          0
                      ],
                      [
                          -63.720687,
                          57.408658,
                          0
                      ],
                      [
                          -63.769209,
                          57.339664,
                          0
                      ],
                      [
                          -63.857801,
                          57.330125,
                          0
                      ],
                      [
                          -63.879386,
                          57.204723,
                          0
                      ],
                      [
                          -63.79914,
                          57.227929,
                          0
                      ],
                      [
                          -63.766995,
                          57.143347,
                          0
                      ],
                      [
                          -63.896391,
                          57.058014,
                          0
                      ],
                      [
                          -63.909055,
                          56.945159,
                          0
                      ],
                      [
                          -64.118479,
                          56.680576,
                          0
                      ],
                      [
                          -63.945974,
                          56.540826,
                          0
                      ],
                      [
                          -63.914853,
                          56.464833,
                          0
                      ],
                      [
                          -64.08519,
                          56.427918,
                          0
                      ],
                      [
                          -64.016286,
                          56.267861,
                          0
                      ],
                      [
                          -63.877618,
                          56.198416,
                          0
                      ],
                      [
                          -64.009223,
                          56.108577,
                          0
                      ],
                      [
                          -63.907157,
                          56.093634,
                          0
                      ],
                      [
                          -63.546921,
                          56.032812,
                          0
                      ],
                      [
                          -63.472111,
                          55.990905,
                          0
                      ],
                      [
                          -63.76515,
                          55.933917,
                          0
                      ],
                      [
                          -63.798409,
                          55.859823,
                          0
                      ],
                      [
                          -63.721241,
                          55.739225,
                          0
                      ],
                      [
                          -63.766136,
                          55.655682,
                          0
                      ],
                      [
                          -63.666504,
                          55.649524,
                          0
                      ],
                      [
                          -63.691015,
                          55.530153,
                          0
                      ],
                      [
                          -63.751275,
                          55.488147,
                          0
                      ],
                      [
                          -63.723998,
                          55.438535,
                          0
                      ],
                      [
                          -63.377609,
                          55.409548,
                          0
                      ],
                      [
                          -63.384951,
                          55.356201,
                          0
                      ],
                      [
                          -63.528858,
                          55.343228,
                          0
                      ],
                      [
                          -63.649219,
                          55.277672,
                          0
                      ],
                      [
                          -63.541025,
                          55.225839,
                          0
                      ],
                      [
                          -63.399986,
                          55.253677,
                          0
                      ],
                      [
                          -63.547765,
                          55.190365,
                          0
                      ],
                      [
                          -63.554303,
                          55.000115,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Ungava",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "GeometryCollection",
              "geometries": [
                  {
                      "type": "Polygon",
                      "coordinates": [
                          [
                              [
                                  -66.814357,
                                  55.354047,
                                  0
                              ],
                              [
                                  -66.78033,
                                  55.272083,
                                  0
                              ],
                              [
                                  -66.685781,
                                  55.213182,
                                  0
                              ],
                              [
                                  -66.754435,
                                  55.169662,
                                  0
                              ],
                              [
                                  -66.665249,
                                  55.024215,
                                  0
                              ],
                              [
                                  -66.727963,
                                  54.965908,
                                  0
                              ],
                              [
                                  -66.629764,
                                  54.841931,
                                  0
                              ],
                              [
                                  -66.661071,
                                  54.73288,
                                  0
                              ],
                              [
                                  -66.773327,
                                  54.763853,
                                  0
                              ],
                              [
                                  -66.873691,
                                  54.812934,
                                  0
                              ],
                              [
                                  -67.02681,
                                  54.85863,
                                  0
                              ],
                              [
                                  -67.224621,
                                  55.004082,
                                  0
                              ],
                              [
                                  -67.233535,
                                  55.355082,
                                  0
                              ],
                              [
                                  -66.814357,
                                  55.354047,
                                  0
                              ]
                          ]
                      ]
                  },
                  {
                      "type": "Polygon",
                      "coordinates": [
                          [
                              [
                                  -66.259617,
                                  55.000101,
                                  0
                              ],
                              [
                                  -63.554303,
                                  55.000115,
                                  0
                              ],
                              [
                                  -63.577438,
                                  54.929922,
                                  0
                              ],
                              [
                                  -63.820152,
                                  54.917003,
                                  0
                              ],
                              [
                                  -63.878743,
                                  54.799751,
                                  0
                              ],
                              [
                                  -63.777543,
                                  54.701305,
                                  0
                              ],
                              [
                                  -63.710168,
                                  54.623639,
                                  0
                              ],
                              [
                                  -63.925547,
                                  54.604527,
                                  0
                              ],
                              [
                                  -64.062867,
                                  54.604635,
                                  0
                              ],
                              [
                                  -64.187398,
                                  54.715894,
                                  0
                              ],
                              [
                                  -64.401906,
                                  54.788447,
                                  0
                              ],
                              [
                                  -64.472212,
                                  54.751278,
                                  0
                              ],
                              [
                                  -64.762476,
                                  54.725954,
                                  0
                              ],
                              [
                                  -64.758555,
                                  54.801037,
                                  0
                              ],
                              [
                                  -64.852591,
                                  54.837727,
                                  0
                              ],
                              [
                                  -65.038291,
                                  54.94179,
                                  0
                              ],
                              [
                                  -65.155961,
                                  54.926845,
                                  0
                              ],
                              [
                                  -65.228632,
                                  54.847123,
                                  0
                              ],
                              [
                                  -65.460026,
                                  54.797844,
                                  0
                              ],
                              [
                                  -65.487331,
                                  54.731981,
                                  0
                              ],
                              [
                                  -65.619311,
                                  54.734764,
                                  0
                              ],
                              [
                                  -65.839451,
                                  54.836067,
                                  0
                              ],
                              [
                                  -65.855588,
                                  54.889933,
                                  0
                              ],
                              [
                                  -66.046909,
                                  54.939631,
                                  0
                              ],
                              [
                                  -66.149434,
                                  54.982486,
                                  0
                              ],
                              [
                                  -66.259617,
                                  55.000101,
                                  0
                              ]
                          ]
                      ]
                  },
                  {
                      "type": "Polygon",
                      "coordinates": [
                          [
                              [
                                  -67.417237,
                                  55.000132,
                                  0
                              ],
                              [
                                  -67.215099,
                                  54.806578,
                                  0
                              ],
                              [
                                  -67.120573,
                                  54.747878,
                                  0
                              ],
                              [
                                  -67.073435,
                                  54.680271,
                                  0
                              ],
                              [
                                  -67.263975,
                                  54.58824,
                                  0
                              ],
                              [
                                  -67.224615,
                                  54.527736,
                                  0
                              ],
                              [
                                  -67.337812,
                                  54.502266,
                                  0
                              ],
                              [
                                  -67.446612,
                                  54.572738,
                                  0
                              ],
                              [
                                  -67.489402,
                                  54.485492,
                                  0
                              ],
                              [
                                  -67.573117,
                                  54.491614,
                                  0
                              ],
                              [
                                  -67.735456,
                                  54.399707,
                                  0
                              ],
                              [
                                  -67.669491,
                                  54.242955,
                                  0
                              ],
                              [
                                  -67.648985,
                                  54.18868,
                                  0
                              ],
                              [
                                  -67.760679,
                                  54.153957,
                                  0
                              ],
                              [
                                  -67.796336,
                                  54.03696,
                                  0
                              ],
                              [
                                  -67.668495,
                                  53.938652,
                                  0
                              ],
                              [
                                  -67.594933,
                                  53.879534,
                                  0
                              ],
                              [
                                  -67.507769,
                                  53.820149,
                                  0
                              ],
                              [
                                  -67.545859,
                                  53.759978,
                                  0
                              ],
                              [
                                  -67.489617,
                                  53.720597,
                                  0
                              ],
                              [
                                  -67.403134,
                                  53.671706,
                                  0
                              ],
                              [
                                  -67.233393,
                                  53.548978,
                                  0
                              ],
                              [
                                  -67.091211,
                                  53.531839,
                                  0
                              ],
                              [
                                  -66.991675,
                                  53.471311,
                                  0
                              ],
                              [
                                  -66.901991,
                                  53.420111,
                                  0
                              ],
                              [
                                  -66.999247,
                                  53.349334,
                                  0
                              ],
                              [
                                  -66.962408,
                                  53.212309,
                                  0
                              ],
                              [
                                  -66.981138,
                                  53.101416,
                                  0
                              ],
                              [
                                  -67.108145,
                                  53.107807,
                                  0
                              ],
                              [
                                  -67.171525,
                                  53.145306,
                                  0
                              ],
                              [
                                  -67.313364,
                                  53.128938,
                                  0
                              ],
                              [
                                  -67.346495,
                                  52.990055,
                                  0
                              ],
                              [
                                  -67.241808,
                                  52.85806,
                                  0
                              ],
                              [
                                  -67.051648,
                                  52.866198,
                                  0
                              ],
                              [
                                  -67.058613,
                                  52.803758,
                                  0
                              ],
                              [
                                  -66.963076,
                                  52.755232,
                                  0
                              ],
                              [
                                  -66.900279,
                                  52.684105,
                                  0
                              ],
                              [
                                  -66.871152,
                                  52.755436,
                                  0
                              ],
                              [
                                  -66.78929,
                                  52.678516,
                                  0
                              ],
                              [
                                  -66.754237,
                                  52.747575,
                                  0
                              ],
                              [
                                  -66.665614,
                                  52.819357,
                                  0
                              ],
                              [
                                  -66.600964,
                                  52.950325,
                                  0
                              ],
                              [
                                  -66.518066,
                                  52.962713,
                                  0
                              ],
                              [
                                  -66.473181,
                                  53.041775,
                                  0
                              ],
                              [
                                  -66.331822,
                                  52.976431,
                                  0
                              ],
                              [
                                  -66.307619,
                                  52.921122,
                                  0
                              ],
                              [
                                  -66.276441,
                                  52.845095,
                                  0
                              ],
                              [
                                  -66.390667,
                                  52.877991,
                                  0
                              ],
                              [
                                  -66.345615,
                                  52.776155,
                                  0
                              ],
                              [
                                  -66.334015,
                                  52.689036,
                                  0
                              ],
                              [
                                  -66.284665,
                                  52.609542,
                                  0
                              ],
                              [
                                  -66.346207,
                                  52.648124,
                                  0
                              ],
                              [
                                  -66.441498,
                                  52.630598,
                                  0
                              ],
                              [
                                  -66.380473,
                                  52.563712,
                                  0
                              ],
                              [
                                  -66.359463,
                                  52.491572,
                                  0
                              ],
                              [
                                  -66.346526,
                                  52.399742,
                                  0
                              ],
                              [
                                  -66.46878,
                                  52.358246,
                                  0
                              ],
                              [
                                  -66.383984,
                                  52.206322,
                                  0
                              ],
                              [
                                  -66.322702,
                                  52.130825,
                                  0
                              ],
                              [
                                  -66.297331,
                                  52.236714,
                                  0
                              ],
                              [
                                  -66.292222,
                                  52.310815,
                                  0
                              ],
                              [
                                  -66.151118,
                                  52.233373,
                                  0
                              ],
                              [
                                  -66.077868,
                                  52.165042,
                                  0
                              ],
                              [
                                  -66.02554,
                                  52.087225,
                                  0
                              ],
                              [
                                  -65.681995,
                                  52.107065,
                                  0
                              ],
                              [
                                  -65.640136,
                                  52.06028,
                                  0
                              ],
                              [
                                  -65.507409,
                                  52.106008,
                                  0
                              ],
                              [
                                  -65.410221,
                                  52.205417,
                                  0
                              ],
                              [
                                  -65.286875,
                                  52.254003,
                                  0
                              ],
                              [
                                  -65.174387,
                                  52.178023,
                                  0
                              ],
                              [
                                  -65.137717,
                                  52.10526,
                                  0
                              ],
                              [
                                  -64.9083,
                                  52.047629,
                                  0
                              ],
                              [
                                  -64.832853,
                                  51.971237,
                                  0
                              ],
                              [
                                  -64.751361,
                                  51.90172,
                                  0
                              ],
                              [
                                  -64.701882,
                                  51.756512,
                                  0
                              ],
                              [
                                  -64.557733,
                                  51.582837,
                                  0
                              ],
                              [
                                  -64.298032,
                                  51.702448,
                                  0
                              ],
                              [
                                  -64.330792,
                                  51.816784,
                                  0
                              ],
                              [
                                  -64.345756,
                                  51.97046,
                                  0
                              ],
                              [
                                  -64.260837,
                                  52.013656,
                                  0
                              ],
                              [
                                  -64.200554,
                                  52.176682,
                                  0
                              ],
                              [
                                  -64.225455,
                                  52.251629,
                                  0
                              ],
                              [
                                  -64.106923,
                                  52.40854,
                                  0
                              ],
                              [
                                  -64.147615,
                                  52.490961,
                                  0
                              ],
                              [
                                  -64.209498,
                                  52.564649,
                                  0
                              ],
                              [
                                  -64.068286,
                                  52.735331,
                                  0
                              ],
                              [
                                  -63.740249,
                                  52.771195,
                                  0
                              ],
                              [
                                  -63.617681,
                                  52.864998,
                                  0
                              ],
                              [
                                  -63.557131,
                                  52.941748,
                                  0
                              ],
                              [
                                  -63.464154,
                                  52.92097,
                                  0
                              ],
                              [
                                  -63.393473,
                                  52.859525,
                                  0
                              ],
                              [
                                  -63.234813,
                                  52.720635,
                                  0
                              ],
                              [
                                  -63.17874,
                                  52.786986,
                                  0
                              ],
                              [
                                  -63.052018,
                                  52.774881,
                                  0
                              ],
                              [
                                  -62.958929,
                                  52.758856,
                                  0
                              ],
                              [
                                  -62.839121,
                                  52.79852,
                                  0
                              ],
                              [
                                  -62.699758,
                                  52.780106,
                                  0
                              ],
                              [
                                  -62.721252,
                                  52.898109,
                                  0
                              ],
                              [
                                  -62.637483,
                                  52.929998,
                                  0
                              ],
                              [
                                  -62.504402,
                                  52.883135,
                                  0
                              ],
                              [
                                  -62.483001,
                                  52.81066,
                                  0
                              ],
                              [
                                  -62.272353,
                                  52.754295,
                                  0
                              ],
                              [
                                  -62.029813,
                                  52.741335,
                                  0
                              ],
                              [
                                  -61.875496,
                                  52.655012,
                                  0
                              ],
                              [
                                  -61.587417,
                                  52.553259,
                                  0
                              ],
                              [
                                  -61.459609,
                                  52.525647,
                                  0
                              ],
                              [
                                  -61.238218,
                                  52.507393,
                                  0
                              ],
                              [
                                  -60.979084,
                                  52.377555,
                                  0
                              ],
                              [
                                  -60.611912,
                                  52.311639,
                                  0
                              ],
                              [
                                  -60.603884,
                                  52.392277,
                                  0
                              ],
                              [
                                  -60.222855,
                                  52.460562,
                                  0
                              ],
                              [
                                  -60.075664,
                                  52.516887,
                                  0
                              ],
                              [
                                  -59.979197,
                                  52.451029,
                                  0
                              ],
                              [
                                  -59.853939,
                                  52.446249,
                                  0
                              ],
                              [
                                  -59.977277,
                                  52.53522,
                                  0
                              ],
                              [
                                  -59.874607,
                                  52.561673,
                                  0
                              ],
                              [
                                  -59.79726,
                                  52.678559,
                                  0
                              ],
                              [
                                  -59.691645,
                                  52.652217,
                                  0
                              ],
                              [
                                  -59.58806,
                                  52.590138,
                                  0
                              ],
                              [
                                  -59.454275,
                                  52.626161,
                                  0
                              ],
                              [
                                  -59.333921,
                                  52.618363,
                                  0
                              ],
                              [
                                  -59.177325,
                                  52.547184,
                                  0
                              ],
                              [
                                  -58.925576,
                                  52.475211,
                                  0
                              ],
                              [
                                  -58.838425,
                                  52.483378,
                                  0
                              ],
                              [
                                  -58.660102,
                                  52.465741,
                                  0
                              ],
                              [
                                  -58.671965,
                                  52.562663,
                                  0
                              ],
                              [
                                  -58.517978,
                                  52.658902,
                                  0
                              ],
                              [
                                  -58.43686,
                                  52.706258,
                                  0
                              ],
                              [
                                  -58.351558,
                                  52.698365,
                                  0
                              ],
                              [
                                  -58.215659,
                                  52.605145,
                                  0
                              ],
                              [
                                  -58.099783,
                                  52.56304,
                                  0
                              ],
                              [
                                  -58.033855,
                                  52.484316,
                                  0
                              ],
                              [
                                  -57.947515,
                                  52.426867,
                                  0
                              ],
                              [
                                  -57.821678,
                                  52.488057,
                                  0
                              ],
                              [
                                  -57.735647,
                                  52.47925,
                                  0
                              ],
                              [
                                  -57.729332,
                                  52.365671,
                                  0
                              ],
                              [
                                  -57.519752,
                                  52.224089,
                                  0
                              ],
                              [
                                  -57.415629,
                                  52.100803,
                                  0
                              ],
                              [
                                  -57.327648,
                                  52.061884,
                                  0
                              ],
                              [
                                  -57.23483,
                                  52.082049,
                                  0
                              ],
                              [
                                  -57.124365,
                                  52.065965,
                                  0
                              ],
                              [
                                  -57.027832,
                                  52.101631,
                                  0
                              ],
                              [
                                  -56.937516,
                                  51.987693,
                                  0
                              ],
                              [
                                  -57.067319,
                                  51.898073,
                                  0
                              ],
                              [
                                  -57.108076,
                                  51.749804,
                                  0
                              ],
                              [
                                  -57.108126,
                                  51.591605,
                                  0
                              ],
                              [
                                  -57.110131,
                                  51.369656,
                                  0
                              ],
                              [
                                  -57.11862,
                                  51.198881,
                                  0
                              ],
                              [
                                  -57.736136,
                                  50.998606,
                                  0
                              ],
                              [
                                  -60.476814,
                                  48.781389,
                                  0
                              ],
                              [
                                  -60.468604,
                                  48.66667,
                                  0
                              ],
                              [
                                  -62.999128,
                                  48.666681,
                                  0
                              ],
                              [
                                  -62.894936,
                                  48.813485,
                                  0
                              ],
                              [
                                  -64.396744,
                                  49.584691,
                                  0
                              ],
                              [
                                  -64.993633,
                                  49.766223,
                                  0
                              ],
                              [
                                  -65.718651,
                                  49.831113,
                                  0
                              ],
                              [
                                  -66.387998,
                                  49.647255,
                                  0
                              ],
                              [
                                  -66.999279,
                                  49.279233,
                                  0
                              ],
                              [
                                  -66.999278,
                                  49.586593,
                                  0
                              ],
                              [
                                  -67.58613,
                                  49.586164,
                                  0
                              ],
                              [
                                  -67.601838,
                                  49.708582,
                                  0
                              ],
                              [
                                  -67.558046,
                                  49.762082,
                                  0
                              ],
                              [
                                  -67.518824,
                                  49.833433,
                                  0
                              ],
                              [
                                  -67.655693,
                                  49.897213,
                                  0
                              ],
                              [
                                  -67.734551,
                                  49.877705,
                                  0
                              ],
                              [
                                  -67.716119,
                                  49.941017,
                                  0
                              ],
                              [
                                  -67.603718,
                                  50.04123,
                                  0
                              ],
                              [
                                  -67.706306,
                                  50.32324,
                                  0
                              ],
                              [
                                  -67.866471,
                                  50.323344,
                                  0
                              ],
                              [
                                  -67.89237,
                                  50.379886,
                                  0
                              ],
                              [
                                  -67.829968,
                                  50.474312,
                                  0
                              ],
                              [
                                  -67.81829,
                                  50.529313,
                                  0
                              ],
                              [
                                  -67.756924,
                                  50.635239,
                                  0
                              ],
                              [
                                  -67.76752,
                                  50.686537,
                                  0
                              ],
                              [
                                  -67.722623,
                                  50.741635,
                                  0
                              ],
                              [
                                  -67.73812,
                                  50.844505,
                                  0
                              ],
                              [
                                  -67.706481,
                                  50.893954,
                                  0
                              ],
                              [
                                  -67.693168,
                                  50.963287,
                                  0
                              ],
                              [
                                  -67.645002,
                                  51.007621,
                                  0
                              ],
                              [
                                  -67.746518,
                                  51.108562,
                                  0
                              ],
                              [
                                  -67.826918,
                                  51.158485,
                                  0
                              ],
                              [
                                  -67.852561,
                                  51.266213,
                                  0
                              ],
                              [
                                  -67.917624,
                                  51.298662,
                                  0
                              ],
                              [
                                  -67.872245,
                                  51.354141,
                                  0
                              ],
                              [
                                  -67.856979,
                                  51.406535,
                                  0
                              ],
                              [
                                  -67.916896,
                                  51.450194,
                                  0
                              ],
                              [
                                  -67.916904,
                                  51.563678,
                                  0
                              ],
                              [
                                  -70.026322,
                                  51.564049,
                                  0
                              ],
                              [
                                  -70.02991,
                                  52.83262,
                                  0
                              ],
                              [
                                  -70.02989,
                                  52.916604,
                                  0
                              ],
                              [
                                  -69.999333,
                                  55.000138,
                                  0
                              ],
                              [
                                  -67.417237,
                                  55.000132,
                                  0
                              ]
                          ]
                      ]
                  }
              ]
          },
          "properties": {
              "name": "Duplessis",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -78.540954,
                          48.286864,
                          0
                      ],
                      [
                          -78.649613,
                          48.287085,
                          0
                      ],
                      [
                          -78.64952,
                          48.229558,
                          0
                      ],
                      [
                          -78.637029,
                          48.223726,
                          0
                      ],
                      [
                          -78.649567,
                          48.212827,
                          0
                      ],
                      [
                          -78.652811,
                          47.996069,
                          0
                      ],
                      [
                          -78.694003,
                          47.996185,
                          0
                      ],
                      [
                          -78.690253,
                          47.953326,
                          0
                      ],
                      [
                          -78.657776,
                          47.921611,
                          0
                      ],
                      [
                          -78.692357,
                          47.906695,
                          0
                      ],
                      [
                          -78.703934,
                          47.880165,
                          0
                      ],
                      [
                          -78.653038,
                          47.880175,
                          0
                      ],
                      [
                          -78.654691,
                          47.703294,
                          0
                      ],
                      [
                          -77.421979,
                          47.702623,
                          0
                      ],
                      [
                          -77.467357,
                          47.643276,
                          0
                      ],
                      [
                          -77.433372,
                          47.590935,
                          0
                      ],
                      [
                          -77.477462,
                          47.599985,
                          0
                      ],
                      [
                          -77.493402,
                          47.544858,
                          0
                      ],
                      [
                          -77.456109,
                          47.538188,
                          0
                      ],
                      [
                          -77.447864,
                          47.502421,
                          0
                      ],
                      [
                          -77.430775,
                          47.488505,
                          0
                      ],
                      [
                          -77.483474,
                          47.514932,
                          0
                      ],
                      [
                          -77.479105,
                          47.49455,
                          0
                      ],
                      [
                          -77.512271,
                          47.499737,
                          0
                      ],
                      [
                          -77.526119,
                          47.484196,
                          0
                      ],
                      [
                          -77.510876,
                          47.469428,
                          0
                      ],
                      [
                          -77.562636,
                          47.474404,
                          0
                      ],
                      [
                          -77.566108,
                          47.45001,
                          0
                      ],
                      [
                          -77.60914,
                          47.433891,
                          0
                      ],
                      [
                          -77.610916,
                          47.408825,
                          0
                      ],
                      [
                          -77.656141,
                          47.425612,
                          0
                      ],
                      [
                          -77.768105,
                          47.418234,
                          0
                      ],
                      [
                          -77.764637,
                          47.39566,
                          0
                      ],
                      [
                          -77.790057,
                          47.390412,
                          0
                      ],
                      [
                          -77.797989,
                          47.367648,
                          0
                      ],
                      [
                          -77.829406,
                          47.337968,
                          0
                      ],
                      [
                          -77.826421,
                          47.318492,
                          0
                      ],
                      [
                          -77.86078,
                          47.324926,
                          0
                      ],
                      [
                          -77.856549,
                          47.307145,
                          0
                      ],
                      [
                          -77.887265,
                          47.301549,
                          0
                      ],
                      [
                          -77.918796,
                          47.285382,
                          0
                      ],
                      [
                          -77.931267,
                          47.269377,
                          0
                      ],
                      [
                          -77.901179,
                          47.243273,
                          0
                      ],
                      [
                          -77.866839,
                          47.242895,
                          0
                      ],
                      [
                          -77.856629,
                          47.225686,
                          0
                      ],
                      [
                          -77.852022,
                          47.198344,
                          0
                      ],
                      [
                          -77.850871,
                          47.152371,
                          0
                      ],
                      [
                          -77.845712,
                          47.11418,
                          0
                      ],
                      [
                          -77.827856,
                          47.077801,
                          0
                      ],
                      [
                          -77.829706,
                          47.05877,
                          0
                      ],
                      [
                          -77.841249,
                          47.041881,
                          0
                      ],
                      [
                          -77.846715,
                          47.023192,
                          0
                      ],
                      [
                          -77.834889,
                          47.005987,
                          0
                      ],
                      [
                          -77.844455,
                          46.979447,
                          0
                      ],
                      [
                          -77.842891,
                          46.951333,
                          0
                      ],
                      [
                          -77.835127,
                          46.922455,
                          0
                      ],
                      [
                          -77.841484,
                          46.903822,
                          0
                      ],
                      [
                          -77.844194,
                          46.88585,
                          0
                      ],
                      [
                          -77.844671,
                          46.905225,
                          0
                      ],
                      [
                          -77.845411,
                          46.924119,
                          0
                      ],
                      [
                          -77.856917,
                          46.940691,
                          0
                      ],
                      [
                          -77.872959,
                          46.905877,
                          0
                      ],
                      [
                          -77.88649,
                          46.889148,
                          0
                      ],
                      [
                          -77.884805,
                          46.860402,
                          0
                      ],
                      [
                          -77.877331,
                          46.827836,
                          0
                      ],
                      [
                          -77.851638,
                          46.821608,
                          0
                      ],
                      [
                          -77.82528,
                          46.823807,
                          0
                      ],
                      [
                          -77.823334,
                          46.805424,
                          0
                      ],
                      [
                          -77.834515,
                          46.771547,
                          0
                      ],
                      [
                          -77.829526,
                          46.743684,
                          0
                      ],
                      [
                          -77.818676,
                          46.707104,
                          0
                      ],
                      [
                          -77.858001,
                          46.660825,
                          0
                      ],
                      [
                          -77.846429,
                          46.635782,
                          0
                      ],
                      [
                          -77.826501,
                          46.622018,
                          0
                      ],
                      [
                          -77.789007,
                          46.606175,
                          0
                      ],
                      [
                          -77.777978,
                          46.589262,
                          0
                      ],
                      [
                          -77.780466,
                          46.555199,
                          0
                      ],
                      [
                          -77.772245,
                          46.531688,
                          0
                      ],
                      [
                          -77.756348,
                          46.506724,
                          0
                      ],
                      [
                          -77.761528,
                          46.488373,
                          0
                      ],
                      [
                          -77.766422,
                          46.459508,
                          0
                      ],
                      [
                          -77.759402,
                          46.439811,
                          0
                      ],
                      [
                          -77.747336,
                          46.415953,
                          0
                      ],
                      [
                          -77.742731,
                          46.398113,
                          0
                      ],
                      [
                          -77.7869,
                          46.365496,
                          0
                      ],
                      [
                          -77.820563,
                          46.337914,
                          0
                      ],
                      [
                          -77.851296,
                          46.278372,
                          0
                      ],
                      [
                          -77.854499,
                          46.23228,
                          0
                      ],
                      [
                          -77.841601,
                          46.206373,
                          0
                      ],
                      [
                          -77.927295,
                          46.225494,
                          0
                      ],
                      [
                          -78.009331,
                          46.247092,
                          0
                      ],
                      [
                          -78.032252,
                          46.237814,
                          0
                      ],
                      [
                          -78.066855,
                          46.244645,
                          0
                      ],
                      [
                          -78.156787,
                          46.276263,
                          0
                      ],
                      [
                          -78.229924,
                          46.273015,
                          0
                      ],
                      [
                          -78.257953,
                          46.274674,
                          0
                      ],
                      [
                          -78.296537,
                          46.256766,
                          0
                      ],
                      [
                          -78.339702,
                          46.262067,
                          0
                      ],
                      [
                          -78.388233,
                          46.291239,
                          0
                      ],
                      [
                          -78.51313,
                          46.296873,
                          0
                      ],
                      [
                          -78.621933,
                          46.319736,
                          0
                      ],
                      [
                          -78.688847,
                          46.318148,
                          0
                      ],
                      [
                          -78.728738,
                          46.341736,
                          0
                      ],
                      [
                          -78.722348,
                          46.368292,
                          0
                      ],
                      [
                          -78.766115,
                          46.400436,
                          0
                      ],
                      [
                          -78.85716,
                          46.442539,
                          0
                      ],
                      [
                          -78.9285,
                          46.493406,
                          0
                      ],
                      [
                          -78.983967,
                          46.543738,
                          0
                      ],
                      [
                          -78.99396,
                          46.591838,
                          0
                      ],
                      [
                          -79.015331,
                          46.630544,
                          0
                      ],
                      [
                          -79.068274,
                          46.662826,
                          0
                      ],
                      [
                          -79.120943,
                          46.74093,
                          0
                      ],
                      [
                          -79.151222,
                          46.796543,
                          0
                      ],
                      [
                          -79.168303,
                          46.822712,
                          0
                      ],
                      [
                          -79.225425,
                          46.843771,
                          0
                      ],
                      [
                          -79.287576,
                          46.906554,
                          0
                      ],
                      [
                          -79.336468,
                          46.969302,
                          0
                      ],
                      [
                          -79.366765,
                          47.022801,
                          0
                      ],
                      [
                          -79.440665,
                          47.099471,
                          0
                      ],
                      [
                          -79.432015,
                          47.248745,
                          0
                      ],
                      [
                          -79.510072,
                          47.341579,
                          0
                      ],
                      [
                          -79.560468,
                          47.38501,
                          0
                      ],
                      [
                          -79.579237,
                          47.431755,
                          0
                      ],
                      [
                          -79.553331,
                          47.519689,
                          0
                      ],
                      [
                          -79.515928,
                          47.549845,
                          0
                      ],
                      [
                          -79.517499,
                          48.430523,
                          0
                      ],
                      [
                          -79.082354,
                          48.429994,
                          0
                      ],
                      [
                          -79.082407,
                          48.56104,
                          0
                      ],
                      [
                          -79.02707,
                          48.561101,
                          0
                      ],
                      [
                          -79.02718,
                          48.574939,
                          0
                      ],
                      [
                          -78.864715,
                          48.57492,
                          0
                      ],
                      [
                          -78.864859,
                          48.561512,
                          0
                      ],
                      [
                          -78.787839,
                          48.561088,
                          0
                      ],
                      [
                          -78.787872,
                          48.553965,
                          0
                      ],
                      [
                          -78.710321,
                          48.554268,
                          0
                      ],
                      [
                          -78.710376,
                          48.570163,
                          0
                      ],
                      [
                          -78.647141,
                          48.574962,
                          0
                      ],
                      [
                          -78.646816,
                          48.43053,
                          0
                      ],
                      [
                          -78.54058,
                          48.430406,
                          0
                      ],
                      [
                          -78.540954,
                          48.286864,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Rouyn-Noranda-Témiscamingue",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -74.710174,
                          45.931876,
                          0
                      ],
                      [
                          -74.680216,
                          45.929796,
                          0
                      ],
                      [
                          -74.670617,
                          45.929208,
                          0
                      ],
                      [
                          -74.673348,
                          45.937829,
                          0
                      ],
                      [
                          -74.656465,
                          45.95097,
                          0
                      ],
                      [
                          -74.652089,
                          45.959456,
                          0
                      ],
                      [
                          -74.642258,
                          45.965613,
                          0
                      ],
                      [
                          -74.63239,
                          45.971461,
                          0
                      ],
                      [
                          -74.625865,
                          45.979319,
                          0
                      ],
                      [
                          -74.633442,
                          45.986797,
                          0
                      ],
                      [
                          -74.633949,
                          46.00493,
                          0
                      ],
                      [
                          -74.628536,
                          46.013609,
                          0
                      ],
                      [
                          -74.629329,
                          46.022801,
                          0
                      ],
                      [
                          -74.620899,
                          46.029997,
                          0
                      ],
                      [
                          -74.613507,
                          46.037432,
                          0
                      ],
                      [
                          -74.623389,
                          46.043248,
                          0
                      ],
                      [
                          -74.616555,
                          46.047827,
                          0
                      ],
                      [
                          -74.627433,
                          46.052581,
                          0
                      ],
                      [
                          -74.61638,
                          46.051122,
                          0
                      ],
                      [
                          -74.571071,
                          46.03894,
                          0
                      ],
                      [
                          -74.517484,
                          46.034284,
                          0
                      ],
                      [
                          -74.480384,
                          46.029272,
                          0
                      ],
                      [
                          -74.430726,
                          46.026809,
                          0
                      ],
                      [
                          -74.412674,
                          46.019169,
                          0
                      ],
                      [
                          -74.412665,
                          46.019166,
                          0
                      ],
                      [
                          -74.40763,
                          46.018673,
                          0
                      ],
                      [
                          -74.261935,
                          45.999758,
                          0
                      ],
                      [
                          -74.262006,
                          45.999547,
                          0
                      ],
                      [
                          -74.271679,
                          45.965544,
                          0
                      ],
                      [
                          -74.192133,
                          45.910871,
                          0
                      ],
                      [
                          -74.192079,
                          45.91083,
                          0
                      ],
                      [
                          -74.263312,
                          45.859645,
                          0
                      ],
                      [
                          -74.254867,
                          45.857505,
                          0
                      ],
                      [
                          -74.226446,
                          45.847604,
                          0
                      ],
                      [
                          -74.226681,
                          45.846745,
                          0
                      ],
                      [
                          -74.227171,
                          45.845016,
                          0
                      ],
                      [
                          -74.224374,
                          45.846179,
                          0
                      ],
                      [
                          -74.224418,
                          45.847107,
                          0
                      ],
                      [
                          -74.220281,
                          45.84553,
                          0
                      ],
                      [
                          -74.220295,
                          45.845331,
                          0
                      ],
                      [
                          -74.22035,
                          45.844729,
                          0
                      ],
                      [
                          -74.219859,
                          45.844507,
                          0
                      ],
                      [
                          -74.219843,
                          45.84423,
                          0
                      ],
                      [
                          -74.21914,
                          45.845148,
                          0
                      ],
                      [
                          -74.143336,
                          45.819718,
                          0
                      ],
                      [
                          -74.12257,
                          45.811486,
                          0
                      ],
                      [
                          -74.118973,
                          45.809651,
                          0
                      ],
                      [
                          -74.115751,
                          45.810229,
                          0
                      ],
                      [
                          -74.11429,
                          45.809289,
                          0
                      ],
                      [
                          -74.113962,
                          45.808073,
                          0
                      ],
                      [
                          -74.113201,
                          45.806799,
                          0
                      ],
                      [
                          -74.110823,
                          45.803792,
                          0
                      ],
                      [
                          -74.12286,
                          45.802648,
                          0
                      ],
                      [
                          -74.121432,
                          45.800722,
                          0
                      ],
                      [
                          -74.116207,
                          45.799781,
                          0
                      ],
                      [
                          -74.115567,
                          45.798225,
                          0
                      ],
                      [
                          -74.112298,
                          45.796729,
                          0
                      ],
                      [
                          -74.111926,
                          45.795623,
                          0
                      ],
                      [
                          -74.109479,
                          45.794284,
                          0
                      ],
                      [
                          -74.109752,
                          45.793198,
                          0
                      ],
                      [
                          -74.106506,
                          45.790596,
                          0
                      ],
                      [
                          -74.105585,
                          45.787203,
                          0
                      ],
                      [
                          -74.105049,
                          45.78517,
                          0
                      ],
                      [
                          -74.1062,
                          45.783037,
                          0
                      ],
                      [
                          -74.121414,
                          45.781293,
                          0
                      ],
                      [
                          -74.039016,
                          45.763668,
                          0
                      ],
                      [
                          -74.05211,
                          45.757823,
                          0
                      ],
                      [
                          -74.074271,
                          45.748234,
                          0
                      ],
                      [
                          -74.057422,
                          45.736625,
                          0
                      ],
                      [
                          -74.057425,
                          45.736623,
                          0
                      ],
                      [
                          -74.057643,
                          45.731292,
                          0
                      ],
                      [
                          -74.062081,
                          45.730852,
                          0
                      ],
                      [
                          -74.065313,
                          45.732454,
                          0
                      ],
                      [
                          -74.076596,
                          45.726208,
                          0
                      ],
                      [
                          -74.079341,
                          45.72593,
                          0
                      ],
                      [
                          -74.080429,
                          45.724161,
                          0
                      ],
                      [
                          -74.084938,
                          45.722701,
                          0
                      ],
                      [
                          -74.086312,
                          45.720885,
                          0
                      ],
                      [
                          -74.089909,
                          45.71853,
                          0
                      ],
                      [
                          -74.091761,
                          45.712235,
                          0
                      ],
                      [
                          -74.103612,
                          45.705989,
                          0
                      ],
                      [
                          -74.103615,
                          45.705987,
                          0
                      ],
                      [
                          -74.106753,
                          45.703464,
                          0
                      ],
                      [
                          -74.117573,
                          45.699218,
                          0
                      ],
                      [
                          -74.119886,
                          45.699963,
                          0
                      ],
                      [
                          -74.123965,
                          45.699565,
                          0
                      ],
                      [
                          -74.127795,
                          45.697663,
                          0
                      ],
                      [
                          -74.128539,
                          45.697763,
                          0
                      ],
                      [
                          -74.129469,
                          45.700652,
                          0
                      ],
                      [
                          -74.133347,
                          45.700194,
                          0
                      ],
                      [
                          -74.141793,
                          45.697555,
                          0
                      ],
                      [
                          -74.149008,
                          45.695349,
                          0
                      ],
                      [
                          -74.153761,
                          45.693055,
                          0
                      ],
                      [
                          -74.162839,
                          45.69043,
                          0
                      ],
                      [
                          -74.16587,
                          45.68886,
                          0
                      ],
                      [
                          -74.170399,
                          45.68885,
                          0
                      ],
                      [
                          -74.180757,
                          45.690985,
                          0
                      ],
                      [
                          -74.180758,
                          45.690985,
                          0
                      ],
                      [
                          -74.183192,
                          45.690381,
                          0
                      ],
                      [
                          -74.185374,
                          45.688972,
                          0
                      ],
                      [
                          -74.186206,
                          45.687477,
                          0
                      ],
                      [
                          -74.185449,
                          45.685311,
                          0
                      ],
                      [
                          -74.187041,
                          45.684961,
                          0
                      ],
                      [
                          -74.188404,
                          45.686527,
                          0
                      ],
                      [
                          -74.192457,
                          45.687849,
                          0
                      ],
                      [
                          -74.197579,
                          45.685567,
                          0
                      ],
                      [
                          -74.198636,
                          45.683547,
                          0
                      ],
                      [
                          -74.197348,
                          45.681634,
                          0
                      ],
                      [
                          -74.200672,
                          45.681859,
                          0
                      ],
                      [
                          -74.205776,
                          45.682594,
                          0
                      ],
                      [
                          -74.206252,
                          45.681295,
                          0
                      ],
                      [
                          -74.202019,
                          45.677941,
                          0
                      ],
                      [
                          -74.203024,
                          45.6754,
                          0
                      ],
                      [
                          -74.212092,
                          45.670586,
                          0
                      ],
                      [
                          -74.208002,
                          45.687224,
                          0
                      ],
                      [
                          -74.210551,
                          45.686647,
                          0
                      ],
                      [
                          -74.210283,
                          45.687682,
                          0
                      ],
                      [
                          -74.215336,
                          45.686546,
                          0
                      ],
                      [
                          -74.211905,
                          45.70043,
                          0
                      ],
                      [
                          -74.223504,
                          45.699262,
                          0
                      ],
                      [
                          -74.223527,
                          45.699259,
                          0
                      ],
                      [
                          -74.226414,
                          45.687777,
                          0
                      ],
                      [
                          -74.233697,
                          45.694666,
                          0
                      ],
                      [
                          -74.23742,
                          45.697934,
                          0
                      ],
                      [
                          -74.237485,
                          45.697007,
                          0
                      ],
                      [
                          -74.240646,
                          45.696263,
                          0
                      ],
                      [
                          -74.2422,
                          45.696659,
                          0
                      ],
                      [
                          -74.242216,
                          45.696642,
                          0
                      ],
                      [
                          -74.242962,
                          45.695794,
                          0
                      ],
                      [
                          -74.2396,
                          45.692234,
                          0
                      ],
                      [
                          -74.240527,
                          45.692335,
                          0
                      ],
                      [
                          -74.243297,
                          45.690117,
                          0
                      ],
                      [
                          -74.24065,
                          45.687493,
                          0
                      ],
                      [
                          -74.248335,
                          45.683663,
                          0
                      ],
                      [
                          -74.248984,
                          45.684328,
                          0
                      ],
                      [
                          -74.250922,
                          45.683388,
                          0
                      ],
                      [
                          -74.255021,
                          45.687481,
                          0
                      ],
                      [
                          -74.25843,
                          45.685603,
                          0
                      ],
                      [
                          -74.263286,
                          45.684476,
                          0
                      ],
                      [
                          -74.261573,
                          45.682701,
                          0
                      ],
                      [
                          -74.265566,
                          45.680702,
                          0
                      ],
                      [
                          -74.25626,
                          45.671246,
                          0
                      ],
                      [
                          -74.252529,
                          45.664981,
                          0
                      ],
                      [
                          -74.250782,
                          45.663884,
                          0
                      ],
                      [
                          -74.249526,
                          45.664686,
                          0
                      ],
                      [
                          -74.245447,
                          45.646825,
                          0
                      ],
                      [
                          -74.262628,
                          45.649312,
                          0
                      ],
                      [
                          -74.267606,
                          45.632033,
                          0
                      ],
                      [
                          -74.222818,
                          45.625863,
                          0
                      ],
                      [
                          -74.241985,
                          45.551528,
                          0
                      ],
                      [
                          -74.244016,
                          45.551672,
                          0
                      ],
                      [
                          -74.245917,
                          45.553125,
                          0
                      ],
                      [
                          -74.249144,
                          45.553477,
                          0
                      ],
                      [
                          -74.249394,
                          45.553099,
                          0
                      ],
                      [
                          -74.250839,
                          45.552651,
                          0
                      ],
                      [
                          -74.253298,
                          45.552468,
                          0
                      ],
                      [
                          -74.260507,
                          45.550645,
                          0
                      ],
                      [
                          -74.260513,
                          45.550645,
                          0
                      ],
                      [
                          -74.265737,
                          45.548678,
                          0
                      ],
                      [
                          -74.2672,
                          45.543369,
                          0
                      ],
                      [
                          -74.274255,
                          45.542988,
                          0
                      ],
                      [
                          -74.279735,
                          45.523522,
                          0
                      ],
                      [
                          -74.270279,
                          45.524734,
                          0
                      ],
                      [
                          -74.257006,
                          45.525458,
                          0
                      ],
                      [
                          -74.2486,
                          45.523822,
                          0
                      ],
                      [
                          -74.246799,
                          45.523,
                          0
                      ],
                      [
                          -74.245748,
                          45.522436,
                          0
                      ],
                      [
                          -74.24575,
                          45.502682,
                          0
                      ],
                      [
                          -74.24575,
                          45.50268,
                          0
                      ],
                      [
                          -74.245753,
                          45.50268,
                          0
                      ],
                      [
                          -74.245762,
                          45.502681,
                          0
                      ],
                      [
                          -74.267836,
                          45.503834,
                          0
                      ],
                      [
                          -74.294172,
                          45.50503,
                          0
                      ],
                      [
                          -74.315689,
                          45.504537,
                          0
                      ],
                      [
                          -74.335904,
                          45.510742,
                          0
                      ],
                      [
                          -74.340327,
                          45.51526,
                          0
                      ],
                      [
                          -74.355309,
                          45.546819,
                          0
                      ],
                      [
                          -74.366658,
                          45.557152,
                          0
                      ],
                      [
                          -74.366712,
                          45.557195,
                          0
                      ],
                      [
                          -74.380892,
                          45.565548,
                          0
                      ],
                      [
                          -74.380894,
                          45.565543,
                          0
                      ],
                      [
                          -74.381344,
                          45.564021,
                          0
                      ],
                      [
                          -74.381345,
                          45.564016,
                          0
                      ],
                      [
                          -74.383284,
                          45.565045,
                          0
                      ],
                      [
                          -74.383289,
                          45.565047,
                          0
                      ],
                      [
                          -74.441669,
                          45.578545,
                          0
                      ],
                      [
                          -74.46906,
                          45.592659,
                          0
                      ],
                      [
                          -74.484511,
                          45.596209,
                          0
                      ],
                      [
                          -74.499338,
                          45.592547,
                          0
                      ],
                      [
                          -74.51965,
                          45.591111,
                          0
                      ],
                      [
                          -74.534221,
                          45.592671,
                          0
                      ],
                      [
                          -74.579822,
                          45.611544,
                          0
                      ],
                      [
                          -74.616145,
                          45.623605,
                          0
                      ],
                      [
                          -74.616146,
                          45.623606,
                          0
                      ],
                      [
                          -74.633213,
                          45.635021,
                          0
                      ],
                      [
                          -74.659124,
                          45.63624,
                          0
                      ],
                      [
                          -74.701605,
                          45.630633,
                          0
                      ],
                      [
                          -74.701607,
                          45.630633,
                          0
                      ],
                      [
                          -74.70161,
                          45.630633,
                          0
                      ],
                      [
                          -74.783937,
                          45.638801,
                          0
                      ],
                      [
                          -74.807914,
                          45.638303,
                          0
                      ],
                      [
                          -74.80792,
                          45.638302,
                          0
                      ],
                      [
                          -74.807918,
                          45.638306,
                          0
                      ],
                      [
                          -74.740655,
                          45.834794,
                          0
                      ],
                      [
                          -74.735402,
                          45.851866,
                          0
                      ],
                      [
                          -74.735397,
                          45.851866,
                          0
                      ],
                      [
                          -74.726673,
                          45.851736,
                          0
                      ],
                      [
                          -74.72667,
                          45.851736,
                          0
                      ],
                      [
                          -74.714373,
                          45.89009,
                          0
                      ],
                      [
                          -74.711867,
                          45.912885,
                          0
                      ],
                      [
                          -74.710174,
                          45.931876,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Argenteuil",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -75.610111,
                          45.511233,
                          0
                      ],
                      [
                          -75.588611,
                          45.511324,
                          0
                      ],
                      [
                          -75.550013,
                          45.51449,
                          0
                      ],
                      [
                          -75.543175,
                          45.516472,
                          0
                      ],
                      [
                          -75.529224,
                          45.521802,
                          0
                      ],
                      [
                          -75.536288,
                          45.570076,
                          0
                      ],
                      [
                          -75.541422,
                          45.603833,
                          0
                      ],
                      [
                          -75.54473,
                          45.624579,
                          0
                      ],
                      [
                          -75.544785,
                          45.624771,
                          0
                      ],
                      [
                          -75.556272,
                          45.70141,
                          0
                      ],
                      [
                          -75.556275,
                          45.70143,
                          0
                      ],
                      [
                          -75.527944,
                          45.703709,
                          0
                      ],
                      [
                          -75.53427,
                          45.706365,
                          0
                      ],
                      [
                          -75.546849,
                          45.708527,
                          0
                      ],
                      [
                          -75.559247,
                          45.713906,
                          0
                      ],
                      [
                          -75.585668,
                          45.719014,
                          0
                      ],
                      [
                          -75.591181,
                          45.72723,
                          0
                      ],
                      [
                          -75.57894,
                          45.74296,
                          0
                      ],
                      [
                          -75.579226,
                          45.752136,
                          0
                      ],
                      [
                          -75.584525,
                          45.760553,
                          0
                      ],
                      [
                          -75.59712,
                          45.777338,
                          0
                      ],
                      [
                          -75.605372,
                          45.784431,
                          0
                      ],
                      [
                          -75.607585,
                          45.793292,
                          0
                      ],
                      [
                          -75.614458,
                          45.802322,
                          0
                      ],
                      [
                          -75.63142,
                          45.816284,
                          0
                      ],
                      [
                          -75.641851,
                          45.833827,
                          0
                      ],
                      [
                          -75.711639,
                          45.828243,
                          0
                      ],
                      [
                          -75.736362,
                          45.825063,
                          0
                      ],
                      [
                          -75.737575,
                          45.845694,
                          0
                      ],
                      [
                          -75.737124,
                          45.891737,
                          0
                      ],
                      [
                          -75.736867,
                          45.913061,
                          0
                      ],
                      [
                          -75.742045,
                          45.915313,
                          0
                      ],
                      [
                          -75.746924,
                          45.92371,
                          0
                      ],
                      [
                          -75.761118,
                          45.938649,
                          0
                      ],
                      [
                          -75.768493,
                          45.940853,
                          0
                      ],
                      [
                          -75.763436,
                          45.94579,
                          0
                      ],
                      [
                          -75.776019,
                          45.951556,
                          0
                      ],
                      [
                          -75.777257,
                          45.952886,
                          0
                      ],
                      [
                          -75.77725,
                          45.95289,
                          0
                      ],
                      [
                          -75.77443,
                          45.953817,
                          0
                      ],
                      [
                          -75.771964,
                          45.954628,
                          0
                      ],
                      [
                          -75.759334,
                          45.960358,
                          0
                      ],
                      [
                          -75.746307,
                          45.972927,
                          0
                      ],
                      [
                          -75.737403,
                          45.970215,
                          0
                      ],
                      [
                          -75.666035,
                          45.970303,
                          0
                      ],
                      [
                          -75.662484,
                          45.973319,
                          0
                      ],
                      [
                          -75.662479,
                          45.982457,
                          0
                      ],
                      [
                          -75.620431,
                          45.982396,
                          0
                      ],
                      [
                          -75.62037,
                          45.994414,
                          0
                      ],
                      [
                          -75.599868,
                          45.994402,
                          0
                      ],
                      [
                          -75.59879,
                          45.971413,
                          0
                      ],
                      [
                          -75.577126,
                          45.972091,
                          0
                      ],
                      [
                          -75.408285,
                          45.973214,
                          0
                      ],
                      [
                          -75.408303,
                          46.097614,
                          0
                      ],
                      [
                          -75.213184,
                          46.098071,
                          0
                      ],
                      [
                          -75.210629,
                          46.103259,
                          0
                      ],
                      [
                          -75.176356,
                          46.103407,
                          0
                      ],
                      [
                          -75.176985,
                          46.132306,
                          0
                      ],
                      [
                          -75.155932,
                          46.132296,
                          0
                      ],
                      [
                          -75.156313,
                          46.160603,
                          0
                      ],
                      [
                          -75.137272,
                          46.160567,
                          0
                      ],
                      [
                          -75.137641,
                          46.184942,
                          0
                      ],
                      [
                          -75.116555,
                          46.184782,
                          0
                      ],
                      [
                          -75.11739,
                          46.215203,
                          0
                      ],
                      [
                          -75.075233,
                          46.215283,
                          0
                      ],
                      [
                          -75.084856,
                          46.13992,
                          0
                      ],
                      [
                          -75.089903,
                          46.103251,
                          0
                      ],
                      [
                          -74.939544,
                          46.105534,
                          0
                      ],
                      [
                          -74.939451,
                          46.061729,
                          0
                      ],
                      [
                          -74.871333,
                          46.062474,
                          0
                      ],
                      [
                          -74.858618,
                          46.060743,
                          0
                      ],
                      [
                          -74.857972,
                          46.016768,
                          0
                      ],
                      [
                          -74.837629,
                          46.01677,
                          0
                      ],
                      [
                          -74.837606,
                          46.003052,
                          0
                      ],
                      [
                          -74.831384,
                          46.00306,
                          0
                      ],
                      [
                          -74.831433,
                          45.97312,
                          0
                      ],
                      [
                          -74.808901,
                          45.973024,
                          0
                      ],
                      [
                          -74.806466,
                          45.958619,
                          0
                      ],
                      [
                          -74.800906,
                          45.958901,
                          0
                      ],
                      [
                          -74.801723,
                          45.944758,
                          0
                      ],
                      [
                          -74.764242,
                          45.946256,
                          0
                      ],
                      [
                          -74.763468,
                          45.960396,
                          0
                      ],
                      [
                          -74.76322,
                          45.972747,
                          0
                      ],
                      [
                          -74.698244,
                          45.971775,
                          0
                      ],
                      [
                          -74.706597,
                          45.945891,
                          0
                      ],
                      [
                          -74.71029,
                          45.93188,
                          0
                      ],
                      [
                          -74.710204,
                          45.931877,
                          0
                      ],
                      [
                          -74.710174,
                          45.931876,
                          0
                      ],
                      [
                          -74.711867,
                          45.912885,
                          0
                      ],
                      [
                          -74.714373,
                          45.89009,
                          0
                      ],
                      [
                          -74.72667,
                          45.851736,
                          0
                      ],
                      [
                          -74.726673,
                          45.851736,
                          0
                      ],
                      [
                          -74.735397,
                          45.851866,
                          0
                      ],
                      [
                          -74.735402,
                          45.851866,
                          0
                      ],
                      [
                          -74.740655,
                          45.834794,
                          0
                      ],
                      [
                          -74.807918,
                          45.638306,
                          0
                      ],
                      [
                          -74.80792,
                          45.638302,
                          0
                      ],
                      [
                          -74.808001,
                          45.638302,
                          0
                      ],
                      [
                          -74.848561,
                          45.63747,
                          0
                      ],
                      [
                          -74.932805,
                          45.645442,
                          0
                      ],
                      [
                          -74.981262,
                          45.612968,
                          0
                      ],
                      [
                          -75.036619,
                          45.593873,
                          0
                      ],
                      [
                          -75.07529,
                          45.590679,
                          0
                      ],
                      [
                          -75.130672,
                          45.577105,
                          0
                      ],
                      [
                          -75.168929,
                          45.577137,
                          0
                      ],
                      [
                          -75.235121,
                          45.587203,
                          0
                      ],
                      [
                          -75.235233,
                          45.587189,
                          0
                      ],
                      [
                          -75.270381,
                          45.571821,
                          0
                      ],
                      [
                          -75.301744,
                          45.55607,
                          0
                      ],
                      [
                          -75.325234,
                          45.543693,
                          0
                      ],
                      [
                          -75.399137,
                          45.523909,
                          0
                      ],
                      [
                          -75.429573,
                          45.520854,
                          0
                      ],
                      [
                          -75.492886,
                          45.511049,
                          0
                      ],
                      [
                          -75.531219,
                          45.498973,
                          0
                      ],
                      [
                          -75.536499,
                          45.496055,
                          0
                      ],
                      [
                          -75.548468,
                          45.484745,
                          0
                      ],
                      [
                          -75.574494,
                          45.475456,
                          0
                      ],
                      [
                          -75.605585,
                          45.471272,
                          0
                      ],
                      [
                          -75.618216,
                          45.47056,
                          0
                      ],
                      [
                          -75.618557,
                          45.47345,
                          0
                      ],
                      [
                          -75.639391,
                          45.475464,
                          0
                      ],
                      [
                          -75.639845,
                          45.480974,
                          0
                      ],
                      [
                          -75.639582,
                          45.482011,
                          0
                      ],
                      [
                          -75.63975,
                          45.4846,
                          0
                      ],
                      [
                          -75.640961,
                          45.490807,
                          0
                      ],
                      [
                          -75.64096,
                          45.49081,
                          0
                      ],
                      [
                          -75.630936,
                          45.492935,
                          0
                      ],
                      [
                          -75.611795,
                          45.493778,
                          0
                      ],
                      [
                          -75.607199,
                          45.494777,
                          0
                      ],
                      [
                          -75.607199,
                          45.494781,
                          0
                      ],
                      [
                          -75.607734,
                          45.495863,
                          0
                      ],
                      [
                          -75.610111,
                          45.511233,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Papineau",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.555509,
                          46.200166,
                          0
                      ],
                      [
                          -73.545007,
                          46.194756,
                          0
                      ],
                      [
                          -73.531938,
                          46.194821,
                          0
                      ],
                      [
                          -73.522317,
                          46.194091,
                          0
                      ],
                      [
                          -73.521286,
                          46.189045,
                          0
                      ],
                      [
                          -73.508839,
                          46.186331,
                          0
                      ],
                      [
                          -73.497341,
                          46.181633,
                          0
                      ],
                      [
                          -73.486105,
                          46.17696,
                          0
                      ],
                      [
                          -73.481515,
                          46.179924,
                          0
                      ],
                      [
                          -73.480304,
                          46.168875,
                          0
                      ],
                      [
                          -73.490292,
                          46.167588,
                          0
                      ],
                      [
                          -73.483374,
                          46.159763,
                          0
                      ],
                      [
                          -73.493237,
                          46.153526,
                          0
                      ],
                      [
                          -73.488688,
                          46.145078,
                          0
                      ],
                      [
                          -73.479241,
                          46.138781,
                          0
                      ],
                      [
                          -73.466028,
                          46.137404,
                          0
                      ],
                      [
                          -73.463309,
                          46.140959,
                          0
                      ],
                      [
                          -73.472866,
                          46.145213,
                          0
                      ],
                      [
                          -73.471027,
                          46.146757,
                          0
                      ],
                      [
                          -73.464258,
                          46.148311,
                          0
                      ],
                      [
                          -73.459837,
                          46.145856,
                          0
                      ],
                      [
                          -73.454078,
                          46.13774,
                          0
                      ],
                      [
                          -73.440711,
                          46.137184,
                          0
                      ],
                      [
                          -73.435025,
                          46.128913,
                          0
                      ],
                      [
                          -73.44625,
                          46.134048,
                          0
                      ],
                      [
                          -73.45799,
                          46.129891,
                          0
                      ],
                      [
                          -73.466446,
                          46.123061,
                          0
                      ],
                      [
                          -73.460997,
                          46.114873,
                          0
                      ],
                      [
                          -73.447006,
                          46.115013,
                          0
                      ],
                      [
                          -73.418132,
                          46.115117,
                          0
                      ],
                      [
                          -73.406741,
                          46.119618,
                          0
                      ],
                      [
                          -73.401464,
                          46.109964,
                          0
                      ],
                      [
                          -73.397312,
                          46.100635,
                          0
                      ],
                      [
                          -73.397187,
                          46.079051,
                          0
                      ],
                      [
                          -73.409032,
                          46.08338,
                          0
                      ],
                      [
                          -73.411862,
                          46.083328,
                          0
                      ],
                      [
                          -73.412064,
                          46.072461,
                          0
                      ],
                      [
                          -73.412122,
                          46.069311,
                          0
                      ],
                      [
                          -73.399896,
                          46.06872,
                          0
                      ],
                      [
                          -73.387117,
                          46.065893,
                          0
                      ],
                      [
                          -73.376283,
                          46.060979,
                          0
                      ],
                      [
                          -73.351429,
                          46.056145,
                          0
                      ],
                      [
                          -73.339234,
                          46.059142,
                          0
                      ],
                      [
                          -73.326121,
                          46.057099,
                          0
                      ],
                      [
                          -73.321672,
                          46.055633,
                          0
                      ],
                      [
                          -73.321795,
                          46.06707,
                          0
                      ],
                      [
                          -73.315264,
                          46.066209,
                          0
                      ],
                      [
                          -73.315278,
                          46.076791,
                          0
                      ],
                      [
                          -73.311872,
                          46.076773,
                          0
                      ],
                      [
                          -73.311842,
                          46.063568,
                          0
                      ],
                      [
                          -73.31183,
                          46.060899,
                          0
                      ],
                      [
                          -73.308429,
                          46.06271,
                          0
                      ],
                      [
                          -73.308461,
                          46.066096,
                          0
                      ],
                      [
                          -73.305522,
                          46.06617,
                          0
                      ],
                      [
                          -73.305293,
                          46.068541,
                          0
                      ],
                      [
                          -73.303705,
                          46.068547,
                          0
                      ],
                      [
                          -73.303584,
                          46.063059,
                          0
                      ],
                      [
                          -73.298963,
                          46.064743,
                          0
                      ],
                      [
                          -73.296202,
                          46.064907,
                          0
                      ],
                      [
                          -73.29175,
                          46.072686,
                          0
                      ],
                      [
                          -73.291633,
                          46.075922,
                          0
                      ],
                      [
                          -73.278695,
                          46.074359,
                          0
                      ],
                      [
                          -73.266592,
                          46.071161,
                          0
                      ],
                      [
                          -73.257549,
                          46.071984,
                          0
                      ],
                      [
                          -73.233888,
                          46.05511,
                          0
                      ],
                      [
                          -73.245942,
                          46.051433,
                          0
                      ],
                      [
                          -73.249189,
                          46.045484,
                          0
                      ],
                      [
                          -73.259493,
                          46.044377,
                          0
                      ],
                      [
                          -73.268955,
                          46.035086,
                          0
                      ],
                      [
                          -73.26987,
                          46.025728,
                          0
                      ],
                      [
                          -73.274104,
                          46.026863,
                          0
                      ],
                      [
                          -73.281306,
                          46.01324,
                          0
                      ],
                      [
                          -73.295845,
                          46.004125,
                          0
                      ],
                      [
                          -73.303585,
                          45.993677,
                          0
                      ],
                      [
                          -73.315578,
                          45.983751,
                          0
                      ],
                      [
                          -73.322165,
                          45.982476,
                          0
                      ],
                      [
                          -73.312472,
                          45.97486,
                          0
                      ],
                      [
                          -73.324133,
                          45.970153,
                          0
                      ],
                      [
                          -73.331192,
                          45.96258,
                          0
                      ],
                      [
                          -73.328381,
                          45.953532,
                          0
                      ],
                      [
                          -73.350481,
                          45.968284,
                          0
                      ],
                      [
                          -73.360061,
                          45.961872,
                          0
                      ],
                      [
                          -73.355262,
                          45.958515,
                          0
                      ],
                      [
                          -73.357061,
                          45.95727,
                          0
                      ],
                      [
                          -73.353057,
                          45.954438,
                          0
                      ],
                      [
                          -73.355461,
                          45.952116,
                          0
                      ],
                      [
                          -73.357454,
                          45.954139,
                          0
                      ],
                      [
                          -73.360975,
                          45.950864,
                          0
                      ],
                      [
                          -73.359324,
                          45.949675,
                          0
                      ],
                      [
                          -73.361933,
                          45.946774,
                          0
                      ],
                      [
                          -73.359346,
                          45.945058,
                          0
                      ],
                      [
                          -73.366224,
                          45.939583,
                          0
                      ],
                      [
                          -73.360709,
                          45.935501,
                          0
                      ],
                      [
                          -73.364857,
                          45.933066,
                          0
                      ],
                      [
                          -73.369047,
                          45.925429,
                          0
                      ],
                      [
                          -73.384796,
                          45.914595,
                          0
                      ],
                      [
                          -73.382901,
                          45.913188,
                          0
                      ],
                      [
                          -73.3873,
                          45.909102,
                          0
                      ],
                      [
                          -73.389899,
                          45.910989,
                          0
                      ],
                      [
                          -73.391582,
                          45.909427,
                          0
                      ],
                      [
                          -73.389243,
                          45.907768,
                          0
                      ],
                      [
                          -73.392683,
                          45.905241,
                          0
                      ],
                      [
                          -73.393318,
                          45.905274,
                          0
                      ],
                      [
                          -73.424898,
                          45.928527,
                          0
                      ],
                      [
                          -73.428947,
                          45.923716,
                          0
                      ],
                      [
                          -73.456735,
                          45.943877,
                          0
                      ],
                      [
                          -73.456397,
                          45.946265,
                          0
                      ],
                      [
                          -73.459473,
                          45.948398,
                          0
                      ],
                      [
                          -73.459479,
                          45.948402,
                          0
                      ],
                      [
                          -73.459151,
                          45.949123,
                          0
                      ],
                      [
                          -73.459979,
                          45.949746,
                          0
                      ],
                      [
                          -73.459288,
                          45.951253,
                          0
                      ],
                      [
                          -73.470713,
                          45.959838,
                          0
                      ],
                      [
                          -73.470716,
                          45.959837,
                          0
                      ],
                      [
                          -73.476234,
                          45.957538,
                          0
                      ],
                      [
                          -73.487242,
                          45.956273,
                          0
                      ],
                      [
                          -73.502351,
                          45.95894,
                          0
                      ],
                      [
                          -73.516351,
                          45.964575,
                          0
                      ],
                      [
                          -73.516386,
                          45.964585,
                          0
                      ],
                      [
                          -73.518611,
                          45.962952,
                          0
                      ],
                      [
                          -73.540517,
                          45.972495,
                          0
                      ],
                      [
                          -73.516541,
                          45.990017,
                          0
                      ],
                      [
                          -73.51666,
                          45.990265,
                          0
                      ],
                      [
                          -73.515234,
                          45.991166,
                          0
                      ],
                      [
                          -73.512888,
                          45.990268,
                          0
                      ],
                      [
                          -73.510404,
                          45.992392,
                          0
                      ],
                      [
                          -73.510855,
                          45.993954,
                          0
                      ],
                      [
                          -73.512255,
                          45.993035,
                          0
                      ],
                      [
                          -73.525455,
                          46.001991,
                          0
                      ],
                      [
                          -73.525418,
                          46.002015,
                          0
                      ],
                      [
                          -73.508018,
                          46.013434,
                          0
                      ],
                      [
                          -73.521619,
                          46.023115,
                          0
                      ],
                      [
                          -73.517141,
                          46.026203,
                          0
                      ],
                      [
                          -73.530379,
                          46.035648,
                          0
                      ],
                      [
                          -73.530411,
                          46.035672,
                          0
                      ],
                      [
                          -73.534341,
                          46.032949,
                          0
                      ],
                      [
                          -73.548913,
                          46.043084,
                          0
                      ],
                      [
                          -73.567836,
                          46.030271,
                          0
                      ],
                      [
                          -73.610291,
                          46.059344,
                          0
                      ],
                      [
                          -73.610296,
                          46.059355,
                          0
                      ],
                      [
                          -73.637829,
                          46.079087,
                          0
                      ],
                      [
                          -73.566921,
                          46.125646,
                          0
                      ],
                      [
                          -73.599632,
                          46.150173,
                          0
                      ],
                      [
                          -73.564579,
                          46.173151,
                          0
                      ],
                      [
                          -73.585095,
                          46.188909,
                          0
                      ],
                      [
                          -73.564206,
                          46.201945,
                          0
                      ],
                      [
                          -73.567065,
                          46.204218,
                          0
                      ],
                      [
                          -73.560817,
                          46.205661,
                          0
                      ],
                      [
                          -73.555509,
                          46.200166,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Joliette",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -72.073467,
                          47.949994,
                          0
                      ],
                      [
                          -71.374437,
                          47.956472,
                          0
                      ],
                      [
                          -71.382643,
                          47.973683,
                          0
                      ],
                      [
                          -71.409088,
                          47.97767,
                          0
                      ],
                      [
                          -71.41168,
                          47.995986,
                          0
                      ],
                      [
                          -70.264651,
                          47.992927,
                          0
                      ],
                      [
                          -70.17188,
                          48.12482,
                          0
                      ],
                      [
                          -69.956658,
                          48.057602,
                          0
                      ],
                      [
                          -69.957023,
                          48.057082,
                          0
                      ],
                      [
                          -69.956933,
                          48.057052,
                          0
                      ],
                      [
                          -69.956152,
                          48.056791,
                          0
                      ],
                      [
                          -69.956149,
                          48.056794,
                          0
                      ],
                      [
                          -69.871083,
                          48.186177,
                          0
                      ],
                      [
                          -69.846117,
                          48.155646,
                          0
                      ],
                      [
                          -69.78719,
                          48.145153,
                          0
                      ],
                      [
                          -69.668037,
                          48.116363,
                          0
                      ],
                      [
                          -69.548373,
                          48.049452,
                          0
                      ],
                      [
                          -69.673174,
                          47.983449,
                          0
                      ],
                      [
                          -70.103777,
                          47.523009,
                          0
                      ],
                      [
                          -70.300433,
                          47.326589,
                          0
                      ],
                      [
                          -70.463304,
                          47.246116,
                          0
                      ],
                      [
                          -70.514474,
                          47.173587,
                          0
                      ],
                      [
                          -70.671882,
                          47.068055,
                          0
                      ],
                      [
                          -70.817919,
                          46.934176,
                          0
                      ],
                      [
                          -70.997192,
                          46.848338,
                          0
                      ],
                      [
                          -71.078856,
                          46.839139,
                          0
                      ],
                      [
                          -71.131725,
                          46.837561,
                          0
                      ],
                      [
                          -71.172551,
                          46.842564,
                          0
                      ],
                      [
                          -71.131397,
                          46.880246,
                          0
                      ],
                      [
                          -71.1314,
                          46.88025,
                          0
                      ],
                      [
                          -71.144017,
                          46.885862,
                          0
                      ],
                      [
                          -71.147003,
                          46.88857,
                          0
                      ],
                      [
                          -71.14825,
                          46.891607,
                          0
                      ],
                      [
                          -71.148325,
                          46.89324,
                          0
                      ],
                      [
                          -71.151547,
                          46.895286,
                          0
                      ],
                      [
                          -71.155313,
                          46.896015,
                          0
                      ],
                      [
                          -71.159325,
                          46.894022,
                          0
                      ],
                      [
                          -71.161563,
                          46.894103,
                          0
                      ],
                      [
                          -71.165967,
                          46.897266,
                          0
                      ],
                      [
                          -71.170042,
                          46.902332,
                          0
                      ],
                      [
                          -71.169567,
                          46.904179,
                          0
                      ],
                      [
                          -71.172309,
                          46.904664,
                          0
                      ],
                      [
                          -71.172073,
                          46.906229,
                          0
                      ],
                      [
                          -71.168719,
                          46.912293,
                          0
                      ],
                      [
                          -71.192511,
                          46.938012,
                          0
                      ],
                      [
                          -71.190579,
                          46.942937,
                          0
                      ],
                      [
                          -71.192864,
                          46.948682,
                          0
                      ],
                      [
                          -71.205296,
                          46.966121,
                          0
                      ],
                      [
                          -71.205315,
                          46.966136,
                          0
                      ],
                      [
                          -71.191553,
                          46.970368,
                          0
                      ],
                      [
                          -71.172119,
                          46.982795,
                          0
                      ],
                      [
                          -71.129124,
                          47.004762,
                          0
                      ],
                      [
                          -71.115082,
                          47.017629,
                          0
                      ],
                      [
                          -71.112759,
                          47.024122,
                          0
                      ],
                      [
                          -71.094463,
                          47.043715,
                          0
                      ],
                      [
                          -71.085683,
                          47.050217,
                          0
                      ],
                      [
                          -71.147055,
                          47.103872,
                          0
                      ],
                      [
                          -71.14708,
                          47.103856,
                          0
                      ],
                      [
                          -71.172849,
                          47.087918,
                          0
                      ],
                      [
                          -71.172876,
                          47.087935,
                          0
                      ],
                      [
                          -71.186905,
                          47.097308,
                          0
                      ],
                      [
                          -71.178381,
                          47.101488,
                          0
                      ],
                      [
                          -71.216218,
                          47.125022,
                          0
                      ],
                      [
                          -71.217887,
                          47.124826,
                          0
                      ],
                      [
                          -71.21789,
                          47.124825,
                          0
                      ],
                      [
                          -71.218014,
                          47.123214,
                          0
                      ],
                      [
                          -71.222051,
                          47.120607,
                          0
                      ],
                      [
                          -71.222049,
                          47.118163,
                          0
                      ],
                      [
                          -71.222049,
                          47.11816,
                          0
                      ],
                      [
                          -71.2241,
                          47.120081,
                          0
                      ],
                      [
                          -71.231731,
                          47.116625,
                          0
                      ],
                      [
                          -71.231735,
                          47.116623,
                          0
                      ],
                      [
                          -71.237735,
                          47.122504,
                          0
                      ],
                      [
                          -71.243648,
                          47.123284,
                          0
                      ],
                      [
                          -71.243649,
                          47.123284,
                          0
                      ],
                      [
                          -71.240578,
                          47.133694,
                          0
                      ],
                      [
                          -71.263703,
                          47.133919,
                          0
                      ],
                      [
                          -71.263704,
                          47.133948,
                          0
                      ],
                      [
                          -71.263744,
                          47.135862,
                          0
                      ],
                      [
                          -71.263678,
                          47.135891,
                          0
                      ],
                      [
                          -71.259907,
                          47.137598,
                          0
                      ],
                      [
                          -71.268451,
                          47.146625,
                          0
                      ],
                      [
                          -71.252011,
                          47.154182,
                          0
                      ],
                      [
                          -71.25715,
                          47.159448,
                          0
                      ],
                      [
                          -71.292697,
                          47.1964,
                          0
                      ],
                      [
                          -71.890544,
                          47.770181,
                          0
                      ],
                      [
                          -72.073467,
                          47.949994,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Charlevoix-Côte-de-Beaupré",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -71.890544,
                          47.770181,
                          0
                      ],
                      [
                          -71.292697,
                          47.1964,
                          0
                      ],
                      [
                          -71.25715,
                          47.159448,
                          0
                      ],
                      [
                          -71.252011,
                          47.154182,
                          0
                      ],
                      [
                          -71.268451,
                          47.146625,
                          0
                      ],
                      [
                          -71.259907,
                          47.137598,
                          0
                      ],
                      [
                          -71.263678,
                          47.135891,
                          0
                      ],
                      [
                          -71.263744,
                          47.135862,
                          0
                      ],
                      [
                          -71.263704,
                          47.133948,
                          0
                      ],
                      [
                          -71.263703,
                          47.133919,
                          0
                      ],
                      [
                          -71.240578,
                          47.133694,
                          0
                      ],
                      [
                          -71.243649,
                          47.123284,
                          0
                      ],
                      [
                          -71.243648,
                          47.123284,
                          0
                      ],
                      [
                          -71.237735,
                          47.122504,
                          0
                      ],
                      [
                          -71.231735,
                          47.116623,
                          0
                      ],
                      [
                          -71.231731,
                          47.116625,
                          0
                      ],
                      [
                          -71.2241,
                          47.120081,
                          0
                      ],
                      [
                          -71.222049,
                          47.11816,
                          0
                      ],
                      [
                          -71.222049,
                          47.118163,
                          0
                      ],
                      [
                          -71.222051,
                          47.120607,
                          0
                      ],
                      [
                          -71.218014,
                          47.123214,
                          0
                      ],
                      [
                          -71.21789,
                          47.124825,
                          0
                      ],
                      [
                          -71.217887,
                          47.124826,
                          0
                      ],
                      [
                          -71.216218,
                          47.125022,
                          0
                      ],
                      [
                          -71.178381,
                          47.101488,
                          0
                      ],
                      [
                          -71.186905,
                          47.097308,
                          0
                      ],
                      [
                          -71.172876,
                          47.087935,
                          0
                      ],
                      [
                          -71.172849,
                          47.087918,
                          0
                      ],
                      [
                          -71.19279,
                          47.087314,
                          0
                      ],
                      [
                          -71.191404,
                          47.085408,
                          0
                      ],
                      [
                          -71.192034,
                          47.083055,
                          0
                      ],
                      [
                          -71.189524,
                          47.079336,
                          0
                      ],
                      [
                          -71.187108,
                          47.077984,
                          0
                      ],
                      [
                          -71.183693,
                          47.072964,
                          0
                      ],
                      [
                          -71.183692,
                          47.072961,
                          0
                      ],
                      [
                          -71.216258,
                          47.057168,
                          0
                      ],
                      [
                          -71.216256,
                          47.057167,
                          0
                      ],
                      [
                          -71.207756,
                          47.048449,
                          0
                      ],
                      [
                          -71.270721,
                          47.018462,
                          0
                      ],
                      [
                          -71.270698,
                          47.018438,
                          0
                      ],
                      [
                          -71.222929,
                          46.969449,
                          0
                      ],
                      [
                          -71.285141,
                          46.940277,
                          0
                      ],
                      [
                          -71.26321,
                          46.917845,
                          0
                      ],
                      [
                          -71.305413,
                          46.901557,
                          0
                      ],
                      [
                          -71.307044,
                          46.901728,
                          0
                      ],
                      [
                          -71.304639,
                          46.899488,
                          0
                      ],
                      [
                          -71.316015,
                          46.894849,
                          0
                      ],
                      [
                          -71.311096,
                          46.887231,
                          0
                      ],
                      [
                          -71.311106,
                          46.887211,
                          0
                      ],
                      [
                          -71.312962,
                          46.886358,
                          0
                      ],
                      [
                          -71.297093,
                          46.869801,
                          0
                      ],
                      [
                          -71.297093,
                          46.8698,
                          0
                      ],
                      [
                          -71.29799,
                          46.869786,
                          0
                      ],
                      [
                          -71.29863,
                          46.869628,
                          0
                      ],
                      [
                          -71.29893,
                          46.869473,
                          0
                      ],
                      [
                          -71.299578,
                          46.869,
                          0
                      ],
                      [
                          -71.299801,
                          46.868927,
                          0
                      ],
                      [
                          -71.300456,
                          46.868949,
                          0
                      ],
                      [
                          -71.30272,
                          46.869464,
                          0
                      ],
                      [
                          -71.303378,
                          46.869532,
                          0
                      ],
                      [
                          -71.303691,
                          46.869467,
                          0
                      ],
                      [
                          -71.304091,
                          46.869059,
                          0
                      ],
                      [
                          -71.304666,
                          46.868848,
                          0
                      ],
                      [
                          -71.305639,
                          46.868958,
                          0
                      ],
                      [
                          -71.306166,
                          46.869117,
                          0
                      ],
                      [
                          -71.306865,
                          46.869454,
                          0
                      ],
                      [
                          -71.307345,
                          46.869927,
                          0
                      ],
                      [
                          -71.307399,
                          46.870008,
                          0
                      ],
                      [
                          -71.30757,
                          46.870105,
                          0
                      ],
                      [
                          -71.307899,
                          46.870148,
                          0
                      ],
                      [
                          -71.308879,
                          46.869862,
                          0
                      ],
                      [
                          -71.311692,
                          46.86977,
                          0
                      ],
                      [
                          -71.312011,
                          46.869714,
                          0
                      ],
                      [
                          -71.313732,
                          46.868991,
                          0
                      ],
                      [
                          -71.313829,
                          46.868858,
                          0
                      ],
                      [
                          -71.313789,
                          46.868815,
                          0
                      ],
                      [
                          -71.314014,
                          46.868718,
                          0
                      ],
                      [
                          -71.314016,
                          46.868717,
                          0
                      ],
                      [
                          -71.319328,
                          46.866396,
                          0
                      ],
                      [
                          -71.32008,
                          46.865937,
                          0
                      ],
                      [
                          -71.341797,
                          46.856419,
                          0
                      ],
                      [
                          -71.341136,
                          46.855667,
                          0
                      ],
                      [
                          -71.347344,
                          46.852822,
                          0
                      ],
                      [
                          -71.346884,
                          46.851694,
                          0
                      ],
                      [
                          -71.347912,
                          46.851594,
                          0
                      ],
                      [
                          -71.348082,
                          46.850286,
                          0
                      ],
                      [
                          -71.34827,
                          46.84995,
                          0
                      ],
                      [
                          -71.348263,
                          46.849609,
                          0
                      ],
                      [
                          -71.347649,
                          46.849044,
                          0
                      ],
                      [
                          -71.347294,
                          46.847921,
                          0
                      ],
                      [
                          -71.347045,
                          46.847536,
                          0
                      ],
                      [
                          -71.346979,
                          46.846477,
                          0
                      ],
                      [
                          -71.346157,
                          46.845396,
                          0
                      ],
                      [
                          -71.345716,
                          46.844903,
                          0
                      ],
                      [
                          -71.345069,
                          46.844817,
                          0
                      ],
                      [
                          -71.344148,
                          46.844817,
                          0
                      ],
                      [
                          -71.343619,
                          46.844588,
                          0
                      ],
                      [
                          -71.343499,
                          46.844224,
                          0
                      ],
                      [
                          -71.343725,
                          46.843483,
                          0
                      ],
                      [
                          -71.343722,
                          46.843478,
                          0
                      ],
                      [
                          -71.342389,
                          46.842787,
                          0
                      ],
                      [
                          -71.342373,
                          46.842764,
                          0
                      ],
                      [
                          -71.342097,
                          46.842097,
                          0
                      ],
                      [
                          -71.34156,
                          46.841476,
                          0
                      ],
                      [
                          -71.341532,
                          46.84098,
                          0
                      ],
                      [
                          -71.342238,
                          46.839783,
                          0
                      ],
                      [
                          -71.342481,
                          46.838658,
                          0
                      ],
                      [
                          -71.342055,
                          46.837563,
                          0
                      ],
                      [
                          -71.342053,
                          46.836911,
                          0
                      ],
                      [
                          -71.341979,
                          46.836273,
                          0
                      ],
                      [
                          -71.350027,
                          46.831889,
                          0
                      ],
                      [
                          -71.385957,
                          46.858327,
                          0
                      ],
                      [
                          -71.387565,
                          46.860134,
                          0
                      ],
                      [
                          -71.388719,
                          46.860588,
                          0
                      ],
                      [
                          -71.3906,
                          46.860789,
                          0
                      ],
                      [
                          -71.390737,
                          46.861005,
                          0
                      ],
                      [
                          -71.400607,
                          46.868,
                          0
                      ],
                      [
                          -71.412744,
                          46.862925,
                          0
                      ],
                      [
                          -71.423595,
                          46.867812,
                          0
                      ],
                      [
                          -71.431142,
                          46.870758,
                          0
                      ],
                      [
                          -71.449338,
                          46.876054,
                          0
                      ],
                      [
                          -71.458091,
                          46.87853,
                          0
                      ],
                      [
                          -71.465184,
                          46.879951,
                          0
                      ],
                      [
                          -71.46522,
                          46.879958,
                          0
                      ],
                      [
                          -71.460162,
                          46.883318,
                          0
                      ],
                      [
                          -71.473042,
                          46.892152,
                          0
                      ],
                      [
                          -71.438447,
                          46.914708,
                          0
                      ],
                      [
                          -71.433411,
                          46.911049,
                          0
                      ],
                      [
                          -71.405611,
                          46.923226,
                          0
                      ],
                      [
                          -71.448041,
                          46.967528,
                          0
                      ],
                      [
                          -71.420617,
                          46.980702,
                          0
                      ],
                      [
                          -71.631695,
                          47.192537,
                          0
                      ],
                      [
                          -71.7133,
                          47.248618,
                          0
                      ],
                      [
                          -71.828202,
                          47.329278,
                          0
                      ],
                      [
                          -71.916266,
                          47.353192,
                          0
                      ],
                      [
                          -71.969219,
                          47.387512,
                          0
                      ],
                      [
                          -71.990917,
                          47.38987,
                          0
                      ],
                      [
                          -72.007084,
                          47.399749,
                          0
                      ],
                      [
                          -72.012889,
                          47.406218,
                          0
                      ],
                      [
                          -72.032714,
                          47.401813,
                          0
                      ],
                      [
                          -72.074595,
                          47.409636,
                          0
                      ],
                      [
                          -72.097717,
                          47.394572,
                          0
                      ],
                      [
                          -72.107277,
                          47.382088,
                          0
                      ],
                      [
                          -72.127043,
                          47.379217,
                          0
                      ],
                      [
                          -72.145406,
                          47.372236,
                          0
                      ],
                      [
                          -72.14668,
                          47.379405,
                          0
                      ],
                      [
                          -72.161367,
                          47.386482,
                          0
                      ],
                      [
                          -72.14696,
                          47.398779,
                          0
                      ],
                      [
                          -72.142218,
                          47.413178,
                          0
                      ],
                      [
                          -72.158659,
                          47.421096,
                          0
                      ],
                      [
                          -72.172947,
                          47.440593,
                          0
                      ],
                      [
                          -72.170799,
                          47.462359,
                          0
                      ],
                      [
                          -72.161775,
                          47.466431,
                          0
                      ],
                      [
                          -72.175273,
                          47.477814,
                          0
                      ],
                      [
                          -72.195148,
                          47.482931,
                          0
                      ],
                      [
                          -72.20087,
                          47.489213,
                          0
                      ],
                      [
                          -72.197398,
                          47.502037,
                          0
                      ],
                      [
                          -72.214452,
                          47.511935,
                          0
                      ],
                      [
                          -72.239414,
                          47.535908,
                          0
                      ],
                      [
                          -71.890544,
                          47.770181,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Chauveau",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.381192,
                          46.889973,
                          0
                      ],
                      [
                          -73.375363,
                          46.887831,
                          0
                      ],
                      [
                          -73.361627,
                          46.887333,
                          0
                      ],
                      [
                          -73.342979,
                          46.881242,
                          0
                      ],
                      [
                          -73.329461,
                          46.881239,
                          0
                      ],
                      [
                          -73.319712,
                          46.874952,
                          0
                      ],
                      [
                          -73.307346,
                          46.870226,
                          0
                      ],
                      [
                          -73.298554,
                          46.876673,
                          0
                      ],
                      [
                          -73.288515,
                          46.882309,
                          0
                      ],
                      [
                          -73.281958,
                          46.88123,
                          0
                      ],
                      [
                          -73.27569,
                          46.8829,
                          0
                      ],
                      [
                          -73.274088,
                          46.887343,
                          0
                      ],
                      [
                          -73.268147,
                          46.889434,
                          0
                      ],
                      [
                          -73.256415,
                          46.884974,
                          0
                      ],
                      [
                          -73.243485,
                          46.882555,
                          0
                      ],
                      [
                          -73.248144,
                          46.869243,
                          0
                      ],
                      [
                          -73.242458,
                          46.866904,
                          0
                      ],
                      [
                          -73.229604,
                          46.864797,
                          0
                      ],
                      [
                          -73.224897,
                          46.861275,
                          0
                      ],
                      [
                          -73.222526,
                          46.852301,
                          0
                      ],
                      [
                          -73.215217,
                          46.850438,
                          0
                      ],
                      [
                          -73.194306,
                          46.839848,
                          0
                      ],
                      [
                          -73.176965,
                          46.845907,
                          0
                      ],
                      [
                          -73.163889,
                          46.846537,
                          0
                      ],
                      [
                          -73.157242,
                          46.84559,
                          0
                      ],
                      [
                          -73.13495,
                          46.835137,
                          0
                      ],
                      [
                          -73.132263,
                          46.786675,
                          0
                      ],
                      [
                          -73.143672,
                          46.770604,
                          0
                      ],
                      [
                          -73.169274,
                          46.764489,
                          0
                      ],
                      [
                          -73.178886,
                          46.750833,
                          0
                      ],
                      [
                          -73.10695,
                          46.704163,
                          0
                      ],
                      [
                          -73.064093,
                          46.706695,
                          0
                      ],
                      [
                          -73.031988,
                          46.653092,
                          0
                      ],
                      [
                          -72.999398,
                          46.648938,
                          0
                      ],
                      [
                          -72.986059,
                          46.667043,
                          0
                      ],
                      [
                          -72.984264,
                          46.677457,
                          0
                      ],
                      [
                          -73.01147,
                          46.736282,
                          0
                      ],
                      [
                          -72.993816,
                          46.745617,
                          0
                      ],
                      [
                          -72.926999,
                          46.733685,
                          0
                      ],
                      [
                          -72.909008,
                          46.724014,
                          0
                      ],
                      [
                          -72.878087,
                          46.67333,
                          0
                      ],
                      [
                          -72.874756,
                          46.675334,
                          0
                      ],
                      [
                          -72.859678,
                          46.664292,
                          0
                      ],
                      [
                          -72.865271,
                          46.661043,
                          0
                      ],
                      [
                          -72.872102,
                          46.66329,
                          0
                      ],
                      [
                          -72.88574,
                          46.66176,
                          0
                      ],
                      [
                          -72.893645,
                          46.667121,
                          0
                      ],
                      [
                          -72.85147,
                          46.633759,
                          0
                      ],
                      [
                          -72.870644,
                          46.621851,
                          0
                      ],
                      [
                          -72.766162,
                          46.546176,
                          0
                      ],
                      [
                          -72.768846,
                          46.546202,
                          0
                      ],
                      [
                          -72.769552,
                          46.546685,
                          0
                      ],
                      [
                          -72.77013,
                          46.546786,
                          0
                      ],
                      [
                          -72.771873,
                          46.546593,
                          0
                      ],
                      [
                          -72.773443,
                          46.545746,
                          0
                      ],
                      [
                          -72.774892,
                          46.545602,
                          0
                      ],
                      [
                          -72.775123,
                          46.545937,
                          0
                      ],
                      [
                          -72.775977,
                          46.545939,
                          0
                      ],
                      [
                          -72.77721,
                          46.546049,
                          0
                      ],
                      [
                          -72.778926,
                          46.541548,
                          0
                      ],
                      [
                          -72.773014,
                          46.537267,
                          0
                      ],
                      [
                          -72.773661,
                          46.523126,
                          0
                      ],
                      [
                          -72.771254,
                          46.518486,
                          0
                      ],
                      [
                          -72.774522,
                          46.507617,
                          0
                      ],
                      [
                          -72.773641,
                          46.496078,
                          0
                      ],
                      [
                          -72.773567,
                          46.490803,
                          0
                      ],
                      [
                          -72.775413,
                          46.480441,
                          0
                      ],
                      [
                          -72.7719,
                          46.475556,
                          0
                      ],
                      [
                          -72.767555,
                          46.466444,
                          0
                      ],
                      [
                          -72.757178,
                          46.458626,
                          0
                      ],
                      [
                          -72.751456,
                          46.455888,
                          0
                      ],
                      [
                          -72.741218,
                          46.449717,
                          0
                      ],
                      [
                          -72.737407,
                          46.445865,
                          0
                      ],
                      [
                          -72.727925,
                          46.437635,
                          0
                      ],
                      [
                          -72.725081,
                          46.433473,
                          0
                      ],
                      [
                          -72.715306,
                          46.427495,
                          0
                      ],
                      [
                          -72.704912,
                          46.424248,
                          0
                      ],
                      [
                          -72.723091,
                          46.411771,
                          0
                      ],
                      [
                          -72.645931,
                          46.35819,
                          0
                      ],
                      [
                          -72.616285,
                          46.340228,
                          0
                      ],
                      [
                          -72.560513,
                          46.306761,
                          0
                      ],
                      [
                          -72.576456,
                          46.296067,
                          0
                      ],
                      [
                          -72.731121,
                          46.233332,
                          0
                      ],
                      [
                          -72.872404,
                          46.18507,
                          0
                      ],
                      [
                          -72.936245,
                          46.151238,
                          0
                      ],
                      [
                          -72.983121,
                          46.167628,
                          0
                      ],
                      [
                          -72.992613,
                          46.163065,
                          0
                      ],
                      [
                          -73.007142,
                          46.165238,
                          0
                      ],
                      [
                          -73.018245,
                          46.160678,
                          0
                      ],
                      [
                          -73.037318,
                          46.156411,
                          0
                      ],
                      [
                          -73.215107,
                          46.278468,
                          0
                      ],
                      [
                          -73.195678,
                          46.294177,
                          0
                      ],
                      [
                          -73.228114,
                          46.317033,
                          0
                      ],
                      [
                          -73.219005,
                          46.323333,
                          0
                      ],
                      [
                          -73.215222,
                          46.32463,
                          0
                      ],
                      [
                          -73.212149,
                          46.32477,
                          0
                      ],
                      [
                          -73.206404,
                          46.321925,
                          0
                      ],
                      [
                          -73.20319,
                          46.324858,
                          0
                      ],
                      [
                          -73.199298,
                          46.322204,
                          0
                      ],
                      [
                          -73.196668,
                          46.321224,
                          0
                      ],
                      [
                          -73.19264,
                          46.321379,
                          0
                      ],
                      [
                          -73.190811,
                          46.322677,
                          0
                      ],
                      [
                          -73.185171,
                          46.323587,
                          0
                      ],
                      [
                          -73.178965,
                          46.32216,
                          0
                      ],
                      [
                          -73.176219,
                          46.323451,
                          0
                      ],
                      [
                          -73.203658,
                          46.342047,
                          0
                      ],
                      [
                          -73.19207,
                          46.350252,
                          0
                      ],
                      [
                          -73.203876,
                          46.35902,
                          0
                      ],
                      [
                          -73.187809,
                          46.370376,
                          0
                      ],
                      [
                          -73.180934,
                          46.366115,
                          0
                      ],
                      [
                          -73.169132,
                          46.374385,
                          0
                      ],
                      [
                          -73.174608,
                          46.378291,
                          0
                      ],
                      [
                          -73.167319,
                          46.38299,
                          0
                      ],
                      [
                          -73.248831,
                          46.439831,
                          0
                      ],
                      [
                          -73.333092,
                          46.498705,
                          0
                      ],
                      [
                          -73.283007,
                          46.533307,
                          0
                      ],
                      [
                          -73.298145,
                          46.544678,
                          0
                      ],
                      [
                          -73.306957,
                          46.555153,
                          0
                      ],
                      [
                          -73.312225,
                          46.573686,
                          0
                      ],
                      [
                          -73.336513,
                          46.601263,
                          0
                      ],
                      [
                          -73.361844,
                          46.61624,
                          0
                      ],
                      [
                          -73.402205,
                          46.598823,
                          0
                      ],
                      [
                          -73.446012,
                          46.596265,
                          0
                      ],
                      [
                          -73.446841,
                          46.612264,
                          0
                      ],
                      [
                          -73.441989,
                          46.668657,
                          0
                      ],
                      [
                          -73.466956,
                          46.709485,
                          0
                      ],
                      [
                          -73.447674,
                          46.766379,
                          0
                      ],
                      [
                          -73.456934,
                          46.786325,
                          0
                      ],
                      [
                          -73.43634,
                          46.829242,
                          0
                      ],
                      [
                          -73.441506,
                          46.843685,
                          0
                      ],
                      [
                          -73.4202,
                          46.88835,
                          0
                      ],
                      [
                          -73.41337,
                          46.880666,
                          0
                      ],
                      [
                          -73.407414,
                          46.882621,
                          0
                      ],
                      [
                          -73.394262,
                          46.884235,
                          0
                      ],
                      [
                          -73.387645,
                          46.884102,
                          0
                      ],
                      [
                          -73.381192,
                          46.889973,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Maskinongé",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.428947,
                          45.923716,
                          0
                      ],
                      [
                          -73.424898,
                          45.928527,
                          0
                      ],
                      [
                          -73.393318,
                          45.905274,
                          0
                      ],
                      [
                          -73.392683,
                          45.905241,
                          0
                      ],
                      [
                          -73.345016,
                          45.871195,
                          0
                      ],
                      [
                          -73.35291,
                          45.864964,
                          0
                      ],
                      [
                          -73.356417,
                          45.860149,
                          0
                      ],
                      [
                          -73.355923,
                          45.859802,
                          0
                      ],
                      [
                          -73.361147,
                          45.855482,
                          0
                      ],
                      [
                          -73.362674,
                          45.856572,
                          0
                      ],
                      [
                          -73.364586,
                          45.855917,
                          0
                      ],
                      [
                          -73.372718,
                          45.851599,
                          0
                      ],
                      [
                          -73.372919,
                          45.851718,
                          0
                      ],
                      [
                          -73.375734,
                          45.850593,
                          0
                      ],
                      [
                          -73.375797,
                          45.850568,
                          0
                      ],
                      [
                          -73.379661,
                          45.847865,
                          0
                      ],
                      [
                          -73.376795,
                          45.845851,
                          0
                      ],
                      [
                          -73.376776,
                          45.845837,
                          0
                      ],
                      [
                          -73.379658,
                          45.843705,
                          0
                      ],
                      [
                          -73.382509,
                          45.845702,
                          0
                      ],
                      [
                          -73.382514,
                          45.845706,
                          0
                      ],
                      [
                          -73.386248,
                          45.843818,
                          0
                      ],
                      [
                          -73.391026,
                          45.847374,
                          0
                      ],
                      [
                          -73.391034,
                          45.84738,
                          0
                      ],
                      [
                          -73.394518,
                          45.84522,
                          0
                      ],
                      [
                          -73.389316,
                          45.841391,
                          0
                      ],
                      [
                          -73.39024,
                          45.840714,
                          0
                      ],
                      [
                          -73.386026,
                          45.837629,
                          0
                      ],
                      [
                          -73.387348,
                          45.836733,
                          0
                      ],
                      [
                          -73.388086,
                          45.836972,
                          0
                      ],
                      [
                          -73.390358,
                          45.835209,
                          0
                      ],
                      [
                          -73.389197,
                          45.83438,
                          0
                      ],
                      [
                          -73.389706,
                          45.833869,
                          0
                      ],
                      [
                          -73.392043,
                          45.831501,
                          0
                      ],
                      [
                          -73.391718,
                          45.831241,
                          0
                      ],
                      [
                          -73.394353,
                          45.829097,
                          0
                      ],
                      [
                          -73.394068,
                          45.828881,
                          0
                      ],
                      [
                          -73.395424,
                          45.826099,
                          0
                      ],
                      [
                          -73.397052,
                          45.822758,
                          0
                      ],
                      [
                          -73.397102,
                          45.822656,
                          0
                      ],
                      [
                          -73.398335,
                          45.821781,
                          0
                      ],
                      [
                          -73.395999,
                          45.820018,
                          0
                      ],
                      [
                          -73.396846,
                          45.818663,
                          0
                      ],
                      [
                          -73.399887,
                          45.814133,
                          0
                      ],
                      [
                          -73.399307,
                          45.813718,
                          0
                      ],
                      [
                          -73.399333,
                          45.813697,
                          0
                      ],
                      [
                          -73.401191,
                          45.812214,
                          0
                      ],
                      [
                          -73.401255,
                          45.812163,
                          0
                      ],
                      [
                          -73.408855,
                          45.817559,
                          0
                      ],
                      [
                          -73.4089,
                          45.817591,
                          0
                      ],
                      [
                          -73.409846,
                          45.816868,
                          0
                      ],
                      [
                          -73.407261,
                          45.814868,
                          0
                      ],
                      [
                          -73.408034,
                          45.814181,
                          0
                      ],
                      [
                          -73.402856,
                          45.810169,
                          0
                      ],
                      [
                          -73.40433,
                          45.808854,
                          0
                      ],
                      [
                          -73.405815,
                          45.804638,
                          0
                      ],
                      [
                          -73.409606,
                          45.800856,
                          0
                      ],
                      [
                          -73.414397,
                          45.798017,
                          0
                      ],
                      [
                          -73.417026,
                          45.794947,
                          0
                      ],
                      [
                          -73.421146,
                          45.796671,
                          0
                      ],
                      [
                          -73.423463,
                          45.793905,
                          0
                      ],
                      [
                          -73.421179,
                          45.792943,
                          0
                      ],
                      [
                          -73.42118,
                          45.79294,
                          0
                      ],
                      [
                          -73.422369,
                          45.791617,
                          0
                      ],
                      [
                          -73.422374,
                          45.791619,
                          0
                      ],
                      [
                          -73.424164,
                          45.792318,
                          0
                      ],
                      [
                          -73.425687,
                          45.791163,
                          0
                      ],
                      [
                          -73.424827,
                          45.790793,
                          0
                      ],
                      [
                          -73.426098,
                          45.789331,
                          0
                      ],
                      [
                          -73.424092,
                          45.788503,
                          0
                      ],
                      [
                          -73.42409,
                          45.788502,
                          0
                      ],
                      [
                          -73.425279,
                          45.787169,
                          0
                      ],
                      [
                          -73.425274,
                          45.787167,
                          0
                      ],
                      [
                          -73.423425,
                          45.786362,
                          0
                      ],
                      [
                          -73.424578,
                          45.785038,
                          0
                      ],
                      [
                          -73.422973,
                          45.784305,
                          0
                      ],
                      [
                          -73.424041,
                          45.783074,
                          0
                      ],
                      [
                          -73.438957,
                          45.789111,
                          0
                      ],
                      [
                          -73.441592,
                          45.78585,
                          0
                      ],
                      [
                          -73.444976,
                          45.784412,
                          0
                      ],
                      [
                          -73.448017,
                          45.783866,
                          0
                      ],
                      [
                          -73.449997,
                          45.78296,
                          0
                      ],
                      [
                          -73.450849,
                          45.779875,
                          0
                      ],
                      [
                          -73.4502,
                          45.777972,
                          0
                      ],
                      [
                          -73.449957,
                          45.773885,
                          0
                      ],
                      [
                          -73.451415,
                          45.7685,
                          0
                      ],
                      [
                          -73.457004,
                          45.761498,
                          0
                      ],
                      [
                          -73.462083,
                          45.755599,
                          0
                      ],
                      [
                          -73.467611,
                          45.751244,
                          0
                      ],
                      [
                          -73.472983,
                          45.746185,
                          0
                      ],
                      [
                          -73.477392,
                          45.743662,
                          0
                      ],
                      [
                          -73.478953,
                          45.741788,
                          0
                      ],
                      [
                          -73.48018,
                          45.739188,
                          0
                      ],
                      [
                          -73.482089,
                          45.732098,
                          0
                      ],
                      [
                          -73.483259,
                          45.728349,
                          0
                      ],
                      [
                          -73.481832,
                          45.723757,
                          0
                      ],
                      [
                          -73.481306,
                          45.721576,
                          0
                      ],
                      [
                          -73.481763,
                          45.717122,
                          0
                      ],
                      [
                          -73.481763,
                          45.717115,
                          0
                      ],
                      [
                          -73.480935,
                          45.713438,
                          0
                      ],
                      [
                          -73.487187,
                          45.712528,
                          0
                      ],
                      [
                          -73.487189,
                          45.71254,
                          0
                      ],
                      [
                          -73.488348,
                          45.717165,
                          0
                      ],
                      [
                          -73.492958,
                          45.720468,
                          0
                      ],
                      [
                          -73.493214,
                          45.719254,
                          0
                      ],
                      [
                          -73.497191,
                          45.722107,
                          0
                      ],
                      [
                          -73.497212,
                          45.725808,
                          0
                      ],
                      [
                          -73.500429,
                          45.728217,
                          0
                      ],
                      [
                          -73.500429,
                          45.728264,
                          0
                      ],
                      [
                          -73.500337,
                          45.735343,
                          0
                      ],
                      [
                          -73.502009,
                          45.736594,
                          0
                      ],
                      [
                          -73.502022,
                          45.736602,
                          0
                      ],
                      [
                          -73.518693,
                          45.744879,
                          0
                      ],
                      [
                          -73.518016,
                          45.74771,
                          0
                      ],
                      [
                          -73.5205,
                          45.748919,
                          0
                      ],
                      [
                          -73.520498,
                          45.749468,
                          0
                      ],
                      [
                          -73.528004,
                          45.753359,
                          0
                      ],
                      [
                          -73.524937,
                          45.792398,
                          0
                      ],
                      [
                          -73.551784,
                          45.793472,
                          0
                      ],
                      [
                          -73.550042,
                          45.816852,
                          0
                      ],
                      [
                          -73.550045,
                          45.816906,
                          0
                      ],
                      [
                          -73.550079,
                          45.816911,
                          0
                      ],
                      [
                          -73.580689,
                          45.820453,
                          0
                      ],
                      [
                          -73.579112,
                          45.827151,
                          0
                      ],
                      [
                          -73.578322,
                          45.827077,
                          0
                      ],
                      [
                          -73.578262,
                          45.827539,
                          0
                      ],
                      [
                          -73.563232,
                          45.825828,
                          0
                      ],
                      [
                          -73.563255,
                          45.825265,
                          0
                      ],
                      [
                          -73.558206,
                          45.824621,
                          0
                      ],
                      [
                          -73.557434,
                          45.836336,
                          0
                      ],
                      [
                          -73.554599,
                          45.836536,
                          0
                      ],
                      [
                          -73.552036,
                          45.838797,
                          0
                      ],
                      [
                          -73.548494,
                          45.840667,
                          0
                      ],
                      [
                          -73.545198,
                          45.843479,
                          0
                      ],
                      [
                          -73.542619,
                          45.843445,
                          0
                      ],
                      [
                          -73.538877,
                          45.842147,
                          0
                      ],
                      [
                          -73.537497,
                          45.860762,
                          0
                      ],
                      [
                          -73.533333,
                          45.861664,
                          0
                      ],
                      [
                          -73.540008,
                          45.866417,
                          0
                      ],
                      [
                          -73.538605,
                          45.867417,
                          0
                      ],
                      [
                          -73.537335,
                          45.866539,
                          0
                      ],
                      [
                          -73.535831,
                          45.86757,
                          0
                      ],
                      [
                          -73.537179,
                          45.868489,
                          0
                      ],
                      [
                          -73.535548,
                          45.869569,
                          0
                      ],
                      [
                          -73.536901,
                          45.870543,
                          0
                      ],
                      [
                          -73.5363,
                          45.870995,
                          0
                      ],
                      [
                          -73.530958,
                          45.867185,
                          0
                      ],
                      [
                          -73.528325,
                          45.868852,
                          0
                      ],
                      [
                          -73.528777,
                          45.868938,
                          0
                      ],
                      [
                          -73.5301,
                          45.869825,
                          0
                      ],
                      [
                          -73.531852,
                          45.870821,
                          0
                      ],
                      [
                          -73.532468,
                          45.870904,
                          0
                      ],
                      [
                          -73.533633,
                          45.871363,
                          0
                      ],
                      [
                          -73.533274,
                          45.871961,
                          0
                      ],
                      [
                          -73.532837,
                          45.87193,
                          0
                      ],
                      [
                          -73.531571,
                          45.872477,
                          0
                      ],
                      [
                          -73.530347,
                          45.871528,
                          0
                      ],
                      [
                          -73.528699,
                          45.871344,
                          0
                      ],
                      [
                          -73.528718,
                          45.870044,
                          0
                      ],
                      [
                          -73.527941,
                          45.869542,
                          0
                      ],
                      [
                          -73.527442,
                          45.869801,
                          0
                      ],
                      [
                          -73.526995,
                          45.870851,
                          0
                      ],
                      [
                          -73.525732,
                          45.870846,
                          0
                      ],
                      [
                          -73.525104,
                          45.870912,
                          0
                      ],
                      [
                          -73.523959,
                          45.871335,
                          0
                      ],
                      [
                          -73.532788,
                          45.877178,
                          0
                      ],
                      [
                          -73.498241,
                          45.899751,
                          0
                      ],
                      [
                          -73.49623,
                          45.899325,
                          0
                      ],
                      [
                          -73.494935,
                          45.899645,
                          0
                      ],
                      [
                          -73.493575,
                          45.900552,
                          0
                      ],
                      [
                          -73.493437,
                          45.902403,
                          0
                      ],
                      [
                          -73.488771,
                          45.901064,
                          0
                      ],
                      [
                          -73.487247,
                          45.899843,
                          0
                      ],
                      [
                          -73.488634,
                          45.896557,
                          0
                      ],
                      [
                          -73.490213,
                          45.894473,
                          0
                      ],
                      [
                          -73.489157,
                          45.892912,
                          0
                      ],
                      [
                          -73.486449,
                          45.892304,
                          0
                      ],
                      [
                          -73.484016,
                          45.891597,
                          0
                      ],
                      [
                          -73.480892,
                          45.892524,
                          0
                      ],
                      [
                          -73.48125,
                          45.895071,
                          0
                      ],
                      [
                          -73.481168,
                          45.89688,
                          0
                      ],
                      [
                          -73.478624,
                          45.897342,
                          0
                      ],
                      [
                          -73.47753,
                          45.898772,
                          0
                      ],
                      [
                          -73.475164,
                          45.899297,
                          0
                      ],
                      [
                          -73.473138,
                          45.899667,
                          0
                      ],
                      [
                          -73.471593,
                          45.900706,
                          0
                      ],
                      [
                          -73.47008,
                          45.90287,
                          0
                      ],
                      [
                          -73.467113,
                          45.904053,
                          0
                      ],
                      [
                          -73.463898,
                          45.904803,
                          0
                      ],
                      [
                          -73.456735,
                          45.943877,
                          0
                      ],
                      [
                          -73.428947,
                          45.923716,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "L'Assomption",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -73.456735,
                          45.943877,
                          0
                      ],
                      [
                          -73.463898,
                          45.904803,
                          0
                      ],
                      [
                          -73.467113,
                          45.904053,
                          0
                      ],
                      [
                          -73.47008,
                          45.90287,
                          0
                      ],
                      [
                          -73.471593,
                          45.900706,
                          0
                      ],
                      [
                          -73.473138,
                          45.899667,
                          0
                      ],
                      [
                          -73.475164,
                          45.899297,
                          0
                      ],
                      [
                          -73.47753,
                          45.898772,
                          0
                      ],
                      [
                          -73.478624,
                          45.897342,
                          0
                      ],
                      [
                          -73.481168,
                          45.89688,
                          0
                      ],
                      [
                          -73.48125,
                          45.895071,
                          0
                      ],
                      [
                          -73.480892,
                          45.892524,
                          0
                      ],
                      [
                          -73.484016,
                          45.891597,
                          0
                      ],
                      [
                          -73.486449,
                          45.892304,
                          0
                      ],
                      [
                          -73.489157,
                          45.892912,
                          0
                      ],
                      [
                          -73.490213,
                          45.894473,
                          0
                      ],
                      [
                          -73.488634,
                          45.896557,
                          0
                      ],
                      [
                          -73.487247,
                          45.899843,
                          0
                      ],
                      [
                          -73.488771,
                          45.901064,
                          0
                      ],
                      [
                          -73.493437,
                          45.902403,
                          0
                      ],
                      [
                          -73.493575,
                          45.900552,
                          0
                      ],
                      [
                          -73.494935,
                          45.899645,
                          0
                      ],
                      [
                          -73.49623,
                          45.899325,
                          0
                      ],
                      [
                          -73.498241,
                          45.899751,
                          0
                      ],
                      [
                          -73.532788,
                          45.877178,
                          0
                      ],
                      [
                          -73.523959,
                          45.871335,
                          0
                      ],
                      [
                          -73.525104,
                          45.870912,
                          0
                      ],
                      [
                          -73.525732,
                          45.870846,
                          0
                      ],
                      [
                          -73.526995,
                          45.870851,
                          0
                      ],
                      [
                          -73.527442,
                          45.869801,
                          0
                      ],
                      [
                          -73.527941,
                          45.869542,
                          0
                      ],
                      [
                          -73.528718,
                          45.870044,
                          0
                      ],
                      [
                          -73.528699,
                          45.871344,
                          0
                      ],
                      [
                          -73.530347,
                          45.871528,
                          0
                      ],
                      [
                          -73.531571,
                          45.872477,
                          0
                      ],
                      [
                          -73.532837,
                          45.87193,
                          0
                      ],
                      [
                          -73.533274,
                          45.871961,
                          0
                      ],
                      [
                          -73.533633,
                          45.871363,
                          0
                      ],
                      [
                          -73.532468,
                          45.870904,
                          0
                      ],
                      [
                          -73.531852,
                          45.870821,
                          0
                      ],
                      [
                          -73.5301,
                          45.869825,
                          0
                      ],
                      [
                          -73.528777,
                          45.868938,
                          0
                      ],
                      [
                          -73.528325,
                          45.868852,
                          0
                      ],
                      [
                          -73.530958,
                          45.867185,
                          0
                      ],
                      [
                          -73.5363,
                          45.870995,
                          0
                      ],
                      [
                          -73.536901,
                          45.870543,
                          0
                      ],
                      [
                          -73.535548,
                          45.869569,
                          0
                      ],
                      [
                          -73.537179,
                          45.868489,
                          0
                      ],
                      [
                          -73.535831,
                          45.86757,
                          0
                      ],
                      [
                          -73.537335,
                          45.866539,
                          0
                      ],
                      [
                          -73.538605,
                          45.867417,
                          0
                      ],
                      [
                          -73.540008,
                          45.866417,
                          0
                      ],
                      [
                          -73.533333,
                          45.861664,
                          0
                      ],
                      [
                          -73.537497,
                          45.860762,
                          0
                      ],
                      [
                          -73.538877,
                          45.842147,
                          0
                      ],
                      [
                          -73.542619,
                          45.843445,
                          0
                      ],
                      [
                          -73.545198,
                          45.843479,
                          0
                      ],
                      [
                          -73.548494,
                          45.840667,
                          0
                      ],
                      [
                          -73.552036,
                          45.838797,
                          0
                      ],
                      [
                          -73.554599,
                          45.836536,
                          0
                      ],
                      [
                          -73.557434,
                          45.836336,
                          0
                      ],
                      [
                          -73.558206,
                          45.824621,
                          0
                      ],
                      [
                          -73.563255,
                          45.825265,
                          0
                      ],
                      [
                          -73.563232,
                          45.825828,
                          0
                      ],
                      [
                          -73.578262,
                          45.827539,
                          0
                      ],
                      [
                          -73.578322,
                          45.827077,
                          0
                      ],
                      [
                          -73.579112,
                          45.827151,
                          0
                      ],
                      [
                          -73.580689,
                          45.820453,
                          0
                      ],
                      [
                          -73.580821,
                          45.819351,
                          0
                      ],
                      [
                          -73.580173,
                          45.819282,
                          0
                      ],
                      [
                          -73.580381,
                          45.815996,
                          0
                      ],
                      [
                          -73.580422,
                          45.816,
                          0
                      ],
                      [
                          -73.590593,
                          45.816952,
                          0
                      ],
                      [
                          -73.591141,
                          45.812224,
                          0
                      ],
                      [
                          -73.593294,
                          45.81246,
                          0
                      ],
                      [
                          -73.594509,
                          45.813261,
                          0
                      ],
                      [
                          -73.594555,
                          45.81323,
                          0
                      ],
                      [
                          -73.619491,
                          45.796392,
                          0
                      ],
                      [
                          -73.619519,
                          45.79641,
                          0
                      ],
                      [
                          -73.64429,
                          45.813567,
                          0
                      ],
                      [
                          -73.645947,
                          45.81272,
                          0
                      ],
                      [
                          -73.653733,
                          45.803072,
                          0
                      ],
                      [
                          -73.6537,
                          45.803042,
                          0
                      ],
                      [
                          -73.654001,
                          45.801034,
                          0
                      ],
                      [
                          -73.652883,
                          45.799927,
                          0
                      ],
                      [
                          -73.65393,
                          45.799225,
                          0
                      ],
                      [
                          -73.653092,
                          45.798443,
                          0
                      ],
                      [
                          -73.653999,
                          45.796053,
                          0
                      ],
                      [
                          -73.654022,
                          45.79604,
                          0
                      ],
                      [
                          -73.661567,
                          45.792244,
                          0
                      ],
                      [
                          -73.660644,
                          45.791356,
                          0
                      ],
                      [
                          -73.669096,
                          45.786716,
                          0
                      ],
                      [
                          -73.669462,
                          45.786983,
                          0
                      ],
                      [
                          -73.669107,
                          45.787643,
                          0
                      ],
                      [
                          -73.67561,
                          45.789174,
                          0
                      ],
                      [
                          -73.675741,
                          45.789602,
                          0
                      ],
                      [
                          -73.677496,
                          45.790029,
                          0
                      ],
                      [
                          -73.677708,
                          45.789743,
                          0
                      ],
                      [
                          -73.677717,
                          45.789745,
                          0
                      ],
                      [
                          -73.679855,
                          45.790257,
                          0
                      ],
                      [
                          -73.68185,
                          45.791199,
                          0
                      ],
                      [
                          -73.685826,
                          45.79247,
                          0
                      ],
                      [
                          -73.687975,
                          45.794164,
                          0
                      ],
                      [
                          -73.688111,
                          45.794258,
                          0
                      ],
                      [
                          -73.690221,
                          45.79444,
                          0
                      ],
                      [
                          -73.694217,
                          45.795621,
                          0
                      ],
                      [
                          -73.693872,
                          45.796227,
                          0
                      ],
                      [
                          -73.695684,
                          45.796571,
                          0
                      ],
                      [
                          -73.696106,
                          45.795765,
                          0
                      ],
                      [
                          -73.700513,
                          45.796941,
                          0
                      ],
                      [
                          -73.696865,
                          45.804394,
                          0
                      ],
                      [
                          -73.696866,
                          45.804394,
                          0
                      ],
                      [
                          -73.740605,
                          45.805618,
                          0
                      ],
                      [
                          -73.747235,
                          45.810039,
                          0
                      ],
                      [
                          -73.755626,
                          45.811622,
                          0
                      ],
                      [
                          -73.755378,
                          45.81343,
                          0
                      ],
                      [
                          -73.769631,
                          45.816725,
                          0
                      ],
                      [
                          -73.769664,
                          45.816733,
                          0
                      ],
                      [
                          -73.770638,
                          45.810322,
                          0
                      ],
                      [
                          -73.770642,
                          45.810296,
                          0
                      ],
                      [
                          -73.776623,
                          45.81055,
                          0
                      ],
                      [
                          -73.779345,
                          45.815015,
                          0
                      ],
                      [
                          -73.797655,
                          45.827175,
                          0
                      ],
                      [
                          -73.80039,
                          45.826485,
                          0
                      ],
                      [
                          -73.807612,
                          45.821661,
                          0
                      ],
                      [
                          -73.807631,
                          45.821674,
                          0
                      ],
                      [
                          -73.901327,
                          45.884534,
                          0
                      ],
                      [
                          -73.882593,
                          45.888473,
                          0
                      ],
                      [
                          -73.882587,
                          45.888475,
                          0
                      ],
                      [
                          -73.950547,
                          45.933183,
                          0
                      ],
                      [
                          -73.950367,
                          45.933315,
                          0
                      ],
                      [
                          -73.935414,
                          45.944245,
                          0
                      ],
                      [
                          -73.935502,
                          45.944295,
                          0
                      ],
                      [
                          -73.95252,
                          45.954381,
                          0
                      ],
                      [
                          -73.952519,
                          45.954382,
                          0
                      ],
                      [
                          -73.947144,
                          45.958031,
                          0
                      ],
                      [
                          -73.947146,
                          45.958032,
                          0
                      ],
                      [
                          -73.964698,
                          45.96806,
                          0
                      ],
                      [
                          -73.964722,
                          45.968073,
                          0
                      ],
                      [
                          -73.964729,
                          45.968077,
                          0
                      ],
                      [
                          -73.976808,
                          45.976624,
                          0
                      ],
                      [
                          -73.974013,
                          45.978329,
                          0
                      ],
                      [
                          -73.974012,
                          45.97833,
                          0
                      ],
                      [
                          -73.973999,
                          45.978338,
                          0
                      ],
                      [
                          -73.923624,
                          46.017443,
                          0
                      ],
                      [
                          -73.923611,
                          46.017453,
                          0
                      ],
                      [
                          -73.923609,
                          46.017453,
                          0
                      ],
                      [
                          -73.921093,
                          46.019244,
                          0
                      ],
                      [
                          -73.918589,
                          46.017721,
                          0
                      ],
                      [
                          -73.918102,
                          46.019163,
                          0
                      ],
                      [
                          -73.910478,
                          46.014477,
                          0
                      ],
                      [
                          -73.9079,
                          46.01619,
                          0
                      ],
                      [
                          -73.89867,
                          46.010525,
                          0
                      ],
                      [
                          -73.873435,
                          46.028234,
                          0
                      ],
                      [
                          -73.873396,
                          46.028259,
                          0
                      ],
                      [
                          -73.788021,
                          45.977017,
                          0
                      ],
                      [
                          -73.788017,
                          45.97702,
                          0
                      ],
                      [
                          -73.78781,
                          45.977158,
                          0
                      ],
                      [
                          -73.707748,
                          46.031171,
                          0
                      ],
                      [
                          -73.705398,
                          46.031422,
                          0
                      ],
                      [
                          -73.705037,
                          46.029011,
                          0
                      ],
                      [
                          -73.704877,
                          46.028944,
                          0
                      ],
                      [
                          -73.704328,
                          46.029043,
                          0
                      ],
                      [
                          -73.703451,
                          46.030388,
                          0
                      ],
                      [
                          -73.699005,
                          46.031167,
                          0
                      ],
                      [
                          -73.698353,
                          46.033351,
                          0
                      ],
                      [
                          -73.698799,
                          46.03456,
                          0
                      ],
                      [
                          -73.697313,
                          46.035432,
                          0
                      ],
                      [
                          -73.695532,
                          46.035013,
                          0
                      ],
                      [
                          -73.694207,
                          46.033408,
                          0
                      ],
                      [
                          -73.69147,
                          46.032158,
                          0
                      ],
                      [
                          -73.688144,
                          46.031385,
                          0
                      ],
                      [
                          -73.687459,
                          46.031571,
                          0
                      ],
                      [
                          -73.687651,
                          46.032434,
                          0
                      ],
                      [
                          -73.687904,
                          46.033726,
                          0
                      ],
                      [
                          -73.686883,
                          46.034292,
                          0
                      ],
                      [
                          -73.68602,
                          46.032825,
                          0
                      ],
                      [
                          -73.684047,
                          46.03199,
                          0
                      ],
                      [
                          -73.681784,
                          46.032346,
                          0
                      ],
                      [
                          -73.679026,
                          46.029285,
                          0
                      ],
                      [
                          -73.677566,
                          46.026175,
                          0
                      ],
                      [
                          -73.677672,
                          46.022934,
                          0
                      ],
                      [
                          -73.676724,
                          46.021159,
                          0
                      ],
                      [
                          -73.677914,
                          46.018162,
                          0
                      ],
                      [
                          -73.67677,
                          46.017326,
                          0
                      ],
                      [
                          -73.675905,
                          46.016012,
                          0
                      ],
                      [
                          -73.672668,
                          46.014409,
                          0
                      ],
                      [
                          -73.669229,
                          46.012911,
                          0
                      ],
                      [
                          -73.666542,
                          46.014261,
                          0
                      ],
                      [
                          -73.663863,
                          46.014017,
                          0
                      ],
                      [
                          -73.662565,
                          46.012609,
                          0
                      ],
                      [
                          -73.658051,
                          46.009733,
                          0
                      ],
                      [
                          -73.622352,
                          46.034518,
                          0
                      ],
                      [
                          -73.622317,
                          46.034543,
                          0
                      ],
                      [
                          -73.634575,
                          46.0433,
                          0
                      ],
                      [
                          -73.634595,
                          46.043313,
                          0
                      ],
                      [
                          -73.610291,
                          46.059344,
                          0
                      ],
                      [
                          -73.567836,
                          46.030271,
                          0
                      ],
                      [
                          -73.548913,
                          46.043084,
                          0
                      ],
                      [
                          -73.534341,
                          46.032949,
                          0
                      ],
                      [
                          -73.530411,
                          46.035672,
                          0
                      ],
                      [
                          -73.530379,
                          46.035648,
                          0
                      ],
                      [
                          -73.517141,
                          46.026203,
                          0
                      ],
                      [
                          -73.521619,
                          46.023115,
                          0
                      ],
                      [
                          -73.508018,
                          46.013434,
                          0
                      ],
                      [
                          -73.525418,
                          46.002015,
                          0
                      ],
                      [
                          -73.525455,
                          46.001991,
                          0
                      ],
                      [
                          -73.512255,
                          45.993035,
                          0
                      ],
                      [
                          -73.510855,
                          45.993954,
                          0
                      ],
                      [
                          -73.510404,
                          45.992392,
                          0
                      ],
                      [
                          -73.512888,
                          45.990268,
                          0
                      ],
                      [
                          -73.515234,
                          45.991166,
                          0
                      ],
                      [
                          -73.51666,
                          45.990265,
                          0
                      ],
                      [
                          -73.516541,
                          45.990017,
                          0
                      ],
                      [
                          -73.540517,
                          45.972495,
                          0
                      ],
                      [
                          -73.518611,
                          45.962952,
                          0
                      ],
                      [
                          -73.516386,
                          45.964585,
                          0
                      ],
                      [
                          -73.516351,
                          45.964575,
                          0
                      ],
                      [
                          -73.502351,
                          45.95894,
                          0
                      ],
                      [
                          -73.487242,
                          45.956273,
                          0
                      ],
                      [
                          -73.476234,
                          45.957538,
                          0
                      ],
                      [
                          -73.470716,
                          45.959837,
                          0
                      ],
                      [
                          -73.470713,
                          45.959838,
                          0
                      ],
                      [
                          -73.459288,
                          45.951253,
                          0
                      ],
                      [
                          -73.459979,
                          45.949746,
                          0
                      ],
                      [
                          -73.459151,
                          45.949123,
                          0
                      ],
                      [
                          -73.459479,
                          45.948402,
                          0
                      ],
                      [
                          -73.459473,
                          45.948398,
                          0
                      ],
                      [
                          -73.456397,
                          45.946265,
                          0
                      ],
                      [
                          -73.456735,
                          45.943877,
                          0
                      ],
                      [
                          -73.456735,
                          45.943877,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Rousseau",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -74.889381,
                          47.762639,
                          0
                      ],
                      [
                          -74.511522,
                          47.762908,
                          0
                      ],
                      [
                          -73.957424,
                          47.388167,
                          0
                      ],
                      [
                          -73.996543,
                          47.349811,
                          0
                      ],
                      [
                          -74.028575,
                          47.177833,
                          0
                      ],
                      [
                          -73.910584,
                          47.099078,
                          0
                      ],
                      [
                          -73.853246,
                          47.151078,
                          0
                      ],
                      [
                          -73.754219,
                          47.065172,
                          0
                      ],
                      [
                          -73.646069,
                          46.937292,
                          0
                      ],
                      [
                          -73.66421,
                          46.928432,
                          0
                      ],
                      [
                          -73.659509,
                          46.890783,
                          0
                      ],
                      [
                          -73.66937,
                          46.888226,
                          0
                      ],
                      [
                          -73.662739,
                          46.882613,
                          0
                      ],
                      [
                          -73.672456,
                          46.879693,
                          0
                      ],
                      [
                          -73.673037,
                          46.87217,
                          0
                      ],
                      [
                          -73.667142,
                          46.866173,
                          0
                      ],
                      [
                          -73.624844,
                          46.8646,
                          0
                      ],
                      [
                          -73.608128,
                          46.857958,
                          0
                      ],
                      [
                          -73.605362,
                          46.864947,
                          0
                      ],
                      [
                          -73.602621,
                          46.872143,
                          0
                      ],
                      [
                          -73.570659,
                          46.872912,
                          0
                      ],
                      [
                          -73.570227,
                          46.880114,
                          0
                      ],
                      [
                          -73.55931,
                          46.882469,
                          0
                      ],
                      [
                          -73.555894,
                          46.875444,
                          0
                      ],
                      [
                          -73.539699,
                          46.866288,
                          0
                      ],
                      [
                          -73.529208,
                          46.867312,
                          0
                      ],
                      [
                          -73.526737,
                          46.882651,
                          0
                      ],
                      [
                          -73.51521,
                          46.89502,
                          0
                      ],
                      [
                          -73.497624,
                          46.903298,
                          0
                      ],
                      [
                          -73.499285,
                          46.910515,
                          0
                      ],
                      [
                          -73.479268,
                          46.906588,
                          0
                      ],
                      [
                          -73.45727,
                          46.906654,
                          0
                      ],
                      [
                          -73.44188,
                          46.8962,
                          0
                      ],
                      [
                          -73.4202,
                          46.88835,
                          0
                      ],
                      [
                          -73.441506,
                          46.843685,
                          0
                      ],
                      [
                          -73.43634,
                          46.829242,
                          0
                      ],
                      [
                          -73.456934,
                          46.786325,
                          0
                      ],
                      [
                          -73.447674,
                          46.766379,
                          0
                      ],
                      [
                          -73.466956,
                          46.709485,
                          0
                      ],
                      [
                          -73.441989,
                          46.668657,
                          0
                      ],
                      [
                          -73.446841,
                          46.612264,
                          0
                      ],
                      [
                          -73.446012,
                          46.596265,
                          0
                      ],
                      [
                          -73.402205,
                          46.598823,
                          0
                      ],
                      [
                          -73.361844,
                          46.61624,
                          0
                      ],
                      [
                          -73.336513,
                          46.601263,
                          0
                      ],
                      [
                          -73.312225,
                          46.573686,
                          0
                      ],
                      [
                          -73.306957,
                          46.555153,
                          0
                      ],
                      [
                          -73.298145,
                          46.544678,
                          0
                      ],
                      [
                          -73.283007,
                          46.533307,
                          0
                      ],
                      [
                          -73.333119,
                          46.498713,
                          0
                      ],
                      [
                          -73.333116,
                          46.498712,
                          0
                      ],
                      [
                          -73.333107,
                          46.498709,
                          0
                      ],
                      [
                          -73.333092,
                          46.498705,
                          0
                      ],
                      [
                          -73.248831,
                          46.439831,
                          0
                      ],
                      [
                          -73.167319,
                          46.38299,
                          0
                      ],
                      [
                          -73.174608,
                          46.378291,
                          0
                      ],
                      [
                          -73.169132,
                          46.374385,
                          0
                      ],
                      [
                          -73.180934,
                          46.366115,
                          0
                      ],
                      [
                          -73.187809,
                          46.370376,
                          0
                      ],
                      [
                          -73.203876,
                          46.35902,
                          0
                      ],
                      [
                          -73.19207,
                          46.350252,
                          0
                      ],
                      [
                          -73.203658,
                          46.342047,
                          0
                      ],
                      [
                          -73.176219,
                          46.323451,
                          0
                      ],
                      [
                          -73.178965,
                          46.32216,
                          0
                      ],
                      [
                          -73.185171,
                          46.323587,
                          0
                      ],
                      [
                          -73.190811,
                          46.322677,
                          0
                      ],
                      [
                          -73.19264,
                          46.321379,
                          0
                      ],
                      [
                          -73.196668,
                          46.321224,
                          0
                      ],
                      [
                          -73.199298,
                          46.322204,
                          0
                      ],
                      [
                          -73.20319,
                          46.324858,
                          0
                      ],
                      [
                          -73.206404,
                          46.321925,
                          0
                      ],
                      [
                          -73.212149,
                          46.32477,
                          0
                      ],
                      [
                          -73.215222,
                          46.32463,
                          0
                      ],
                      [
                          -73.219005,
                          46.323333,
                          0
                      ],
                      [
                          -73.228114,
                          46.317033,
                          0
                      ],
                      [
                          -73.195678,
                          46.294177,
                          0
                      ],
                      [
                          -73.215107,
                          46.278468,
                          0
                      ],
                      [
                          -73.037318,
                          46.156411,
                          0
                      ],
                      [
                          -73.018245,
                          46.160678,
                          0
                      ],
                      [
                          -73.007142,
                          46.165238,
                          0
                      ],
                      [
                          -72.992613,
                          46.163065,
                          0
                      ],
                      [
                          -72.983121,
                          46.167628,
                          0
                      ],
                      [
                          -72.936245,
                          46.151238,
                          0
                      ],
                      [
                          -72.967103,
                          46.113121,
                          0
                      ],
                      [
                          -72.990819,
                          46.111594,
                          0
                      ],
                      [
                          -72.998119,
                          46.112783,
                          0
                      ],
                      [
                          -73.028231,
                          46.109144,
                          0
                      ],
                      [
                          -73.050759,
                          46.100783,
                          0
                      ],
                      [
                          -73.074599,
                          46.073885,
                          0
                      ],
                      [
                          -73.084658,
                          46.065907,
                          0
                      ],
                      [
                          -73.145585,
                          46.051315,
                          0
                      ],
                      [
                          -73.164742,
                          46.035797,
                          0
                      ],
                      [
                          -73.164835,
                          46.035657,
                          0
                      ],
                      [
                          -73.186353,
                          45.984591,
                          0
                      ],
                      [
                          -73.186378,
                          45.984541,
                          0
                      ],
                      [
                          -73.230877,
                          45.924225,
                          0
                      ],
                      [
                          -73.243416,
                          45.892309,
                          0
                      ],
                      [
                          -73.271701,
                          45.860217,
                          0
                      ],
                      [
                          -73.274774,
                          45.849923,
                          0
                      ],
                      [
                          -73.27478,
                          45.849924,
                          0
                      ],
                      [
                          -73.286166,
                          45.850983,
                          0
                      ],
                      [
                          -73.305299,
                          45.845726,
                          0
                      ],
                      [
                          -73.31533,
                          45.850036,
                          0
                      ],
                      [
                          -73.345016,
                          45.871195,
                          0
                      ],
                      [
                          -73.392683,
                          45.905241,
                          0
                      ],
                      [
                          -73.389243,
                          45.907768,
                          0
                      ],
                      [
                          -73.391582,
                          45.909427,
                          0
                      ],
                      [
                          -73.389899,
                          45.910989,
                          0
                      ],
                      [
                          -73.3873,
                          45.909102,
                          0
                      ],
                      [
                          -73.382901,
                          45.913188,
                          0
                      ],
                      [
                          -73.384796,
                          45.914595,
                          0
                      ],
                      [
                          -73.369047,
                          45.925429,
                          0
                      ],
                      [
                          -73.364857,
                          45.933066,
                          0
                      ],
                      [
                          -73.360709,
                          45.935501,
                          0
                      ],
                      [
                          -73.366224,
                          45.939583,
                          0
                      ],
                      [
                          -73.359346,
                          45.945058,
                          0
                      ],
                      [
                          -73.361933,
                          45.946774,
                          0
                      ],
                      [
                          -73.359324,
                          45.949675,
                          0
                      ],
                      [
                          -73.360975,
                          45.950864,
                          0
                      ],
                      [
                          -73.357454,
                          45.954139,
                          0
                      ],
                      [
                          -73.355461,
                          45.952116,
                          0
                      ],
                      [
                          -73.353057,
                          45.954438,
                          0
                      ],
                      [
                          -73.357061,
                          45.95727,
                          0
                      ],
                      [
                          -73.355262,
                          45.958515,
                          0
                      ],
                      [
                          -73.360061,
                          45.961872,
                          0
                      ],
                      [
                          -73.350481,
                          45.968284,
                          0
                      ],
                      [
                          -73.328381,
                          45.953532,
                          0
                      ],
                      [
                          -73.331192,
                          45.96258,
                          0
                      ],
                      [
                          -73.324133,
                          45.970153,
                          0
                      ],
                      [
                          -73.312472,
                          45.97486,
                          0
                      ],
                      [
                          -73.322165,
                          45.982476,
                          0
                      ],
                      [
                          -73.315578,
                          45.983751,
                          0
                      ],
                      [
                          -73.303585,
                          45.993677,
                          0
                      ],
                      [
                          -73.295845,
                          46.004125,
                          0
                      ],
                      [
                          -73.281306,
                          46.01324,
                          0
                      ],
                      [
                          -73.274104,
                          46.026863,
                          0
                      ],
                      [
                          -73.26987,
                          46.025728,
                          0
                      ],
                      [
                          -73.268955,
                          46.035086,
                          0
                      ],
                      [
                          -73.259493,
                          46.044377,
                          0
                      ],
                      [
                          -73.249189,
                          46.045484,
                          0
                      ],
                      [
                          -73.245942,
                          46.051433,
                          0
                      ],
                      [
                          -73.233888,
                          46.05511,
                          0
                      ],
                      [
                          -73.257549,
                          46.071984,
                          0
                      ],
                      [
                          -73.266592,
                          46.071161,
                          0
                      ],
                      [
                          -73.278695,
                          46.074359,
                          0
                      ],
                      [
                          -73.291633,
                          46.075922,
                          0
                      ],
                      [
                          -73.29175,
                          46.072686,
                          0
                      ],
                      [
                          -73.296202,
                          46.064907,
                          0
                      ],
                      [
                          -73.298963,
                          46.064743,
                          0
                      ],
                      [
                          -73.303584,
                          46.063059,
                          0
                      ],
                      [
                          -73.303705,
                          46.068547,
                          0
                      ],
                      [
                          -73.305293,
                          46.068541,
                          0
                      ],
                      [
                          -73.305522,
                          46.06617,
                          0
                      ],
                      [
                          -73.308461,
                          46.066096,
                          0
                      ],
                      [
                          -73.308429,
                          46.06271,
                          0
                      ],
                      [
                          -73.31183,
                          46.060899,
                          0
                      ],
                      [
                          -73.311842,
                          46.063568,
                          0
                      ],
                      [
                          -73.311872,
                          46.076773,
                          0
                      ],
                      [
                          -73.315278,
                          46.076791,
                          0
                      ],
                      [
                          -73.315264,
                          46.066209,
                          0
                      ],
                      [
                          -73.321795,
                          46.06707,
                          0
                      ],
                      [
                          -73.321672,
                          46.055633,
                          0
                      ],
                      [
                          -73.326121,
                          46.057099,
                          0
                      ],
                      [
                          -73.339234,
                          46.059142,
                          0
                      ],
                      [
                          -73.351429,
                          46.056145,
                          0
                      ],
                      [
                          -73.376283,
                          46.060979,
                          0
                      ],
                      [
                          -73.387117,
                          46.065893,
                          0
                      ],
                      [
                          -73.399896,
                          46.06872,
                          0
                      ],
                      [
                          -73.412122,
                          46.069311,
                          0
                      ],
                      [
                          -73.412064,
                          46.072461,
                          0
                      ],
                      [
                          -73.411862,
                          46.083328,
                          0
                      ],
                      [
                          -73.409032,
                          46.08338,
                          0
                      ],
                      [
                          -73.397187,
                          46.079051,
                          0
                      ],
                      [
                          -73.397312,
                          46.100635,
                          0
                      ],
                      [
                          -73.401464,
                          46.109964,
                          0
                      ],
                      [
                          -73.406741,
                          46.119618,
                          0
                      ],
                      [
                          -73.418132,
                          46.115117,
                          0
                      ],
                      [
                          -73.447006,
                          46.115013,
                          0
                      ],
                      [
                          -73.460997,
                          46.114873,
                          0
                      ],
                      [
                          -73.466446,
                          46.123061,
                          0
                      ],
                      [
                          -73.45799,
                          46.129891,
                          0
                      ],
                      [
                          -73.44625,
                          46.134048,
                          0
                      ],
                      [
                          -73.435025,
                          46.128913,
                          0
                      ],
                      [
                          -73.440711,
                          46.137184,
                          0
                      ],
                      [
                          -73.454078,
                          46.13774,
                          0
                      ],
                      [
                          -73.459837,
                          46.145856,
                          0
                      ],
                      [
                          -73.464258,
                          46.148311,
                          0
                      ],
                      [
                          -73.471027,
                          46.146757,
                          0
                      ],
                      [
                          -73.472866,
                          46.145213,
                          0
                      ],
                      [
                          -73.463309,
                          46.140959,
                          0
                      ],
                      [
                          -73.466028,
                          46.137404,
                          0
                      ],
                      [
                          -73.479241,
                          46.138781,
                          0
                      ],
                      [
                          -73.488688,
                          46.145078,
                          0
                      ],
                      [
                          -73.493237,
                          46.153526,
                          0
                      ],
                      [
                          -73.483374,
                          46.159763,
                          0
                      ],
                      [
                          -73.490292,
                          46.167588,
                          0
                      ],
                      [
                          -73.480304,
                          46.168875,
                          0
                      ],
                      [
                          -73.481515,
                          46.179924,
                          0
                      ],
                      [
                          -73.486105,
                          46.17696,
                          0
                      ],
                      [
                          -73.497341,
                          46.181633,
                          0
                      ],
                      [
                          -73.508839,
                          46.186331,
                          0
                      ],
                      [
                          -73.521286,
                          46.189045,
                          0
                      ],
                      [
                          -73.522317,
                          46.194091,
                          0
                      ],
                      [
                          -73.531938,
                          46.194821,
                          0
                      ],
                      [
                          -73.545007,
                          46.194756,
                          0
                      ],
                      [
                          -73.555509,
                          46.200166,
                          0
                      ],
                      [
                          -73.560817,
                          46.205661,
                          0
                      ],
                      [
                          -73.567065,
                          46.204218,
                          0
                      ],
                      [
                          -73.564206,
                          46.201945,
                          0
                      ],
                      [
                          -73.585095,
                          46.188909,
                          0
                      ],
                      [
                          -73.564579,
                          46.173151,
                          0
                      ],
                      [
                          -73.599632,
                          46.150173,
                          0
                      ],
                      [
                          -73.566921,
                          46.125646,
                          0
                      ],
                      [
                          -73.637829,
                          46.079087,
                          0
                      ],
                      [
                          -73.637837,
                          46.079094,
                          0
                      ],
                      [
                          -74.343519,
                          46.549237,
                          0
                      ],
                      [
                          -74.444027,
                          46.620295,
                          0
                      ],
                      [
                          -74.454507,
                          46.607079,
                          0
                      ],
                      [
                          -74.467728,
                          46.610634,
                          0
                      ],
                      [
                          -74.480194,
                          46.606935,
                          0
                      ],
                      [
                          -74.486893,
                          46.598743,
                          0
                      ],
                      [
                          -74.499343,
                          46.593519,
                          0
                      ],
                      [
                          -74.499319,
                          46.602633,
                          0
                      ],
                      [
                          -74.496388,
                          46.631325,
                          0
                      ],
                      [
                          -74.506922,
                          46.648442,
                          0
                      ],
                      [
                          -74.502802,
                          46.657271,
                          0
                      ],
                      [
                          -74.50958,
                          46.665075,
                          0
                      ],
                      [
                          -74.507829,
                          46.683263,
                          0
                      ],
                      [
                          -74.499981,
                          46.691413,
                          0
                      ],
                      [
                          -74.501143,
                          46.70047,
                          0
                      ],
                      [
                          -74.489058,
                          46.716496,
                          0
                      ],
                      [
                          -74.495727,
                          46.72435,
                          0
                      ],
                      [
                          -74.487585,
                          46.731743,
                          0
                      ],
                      [
                          -74.49961,
                          46.735633,
                          0
                      ],
                      [
                          -74.498083,
                          46.754859,
                          0
                      ],
                      [
                          -74.482553,
                          46.780855,
                          0
                      ],
                      [
                          -74.483803,
                          46.790018,
                          0
                      ],
                      [
                          -74.472172,
                          46.794332,
                          0
                      ],
                      [
                          -74.477308,
                          46.830823,
                          0
                      ],
                      [
                          -74.484253,
                          46.83883,
                          0
                      ],
                      [
                          -74.484498,
                          46.859049,
                          0
                      ],
                      [
                          -74.454484,
                          46.878371,
                          0
                      ],
                      [
                          -74.450442,
                          46.887194,
                          0
                      ],
                      [
                          -74.427757,
                          46.896843,
                          0
                      ],
                      [
                          -74.57471,
                          46.997502,
                          0
                      ],
                      [
                          -74.415174,
                          47.100719,
                          0
                      ],
                      [
                          -74.466049,
                          47.135695,
                          0
                      ],
                      [
                          -74.434522,
                          47.157527,
                          0
                      ],
                      [
                          -74.489746,
                          47.224559,
                          0
                      ],
                      [
                          -74.654081,
                          47.23395,
                          0
                      ],
                      [
                          -74.652731,
                          47.24826,
                          0
                      ],
                      [
                          -74.64045,
                          47.251707,
                          0
                      ],
                      [
                          -74.62806,
                          47.248533,
                          0
                      ],
                      [
                          -74.617571,
                          47.254353,
                          0
                      ],
                      [
                          -74.611148,
                          47.271953,
                          0
                      ],
                      [
                          -74.588032,
                          47.293412,
                          0
                      ],
                      [
                          -74.588521,
                          47.302471,
                          0
                      ],
                      [
                          -74.584316,
                          47.311214,
                          0
                      ],
                      [
                          -74.581527,
                          47.30223,
                          0
                      ],
                      [
                          -74.55842,
                          47.324385,
                          0
                      ],
                      [
                          -74.546184,
                          47.320721,
                          0
                      ],
                      [
                          -74.550317,
                          47.329544,
                          0
                      ],
                      [
                          -74.529004,
                          47.372552,
                          0
                      ],
                      [
                          -74.506351,
                          47.415068,
                          0
                      ],
                      [
                          -74.488511,
                          47.42817,
                          0
                      ],
                      [
                          -74.442093,
                          47.472185,
                          0
                      ],
                      [
                          -74.439053,
                          47.489133,
                          0
                      ],
                      [
                          -74.426026,
                          47.505371,
                          0
                      ],
                      [
                          -74.462931,
                          47.505064,
                          0
                      ],
                      [
                          -74.493302,
                          47.511538,
                          0
                      ],
                      [
                          -74.51707,
                          47.502808,
                          0
                      ],
                      [
                          -74.550794,
                          47.519582,
                          0
                      ],
                      [
                          -74.559924,
                          47.536634,
                          0
                      ],
                      [
                          -74.583245,
                          47.545379,
                          0
                      ],
                      [
                          -74.613942,
                          47.574187,
                          0
                      ],
                      [
                          -74.630047,
                          47.588706,
                          0
                      ],
                      [
                          -74.642092,
                          47.606309,
                          0
                      ],
                      [
                          -74.651105,
                          47.613632,
                          0
                      ],
                      [
                          -74.698509,
                          47.633395,
                          0
                      ],
                      [
                          -74.889381,
                          47.762639,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Berthier",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -69.124341,
                          48.366118,
                          0
                      ],
                      [
                          -68.644172,
                          48.66379,
                          0
                      ],
                      [
                          -68.273742,
                          48.436801,
                          0
                      ],
                      [
                          -68.299418,
                          48.417334,
                          0
                      ],
                      [
                          -68.267673,
                          48.40601,
                          0
                      ],
                      [
                          -68.183069,
                          48.349084,
                          0
                      ],
                      [
                          -68.257184,
                          48.293665,
                          0
                      ],
                      [
                          -67.950395,
                          48.096603,
                          0
                      ],
                      [
                          -67.950227,
                          48.000205,
                          0
                      ],
                      [
                          -67.950373,
                          48.000056,
                          0
                      ],
                      [
                          -67.950373,
                          48.000052,
                          0
                      ],
                      [
                          -68.122891,
                          47.999977,
                          0
                      ],
                      [
                          -68.122892,
                          47.91645,
                          0
                      ],
                      [
                          -68.122894,
                          47.91645,
                          0
                      ],
                      [
                          -68.383074,
                          47.916534,
                          0
                      ],
                      [
                          -68.38309,
                          47.880026,
                          0
                      ],
                      [
                          -68.505311,
                          47.962275,
                          0
                      ],
                      [
                          -68.505327,
                          47.962286,
                          0
                      ],
                      [
                          -68.909817,
                          48.232672,
                          0
                      ],
                      [
                          -68.92381,
                          48.222748,
                          0
                      ],
                      [
                          -68.944455,
                          48.237635,
                          0
                      ],
                      [
                          -68.939909,
                          48.240645,
                          0
                      ],
                      [
                          -68.935598,
                          48.237833,
                          0
                      ],
                      [
                          -68.927854,
                          48.242913,
                          0
                      ],
                      [
                          -69.124341,
                          48.366118,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Rimouski",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "GeometryCollection",
              "geometries": [
                  {
                      "type": "Polygon",
                      "coordinates": [
                          [
                              [
                                  -67.665399,
                                  48.118834,
                                  0
                              ],
                              [
                                  -67.374604,
                                  48.194893,
                                  0
                              ],
                              [
                                  -67.277548,
                                  48.031538,
                                  0
                              ],
                              [
                                  -66.879576,
                                  48.136969,
                                  0
                              ],
                              [
                                  -66.934527,
                                  48.18113,
                                  0
                              ],
                              [
                                  -66.900372,
                                  48.200355,
                                  0
                              ],
                              [
                                  -66.870208,
                                  48.177387,
                                  0
                              ],
                              [
                                  -66.847765,
                                  48.15734,
                                  0
                              ],
                              [
                                  -66.97416,
                                  48.303156,
                                  0
                              ],
                              [
                                  -65.948499,
                                  48.567089,
                                  0
                              ],
                              [
                                  -65.500811,
                                  48.679699,
                                  0
                              ],
                              [
                                  -64.958967,
                                  48.329199,
                                  0
                              ],
                              [
                                  -64.809564,
                                  48.427286,
                                  0
                              ],
                              [
                                  -64.820256,
                                  48.441984,
                                  0
                              ],
                              [
                                  -64.63693,
                                  48.502895,
                                  0
                              ],
                              [
                                  -64.573193,
                                  48.419944,
                                  0
                              ],
                              [
                                  -64.604649,
                                  48.409593,
                                  0
                              ],
                              [
                                  -64.593192,
                                  48.395126,
                                  0
                              ],
                              [
                                  -64.599112,
                                  48.385799,
                                  0
                              ],
                              [
                                  -64.59807,
                                  48.385379,
                                  0
                              ],
                              [
                                  -64.595328,
                                  48.383573,
                                  0
                              ],
                              [
                                  -64.591746,
                                  48.381595,
                                  0
                              ],
                              [
                                  -64.590111,
                                  48.380725,
                                  0
                              ],
                              [
                                  -64.588828,
                                  48.379357,
                                  0
                              ],
                              [
                                  -64.585268,
                                  48.378398,
                                  0
                              ],
                              [
                                  -64.581667,
                                  48.378037,
                                  0
                              ],
                              [
                                  -64.581328,
                                  48.377763,
                                  0
                              ],
                              [
                                  -64.570891,
                                  48.361959,
                                  0
                              ],
                              [
                                  -64.421962,
                                  48.220576,
                                  0
                              ],
                              [
                                  -65.111709,
                                  47.921127,
                                  0
                              ],
                              [
                                  -65.530297,
                                  47.829923,
                                  0
                              ],
                              [
                                  -65.606461,
                                  47.933634,
                                  0
                              ],
                              [
                                  -65.858134,
                                  48.02225,
                                  0
                              ],
                              [
                                  -66.149049,
                                  48.048013,
                                  0
                              ],
                              [
                                  -66.180014,
                                  48.048502,
                                  0
                              ],
                              [
                                  -66.306627,
                                  48.033449,
                                  0
                              ],
                              [
                                  -66.347831,
                                  48.078276,
                                  0
                              ],
                              [
                                  -66.387991,
                                  48.088968,
                                  0
                              ],
                              [
                                  -66.472007,
                                  48.084973,
                                  0
                              ],
                              [
                                  -66.50253,
                                  48.076943,
                                  0
                              ],
                              [
                                  -66.519226,
                                  48.055614,
                                  0
                              ],
                              [
                                  -66.534648,
                                  48.04664,
                                  0
                              ],
                              [
                                  -66.691094,
                                  48.008918,
                                  0
                              ],
                              [
                                  -66.748288,
                                  47.99697,
                                  0
                              ],
                              [
                                  -66.759539,
                                  48.002692,
                                  0
                              ],
                              [
                                  -66.783518,
                                  47.992162,
                                  0
                              ],
                              [
                                  -66.798261,
                                  47.991141,
                                  0
                              ],
                              [
                                  -66.838167,
                                  48.001639,
                                  0
                              ],
                              [
                                  -66.878461,
                                  47.99336,
                                  0
                              ],
                              [
                                  -66.919128,
                                  47.985003,
                                  0
                              ],
                              [
                                  -66.941546,
                                  47.971138,
                                  0
                              ],
                              [
                                  -66.960986,
                                  47.946578,
                                  0
                              ],
                              [
                                  -66.958069,
                                  47.910444,
                                  0
                              ],
                              [
                                  -66.944663,
                                  47.903585,
                                  0
                              ],
                              [
                                  -66.969721,
                                  47.892203,
                                  0
                              ],
                              [
                                  -66.997691,
                                  47.902617,
                                  0
                              ],
                              [
                                  -67.037327,
                                  47.923602,
                                  0
                              ],
                              [
                                  -67.061496,
                                  47.934761,
                                  0
                              ],
                              [
                                  -67.068634,
                                  47.923937,
                                  0
                              ],
                              [
                                  -67.106879,
                                  47.91328,
                                  0
                              ],
                              [
                                  -67.127113,
                                  47.915302,
                                  0
                              ],
                              [
                                  -67.135326,
                                  47.896856,
                                  0
                              ],
                              [
                                  -67.146169,
                                  47.891364,
                                  0
                              ],
                              [
                                  -67.171775,
                                  47.891747,
                                  0
                              ],
                              [
                                  -67.188878,
                                  47.881533,
                                  0
                              ],
                              [
                                  -67.215338,
                                  47.876668,
                                  0
                              ],
                              [
                                  -67.223552,
                                  47.883999,
                                  0
                              ],
                              [
                                  -67.276138,
                                  47.892115,
                                  0
                              ],
                              [
                                  -67.286,
                                  47.898375,
                                  0
                              ],
                              [
                                  -67.29408,
                                  47.891054,
                                  0
                              ],
                              [
                                  -67.304752,
                                  47.885593,
                                  0
                              ],
                              [
                                  -67.343385,
                                  47.895333,
                                  0
                              ],
                              [
                                  -67.340338,
                                  47.868608,
                                  0
                              ],
                              [
                                  -67.351003,
                                  47.874114,
                                  0
                              ],
                              [
                                  -67.36292,
                                  47.858749,
                                  0
                              ],
                              [
                                  -67.357057,
                                  47.849888,
                                  0
                              ],
                              [
                                  -67.369941,
                                  47.845087,
                                  0
                              ],
                              [
                                  -67.383082,
                                  47.847109,
                                  0
                              ],
                              [
                                  -67.392754,
                                  47.859332,
                                  0
                              ],
                              [
                                  -67.409635,
                                  47.87306,
                                  0
                              ],
                              [
                                  -67.444396,
                                  47.885726,
                                  0
                              ],
                              [
                                  -67.468892,
                                  47.893419,
                                  0
                              ],
                              [
                                  -67.480235,
                                  47.898559,
                                  0
                              ],
                              [
                                  -67.513458,
                                  47.913835,
                                  0
                              ],
                              [
                                  -67.551611,
                                  47.921353,
                                  0
                              ],
                              [
                                  -67.590774,
                                  47.92414,
                                  0
                              ],
                              [
                                  -67.607103,
                                  47.937832,
                                  0
                              ],
                              [
                                  -67.606706,
                                  47.956029,
                                  0
                              ],
                              [
                                  -67.59797,
                                  47.972659,
                                  0
                              ],
                              [
                                  -67.610346,
                                  47.9875,
                                  0
                              ],
                              [
                                  -67.591981,
                                  48.007755,
                                  0
                              ],
                              [
                                  -67.595549,
                                  48.027138,
                                  0
                              ],
                              [
                                  -67.586707,
                                  48.048543,
                                  0
                              ],
                              [
                                  -67.606251,
                                  48.061057,
                                  0
                              ],
                              [
                                  -67.630002,
                                  48.076717,
                                  0
                              ],
                              [
                                  -67.649476,
                                  48.081511,
                                  0
                              ],
                              [
                                  -67.665399,
                                  48.118834,
                                  0
                              ]
                          ]
                      ]
                  },
                  {
                      "type": "Polygon",
                      "coordinates": [
                          [
                              [
                                  -66.843247,
                                  48.152108,
                                  0
                              ],
                              [
                                  -66.847765,
                                  48.15734,
                                  0
                              ],
                              [
                                  -66.836773,
                                  48.147513,
                                  0
                              ],
                              [
                                  -66.843247,
                                  48.152108,
                                  0
                              ]
                          ]
                      ]
                  }
              ]
          },
          "properties": {
              "name": "Bonaventure",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -79.517775,
                          49.009117,
                          0
                      ],
                      [
                          -79.05335,
                          48.999986,
                          0
                      ],
                      [
                          -78.903012,
                          49.009179,
                          0
                      ],
                      [
                          -78.752875,
                          49.000082,
                          0
                      ],
                      [
                          -77.059499,
                          49.000107,
                          0
                      ],
                      [
                          -77.059536,
                          49.000004,
                          0
                      ],
                      [
                          -77.059518,
                          48.999997,
                          0
                      ],
                      [
                          -77.066295,
                          48.960959,
                          0
                      ],
                      [
                          -77.095188,
                          48.953592,
                          0
                      ],
                      [
                          -77.117633,
                          48.926685,
                          0
                      ],
                      [
                          -77.131811,
                          48.897713,
                          0
                      ],
                      [
                          -77.141774,
                          48.862548,
                          0
                      ],
                      [
                          -77.118636,
                          48.853133,
                          0
                      ],
                      [
                          -77.112817,
                          48.834098,
                          0
                      ],
                      [
                          -77.125247,
                          48.80073,
                          0
                      ],
                      [
                          -77.110031,
                          48.788778,
                          0
                      ],
                      [
                          -77.109176,
                          48.770185,
                          0
                      ],
                      [
                          -77.090346,
                          48.740703,
                          0
                      ],
                      [
                          -77.08575,
                          48.722649,
                          0
                      ],
                      [
                          -77.093731,
                          48.715262,
                          0
                      ],
                      [
                          -77.09662,
                          48.65615,
                          0
                      ],
                      [
                          -77.100109,
                          48.641365,
                          0
                      ],
                      [
                          -77.123951,
                          48.614687,
                          0
                      ],
                      [
                          -77.137206,
                          48.575059,
                          0
                      ],
                      [
                          -77.344049,
                          48.574847,
                          0
                      ],
                      [
                          -77.344048,
                          48.574845,
                          0
                      ],
                      [
                          -77.344096,
                          48.503571,
                          0
                      ],
                      [
                          -77.560689,
                          48.503311,
                          0
                      ],
                      [
                          -77.560571,
                          48.287412,
                          0
                      ],
                      [
                          -78.061235,
                          48.286906,
                          0
                      ],
                      [
                          -78.078647,
                          48.266842,
                          0
                      ],
                      [
                          -78.108052,
                          48.258367,
                          0
                      ],
                      [
                          -78.22339,
                          48.258263,
                          0
                      ],
                      [
                          -78.224503,
                          48.298329,
                          0
                      ],
                      [
                          -78.27181,
                          48.298392,
                          0
                      ],
                      [
                          -78.279041,
                          48.30597,
                          0
                      ],
                      [
                          -78.274238,
                          48.339017,
                          0
                      ],
                      [
                          -78.307612,
                          48.348471,
                          0
                      ],
                      [
                          -78.323691,
                          48.346829,
                          0
                      ],
                      [
                          -78.37809,
                          48.30198,
                          0
                      ],
                      [
                          -78.429465,
                          48.287654,
                          0
                      ],
                      [
                          -78.429791,
                          48.247922,
                          0
                      ],
                      [
                          -78.54094,
                          48.247944,
                          0
                      ],
                      [
                          -78.540954,
                          48.286864,
                          0
                      ],
                      [
                          -78.54058,
                          48.430406,
                          0
                      ],
                      [
                          -78.646816,
                          48.43053,
                          0
                      ],
                      [
                          -78.647141,
                          48.574962,
                          0
                      ],
                      [
                          -78.710376,
                          48.570163,
                          0
                      ],
                      [
                          -78.710321,
                          48.554268,
                          0
                      ],
                      [
                          -78.787872,
                          48.553965,
                          0
                      ],
                      [
                          -78.787839,
                          48.561088,
                          0
                      ],
                      [
                          -78.864859,
                          48.561512,
                          0
                      ],
                      [
                          -78.864711,
                          48.574921,
                          0
                      ],
                      [
                          -78.864715,
                          48.57492,
                          0
                      ],
                      [
                          -79.02718,
                          48.574939,
                          0
                      ],
                      [
                          -79.02707,
                          48.561101,
                          0
                      ],
                      [
                          -79.082407,
                          48.56104,
                          0
                      ],
                      [
                          -79.082354,
                          48.429994,
                          0
                      ],
                      [
                          -79.517499,
                          48.430524,
                          0
                      ],
                      [
                          -79.517495,
                          48.430616,
                          0
                      ],
                      [
                          -79.517493,
                          48.430705,
                          0
                      ],
                      [
                          -79.517775,
                          49.009117,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Abitibi-Ouest",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -75.520552,
                          49.00007,
                          0
                      ],
                      [
                          -74.676271,
                          48.99963,
                          0
                      ],
                      [
                          -74.541707,
                          48.978958,
                          0
                      ],
                      [
                          -74.487088,
                          48.932791,
                          0
                      ],
                      [
                          -74.471658,
                          48.945604,
                          0
                      ],
                      [
                          -73.017743,
                          47.998586,
                          0
                      ],
                      [
                          -72.123633,
                          47.998572,
                          0
                      ],
                      [
                          -72.073467,
                          47.949994,
                          0
                      ],
                      [
                          -71.890544,
                          47.770181,
                          0
                      ],
                      [
                          -72.239414,
                          47.535908,
                          0
                      ],
                      [
                          -72.214452,
                          47.511935,
                          0
                      ],
                      [
                          -72.197398,
                          47.502037,
                          0
                      ],
                      [
                          -72.20087,
                          47.489213,
                          0
                      ],
                      [
                          -72.195148,
                          47.482931,
                          0
                      ],
                      [
                          -72.175273,
                          47.477814,
                          0
                      ],
                      [
                          -72.161775,
                          47.466431,
                          0
                      ],
                      [
                          -72.170799,
                          47.462359,
                          0
                      ],
                      [
                          -72.172947,
                          47.440593,
                          0
                      ],
                      [
                          -72.158659,
                          47.421096,
                          0
                      ],
                      [
                          -72.142218,
                          47.413178,
                          0
                      ],
                      [
                          -72.14696,
                          47.398779,
                          0
                      ],
                      [
                          -72.161367,
                          47.386482,
                          0
                      ],
                      [
                          -72.184606,
                          47.365851,
                          0
                      ],
                      [
                          -72.20251,
                          47.357699,
                          0
                      ],
                      [
                          -72.215765,
                          47.313856,
                          0
                      ],
                      [
                          -72.23885,
                          47.290066,
                          0
                      ],
                      [
                          -72.284243,
                          47.29015,
                          0
                      ],
                      [
                          -72.317572,
                          47.301429,
                          0
                      ],
                      [
                          -72.340962,
                          47.278098,
                          0
                      ],
                      [
                          -72.355351,
                          47.289738,
                          0
                      ],
                      [
                          -72.410633,
                          47.291042,
                          0
                      ],
                      [
                          -72.412045,
                          47.345324,
                          0
                      ],
                      [
                          -72.45955,
                          47.369847,
                          0
                      ],
                      [
                          -72.459303,
                          47.385763,
                          0
                      ],
                      [
                          -72.485629,
                          47.36782,
                          0
                      ],
                      [
                          -72.470693,
                          47.331599,
                          0
                      ],
                      [
                          -72.503381,
                          47.311928,
                          0
                      ],
                      [
                          -72.510361,
                          47.268066,
                          0
                      ],
                      [
                          -72.53855,
                          47.218851,
                          0
                      ],
                      [
                          -72.540781,
                          47.190653,
                          0
                      ],
                      [
                          -72.51469,
                          47.143983,
                          0
                      ],
                      [
                          -72.459999,
                          47.143071,
                          0
                      ],
                      [
                          -72.416036,
                          47.118548,
                          0
                      ],
                      [
                          -72.394896,
                          47.117765,
                          0
                      ],
                      [
                          -72.378898,
                          47.096495,
                          0
                      ],
                      [
                          -72.438049,
                          47.057588,
                          0
                      ],
                      [
                          -72.560134,
                          46.97089,
                          0
                      ],
                      [
                          -72.515623,
                          46.944963,
                          0
                      ],
                      [
                          -72.565381,
                          46.908525,
                          0
                      ],
                      [
                          -72.612652,
                          46.940482,
                          0
                      ],
                      [
                          -72.715297,
                          46.866996,
                          0
                      ],
                      [
                          -72.587993,
                          46.78267,
                          0
                      ],
                      [
                          -72.640487,
                          46.743998,
                          0
                      ],
                      [
                          -72.631589,
                          46.737859,
                          0
                      ],
                      [
                          -72.664302,
                          46.715688,
                          0
                      ],
                      [
                          -72.673566,
                          46.721969,
                          0
                      ],
                      [
                          -72.674522,
                          46.721321,
                          0
                      ],
                      [
                          -72.674196,
                          46.719961,
                          0
                      ],
                      [
                          -72.6711,
                          46.717317,
                          0
                      ],
                      [
                          -72.673892,
                          46.715482,
                          0
                      ],
                      [
                          -72.671566,
                          46.713886,
                          0
                      ],
                      [
                          -72.682451,
                          46.706544,
                          0
                      ],
                      [
                          -72.642932,
                          46.67923,
                          0
                      ],
                      [
                          -72.655563,
                          46.670633,
                          0
                      ],
                      [
                          -72.583126,
                          46.623994,
                          0
                      ],
                      [
                          -72.614067,
                          46.602043,
                          0
                      ],
                      [
                          -72.536023,
                          46.547153,
                          0
                      ],
                      [
                          -72.593994,
                          46.505734,
                          0
                      ],
                      [
                          -72.604485,
                          46.505683,
                          0
                      ],
                      [
                          -72.618762,
                          46.49586,
                          0
                      ],
                      [
                          -72.588754,
                          46.474483,
                          0
                      ],
                      [
                          -72.679101,
                          46.410845,
                          0
                      ],
                      [
                          -72.67922,
                          46.410842,
                          0
                      ],
                      [
                          -72.680312,
                          46.411762,
                          0
                      ],
                      [
                          -72.681593,
                          46.413326,
                          0
                      ],
                      [
                          -72.682599,
                          46.41551,
                          0
                      ],
                      [
                          -72.684382,
                          46.41839,
                          0
                      ],
                      [
                          -72.685974,
                          46.419756,
                          0
                      ],
                      [
                          -72.687761,
                          46.420613,
                          0
                      ],
                      [
                          -72.691411,
                          46.421713,
                          0
                      ],
                      [
                          -72.700568,
                          46.423346,
                          0
                      ],
                      [
                          -72.704912,
                          46.424248,
                          0
                      ],
                      [
                          -72.715306,
                          46.427495,
                          0
                      ],
                      [
                          -72.725081,
                          46.433473,
                          0
                      ],
                      [
                          -72.727925,
                          46.437635,
                          0
                      ],
                      [
                          -72.737407,
                          46.445865,
                          0
                      ],
                      [
                          -72.741218,
                          46.449717,
                          0
                      ],
                      [
                          -72.751456,
                          46.455888,
                          0
                      ],
                      [
                          -72.757178,
                          46.458626,
                          0
                      ],
                      [
                          -72.767555,
                          46.466444,
                          0
                      ],
                      [
                          -72.7719,
                          46.475556,
                          0
                      ],
                      [
                          -72.775413,
                          46.480441,
                          0
                      ],
                      [
                          -72.773567,
                          46.490803,
                          0
                      ],
                      [
                          -72.773641,
                          46.496078,
                          0
                      ],
                      [
                          -72.774522,
                          46.507617,
                          0
                      ],
                      [
                          -72.771254,
                          46.518486,
                          0
                      ],
                      [
                          -72.773661,
                          46.523126,
                          0
                      ],
                      [
                          -72.773014,
                          46.537267,
                          0
                      ],
                      [
                          -72.778926,
                          46.541548,
                          0
                      ],
                      [
                          -72.77721,
                          46.546049,
                          0
                      ],
                      [
                          -72.775977,
                          46.545939,
                          0
                      ],
                      [
                          -72.775123,
                          46.545937,
                          0
                      ],
                      [
                          -72.774892,
                          46.545602,
                          0
                      ],
                      [
                          -72.773443,
                          46.545746,
                          0
                      ],
                      [
                          -72.771873,
                          46.546593,
                          0
                      ],
                      [
                          -72.77013,
                          46.546786,
                          0
                      ],
                      [
                          -72.769552,
                          46.546685,
                          0
                      ],
                      [
                          -72.768846,
                          46.546202,
                          0
                      ],
                      [
                          -72.766162,
                          46.546176,
                          0
                      ],
                      [
                          -72.870644,
                          46.621851,
                          0
                      ],
                      [
                          -72.85147,
                          46.633759,
                          0
                      ],
                      [
                          -72.893645,
                          46.667121,
                          0
                      ],
                      [
                          -72.88574,
                          46.66176,
                          0
                      ],
                      [
                          -72.872102,
                          46.66329,
                          0
                      ],
                      [
                          -72.865271,
                          46.661043,
                          0
                      ],
                      [
                          -72.859678,
                          46.664292,
                          0
                      ],
                      [
                          -72.874756,
                          46.675334,
                          0
                      ],
                      [
                          -72.878087,
                          46.67333,
                          0
                      ],
                      [
                          -72.909008,
                          46.724014,
                          0
                      ],
                      [
                          -72.926999,
                          46.733685,
                          0
                      ],
                      [
                          -72.993816,
                          46.745617,
                          0
                      ],
                      [
                          -73.011427,
                          46.736305,
                          0
                      ],
                      [
                          -73.01147,
                          46.736282,
                          0
                      ],
                      [
                          -72.984264,
                          46.677457,
                          0
                      ],
                      [
                          -72.986059,
                          46.667043,
                          0
                      ],
                      [
                          -72.999398,
                          46.648938,
                          0
                      ],
                      [
                          -73.031988,
                          46.653092,
                          0
                      ],
                      [
                          -73.063944,
                          46.706448,
                          0
                      ],
                      [
                          -73.064093,
                          46.706695,
                          0
                      ],
                      [
                          -73.10695,
                          46.704163,
                          0
                      ],
                      [
                          -73.178886,
                          46.750833,
                          0
                      ],
                      [
                          -73.169274,
                          46.764489,
                          0
                      ],
                      [
                          -73.143672,
                          46.770604,
                          0
                      ],
                      [
                          -73.132263,
                          46.786675,
                          0
                      ],
                      [
                          -73.13495,
                          46.835137,
                          0
                      ],
                      [
                          -73.157242,
                          46.84559,
                          0
                      ],
                      [
                          -73.163889,
                          46.846537,
                          0
                      ],
                      [
                          -73.176965,
                          46.845907,
                          0
                      ],
                      [
                          -73.194306,
                          46.839848,
                          0
                      ],
                      [
                          -73.215217,
                          46.850438,
                          0
                      ],
                      [
                          -73.222526,
                          46.852301,
                          0
                      ],
                      [
                          -73.224897,
                          46.861275,
                          0
                      ],
                      [
                          -73.229604,
                          46.864797,
                          0
                      ],
                      [
                          -73.242458,
                          46.866904,
                          0
                      ],
                      [
                          -73.248144,
                          46.869243,
                          0
                      ],
                      [
                          -73.243485,
                          46.882555,
                          0
                      ],
                      [
                          -73.256415,
                          46.884974,
                          0
                      ],
                      [
                          -73.268147,
                          46.889434,
                          0
                      ],
                      [
                          -73.274088,
                          46.887343,
                          0
                      ],
                      [
                          -73.27569,
                          46.8829,
                          0
                      ],
                      [
                          -73.281958,
                          46.88123,
                          0
                      ],
                      [
                          -73.288515,
                          46.882309,
                          0
                      ],
                      [
                          -73.298554,
                          46.876673,
                          0
                      ],
                      [
                          -73.307346,
                          46.870226,
                          0
                      ],
                      [
                          -73.319712,
                          46.874952,
                          0
                      ],
                      [
                          -73.329461,
                          46.881239,
                          0
                      ],
                      [
                          -73.342979,
                          46.881242,
                          0
                      ],
                      [
                          -73.361627,
                          46.887333,
                          0
                      ],
                      [
                          -73.375363,
                          46.887831,
                          0
                      ],
                      [
                          -73.381192,
                          46.889973,
                          0
                      ],
                      [
                          -73.387645,
                          46.884102,
                          0
                      ],
                      [
                          -73.394262,
                          46.884235,
                          0
                      ],
                      [
                          -73.407414,
                          46.882621,
                          0
                      ],
                      [
                          -73.41337,
                          46.880666,
                          0
                      ],
                      [
                          -73.4202,
                          46.88835,
                          0
                      ],
                      [
                          -73.4202,
                          46.88835,
                          0
                      ],
                      [
                          -73.44188,
                          46.8962,
                          0
                      ],
                      [
                          -73.45727,
                          46.906654,
                          0
                      ],
                      [
                          -73.479268,
                          46.906588,
                          0
                      ],
                      [
                          -73.499285,
                          46.910515,
                          0
                      ],
                      [
                          -73.497624,
                          46.903298,
                          0
                      ],
                      [
                          -73.51521,
                          46.89502,
                          0
                      ],
                      [
                          -73.526737,
                          46.882651,
                          0
                      ],
                      [
                          -73.529208,
                          46.867312,
                          0
                      ],
                      [
                          -73.539699,
                          46.866288,
                          0
                      ],
                      [
                          -73.555894,
                          46.875444,
                          0
                      ],
                      [
                          -73.55931,
                          46.882469,
                          0
                      ],
                      [
                          -73.570227,
                          46.880114,
                          0
                      ],
                      [
                          -73.570659,
                          46.872912,
                          0
                      ],
                      [
                          -73.602621,
                          46.872143,
                          0
                      ],
                      [
                          -73.605362,
                          46.864947,
                          0
                      ],
                      [
                          -73.608128,
                          46.857958,
                          0
                      ],
                      [
                          -73.624844,
                          46.8646,
                          0
                      ],
                      [
                          -73.667142,
                          46.866173,
                          0
                      ],
                      [
                          -73.673037,
                          46.87217,
                          0
                      ],
                      [
                          -73.672456,
                          46.879693,
                          0
                      ],
                      [
                          -73.662739,
                          46.882613,
                          0
                      ],
                      [
                          -73.66937,
                          46.888226,
                          0
                      ],
                      [
                          -73.659509,
                          46.890783,
                          0
                      ],
                      [
                          -73.66421,
                          46.928432,
                          0
                      ],
                      [
                          -73.646069,
                          46.937292,
                          0
                      ],
                      [
                          -73.754219,
                          47.065172,
                          0
                      ],
                      [
                          -73.853246,
                          47.151078,
                          0
                      ],
                      [
                          -73.910584,
                          47.099078,
                          0
                      ],
                      [
                          -74.028575,
                          47.177833,
                          0
                      ],
                      [
                          -73.996543,
                          47.349811,
                          0
                      ],
                      [
                          -73.957424,
                          47.388167,
                          0
                      ],
                      [
                          -74.511522,
                          47.762908,
                          0
                      ],
                      [
                          -74.889381,
                          47.762639,
                          0
                      ],
                      [
                          -75.521074,
                          47.763317,
                          0
                      ],
                      [
                          -75.520642,
                          47.847738,
                          0
                      ],
                      [
                          -75.520552,
                          49.00007,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Laviolette-Saint-Maurice",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -74.471658,
                          48.945604,
                          0
                      ],
                      [
                          -74.438415,
                          48.973183,
                          0
                      ],
                      [
                          -74.406751,
                          48.922763,
                          0
                      ],
                      [
                          -74.330865,
                          48.933877,
                          0
                      ],
                      [
                          -74.294195,
                          48.982944,
                          0
                      ],
                      [
                          -74.278834,
                          49.102103,
                          0
                      ],
                      [
                          -74.249263,
                          49.148117,
                          0
                      ],
                      [
                          -74.228916,
                          49.256308,
                          0
                      ],
                      [
                          -74.3033,
                          49.268313,
                          0
                      ],
                      [
                          -74.368096,
                          49.300842,
                          0
                      ],
                      [
                          -74.406945,
                          49.40467,
                          0
                      ],
                      [
                          -74.39302,
                          49.475466,
                          0
                      ],
                      [
                          -74.311431,
                          49.48111,
                          0
                      ],
                      [
                          -74.257064,
                          49.519318,
                          0
                      ],
                      [
                          -74.249636,
                          49.570123,
                          0
                      ],
                      [
                          -74.188226,
                          49.661617,
                          0
                      ],
                      [
                          -74.117159,
                          49.703504,
                          0
                      ],
                      [
                          -74.048638,
                          49.735351,
                          0
                      ],
                      [
                          -74.012647,
                          49.803369,
                          0
                      ],
                      [
                          -73.911041,
                          49.896872,
                          0
                      ],
                      [
                          -73.890961,
                          49.958655,
                          0
                      ],
                      [
                          -73.827427,
                          50.011312,
                          0
                      ],
                      [
                          -73.785224,
                          50.06822,
                          0
                      ],
                      [
                          -73.783648,
                          50.175515,
                          0
                      ],
                      [
                          -73.757272,
                          50.225783,
                          0
                      ],
                      [
                          -73.671472,
                          50.230993,
                          0
                      ],
                      [
                          -73.637338,
                          50.280507,
                          0
                      ],
                      [
                          -73.609553,
                          50.331773,
                          0
                      ],
                      [
                          -73.471291,
                          50.368154,
                          0
                      ],
                      [
                          -73.445101,
                          50.419043,
                          0
                      ],
                      [
                          -73.399831,
                          50.468779,
                          0
                      ],
                      [
                          -73.37173,
                          50.521626,
                          0
                      ],
                      [
                          -73.306912,
                          50.586431,
                          0
                      ],
                      [
                          -73.232949,
                          50.694699,
                          0
                      ],
                      [
                          -73.167292,
                          50.73482,
                          0
                      ],
                      [
                          -73.079191,
                          50.731467,
                          0
                      ],
                      [
                          -73.017837,
                          50.670273,
                          0
                      ],
                      [
                          -72.959537,
                          50.705564,
                          0
                      ],
                      [
                          -72.928537,
                          50.757708,
                          0
                      ],
                      [
                          -72.875771,
                          50.805196,
                          0
                      ],
                      [
                          -72.7802,
                          50.82582,
                          0
                      ],
                      [
                          -72.730213,
                          50.77325,
                          0
                      ],
                      [
                          -72.666519,
                          50.744738,
                          0
                      ],
                      [
                          -72.607589,
                          50.809238,
                          0
                      ],
                      [
                          -72.563754,
                          50.884035,
                          0
                      ],
                      [
                          -72.566088,
                          50.831828,
                          0
                      ],
                      [
                          -72.546492,
                          50.759625,
                          0
                      ],
                      [
                          -72.469868,
                          50.784321,
                          0
                      ],
                      [
                          -72.397645,
                          50.817315,
                          0
                      ],
                      [
                          -72.345371,
                          50.885426,
                          0
                      ],
                      [
                          -72.296128,
                          50.949945,
                          0
                      ],
                      [
                          -72.267441,
                          50.99836,
                          0
                      ],
                      [
                          -72.202848,
                          51.027865,
                          0
                      ],
                      [
                          -72.164895,
                          50.978473,
                          0
                      ],
                      [
                          -72.151527,
                          51.03789,
                          0
                      ],
                      [
                          -72.124254,
                          51.085221,
                          0
                      ],
                      [
                          -72.067402,
                          51.136465,
                          0
                      ],
                      [
                          -72.049717,
                          51.092899,
                          0
                      ],
                      [
                          -72.050146,
                          51.065903,
                          0
                      ],
                      [
                          -72.081743,
                          51.030635,
                          0
                      ],
                      [
                          -72.098238,
                          50.986307,
                          0
                      ],
                      [
                          -72.10457,
                          50.932625,
                          0
                      ],
                      [
                          -72.116398,
                          50.849413,
                          0
                      ],
                      [
                          -72.154049,
                          50.815402,
                          0
                      ],
                      [
                          -72.155464,
                          50.806335,
                          0
                      ],
                      [
                          -72.151867,
                          50.743046,
                          0
                      ],
                      [
                          -72.164102,
                          50.725364,
                          0
                      ],
                      [
                          -72.183983,
                          50.654979,
                          0
                      ],
                      [
                          -72.189192,
                          50.618381,
                          0
                      ],
                      [
                          -72.174887,
                          50.556441,
                          0
                      ],
                      [
                          -72.161882,
                          50.504039,
                          0
                      ],
                      [
                          -72.158694,
                          50.46034,
                          0
                      ],
                      [
                          -72.183513,
                          50.37886,
                          0
                      ],
                      [
                          -72.161566,
                          50.336555,
                          0
                      ],
                      [
                          -72.160046,
                          50.236414,
                          0
                      ],
                      [
                          -72.158799,
                          50.216213,
                          0
                      ],
                      [
                          -72.172263,
                          50.213559,
                          0
                      ],
                      [
                          -72.175689,
                          50.19429,
                          0
                      ],
                      [
                          -72.165948,
                          50.126992,
                          0
                      ],
                      [
                          -72.158884,
                          50.07084,
                          0
                      ],
                      [
                          -72.173163,
                          50.056093,
                          0
                      ],
                      [
                          -72.143654,
                          49.993561,
                          0
                      ],
                      [
                          -72.108682,
                          49.908172,
                          0
                      ],
                      [
                          -72.092017,
                          49.872227,
                          0
                      ],
                      [
                          -72.032449,
                          49.809034,
                          0
                      ],
                      [
                          -72.033956,
                          49.7809,
                          0
                      ],
                      [
                          -72.001946,
                          49.719169,
                          0
                      ],
                      [
                          -72.042233,
                          49.674953,
                          0
                      ],
                      [
                          -72.03393,
                          49.621417,
                          0
                      ],
                      [
                          -71.96063,
                          49.52965,
                          0
                      ],
                      [
                          -71.93447,
                          49.509308,
                          0
                      ],
                      [
                          -71.933397,
                          49.452754,
                          0
                      ],
                      [
                          -71.928417,
                          49.432728,
                          0
                      ],
                      [
                          -71.943752,
                          49.404388,
                          0
                      ],
                      [
                          -71.945485,
                          49.384314,
                          0
                      ],
                      [
                          -71.959769,
                          49.382746,
                          0
                      ],
                      [
                          -71.975953,
                          49.367065,
                          0
                      ],
                      [
                          -72.019116,
                          49.390393,
                          0
                      ],
                      [
                          -72.0371,
                          49.379781,
                          0
                      ],
                      [
                          -72.07985,
                          49.374142,
                          0
                      ],
                      [
                          -72.092691,
                          49.378072,
                          0
                      ],
                      [
                          -72.098619,
                          49.369859,
                          0
                      ],
                      [
                          -72.137906,
                          49.305753,
                          0
                      ],
                      [
                          -72.153294,
                          49.248532,
                          0
                      ],
                      [
                          -72.113178,
                          49.20864,
                          0
                      ],
                      [
                          -72.083404,
                          49.163746,
                          0
                      ],
                      [
                          -72.089859,
                          49.13574,
                          0
                      ],
                      [
                          -71.874988,
                          49.135749,
                          0
                      ],
                      [
                          -71.866964,
                          49.115183,
                          0
                      ],
                      [
                          -71.889247,
                          49.080132,
                          0
                      ],
                      [
                          -71.868429,
                          49.056351,
                          0
                      ],
                      [
                          -71.849649,
                          49.042974,
                          0
                      ],
                      [
                          -71.846994,
                          49.024767,
                          0
                      ],
                      [
                          -71.841004,
                          49.006382,
                          0
                      ],
                      [
                          -71.857458,
                          48.992956,
                          0
                      ],
                      [
                          -71.889554,
                          48.943794,
                          0
                      ],
                      [
                          -71.923661,
                          48.928822,
                          0
                      ],
                      [
                          -71.930337,
                          48.920865,
                          0
                      ],
                      [
                          -71.925111,
                          48.902934,
                          0
                      ],
                      [
                          -71.971672,
                          48.870516,
                          0
                      ],
                      [
                          -71.768287,
                          48.83449,
                          0
                      ],
                      [
                          -71.824462,
                          48.806691,
                          0
                      ],
                      [
                          -71.82151,
                          48.778271,
                          0
                      ],
                      [
                          -71.812417,
                          48.771314,
                          0
                      ],
                      [
                          -71.854799,
                          48.744862,
                          0
                      ],
                      [
                          -71.920153,
                          48.726244,
                          0
                      ],
                      [
                          -71.985253,
                          48.738232,
                          0
                      ],
                      [
                          -72.052348,
                          48.761106,
                          0
                      ],
                      [
                          -72.104357,
                          48.744186,
                          0
                      ],
                      [
                          -71.965435,
                          48.419773,
                          0
                      ],
                      [
                          -71.962126,
                          48.410324,
                          0
                      ],
                      [
                          -71.977323,
                          48.395249,
                          0
                      ],
                      [
                          -71.965059,
                          48.37923,
                          0
                      ],
                      [
                          -71.901898,
                          48.366298,
                          0
                      ],
                      [
                          -71.905029,
                          48.351646,
                          0
                      ],
                      [
                          -71.928211,
                          48.30377,
                          0
                      ],
                      [
                          -71.887497,
                          48.297152,
                          0
                      ],
                      [
                          -71.910263,
                          48.241438,
                          0
                      ],
                      [
                          -71.954925,
                          48.245661,
                          0
                      ],
                      [
                          -71.920987,
                          48.187233,
                          0
                      ],
                      [
                          -71.895334,
                          48.181142,
                          0
                      ],
                      [
                          -71.882275,
                          48.165549,
                          0
                      ],
                      [
                          -71.895916,
                          48.14058,
                          0
                      ],
                      [
                          -71.961654,
                          48.085396,
                          0
                      ],
                      [
                          -71.979126,
                          48.050863,
                          0
                      ],
                      [
                          -72.003259,
                          48.043036,
                          0
                      ],
                      [
                          -72.035323,
                          48.027205,
                          0
                      ],
                      [
                          -72.047238,
                          48.031891,
                          0
                      ],
                      [
                          -72.049766,
                          48.022546,
                          0
                      ],
                      [
                          -72.050544,
                          48.003934,
                          0
                      ],
                      [
                          -72.063472,
                          48.001469,
                          0
                      ],
                      [
                          -72.077006,
                          48.004179,
                          0
                      ],
                      [
                          -72.087582,
                          47.998621,
                          0
                      ],
                      [
                          -72.123633,
                          47.998572,
                          0
                      ],
                      [
                          -73.013625,
                          47.99858,
                          0
                      ],
                      [
                          -73.017743,
                          47.998586,
                          0
                      ],
                      [
                          -74.471658,
                          48.945604,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Roberval",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -72.123633,
                          47.998572,
                          0
                      ],
                      [
                          -72.087582,
                          47.998621,
                          0
                      ],
                      [
                          -72.077006,
                          48.004179,
                          0
                      ],
                      [
                          -72.063472,
                          48.001469,
                          0
                      ],
                      [
                          -72.050544,
                          48.003934,
                          0
                      ],
                      [
                          -72.049766,
                          48.022546,
                          0
                      ],
                      [
                          -72.047238,
                          48.031891,
                          0
                      ],
                      [
                          -72.035323,
                          48.027205,
                          0
                      ],
                      [
                          -72.003259,
                          48.043036,
                          0
                      ],
                      [
                          -71.979126,
                          48.050863,
                          0
                      ],
                      [
                          -71.961654,
                          48.085396,
                          0
                      ],
                      [
                          -71.895916,
                          48.14058,
                          0
                      ],
                      [
                          -71.882275,
                          48.165549,
                          0
                      ],
                      [
                          -71.895334,
                          48.181142,
                          0
                      ],
                      [
                          -71.920987,
                          48.187233,
                          0
                      ],
                      [
                          -71.954925,
                          48.245661,
                          0
                      ],
                      [
                          -71.910263,
                          48.241438,
                          0
                      ],
                      [
                          -71.887497,
                          48.297152,
                          0
                      ],
                      [
                          -71.928211,
                          48.30377,
                          0
                      ],
                      [
                          -71.905029,
                          48.351646,
                          0
                      ],
                      [
                          -71.901898,
                          48.366298,
                          0
                      ],
                      [
                          -71.965059,
                          48.37923,
                          0
                      ],
                      [
                          -71.977323,
                          48.395249,
                          0
                      ],
                      [
                          -71.962126,
                          48.410324,
                          0
                      ],
                      [
                          -71.965435,
                          48.419773,
                          0
                      ],
                      [
                          -72.104357,
                          48.744186,
                          0
                      ],
                      [
                          -72.052348,
                          48.761106,
                          0
                      ],
                      [
                          -71.985253,
                          48.738232,
                          0
                      ],
                      [
                          -71.920153,
                          48.726244,
                          0
                      ],
                      [
                          -71.854799,
                          48.744862,
                          0
                      ],
                      [
                          -71.812417,
                          48.771314,
                          0
                      ],
                      [
                          -71.82151,
                          48.778271,
                          0
                      ],
                      [
                          -71.824462,
                          48.806691,
                          0
                      ],
                      [
                          -71.768287,
                          48.83449,
                          0
                      ],
                      [
                          -71.971672,
                          48.870516,
                          0
                      ],
                      [
                          -71.925111,
                          48.902934,
                          0
                      ],
                      [
                          -71.930337,
                          48.920865,
                          0
                      ],
                      [
                          -71.923661,
                          48.928822,
                          0
                      ],
                      [
                          -71.889554,
                          48.943794,
                          0
                      ],
                      [
                          -71.857458,
                          48.992956,
                          0
                      ],
                      [
                          -71.841004,
                          49.006382,
                          0
                      ],
                      [
                          -71.846994,
                          49.024767,
                          0
                      ],
                      [
                          -71.849649,
                          49.042974,
                          0
                      ],
                      [
                          -71.868429,
                          49.056351,
                          0
                      ],
                      [
                          -71.889247,
                          49.080132,
                          0
                      ],
                      [
                          -71.866964,
                          49.115183,
                          0
                      ],
                      [
                          -71.874988,
                          49.135749,
                          0
                      ],
                      [
                          -72.089859,
                          49.13574,
                          0
                      ],
                      [
                          -72.083404,
                          49.163746,
                          0
                      ],
                      [
                          -72.113178,
                          49.20864,
                          0
                      ],
                      [
                          -72.153294,
                          49.248532,
                          0
                      ],
                      [
                          -72.137906,
                          49.305753,
                          0
                      ],
                      [
                          -72.098619,
                          49.369859,
                          0
                      ],
                      [
                          -72.092691,
                          49.378072,
                          0
                      ],
                      [
                          -72.07985,
                          49.374142,
                          0
                      ],
                      [
                          -72.0371,
                          49.379781,
                          0
                      ],
                      [
                          -72.019116,
                          49.390393,
                          0
                      ],
                      [
                          -71.975953,
                          49.367065,
                          0
                      ],
                      [
                          -71.959769,
                          49.382746,
                          0
                      ],
                      [
                          -71.945485,
                          49.384314,
                          0
                      ],
                      [
                          -71.943752,
                          49.404388,
                          0
                      ],
                      [
                          -71.928417,
                          49.432728,
                          0
                      ],
                      [
                          -71.933397,
                          49.452754,
                          0
                      ],
                      [
                          -71.93447,
                          49.509308,
                          0
                      ],
                      [
                          -71.96063,
                          49.52965,
                          0
                      ],
                      [
                          -72.03393,
                          49.621417,
                          0
                      ],
                      [
                          -72.042233,
                          49.674953,
                          0
                      ],
                      [
                          -72.001946,
                          49.719169,
                          0
                      ],
                      [
                          -72.033956,
                          49.7809,
                          0
                      ],
                      [
                          -72.032449,
                          49.809034,
                          0
                      ],
                      [
                          -72.092017,
                          49.872227,
                          0
                      ],
                      [
                          -72.108682,
                          49.908172,
                          0
                      ],
                      [
                          -72.143654,
                          49.993561,
                          0
                      ],
                      [
                          -72.173163,
                          50.056093,
                          0
                      ],
                      [
                          -72.158884,
                          50.07084,
                          0
                      ],
                      [
                          -72.165948,
                          50.126992,
                          0
                      ],
                      [
                          -72.175689,
                          50.19429,
                          0
                      ],
                      [
                          -72.172263,
                          50.213559,
                          0
                      ],
                      [
                          -72.158799,
                          50.216213,
                          0
                      ],
                      [
                          -72.160046,
                          50.236414,
                          0
                      ],
                      [
                          -72.161566,
                          50.336555,
                          0
                      ],
                      [
                          -72.183513,
                          50.37886,
                          0
                      ],
                      [
                          -72.158694,
                          50.46034,
                          0
                      ],
                      [
                          -72.161882,
                          50.504039,
                          0
                      ],
                      [
                          -72.174887,
                          50.556441,
                          0
                      ],
                      [
                          -72.189192,
                          50.618381,
                          0
                      ],
                      [
                          -72.183983,
                          50.654979,
                          0
                      ],
                      [
                          -72.164102,
                          50.725364,
                          0
                      ],
                      [
                          -72.151867,
                          50.743046,
                          0
                      ],
                      [
                          -72.155464,
                          50.806335,
                          0
                      ],
                      [
                          -72.154049,
                          50.815402,
                          0
                      ],
                      [
                          -72.116398,
                          50.849413,
                          0
                      ],
                      [
                          -72.10457,
                          50.932625,
                          0
                      ],
                      [
                          -72.098238,
                          50.986307,
                          0
                      ],
                      [
                          -72.081743,
                          51.030635,
                          0
                      ],
                      [
                          -72.050146,
                          51.065903,
                          0
                      ],
                      [
                          -72.049717,
                          51.092899,
                          0
                      ],
                      [
                          -72.067402,
                          51.136465,
                          0
                      ],
                      [
                          -72.014855,
                          51.177591,
                          0
                      ],
                      [
                          -71.990176,
                          51.23176,
                          0
                      ],
                      [
                          -71.876463,
                          51.311347,
                          0
                      ],
                      [
                          -71.81608,
                          51.362799,
                          0
                      ],
                      [
                          -71.721117,
                          51.366444,
                          0
                      ],
                      [
                          -71.70997,
                          51.431201,
                          0
                      ],
                      [
                          -71.65698,
                          51.476821,
                          0
                      ],
                      [
                          -71.59159,
                          51.505659,
                          0
                      ],
                      [
                          -71.582292,
                          51.562752,
                          0
                      ],
                      [
                          -71.603596,
                          51.673697,
                          0
                      ],
                      [
                          -71.574538,
                          51.777094,
                          0
                      ],
                      [
                          -71.527647,
                          51.833711,
                          0
                      ],
                      [
                          -71.373747,
                          51.857358,
                          0
                      ],
                      [
                          -71.336421,
                          51.914786,
                          0
                      ],
                      [
                          -71.296233,
                          51.974734,
                          0
                      ],
                      [
                          -71.252428,
                          52.059612,
                          0
                      ],
                      [
                          -71.192552,
                          52.110823,
                          0
                      ],
                      [
                          -71.14563,
                          52.157505,
                          0
                      ],
                      [
                          -71.084872,
                          52.205993,
                          0
                      ],
                      [
                          -70.926367,
                          52.241847,
                          0
                      ],
                      [
                          -70.84827,
                          52.27059,
                          0
                      ],
                      [
                          -70.860071,
                          52.264916,
                          0
                      ],
                      [
                          -70.906096,
                          52.229115,
                          0
                      ],
                      [
                          -70.941653,
                          52.199446,
                          0
                      ],
                      [
                          -71.002911,
                          52.162172,
                          0
                      ],
                      [
                          -71.028469,
                          52.151031,
                          0
                      ],
                      [
                          -71.039855,
                          52.133671,
                          0
                      ],
                      [
                          -71.094585,
                          52.108341,
                          0
                      ],
                      [
                          -71.11214,
                          52.064814,
                          0
                      ],
                      [
                          -71.15195,
                          51.994111,
                          0
                      ],
                      [
                          -71.177249,
                          51.97219,
                          0
                      ],
                      [
                          -71.184668,
                          51.954552,
                          0
                      ],
                      [
                          -71.216948,
                          51.912502,
                          0
                      ],
                      [
                          -71.23014,
                          51.896275,
                          0
                      ],
                      [
                          -71.268528,
                          51.836904,
                          0
                      ],
                      [
                          -71.304562,
                          51.796819,
                          0
                      ],
                      [
                          -71.307912,
                          51.74314,
                          0
                      ],
                      [
                          -71.320255,
                          51.707789,
                          0
                      ],
                      [
                          -71.371114,
                          51.662564,
                          0
                      ],
                      [
                          -71.422821,
                          51.621911,
                          0
                      ],
                      [
                          -71.433101,
                          51.504556,
                          0
                      ],
                      [
                          -71.464161,
                          51.408631,
                          0
                      ],
                      [
                          -71.466522,
                          51.379494,
                          0
                      ],
                      [
                          -71.448792,
                          51.315711,
                          0
                      ],
                      [
                          -71.459309,
                          51.267772,
                          0
                      ],
                      [
                          -71.50572,
                          51.234182,
                          0
                      ],
                      [
                          -71.451392,
                          51.177131,
                          0
                      ],
                      [
                          -71.405676,
                          51.111713,
                          0
                      ],
                      [
                          -71.357973,
                          51.069123,
                          0
                      ],
                      [
                          -71.338054,
                          50.989348,
                          0
                      ],
                      [
                          -71.345268,
                          50.959408,
                          0
                      ],
                      [
                          -71.319457,
                          50.908778,
                          0
                      ],
                      [
                          -71.291422,
                          50.864291,
                          0
                      ],
                      [
                          -71.308546,
                          50.767538,
                          0
                      ],
                      [
                          -71.28463,
                          50.671078,
                          0
                      ],
                      [
                          -71.273519,
                          50.620756,
                          0
                      ],
                      [
                          -71.245586,
                          50.599862,
                          0
                      ],
                      [
                          -71.282939,
                          50.568199,
                          0
                      ],
                      [
                          -71.279166,
                          50.523579,
                          0
                      ],
                      [
                          -71.333591,
                          50.460372,
                          0
                      ],
                      [
                          -71.343899,
                          50.454218,
                          0
                      ],
                      [
                          -71.355792,
                          50.408936,
                          0
                      ],
                      [
                          -71.343182,
                          50.388277,
                          0
                      ],
                      [
                          -71.295628,
                          50.292946,
                          0
                      ],
                      [
                          -71.253456,
                          50.220927,
                          0
                      ],
                      [
                          -71.26013,
                          50.167638,
                          0
                      ],
                      [
                          -71.211285,
                          50.096311,
                          0
                      ],
                      [
                          -71.194829,
                          50.055505,
                          0
                      ],
                      [
                          -71.191247,
                          50.017769,
                          0
                      ],
                      [
                          -71.238748,
                          49.965952,
                          0
                      ],
                      [
                          -71.250145,
                          49.941852,
                          0
                      ],
                      [
                          -71.248422,
                          49.879364,
                          0
                      ],
                      [
                          -71.159334,
                          49.826148,
                          0
                      ],
                      [
                          -71.183658,
                          49.78151,
                          0
                      ],
                      [
                          -71.189408,
                          49.642828,
                          0
                      ],
                      [
                          -71.207794,
                          49.559329,
                          0
                      ],
                      [
                          -71.171339,
                          49.493216,
                          0
                      ],
                      [
                          -71.188679,
                          49.451739,
                          0
                      ],
                      [
                          -71.206316,
                          49.407106,
                          0
                      ],
                      [
                          -71.250026,
                          49.31325,
                          0
                      ],
                      [
                          -71.254615,
                          49.252748,
                          0
                      ],
                      [
                          -71.263524,
                          49.139298,
                          0
                      ],
                      [
                          -71.269649,
                          49.110191,
                          0
                      ],
                      [
                          -71.317942,
                          49.052202,
                          0
                      ],
                      [
                          -71.381765,
                          48.936991,
                          0
                      ],
                      [
                          -71.455193,
                          48.864628,
                          0
                      ],
                      [
                          -71.453095,
                          48.847044,
                          0
                      ],
                      [
                          -71.424276,
                          48.842784,
                          0
                      ],
                      [
                          -71.327037,
                          48.776488,
                          0
                      ],
                      [
                          -71.372798,
                          48.780628,
                          0
                      ],
                      [
                          -71.483264,
                          48.513981,
                          0
                      ],
                      [
                          -71.465178,
                          48.50648,
                          0
                      ],
                      [
                          -71.486862,
                          48.452367,
                          0
                      ],
                      [
                          -71.377225,
                          48.433023,
                          0
                      ],
                      [
                          -71.377817,
                          48.431554,
                          0
                      ],
                      [
                          -71.377191,
                          48.431396,
                          0
                      ],
                      [
                          -71.38277,
                          48.419531,
                          0
                      ],
                      [
                          -71.475549,
                          48.435175,
                          0
                      ],
                      [
                          -71.488459,
                          48.399519,
                          0
                      ],
                      [
                          -71.518147,
                          48.403902,
                          0
                      ],
                      [
                          -71.524222,
                          48.389958,
                          0
                      ],
                      [
                          -71.550623,
                          48.393902,
                          0
                      ],
                      [
                          -71.563099,
                          48.36472,
                          0
                      ],
                      [
                          -71.539671,
                          48.349672,
                          0
                      ],
                      [
                          -71.543125,
                          48.340563,
                          0
                      ],
                      [
                          -71.571085,
                          48.345893,
                          0
                      ],
                      [
                          -71.63622,
                          48.182435,
                          0
                      ],
                      [
                          -71.566477,
                          48.123067,
                          0
                      ],
                      [
                          -71.553643,
                          48.119622,
                          0
                      ],
                      [
                          -71.536185,
                          48.103034,
                          0
                      ],
                      [
                          -71.509029,
                          48.072261,
                          0
                      ],
                      [
                          -71.480553,
                          48.052875,
                          0
                      ],
                      [
                          -71.426977,
                          48.000011,
                          0
                      ],
                      [
                          -71.41168,
                          47.995986,
                          0
                      ],
                      [
                          -71.409088,
                          47.97767,
                          0
                      ],
                      [
                          -71.382643,
                          47.973683,
                          0
                      ],
                      [
                          -71.374437,
                          47.956472,
                          0
                      ],
                      [
                          -72.073467,
                          47.949994,
                          0
                      ],
                      [
                          -72.123633,
                          47.998572,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Lac-Saint-Jean",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -71.63622,
                          48.182435,
                          0
                      ],
                      [
                          -71.571085,
                          48.345893,
                          0
                      ],
                      [
                          -71.543125,
                          48.340563,
                          0
                      ],
                      [
                          -71.539671,
                          48.349672,
                          0
                      ],
                      [
                          -71.509294,
                          48.342431,
                          0
                      ],
                      [
                          -71.482661,
                          48.339716,
                          0
                      ],
                      [
                          -71.450307,
                          48.33127,
                          0
                      ],
                      [
                          -71.322652,
                          48.307684,
                          0
                      ],
                      [
                          -71.273911,
                          48.302759,
                          0
                      ],
                      [
                          -71.220841,
                          48.352449,
                          0
                      ],
                      [
                          -71.208297,
                          48.346841,
                          0
                      ],
                      [
                          -71.184477,
                          48.373724,
                          0
                      ],
                      [
                          -71.112332,
                          48.341007,
                          0
                      ],
                      [
                          -71.127438,
                          48.325774,
                          0
                      ],
                      [
                          -71.131953,
                          48.319378,
                          0
                      ],
                      [
                          -71.134355,
                          48.316498,
                          0
                      ],
                      [
                          -71.145825,
                          48.305269,
                          0
                      ],
                      [
                          -71.144933,
                          48.30481,
                          0
                      ],
                      [
                          -71.145344,
                          48.304421,
                          0
                      ],
                      [
                          -71.145719,
                          48.304621,
                          0
                      ],
                      [
                          -71.147381,
                          48.303026,
                          0
                      ],
                      [
                          -71.147186,
                          48.302941,
                          0
                      ],
                      [
                          -71.147573,
                          48.302515,
                          0
                      ],
                      [
                          -71.14781,
                          48.302605,
                          0
                      ],
                      [
                          -71.150391,
                          48.300048,
                          0
                      ],
                      [
                          -71.149392,
                          48.299628,
                          0
                      ],
                      [
                          -71.150208,
                          48.298989,
                          0
                      ],
                      [
                          -71.151095,
                          48.299342,
                          0
                      ],
                      [
                          -71.151838,
                          48.298637,
                          0
                      ],
                      [
                          -71.15068,
                          48.298117,
                          0
                      ],
                      [
                          -71.15096,
                          48.297873,
                          0
                      ],
                      [
                          -71.151075,
                          48.297915,
                          0
                      ],
                      [
                          -71.151316,
                          48.29775,
                          0
                      ],
                      [
                          -71.151555,
                          48.297842,
                          0
                      ],
                      [
                          -71.152506,
                          48.29686,
                          0
                      ],
                      [
                          -71.153258,
                          48.297216,
                          0
                      ],
                      [
                          -71.155553,
                          48.295167,
                          0
                      ],
                      [
                          -71.154752,
                          48.29482,
                          0
                      ],
                      [
                          -71.155222,
                          48.294582,
                          0
                      ],
                      [
                          -71.147187,
                          48.290899,
                          0
                      ],
                      [
                          -71.147224,
                          48.290322,
                          0
                      ],
                      [
                          -71.145898,
                          48.289723,
                          0
                      ],
                      [
                          -71.148772,
                          48.286805,
                          0
                      ],
                      [
                          -71.124088,
                          48.275511,
                          0
                      ],
                      [
                          -71.102145,
                          48.296971,
                          0
                      ],
                      [
                          -71.102514,
                          48.297143,
                          0
                      ],
                      [
                          -71.11428,
                          48.302612,
                          0
                      ],
                      [
                          -71.114533,
                          48.302529,
                          0
                      ],
                      [
                          -71.114108,
                          48.302224,
                          0
                      ],
                      [
                          -71.115441,
                          48.301533,
                          0
                      ],
                      [
                          -71.11576,
                          48.301848,
                          0
                      ],
                      [
                          -71.116335,
                          48.301474,
                          0
                      ],
                      [
                          -71.116862,
                          48.301745,
                          0
                      ],
                      [
                          -71.116011,
                          48.302547,
                          0
                      ],
                      [
                          -71.115021,
                          48.303194,
                          0
                      ],
                      [
                          -71.113807,
                          48.303478,
                          0
                      ],
                      [
                          -71.11311,
                          48.30405,
                          0
                      ],
                      [
                          -71.114515,
                          48.304937,
                          0
                      ],
                      [
                          -71.115644,
                          48.304267,
                          0
                      ],
                      [
                          -71.11676,
                          48.304708,
                          0
                      ],
                      [
                          -71.11602,
                          48.305117,
                          0
                      ],
                      [
                          -71.116666,
                          48.305418,
                          0
                      ],
                      [
                          -71.114731,
                          48.306477,
                          0
                      ],
                      [
                          -71.133872,
                          48.314993,
                          0
                      ],
                      [
                          -71.138595,
                          48.310411,
                          0
                      ],
                      [
                          -71.139411,
                          48.310749,
                          0
                      ],
                      [
                          -71.133761,
                          48.316305,
                          0
                      ],
                      [
                          -71.131811,
                          48.317914,
                          0
                      ],
                      [
                          -71.130052,
                          48.32014,
                          0
                      ],
                      [
                          -71.126238,
                          48.325327,
                          0
                      ],
                      [
                          -71.124782,
                          48.326953,
                          0
                      ],
                      [
                          -71.111071,
                          48.340499,
                          0
                      ],
                      [
                          -71.098158,
                          48.334789,
                          0
                      ],
                      [
                          -71.064085,
                          48.365708,
                          0
                      ],
                      [
                          -71.025878,
                          48.34873,
                          0
                      ],
                      [
                          -70.9903,
                          48.382483,
                          0
                      ],
                      [
                          -70.966635,
                          48.370912,
                          0
                      ],
                      [
                          -70.902203,
                          48.430273,
                          0
                      ],
                      [
                          -70.902285,
                          48.430285,
                          0
                      ],
                      [
                          -70.902302,
                          48.430287,
                          0
                      ],
                      [
                          -70.959926,
                          48.446187,
                          0
                      ],
                      [
                          -70.986701,
                          48.45291,
                          0
                      ],
                      [
                          -70.986249,
                          48.454251,
                          0
                      ],
                      [
                          -70.986252,
                          48.45425,
                          0
                      ],
                      [
                          -71.000521,
                          48.453368,
                          0
                      ],
                      [
                          -71.010957,
                          48.449272,
                          0
                      ],
                      [
                          -71.020115,
                          48.443525,
                          0
                      ],
                      [
                          -71.052603,
                          48.451433,
                          0
                      ],
                      [
                          -71.05254,
                          48.451583,
                          0
                      ],
                      [
                          -71.048264,
                          48.461597,
                          0
                      ],
                      [
                          -71.07376,
                          48.466723,
                          0
                      ],
                      [
                          -71.077795,
                          48.457765,
                          0
                      ],
                      [
                          -71.11227,
                          48.466176,
                          0
                      ],
                      [
                          -71.121778,
                          48.441173,
                          0
                      ],
                      [
                          -71.17752,
                          48.45476,
                          0
                      ],
                      [
                          -71.18698,
                          48.454081,
                          0
                      ],
                      [
                          -71.205202,
                          48.44625,
                          0
                      ],
                      [
                          -71.209849,
                          48.445162,
                          0
                      ],
                      [
                          -71.217311,
                          48.449855,
                          0
                      ],
                      [
                          -71.243098,
                          48.452031,
                          0
                      ],
                      [
                          -71.261164,
                          48.454855,
                          0
                      ],
                      [
                          -71.273911,
                          48.462212,
                          0
                      ],
                      [
                          -71.300122,
                          48.468521,
                          0
                      ],
                      [
                          -71.348963,
                          48.480604,
                          0
                      ],
                      [
                          -71.382633,
                          48.482295,
                          0
                      ],
                      [
                          -71.400796,
                          48.484129,
                          0
                      ],
                      [
                          -71.419087,
                          48.491312,
                          0
                      ],
                      [
                          -71.455299,
                          48.504211,
                          0
                      ],
                      [
                          -71.465178,
                          48.50648,
                          0
                      ],
                      [
                          -71.483264,
                          48.513981,
                          0
                      ],
                      [
                          -71.372798,
                          48.780628,
                          0
                      ],
                      [
                          -71.327037,
                          48.776488,
                          0
                      ],
                      [
                          -71.424276,
                          48.842784,
                          0
                      ],
                      [
                          -71.453095,
                          48.847044,
                          0
                      ],
                      [
                          -71.455193,
                          48.864628,
                          0
                      ],
                      [
                          -71.381765,
                          48.936991,
                          0
                      ],
                      [
                          -71.317942,
                          49.052202,
                          0
                      ],
                      [
                          -71.269649,
                          49.110191,
                          0
                      ],
                      [
                          -71.263524,
                          49.139298,
                          0
                      ],
                      [
                          -71.254615,
                          49.252748,
                          0
                      ],
                      [
                          -71.250026,
                          49.31325,
                          0
                      ],
                      [
                          -71.206316,
                          49.407106,
                          0
                      ],
                      [
                          -71.188679,
                          49.451739,
                          0
                      ],
                      [
                          -71.171339,
                          49.493216,
                          0
                      ],
                      [
                          -71.207794,
                          49.559329,
                          0
                      ],
                      [
                          -71.189408,
                          49.642828,
                          0
                      ],
                      [
                          -71.183658,
                          49.78151,
                          0
                      ],
                      [
                          -71.159334,
                          49.826148,
                          0
                      ],
                      [
                          -71.248422,
                          49.879364,
                          0
                      ],
                      [
                          -71.250145,
                          49.941852,
                          0
                      ],
                      [
                          -71.238748,
                          49.965952,
                          0
                      ],
                      [
                          -71.191247,
                          50.017769,
                          0
                      ],
                      [
                          -71.194829,
                          50.055505,
                          0
                      ],
                      [
                          -71.211285,
                          50.096311,
                          0
                      ],
                      [
                          -71.26013,
                          50.167638,
                          0
                      ],
                      [
                          -71.253456,
                          50.220927,
                          0
                      ],
                      [
                          -71.295628,
                          50.292946,
                          0
                      ],
                      [
                          -71.343182,
                          50.388277,
                          0
                      ],
                      [
                          -71.355792,
                          50.408936,
                          0
                      ],
                      [
                          -71.343899,
                          50.454218,
                          0
                      ],
                      [
                          -71.333591,
                          50.460372,
                          0
                      ],
                      [
                          -71.279166,
                          50.523579,
                          0
                      ],
                      [
                          -71.282939,
                          50.568199,
                          0
                      ],
                      [
                          -71.245586,
                          50.599862,
                          0
                      ],
                      [
                          -71.273519,
                          50.620756,
                          0
                      ],
                      [
                          -71.28463,
                          50.671078,
                          0
                      ],
                      [
                          -71.308546,
                          50.767538,
                          0
                      ],
                      [
                          -71.291422,
                          50.864291,
                          0
                      ],
                      [
                          -71.319457,
                          50.908778,
                          0
                      ],
                      [
                          -71.345268,
                          50.959408,
                          0
                      ],
                      [
                          -71.338054,
                          50.989348,
                          0
                      ],
                      [
                          -71.357973,
                          51.069123,
                          0
                      ],
                      [
                          -71.405676,
                          51.111713,
                          0
                      ],
                      [
                          -71.451392,
                          51.177131,
                          0
                      ],
                      [
                          -71.50572,
                          51.234182,
                          0
                      ],
                      [
                          -71.459309,
                          51.267772,
                          0
                      ],
                      [
                          -71.448792,
                          51.315711,
                          0
                      ],
                      [
                          -71.466522,
                          51.379494,
                          0
                      ],
                      [
                          -71.464161,
                          51.408631,
                          0
                      ],
                      [
                          -71.433101,
                          51.504556,
                          0
                      ],
                      [
                          -71.422821,
                          51.621911,
                          0
                      ],
                      [
                          -71.371114,
                          51.662564,
                          0
                      ],
                      [
                          -71.320255,
                          51.707789,
                          0
                      ],
                      [
                          -71.307912,
                          51.74314,
                          0
                      ],
                      [
                          -71.304562,
                          51.796819,
                          0
                      ],
                      [
                          -71.268528,
                          51.836904,
                          0
                      ],
                      [
                          -71.23014,
                          51.896275,
                          0
                      ],
                      [
                          -71.216948,
                          51.912502,
                          0
                      ],
                      [
                          -71.184668,
                          51.954552,
                          0
                      ],
                      [
                          -71.177249,
                          51.97219,
                          0
                      ],
                      [
                          -71.15195,
                          51.994111,
                          0
                      ],
                      [
                          -71.11214,
                          52.064814,
                          0
                      ],
                      [
                          -71.094585,
                          52.108341,
                          0
                      ],
                      [
                          -71.039855,
                          52.133671,
                          0
                      ],
                      [
                          -71.028469,
                          52.151031,
                          0
                      ],
                      [
                          -71.002911,
                          52.162172,
                          0
                      ],
                      [
                          -70.941653,
                          52.199446,
                          0
                      ],
                      [
                          -70.906096,
                          52.229115,
                          0
                      ],
                      [
                          -70.860071,
                          52.264916,
                          0
                      ],
                      [
                          -70.84827,
                          52.27059,
                          0
                      ],
                      [
                          -70.834667,
                          52.275587,
                          0
                      ],
                      [
                          -70.754892,
                          52.24719,
                          0
                      ],
                      [
                          -70.687719,
                          52.281688,
                          0
                      ],
                      [
                          -70.603042,
                          52.295734,
                          0
                      ],
                      [
                          -70.43333,
                          52.326974,
                          0
                      ],
                      [
                          -70.333236,
                          52.340795,
                          0
                      ],
                      [
                          -70.261723,
                          52.37061,
                          0
                      ],
                      [
                          -70.281927,
                          52.42422,
                          0
                      ],
                      [
                          -70.228057,
                          52.464516,
                          0
                      ],
                      [
                          -70.188143,
                          52.512866,
                          0
                      ],
                      [
                          -70.240563,
                          52.564199,
                          0
                      ],
                      [
                          -70.165947,
                          52.605152,
                          0
                      ],
                      [
                          -70.182893,
                          52.66209,
                          0
                      ],
                      [
                          -70.198017,
                          52.718494,
                          0
                      ],
                      [
                          -70.141241,
                          52.759922,
                          0
                      ],
                      [
                          -70.121453,
                          52.812445,
                          0
                      ],
                      [
                          -70.02991,
                          52.83262,
                          0
                      ],
                      [
                          -70.026325,
                          51.564049,
                          0
                      ],
                      [
                          -70.007993,
                          48.357005,
                          0
                      ],
                      [
                          -70.077881,
                          48.252831,
                          0
                      ],
                      [
                          -70.077873,
                          48.252832,
                          0
                      ],
                      [
                          -70.028582,
                          48.256895,
                          0
                      ],
                      [
                          -70.001657,
                          48.252704,
                          0
                      ],
                      [
                          -69.962459,
                          48.237563,
                          0
                      ],
                      [
                          -69.920372,
                          48.231981,
                          0
                      ],
                      [
                          -69.890488,
                          48.224316,
                          0
                      ],
                      [
                          -69.871083,
                          48.186177,
                          0
                      ],
                      [
                          -69.956149,
                          48.056794,
                          0
                      ],
                      [
                          -69.956152,
                          48.056791,
                          0
                      ],
                      [
                          -69.956933,
                          48.057052,
                          0
                      ],
                      [
                          -69.957023,
                          48.057082,
                          0
                      ],
                      [
                          -69.956658,
                          48.057602,
                          0
                      ],
                      [
                          -70.17188,
                          48.12482,
                          0
                      ],
                      [
                          -70.264651,
                          47.992927,
                          0
                      ],
                      [
                          -71.41168,
                          47.995986,
                          0
                      ],
                      [
                          -71.426977,
                          48.000011,
                          0
                      ],
                      [
                          -71.480553,
                          48.052875,
                          0
                      ],
                      [
                          -71.509029,
                          48.072261,
                          0
                      ],
                      [
                          -71.536185,
                          48.103034,
                          0
                      ],
                      [
                          -71.553643,
                          48.119622,
                          0
                      ],
                      [
                          -71.566477,
                          48.123067,
                          0
                      ],
                      [
                          -71.63622,
                          48.182435,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Dubuc",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -70.077881,
                          48.252831,
                          0
                      ],
                      [
                          -70.007993,
                          48.357005,
                          0
                      ],
                      [
                          -70.026325,
                          51.564049,
                          0
                      ],
                      [
                          -70.026322,
                          51.564049,
                          0
                      ],
                      [
                          -67.916904,
                          51.563678,
                          0
                      ],
                      [
                          -67.916896,
                          51.450194,
                          0
                      ],
                      [
                          -67.856979,
                          51.406535,
                          0
                      ],
                      [
                          -67.872245,
                          51.354141,
                          0
                      ],
                      [
                          -67.917624,
                          51.298662,
                          0
                      ],
                      [
                          -67.852561,
                          51.266213,
                          0
                      ],
                      [
                          -67.826918,
                          51.158485,
                          0
                      ],
                      [
                          -67.746518,
                          51.108562,
                          0
                      ],
                      [
                          -67.645002,
                          51.007621,
                          0
                      ],
                      [
                          -67.693168,
                          50.963287,
                          0
                      ],
                      [
                          -67.706481,
                          50.893954,
                          0
                      ],
                      [
                          -67.73812,
                          50.844505,
                          0
                      ],
                      [
                          -67.722623,
                          50.741635,
                          0
                      ],
                      [
                          -67.76752,
                          50.686537,
                          0
                      ],
                      [
                          -67.756924,
                          50.635239,
                          0
                      ],
                      [
                          -67.81829,
                          50.529313,
                          0
                      ],
                      [
                          -67.829968,
                          50.474312,
                          0
                      ],
                      [
                          -67.89237,
                          50.379886,
                          0
                      ],
                      [
                          -67.866471,
                          50.323344,
                          0
                      ],
                      [
                          -67.706306,
                          50.32324,
                          0
                      ],
                      [
                          -67.603718,
                          50.04123,
                          0
                      ],
                      [
                          -67.716119,
                          49.941017,
                          0
                      ],
                      [
                          -67.734551,
                          49.877705,
                          0
                      ],
                      [
                          -67.655693,
                          49.897213,
                          0
                      ],
                      [
                          -67.518824,
                          49.833433,
                          0
                      ],
                      [
                          -67.558046,
                          49.762082,
                          0
                      ],
                      [
                          -67.601838,
                          49.708582,
                          0
                      ],
                      [
                          -67.58613,
                          49.586164,
                          0
                      ],
                      [
                          -66.999278,
                          49.586593,
                          0
                      ],
                      [
                          -66.999279,
                          49.279233,
                          0
                      ],
                      [
                          -66.999279,
                          49.279232,
                          0
                      ],
                      [
                          -67.153346,
                          49.189066,
                          0
                      ],
                      [
                          -67.825346,
                          49.000068,
                          0
                      ],
                      [
                          -68.644172,
                          48.66379,
                          0
                      ],
                      [
                          -69.124341,
                          48.366118,
                          0
                      ],
                      [
                          -69.548371,
                          48.049453,
                          0
                      ],
                      [
                          -69.548373,
                          48.049452,
                          0
                      ],
                      [
                          -69.668037,
                          48.116363,
                          0
                      ],
                      [
                          -69.78719,
                          48.145153,
                          0
                      ],
                      [
                          -69.846117,
                          48.155646,
                          0
                      ],
                      [
                          -69.871083,
                          48.186177,
                          0
                      ],
                      [
                          -69.890488,
                          48.224316,
                          0
                      ],
                      [
                          -69.920372,
                          48.231981,
                          0
                      ],
                      [
                          -69.962459,
                          48.237563,
                          0
                      ],
                      [
                          -70.001657,
                          48.252704,
                          0
                      ],
                      [
                          -70.028582,
                          48.256895,
                          0
                      ],
                      [
                          -70.077873,
                          48.252832,
                          0
                      ],
                      [
                          -70.077881,
                          48.252831,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "René-Lévesque",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -66.999275,
                          49.279236,
                          0
                      ],
                      [
                          -66.387998,
                          49.647255,
                          0
                      ],
                      [
                          -65.718651,
                          49.831113,
                          0
                      ],
                      [
                          -64.993633,
                          49.766223,
                          0
                      ],
                      [
                          -64.396744,
                          49.584691,
                          0
                      ],
                      [
                          -62.894936,
                          48.813485,
                          0
                      ],
                      [
                          -62.999112,
                          48.666704,
                          0
                      ],
                      [
                          -62.999128,
                          48.666682,
                          0
                      ],
                      [
                          -62.999128,
                          48.666681,
                          0
                      ],
                      [
                          -62.999143,
                          47.157154,
                          0
                      ],
                      [
                          -62.999143,
                          47.157145,
                          0
                      ],
                      [
                          -63.000007,
                          47.15832,
                          0
                      ],
                      [
                          -63.067394,
                          47.250034,
                          0
                      ],
                      [
                          -63.252426,
                          47.500012,
                          0
                      ],
                      [
                          -63.344281,
                          47.623313,
                          0
                      ],
                      [
                          -63.438239,
                          47.750044,
                          0
                      ],
                      [
                          -63.625077,
                          48.000011,
                          0
                      ],
                      [
                          -63.69773,
                          48.096474,
                          0
                      ],
                      [
                          -63.791655,
                          48.220576,
                          0
                      ],
                      [
                          -63.999164,
                          48.220965,
                          0
                      ],
                      [
                          -64.421962,
                          48.220576,
                          0
                      ],
                      [
                          -64.570891,
                          48.361959,
                          0
                      ],
                      [
                          -64.581328,
                          48.377763,
                          0
                      ],
                      [
                          -64.581667,
                          48.378037,
                          0
                      ],
                      [
                          -64.585268,
                          48.378398,
                          0
                      ],
                      [
                          -64.588828,
                          48.379357,
                          0
                      ],
                      [
                          -64.590111,
                          48.380725,
                          0
                      ],
                      [
                          -64.591746,
                          48.381595,
                          0
                      ],
                      [
                          -64.595328,
                          48.383573,
                          0
                      ],
                      [
                          -64.59807,
                          48.385379,
                          0
                      ],
                      [
                          -64.599112,
                          48.385799,
                          0
                      ],
                      [
                          -64.593192,
                          48.395126,
                          0
                      ],
                      [
                          -64.604649,
                          48.409593,
                          0
                      ],
                      [
                          -64.573193,
                          48.419944,
                          0
                      ],
                      [
                          -64.63693,
                          48.502895,
                          0
                      ],
                      [
                          -64.820256,
                          48.441984,
                          0
                      ],
                      [
                          -64.809564,
                          48.427286,
                          0
                      ],
                      [
                          -64.958967,
                          48.329199,
                          0
                      ],
                      [
                          -65.500811,
                          48.679699,
                          0
                      ],
                      [
                          -65.948499,
                          48.567089,
                          0
                      ],
                      [
                          -66.537316,
                          48.957827,
                          0
                      ],
                      [
                          -66.704415,
                          48.902873,
                          0
                      ],
                      [
                          -66.713129,
                          48.910169,
                          0
                      ],
                      [
                          -66.699114,
                          48.919334,
                          0
                      ],
                      [
                          -66.695715,
                          48.923488,
                          0
                      ],
                      [
                          -66.700304,
                          48.926886,
                          0
                      ],
                      [
                          -66.6953,
                          48.93573,
                          0
                      ],
                      [
                          -66.69758,
                          48.940179,
                          0
                      ],
                      [
                          -66.69562,
                          48.944691,
                          0
                      ],
                      [
                          -66.698313,
                          48.949165,
                          0
                      ],
                      [
                          -66.695579,
                          48.953506,
                          0
                      ],
                      [
                          -66.700639,
                          48.956639,
                          0
                      ],
                      [
                          -66.69901,
                          48.961086,
                          0
                      ],
                      [
                          -66.696713,
                          48.965394,
                          0
                      ],
                      [
                          -66.700349,
                          48.969282,
                          0
                      ],
                      [
                          -66.698048,
                          48.973548,
                          0
                      ],
                      [
                          -66.702313,
                          48.977171,
                          0
                      ],
                      [
                          -66.701071,
                          48.986573,
                          0
                      ],
                      [
                          -66.706177,
                          49.000007,
                          0
                      ],
                      [
                          -66.733571,
                          49.01748,
                          0
                      ],
                      [
                          -66.832404,
                          48.98514,
                          0
                      ],
                      [
                          -67.153346,
                          49.189066,
                          0
                      ],
                      [
                          -66.999279,
                          49.279232,
                          0
                      ],
                      [
                          -66.999277,
                          49.279233,
                          0
                      ],
                      [
                          -66.999275,
                          49.279236,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Gaspé",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -75.521074,
                          47.763317,
                          0
                      ],
                      [
                          -75.532347,
                          47.737517,
                          0
                      ],
                      [
                          -75.524818,
                          47.711644,
                          0
                      ],
                      [
                          -75.496906,
                          47.693334,
                          0
                      ],
                      [
                          -75.446258,
                          47.66298,
                          0
                      ],
                      [
                          -75.435843,
                          47.646398,
                          0
                      ],
                      [
                          -75.419878,
                          47.630533,
                          0
                      ],
                      [
                          -75.427634,
                          47.612887,
                          0
                      ],
                      [
                          -75.412365,
                          47.587567,
                          0
                      ],
                      [
                          -75.391646,
                          47.60434,
                          0
                      ],
                      [
                          -75.380356,
                          47.620941,
                          0
                      ],
                      [
                          -75.360352,
                          47.601099,
                          0
                      ],
                      [
                          -75.365268,
                          47.583313,
                          0
                      ],
                      [
                          -75.327595,
                          47.559924,
                          0
                      ],
                      [
                          -75.308029,
                          47.54747,
                          0
                      ],
                      [
                          -75.32243,
                          47.532201,
                          0
                      ],
                      [
                          -75.36627,
                          47.486178,
                          0
                      ],
                      [
                          -75.383896,
                          47.450764,
                          0
                      ],
                      [
                          -75.393785,
                          47.424186,
                          0
                      ],
                      [
                          -75.421169,
                          47.403294,
                          0
                      ],
                      [
                          -75.434218,
                          47.386682,
                          0
                      ],
                      [
                          -75.455483,
                          47.374437,
                          0
                      ],
                      [
                          -75.470617,
                          47.348733,
                          0
                      ],
                      [
                          -75.46012,
                          47.324104,
                          0
                      ],
                      [
                          -75.476271,
                          47.279756,
                          0
                      ],
                      [
                          -75.497264,
                          47.268063,
                          0
                      ],
                      [
                          -75.536739,
                          47.230697,
                          0
                      ],
                      [
                          -75.590166,
                          47.189996,
                          0
                      ],
                      [
                          -75.615265,
                          47.182596,
                          0
                      ],
                      [
                          -75.627548,
                          47.222221,
                          0
                      ],
                      [
                          -75.649093,
                          47.272687,
                          0
                      ],
                      [
                          -75.679506,
                          47.285394,
                          0
                      ],
                      [
                          -75.713833,
                          47.284216,
                          0
                      ],
                      [
                          -75.734676,
                          47.272422,
                          0
                      ],
                      [
                          -75.746806,
                          47.29295,
                          0
                      ],
                      [
                          -75.779641,
                          47.308126,
                          0
                      ],
                      [
                          -75.813978,
                          47.31634,
                          0
                      ],
                      [
                          -75.88594,
                          47.277905,
                          0
                      ],
                      [
                          -75.919443,
                          47.249998,
                          0
                      ],
                      [
                          -75.968572,
                          47.20813,
                          0
                      ],
                      [
                          -76.041798,
                          47.197897,
                          0
                      ],
                      [
                          -76.090037,
                          47.184679,
                          0
                      ],
                      [
                          -76.100124,
                          47.161313,
                          0
                      ],
                      [
                          -76.092178,
                          47.134948,
                          0
                      ],
                      [
                          -76.098449,
                          47.089967,
                          0
                      ],
                      [
                          -76.10341,
                          47.045067,
                          0
                      ],
                      [
                          -76.117844,
                          47.021795,
                          0
                      ],
                      [
                          -76.148459,
                          47.005988,
                          0
                      ],
                      [
                          -76.134576,
                          46.964911,
                          0
                      ],
                      [
                          -76.135307,
                          46.946834,
                          0
                      ],
                      [
                          -76.127576,
                          46.920724,
                          0
                      ],
                      [
                          -76.056527,
                          46.902905,
                          0
                      ],
                      [
                          -76.027172,
                          46.895432,
                          0
                      ],
                      [
                          -75.968625,
                          46.87715,
                          0
                      ],
                      [
                          -75.936776,
                          46.857773,
                          0
                      ],
                      [
                          -75.915226,
                          46.844769,
                          0
                      ],
                      [
                          -75.896979,
                          46.818131,
                          0
                      ],
                      [
                          -75.867951,
                          46.816722,
                          0
                      ],
                      [
                          -75.828998,
                          46.82434,
                          0
                      ],
                      [
                          -75.801988,
                          46.844883,
                          0
                      ],
                      [
                          -75.747544,
                          46.844826,
                          0
                      ],
                      [
                          -75.745211,
                          46.438257,
                          0
                      ],
                      [
                          -75.705594,
                          46.438808,
                          0
                      ],
                      [
                          -75.70662,
                          46.308718,
                          0
                      ],
                      [
                          -75.814236,
                          46.306122,
                          0
                      ],
                      [
                          -75.784019,
                          46.286146,
                          0
                      ],
                      [
                          -75.795817,
                          46.258636,
                          0
                      ],
                      [
                          -75.80419,
                          46.239879,
                          0
                      ],
                      [
                          -75.811734,
                          46.222505,
                          0
                      ],
                      [
                          -75.810317,
                          46.196459,
                          0
                      ],
                      [
                          -75.800076,
                          46.178652,
                          0
                      ],
                      [
                          -75.768351,
                          46.173832,
                          0
                      ],
                      [
                          -75.763414,
                          46.022737,
                          0
                      ],
                      [
                          -75.699371,
                          46.023248,
                          0
                      ],
                      [
                          -75.738772,
                          46.007899,
                          0
                      ],
                      [
                          -75.775171,
                          45.983826,
                          0
                      ],
                      [
                          -75.784876,
                          45.963533,
                          0
                      ],
                      [
                          -75.77443,
                          45.953817,
                          0
                      ],
                      [
                          -75.77725,
                          45.95289,
                          0
                      ],
                      [
                          -75.777257,
                          45.952886,
                          0
                      ],
                      [
                          -75.776019,
                          45.951556,
                          0
                      ],
                      [
                          -75.763436,
                          45.94579,
                          0
                      ],
                      [
                          -75.768493,
                          45.940853,
                          0
                      ],
                      [
                          -75.761118,
                          45.938649,
                          0
                      ],
                      [
                          -75.746924,
                          45.92371,
                          0
                      ],
                      [
                          -75.742045,
                          45.915313,
                          0
                      ],
                      [
                          -75.736867,
                          45.913061,
                          0
                      ],
                      [
                          -75.737124,
                          45.891737,
                          0
                      ],
                      [
                          -75.737575,
                          45.845694,
                          0
                      ],
                      [
                          -75.736362,
                          45.825063,
                          0
                      ],
                      [
                          -75.711639,
                          45.828243,
                          0
                      ],
                      [
                          -75.641851,
                          45.833827,
                          0
                      ],
                      [
                          -75.63142,
                          45.816284,
                          0
                      ],
                      [
                          -75.614458,
                          45.802322,
                          0
                      ],
                      [
                          -75.607585,
                          45.793292,
                          0
                      ],
                      [
                          -75.605372,
                          45.784431,
                          0
                      ],
                      [
                          -75.59712,
                          45.777338,
                          0
                      ],
                      [
                          -75.584525,
                          45.760553,
                          0
                      ],
                      [
                          -75.579226,
                          45.752136,
                          0
                      ],
                      [
                          -75.57894,
                          45.74296,
                          0
                      ],
                      [
                          -75.591181,
                          45.72723,
                          0
                      ],
                      [
                          -75.585668,
                          45.719014,
                          0
                      ],
                      [
                          -75.559247,
                          45.713906,
                          0
                      ],
                      [
                          -75.546849,
                          45.708527,
                          0
                      ],
                      [
                          -75.53427,
                          45.706365,
                          0
                      ],
                      [
                          -75.527944,
                          45.703709,
                          0
                      ],
                      [
                          -75.556275,
                          45.70143,
                          0
                      ],
                      [
                          -75.556272,
                          45.70141,
                          0
                      ],
                      [
                          -75.544785,
                          45.624771,
                          0
                      ],
                      [
                          -75.54473,
                          45.624579,
                          0
                      ],
                      [
                          -75.541422,
                          45.603833,
                          0
                      ],
                      [
                          -75.536288,
                          45.570076,
                          0
                      ],
                      [
                          -75.529224,
                          45.521802,
                          0
                      ],
                      [
                          -75.543175,
                          45.516472,
                          0
                      ],
                      [
                          -75.550013,
                          45.51449,
                          0
                      ],
                      [
                          -75.588611,
                          45.511324,
                          0
                      ],
                      [
                          -75.610111,
                          45.511233,
                          0
                      ],
                      [
                          -75.610111,
                          45.511233,
                          0
                      ],
                      [
                          -75.644086,
                          45.511288,
                          0
                      ],
                      [
                          -75.649624,
                          45.511076,
                          0
                      ],
                      [
                          -75.706216,
                          45.503884,
                          0
                      ],
                      [
                          -75.709857,
                          45.502863,
                          0
                      ],
                      [
                          -75.713044,
                          45.501012,
                          0
                      ],
                      [
                          -75.715593,
                          45.49731,
                          0
                      ],
                      [
                          -75.716304,
                          45.494672,
                          0
                      ],
                      [
                          -75.715456,
                          45.488396,
                          0
                      ],
                      [
                          -75.715456,
                          45.488392,
                          0
                      ],
                      [
                          -75.718324,
                          45.489042,
                          0
                      ],
                      [
                          -75.71838,
                          45.488098,
                          0
                      ],
                      [
                          -75.717819,
                          45.487889,
                          0
                      ],
                      [
                          -75.718543,
                          45.487081,
                          0
                      ],
                      [
                          -75.718865,
                          45.486022,
                          0
                      ],
                      [
                          -75.719565,
                          45.485351,
                          0
                      ],
                      [
                          -75.719845,
                          45.484453,
                          0
                      ],
                      [
                          -75.720259,
                          45.484177,
                          0
                      ],
                      [
                          -75.719471,
                          45.483811,
                          0
                      ],
                      [
                          -75.720843,
                          45.483524,
                          0
                      ],
                      [
                          -75.720812,
                          45.483029,
                          0
                      ],
                      [
                          -75.722154,
                          45.482238,
                          0
                      ],
                      [
                          -75.723115,
                          45.482118,
                          0
                      ],
                      [
                          -75.724701,
                          45.481112,
                          0
                      ],
                      [
                          -75.726898,
                          45.480407,
                          0
                      ],
                      [
                          -75.728449,
                          45.480184,
                          0
                      ],
                      [
                          -75.729835,
                          45.479895,
                          0
                      ],
                      [
                          -75.731712,
                          45.480124,
                          0
                      ],
                      [
                          -75.732647,
                          45.479959,
                          0
                      ],
                      [
                          -75.73359,
                          45.479364,
                          0
                      ],
                      [
                          -75.734159,
                          45.479835,
                          0
                      ],
                      [
                          -75.734327,
                          45.47971,
                          0
                      ],
                      [
                          -75.73449,
                          45.479054,
                          0
                      ],
                      [
                          -75.734161,
                          45.478701,
                          0
                      ],
                      [
                          -75.734346,
                          45.478343,
                          0
                      ],
                      [
                          -75.734066,
                          45.476325,
                          0
                      ],
                      [
                          -75.735026,
                          45.475266,
                          0
                      ],
                      [
                          -75.738767,
                          45.476775,
                          0
                      ],
                      [
                          -75.744333,
                          45.482309,
                          0
                      ],
                      [
                          -75.748737,
                          45.487143,
                          0
                      ],
                      [
                          -75.750427,
                          45.486192,
                          0
                      ],
                      [
                          -75.750835,
                          45.485394,
                          0
                      ],
                      [
                          -75.750716,
                          45.482163,
                          0
                      ],
                      [
                          -75.748938,
                          45.481921,
                          0
                      ],
                      [
                          -75.74747,
                          45.481179,
                          0
                      ],
                      [
                          -75.744953,
                          45.479435,
                          0
                      ],
                      [
                          -75.7435,
                          45.477221,
                          0
                      ],
                      [
                          -75.74808,
                          45.47689,
                          0
                      ],
                      [
                          -75.749177,
                          45.478537,
                          0
                      ],
                      [
                          -75.750699,
                          45.479945,
                          0
                      ],
                      [
                          -75.752445,
                          45.48081,
                          0
                      ],
                      [
                          -75.753518,
                          45.479571,
                          0
                      ],
                      [
                          -75.75449,
                          45.478943,
                          0
                      ],
                      [
                          -75.755281,
                          45.477819,
                          0
                      ],
                      [
                          -75.757242,
                          45.477085,
                          0
                      ],
                      [
                          -75.757248,
                          45.476669,
                          0
                      ],
                      [
                          -75.757753,
                          45.476589,
                          0
                      ],
                      [
                          -75.758165,
                          45.476855,
                          0
                      ],
                      [
                          -75.759295,
                          45.476391,
                          0
                      ],
                      [
                          -75.760185,
                          45.477525,
                          0
                      ],
                      [
                          -75.765132,
                          45.478834,
                          0
                      ],
                      [
                          -75.766917,
                          45.479812,
                          0
                      ],
                      [
                          -75.768172,
                          45.480892,
                          0
                      ],
                      [
                          -75.768784,
                          45.480733,
                          0
                      ],
                      [
                          -75.769785,
                          45.481944,
                          0
                      ],
                      [
                          -75.769744,
                          45.482523,
                          0
                      ],
                      [
                          -75.771103,
                          45.483002,
                          0
                      ],
                      [
                          -75.771572,
                          45.484519,
                          0
                      ],
                      [
                          -75.772769,
                          45.484681,
                          0
                      ],
                      [
                          -75.773816,
                          45.485835,
                          0
                      ],
                      [
                          -75.775326,
                          45.486765,
                          0
                      ],
                      [
                          -75.77524,
                          45.488005,
                          0
                      ],
                      [
                          -75.776279,
                          45.488492,
                          0
                      ],
                      [
                          -75.77802,
                          45.489184,
                          0
                      ],
                      [
                          -75.77812,
                          45.489386,
                          0
                      ],
                      [
                          -75.777021,
                          45.49008,
                          0
                      ],
                      [
                          -75.777008,
                          45.490267,
                          0
                      ],
                      [
                          -75.778601,
                          45.490173,
                          0
                      ],
                      [
                          -75.777378,
                          45.482571,
                          0
                      ],
                      [
                          -75.784219,
                          45.482038,
                          0
                      ],
                      [
                          -75.784538,
                          45.484051,
                          0
                      ],
                      [
                          -75.785534,
                          45.483773,
                          0
                      ],
                      [
                          -75.787794,
                          45.484049,
                          0
                      ],
                      [
                          -75.788345,
                          45.484047,
                          0
                      ],
                      [
                          -75.789339,
                          45.483977,
                          0
                      ],
                      [
                          -75.791575,
                          45.484843,
                          0
                      ],
                      [
                          -75.790844,
                          45.480836,
                          0
                      ],
                      [
                          -75.792804,
                          45.48205,
                          0
                      ],
                      [
                          -75.794698,
                          45.483594,
                          0
                      ],
                      [
                          -75.795816,
                          45.483777,
                          0
                      ],
                      [
                          -75.796878,
                          45.482441,
                          0
                      ],
                      [
                          -75.796704,
                          45.481666,
                          0
                      ],
                      [
                          -75.795147,
                          45.479883,
                          0
                      ],
                      [
                          -75.794373,
                          45.479616,
                          0
                      ],
                      [
                          -75.794016,
                          45.478918,
                          0
                      ],
                      [
                          -75.793857,
                          45.479143,
                          0
                      ],
                      [
                          -75.793353,
                          45.478865,
                          0
                      ],
                      [
                          -75.793406,
                          45.478732,
                          0
                      ],
                      [
                          -75.792729,
                          45.478369,
                          0
                      ],
                      [
                          -75.79252,
                          45.478285,
                          0
                      ],
                      [
                          -75.792445,
                          45.478441,
                          0
                      ],
                      [
                          -75.790853,
                          45.478013,
                          0
                      ],
                      [
                          -75.790853,
                          45.478125,
                          0
                      ],
                      [
                          -75.79032,
                          45.47815,
                          0
                      ],
                      [
                          -75.79009,
                          45.477102,
                          0
                      ],
                      [
                          -75.788544,
                          45.476691,
                          0
                      ],
                      [
                          -75.786945,
                          45.475625,
                          0
                      ],
                      [
                          -75.784789,
                          45.4746,
                          0
                      ],
                      [
                          -75.782852,
                          45.473316,
                          0
                      ],
                      [
                          -75.780631,
                          45.459715,
                          0
                      ],
                      [
                          -75.793999,
                          45.458763,
                          0
                      ],
                      [
                          -75.796402,
                          45.473318,
                          0
                      ],
                      [
                          -75.796407,
                          45.473347,
                          0
                      ],
                      [
                          -75.820735,
                          45.471562,
                          0
                      ],
                      [
                          -75.837111,
                          45.470379,
                          0
                      ],
                      [
                          -75.840154,
                          45.485741,
                          0
                      ],
                      [
                          -75.849088,
                          45.484993,
                          0
                      ],
                      [
                          -75.848908,
                          45.484163,
                          0
                      ],
                      [
                          -75.850092,
                          45.484082,
                          0
                      ],
                      [
                          -75.850202,
                          45.484617,
                          0
                      ],
                      [
                          -75.853491,
                          45.484333,
                          0
                      ],
                      [
                          -75.853557,
                          45.484699,
                          0
                      ],
                      [
                          -75.908451,
                          45.480412,
                          0
                      ],
                      [
                          -75.920124,
                          45.56264,
                          0
                      ],
                      [
                          -75.929509,
                          45.613755,
                          0
                      ],
                      [
                          -76.118367,
                          45.597159,
                          0
                      ],
                      [
                          -76.131647,
                          45.648229,
                          0
                      ],
                      [
                          -76.137411,
                          45.682622,
                          0
                      ],
                      [
                          -76.32352,
                          45.668083,
                          0
                      ],
                      [
                          -76.325507,
                          45.800553,
                          0
                      ],
                      [
                          -76.151145,
                          45.804124,
                          0
                      ],
                      [
                          -76.15221,
                          46.022193,
                          0
                      ],
                      [
                          -76.319064,
                          46.023673,
                          0
                      ],
                      [
                          -76.315835,
                          46.259576,
                          0
                      ],
                      [
                          -76.327807,
                          46.27601,
                          0
                      ],
                      [
                          -76.363355,
                          46.283673,
                          0
                      ],
                      [
                          -76.388759,
                          46.263109,
                          0
                      ],
                      [
                          -76.457425,
                          46.24705,
                          0
                      ],
                      [
                          -76.482638,
                          46.241181,
                          0
                      ],
                      [
                          -76.507955,
                          46.24582,
                          0
                      ],
                      [
                          -76.552095,
                          46.280613,
                          0
                      ],
                      [
                          -76.590742,
                          46.323008,
                          0
                      ],
                      [
                          -76.556567,
                          46.325139,
                          0
                      ],
                      [
                          -76.533341,
                          46.333453,
                          0
                      ],
                      [
                          -76.50895,
                          46.341668,
                          0
                      ],
                      [
                          -76.512067,
                          46.364809,
                          0
                      ],
                      [
                          -76.530028,
                          46.389482,
                          0
                      ],
                      [
                          -76.522749,
                          46.41059,
                          0
                      ],
                      [
                          -76.531439,
                          46.428619,
                          0
                      ],
                      [
                          -76.589809,
                          46.462257,
                          0
                      ],
                      [
                          -76.609471,
                          46.486894,
                          0
                      ],
                      [
                          -76.623393,
                          46.503426,
                          0
                      ],
                      [
                          -76.653842,
                          46.514174,
                          0
                      ],
                      [
                          -76.656087,
                          46.534479,
                          0
                      ],
                      [
                          -76.648594,
                          46.556568,
                          0
                      ],
                      [
                          -76.657991,
                          46.574477,
                          0
                      ],
                      [
                          -76.681797,
                          46.583368,
                          0
                      ],
                      [
                          -76.676956,
                          46.60651,
                          0
                      ],
                      [
                          -76.670161,
                          46.629786,
                          0
                      ],
                      [
                          -76.644398,
                          46.63481,
                          0
                      ],
                      [
                          -76.627146,
                          46.649618,
                          0
                      ],
                      [
                          -76.658716,
                          46.667755,
                          0
                      ],
                      [
                          -76.684514,
                          46.671309,
                          0
                      ],
                      [
                          -76.697854,
                          46.654676,
                          0
                      ],
                      [
                          -76.724547,
                          46.65476,
                          0
                      ],
                      [
                          -76.744857,
                          46.642009,
                          0
                      ],
                      [
                          -76.75574,
                          46.659363,
                          0
                      ],
                      [
                          -76.774054,
                          46.646419,
                          0
                      ],
                      [
                          -76.793518,
                          46.658571,
                          0
                      ],
                      [
                          -76.794241,
                          47.000439,
                          0
                      ],
                      [
                          -77.002098,
                          47.000474,
                          0
                      ],
                      [
                          -77.002779,
                          47.144413,
                          0
                      ],
                      [
                          -77.002048,
                          47.290181,
                          0
                      ],
                      [
                          -76.575722,
                          47.289784,
                          0
                      ],
                      [
                          -76.57464,
                          47.603485,
                          0
                      ],
                      [
                          -76.426445,
                          47.513087,
                          0
                      ],
                      [
                          -76.40764,
                          47.562311,
                          0
                      ],
                      [
                          -76.424007,
                          47.606505,
                          0
                      ],
                      [
                          -76.475809,
                          47.600731,
                          0
                      ],
                      [
                          -76.455751,
                          47.628459,
                          0
                      ],
                      [
                          -76.429799,
                          47.632512,
                          0
                      ],
                      [
                          -76.403259,
                          47.646986,
                          0
                      ],
                      [
                          -76.369229,
                          47.652705,
                          0
                      ],
                      [
                          -76.342673,
                          47.655552,
                          0
                      ],
                      [
                          -76.322653,
                          47.667528,
                          0
                      ],
                      [
                          -76.291483,
                          47.678916,
                          0
                      ],
                      [
                          -76.244937,
                          47.706678,
                          0
                      ],
                      [
                          -76.221433,
                          47.726644,
                          0
                      ],
                      [
                          -76.184785,
                          47.731501,
                          0
                      ],
                      [
                          -76.156393,
                          47.7162,
                          0
                      ],
                      [
                          -76.0807,
                          47.723468,
                          0
                      ],
                      [
                          -76.05647,
                          47.731148,
                          0
                      ],
                      [
                          -76.019783,
                          47.72355,
                          0
                      ],
                      [
                          -75.98313,
                          47.746656,
                          0
                      ],
                      [
                          -75.985606,
                          47.771945,
                          0
                      ],
                      [
                          -75.92646,
                          47.801315,
                          0
                      ],
                      [
                          -75.882457,
                          47.799935,
                          0
                      ],
                      [
                          -75.855939,
                          47.802354,
                          0
                      ],
                      [
                          -75.866255,
                          47.779875,
                          0
                      ],
                      [
                          -75.834016,
                          47.758251,
                          0
                      ],
                      [
                          -75.83347,
                          47.740183,
                          0
                      ],
                      [
                          -75.842259,
                          47.721277,
                          0
                      ],
                      [
                          -75.833754,
                          47.697714,
                          0
                      ],
                      [
                          -75.813921,
                          47.684044,
                          0
                      ],
                      [
                          -75.794444,
                          47.703176,
                          0
                      ],
                      [
                          -75.79022,
                          47.721044,
                          0
                      ],
                      [
                          -75.782579,
                          47.774921,
                          0
                      ],
                      [
                          -75.781876,
                          47.799594,
                          0
                      ],
                      [
                          -75.750776,
                          47.826649,
                          0
                      ],
                      [
                          -75.725318,
                          47.847538,
                          0
                      ],
                      [
                          -75.520639,
                          47.848128,
                          0
                      ],
                      [
                          -75.521074,
                          47.763317,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Gatineau",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -75.521074,
                          47.763317,
                          0
                      ],
                      [
                          -74.889381,
                          47.762639,
                          0
                      ],
                      [
                          -74.698509,
                          47.633395,
                          0
                      ],
                      [
                          -74.651105,
                          47.613632,
                          0
                      ],
                      [
                          -74.642092,
                          47.606309,
                          0
                      ],
                      [
                          -74.630047,
                          47.588706,
                          0
                      ],
                      [
                          -74.613942,
                          47.574187,
                          0
                      ],
                      [
                          -74.583245,
                          47.545379,
                          0
                      ],
                      [
                          -74.559924,
                          47.536634,
                          0
                      ],
                      [
                          -74.550794,
                          47.519582,
                          0
                      ],
                      [
                          -74.51707,
                          47.502808,
                          0
                      ],
                      [
                          -74.493302,
                          47.511538,
                          0
                      ],
                      [
                          -74.462931,
                          47.505064,
                          0
                      ],
                      [
                          -74.426026,
                          47.505371,
                          0
                      ],
                      [
                          -74.439053,
                          47.489133,
                          0
                      ],
                      [
                          -74.442093,
                          47.472185,
                          0
                      ],
                      [
                          -74.488511,
                          47.42817,
                          0
                      ],
                      [
                          -74.506351,
                          47.415068,
                          0
                      ],
                      [
                          -74.529004,
                          47.372552,
                          0
                      ],
                      [
                          -74.550317,
                          47.329544,
                          0
                      ],
                      [
                          -74.546184,
                          47.320721,
                          0
                      ],
                      [
                          -74.55842,
                          47.324385,
                          0
                      ],
                      [
                          -74.581527,
                          47.30223,
                          0
                      ],
                      [
                          -74.584316,
                          47.311214,
                          0
                      ],
                      [
                          -74.588521,
                          47.302471,
                          0
                      ],
                      [
                          -74.588032,
                          47.293412,
                          0
                      ],
                      [
                          -74.611148,
                          47.271953,
                          0
                      ],
                      [
                          -74.617571,
                          47.254353,
                          0
                      ],
                      [
                          -74.62806,
                          47.248533,
                          0
                      ],
                      [
                          -74.64045,
                          47.251707,
                          0
                      ],
                      [
                          -74.652731,
                          47.24826,
                          0
                      ],
                      [
                          -74.654081,
                          47.23395,
                          0
                      ],
                      [
                          -74.489746,
                          47.224559,
                          0
                      ],
                      [
                          -74.434522,
                          47.157527,
                          0
                      ],
                      [
                          -74.466049,
                          47.135695,
                          0
                      ],
                      [
                          -74.415174,
                          47.100719,
                          0
                      ],
                      [
                          -74.57471,
                          46.997502,
                          0
                      ],
                      [
                          -74.427757,
                          46.896843,
                          0
                      ],
                      [
                          -74.450442,
                          46.887194,
                          0
                      ],
                      [
                          -74.454484,
                          46.878371,
                          0
                      ],
                      [
                          -74.484498,
                          46.859049,
                          0
                      ],
                      [
                          -74.484253,
                          46.83883,
                          0
                      ],
                      [
                          -74.477308,
                          46.830823,
                          0
                      ],
                      [
                          -74.472172,
                          46.794332,
                          0
                      ],
                      [
                          -74.483803,
                          46.790018,
                          0
                      ],
                      [
                          -74.482553,
                          46.780855,
                          0
                      ],
                      [
                          -74.498083,
                          46.754859,
                          0
                      ],
                      [
                          -74.49961,
                          46.735633,
                          0
                      ],
                      [
                          -74.487585,
                          46.731743,
                          0
                      ],
                      [
                          -74.495727,
                          46.72435,
                          0
                      ],
                      [
                          -74.489058,
                          46.716496,
                          0
                      ],
                      [
                          -74.501143,
                          46.70047,
                          0
                      ],
                      [
                          -74.499981,
                          46.691413,
                          0
                      ],
                      [
                          -74.507829,
                          46.683263,
                          0
                      ],
                      [
                          -74.50958,
                          46.665075,
                          0
                      ],
                      [
                          -74.502802,
                          46.657271,
                          0
                      ],
                      [
                          -74.506922,
                          46.648442,
                          0
                      ],
                      [
                          -74.496388,
                          46.631325,
                          0
                      ],
                      [
                          -74.499319,
                          46.602633,
                          0
                      ],
                      [
                          -74.499343,
                          46.593519,
                          0
                      ],
                      [
                          -74.486893,
                          46.598743,
                          0
                      ],
                      [
                          -74.480194,
                          46.606935,
                          0
                      ],
                      [
                          -74.467728,
                          46.610634,
                          0
                      ],
                      [
                          -74.454507,
                          46.607079,
                          0
                      ],
                      [
                          -74.444027,
                          46.620295,
                          0
                      ],
                      [
                          -74.343519,
                          46.549237,
                          0
                      ],
                      [
                          -74.477487,
                          46.454092,
                          0
                      ],
                      [
                          -74.334157,
                          46.354002,
                          0
                      ],
                      [
                          -74.474843,
                          46.254501,
                          0
                      ],
                      [
                          -74.443276,
                          46.232429,
                          0
                      ],
                      [
                          -74.458985,
                          46.221155,
                          0
                      ],
                      [
                          -74.368136,
                          46.158713,
                          0
                      ],
                      [
                          -74.396271,
                          46.068347,
                          0
                      ],
                      [
                          -74.41289,
                          46.07128,
                          0
                      ],
                      [
                          -74.416107,
                          46.055644,
                          0
                      ],
                      [
                          -74.399077,
                          46.053961,
                          0
                      ],
                      [
                          -74.40763,
                          46.018673,
                          0
                      ],
                      [
                          -74.412674,
                          46.019169,
                          0
                      ],
                      [
                          -74.430726,
                          46.026809,
                          0
                      ],
                      [
                          -74.480384,
                          46.029272,
                          0
                      ],
                      [
                          -74.517484,
                          46.034284,
                          0
                      ],
                      [
                          -74.571071,
                          46.03894,
                          0
                      ],
                      [
                          -74.61638,
                          46.051122,
                          0
                      ],
                      [
                          -74.627433,
                          46.052581,
                          0
                      ],
                      [
                          -74.616555,
                          46.047827,
                          0
                      ],
                      [
                          -74.623389,
                          46.043248,
                          0
                      ],
                      [
                          -74.613507,
                          46.037432,
                          0
                      ],
                      [
                          -74.620899,
                          46.029997,
                          0
                      ],
                      [
                          -74.629329,
                          46.022801,
                          0
                      ],
                      [
                          -74.628536,
                          46.013609,
                          0
                      ],
                      [
                          -74.633949,
                          46.00493,
                          0
                      ],
                      [
                          -74.633442,
                          45.986797,
                          0
                      ],
                      [
                          -74.625865,
                          45.979319,
                          0
                      ],
                      [
                          -74.63239,
                          45.971461,
                          0
                      ],
                      [
                          -74.642258,
                          45.965613,
                          0
                      ],
                      [
                          -74.652089,
                          45.959456,
                          0
                      ],
                      [
                          -74.656465,
                          45.95097,
                          0
                      ],
                      [
                          -74.673348,
                          45.937829,
                          0
                      ],
                      [
                          -74.670617,
                          45.929208,
                          0
                      ],
                      [
                          -74.680216,
                          45.929796,
                          0
                      ],
                      [
                          -74.710174,
                          45.931876,
                          0
                      ],
                      [
                          -74.706597,
                          45.945891,
                          0
                      ],
                      [
                          -74.698244,
                          45.971775,
                          0
                      ],
                      [
                          -74.76322,
                          45.972747,
                          0
                      ],
                      [
                          -74.763468,
                          45.960396,
                          0
                      ],
                      [
                          -74.764242,
                          45.946256,
                          0
                      ],
                      [
                          -74.801723,
                          45.944758,
                          0
                      ],
                      [
                          -74.800906,
                          45.958901,
                          0
                      ],
                      [
                          -74.806466,
                          45.958619,
                          0
                      ],
                      [
                          -74.808901,
                          45.973024,
                          0
                      ],
                      [
                          -74.831433,
                          45.97312,
                          0
                      ],
                      [
                          -74.831384,
                          46.00306,
                          0
                      ],
                      [
                          -74.837606,
                          46.003052,
                          0
                      ],
                      [
                          -74.837629,
                          46.01677,
                          0
                      ],
                      [
                          -74.857972,
                          46.016768,
                          0
                      ],
                      [
                          -74.858618,
                          46.060743,
                          0
                      ],
                      [
                          -74.871333,
                          46.062474,
                          0
                      ],
                      [
                          -74.939451,
                          46.061729,
                          0
                      ],
                      [
                          -74.939544,
                          46.105534,
                          0
                      ],
                      [
                          -75.089903,
                          46.103251,
                          0
                      ],
                      [
                          -75.084856,
                          46.13992,
                          0
                      ],
                      [
                          -75.075233,
                          46.215283,
                          0
                      ],
                      [
                          -75.11739,
                          46.215203,
                          0
                      ],
                      [
                          -75.116555,
                          46.184782,
                          0
                      ],
                      [
                          -75.137641,
                          46.184942,
                          0
                      ],
                      [
                          -75.137272,
                          46.160567,
                          0
                      ],
                      [
                          -75.156313,
                          46.160603,
                          0
                      ],
                      [
                          -75.155932,
                          46.132296,
                          0
                      ],
                      [
                          -75.176985,
                          46.132306,
                          0
                      ],
                      [
                          -75.176356,
                          46.103407,
                          0
                      ],
                      [
                          -75.210629,
                          46.103259,
                          0
                      ],
                      [
                          -75.213184,
                          46.098071,
                          0
                      ],
                      [
                          -75.408303,
                          46.097614,
                          0
                      ],
                      [
                          -75.408285,
                          45.973214,
                          0
                      ],
                      [
                          -75.577126,
                          45.972091,
                          0
                      ],
                      [
                          -75.59879,
                          45.971413,
                          0
                      ],
                      [
                          -75.599868,
                          45.994402,
                          0
                      ],
                      [
                          -75.62037,
                          45.994414,
                          0
                      ],
                      [
                          -75.620431,
                          45.982396,
                          0
                      ],
                      [
                          -75.662479,
                          45.982457,
                          0
                      ],
                      [
                          -75.662484,
                          45.973319,
                          0
                      ],
                      [
                          -75.666035,
                          45.970303,
                          0
                      ],
                      [
                          -75.737403,
                          45.970215,
                          0
                      ],
                      [
                          -75.746307,
                          45.972927,
                          0
                      ],
                      [
                          -75.759334,
                          45.960358,
                          0
                      ],
                      [
                          -75.771964,
                          45.954628,
                          0
                      ],
                      [
                          -75.77443,
                          45.953817,
                          0
                      ],
                      [
                          -75.784876,
                          45.963533,
                          0
                      ],
                      [
                          -75.775171,
                          45.983826,
                          0
                      ],
                      [
                          -75.738772,
                          46.007899,
                          0
                      ],
                      [
                          -75.699371,
                          46.023248,
                          0
                      ],
                      [
                          -75.763414,
                          46.022737,
                          0
                      ],
                      [
                          -75.768351,
                          46.173832,
                          0
                      ],
                      [
                          -75.800076,
                          46.178652,
                          0
                      ],
                      [
                          -75.810317,
                          46.196459,
                          0
                      ],
                      [
                          -75.811734,
                          46.222505,
                          0
                      ],
                      [
                          -75.80419,
                          46.239879,
                          0
                      ],
                      [
                          -75.795817,
                          46.258636,
                          0
                      ],
                      [
                          -75.784019,
                          46.286146,
                          0
                      ],
                      [
                          -75.814236,
                          46.306122,
                          0
                      ],
                      [
                          -75.70662,
                          46.308718,
                          0
                      ],
                      [
                          -75.705594,
                          46.438808,
                          0
                      ],
                      [
                          -75.745211,
                          46.438257,
                          0
                      ],
                      [
                          -75.747544,
                          46.844826,
                          0
                      ],
                      [
                          -75.801988,
                          46.844883,
                          0
                      ],
                      [
                          -75.828998,
                          46.82434,
                          0
                      ],
                      [
                          -75.867951,
                          46.816722,
                          0
                      ],
                      [
                          -75.896979,
                          46.818131,
                          0
                      ],
                      [
                          -75.915226,
                          46.844769,
                          0
                      ],
                      [
                          -75.936776,
                          46.857773,
                          0
                      ],
                      [
                          -75.968625,
                          46.87715,
                          0
                      ],
                      [
                          -76.027172,
                          46.895432,
                          0
                      ],
                      [
                          -76.056527,
                          46.902905,
                          0
                      ],
                      [
                          -76.127576,
                          46.920724,
                          0
                      ],
                      [
                          -76.135307,
                          46.946834,
                          0
                      ],
                      [
                          -76.134576,
                          46.964911,
                          0
                      ],
                      [
                          -76.148459,
                          47.005988,
                          0
                      ],
                      [
                          -76.117844,
                          47.021795,
                          0
                      ],
                      [
                          -76.10341,
                          47.045067,
                          0
                      ],
                      [
                          -76.098449,
                          47.089967,
                          0
                      ],
                      [
                          -76.092178,
                          47.134948,
                          0
                      ],
                      [
                          -76.100124,
                          47.161313,
                          0
                      ],
                      [
                          -76.090037,
                          47.184679,
                          0
                      ],
                      [
                          -76.041798,
                          47.197897,
                          0
                      ],
                      [
                          -75.968572,
                          47.20813,
                          0
                      ],
                      [
                          -75.919443,
                          47.249998,
                          0
                      ],
                      [
                          -75.88594,
                          47.277905,
                          0
                      ],
                      [
                          -75.813978,
                          47.31634,
                          0
                      ],
                      [
                          -75.779641,
                          47.308126,
                          0
                      ],
                      [
                          -75.746806,
                          47.29295,
                          0
                      ],
                      [
                          -75.734676,
                          47.272422,
                          0
                      ],
                      [
                          -75.713833,
                          47.284216,
                          0
                      ],
                      [
                          -75.679506,
                          47.285394,
                          0
                      ],
                      [
                          -75.649093,
                          47.272687,
                          0
                      ],
                      [
                          -75.627548,
                          47.222221,
                          0
                      ],
                      [
                          -75.615265,
                          47.182596,
                          0
                      ],
                      [
                          -75.590166,
                          47.189996,
                          0
                      ],
                      [
                          -75.536739,
                          47.230697,
                          0
                      ],
                      [
                          -75.497264,
                          47.268063,
                          0
                      ],
                      [
                          -75.476271,
                          47.279756,
                          0
                      ],
                      [
                          -75.46012,
                          47.324104,
                          0
                      ],
                      [
                          -75.470617,
                          47.348733,
                          0
                      ],
                      [
                          -75.455483,
                          47.374437,
                          0
                      ],
                      [
                          -75.434218,
                          47.386682,
                          0
                      ],
                      [
                          -75.421169,
                          47.403294,
                          0
                      ],
                      [
                          -75.393785,
                          47.424186,
                          0
                      ],
                      [
                          -75.383896,
                          47.450764,
                          0
                      ],
                      [
                          -75.36627,
                          47.486178,
                          0
                      ],
                      [
                          -75.32243,
                          47.532201,
                          0
                      ],
                      [
                          -75.308029,
                          47.54747,
                          0
                      ],
                      [
                          -75.327595,
                          47.559924,
                          0
                      ],
                      [
                          -75.365268,
                          47.583313,
                          0
                      ],
                      [
                          -75.360352,
                          47.601099,
                          0
                      ],
                      [
                          -75.380356,
                          47.620941,
                          0
                      ],
                      [
                          -75.391646,
                          47.60434,
                          0
                      ],
                      [
                          -75.412365,
                          47.587567,
                          0
                      ],
                      [
                          -75.427634,
                          47.612887,
                          0
                      ],
                      [
                          -75.419878,
                          47.630533,
                          0
                      ],
                      [
                          -75.435843,
                          47.646398,
                          0
                      ],
                      [
                          -75.446258,
                          47.66298,
                          0
                      ],
                      [
                          -75.496906,
                          47.693334,
                          0
                      ],
                      [
                          -75.524818,
                          47.711644,
                          0
                      ],
                      [
                          -75.532347,
                          47.737517,
                          0
                      ],
                      [
                          -75.521074,
                          47.763317,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Labelle",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -77.931267,
                          47.269377,
                          0
                      ],
                      [
                          -77.647309,
                          47.269272,
                          0
                      ],
                      [
                          -77.647279,
                          47.290096,
                          0
                      ],
                      [
                          -77.432627,
                          47.290308,
                          0
                      ],
                      [
                          -77.431501,
                          47.144711,
                          0
                      ],
                      [
                          -77.002779,
                          47.144413,
                          0
                      ],
                      [
                          -77.002098,
                          47.000474,
                          0
                      ],
                      [
                          -77.001821,
                          47.000473,
                          0
                      ],
                      [
                          -77.001807,
                          47.000473,
                          0
                      ],
                      [
                          -77.001801,
                          47.000474,
                          0
                      ],
                      [
                          -77.001776,
                          47.000474,
                          0
                      ],
                      [
                          -77.001669,
                          47.000473,
                          0
                      ],
                      [
                          -77.001667,
                          47.000474,
                          0
                      ],
                      [
                          -76.794241,
                          47.000439,
                          0
                      ],
                      [
                          -76.793518,
                          46.658571,
                          0
                      ],
                      [
                          -76.774054,
                          46.646419,
                          0
                      ],
                      [
                          -76.75574,
                          46.659363,
                          0
                      ],
                      [
                          -76.744857,
                          46.642009,
                          0
                      ],
                      [
                          -76.724547,
                          46.65476,
                          0
                      ],
                      [
                          -76.697854,
                          46.654676,
                          0
                      ],
                      [
                          -76.684514,
                          46.671309,
                          0
                      ],
                      [
                          -76.658716,
                          46.667755,
                          0
                      ],
                      [
                          -76.627146,
                          46.649618,
                          0
                      ],
                      [
                          -76.644398,
                          46.63481,
                          0
                      ],
                      [
                          -76.670161,
                          46.629786,
                          0
                      ],
                      [
                          -76.676956,
                          46.60651,
                          0
                      ],
                      [
                          -76.681797,
                          46.583368,
                          0
                      ],
                      [
                          -76.657991,
                          46.574477,
                          0
                      ],
                      [
                          -76.648594,
                          46.556568,
                          0
                      ],
                      [
                          -76.656087,
                          46.534479,
                          0
                      ],
                      [
                          -76.653842,
                          46.514174,
                          0
                      ],
                      [
                          -76.623393,
                          46.503426,
                          0
                      ],
                      [
                          -76.609471,
                          46.486894,
                          0
                      ],
                      [
                          -76.589809,
                          46.462257,
                          0
                      ],
                      [
                          -76.531439,
                          46.428619,
                          0
                      ],
                      [
                          -76.522749,
                          46.41059,
                          0
                      ],
                      [
                          -76.530028,
                          46.389482,
                          0
                      ],
                      [
                          -76.512067,
                          46.364809,
                          0
                      ],
                      [
                          -76.50895,
                          46.341668,
                          0
                      ],
                      [
                          -76.533341,
                          46.333453,
                          0
                      ],
                      [
                          -76.556567,
                          46.325139,
                          0
                      ],
                      [
                          -76.590742,
                          46.323008,
                          0
                      ],
                      [
                          -76.552095,
                          46.280613,
                          0
                      ],
                      [
                          -76.507955,
                          46.24582,
                          0
                      ],
                      [
                          -76.482638,
                          46.241181,
                          0
                      ],
                      [
                          -76.457425,
                          46.24705,
                          0
                      ],
                      [
                          -76.388759,
                          46.263109,
                          0
                      ],
                      [
                          -76.363355,
                          46.283673,
                          0
                      ],
                      [
                          -76.327807,
                          46.27601,
                          0
                      ],
                      [
                          -76.315835,
                          46.259576,
                          0
                      ],
                      [
                          -76.319064,
                          46.023673,
                          0
                      ],
                      [
                          -76.15221,
                          46.022193,
                          0
                      ],
                      [
                          -76.151145,
                          45.804124,
                          0
                      ],
                      [
                          -76.325507,
                          45.800553,
                          0
                      ],
                      [
                          -76.323523,
                          45.668101,
                          0
                      ],
                      [
                          -76.32352,
                          45.668083,
                          0
                      ],
                      [
                          -76.137411,
                          45.682622,
                          0
                      ],
                      [
                          -76.131647,
                          45.648229,
                          0
                      ],
                      [
                          -76.118367,
                          45.597159,
                          0
                      ],
                      [
                          -75.929509,
                          45.613755,
                          0
                      ],
                      [
                          -75.920124,
                          45.56264,
                          0
                      ],
                      [
                          -75.908451,
                          45.480412,
                          0
                      ],
                      [
                          -75.853557,
                          45.484699,
                          0
                      ],
                      [
                          -75.853491,
                          45.484333,
                          0
                      ],
                      [
                          -75.850202,
                          45.484617,
                          0
                      ],
                      [
                          -75.850092,
                          45.484082,
                          0
                      ],
                      [
                          -75.848908,
                          45.484163,
                          0
                      ],
                      [
                          -75.849088,
                          45.484993,
                          0
                      ],
                      [
                          -75.840154,
                          45.485741,
                          0
                      ],
                      [
                          -75.837111,
                          45.470379,
                          0
                      ],
                      [
                          -75.820735,
                          45.471562,
                          0
                      ],
                      [
                          -75.819887,
                          45.470262,
                          0
                      ],
                      [
                          -75.819825,
                          45.468666,
                          0
                      ],
                      [
                          -75.820723,
                          45.467482,
                          0
                      ],
                      [
                          -75.820938,
                          45.466965,
                          0
                      ],
                      [
                          -75.820956,
                          45.466297,
                          0
                      ],
                      [
                          -75.820571,
                          45.46547,
                          0
                      ],
                      [
                          -75.820141,
                          45.464807,
                          0
                      ],
                      [
                          -75.820072,
                          45.464522,
                          0
                      ],
                      [
                          -75.820215,
                          45.463459,
                          0
                      ],
                      [
                          -75.819932,
                          45.463105,
                          0
                      ],
                      [
                          -75.819344,
                          45.462757,
                          0
                      ],
                      [
                          -75.819164,
                          45.462631,
                          0
                      ],
                      [
                          -75.819146,
                          45.462578,
                          0
                      ],
                      [
                          -75.819237,
                          45.462474,
                          0
                      ],
                      [
                          -75.820329,
                          45.462975,
                          0
                      ],
                      [
                          -75.821204,
                          45.463596,
                          0
                      ],
                      [
                          -75.822302,
                          45.463916,
                          0
                      ],
                      [
                          -75.81935,
                          45.441217,
                          0
                      ],
                      [
                          -75.817848,
                          45.430816,
                          0
                      ],
                      [
                          -75.817303,
                          45.429916,
                          0
                      ],
                      [
                          -75.814374,
                          45.425787,
                          0
                      ],
                      [
                          -75.811594,
                          45.423869,
                          0
                      ],
                      [
                          -75.80977,
                          45.421389,
                          0
                      ],
                      [
                          -75.808787,
                          45.415435,
                          0
                      ],
                      [
                          -75.806026,
                          45.415815,
                          0
                      ],
                      [
                          -75.803571,
                          45.416857,
                          0
                      ],
                      [
                          -75.80159,
                          45.418818,
                          0
                      ],
                      [
                          -75.801176,
                          45.419755,
                          0
                      ],
                      [
                          -75.799437,
                          45.427816,
                          0
                      ],
                      [
                          -75.797606,
                          45.429846,
                          0
                      ],
                      [
                          -75.795313,
                          45.430776,
                          0
                      ],
                      [
                          -75.79384,
                          45.430948,
                          0
                      ],
                      [
                          -75.784227,
                          45.43054,
                          0
                      ],
                      [
                          -75.780252,
                          45.430944,
                          0
                      ],
                      [
                          -75.775972,
                          45.432034,
                          0
                      ],
                      [
                          -75.775778,
                          45.428981,
                          0
                      ],
                      [
                          -75.773611,
                          45.411868,
                          0
                      ],
                      [
                          -75.767692,
                          45.413939,
                          0
                      ],
                      [
                          -75.760781,
                          45.410431,
                          0
                      ],
                      [
                          -75.764873,
                          45.40128,
                          0
                      ],
                      [
                          -75.788284,
                          45.381335,
                          0
                      ],
                      [
                          -75.814988,
                          45.373387,
                          0
                      ],
                      [
                          -75.841067,
                          45.373383,
                          0
                      ],
                      [
                          -75.854145,
                          45.378976,
                          0
                      ],
                      [
                          -75.912646,
                          45.420107,
                          0
                      ],
                      [
                          -75.983573,
                          45.475778,
                          0
                      ],
                      [
                          -76.048741,
                          45.498364,
                          0
                      ],
                      [
                          -76.10116,
                          45.518136,
                          0
                      ],
                      [
                          -76.16263,
                          45.514665,
                          0
                      ],
                      [
                          -76.191749,
                          45.518553,
                          0
                      ],
                      [
                          -76.226202,
                          45.512871,
                          0
                      ],
                      [
                          -76.243114,
                          45.467806,
                          0
                      ],
                      [
                          -76.276607,
                          45.467864,
                          0
                      ],
                      [
                          -76.344179,
                          45.4568,
                          0
                      ],
                      [
                          -76.371359,
                          45.459301,
                          0
                      ],
                      [
                          -76.427691,
                          45.492641,
                          0
                      ],
                      [
                          -76.505612,
                          45.515419,
                          0
                      ],
                      [
                          -76.606743,
                          45.532768,
                          0
                      ],
                      [
                          -76.6346,
                          45.54622,
                          0
                      ],
                      [
                          -76.655953,
                          45.558195,
                          0
                      ],
                      [
                          -76.669524,
                          45.576141,
                          0
                      ],
                      [
                          -76.670377,
                          45.613503,
                          0
                      ],
                      [
                          -76.693695,
                          45.647482,
                          0
                      ],
                      [
                          -76.709536,
                          45.664999,
                          0
                      ],
                      [
                          -76.704387,
                          45.675567,
                          0
                      ],
                      [
                          -76.691234,
                          45.678777,
                          0
                      ],
                      [
                          -76.692132,
                          45.707551,
                          0
                      ],
                      [
                          -76.700318,
                          45.71528,
                          0
                      ],
                      [
                          -76.751741,
                          45.724347,
                          0
                      ],
                      [
                          -76.763489,
                          45.730923,
                          0
                      ],
                      [
                          -76.77013,
                          45.758332,
                          0
                      ],
                      [
                          -76.77823,
                          45.790534,
                          0
                      ],
                      [
                          -76.764787,
                          45.808086,
                          0
                      ],
                      [
                          -76.769186,
                          45.826311,
                          0
                      ],
                      [
                          -76.765785,
                          45.84712,
                          0
                      ],
                      [
                          -76.782549,
                          45.874191,
                          0
                      ],
                      [
                          -76.839978,
                          45.890708,
                          0
                      ],
                      [
                          -76.898745,
                          45.897779,
                          0
                      ],
                      [
                          -76.92775,
                          45.892399,
                          0
                      ],
                      [
                          -76.923683,
                          45.87337,
                          0
                      ],
                      [
                          -76.903202,
                          45.849194,
                          0
                      ],
                      [
                          -76.920963,
                          45.848756,
                          0
                      ],
                      [
                          -76.923016,
                          45.838809,
                          0
                      ],
                      [
                          -76.911569,
                          45.810002,
                          0
                      ],
                      [
                          -76.925804,
                          45.798781,
                          0
                      ],
                      [
                          -76.956824,
                          45.788115,
                          0
                      ],
                      [
                          -76.986216,
                          45.785473,
                          0
                      ],
                      [
                          -77.023967,
                          45.806617,
                          0
                      ],
                      [
                          -77.050549,
                          45.810453,
                          0
                      ],
                      [
                          -77.057941,
                          45.818451,
                          0
                      ],
                      [
                          -77.079115,
                          45.833396,
                          0
                      ],
                      [
                          -77.114363,
                          45.840202,
                          0
                      ],
                      [
                          -77.15635,
                          45.862692,
                          0
                      ],
                      [
                          -77.194387,
                          45.869032,
                          0
                      ],
                      [
                          -77.209599,
                          45.885834,
                          0
                      ],
                      [
                          -77.239747,
                          45.924033,
                          0
                      ],
                      [
                          -77.269166,
                          45.940162,
                          0
                      ],
                      [
                          -77.276807,
                          45.952113,
                          0
                      ],
                      [
                          -77.287311,
                          45.978231,
                          0
                      ],
                      [
                          -77.281559,
                          46.000005,
                          0
                      ],
                      [
                          -77.277893,
                          46.009393,
                          0
                      ],
                      [
                          -77.282361,
                          46.017927,
                          0
                      ],
                      [
                          -77.314531,
                          46.028369,
                          0
                      ],
                      [
                          -77.346449,
                          46.04737,
                          0
                      ],
                      [
                          -77.482737,
                          46.113955,
                          0
                      ],
                      [
                          -77.566968,
                          46.161624,
                          0
                      ],
                      [
                          -77.676675,
                          46.197963,
                          0
                      ],
                      [
                          -77.692823,
                          46.182478,
                          0
                      ],
                      [
                          -77.767998,
                          46.198244,
                          0
                      ],
                      [
                          -77.841599,
                          46.20637,
                          0
                      ],
                      [
                          -77.841608,
                          46.206374,
                          0
                      ],
                      [
                          -77.841601,
                          46.206373,
                          0
                      ],
                      [
                          -77.854499,
                          46.23228,
                          0
                      ],
                      [
                          -77.851296,
                          46.278372,
                          0
                      ],
                      [
                          -77.820563,
                          46.337914,
                          0
                      ],
                      [
                          -77.7869,
                          46.365496,
                          0
                      ],
                      [
                          -77.742731,
                          46.398113,
                          0
                      ],
                      [
                          -77.747336,
                          46.415953,
                          0
                      ],
                      [
                          -77.759402,
                          46.439811,
                          0
                      ],
                      [
                          -77.766422,
                          46.459508,
                          0
                      ],
                      [
                          -77.761528,
                          46.488373,
                          0
                      ],
                      [
                          -77.756348,
                          46.506724,
                          0
                      ],
                      [
                          -77.772245,
                          46.531688,
                          0
                      ],
                      [
                          -77.780466,
                          46.555199,
                          0
                      ],
                      [
                          -77.777978,
                          46.589262,
                          0
                      ],
                      [
                          -77.789007,
                          46.606175,
                          0
                      ],
                      [
                          -77.826501,
                          46.622018,
                          0
                      ],
                      [
                          -77.846429,
                          46.635782,
                          0
                      ],
                      [
                          -77.858001,
                          46.660825,
                          0
                      ],
                      [
                          -77.818676,
                          46.707104,
                          0
                      ],
                      [
                          -77.829526,
                          46.743684,
                          0
                      ],
                      [
                          -77.834515,
                          46.771547,
                          0
                      ],
                      [
                          -77.823334,
                          46.805424,
                          0
                      ],
                      [
                          -77.82528,
                          46.823807,
                          0
                      ],
                      [
                          -77.851638,
                          46.821608,
                          0
                      ],
                      [
                          -77.877331,
                          46.827836,
                          0
                      ],
                      [
                          -77.884805,
                          46.860402,
                          0
                      ],
                      [
                          -77.88649,
                          46.889148,
                          0
                      ],
                      [
                          -77.872959,
                          46.905877,
                          0
                      ],
                      [
                          -77.856917,
                          46.940691,
                          0
                      ],
                      [
                          -77.845411,
                          46.924119,
                          0
                      ],
                      [
                          -77.844671,
                          46.905225,
                          0
                      ],
                      [
                          -77.844194,
                          46.88585,
                          0
                      ],
                      [
                          -77.841484,
                          46.903822,
                          0
                      ],
                      [
                          -77.835127,
                          46.922455,
                          0
                      ],
                      [
                          -77.842891,
                          46.951333,
                          0
                      ],
                      [
                          -77.844455,
                          46.979447,
                          0
                      ],
                      [
                          -77.834889,
                          47.005987,
                          0
                      ],
                      [
                          -77.846715,
                          47.023192,
                          0
                      ],
                      [
                          -77.841249,
                          47.041881,
                          0
                      ],
                      [
                          -77.829706,
                          47.05877,
                          0
                      ],
                      [
                          -77.827856,
                          47.077801,
                          0
                      ],
                      [
                          -77.845712,
                          47.11418,
                          0
                      ],
                      [
                          -77.850871,
                          47.152371,
                          0
                      ],
                      [
                          -77.852022,
                          47.198344,
                          0
                      ],
                      [
                          -77.856629,
                          47.225686,
                          0
                      ],
                      [
                          -77.866839,
                          47.242895,
                          0
                      ],
                      [
                          -77.901179,
                          47.243273,
                          0
                      ],
                      [
                          -77.931267,
                          47.269377,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Pontiac",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      },
      {
          "type": "Feature",
          "geometry": {
              "type": "Polygon",
              "coordinates": [
                  [
                      [
                          -78.540954,
                          48.286864,
                          0
                      ],
                      [
                          -78.54094,
                          48.247944,
                          0
                      ],
                      [
                          -78.429791,
                          48.247922,
                          0
                      ],
                      [
                          -78.429465,
                          48.287654,
                          0
                      ],
                      [
                          -78.37809,
                          48.30198,
                          0
                      ],
                      [
                          -78.323691,
                          48.346829,
                          0
                      ],
                      [
                          -78.307612,
                          48.348471,
                          0
                      ],
                      [
                          -78.274238,
                          48.339017,
                          0
                      ],
                      [
                          -78.279041,
                          48.30597,
                          0
                      ],
                      [
                          -78.27181,
                          48.298392,
                          0
                      ],
                      [
                          -78.224503,
                          48.298329,
                          0
                      ],
                      [
                          -78.22339,
                          48.258263,
                          0
                      ],
                      [
                          -78.108052,
                          48.258367,
                          0
                      ],
                      [
                          -78.078647,
                          48.266842,
                          0
                      ],
                      [
                          -78.061235,
                          48.286906,
                          0
                      ],
                      [
                          -77.560571,
                          48.287412,
                          0
                      ],
                      [
                          -77.560689,
                          48.503311,
                          0
                      ],
                      [
                          -77.344096,
                          48.503571,
                          0
                      ],
                      [
                          -77.344048,
                          48.574845,
                          0
                      ],
                      [
                          -77.137206,
                          48.575059,
                          0
                      ],
                      [
                          -77.123951,
                          48.614687,
                          0
                      ],
                      [
                          -77.100109,
                          48.641365,
                          0
                      ],
                      [
                          -77.09662,
                          48.65615,
                          0
                      ],
                      [
                          -77.093731,
                          48.715262,
                          0
                      ],
                      [
                          -77.08575,
                          48.722649,
                          0
                      ],
                      [
                          -77.090346,
                          48.740703,
                          0
                      ],
                      [
                          -77.109176,
                          48.770185,
                          0
                      ],
                      [
                          -77.110031,
                          48.788778,
                          0
                      ],
                      [
                          -77.125247,
                          48.80073,
                          0
                      ],
                      [
                          -77.112817,
                          48.834098,
                          0
                      ],
                      [
                          -77.118636,
                          48.853133,
                          0
                      ],
                      [
                          -77.141774,
                          48.862548,
                          0
                      ],
                      [
                          -77.131811,
                          48.897713,
                          0
                      ],
                      [
                          -77.117633,
                          48.926685,
                          0
                      ],
                      [
                          -77.095188,
                          48.953592,
                          0
                      ],
                      [
                          -77.066295,
                          48.960959,
                          0
                      ],
                      [
                          -77.059499,
                          49.000107,
                          0
                      ],
                      [
                          -75.520554,
                          49.00007,
                          0
                      ],
                      [
                          -75.520639,
                          47.848128,
                          0
                      ],
                      [
                          -75.725318,
                          47.847538,
                          0
                      ],
                      [
                          -75.750776,
                          47.826649,
                          0
                      ],
                      [
                          -75.781876,
                          47.799594,
                          0
                      ],
                      [
                          -75.782579,
                          47.774921,
                          0
                      ],
                      [
                          -75.79022,
                          47.721044,
                          0
                      ],
                      [
                          -75.794444,
                          47.703176,
                          0
                      ],
                      [
                          -75.813921,
                          47.684044,
                          0
                      ],
                      [
                          -75.833754,
                          47.697714,
                          0
                      ],
                      [
                          -75.842259,
                          47.721277,
                          0
                      ],
                      [
                          -75.83347,
                          47.740183,
                          0
                      ],
                      [
                          -75.834016,
                          47.758251,
                          0
                      ],
                      [
                          -75.866255,
                          47.779875,
                          0
                      ],
                      [
                          -75.855939,
                          47.802354,
                          0
                      ],
                      [
                          -75.882457,
                          47.799935,
                          0
                      ],
                      [
                          -75.92646,
                          47.801315,
                          0
                      ],
                      [
                          -75.985606,
                          47.771945,
                          0
                      ],
                      [
                          -75.98313,
                          47.746656,
                          0
                      ],
                      [
                          -76.019783,
                          47.72355,
                          0
                      ],
                      [
                          -76.05647,
                          47.731148,
                          0
                      ],
                      [
                          -76.0807,
                          47.723468,
                          0
                      ],
                      [
                          -76.156393,
                          47.7162,
                          0
                      ],
                      [
                          -76.184785,
                          47.731501,
                          0
                      ],
                      [
                          -76.221433,
                          47.726644,
                          0
                      ],
                      [
                          -76.244937,
                          47.706678,
                          0
                      ],
                      [
                          -76.291483,
                          47.678916,
                          0
                      ],
                      [
                          -76.322653,
                          47.667528,
                          0
                      ],
                      [
                          -76.342673,
                          47.655552,
                          0
                      ],
                      [
                          -76.369229,
                          47.652705,
                          0
                      ],
                      [
                          -76.403259,
                          47.646986,
                          0
                      ],
                      [
                          -76.429799,
                          47.632512,
                          0
                      ],
                      [
                          -76.455751,
                          47.628459,
                          0
                      ],
                      [
                          -76.475809,
                          47.600731,
                          0
                      ],
                      [
                          -76.424007,
                          47.606505,
                          0
                      ],
                      [
                          -76.40764,
                          47.562311,
                          0
                      ],
                      [
                          -76.426445,
                          47.513087,
                          0
                      ],
                      [
                          -76.57464,
                          47.603485,
                          0
                      ],
                      [
                          -76.575722,
                          47.289784,
                          0
                      ],
                      [
                          -77.002048,
                          47.290181,
                          0
                      ],
                      [
                          -77.002779,
                          47.144413,
                          0
                      ],
                      [
                          -77.431501,
                          47.144711,
                          0
                      ],
                      [
                          -77.432627,
                          47.290308,
                          0
                      ],
                      [
                          -77.647279,
                          47.290096,
                          0
                      ],
                      [
                          -77.647273,
                          47.269271,
                          0
                      ],
                      [
                          -77.931267,
                          47.269377,
                          0
                      ],
                      [
                          -77.918796,
                          47.285382,
                          0
                      ],
                      [
                          -77.887265,
                          47.301549,
                          0
                      ],
                      [
                          -77.856549,
                          47.307145,
                          0
                      ],
                      [
                          -77.86078,
                          47.324926,
                          0
                      ],
                      [
                          -77.826421,
                          47.318492,
                          0
                      ],
                      [
                          -77.829406,
                          47.337968,
                          0
                      ],
                      [
                          -77.797989,
                          47.367648,
                          0
                      ],
                      [
                          -77.790057,
                          47.390412,
                          0
                      ],
                      [
                          -77.764637,
                          47.39566,
                          0
                      ],
                      [
                          -77.768105,
                          47.418234,
                          0
                      ],
                      [
                          -77.656141,
                          47.425612,
                          0
                      ],
                      [
                          -77.610916,
                          47.408825,
                          0
                      ],
                      [
                          -77.60914,
                          47.433891,
                          0
                      ],
                      [
                          -77.566108,
                          47.45001,
                          0
                      ],
                      [
                          -77.562636,
                          47.474404,
                          0
                      ],
                      [
                          -77.510876,
                          47.469428,
                          0
                      ],
                      [
                          -77.526119,
                          47.484196,
                          0
                      ],
                      [
                          -77.512271,
                          47.499737,
                          0
                      ],
                      [
                          -77.479105,
                          47.49455,
                          0
                      ],
                      [
                          -77.483474,
                          47.514932,
                          0
                      ],
                      [
                          -77.430775,
                          47.488505,
                          0
                      ],
                      [
                          -77.447864,
                          47.502421,
                          0
                      ],
                      [
                          -77.456109,
                          47.538188,
                          0
                      ],
                      [
                          -77.493402,
                          47.544858,
                          0
                      ],
                      [
                          -77.477462,
                          47.599985,
                          0
                      ],
                      [
                          -77.433372,
                          47.590935,
                          0
                      ],
                      [
                          -77.467357,
                          47.643276,
                          0
                      ],
                      [
                          -77.421979,
                          47.702623,
                          0
                      ],
                      [
                          -78.654685,
                          47.703295,
                          0
                      ],
                      [
                          -78.653038,
                          47.880175,
                          0
                      ],
                      [
                          -78.703934,
                          47.880165,
                          0
                      ],
                      [
                          -78.692357,
                          47.906695,
                          0
                      ],
                      [
                          -78.657776,
                          47.921611,
                          0
                      ],
                      [
                          -78.690253,
                          47.953326,
                          0
                      ],
                      [
                          -78.694003,
                          47.996185,
                          0
                      ],
                      [
                          -78.652811,
                          47.996069,
                          0
                      ],
                      [
                          -78.649567,
                          48.212827,
                          0
                      ],
                      [
                          -78.637029,
                          48.223726,
                          0
                      ],
                      [
                          -78.64952,
                          48.229558,
                          0
                      ],
                      [
                          -78.649613,
                          48.287085,
                          0
                      ],
                      [
                          -78.540954,
                          48.286864,
                          0
                      ]
                  ]
              ]
          },
          "properties": {
              "name": "Abitibi-Est",
              "styleUrl": "#polystyle",
              "styleHash": "1c499575",
              "stroke": "#ffaa7f",
              "stroke-opacity": 1,
              "fill-opacity": 0
          }
      }
  ]
};

export default ridingsGeojson;
